import { DefaultStyles } from "@/common/styles/default-styles";

/**
 * Describes the style settings a menu node might have.
 */
export class MenuNodeStyle {
  get leftindentationInEmUnits(): string {
    return this._leftindentationInEmUnits + "em";
  }

  constructor(
    public borderColor: string = DefaultStyles.colorConstants.headerTextHEX,
    public selectedColor: string = DefaultStyles.colorConstants.editYellow,
    private _leftindentationInEmUnits: number = 3
  ) {}
}
