import { computed, Ref } from "vue";
import { useHelpText } from "@/services/help-text-service/help-text.cpsl";
import { SwiperVm } from "@/common/components/swiper-vm";

export function useHelpTextHelper(
  isExcludedFromScaling: Ref<boolean>,
  isShowSparklines: Ref<boolean>,
  canSwipePeriod: Ref<boolean>,
  kpiScaleIndex: Ref<number>,
  hasStructures: Ref<boolean>,
  isShowStructureElements: Ref<boolean>,
  canClickValueGroupTexts: Ref<boolean>,
  canSwipe: Ref<boolean>,
  deltaValuesSwiper: Ref<SwiperVm>,
  canExcludeFromScaling: Ref<boolean>
) {
  const helpTextCpsl = useHelpText();

  const periodTexts = computed<string>(() =>
    helpTextCpsl.kpiHeaderTexts(canSwipePeriod.value, -1)
  );

  const periodEmptyValue = computed<string>(() =>
    helpTextCpsl.kpiValueTexts(
      isExcludedFromScaling.value,
      kpiScaleIndex.value,
      -1,
      hasStructures.value,
      isShowSparklines.value,
      isShowStructureElements.value,
      false
    )
  );

  const periodDeviationValues = computed<string>(() =>
    helpTextCpsl.kpiValueTexts(
      isExcludedFromScaling.value,
      kpiScaleIndex.value,
      -1,
      hasStructures.value,
      isShowSparklines.value,
      isShowStructureElements.value,
      false
    )
  );

  const valueGroupTexts = computed<string>(() =>
    helpTextCpsl.kpiHeaderTexts(canClickValueGroupTexts.value, 0)
  );

  const valueGroupValues = computed<string>(() =>
    helpTextCpsl.kpiValueTexts(
      isExcludedFromScaling.value,
      kpiScaleIndex.value,
      0,
      hasStructures.value,
      isShowSparklines.value,
      isShowStructureElements.value,
      canExcludeFromScaling.value
    )
  );

  const deltaColumnIndex = computed<number>(() => {
    const activeIndex = deltaValuesSwiper.value.activeIndex;
    return activeIndex === 1 || activeIndex === 3 ? 1 : 2;
  });

  const deltaTexts = computed<string>(() =>
    helpTextCpsl.kpiHeaderTexts(canSwipe.value, deltaColumnIndex.value)
  );

  const deltaValues = computed<string>(() => {
    return helpTextCpsl.kpiValueTexts(
      isExcludedFromScaling.value,
      kpiScaleIndex.value,
      deltaColumnIndex.value,
      hasStructures.value,
      isShowSparklines.value,
      isShowStructureElements.value,
      canExcludeFromScaling.value
    );
  });

  const deltaValue_1 = computed<string>(() =>
    helpTextCpsl.kpiValueTexts(
      isExcludedFromScaling.value,
      kpiScaleIndex.value,
      2,
      hasStructures.value,
      isShowSparklines.value,
      isShowStructureElements.value,
      canExcludeFromScaling.value
    )
  );

  const deltaValue_2 = computed<string>(() =>
    helpTextCpsl.kpiValueTexts(
      isExcludedFromScaling.value,
      kpiScaleIndex.value,
      1,
      hasStructures.value,
      isShowSparklines.value,
      isShowStructureElements.value,
      canExcludeFromScaling.value
    )
  );

  return {
    periodTexts,
    periodEmptyValue,
    periodDeviationValues,
    valueGroupTexts,
    valueGroupValues,
    deltaTexts,
    deltaValues,
    deltaValue_1,
    deltaValue_2,
  };
}
