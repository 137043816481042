import { inject, provide } from "vue";
import { AxiosInstance } from "axios";
import {
  ApplicationGroupsServiceClient,
  ApplicationVersionsServiceClient,
  ApplicationsServiceClient,
  ConnectionGroupsServiceClient,
  ConnectionSetGroupsServiceClient,
  ConnectionSetsServiceClient,
  ConnectionsServiceClient,
  DashboardsServiceClient,
  Dm7ApplicationsServiceClient,
  PortalsServiceClient,
  PublishedApplicationGroupsServiceClient,
  PublishedApplicationsServiceClient,
  ReportGroupsServiceClient,
  ReportsServiceClient,
  SemanticModelsServiceClient,
  ReportingItemsServiceClient,
} from "@/common/service-clients/generated-clients";
import { ConnectionFacade } from "@/features/application-wizard/backend-wrapper/connection-facade";
import { IConnectionFacade } from "./connection-facade.interface";
import { IApplicationFacade } from "./application-facade.interface";
import { ApplicationFacade } from "./application-facade";
import { ModelFacade } from "./model-facade";
import { IModelFacade } from "./model-facade.interface";
import { LongRunningWorkServiceClient } from "@/common/service-clients/long-running-work-service-client";
import { IPortalFacade } from "./portal-facade.interface";
import { PortalFacade } from "./portal-facade";
import { IReportFacade } from "./report-facade-interface";
import { ReportFacade } from "./report-facade";

export function useBackendDependencies() {
  const axiosInstance = inject("axiosInstance", null) as AxiosInstance;
  const usedServiceClients = getUsedServiceClients(axiosInstance);

  let connectionFacade = inject("connectionFacade", null) as IConnectionFacade;
  if (!connectionFacade) {
    connectionFacade = new ConnectionFacade(
      usedServiceClients.connectionsClient,
      usedServiceClients.connectionSetGroupsClient,
      usedServiceClients.connectionSetsClient,
      usedServiceClients.connectionGroupsClient
    );
    provide("connectionFacade", connectionFacade);
  }

  let applicationFacade = inject("applicationFacade", null) as IApplicationFacade;
  if (!applicationFacade) {
    applicationFacade = new ApplicationFacade(
      usedServiceClients.applicationsClient,
      usedServiceClients.applicationVersionsClient,
      usedServiceClients.publishedApplicationsClient,
      usedServiceClients.applicationGroupsClient,
      usedServiceClients.publishedApplicationGroupsClient
    );
    provide("applicationFacade", applicationFacade);
  }

  let modelFacade = inject("modelFacade", null) as IModelFacade;
  if (!modelFacade) {
    modelFacade = new ModelFacade(
      usedServiceClients.publishedApplicationsClient,
      usedServiceClients.semanticModelClient,
      usedServiceClients.dm7ApplicationsClient,
      usedServiceClients.reportGroupsClient,
      usedServiceClients.reportsClient,
      usedServiceClients.dashboardsClient,
      usedServiceClients.longRunningWorkClient,
      usedServiceClients.reportingItemsServiceClient
    );
    provide("modelFacade", modelFacade);
  }

  let reportFacade = inject("reportFacade", null) as IReportFacade;
  if (!reportFacade) {
    reportFacade = new ReportFacade(
      usedServiceClients.semanticModelClient,
      usedServiceClients.reportsClient
    );
    provide("reportFacade", reportFacade);
  }

  let portalFacade = inject("portalFacade", null) as IPortalFacade;
  if (!portalFacade) {
    portalFacade = new PortalFacade(usedServiceClients.portalServiceClient);
    provide("portalFacade", portalFacade);
  }

  return {
    connectionFacade,
    applicationFacade,
    modelFacade,
    reportFacade,
    portalFacade,
  };

  function getUsedServiceClients(axiosInstance: AxiosInstance) {
    return {
      // for ConnectionFacade:
      connectionsClient: new ConnectionsServiceClient(null, axiosInstance),
      connectionGroupsClient: new ConnectionGroupsServiceClient(null, axiosInstance),
      connectionSetsClient: new ConnectionSetsServiceClient(null, axiosInstance),
      connectionSetGroupsClient: new ConnectionSetGroupsServiceClient(
        null,
        axiosInstance
      ),

      // for ApplicationFacade:
      applicationsClient: new ApplicationsServiceClient(null, axiosInstance),
      applicationVersionsClient: new ApplicationVersionsServiceClient(
        null,
        axiosInstance
      ),
      publishedApplicationsClient: new PublishedApplicationsServiceClient(
        null,
        axiosInstance
      ),
      applicationGroupsClient: new ApplicationGroupsServiceClient(null, axiosInstance),
      publishedApplicationGroupsClient: new PublishedApplicationGroupsServiceClient(
        null,
        axiosInstance
      ),
      dm7ApplicationsClient: new Dm7ApplicationsServiceClient(null, axiosInstance),

      // for ModelFacade:
      semanticModelClient: new SemanticModelsServiceClient(null, axiosInstance),
      reportGroupsClient: new ReportGroupsServiceClient(null, axiosInstance),
      reportsClient: new ReportsServiceClient(null, axiosInstance),
      dashboardsClient: new DashboardsServiceClient(null, axiosInstance),
      longRunningWorkClient: new LongRunningWorkServiceClient({ delay: 1500 }),
      reportingItemsServiceClient: new ReportingItemsServiceClient(null, axiosInstance),

      // for PortalFacade:
      portalServiceClient: new PortalsServiceClient(null, axiosInstance),
    };
  }
}
