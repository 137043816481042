<script lang="ts">
import AppWizardButtons from "../helper-components/app-wizard-buttons.vue";
import AppWizardInstruction from "../helper-components/app-wizard-instruction.vue";
import { BcDropdown, BcInput } from "@bissantz/controls";
import AppWizardTabTemplate from "../helper-components/app-wizard-tab-template.vue";
import AppWizardSpinner from "../helper-components/app-wizard-spinner.vue";
import AppWizardError from "../helper-components/app-wizard-error.vue";
import LabelWithTooltip from "../helper-components/label-with-tooltip.vue";
import { reactive, defineComponent, watch, computed, onBeforeMount } from "vue";
import { AppWizardCommon } from "../app-wizard-common";
import { appResources } from "@/app-resources";
import { DatasourceVm } from "../view-models/datasource-vm";
import WizardInput from "../helper-components/wizard-input.vue";
import { BcInputUniqueValidator } from "../validators/bc-input-unique-validator";

export default defineComponent({
  components: {
    AppWizardTabTemplate,
    AppWizardInstruction,
    AppWizardButtons,
    BcDropdown,
    BcInput,
    AppWizardSpinner,
    AppWizardError,
    LabelWithTooltip,
    WizardInput,
  },

  emits: [AppWizardCommon.events.confirmed, AppWizardCommon.events.cancelled],

  props: {
    viewModel: { type: DatasourceVm, required: true },
    showTabTemplate: Boolean,
  },

  setup(props, context) {
    const state = reactive({
      appWizardTexts: appResources.applicationWizardTexts,
      connectionError: "",
    });

    const textsError = new Map<string, string>([
      ["valueMissing", state.appWizardTexts.general_errorMinValue],
      ["invalidValue", state.appWizardTexts.datasource_errorConnectionString],
      [
        BcInputUniqueValidator.NotUniqueErrorCode,
        state.appWizardTexts.general_errorUniqueNameRequired,
      ],
    ]);

    onBeforeMount(async () => {
      await props.viewModel.init("+ " + state.appWizardTexts.datasource_newConnection);
    });

    const isNotLoadingDataAndNoError = computed(() => {
      return props.showTabTemplate && !state.connectionError;
    });

    const userNotAllowedToAddANewConnectionReason = computed(() => {
      return props.viewModel.userNotAllowedToCreateConnectionReason ===
        "NoConnectionSetGroupExistWhereUserIsAdmin"
        ? state.appWizardTexts.datasource_missingConnectionSetGroup
        : "";
    });

    async function onConnectionConfirmed() {
      if (!props.viewModel.isInAddNewConnectionMode) {
        context.emit(AppWizardCommon.events.confirmed);
      } else {
        let result = await props.viewModel.createNewConnectionAsync();
        if (!result) {
          state.connectionError = state.appWizardTexts.datasource_errorBody;
        } else {
          context.emit(AppWizardCommon.events.confirmed);
        }
      }
    }

    async function onWizardCancelled() {
      context.emit(AppWizardCommon.events.cancelled);
    }

    watch(
      () => props.viewModel.isInAddNewConnectionMode,
      async () => await props.viewModel.checkIfUserIsAllowedToCreateNewConnection()
    );

    return {
      // general
      state,
      textsError,

      // emits:
      AppWizardCommon,

      //functions:
      onConnectionConfirmed: onConnectionConfirmed,
      onWizardCancelled: onWizardCancelled,

      //computed:
      userNotAllowedToAddANewConnectionReason: userNotAllowedToAddANewConnectionReason,
      isNotLoadingDataAndNoError,
    };
  },
});
</script>

<template>
  <div class="application-wizard_datasource application-wizard_tab">
    <AppWizardTabTemplate v-show="isNotLoadingDataAndNoError" class="padding-template">
      <template v-slot:inputFields>
        <div class="input-container">
          <LabelWithTooltip
            v-bind:labelText="state.appWizardTexts.datasource_connection"
            v-bind:tooltipText="state.appWizardTexts.datasource_infotextConnection"
          />
          <BcDropdown
            class="lastDropdownItem center"
            type="select"
            design="7"
            v-model="$props.viewModel.selectedConnection"
            v-bind:numberOfVisibleItems="5"
            v-bind:items="$props.viewModel.connections"
            v-bind:disabled="$props.viewModel.isConnectionSelectionDisabled"
            itemKeyProperty="id"
            itemTextProperty="name"
            v-bind:placeholder="state.appWizardTexts.general_select"
          />
        </div>
        <div
          class="missing-group"
          v-if="$props.viewModel.userNotAllowedToCreateConnection"
        >
          {{ userNotAllowedToAddANewConnectionReason }}
        </div>
        <div class="input-container" v-show="$props.viewModel.isInAddNewConnectionMode">
          <LabelWithTooltip
            v-bind:labelText="state.appWizardTexts.datasource_connectionGroup"
            v-bind:tooltipText="state.appWizardTexts.datasource_infotextConnectionGroup"
          />
          <BcDropdown
            class="center"
            type="select"
            design="7"
            v-model="$props.viewModel.selectedConnectionGroup"
            v-bind:numberOfVisibleItems="5"
            v-bind:items="$props.viewModel.connectionGroups"
            v-bind:errors="textsError"
            itemKeyProperty="id"
            itemTextProperty="name"
            v-bind:placeholder="state.appWizardTexts.general_select"
          />
        </div>
        <div class="missing-group" v-if="$props.viewModel.isConnectionGroupMissing">
          {{ state.appWizardTexts.datasource_missingConnectionGroup }}
        </div>
        <div class="input-container" v-show="$props.viewModel.isInAddNewConnectionMode">
          <LabelWithTooltip
            v-bind:labelText="state.appWizardTexts.datasource_connectionName"
            v-bind:tooltipText="state.appWizardTexts.datasource_infotextConnectionName"
          />
          <WizardInput
            class="center"
            fullWidth
            v-model="$props.viewModel.enteredConnectionName"
            v-bind:required="true"
            v-bind:fitContent="true"
            v-bind:errors="textsError"
            v-bind:uniqueValidator="$props.viewModel.connectionNameValidator"
          />
        </div>
        <div class="input-container" v-show="$props.viewModel.isInAddNewConnectionMode">
          <LabelWithTooltip
            v-bind:labelText="state.appWizardTexts.datasource_connectionString"
            v-bind:tooltipText="state.appWizardTexts.datasource_infotextConnectionString"
          />
          <BcInput
            class="center"
            design="7"
            fullWidth
            v-model="$props.viewModel.enteredConnectionString"
            v-bind:required="true"
            v-bind:fitContent="true"
            v-bind:errors="textsError"
            v-bind:customValidation="$props.viewModel.isValidConnectionString"
          />
        </div>
      </template>
      <template v-slot:infotext>
        <AppWizardInstruction
          v-show="!$props.viewModel.isInAddNewConnectionMode"
          v-bind:instructionText="state.appWizardTexts.datasource_instructionText"
        />
      </template>
      <template v-slot:buttons>
        <AppWizardButtons
          v-on:confirmed="onConnectionConfirmed"
          v-on:cancelled="onWizardCancelled"
          v-bind:disabled="!$props.viewModel.canContinueToNextStep"
        />
      </template>
    </AppWizardTabTemplate>
    <AppWizardError
      v-show="!!state.connectionError"
      v-bind:errorHeader="state.appWizardTexts.datasource_errorHeader"
      v-bind:errorBody="state.connectionError"
    />
    <AppWizardSpinner v-show="$props.viewModel.isSendingDataToBackend" />
  </div>
</template>

<style lang="less" scoped>
@import "./tabs.less";

.application-wizard_datasource {
  .padding-template {
    padding: 75px clamp(50px, 9vw, 100px) 0 clamp(50px, 9vw, 100px);
  }
}
</style>
