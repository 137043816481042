// Copied and edited from:
// https://stackoverflow.com/questions/49216952/how-to-work-with-timespan-in-typescript

const MILLIS_PER_SECOND = 1000;
const MILLIS_PER_MINUTE = MILLIS_PER_SECOND * 60; //     60,000
const MILLIS_PER_HOUR = MILLIS_PER_MINUTE * 60; //  3,600,000
const MILLIS_PER_DAY = MILLIS_PER_HOUR * 24; // 86,400,000

/**
 * A .NET like timeSpan implementation
 */
export class BissantzTimeSpan {
  private _millis: number;

  private static _interval(value: number, scale: number): BissantzTimeSpan {
    if (Number.isNaN(value)) {
      throw new Error("value can't be NaN");
    }

    const tmp = value * scale;
    const millis = BissantzTimeSpan._round(tmp + (value >= 0 ? 0.5 : -0.5));
    if (
      millis > BissantzTimeSpan.maxValue.totalMilliseconds ||
      millis < BissantzTimeSpan.minValue.totalMilliseconds
    ) {
      throw new Error("TimeSpanTooLong");
    }

    return new BissantzTimeSpan(millis);
  }

  private static _round(n: number): number {
    if (n < 0) {
      return Math.ceil(n);
    } else if (n > 0) {
      return Math.floor(n);
    }

    return 0;
  }

  private static _timeToMilliseconds(
    hour: number,
    minute: number,
    second: number
  ): number {
    const totalSeconds = hour * 3600 + minute * 60 + second;
    if (
      totalSeconds > BissantzTimeSpan.maxValue.totalSeconds ||
      totalSeconds < BissantzTimeSpan.minValue.totalSeconds
    ) {
      throw new Error("TimeSpanTooLong");
    }

    return totalSeconds * MILLIS_PER_SECOND;
  }

  static get zero(): BissantzTimeSpan {
    return new BissantzTimeSpan(0);
  }

  static get maxValue(): BissantzTimeSpan {
    return new BissantzTimeSpan(Number.MAX_SAFE_INTEGER);
  }

  static get minValue(): BissantzTimeSpan {
    return new BissantzTimeSpan(Number.MIN_SAFE_INTEGER);
  }

  static fromDays(value: number): BissantzTimeSpan {
    return BissantzTimeSpan._interval(value, MILLIS_PER_DAY);
  }

  static fromHours(value: number): BissantzTimeSpan {
    return BissantzTimeSpan._interval(value, MILLIS_PER_HOUR);
  }

  static fromMilliseconds(value: number): BissantzTimeSpan {
    return BissantzTimeSpan._interval(value, 1);
  }

  static fromMinutes(value: number): BissantzTimeSpan {
    return BissantzTimeSpan._interval(value, MILLIS_PER_MINUTE);
  }

  static fromSeconds(value: number): BissantzTimeSpan {
    return BissantzTimeSpan._interval(value, MILLIS_PER_SECOND);
  }

  static fromTime(hours: number, minutes: number, seconds: number): BissantzTimeSpan;
  static fromTime(
    days: number,
    hours: number,
    minutes: number,
    seconds: number,
    milliseconds: number
  ): BissantzTimeSpan;
  static fromTime(
    daysOrHours: number,
    hoursOrMinutes: number,
    minutesOrSeconds: number,
    seconds?: number,
    milliseconds?: number
  ): BissantzTimeSpan {
    if (milliseconds != undefined) {
      return this._fromTimeStartingFromDays(
        daysOrHours,
        hoursOrMinutes,
        minutesOrSeconds,
        seconds,
        milliseconds
      );
    } else {
      return this._fromTimeStartingFromHours(
        daysOrHours,
        hoursOrMinutes,
        minutesOrSeconds
      );
    }
  }

  private static _fromTimeStartingFromHours(
    hours: number,
    minutes: number,
    seconds: number
  ): BissantzTimeSpan {
    const millis = BissantzTimeSpan._timeToMilliseconds(hours, minutes, seconds);
    return new BissantzTimeSpan(millis);
  }

  private static _fromTimeStartingFromDays(
    days: number,
    hours: number,
    minutes: number,
    seconds: number,
    milliseconds: number
  ): BissantzTimeSpan {
    const totalMilliSeconds =
      days * MILLIS_PER_DAY +
      hours * MILLIS_PER_HOUR +
      minutes * MILLIS_PER_MINUTE +
      seconds * MILLIS_PER_SECOND +
      milliseconds;

    if (
      totalMilliSeconds > BissantzTimeSpan.maxValue.totalMilliseconds ||
      totalMilliSeconds < BissantzTimeSpan.minValue.totalMilliseconds
    ) {
      throw new Error("TimeSpanTooLong");
    }
    return new BissantzTimeSpan(totalMilliSeconds);
  }

  constructor(millis: number) {
    this._millis = millis;
  }

  get days(): number {
    return BissantzTimeSpan._round(this._millis / MILLIS_PER_DAY);
  }

  get hours(): number {
    return BissantzTimeSpan._round((this._millis / MILLIS_PER_HOUR) % 24);
  }

  get minutes(): number {
    return BissantzTimeSpan._round((this._millis / MILLIS_PER_MINUTE) % 60);
  }

  get seconds(): number {
    return BissantzTimeSpan._round((this._millis / MILLIS_PER_SECOND) % 60);
  }

  get milliseconds(): number {
    return BissantzTimeSpan._round(this._millis % 1000);
  }

  get totalDays(): number {
    return this._millis / MILLIS_PER_DAY;
  }

  get totalHours(): number {
    return this._millis / MILLIS_PER_HOUR;
  }

  get totalMinutes(): number {
    return this._millis / MILLIS_PER_MINUTE;
  }

  get totalSeconds(): number {
    return this._millis / MILLIS_PER_SECOND;
  }

  get totalMilliseconds(): number {
    return this._millis;
  }

  add(ts: BissantzTimeSpan): BissantzTimeSpan {
    const result = this._millis + ts.totalMilliseconds;
    return new BissantzTimeSpan(result);
  }

  subtract(ts: BissantzTimeSpan): BissantzTimeSpan {
    const result = this._millis - ts.totalMilliseconds;
    return new BissantzTimeSpan(result);
  }

  get timeOfDay(): string {
    const fillZero = BissantzTimeSpan._padLeadingZero.bind(null, 2);
    const result = `${fillZero(this.hours)}:${fillZero(this.minutes)}:${fillZero(
      this.seconds
    )}`;
    return result;
  }

  private static _padLeadingZero(targetDigitCount: number, value: number): string {
    let result = value.toString();
    while (result.length < targetDigitCount) {
      result = "0" + result;
    }
    return result;
  }
}
