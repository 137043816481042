import { DashboardVm } from "../view-models/dashboard-vm";
import { inject, onBeforeUnmount, watch } from "vue";
import { ISyncerRegistrationService } from "@/services/view-state-service/contract/syncer-registration-service.interface";
import { VsScopeState } from "@/services/view-state-service/vs-scope-state";
import { DashboardVsSyncer } from "./vs-syncer";
import { IVsSyncer } from "@/services/view-state-service/vs-syncer-base";
import { ViewStateFacade } from "@/features/dashboard/backend-wrapper/view-state-facade";

// TODO: make sure that vs-system registration stays optional for Testing:View-VM
export function useRegisterVsSyncer(
  dashboardVm: DashboardVm,
  dashboardViewStateFacade: ViewStateFacade
): void {
  let vsSyncer: IVsSyncer = null;
  const syncerRegistrationService = inject<ISyncerRegistrationService>(
    "syncerRegistrationService",
    null
  );

  const vsScopeState = inject<VsScopeState>("vsScopeState", null);
  if (!syncerRegistrationService) {
    return;
  }

  if (vsScopeState.isInError.value) {
    return;
  }
  vsSyncer = startSyncer();

  onBeforeUnmount(() => {
    killSyncer();
  });

  function startSyncer(): IVsSyncer {
    const vsSyncer: IVsSyncer = new DashboardVsSyncer(
      dashboardVm,
      vsScopeState,
      dashboardViewStateFacade
    );

    syncerRegistrationService.register(vsSyncer);
    return vsSyncer;
  }

  function killSyncer(): void {
    if (!vsSyncer) {
      return;
    }

    syncerRegistrationService.unregister(vsSyncer);
    vsSyncer.dispose();
    vsSyncer = null;
  }

  function onDashboardErrorChanged(): void {
    if (dashboardVm?.error) {
      killSyncer();
    } else if (!vsSyncer) {
      startSyncer();
    }
  }

  watch(() => dashboardVm?.error, onDashboardErrorChanged);
}
