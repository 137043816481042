import { createCommonAxiosInstance, smartformsStore } from "@bissantz/smartforms";
import Axios, { AxiosInstance } from "axios";
import { logHttpRequest, logHttpResponse } from "./logging/logging-instance";

let axiosInstance: AxiosInstance;

/**
 * Returns the common axios instance that must be for all requests (not sent by SmartForms itself).
 */
export function getCommonAxiosInstance(): AxiosInstance {
  if (axiosInstance) return axiosInstance;

  if (!smartformsStore.application) {
    // When SmartForms is not yet initialized, just use the static Axios instance
    return Axios;
  }

  axiosInstance = createCommonAxiosInstance(
    smartformsStore.application,
    smartformsStore.application.router.options.base
  ); // generated proxies already include /api
  axiosInstance.interceptors.request.use(logHttpRequest); // TODO: logging also for requests from SmartForms data provider
  axiosInstance.interceptors.response.use(logHttpResponse);

  // re-map errors into problem details:
  axiosInstance.interceptors.response.use(
    (resp) => resp,
    (error) => {
      if (error?.response) {
        error.response.data = {
          status: error?.response?.status,
          detail: error?.response?.statusText,
        };
      }
      return error?.response ?? error;
    }
  );

  return axiosInstance;
}
