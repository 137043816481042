<script lang="ts">
import Dashboard from "@/features/dashboard/dashboard.vue";
import ActionBar from "./dashboard-action-bar.vue";
import { useContextMenuItems } from "@/features/portal/tile-pages/dashboard/context-menu-items.cpsl";

import { appResources } from "@/app-resources";
import { LiveFeatureTogglesVm } from "@/features/live-feature-toggles/live-feature-toggles-vm";
import { formatResourceString } from "@/common/formatting/textFormatting";
import { delay } from "@/common/helper/async-helper";
import { SparklineCommon } from "@/features/dashboard-shared/sparkline";
import { TileFacade } from "../../backend-wrapper/tile-facade";

import {
  computed,
  defineComponent,
  inject,
  onMounted,
  PropType,
  reactive,
  Ref,
  ref,
  watch,
} from "vue";
import { DashboardPageVm } from "./dashboard-page-vm";
import { ITileService } from "../tile-service.interface";
import { IScalingContext } from "../scaling-context.interface";
import { IScalable } from "@/common/formatting/scalable.interface";
import { ValueVm } from "@/features/dashboard/view-models/value-vm";

class DashboardPageState {
  textResources = appResources.portalTexts;
  appNameForErrors: string = "";
  sparklineAnimationRunning: Promise<void> = null;
  isPreviewEnabled = true;
}

export default defineComponent({
  components: {
    ActionBar,
    Dashboard,
  },

  emits: [],

  props: {
    pageVm: { type: Object as PropType<DashboardPageVm>, required: true },
  },

  setup: function (props) {
    //
    // Injections:
    // ------------------
    const tileFacade: TileFacade = inject<TileFacade>("tileFacade");
    const liveToggles = inject("liveToggles") as LiveFeatureTogglesVm;
    const tileService = inject<Ref<ITileService>>("tileService");
    const globalScalingContext = inject<Ref<IScalingContext>>("globalScalingContext");
    //
    // DOM/Comp. refs:
    // ------------------
    const ref_componentElem = ref<HTMLDivElement>();

    // State:
    const state = reactive(new DashboardPageState());

    const contextMenuItemsCpsl = useContextMenuItems(
      props.pageVm.dashboardVm,
      props.pageVm.dashboardSelection
    );

    // Life cycle:
    // ------------------
    onMounted(() => {
      applyLiveToggles();

      // TODO: still relevant? Perhaps cloning vs normal init?
      if (!props.pageVm.dashboardVm.isInitialized) {
        return;
      }

      onDashboardInitializedChanged();
    });

    //
    // Computeds:
    // ------------------
    const isExtended = computed<boolean>(() => {
      return props.pageVm.dashboardVm?.dbSettings.isExtended;
    });

    const showErrorInPage = computed<boolean>(() => {
      const shouldShowError =
        props.pageVm.dashboardVm.error === "TimedOut" ||
        props.pageVm.dashboardVm.error === "BackendIsLoading";

      return shouldShowError;
    });

    const hasAnyError = computed<boolean>(() => {
      return tileService.value?.isInError || !!props.pageVm.dashboardVm.error;
    });

    const errorMessage = computed<string>(() => {
      let errorMsg = null;
      if (props.pageVm.dashboardVm?.error) {
        errorMsg = getDBError();
      }

      return errorMsg;
    });

    const globalSparklinesEnabled = computed<boolean>(() => {
      return liveToggles?.isEnabled && liveToggles.toggleValues.sparkline_globalScaling;
    });

    const sparklineAnimationFeatureEnabled = computed<boolean>(() => {
      return liveToggles?.isEnabled && liveToggles.toggleValues.sparkline_animations;
    });

    //
    // Methods:
    // ------------------

    // TODO: DB-Only
    function onRetryLoadDashboardClick(): void {
      props.pageVm.dashboardVm.error = null;
      props.pageVm.dashboardVm.isInitialized = false;
      props.pageVm.dashboardVm.retryInitializeAsync();
    }

    function getDBError(): string {
      if (props.pageVm.dashboardVm.error === "InvalidDashboardId") {
        return state.textResources.errorInvalidDashboardId;
      } else if (props.pageVm.dashboardVm.error === "NotFound") {
        updateAppName();
        const appText = state.appNameForErrors ? ` '${state.appNameForErrors}'` : "";
        return formatResourceString(state.textResources.errorApplicationHasNoDbFile, {
          appName: appText,
        });
      } else if (props.pageVm.dashboardVm.error === "NoPermission") {
        const appText = state.appNameForErrors ? ` '${state.appNameForErrors}'` : "";
        return formatResourceString(state.textResources.errorTileViewPermission, {
          appName: appText,
        });
      } else if (props.pageVm.dashboardVm.error === "TimedOut") {
        return state.textResources.errorTimeout;
      } else if (props.pageVm.dashboardVm.error === "BackendIsLoading") {
        return state.textResources.errorBackendIsLoading;
      }

      return state.textResources.errorUnkownTileError;
    }

    async function updateAppName() {
      if (state.appNameForErrors) {
        return;
      }

      const pubAppId = props.pageVm.pubAppId;
      state.appNameForErrors = await tileFacade.getPubAppName(pubAppId);
    }

    function applyLiveToggles(): void {
      if (!liveToggles?.isEnabled) {
        return;
      }

      const sparklinesState = props.pageVm.dashboardVm?.sharedState?.sparklineState;
      if (!sparklinesState) {
        return;
      }

      const toggleValues = liveToggles.toggleValues;

      sparklinesState.globalSparklinesEnabled = toggleValues.sparkline_globalScaling;
      sparklinesState.animationsPossible = toggleValues.sparkline_animations;
      sparklinesState.deltaSparklinesEnabled = toggleValues.sparkline_deltaValues;
    }

    function onWidthChanged(): void {
      if (tileService.value.width > 2) {
        props.pageVm.dashboardVm.dbSettings.isExtended = true;
      } else {
        props.pageVm.dashboardVm.dbSettings.isExtended = false;
      }
    }

    function onDashboardInitializedChanged(): void {
      const isInitialized = props.pageVm.dashboardVm.isInitialized;
      props.pageVm.isInitialized = isInitialized;
      props.pageVm.name = props.pageVm.dashboardVm?.dashboardFm?.name ?? null;

      if (isInitialized) {
        props.pageVm.scaleContextValues.splice(0, props.pageVm.scaleContextValues.length);

        props.pageVm.scaleContextValues.push(...getScalingValueCopies());
      }
    }

    function getScalingValueCopies(): IScalable[] {
      const dashboardVm = props.pageVm.dashboardVm;
      const shonwKpi = dashboardVm.shownKpiTileVms;
      const sharedDbState = dashboardVm.sharedState;

      const portalScaledValueReferences: ValueVm[] = shonwKpi.map((kpiTile) =>
        kpiTile.currentValueGroup.getScaledValue(sharedDbState)
      );

      return portalScaledValueReferences.map((v) => {
        const partialCopy = new ValueVm();
        partialCopy.updateFrom(v);
        return partialCopy;
      });
    }

    function onSparklineModeChanged(): void {
      state.sparklineAnimationRunning = delay(
        SparklineCommon.generalAnimationMaxDuration
      );
    }

    function onMaxNumKpiValuesChanged(): void {
      if (!props.pageVm.dashboardVm.isVsSyncReady) {
        return;
      }

      if (props.pageVm.dashboardVm?.maxNumKpiValues > 1) {
        return;
      }

      tileService.value?.setWidth(2);
    }

    function onIsSelectedChanged(): void {
      props.pageVm.dashboardSelection.isDashboardSelected = props.pageVm.isSelected;
    }

    function tryApplyScalingContext(): void {
      const dashboardVm = props.pageVm.dashboardVm;

      if (!dashboardVm || !dashboardVm.isInitialized) {
        return;
      }

      const showsDrill = dashboardVm.showsAnyStructureElements;
      const showsAllKpi =
        !dashboardVm.dbSettings.applyKpiIdFilter && dashboardVm.hasEnoughKpis;

      const mustUseDashboardScaling =
        showsDrill || showsAllKpi || !globalScalingContext.value.allInitialized;
      if (mustUseDashboardScaling) {
        dashboardVm.fontScaler.presetScalingContext = null;
      } else if (!mustUseDashboardScaling) {
        dashboardVm.fontScaler.presetScalingContext = globalScalingContext.value.values;
      }

      dashboardVm.refreshColorAndScaling();
    }

    function onScalingContextAvailable(): void {
      if (!globalScalingContext.value.allInitialized) {
        return;
      }

      tryApplyScalingContext();
    }

    function onAnimationsEnabledChanged() {
      const animationFeatureAllowed = sparklineAnimationFeatureEnabled.value;

      props.pageVm.dashboardVm.sharedState.sparklineState.animationsCurrentlyEnabled =
        tileService.value.isAnimationsEnabled && animationFeatureAllowed;
    }

    function isEditingChanged(): void {
      state.isPreviewEnabled = !tileService.value.isInEdit;
    }

    // Watchers:
    // -----------------
    watch(() => props.pageVm.isSelected, onIsSelectedChanged);
    watch(
      () => props.pageVm.dashboardSelection.selectionColor,
      () => (props.pageVm.weatherColor = props.pageVm.dashboardSelection.selectionColor)
    );
    watch(() => tileService.value.width, onWidthChanged, { immediate: true });
    watch(() => props.pageVm.dashboardVm?.isInitialized, onDashboardInitializedChanged);

    watch(
      () => props.pageVm.dashboardVm?.sharedState.sparklineState.mode,
      onSparklineModeChanged
    );
    watch(() => props.pageVm.dashboardVm?.maxNumKpiValues, onMaxNumKpiValuesChanged);
    watch(() => globalScalingContext.value.allInitialized, onScalingContextAvailable, {
      immediate: true,
    });
    watch(
      [
        () => props.pageVm.dashboardVm?.dbSettings.applyKpiIdFilter,
        () => props.pageVm.dashboardVm?.showsAnyStructureElements,
      ],
      tryApplyScalingContext
    );
    watch(() => tileService.value.isAnimationsEnabled, onAnimationsEnabledChanged, {
      immediate: true,
    });

    watch(() => tileService.value.isInEdit, isEditingChanged);

    return {
      // data
      state,
      tileService,
      contextMenuItemsCpsl,

      // Injections, State & Refs:
      ref_componentElem,

      // Computeds:
      showErrorInPage,
      hasAnyError,
      errorMessage,
      globalSparklinesEnabled,
      isExtended,

      // Methods:

      // Event Handler:
      onRetryLoadDashboardClick,
    };
  },
});
</script>

<template>
  <div ref="ref_componentElem" class="dashboard-page-component" v-if="$props.pageVm">
    <!-- ACTIONS -->
    <ActionBar
      v-if="!hasAnyError"
      v-bind:pageVm="$props.pageVm"
      v-bind:sparklineAnimationRunning="state.sparklineAnimationRunning"
      v-bind:isPreviewEnabled="state.isPreviewEnabled"
      v-on="$listeners"
    />
    <div v-else class="error-action-bar" />

    <div
      class="result-area"
      v-bind:class="{
        'is-extended-width': isExtended,
      }"
    >
      <!-- ERRORS  -->
      <div v-if="errorMessage" class="tile-page-error">
        <div class="error-text">
          {{ errorMessage }}
        </div>
        <div
          class="retry-button"
          v-if="showErrorInPage"
          v-on:click="onRetryLoadDashboardClick"
        />
      </div>

      <!-- MAIN CONTENT -->
      <Dashboard
        v-bind:dashboardSelection="$props.pageVm.dashboardSelection"
        v-bind:dashboardVm="$props.pageVm.dashboardVm"
        v-bind:enableViewStates="tileService.isViewStatesEnabled"
        v-bind:globalSparklinesEnabled="globalSparklinesEnabled"
        v-on:mousedown.native="contextMenuItemsCpsl.clickHelper.mouseDown($event)"
        v-on:mouseup.native="contextMenuItemsCpsl.clickHelper.mouseUp($event)"
        v-on:touchstart.native="contextMenuItemsCpsl.touchHelper.touchStart($event)"
        v-on:touchmove.native="contextMenuItemsCpsl.touchHelper.cancelTouch($event)"
        v-on:touchend.native="contextMenuItemsCpsl.touchHelper.touchEnd($event)"
        v-on="$listeners"
      />
    </div>
  </div>
</template>

<style scoped lang="less">
.dashboard-page-component {
  background-color: var(--color_bg_white);

  font-size: var(--hostedFontSize);
  position: relative;
  // TODO: clean up and bundle code for borders
  border-radius: 0 0 6px 6px;

  .error-action-bar {
    width: 100%;
    height: 27px;
    border-bottom: 1px solid var(--color_bg-gray);
  }

  .result-area {
    padding: 6px;
    padding-top: 0;

    &.is-extended-width {
      padding-left: 0;
      padding-right: 0;
    }

    .tile-page-error {
      white-space: normal;
      height: 82px;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      padding: 0 2em;

      .error-text {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--color_neutralText);
      }

      .retry-button {
        cursor: pointer;
        margin-top: 1em;
        height: 2em;
        width: 2em;

        background-image: var(--icon_update);
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
}
</style>

<style lang="less">
.dashboard-page-component {
  // TODO: clean up and bundle code for borders
  & > .dashboard > :last-child .valueGroup:not(.showsDrill) {
    .kpiFrame.backgroundImage {
      border-radius: 0 0 4px 4px;
    }
  }
}
</style>
