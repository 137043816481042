import { IVsSyncer } from "@/services/view-state-service/vs-syncer-base";
import { ISyncerRegistrationService } from "@/services/view-state-service/contract/syncer-registration-service.interface";

import { onBeforeUnmount, provide } from "vue";

/**
 * Defines a lifetime scope for syncers so that their
 * lifetime is independent of the view.
 */
export function useVsSyncerScope() {
  const state = {
    vsSyncer: new Array<IVsSyncer>(),
  };

  const syncerRegistrationService = {
    register(vsSyncer: IVsSyncer): void {
      state.vsSyncer.push(vsSyncer);
    },
    unregister(vsSyncer: IVsSyncer): void {
      // TODO: better block push/'register' during this method call,
      //       or have a async safe list/array
      const idx = state.vsSyncer.findIndex((w) => w.id === vsSyncer.id);
      if (idx > -1) {
        state.vsSyncer.splice(idx, 1);
      }
    },
  };
  provide<ISyncerRegistrationService>(
    "syncerRegistrationService",
    syncerRegistrationService
  );

  onBeforeUnmount(() => {
    removeVsSyncers();
  });

  function removeVsSyncers(): void {
    state.vsSyncer.map((watcher) => watcher.dispose());
    state.vsSyncer.splice(0, state.vsSyncer.length);
  }
}
