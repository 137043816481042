export class LiveToggleItem {
  title: string = "Unnamed";
  getValue: () => boolean = null;
  setValue: (val: boolean) => void = null;
}

export class LiveToggleSection {
  name: string = "Unnamed";
  items: LiveToggleItem[] = [];
}

const storageKey = "dm7_live-toggles";
class ToggleValues {
  sparkline_animations: boolean;
  sparkline_globalScaling: boolean;
  sparkline_deltaValues: boolean;
  dashboardSearch: boolean;
  notificationDemos: boolean;
}

export class LiveFeatureTogglesVm {
  isEnabled = false;

  toggleValues: ToggleValues = new ToggleValues();

  toggleDefinitions: LiveToggleSection[] = [];

  constructor(enable: boolean = false) {
    this.isEnabled = enable;
    this.readValues();
  }

  private _getToggleDefinitions(): LiveToggleSection[] {
    return [
      {
        name: "General",
        items: [
          {
            title: "Notification Demos",
            getValue: () => this.toggleValues.notificationDemos,
            setValue: (val) => (this.toggleValues.notificationDemos = val),
          },
        ],
      },
      {
        name: "Portal Frame",
        items: [
          {
            title: "Dashboard Search",
            getValue: () => this.toggleValues.dashboardSearch,
            setValue: (val) => (this.toggleValues.dashboardSearch = val),
          },
        ],
      },
      {
        name: "Sparklines",
        items: [
          {
            title: "Animations",
            getValue: () => this.toggleValues.sparkline_animations,
            setValue: (val) => (this.toggleValues.sparkline_animations = val),
          },
          {
            title: "Global Scaling",
            getValue: () => this.toggleValues.sparkline_globalScaling,
            setValue: (val) => (this.toggleValues.sparkline_globalScaling = val),
          },
          {
            title: "Delta Values",
            getValue: () => this.toggleValues.sparkline_deltaValues,
            setValue: (val) => (this.toggleValues.sparkline_deltaValues = val),
          },
        ],
      },
    ];
  }

  private _updateValues(otherValues: ToggleValues): void {
    this.toggleValues.sparkline_animations = otherValues.sparkline_animations;
    this.toggleValues.sparkline_globalScaling = otherValues.sparkline_globalScaling;
    this.toggleValues.sparkline_deltaValues = otherValues.sparkline_deltaValues;
    this.toggleValues.dashboardSearch = otherValues.dashboardSearch;
    this.toggleValues.notificationDemos = otherValues.notificationDemos;

    this.toggleDefinitions = this._getToggleDefinitions();
  }

  readValues(): void {
    if (!this.isEnabled) {
      this._updateValues(this._getDefaultValues());
      return;
    }

    const sessionValues = this._readValuesFromSessionStorage();
    if (sessionValues) {
      this._updateValues(sessionValues);
      return;
    }

    const localValues = this._readValuesFromLocalstorage();
    if (localValues) {
      this._updateValues(localValues);
      return;
    }

    this._updateValues(this._getDefaultValues());
  }

  private _readValuesFromLocalstorage(): ToggleValues {
    const storageContent = localStorage.getItem(storageKey);

    if (storageContent) return JSON.parse(storageContent);

    return null;
  }

  private _readValuesFromSessionStorage(): ToggleValues {
    const storageContent = sessionStorage.getItem(storageKey);

    if (storageContent) return JSON.parse(storageContent);

    return null;
  }

  deleteAllStorage(): void {
    this.deleteSessionStorage();
    this.deleteLocalStorage();
  }

  deleteSessionStorage(): void {
    sessionStorage.removeItem(storageKey);
  }

  deleteLocalStorage(): void {
    localStorage.removeItem(storageKey);
  }

  writeValuesToLocalstorage(): void {
    localStorage.setItem(storageKey, JSON.stringify(this.toggleValues));
  }

  writeValuesToSessionStorage(): void {
    sessionStorage.setItem(storageKey, JSON.stringify(this.toggleValues));
  }

  private _getDefaultValues(): ToggleValues {
    const defaults = new ToggleValues();
    defaults.sparkline_animations = true;
    defaults.sparkline_globalScaling = false;
    defaults.sparkline_deltaValues = false;
    defaults.notificationDemos = false;

    return defaults;
  }
}
