﻿import { HttpRequest, HttpResponse, HttpStack, Upload } from "tus-js-client";
import {
  AxiosHeaders,
  AxiosInstance,
  AxiosProgressEvent,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";

type Progress = (value: number) => void;

export class UploadServiceClient {
  private readonly _stack: HttpStack;

  constructor(instance: AxiosInstance) {
    this._stack = new AxiosHttpStack(instance);
  }

  upload(
    file: File,
    metadata?: Record<string, string>,
    progress?: Progress
  ): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const callbacks = {
        onError: reject,
        onProgress: function (uploaded: number, total: number) {
          const percent = (uploaded / total) * 100;
          progress && progress(percent);
        },
        onSuccess: function () {
          resolve(upload.url.substring(upload.url.lastIndexOf("/") + 1));
        },
      };

      // The 'metadata' is validated on the server if all required fields are present.
      // See DeltaMaster.Host/Configuration/PathBaseTusConfiguration.cs for more information
      const upload = new Upload(file, {
        endpoint: "/api/files",
        chunkSize: 30000000,
        retryDelays: [0, 3000, 5000],
        metadata: {
          filename: file.name,
          ...metadata,
        },
        httpStack: this._stack,
        storeFingerprintForResuming: false,
        ...callbacks,
      });

      upload.start();
    });
  }
}

class AxiosHttpStack implements HttpStack {
  constructor(private readonly _instance: AxiosInstance) {}

  createRequest(method: string, url: string): HttpRequest {
    return new AxiosHttpRequest(this._instance, method, url);
  }

  getName(): string {
    return "axios";
  }
}

class AxiosHttpRequest implements HttpRequest {
  private readonly _options: AxiosRequestConfig;
  private readonly _abort: AbortController;

  constructor(private readonly instance: AxiosInstance, method: string, url: string) {
    this._abort = new AbortController();
    this._options = {
      method,
      url,
      signal: this._abort.signal,
      headers: new AxiosHeaders(),
    };
  }

  abort(): Promise<void> {
    this._abort.abort();
    return Promise.resolve();
  }

  getMethod(): string {
    return this._options.method;
  }

  getURL(): string {
    return this._options.url;
  }

  getUnderlyingObject(): object {
    return this._options;
  }

  async send(body: object): Promise<HttpResponse> {
    this._options.data = body;
    const response = await this.instance.request<string>(this._options);
    return new AxiosHttpResponse(response);
  }

  getHeader(header: string): string {
    const headers = this._options.headers as AxiosHeaders;
    return headers.get(header)?.toString();
  }

  setHeader(header: string, value: string): void {
    const headers = this._options.headers as AxiosHeaders;
    headers.set(header, value);
  }

  setProgressHandler(handler: (bytesSent: number) => void): void {
    // this event type depends on the technology which is used by axios
    this._options.onUploadProgress = function (event: AxiosProgressEvent) {
      handler(event.loaded);
    };
  }
}

class AxiosHttpResponse implements HttpResponse {
  constructor(private readonly _response: AxiosResponse<string>) {}

  getBody(): string {
    return this._response.data;
  }

  getHeader(header: string): string {
    header = header.toLowerCase();
    const value = this._response.headers[header];
    if (header === "location") {
      // we must make the path relative to the base path,
      // because the request pipeline will add the base path again
      const base = this._response.config.baseURL;
      return value.replace(base, "/");
    }
    return value;
  }

  getStatus(): number {
    return this._response.status;
  }

  getUnderlyingObject(): object {
    return this._response;
  }
}
