export type ClipboardCopyResult = "success" | "error" | "not-supported";

export async function copyImageToClipboard(img: Blob): Promise<ClipboardCopyResult> {
  const item = new ClipboardItem({ "image/png": img });
  try {
    await navigator.clipboard.write([item]);
  } catch (err) {
    return "error";
  }
  return "success";
}

export function checkCanUseShareAPI(shareData: ShareData) {
  if (!navigator.canShare || !navigator.share) {
    return false;
  }

  try {
    const canShareResult = navigator.canShare(shareData);
    return canShareResult;
  } catch (err) {
    return false;
  }
}

/**
 * IMPORTANT: when using the generated data you MUST apply an any cast like this:
 *
 * await navigator.share(shareData as any);
 *
 * Otherwise sharing fails on iOS with a cryptic sharingNotAllowed error
 */
export function createImageShareData(img: Blob, nameWithoutExtension: string): ShareData {
  // create a file from the blob
  const fileArray = [
    new File([img], nameWithoutExtension + ".png", {
      type: img.type,
      lastModified: new Date().getTime(),
    }),
  ];
  const shareData: ShareData = {
    files: fileArray,
  };
  return shareData;
}

export function downloadImage(img: Blob, nameWithoutExtension: string) {
  const imgFile = new File([img], nameWithoutExtension + ".png", {
    type: img.type,
    lastModified: new Date().getTime(),
  });
  // snippet copied from:
  // https://www.stefanjudis.com/snippets/how-trigger-file-downloads-with-javascript/

  const link = document.createElement("a");
  link.style.display = "none";
  link.href = URL.createObjectURL(imgFile);
  link.download = imgFile.name;

  document.body.appendChild(link);
  link.click();

  setTimeout(() => {
    URL.revokeObjectURL(link.href);
    link.parentNode.removeChild(link);
  }, 10);
}
