<script lang="ts">
import { computed, defineComponent } from "vue";
import type { CSSProperties } from "vue/types/jsx";

export default defineComponent({
  props: {
    mainColor: {
      type: String,
      default: "#ababab",
    },
    svgHeight: {
      type: String,
      default: "15px",
    },
  },

  setup(props) {
    const colorVars = computed<CSSProperties>(() => {
      const usedColor = props.mainColor;
      return {
        "--main-color": usedColor,
      };
    });

    const heightVars = computed<CSSProperties>(() => {
      const svgHeight = props.svgHeight;
      return {
        "--svg-height": svgHeight,
      };
    });

    return {
      colorVars,
      heightVars,
    };
  },
});
</script>

<template>
  <div class="app-wizard-logo" v-bind:style="[colorVars, heightVars]">
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 441.534 36.084"
      xml:space="preserve"
    >
      <g>
        <path
          d="M131.119,18.908h-12.613c-2.691,0-3.697,0.2-4.419,0.563c-0.722,0.363-1.16,0.887-1.379,1.474
		c-0.219,0.586-0.219,1.235-0.024,1.835c0.194,0.6,0.583,1.153,1.339,1.508c0.756,0.356,1.88,0.515,4.483,0.515h12.613V18.908z
		 M128.78,9.877h-18.563V6.076h19.581c3.259,0,5.902,2.466,5.902,5.509v17.018h-18.083c-2.444,0-4.464-0.024-6.303-0.908
		c-1.839-0.884-3.496-2.628-3.335-6.057c0.129-2.182,1.193-3.878,2.805-4.966c1.612-1.087,3.772-1.565,8.048-1.565h12.288V12.06
		C131.119,10.854,130.072,9.877,128.78,9.877"
        />
        <path
          d="M82.836,6.08c-4.302-0.022-5.996,1.375-6.89,2.948c-0.894,1.572-0.987,3.32-0.281,4.833
		c0.707,1.513,2.213,2.791,6.337,3.453c4.465,0.727,8.93,1.453,13.396,2.18c2.662,0.433,3.389,1.91,3.141,3.128
		c-0.247,1.217-1.469,2.176-3.743,2.176c-6.161,0.001-12.499,0.002-18.836,0.003v3.801c6.338,0,12.675-0.001,19.504-0.002
		c3.786-0.021,5.953-1.654,6.98-3.446c1.027-1.792,0.912-3.743,0.144-5.454c-0.768-1.711-2.188-3.184-6.403-3.937
		c-4.597-0.748-9.194-1.496-13.791-2.244c-1.658-0.27-2.467-0.601-2.688-1.444c-0.221-0.842,0.145-2.196,2.408-2.196h19.708V6.08
		H82.836z"
        />
        <polygon
          points="205.754,6.062 205.754,9.862 226.027,9.88 205.151,25.641 205.151,28.603 233.144,28.603 233.144,24.802 
		212.737,24.802 233.144,9.395 233.144,6.062 	"
        />
        <polygon
          points="173.784,6.062 173.784,9.862 185.188,9.862 185.188,28.603 189.769,28.603 189.769,9.862 201.173,9.862 
		201.173,6.062 	"
        />
        <polygon
          points="164.773,6.062 164.773,23.055 145.973,6.062 141.361,6.062 141.361,28.603 145.942,28.603 145.942,11.324 
		165.059,28.603 169.354,28.603 169.354,6.062 	"
        />
        <path
          d="M50.396,6.08c-4.3-0.022-5.994,1.375-6.888,2.948c-0.893,1.572-0.987,3.32-0.281,4.833
		c0.706,1.513,2.213,2.791,6.335,3.453c4.464,0.727,8.928,1.453,13.392,2.18c2.661,0.433,3.388,1.91,3.14,3.128
		c-0.247,1.217-1.469,2.176-3.741,2.176c-6.159,0.001-12.495,0.002-18.83,0.003v3.801c6.336,0,12.672-0.001,19.498-0.002
		c3.785-0.021,5.952-1.654,6.978-3.446c1.026-1.792,0.912-3.743,0.144-5.454c-0.767-1.711-2.188-3.184-6.401-3.937
		c-4.596-0.748-9.191-1.496-13.787-2.244c-1.658-0.27-2.466-0.601-2.687-1.444c-0.221-0.842,0.145-2.196,2.407-2.196h19.703V6.08
		H50.396z"
        />
        <rect x="32.827" y="6.062" width="4.581" height="22.541" />
        <path
          d="M4.581,9.879v5.228h13.316c0.277,0,0.555-0.017,0.829-0.06c0.23-0.035,0.458-0.088,0.679-0.164
		c0.186-0.064,0.367-0.145,0.537-0.247c0.147-0.087,0.285-0.191,0.411-0.31c0.113-0.107,0.215-0.227,0.302-0.36
		c0.083-0.126,0.153-0.262,0.209-0.405c0.113-0.291,0.165-0.609,0.166-0.926c0.001-0.335-0.055-0.67-0.169-0.981
		c-0.058-0.16-0.132-0.313-0.22-0.456c-0.092-0.15-0.201-0.289-0.321-0.413c-0.132-0.137-0.279-0.257-0.436-0.359
		c-0.179-0.117-0.372-0.212-0.569-0.287c-0.235-0.089-0.477-0.151-0.723-0.191c-0.294-0.05-0.592-0.069-0.89-0.069H4.581z
		 M4.581,18.908v5.894h14.798c0.312,0,0.624-0.019,0.933-0.066c0.26-0.039,0.517-0.098,0.767-0.183
		c0.211-0.072,0.416-0.162,0.61-0.277c0.167-0.099,0.326-0.215,0.469-0.35c0.129-0.122,0.246-0.258,0.346-0.407
		c0.095-0.142,0.176-0.297,0.239-0.459c0.129-0.329,0.189-0.688,0.189-1.047c0.001-0.378-0.063-0.755-0.192-1.106
		c-0.066-0.181-0.15-0.354-0.251-0.515c-0.105-0.169-0.229-0.326-0.366-0.466c-0.151-0.154-0.318-0.289-0.497-0.404
		c-0.204-0.132-0.422-0.238-0.646-0.322c-0.265-0.099-0.54-0.168-0.817-0.214c-0.331-0.055-0.667-0.077-1.002-0.076H4.581z
		 M0,28.603V6.078h18.4c0.502,0,1.004,0.026,1.502,0.086c0.483,0.058,0.962,0.147,1.427,0.273c0.463,0.125,0.912,0.286,1.338,0.485
		c0.425,0.198,0.826,0.433,1.19,0.705c0.362,0.269,0.686,0.574,0.968,0.905c0.274,0.323,0.506,0.671,0.688,1.038
		c0.175,0.35,0.304,0.716,0.39,1.09c0.079,0.347,0.119,0.7,0.123,1.053c0.018,2.183-1.041,3.871-2.244,5.061
		c2.515,1.203,4.017,3.088,3.999,5.652c-0.003,0.387-0.044,0.774-0.123,1.154c-0.085,0.409-0.215,0.811-0.39,1.195
		c-0.183,0.401-0.415,0.783-0.689,1.137c-0.281,0.364-0.606,0.698-0.967,0.993c-0.364,0.297-0.766,0.555-1.191,0.773
		c-0.426,0.218-0.875,0.394-1.338,0.531c-0.465,0.138-0.944,0.236-1.427,0.299c-0.497,0.066-1,0.094-2.91,0.094H0z"
        />
      </g>
      <g>
        <path
          d="M274.999,28.603v-2.891h-0.064c-0.535,1.037-1.266,1.842-2.19,2.414c-0.927,0.571-1.973,0.857-3.139,0.857
		c-0.792,0-1.507-0.111-2.144-0.333c-0.637-0.223-1.177-0.538-1.621-0.945s-0.787-0.897-1.027-1.47
		c-0.241-0.571-0.361-1.201-0.361-1.89c0-1.472,0.46-2.645,1.381-3.519c0.92-0.873,2.312-1.442,4.174-1.707l4.992-0.715
		c0-3.123-1.226-4.686-3.676-4.686c-1.916,0-3.692,0.693-5.33,2.081v-2.224c0.343-0.244,0.73-0.463,1.164-0.659
		s0.891-0.365,1.372-0.509c0.482-0.143,0.977-0.254,1.485-0.333c0.508-0.08,1.003-0.119,1.485-0.119c1.83,0,3.213,0.508,4.149,1.524
		s1.404,2.531,1.404,4.543v10.578H274.999z M270.761,20.709c-0.738,0.106-1.37,0.238-1.894,0.397
		c-0.524,0.159-0.958,0.373-1.301,0.644c-0.343,0.27-0.594,0.604-0.755,1c-0.16,0.397-0.24,0.888-0.24,1.47
		c0,0.435,0.077,0.836,0.232,1.207s0.38,0.69,0.675,0.961c0.294,0.27,0.652,0.479,1.075,0.627c0.423,0.148,0.901,0.223,1.437,0.223
		c0.728,0,1.396-0.138,2.007-0.413s1.137-0.656,1.581-1.144c0.444-0.486,0.792-1.069,1.044-1.747
		c0.251-0.678,0.377-1.413,0.377-2.208v-1.604L270.761,20.709z"
        />
        <path
          d="M287.013,25.982v10.102h-2.056V12.338h2.056v3.113h0.063c0.61-1.133,1.426-1.999,2.448-2.597
		c1.022-0.599,2.176-0.897,3.46-0.897c1.049,0,1.987,0.188,2.817,0.563c0.829,0.376,1.53,0.908,2.103,1.597s1.012,1.524,1.316,2.509
		c0.305,0.985,0.458,2.092,0.458,3.32c0,1.345-0.172,2.57-0.514,3.677c-0.343,1.106-0.835,2.057-1.478,2.851
		c-0.642,0.795-1.421,1.411-2.336,1.851s-1.945,0.659-3.09,0.659c-2.312,0-4.04-1.001-5.186-3.002H287.013z M287.013,21.836
		c0,0.741,0.125,1.438,0.377,2.089c0.251,0.651,0.602,1.223,1.052,1.715c0.449,0.493,0.992,0.882,1.629,1.168
		s1.341,0.429,2.111,0.429c0.824,0,1.567-0.172,2.231-0.517c0.663-0.344,1.228-0.833,1.693-1.469s0.824-1.403,1.075-2.303
		c0.252-0.9,0.378-1.906,0.378-3.019c0-0.952-0.118-1.812-0.354-2.58c-0.235-0.769-0.57-1.422-1.003-1.962
		c-0.434-0.54-0.958-0.953-1.573-1.239c-0.616-0.286-1.304-0.429-2.063-0.429c-0.856,0-1.627,0.156-2.312,0.469
		s-1.269,0.738-1.75,1.278c-0.481,0.54-0.851,1.173-1.107,1.898s-0.385,1.495-0.385,2.311V21.836z"
        />
        <path
          d="M308.834,25.982v10.102h-2.056V12.338h2.056v3.113h0.063c0.61-1.133,1.426-1.999,2.448-2.597
		c1.022-0.599,2.176-0.897,3.46-0.897c1.049,0,1.987,0.188,2.817,0.563c0.829,0.376,1.53,0.908,2.103,1.597s1.012,1.524,1.316,2.509
		c0.305,0.985,0.458,2.092,0.458,3.32c0,1.345-0.172,2.57-0.514,3.677c-0.343,1.106-0.835,2.057-1.478,2.851
		c-0.642,0.795-1.421,1.411-2.336,1.851s-1.945,0.659-3.09,0.659c-2.312,0-4.04-1.001-5.186-3.002H308.834z M308.834,21.836
		c0,0.741,0.125,1.438,0.377,2.089c0.251,0.651,0.602,1.223,1.052,1.715c0.449,0.493,0.992,0.882,1.629,1.168
		s1.341,0.429,2.111,0.429c0.824,0,1.567-0.172,2.231-0.517c0.663-0.344,1.228-0.833,1.693-1.469s0.824-1.403,1.075-2.303
		c0.252-0.9,0.378-1.906,0.378-3.019c0-0.952-0.118-1.812-0.354-2.58c-0.235-0.769-0.57-1.422-1.003-1.962
		c-0.434-0.54-0.958-0.953-1.573-1.239c-0.616-0.286-1.304-0.429-2.063-0.429c-0.856,0-1.627,0.156-2.312,0.469
		s-1.269,0.738-1.75,1.278c-0.481,0.54-0.851,1.173-1.107,1.898s-0.385,1.495-0.385,2.311V21.836z"
        />
        <path
          d="M355.415,28.603h-2.055l-3.483-11.944c-0.107-0.37-0.192-0.853-0.257-1.445h-0.064c-0.021,0.159-0.057,0.354-0.104,0.588
		c-0.048,0.232-0.126,0.508-0.232,0.825l-3.837,11.977h-2.007l-4.977-16.265h2.199l3.612,12.611
		c0.106,0.371,0.182,0.842,0.225,1.413h0.129c0.021-0.19,0.056-0.407,0.104-0.65c0.049-0.244,0.115-0.503,0.201-0.778l3.981-12.596
		h1.701l3.564,12.627c0.096,0.36,0.176,0.832,0.24,1.414h0.129c-0.011-0.18,0.01-0.39,0.063-0.628s0.123-0.5,0.209-0.786
		l3.612-12.627h2.07L355.415,28.603z"
        />
        <path
          d="M367.54,8.225c-0.396,0-0.738-0.138-1.027-0.413s-0.434-0.625-0.434-1.049c0-0.413,0.145-0.754,0.434-1.024
		c0.289-0.27,0.631-0.404,1.027-0.404c0.203,0,0.396,0.034,0.578,0.103c0.182,0.069,0.34,0.167,0.474,0.294s0.24,0.278,0.321,0.453
		c0.08,0.175,0.12,0.368,0.12,0.579c0,0.201-0.038,0.393-0.112,0.572c-0.075,0.18-0.183,0.336-0.321,0.469
		c-0.14,0.132-0.297,0.235-0.474,0.31S367.754,8.225,367.54,8.225z M366.529,28.603V12.338h2.056v16.265H366.529z"
        />
        <path
          d="M378.044,26.824h10.13v1.779h-13.26v-0.684l10.322-13.802h-9.472v-1.779h12.65v0.572L378.044,26.824z"
        />
        <path
          d="M404.329,28.603v-2.891h-0.064c-0.535,1.037-1.266,1.842-2.19,2.414c-0.927,0.571-1.973,0.857-3.139,0.857
		c-0.792,0-1.507-0.111-2.144-0.333c-0.637-0.223-1.177-0.538-1.621-0.945s-0.787-0.897-1.027-1.47
		c-0.241-0.571-0.361-1.201-0.361-1.89c0-1.472,0.46-2.645,1.381-3.519c0.92-0.873,2.312-1.442,4.174-1.707l4.992-0.715
		c0-3.123-1.226-4.686-3.676-4.686c-1.916,0-3.692,0.693-5.33,2.081v-2.224c0.343-0.244,0.73-0.463,1.164-0.659
		s0.891-0.365,1.372-0.509c0.482-0.143,0.977-0.254,1.485-0.333c0.508-0.08,1.003-0.119,1.485-0.119c1.83,0,3.213,0.508,4.149,1.524
		s1.404,2.531,1.404,4.543v10.578H404.329z M400.091,20.709c-0.738,0.106-1.37,0.238-1.894,0.397
		c-0.524,0.159-0.958,0.373-1.301,0.644c-0.343,0.27-0.594,0.604-0.755,1c-0.16,0.397-0.24,0.888-0.24,1.47
		c0,0.435,0.077,0.836,0.232,1.207s0.38,0.69,0.675,0.961c0.294,0.27,0.652,0.479,1.075,0.627c0.423,0.148,0.901,0.223,1.437,0.223
		c0.728,0,1.396-0.138,2.007-0.413s1.137-0.656,1.581-1.144c0.444-0.486,0.792-1.069,1.044-1.747
		c0.251-0.678,0.377-1.413,0.377-2.208v-1.604L400.091,20.709z"
        />
        <path
          d="M422.443,14.419c-0.481-0.35-1.103-0.524-1.862-0.524c-0.546,0-1.027,0.104-1.444,0.31c-0.418,0.207-0.784,0.484-1.1,0.834
		c-0.316,0.35-0.584,0.755-0.803,1.215c-0.22,0.461-0.396,0.94-0.53,1.438c-0.134,0.498-0.229,1.001-0.289,1.509
		c-0.059,0.509-0.088,0.99-0.088,1.445v7.958h-2.039V12.338h2.039v3.479h0.064c0.192-0.593,0.436-1.125,0.73-1.597
		c0.294-0.471,0.634-0.867,1.02-1.19c0.385-0.323,0.805-0.569,1.26-0.739c0.455-0.169,0.934-0.254,1.437-0.254
		c0.332,0,0.629,0.021,0.892,0.063c0.262,0.043,0.5,0.106,0.714,0.19V14.419z"
        />
        <path
          d="M439.48,28.603v-2.859h-0.063c-0.546,1.027-1.325,1.824-2.336,2.391c-1.012,0.566-2.159,0.85-3.443,0.85
		c-1.027,0-1.962-0.188-2.802-0.563c-0.84-0.376-1.56-0.919-2.159-1.628c-0.6-0.71-1.062-1.567-1.389-2.573
		s-0.489-2.145-0.489-3.415c0-1.312,0.179-2.512,0.537-3.598c0.359-1.085,0.867-2.017,1.525-2.795s1.45-1.382,2.376-1.812
		c0.926-0.429,1.956-0.643,3.091-0.643c2.364,0,4.061,0.937,5.089,2.812h0.063V4.524h2.055v24.079H439.48z M439.48,18.787
		c0-0.709-0.123-1.371-0.369-1.985c-0.246-0.613-0.589-1.148-1.027-1.604s-0.966-0.815-1.581-1.08s-1.292-0.397-2.031-0.397
		c-0.813,0-1.562,0.159-2.247,0.477s-1.271,0.775-1.758,1.374c-0.487,0.599-0.867,1.326-1.14,2.184
		c-0.273,0.858-0.409,1.832-0.409,2.923c0,1.027,0.123,1.948,0.369,2.764s0.597,1.504,1.052,2.064
		c0.454,0.562,0.997,0.99,1.629,1.287c0.632,0.296,1.332,0.444,2.103,0.444c0.835,0,1.587-0.148,2.256-0.444
		c0.669-0.297,1.236-0.704,1.702-1.224c0.465-0.519,0.823-1.127,1.075-1.826c0.251-0.699,0.377-1.445,0.377-2.239V18.787z"
        />
      </g>
      <g>
        <line x1="250.059" y1="0" x2="250.059" y2="33.645" />
        <rect x="249.559" y="0" width="1" height="33.645" />
      </g>
    </svg>
  </div>
</template>

<style lang="less" scoped>
.app-wizard-logo {
  display: flex;
  align-items: center;
  height: var(--svg-height);
  cursor: inherit;

  svg {
    fill: var(--main-color);
    height: var(--svg-height);
  }
}
</style>
