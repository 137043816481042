import {
  IPortalsServiceClient,
  PortalTilePageDto,
  PortalTileDto,
  IPortalTilePageDto,
  PortalTilePageType,
  RoleType,
} from "@/common/service-clients/generated-clients";
import { TileDescriptorFm } from "@/features/portal/backend-wrapper/tile-descriptor-fm";
import { exists } from "@/common/object-helper/null-helper";
import { getRequestError, RequestError } from "@/common/request-error";
import { TileVm } from "../tile/tile-vm";
import { IPortalFm, IPortalPageFm } from "./facade-models";
import { TilePageFactory } from "./tile-page-factory";

const tilePageFactory = new TilePageFactory();
export class PortalFacade {
  private _portalServiceClient: IPortalsServiceClient;
  private _cachedPortalIds: string[] = null;

  constructor(portalServiceClient: IPortalsServiceClient) {
    this._portalServiceClient = portalServiceClient;
  }

  async getFallbackPortalId(): Promise<string> {
    const userAvailablePortals = await this._getAvailablePortalIds();

    if (!userAvailablePortals.length) {
      return null;
    }

    const fallbackId = userAvailablePortals[0];
    return fallbackId;
  }

  async isPortalIdValid(portalId: string): Promise<boolean> {
    if (!portalId) {
      return false;
    }

    const userAvailablePortals = await this._getAvailablePortalIds();
    if (!userAvailablePortals.length) {
      return false;
    }

    const isIdValid = userAvailablePortals.some(
      (availableId) => availableId === portalId
    );
    return isIdValid;
  }

  async getPortal(portalId: string): Promise<IPortalFm> {
    const portalDto = await this._portalServiceClient.getPortal(portalId);
    return portalDto;
  }

  async getPages(portalId: string): Promise<IPortalPageFm[]> {
    const pages = await this._portalServiceClient.getPortalPages(portalId);
    return pages;
  }

  async getPortalTiles(portalPageId: string): Promise<TileDescriptorFm[]> {
    const [portalTiles, portalTilePages] = await Promise.all([
      this._portalServiceClient.getPortalTiles(portalPageId),
      this._portalServiceClient.getPortalTilePages(portalPageId),
    ]);

    const portalTilePagesByPortalTile = new Map<string, PortalTilePageDto[]>();
    for (const pTilePage of portalTilePages) {
      portalTilePagesByPortalTile.get(pTilePage.portalTileId)?.push(pTilePage) ||
        portalTilePagesByPortalTile.set(pTilePage.portalTileId, [pTilePage]);
    }

    return portalTiles.map((portalTile) => {
      const portalTilePages = portalTilePagesByPortalTile.get(portalTile.id) || [];
      return this._getTileDescriptor(portalTile, portalTilePages);
    });
  }

  async hasEditPermission(portalId: string): Promise<boolean> {
    const editorRole: RoleType = "Editor";

    const editPortals = await this._portalServiceClient.getPortals(null, editorRole);
    const canEdit = editPortals.some((p) => p.id === portalId);
    return canEdit;
  }

  async removeTileFromPortalDefinition(
    portalId: string
  ): Promise<{ error: RequestError }> {
    try {
      await this._portalServiceClient.deletePortalTile(portalId);
      return { error: null };
    } catch (err) {
      const error = getRequestError(err?.status);
      return { error };
    }
  }

  private async _getAvailablePortalIds() {
    if (!this._cachedPortalIds) {
      const portalRefDtos = await this._portalServiceClient.getPortalReferences();
      this._cachedPortalIds = portalRefDtos.map((dto) => dto.id);
    }

    return this._cachedPortalIds;
  }

  // TODO 1: check if dtos do NOT contain more props than needed
  //
  // TODO 2: (See TFS PBI 24406):  Use Transaction-Scope for these actions!
  async addNewTileToPortal(portalPageId: string, tile: TileVm): Promise<RequestError> {
    let error: RequestError = null;

    // 1. create PortalTile
    const portalTileId = await this._portalServiceClient
      .createPortalTile(
        PortalTileDto.fromJS({
          portalPageId: portalPageId,
          name: tile.name, // Name from original Name and Clone ID, Also: should be in DTO!
          description: tile.description ?? "",
        })
      )
      .catch((err) => (error = getRequestError(err?.status)));
    if (error) return error;

    // 2. create PortalTilePage
    const portalTilePageDto = tilePageFactory.getTilePageDto(tile.activePage);
    portalTilePageDto.portalTileId = tile.id;
    const portalTilePageId = await this._portalServiceClient
      .createPortalTilePage(portalTilePageDto)
      .catch((err) => (error = getRequestError(err?.status)));
    if (error) {
      // TODO: transaction scope?
      await this._portalServiceClient.deletePortalTile(portalTileId);
      return error;
    }

    // 3. if success: update tile- and page id
    tile.id = portalTileId;
    tile.activePage.id = portalTilePageId;

    return null;
  }

  private _getTileDescriptor(
    tile: PortalTileDto,
    tilePages: PortalTilePageDto[]
  ): TileDescriptorFm {
    let selectedPage: PortalTilePageDto = null;
    if (tilePages) {
      selectedPage = tilePages[0];
    }

    if (!exists(selectedPage)) {
      return TileDescriptorFm.fromJS({
        tilePageDto: null,
        tileDto: tile,
        error: "NoTilePage",
        tilePageType: null,
      });
    }

    return TileDescriptorFm.fromJS({
      tilePageDto: selectedPage,
      tilePageType: this._getTilePageType(selectedPage),
      tileDto: tile,
      error: null,
    });
  }

  private _getTilePageType(value: IPortalTilePageDto): PortalTilePageType | null {
    const type = value["portalTilePageType"] as PortalTilePageType;

    return type ?? null;
  }
}
