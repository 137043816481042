import {
  IPortalPageViewStateDto,
  IPortalTileViewStateDto,
  IPortalViewStateDto,
  IViewStateDto,
} from "@/common/service-clients/generated-clients";
import { PortalVm } from "../../view-models/portal-vm";
import { PortalPageVm } from "../../view-models/portal-page-vm";
import { TileVm } from "../../tile/tile-vm";
import { ITilePageVm } from "../../tile-pages/tile-page-vm.interface";
import { VsRoot } from "@/services/view-state-service/contract/vs-root";
import {
  IVsApplicator,
  VsApplicatorResult,
} from "@/services/view-state-service/contract/vs-applicator.interface";
import Vue from "vue";

export class PortalVsApplicator implements IVsApplicator {
  private _portalVm: PortalVm = null;
  private _applyResult: VsApplicatorResult = null;

  async applyAsync(
    vm: VsRoot,
    vsToBeApplied: IViewStateDto
  ): Promise<VsApplicatorResult> {
    this._applyResult = VsApplicatorResult.success;
    this._portalVm = vm as PortalVm;
    const portalVs = vsToBeApplied as IPortalViewStateDto;

    this._portalVsApplicator(portalVs);
    return this._applyResult;
  }

  setVsRefsOnVm(vm: VsRoot, vs: IViewStateDto): void {
    const portalVm = vm as PortalVm;
    const portalVs = vs as IPortalViewStateDto;

    this._forOfPages(portalVm.portalPages, portalVs.portalPages, (pageVm, pageVs) => {
      for (const tileVm of pageVm.tiles) {
        const tileVs = pageVs.portalTiles.find((vs) => vs.portalTileId === tileVm.id);

        if (!tileVs) {
          continue;
        }

        const childVsRoot = tileVm.activePage?.getVsRoot();
        if (!childVsRoot) {
          continue;
        }

        childVsRoot.parentViewStateId = tileVs.id;

        const childVs = tileVs.portalTilePages[0];
        if (childVs) {
          childVsRoot.viewStateId = childVs.id;
        }

        // // If dashboard VMs would be instanciated after tileVms:
        // VueHelper.waitForChangeAndCondition(() => tileVm.dashboardVm, exists).then(() => {
        //   applyVsToTilePageVms([tileVm.dashboardVm], tileVs);
        // });
      }
    });
  }

  private _portalVsApplicator(viewState: IPortalViewStateDto): void {
    this._applyPortalPageSorting(this._portalVm.portalPages, viewState.portalPages);

    this._portalVm.portalSelection.pageId = viewState.activePortalPageId;

    this._applyVsToPortalPages(this._portalVm.portalPages, viewState.portalPages);
  }

  // inplace sorting of the VM array according to the VS array
  private _applyPortalPageSorting(
    pagesVms: PortalPageVm[],
    pagesVss: IPortalPageViewStateDto[]
  ): void {
    const sortedPageIds = pagesVss
      .sort((entry1, entry2) => entry1.sortIndex - entry2.sortIndex)
      .map((pageVs) => pageVs.portalPageId);

    for (let loopIdx = 0; loopIdx < sortedPageIds.length; loopIdx++) {
      const origVm = pagesVms[loopIdx];

      const pageId = sortedPageIds[loopIdx];
      const pageIdx = pagesVms.findIndex((page) => page.id === pageId);
      Vue.set(pagesVms, loopIdx, pagesVms[pageIdx]);

      pagesVms[pageIdx] = origVm;
    }
  }

  private _applyVsToPortalPages(
    pageVms: PortalPageVm[],
    pageVss: IPortalPageViewStateDto[]
  ): void {
    this._forOfPages(pageVms, pageVss, (pageVm, pageVs) => {
      this._applyVsToTiles(pageVm.tiles, pageVs.portalTiles);
    });
  }

  private _forOfPages(
    pageVms: PortalPageVm[],
    pageVss: IPortalPageViewStateDto[],
    callback: (pageVm: PortalPageVm, pageVs: IPortalPageViewStateDto) => void
  ) {
    for (const pageVm of pageVms) {
      const pageVs = pageVss.find((vs) => vs.portalPageId === pageVm.id);
      if (!pageVs) {
        continue;
      }

      callback(pageVm, pageVs);
    }
  }

  private _applyVsToTiles(tileVms: TileVm[], tileVss: IPortalTileViewStateDto[]): void {
    if (!tileVms || !tileVms.length) {
      return;
    }

    const tileVmsWithotVs: TileVm[] = [];
    for (const tileVm of tileVms) {
      const tileVs = tileVss.find((vs) => vs.portalTileId === tileVm.id);

      if (!tileVs) {
        tileVmsWithotVs.push(tileVm);
        continue;
      }

      tileVm.isFullpage = tileVs.frame.isFullScreen;

      tileVm.tileConfig.order = tileVs.sortIndex;

      // tileVm.tileConfig.x = tileVs.frame?.positionX;
      // tileVm.tileConfig.y = tileVs.frame?.positionY;
      tileVm.tileConfig.w = tileVs.frame?.width;

      // tileVm.tileConfig.h = tileVs.frame?.height;

      this._applyVsToTilePageVms([tileVm.activePage], tileVs);
    }

    if (tileVmsWithotVs?.length) {
      this._applyResult.updateFrom(VsApplicatorResult.failMissingViewState);
      const existingOrders = tileVss.map((vs) => vs.sortIndex);
      this._setOrderWithoutVS(tileVmsWithotVs, existingOrders);
    }
  }

  private _setOrderWithoutVS(tileVmsWithotVs: TileVm[], existingOrders: number[]) {
    let minOrder = 0;
    if (existingOrders?.length) {
      minOrder = Math.min(...existingOrders) - tileVmsWithotVs.length;
    }

    for (const tileVm of tileVmsWithotVs) {
      tileVm.tileConfig.order = minOrder;
      minOrder++;
    }
  }

  private _applyVsToTilePageVms(
    tilePageVms: ITilePageVm[],
    parentTileVs: IPortalTileViewStateDto
  ): void {
    for (const tpVm of tilePageVms) {
      // tp-vms may be null, if a tile has
      // no assigned tilePage in definition data
      if (!tpVm) {
        continue;
      }
      const vsRoot = tpVm.getVsRoot();
      if (!vsRoot) {
        // some pages dont have VS (e.g.: URL Report)
        continue;
      }
      vsRoot.parentViewStateId = parentTileVs.id;

      const tpVs = parentTileVs.portalTilePages.find(
        (vs) => tpVm.id == vs.portalTilePageId
      );
      if (!tpVs) {
        continue;
      }

      vsRoot.viewStateId = tpVs.id;
    }
  }
}
