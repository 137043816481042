<script lang="ts">
import { BcMenuItem as MenuItem } from "@bissantz/controls";
import { appResources } from "@/app-resources";
import { Dm7App } from "@/app";
import { IAppWizardService } from "@/services/app-wizard-service.interface";
import {
  computed,
  defineComponent,
  reactive,
  inject,
  onBeforeMount,
  onBeforeUnmount,
  watch,
} from "vue";
import { isMobile } from "@/common/browser-detection";
import MenuNode from "@/features/main-menu/application-menu/helper-components/menus/menu-node.vue";
import { MenuNodeStyle } from "./helper-components/menus/menu-node-style";
import { useBackendDependencies } from "../backend-wrapper/backend-dependencies.cpsl";
import { ApplicationMenuVm } from "./view-models/application-menu-vm";
import { MenuNodeVm } from "./helper-components/menus/menu-node-vm";
import { MenuNodeEventNotifier } from "./helper-components/menus/menu-node-event-notifier";
import MenuNodeSpinner from "./helper-components/menus/menu-node-spinner.vue";
import CreateIcon from "./icons/create-icon.vue";

export default defineComponent({
  components: {
    MenuItem,
    MenuNode,
    CreateIcon,
    MenuNodeSpinner,
  },

  props: {
    styleSettings: {
      type: MenuNodeStyle,
      required: false,
      default: () => new MenuNodeStyle(),
    },
  },

  setup: function () {
    const { applicationFacade } = useBackendDependencies();
    // injections
    const appWizardService: IAppWizardService = inject("appWizardService");
    const application = inject("application") as Dm7App;

    const state = reactive({
      menuTexts: appResources.menuTexts,
      applicationMenu: {} as ApplicationMenuVm,
      eventListeners: [],
    });

    onBeforeMount(async () => {
      state.applicationMenu = new ApplicationMenuVm(
        applicationFacade,
        state.menuTexts.applicationsOverview
      );

      const eventNotifier = new MenuNodeEventNotifier();
      state.applicationMenu.rootNode.eventNotifier = eventNotifier;

      const editApplicationEventListener =
        eventNotifier.nodeSelectionChanged.on(editApplicationAsync);
      state.eventListeners.push(editApplicationEventListener);
    });

    onBeforeUnmount(() => {
      state.eventListeners.map((disp) => disp.dispose());
    });

    const showAppWizard = computed(() => {
      return !isMobile();
    });

    const rootNode = computed(() => {
      return state.applicationMenu.rootNode as MenuNodeVm;
    });

    const isSpinnerHidden = computed(() => {
      return rootNode.value.isLoadingData === false;
    });

    async function openApplicationMenuAsync() {
      if (rootNode.value.isOpen === false) {
        rootNode.value.clearCache();
      }

      await rootNode.value.toggleAsync();
    }

    async function createApplicationAsync() {
      appWizardService.openNewApplicationMode();
      application.mainMenuOpen = false;
    }

    async function editApplicationAsync(menuNode: MenuNodeVm) {
      application.mainMenuOpen = false;
      appWizardService.openEditApplicationMode(menuNode.value);
    }

    watch(() => application.locale, onApplicationLanguageChanged);
    async function onApplicationLanguageChanged() {
      state.applicationMenu.rootNodeName = state.menuTexts.applicationsOverview;
    }

    return {
      // general:
      state,

      // computed:
      showAppWizard,
      rootNode,
      isSpinnerHidden,

      // functions:
      createApplicationAsync,
      openApplicationMenuAsync,
    };
  },
});
</script>

<template>
  <div class="application-menu">
    <MenuItem
      v-bind:data-testId="rootNode.id"
      v-if="showAppWizard"
      class="borderAtTop borderAtBottom"
      v-bind:hideOnClose="false"
      v-bind:beginGroup="true"
      v-on:click="openApplicationMenuAsync"
    >
      <template v-slot:title>
        <div class="title-container">
          <MenuNodeSpinner v-if="rootNode.isLoadingData" />
          <div v-if="isSpinnerHidden" class="title-menu-part">{{ rootNode.name }}</div>
          <div v-if="isSpinnerHidden">
            <CreateIcon
              v-bind:mainColor="$props.styleSettings.borderColor"
              v-on:click="createApplicationAsync"
            />
          </div>
        </div>
      </template>
      <template v-slot:component>
        <div v-for="nodeMenuVm in rootNode.children" v-bind:key="nodeMenuVm.id">
          <MenuNode
            v-bind:model="nodeMenuVm"
            v-bind:styleSettings="$props.styleSettings"
            v-bind:nodeFontSizeInEmUnits="0.9"
          />
        </div>
      </template>
    </MenuItem>
  </div>
</template>

<style lang="less" scoped>
.application-menu {
  .bissantzControls.menuItemControl::v-deep {
    .bissantzControls.buttonControl.plain.menuTitle {
      padding-top: 13px;
      padding-bottom: 13px;
    }
  }

  .title-container {
    display: flex;
    justify-content: space-between;
  }

  .title-menu-part {
    line-height: 24px;
  }
}
</style>
