export class SelectionResultVm {
  private _isOpen: boolean = false;
  private _hasBeenClosedExplicitly: boolean = false;
  private _numberOfSelectedItems: number = 0;

  constructor(_isEditMode: boolean) {
    if (_isEditMode) {
      this._isOpen = true;
    }
  }

  get isOpen(): boolean {
    return this._isOpen;
  }

  set numberOfSelectedItems(value: number) {
    if (value === 1 && this._numberOfSelectedItems === 0) {
      // first item has been selected

      this._isOpen = true;
    }
    // the last item has been removed from selection
    else if (
      value === 0 &&
      this._numberOfSelectedItems === 1 &&
      !this._hasBeenClosedExplicitly
    ) {
      this._isOpen = false;
    }

    this._numberOfSelectedItems = value;
  }

  get numberOfSelectedItems(): number {
    return this._numberOfSelectedItems;
  }

  toggle(): void {
    this._isOpen = !this._isOpen;

    this._hasBeenClosedExplicitly = !this._isOpen;
  }
}
