<script lang="ts">
import { computed, defineComponent } from "vue";
import type { CSSProperties } from "vue/types/jsx";

export default defineComponent({
  props: {
    mainColor: {
      type: String,
      default: "#000",
    },
  },
  setup(props) {
    const colorVars = computed<CSSProperties>(() => {
      const usedColor = props.mainColor;
      return {
        "--main-color": usedColor,
      };
    });

    return {
      colorVars,
    };
  },
});
</script>

<template>
  <div class="dialogWarningIcon" v-bind:style="colorVars">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="-15 0 255 210"
      style="enable-background: new -15 0 255 210"
      xml:space="preserve"
    >
      <g>
        <g>
          <path
            d="M113.7,12L217,191H10.4L113.7,12 M113.7,0L0,197h227.4L113.7,0L113.7,0z"
          />
        </g>
        <line x1="113.7" y1="62.2" x2="113.7" y2="151.2" />
        <line x1="113.7" y1="161.2" x2="113.7" y2="173.7" />
      </g>
    </svg>
  </div>
</template>

<style lang="less" scoped>
.dialogWarningIcon {
  width: 100%;
  height: 100%;

  svg {
    stroke: var(--main-color);
    stroke-width: 12;
    width: 100%;
    height: 100%;
    fill: none;

    line {
      stroke-miterlimit: 10;
    }
  }
}
</style>
