export abstract class AppWizardCommon {
  private constructor() {}

  static events = {
    confirmed: "confirmed",
    cancelled: "cancelled",
    click: "click",
    xIconClicked: "xIconClicked",
  };
}
