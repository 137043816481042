<script lang="ts">
import Notification from "./notification.vue";

import {
  inject,
  onBeforeMount,
  onBeforeUnmount,
  watch,
  onMounted,
  defineComponent,
  reactive,
} from "vue";
import { IDisposable } from "@/common/disposable.interface";
import { NotificationService, NotificationData } from "./notification-service";
import { registerDemos, unregisterDemos } from "./demos";

export default defineComponent({
  components: {
    Notification,
  },

  props: {
    zIndex: { type: Number, required: true },
    addDemos: { type: Boolean, required: false, default: false },
  },

  setup(props) {
    const notificationService: NotificationService = inject("notificationService");

    const state = reactive({
      notificationQueue: [] as NotificationData[],
      isProcessing: false,
      activeNotifiaction: null as NotificationData,
      eventDisposer: null as IDisposable,
    });

    onBeforeMount(() => {
      state.eventDisposer = notificationService.showNotifiaction.on(
        onNewNotificationQueued
      );
    });

    onMounted(() => {
      onAddDemosChanged(props.addDemos);
    });

    onBeforeUnmount(() => {
      state.eventDisposer.dispose();
    });

    function onNewNotificationQueued(ev: NotificationData): void {
      state.notificationQueue.push(ev);
      if (state.isProcessing) {
        return;
      }
      processQueue();
    }

    function processQueue() {
      if (state.notificationQueue.length === 0) {
        state.isProcessing = false;
        return;
      }

      state.isProcessing = true;
      const notification = state.notificationQueue.shift();
      state.activeNotifiaction = notification;

      if (!notificationService.isAutoCloseEnabled) {
        return;
      }

      const waitTime =
        notification.messageData.message === null
          ? notificationService.shortNotifiactionDisplayTime
          : notificationService.notifiactionDisplayTime;

      setTimeout(closeNotification, waitTime);
    }

    async function closeNotification() {
      if (state.activeNotifiaction === null) {
        return;
      }

      // signal back to the service that the notification is closed:
      state.activeNotifiaction.resolveFn();

      state.activeNotifiaction = null;

      // some time to fade the just closed notification out
      // before the next one is shown
      await new Promise((resolve) =>
        setTimeout(resolve, notificationService.waitBetweenNotifications)
      );
      processQueue();
    }

    watch(() => props.addDemos, onAddDemosChanged);
    function onAddDemosChanged(addDemosNew: boolean) {
      if (addDemosNew) {
        registerDemos(notificationService);
        console.debug("added notification demos (under 'document.noti...')");
      } else {
        unregisterDemos();
        console.debug("removed notification demos");
      }
    }
    return {
      state,
      closeNotification,
    };
  },
});
</script>

<template>
  <div class="notificationHostComponent">
    <Notification
      v-bind:notificationData="state.activeNotifiaction"
      v-on:closeClicked="closeNotification"
    />
  </div>
</template>

<style lang="less" scoped>
.notificationHostComponent {
  position: fixed;
  width: 0;
  height: 0;
  z-index: v-bind("$props.zIndex");
}
</style>
