<script lang="ts">
import { defineComponent, computed } from "vue";
import type { CSSProperties } from "vue/types/jsx";

export default defineComponent({
  props: {
    color: { type: String, default: null },
  },

  setup(props) {
    const colorVars = computed<CSSProperties>(() => {
      return {
        "--main-color": props.color ? props.color : "var(--color_neutralText)",
      };
    });

    return {
      colorVars,
    };
  },
});
</script>

<template>
  <div class="sort-by-name-desc">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 210 210"
      v-bind:style="colorVars"
    >
      <rect class="cls-2" x="65.2" y="65.2" width="109.5" height="109.5" />
      <polygon
        class="cls-2"
        points="65.2 128.3 65.2 65.2 128.3 65.2 128.3 35.3 35.3 35.3 35.3 128.3 65.2 128.3"
      />
      <path
        class="cls-1"
        d="M94.3,140.7l32.6-38.9h-30.9v-10.9h48.4v9.5l-32.6,38.9h32.6v10.9h-50.1v-9.5Z"
      />
    </svg>
  </div>
</template>

<style lang="less" scoped>
.sort-by-name-desc {
  height: 100%;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 100%;
    width: 100%;

    .cls-1 {
      fill: var(--main-color);
      stroke-width: 0;
    }

    .cls-2 {
      fill: none;
      stroke: var(--main-color);
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 12px;
    }
  }
}
</style>
