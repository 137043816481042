<script lang="ts">
import DialogErrorIcon from "@/common/components/icons/dialog-error-icon.vue";
import { DefaultStyles } from "@/common/styles/default-styles";
import { defineComponent } from "vue";

const errorColor = DefaultStyles.colorConstants.weatherMinus2;

export default defineComponent({
  components: {
    DialogErrorIcon,
  },

  props: {
    errorHeader: { type: String, default: "" },
    errorBody: { type: String, default: "" },
  },

  setup() {
    return {
      errorColor,
    };
  },
});
</script>

<template>
  <div class="application-wizard_error">
    <div class="header">
      <div class="titleRow">
        <div class="icon">
          <DialogErrorIcon v-bind:mainColor="errorColor" />
        </div>
        <div class="title">{{ $props.errorHeader }}</div>
      </div>
    </div>
    <div class="separator" />
    <div class="message">
      {{ $props.errorBody }}
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "../../../common/styles/base-styles.less";
.application-wizard_error {
  padding: 20px;
  color: var(--color_neutralText);
  box-shadow: 0px 0px 12px 6px #0000000d;
  max-width: 400px;

  .header {
    .titleRow {
      display: flex;
      align-items: center;
      padding-right: 15px;

      .icon {
        width: 48px;
        height: 48px;
        flex: 0 0 48px;
      }
      .title {
        font-size: 25px;
        padding-left: 19px;
      }
    }
  }

  .separator {
    margin-top: 13px;
    background-color: var(--color_bg-gray);
    height: 2px;
    width: 100%;
  }

  .message {
    margin-top: 1em;
    font-size: 18px;
    line-height: 1.5;
  }
}
</style>
