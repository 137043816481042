<script lang="ts">
import { BcMenuItem as MenuItem } from "@bissantz/controls";
import MagicButtonList from "./magic-button-list.vue";
import { reactive, defineComponent, watch, computed, inject } from "vue";
import { ModellingVm } from "../../../view-models/modelling-vm";
import { appResources } from "@/app-resources";
import { SelectionResultVm } from "../../../view-models/modelling/selection-result-vm";
import RotatableArrowWithText from "./rotatable-arrow-with-text.vue";
import WizardButtonList from "../../../helper-components/wizard-button-list.vue";
import { AppWizardService } from "@/services/app-wizard-service";
import { INotificationService } from "@/features/notifications/notification-service.interface";

class TitleDefaultProps {
  changeArrowColorOnRotation: boolean = false;
  changeTextColorOnRotation: boolean = false;
  changeTextColorOnHovering: boolean = false;
}

export default defineComponent({
  components: {
    MenuItem,
    MagicButtonList,
    RotatableArrowWithText,
    WizardButtonList,
  },

  props: {
    modellingVm: { type: ModellingVm, required: true },
  },

  setup(props) {
    const appWizardService: AppWizardService = inject("appWizardService");

    const notificationService = inject("notificationService") as INotificationService;

    const state = reactive({
      appWizardTexts: appResources.applicationWizardTexts,
      dimensionSelectionResult: new SelectionResultVm(appWizardService.isEditMode),
      kpiSelectionResult: new SelectionResultVm(appWizardService.isEditMode),
      isMagicButtonOpen: true,
      isFirstClick: true,
    });

    const titleDefaultProps = computed<TitleDefaultProps>(() => {
      return new TitleDefaultProps();
    });

    function toggleDimensionVisibility() {
      state.dimensionSelectionResult.toggle();
    }

    function toggleKpiVisibility() {
      state.kpiSelectionResult.toggle();
    }

    function toggleMagicButtonVisibility() {
      state.isMagicButtonOpen = !state.isMagicButtonOpen;
    }

    function onDimensionSelectionChange(newLength: number): void {
      state.dimensionSelectionResult.numberOfSelectedItems = newLength;
    }

    function onDimensionElementDeleted(buttonId: string) {
      props.modellingVm.navigationVm.dimensions.toggleNodeSelection(buttonId);
    }

    function onKpiSelectionChange(newLength: number): void {
      state.kpiSelectionResult.numberOfSelectedItems = newLength;
    }

    function onKpiDeleted(kpiId: string) {
      props.modellingVm.navigationVm.kpis.toggleNodeSelection(kpiId);
    }

    function showNotification() {
      notificationService.error({
        title: state.appWizardTexts.modelling_dataStoreErrorHeader,
        message: state.appWizardTexts.modelling_dataStoreErrorBody,
      });
    }

    watch(
      () => props.modellingVm.navigationVm.selectedLevels.length,
      onDimensionSelectionChange
    );

    watch(() => props.modellingVm.navigationVm.selectedKpis.length, onKpiSelectionChange);

    watch(
      () => props.modellingVm.navigationVm.dataStoreError,
      () => {
        if (props.modellingVm.navigationVm.dataStoreError) {
          showNotification();
        }
      }
    );

    watch(
      () => [
        state.dimensionSelectionResult.numberOfSelectedItems,
        state.kpiSelectionResult.numberOfSelectedItems,
        props.modellingVm.navigationVm.selectedPeriodElementsDisplayText,
      ],
      () => {
        if (state.isFirstClick) {
          state.isFirstClick = false;
        }
      }
    );

    return {
      state,
      toggleDimensionVisibility,
      toggleKpiVisibility,
      toggleMagicButtonVisibility,
      titleDefaultProps,
      onDimensionElementDeleted,
      onKpiDeleted,
      appWizardService,
    };
  },
});
</script>

<template>
  <div class="application-wizard_report-modelling-selected-options report-wrapper">
    <div v-show="!state.isFirstClick || appWizardService.isEditMode">
      <!-- PERIOD -->
      <div class="selection-wrapper">
        <div class="period-header">
          {{ state.appWizardTexts.modelling_periodHeader }}:
        </div>
        <div class="selected-period">
          {{ $props.modellingVm.navigationVm.selectedPeriodElementsDisplayText }}
        </div>
      </div>

      <!-- Not Clickable Separator -->
      <div class="separator" />

      <!-- DIMENSIONS -->
      <MenuItem
        v-bind:transitionDuration="200"
        v-bind:active="state.dimensionSelectionResult.isOpen"
        v-on:click="toggleDimensionVisibility"
      >
        <template v-slot:title>
          <RotatableArrowWithText
            v-bind:isRotated="state.dimensionSelectionResult.isOpen"
            v-bind:text="state.appWizardTexts.modelling_dimensionHeader"
            v-bind="titleDefaultProps"
          />
        </template>
        <template v-slot:component>
          <WizardButtonList
            v-bind:buttonList="$props.modellingVm.navigationVm.selectedLevels"
            v-bind:areButtonsDeletable="true"
            v-on:xIconClicked="onDimensionElementDeleted"
          />
        </template>
      </MenuItem>

      <!-- Not Clickable Separator -->
      <div class="separator" />

      <!-- MEASURES -->
      <MenuItem
        v-bind:transitionDuration="200"
        v-bind:active="state.kpiSelectionResult.isOpen"
        v-on:click="toggleKpiVisibility"
      >
        <template v-slot:title>
          <RotatableArrowWithText
            v-bind:isRotated="state.kpiSelectionResult.isOpen"
            v-bind:text="state.appWizardTexts.modelling_measureHeader"
            v-bind="titleDefaultProps"
          />
        </template>
        <template v-slot:component>
          <WizardButtonList
            v-bind:buttonList="$props.modellingVm.navigationVm.selectedKpis"
            v-bind:areButtonsDeletable="true"
            v-on:xIconClicked="onKpiDeleted"
          />
        </template>
      </MenuItem>

      <!-- Not Clickable Separator -->
      <div class="separator" />

      <!-- MAGIC BUTTONS -->
      <MenuItem
        v-bind:transitionDuration="200"
        v-bind:active="true"
        v-on:click="toggleMagicButtonVisibility"
      >
        <template v-slot:title>
          <RotatableArrowWithText
            v-bind:isRotated="state.isMagicButtonOpen"
            v-bind:text="state.appWizardTexts.modelling_viewOfPeriodHeader"
            v-bind="titleDefaultProps"
          />
        </template>
        <template v-slot:component>
          <MagicButtonList
            v-bind:magicButtonListVm="$props.modellingVm.magicButtonListVm"
          />
        </template>
      </MenuItem>
    </div>
    <div
      v-show="state.isFirstClick && !appWizardService.isEditMode"
      class="instruction-text"
    >
      <p>{{ state.appWizardTexts.modelling_clickElementLeftSide }}</p>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "../../../../../common/styles/base-styles.less";
@import "./report-modelling.less";
.application-wizard_report-modelling-selected-options {
  display: flex;
  flex: 0 0 69%;
  flex-direction: column;

  .period-header {
    display: flex;
    margin-left: 26px;
  }

  .selected-period {
    color: var(--color_Weather3);
    margin-left: 5px;
  }

  .instruction-text {
    font-size: 0.9rem;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }
}
</style>
