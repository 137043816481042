<script lang="ts">
import { BcMenuItem as MenuItem } from "@bissantz/controls";
import HideAndShow from "@/common/components/hide-and-show.vue";
import { appResources } from "@/app-resources";
import { PortalEntry } from "./backend-wrapper/portal-menu-facade";
import { Dm7App } from "@/app";
import { computed, defineComponent, reactive, inject } from "vue";
import { IPortalSelection } from "@/features/portal/contract/portal-selection.interface";
import { useBackendDependencies } from "./backend-wrapper/backend-dependencies.cpsl";
import ApplicationMenu from "@/features/main-menu/application-menu/application-menu.vue";

export default defineComponent({
  components: {
    MenuItem,
    HideAndShow,
    ApplicationMenu,
  },

  setup: function () {
    const { portalMenuFacade } = useBackendDependencies();
    // injections

    const application = inject("application") as Dm7App;
    const portalSelection = inject("portalSelection") as IPortalSelection;

    const state = reactive({
      menuTexts: appResources.menuTexts,
      portalEntries: [] as PortalEntry[],
      isLoading: true,
    });

    async function init() {
      state.isLoading = true;

      // TODO: entries must be updated when menuItem is shown again!
      //       see TFS PBI: 24404
      state.portalEntries = await portalMenuFacade.getEntries();
      state.isLoading = false;
    }
    init();

    const selectedId = computed(() => {
      return portalSelection.portalId;
    });

    /**
     * show only if more than 1 portals are defined
     */
    const showPortalsList = computed(() => {
      return !state.isLoading && state.portalEntries.length > 1;
    });

    async function select(portalId: string) {
      portalSelection.portalId = portalId;
      application.mainMenuOpen = false;
      try {
        await application.content.find((c) => c.contentId == "portal").navigate();
      } catch (error) {
        if ("NavigationDuplicated" == error?.name) {
          application.content.find((c) => c.contentId == "portal").refresh();
        }
      }
    }

    return {
      // general:
      state,

      // computed:
      selectedId,
      showPortalsList,

      // functions:
      select,
    };
  },
});
</script>

<template>
  <div data-testid="portalMenuTestId" class="portalMenu">
    <MenuItem
      class="portalList borderAtTop borderAtBottom"
      v-if="showPortalsList"
      v-bind:beginGroup="true"
      v-bind:active="true"
      v-bind:hideOnClose="false"
    >
      <template v-slot:title>{{ state.menuTexts.portalsOverview }}</template>
      <template v-slot:component>
        <div class="availablePortalsList">
          <div
            class="portalEntry"
            v-bind:class="{ selected: portal.id === selectedId }"
            v-for="portal in state.portalEntries"
            v-on:click="select(portal.id)"
            v-bind:key="portal.id"
          >
            <HideAndShow>{{ portal.name }}</HideAndShow>
          </div>
        </div>
      </template>
    </MenuItem>
    <ApplicationMenu data-testId="applicationMenuTestId" />
  </div>
</template>

<style lang="less" scoped>
@import "../../common/styles/base-styles.less";

.borderAtTop {
  border-top: 1px solid var(--color_headerText);
}

.borderAtBottom {
  border-bottom: 1px solid var(--color_headerText);
}

.portalMenu .availablePortalsList {
  .portalEntry {
    padding: 1em 0;
    padding-left: 5em;
    padding-right: 3em;

    font-size: 0.9em;
    font-family: @fontLight;
    display: flex;
    justify-content: space-between;

    cursor: pointer;
  }

  .defaultEntry {
    font-style: italic;
  }

  .portalEntry.selected {
    color: var(--color_edit_yellow);
  }

  .portalEntry.noPortals {
    cursor: default;
  }
}
</style>
