import { BiColorThresholds } from "@/common/formatting/bicolor-thresholds";
import { BarAnimation } from "./bar-animation";
import { SharedSparklineState } from "../shared-sparkline-state";
import { SparkOrientation, SparklineState } from "../sparkline-common";

export class BarAnimationState {
  availableHeight: number;
  sparklinesGlobalMax: number;
  sparklinesDisplayState: SharedSparklineState;
  sparklineState: SparklineState;
  excludeFromScaling: boolean;
  prevAnimation: BarAnimation = null;
  barOrientation: SparkOrientation;
  defaultTransition: boolean = true;
  index: number;
  globalSparklinesEnabled: boolean = false;

  get maxHeight(): number {
    if (this.excludeFromScaling || this.sparklinesDisplayState.mode !== "global") {
      return this.sparklineState.localSparklineMax;
    }

    return this.sparklinesGlobalMax;
  }

  scaleFactor?: number = null;
  factor: number = null;
  invertSign: boolean = null;
  biColorThresholds?: BiColorThresholds = null;
}
