import { IAppWizardService } from "./app-wizard-service.interface";
import { AppEntry } from "@/features/main-menu/application-menu/helper-components/menus/app-entry";

export class AppWizardService implements IAppWizardService {
  private _isShowing: boolean = false;
  private _isEditMode: boolean = false;
  private _appEntry: AppEntry;

  get isShowing(): boolean {
    return this._isShowing;
  }

  get isEditMode(): boolean {
    return this._isEditMode;
  }

  get appEntry(): AppEntry {
    return this._appEntry;
  }

  closeApplication(): void {
    this._isShowing = false;
    this._isEditMode = false;
  }

  openNewApplicationMode(): void {
    this._isShowing = true;
  }

  openEditApplicationMode(appEntry: AppEntry): void {
    this._isShowing = true;
    this._isEditMode = true;
    this._appEntry = appEntry;
  }
}
