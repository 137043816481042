<script lang="ts">
import { DefaultStyles } from "@/common/styles/default-styles";
import { reactive, defineComponent, PropType, computed } from "vue";
import { appResources } from "@/app-resources";
import type { CSSProperties } from "vue/types/jsx";

export default defineComponent({
  props: {
    activeTab: { type: Number, required: true },
    names: { type: Array as PropType<string[]>, required: true },
  },

  setup(props) {
    const state = reactive({
      bannerTexts: appResources.applicationWizardTexts,
    });

    const greaterIcon = computed<string>(() => {
      return DefaultStyles.colorConstants.headerTextHEX;
    });

    const tabIcon = computed<string>(() => {
      return DefaultStyles.colorConstants.bgWhite;
    });

    function isTabActive(index: number): boolean {
      return props.activeTab === index;
    }

    const progressBar = computed<CSSProperties>(() => {
      const translateValue = `${props.activeTab * 100}%`;

      return {
        "--xTranslate": translateValue,
      };
    });

    return {
      // general
      state,
      greaterIcon,
      tabIcon,

      //functions
      isTabActive,

      //computed
      progressBar,
    };
  },
});
</script>

<template>
  <div class="application-wizard_tabs-overview">
    <div class="tabs">
      <div
        class="single-tab"
        v-for="(tab, index) in $props.names"
        v-bind:key="index"
        v-bind:class="{ active: isTabActive(index) }"
      >
        {{ tab }}
      </div>
    </div>
    <div class="progress-bar" v-bind:style="progressBar"></div>
  </div>
</template>

<style lang="less" scoped>
@import "../../../common/styles/base-styles.less";

.application-wizard_tabs-overview {
  .tabs {
    font-size: 1.1rem;
    padding: 23px 0;
    border-bottom: 1px solid var(--color_bg-gray);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;

    .active {
      color: var(--color_Weather3);
      font-family: @fontSemi;
      letter-spacing: 2px;
    }
  }

  .progress-bar {
    background-color: var(--color_Weather3);
    height: 6px;
    width: calc(100% / 3);
    transform: translateX(var(--xTranslate));
    transition: transform 0.4s ease-out;
  }
}
</style>
