import {
  KpiValueIdFm,
  KpiValueGroupIdFm,
  KpiIdFm,
  DashboardIdFm,
  EntityIdFm,
  ElementIdFm,
  StructureIdFm,
  EntityTypeFm,
} from "@/features/dashboard-shared/backend-wrapper/facade-models-dashboard-shared";
import {
  KpiValueIdDto,
  KpiValueGroupIdDto,
  KpiIdDto,
  DashboardIdDto,
  EntityIdDto,
  ElementIdDto,
  StructureIdDto,
  EntityType,
} from "@/common/service-clients/generated-clients";

export class FmDtoMapperDashboardShared {
  static mapElementIdDto(elementIdFm: ElementIdFm): ElementIdDto {
    const elementIdDto = new ElementIdDto();
    elementIdDto.id = elementIdFm.id;
    elementIdDto.structureId = this.mapStructureIdDto(elementIdFm.structureId);
    return elementIdDto;
  }

  static mapKpiValueIdDto(valueIdFm: KpiValueIdFm): KpiValueIdDto {
    if (!valueIdFm) {
      return null;
    }

    const kpiValueIdDto = new KpiValueIdDto();
    kpiValueIdDto.id = valueIdFm.id;
    kpiValueIdDto.kpiValueGroupId = this.mapKpiValueGroupIdDto(valueIdFm.kpiValueGroupId);
    return kpiValueIdDto;
  }

  static mapKpiValueGroupIdDto(kpiValueGroupIdFm: KpiValueGroupIdFm): KpiValueGroupIdDto {
    const kpiValueGroupIdDto = new KpiValueGroupIdDto();
    kpiValueGroupIdDto.id = kpiValueGroupIdFm.id;
    kpiValueGroupIdDto.kpiId = this.mapKpiIdDto(kpiValueGroupIdFm.kpiId);
    return kpiValueGroupIdDto;
  }

  static mapKpiIdDto(kpiIdFm: KpiIdFm): KpiIdDto {
    const kpiIdDto = new KpiIdDto();
    kpiIdDto.id = kpiIdFm.id;
    kpiIdDto.dashboardId = this._mapDashboardIdDto(kpiIdFm.dashboardId);
    return kpiIdDto;
  }

  static mapStructureIdDto(structureIdFm: StructureIdFm): StructureIdDto {
    const structureIdDto = new StructureIdDto();
    structureIdDto.id = structureIdFm.id;
    structureIdDto.kpiId = this.mapKpiIdDto(structureIdFm.kpiId);
    return structureIdDto;
  }

  private static _mapDashboardIdDto(dashboardIdFm: DashboardIdFm): DashboardIdDto {
    const dashboardIdDto = new DashboardIdDto();
    dashboardIdDto.id = dashboardIdFm.id;
    dashboardIdDto.publishedApplicationId = this._mapEntityIdDto(
      dashboardIdFm.publishedApplicationId
    );
    return dashboardIdDto;
  }

  private static _mapEntityIdDto(entityIdFm: EntityIdFm): EntityIdDto {
    const entityIdDto = new EntityIdDto();
    entityIdDto.idType = this._mappingEntityType[entityIdFm.idType];
    entityIdDto.internalId = entityIdFm.internalId;
    return entityIdDto;
  }

  private static _mappingEntityType: {
    [key in EntityTypeFm]: EntityType;
  } = {
    Application: "Application",
    ApplicationGroup: "ApplicationGroup",
    ApplicationVersion: "ApplicationVersion",
    Axis: "Axis",
    AxisHierarchy: "AxisHierarchy",
    AxisItem: "AxisItem",
    AxisPart: "AxisPart",
    AxisTuple: "AxisTuple",
    AxisTupleSet: "AxisTupleSet",
    Blob: "Blob",
    BlobTransaction: "BlobTransaction",
    Component: "Component",
    ComponentElement: "ComponentElement",
    ComponentElementValue: "ComponentElementValue",
    Connection: "Connection",
    ConnectionGroup: "ConnectionGroup",
    ConnectionSet: "ConnectionSet",
    ConnectionSetGroup: "ConnectionSetGroup",
    Dashboard: "Dashboard",
    DashboardKpi: "DashboardKpi",
    DashboardKpiValue: "DashboardKpiValue",
    DashboardKpiValueGroup: "DashboardKpiValueGroup",
    DashboardOfflineData: "DashboardOfflineData",
    DashboardPortalTilePageViewState: "DashboardPortalTilePageViewState",
    DataDescriptionModel: "DataDescriptionModel",
    DataSource: "DataSource",
    DesignatedConnection: "DesignatedConnection",
    Dm7Application: "Dm7Application",
    DynamicData: "DynamicData",
    Hierarchy: "Hierarchy",
    HierarchyLevel: "HierarchyLevel",
    Information: "Information",
    Kpi: "Kpi",
    KpiDrillQueryViewState: "KpiDrillQueryViewState",
    KpiDrillStructureFilterViewState: "KpiDrillStructureFilterViewState",
    KpiDrillViewState: "KpiDrillViewState",
    KpiValueGroupViewState: "KpiValueGroupViewState",
    KpiValueViewState: "KpiValueViewState",
    KpiViewState: "KpiViewState",
    LicenseAssignment: "LicenseAssignment",
    LicenseData: "LicenseData",
    OlapAttribute: "OlapAttribute",
    OlapAttributeSemantic: "OlapAttributeSemantic",
    OlapCube: "OlapCube",
    OlapHierarchy: "OlapHierarchy",
    OlapHierarchyLevel: "OlapHierarchyLevel",
    OlapMeasure: "OlapMeasure",
    OlapMeasureGroup: "OlapMeasureGroup",
    Portal: "Portal",
    PortalGroup: "PortalGroup",
    PortalPage: "PortalPage",
    PortalTile: "PortalTile",
    PortalTilePage: "PortalTilePage",
    PublishedApplication: "PublishedApplication",
    PublishedApplicationGroup: "PublishedApplicationGroup",
    Query: "Query",
    RelationalDataColumn: "RelationalDataColumn",
    RelationalDataColumnSemantic: "RelationalDataColumnSemantic",
    RelationalDataTable: "RelationalDataTable",
    ReportDefinition: "ReportDefinition",
    ReportingItem: "ReportingItem",
    RoleAssignment: "RoleAssignment",
    ScheduledTask: "ScheduledTask",
    Secret: "Secret",
    Selection: "Selection",
    SemanticModel: "SemanticModel",
    Sparkline: "Sparkline",
    SparklineViewState: "SparklineViewState",
    Structure: "Structure",
    System: "System",
    Tenant: "Tenant",
    Transaction: "Transaction",
    TransactionItem: "TransactionItem",
    Unknown: "Unknown",
    UrlApplicationDefinition: "UrlApplicationDefinition",
    User: "User",
    UserData: "UserData",
    UserGroup: "UserGroup",
    UserGroupData: "UserGroupData",
  };
}
