import { IReportFacade } from "../../backend-wrapper/report-facade-interface";
import { HierarchyLevel, Kpi, PeriodElement } from "../../backend-wrapper/dto-wrappers";
import { FailedReason } from "@/common/results/failed-reason";
import { TreeNodeVm } from "../../tabs/tab-components/modelling/trees/tree-node-vm";
import { TreeNodeEventNotifier } from "../../tabs/tab-components/modelling/trees/tree-node-event-notifier";
import { appResources } from "@/app-resources";
import { MeasureNodeLoader } from "../../tabs/tab-components/modelling/tree-loaders/measure-node-loader";
import { Result } from "@/common/results/result";
import { DimensionNodeLoader } from "../../tabs/tab-components/modelling/tree-loaders/dimension-node-loader";
import { PeriodHierarchyNodeLoader } from "../../tabs/tab-components/modelling/tree-loaders/period-node-loader";
import { DimensionRetriever } from "../../tabs/tab-components/modelling/tree-loaders/dimension-retriever";
import { exists } from "@/common/object-helper/null-helper";

export class NavigationVm {
  private readonly _reportFacade: IReportFacade;
  private readonly _currentPublishedApplication: string;
  private readonly _reportId: string;

  private _selectedLevels: HierarchyLevel[] = [];
  private _selectedPeriodElement: PeriodElement = null;
  private _selectedKpis: Kpi[] = [];
  private _periods: TreeNodeVm;
  private _dimensions: TreeNodeVm;
  private _kpis: TreeNodeVm;
  private _isLoadingData = false;
  private _dataStoreError: boolean = false;

  get selectedLevels(): HierarchyLevel[] {
    return this._selectedLevels;
  }

  get selectedKpis(): Kpi[] {
    return this._selectedKpis;
  }

  get selectedPeriodElementsDisplayText(): string {
    if (this._selectedPeriodElement) {
      const periodCaption = this._selectedPeriodElement.caption;
      return periodCaption;
    }
    return "";
  }

  get periods(): TreeNodeVm {
    return this._periods;
  }

  get dimensions(): TreeNodeVm {
    return this._dimensions;
  }

  get kpis(): TreeNodeVm {
    return this._kpis;
  }

  get isLoadingData(): boolean {
    return this._isLoadingData;
  }

  get dataStoreError(): boolean {
    return this._dataStoreError;
  }

  constructor(
    reportFacade: IReportFacade,
    currentPublishedApplication: string,
    semanticModelId: string,
    reportId: string
  ) {
    this._reportFacade = reportFacade;
    this._currentPublishedApplication = currentPublishedApplication;
    this._reportId = reportId;

    const dimensionRetriever = new DimensionRetriever(
      this._reportFacade,
      semanticModelId
    );

    this._periods = new TreeNodeVm(
      "allPeriods",
      appResources.applicationWizardTexts.modelling_periodHeader,
      new PeriodHierarchyNodeLoader(dimensionRetriever, currentPublishedApplication)
    );
    this._periods.eventNotifier = new TreeNodeEventNotifier();

    this._dimensions = new TreeNodeVm(
      "allDimensions",
      appResources.applicationWizardTexts.modelling_dimensionHeader,
      new DimensionNodeLoader(dimensionRetriever)
    );
    this._dimensions.eventNotifier = new TreeNodeEventNotifier();

    this._kpis = new TreeNodeVm(
      "allMeasures",
      appResources.applicationWizardTexts.modelling_measureHeader,
      new MeasureNodeLoader(this._reportFacade, semanticModelId)
    );
    this._kpis.eventNotifier = new TreeNodeEventNotifier();
  }

  async loadCurrentReportData() {
    this._isLoadingData = true;
    await Promise.all([
      this._loadAndSelectPeriodData(),
      this._loadAndSelectNavigationData(),
      this._loadAndSelectKpiData(),
    ]);

    this._isLoadingData = false;
  }

  private async _loadAndSelectPeriodData(): Promise<void> {
    const periodSelection =
      await this._reportFacade.getDashboardReportPeriodSelectionAsync(this._reportId);

    await this._loadAllChildren(this._periods);
    this._periods.toggleNodeSelection(periodSelection.value.name);

    this._updateCaption(periodSelection.value);

    this._selectedPeriodElement = periodSelection.value;
  }

  private _updateCaption(loadedPeriod: PeriodElement): void {
    const period = this._periods.children;

    period.forEach((period) => {
      period.children.forEach((elem) => {
        if (elem.id === loadedPeriod.name) {
          loadedPeriod.caption = elem.name;
        }
      });
    });
  }

  private async _loadAndSelectNavigationData(): Promise<void> {
    const navSelection = await this._reportFacade.getDashboardReportNavigationsAsync(
      this._reportId
    );

    await this._loadAllChildren(this._dimensions);

    navSelection.value.forEach((value) => {
      this._dimensions.toggleNodeSelection(value.id);
    });

    navSelection.value.forEach((value) => {
      this._updateLevelOrdinal(value);
    });

    this._selectedLevels = navSelection.value;
  }

  private _updateLevelOrdinal(loadedLevel: HierarchyLevel): void {
    const allDimensions = this._dimensions.children;

    allDimensions.forEach((dim) => {
      dim.children.forEach((level) => {
        if (level.id === loadedLevel.id) {
          loadedLevel.levelOrdinal = (level.value as HierarchyLevel).levelOrdinal;
        }
      });
    });
  }

  private async _loadAndSelectKpiData(): Promise<void> {
    const kpiSelection = await this._reportFacade.getDashboardReportKpisAsync(
      this._reportId
    );

    await this._loadAllChildren(this._kpis);
    kpiSelection.value.forEach((value) => {
      this._kpis.toggleNodeSelection(value.id);
    });

    this._selectedKpis = kpiSelection.value;
  }

  async togglePeriodElementSelection(elementNode: TreeNodeVm): Promise<void> {
    if (!this._isLoadingData) {
      this._dataStoreError = false;
      this._isLoadingData = true;

      const result = await NavigationVm._toggleSelection(
        elementNode,
        await this.addPeriodElement.bind(this),
        await this.removePeriodElement.bind(this)
      );

      if (!result.succeeded) {
        this._dataStoreError = true;
        elementNode.isSelected = !elementNode.isSelected;
      }

      this._isLoadingData = false;
    }
  }

  async addPeriodElement(elementNode: PeriodElement): Promise<Result<FailedReason>> {
    const resultDeletion =
      await this._reportFacade.deleteDashboardReportPeriodSelectionAsync(this._reportId);
    const resultCreation =
      await this._reportFacade.createDashboardReportPeriodSelectionAsync(
        this._currentPublishedApplication,
        this._reportId,
        elementNode.hierarchyLevelId,
        elementNode.name
      );

    const resultOperation = resultDeletion && resultCreation;

    if (resultOperation.succeeded) {
      this._selectedPeriodElement = elementNode;
    }

    return resultOperation;
  }

  removePeriodElement(): void {
    this._selectedPeriodElement = null;
  }

  async toggleLevelSelection(levelNode: TreeNodeVm): Promise<void> {
    if (!this._isLoadingData) {
      this._dataStoreError = false;
      this._isLoadingData = true;

      const result = await NavigationVm._toggleSelection(
        levelNode,
        await this.addLevel.bind(this),
        await this.removeLevel.bind(this)
      );

      if (!result.succeeded) {
        this._dataStoreError = true;
        levelNode.isSelected = !levelNode.isSelected;
      }

      this._isLoadingData = false;
    }
  }

  async addLevel(level: HierarchyLevel): Promise<Result<FailedReason>> {
    const indexToAdd = this._selectedLevels.findIndex(
      (existentLevel) =>
        level.hierarchyId === existentLevel.hierarchyId &&
        level.levelOrdinal < existentLevel.levelOrdinal
    );

    if (indexToAdd !== -1) {
      const result = await this._createLevelSelection(level, indexToAdd);
      if (!result.succeeded) return result;
    } else {
      const result = await this._createLevelSelection(level, this._selectedLevels.length);
      if (!result.succeeded) return result;
    }

    return new Result();
  }

  private async _createLevelSelection(
    level: HierarchyLevel,
    index: number
  ): Promise<Result<FailedReason>> {
    const result = await this._reportFacade.createDashboardReportNavigationAsync(
      this._reportId,
      level.id,
      index
    );

    if (result.succeeded) {
      this._selectedLevels.splice(index, 0, level);
    }
    return result;
  }

  async removeLevel(level: HierarchyLevel): Promise<Result<FailedReason>> {
    const result = await this._reportFacade.deleteDashboardReportNavigationAsync(
      this._reportId,
      level.componentId,
      level.id
    );

    if (result.succeeded) {
      NavigationVm._removeItem(this._selectedLevels, level, (item) => item.id);
    }

    return result;
  }

  async toggleKpiSelection(kpiNode: TreeNodeVm): Promise<void> {
    if (!this._isLoadingData) {
      this._dataStoreError = false;
      this._isLoadingData = true;

      const result = await NavigationVm._toggleSelection(
        kpiNode,
        await this.addKpi.bind(this),
        await this.removeKpi.bind(this)
      );

      if (!result.succeeded) {
        this._dataStoreError = true;
        kpiNode.isSelected = !kpiNode.isSelected;
      }

      this._isLoadingData = false;
    }
  }

  async addKpi(kpi: Kpi): Promise<Result<FailedReason>> {
    const result = await this._reportFacade.createDashboardReportKpiAsync(
      this._reportId,
      this.selectedKpis.length,
      kpi.id
    );

    if (result.succeeded) {
      this._selectedKpis.push(kpi);
    }

    return result;
  }

  async removeKpi(kpi: Kpi): Promise<Result<FailedReason>> {
    const result = await this._reportFacade.deleteDashboardReportKpiAsync(
      this._reportId,
      kpi.id
    );

    if (result.succeeded) {
      NavigationVm._removeItem(this._selectedKpis, kpi, (item) => item.id);
    }

    return result;
  }

  private static async _toggleSelection<T>(
    treeNode: TreeNodeVm,
    addItem: (item: T) => Promise<Result<FailedReason>>,
    removeItem: (item: T) => Promise<Result<FailedReason>>
  ): Promise<Result<FailedReason>> {
    const item = treeNode.value as T;

    if (!exists(item)) {
      return;
    }

    if (treeNode.isSelected) {
      const result = await addItem(item);
      return result;
    } else {
      const result = await removeItem(item);
      return result;
    }
  }

  private static _removeItem<T>(
    selectedItems: Array<T>,
    itemToRemove: T,
    idRetriever: (_: T) => string
  ): void {
    const itemIndex = selectedItems.findIndex(
      (item) => idRetriever(item) === idRetriever(itemToRemove)
    );

    if (itemIndex >= 0) {
      selectedItems.splice(itemIndex, 1);
    }
  }

  private async _loadAllChildren(nodeVm: TreeNodeVm) {
    if (nodeVm.isSelectable) {
      return;
    }
    await nodeVm._loadChildrenIfNotAlreadyLoadedAsync();

    for (const child of nodeVm.children) {
      await this._loadAllChildren(child);
    }
  }
}
