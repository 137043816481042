import {
  DashboardPortalTilePageDto,
  IDashboardPortalTilePageDto,
  IPortalTilePageDto,
} from "@/common/service-clients/generated-clients";
import { DashboardBuilder } from "@/features/dashboard/view-models/builder/dashboard-builder";
import { DashboardPageVm } from "./dashboard-page-vm";
import { DashboardSettings } from "@/features/dashboard-shared/dashboard-settings";
import { DashboardVm } from "@/features/dashboard/view-models/dashboard-vm";
import { DashboardId } from "@/features/dashboard-shared/dashboard-id";
import { ITilePageVm } from "../tile-page-vm.interface";
import { IPageVmBuilder } from "../page-vm-builder.interface";

const dashboardBuilder = new DashboardBuilder();

export class DashboardPageBuilder implements IPageVmBuilder {
  createVm(tilePageDto: IPortalTilePageDto): DashboardPageVm {
    const pageDto = tilePageDto as DashboardPortalTilePageDto;

    const dbSettings = new DashboardSettings();
    DashboardSettings.applyDefaultPortal(dbSettings);
    const dashboardVm = this._createEmptyDashboard(pageDto, dbSettings);
    const result = new DashboardPageVm(
      pageDto.id,
      pageDto.publishedApplicationId,
      dashboardVm
    );

    return result;
  }

  private _createEmptyDashboard(
    tilePageDto: DashboardPortalTilePageDto,
    settings: DashboardSettings
  ): DashboardVm {
    const dashboardId = new DashboardId(
      tilePageDto.publishedApplicationId,
      tilePageDto.dashboardId
    );
    const dbResult = dashboardBuilder.createDashboard(dashboardId);
    dbResult.dbSettings = settings;
    dbResult.tilePageId = tilePageDto.id;

    return dbResult;
  }

  createDto(dbPageVm: ITilePageVm): DashboardPortalTilePageDto {
    if (!this._isDashboardPage(dbPageVm)) {
      throw Error(
        "Wrong TilePage type used in DashboardPageBuilder. Got type: " + dbPageVm.type
      );
    }

    let result: DashboardPortalTilePageDto = null;

    const dbSelection = dbPageVm.dashboardVm.dashboardId;

    result = DashboardPortalTilePageDto.fromJS({
      id: null,
      portalTilePageType: "DashboardPortalTilePage",
      portalTile: null,
      publishedApplication: null,
      dashboardId: dbSelection.dashboardId,
      publishedApplicationId: dbSelection.publishedApplicationId,
      name: dbPageVm.name,
      description: dbPageVm.description ?? "",
    } as IDashboardPortalTilePageDto);
    return result;
  }

  private _isDashboardPage(tilePageVm: ITilePageVm): tilePageVm is DashboardPageVm {
    return tilePageVm.type === "Dashboard";
  }
}
