<script lang="ts">
import { defineComponent, reactive, computed, ref, inject, watch } from "vue";
import { isInRect } from "@/common/events/dom-event-helper";
import type { CSSProperties } from "vue/types/jsx";
import { IContextMenuService } from "@/common/components/context-menu/context-menu-service.interface";
import { ContextMenuItem } from "@/common/components/context-menu/context-menu-item";
import MenuItem from "@/common/components/context-menu/context-menu-item.vue";

class ContextMenuRootState {
  posX: number = 0;
  posY: number = 0;
}

export default defineComponent({
  components: { MenuItem },
  emits: [],
  props: {},
  setup() {
    const ref_contextMenu = ref(null);
    const state = reactive(new ContextMenuRootState());
    const contextMenuService = inject<IContextMenuService>("contextMenuService");

    //
    // Life Cycle:
    // --------------------

    //
    // Computeds:
    // --------------------
    const contextMenuStyle = computed<CSSProperties>(() => {
      const x = state.posX;
      const y = state.posY;
      return {
        left: x + "px",
        top: y + "px",
        visibility: x === 0 && y === 0 ? "hidden" : "visible",
      };
    });

    //
    // Functions:
    // --------------------
    function onContextMenuRootClick(event: MouseEvent): void {
      if (!contextMenuService.isMenuOpen || !event || !event.target) {
        return;
      }
      event.stopPropagation();

      const contextMenu = ref_contextMenu.value as HTMLDivElement;

      const menuClicked = isInRect(
        event.clientX,
        event.clientY,
        contextMenu.getBoundingClientRect()
      );

      if (menuClicked) {
        return;
      }

      contextMenuService.onHideContextMenu();
    }

    async function onMenuItemClicked(menuItem: ContextMenuItem): Promise<void> {
      contextMenuService.onHideContextMenu();

      if (!menuItem || !menuItem.callback) {
        return;
      }

      menuItem.callback();
    }

    function showMenuAtViewPort(): void {
      if (
        contextMenuService.menuItems.length === 0 &&
        contextMenuService.topIcons.length === 0
      ) {
        contextMenuService.onHideContextMenu();
        return;
      }

      state.posX = 0;
      state.posY = 0;

      const contextMenu = ref_contextMenu.value as HTMLDivElement;
      if (!contextMenu) {
        setTimeout(() => showMenuAtViewPort(), 0);
        return;
      }

      const menuHeight = contextMenu.clientHeight;
      const menuWidth = contextMenu.clientWidth;
      const offsetMouse = 5;
      const offsetMenu = 20;
      const viewPortInnerWidth = window.innerWidth - offsetMenu;
      const viewPortInnerHeight = window.innerHeight - offsetMenu;

      let x = contextMenuService.posX + offsetMouse;
      let y = contextMenuService.posY + offsetMouse;

      if (x + menuWidth > viewPortInnerWidth) {
        x = viewPortInnerWidth - menuWidth;
      }
      if (y + menuHeight > viewPortInnerHeight) {
        y = viewPortInnerHeight - menuHeight;
      }

      if (isNaN(x) || isNaN(y)) {
        return;
      }

      state.posX = x;
      state.posY = y;
    }

    //
    // Watcher:
    // --------------------
    watch(() => contextMenuService.isMenuOpen, showMenuAtViewPort);

    return {
      state,
      ref_contextMenu,
      contextMenuService,
      contextMenuStyle,
      onContextMenuRootClick,
      onMenuItemClicked,
    };
  },
});
</script>

<template>
  <div
    v-if="contextMenuService.isMenuOpen"
    class="context-menu-root"
    v-on:mouseup="onContextMenuRootClick"
  >
    <div ref="ref_contextMenu" class="context-menu" v-bind:style="contextMenuStyle">
      <div
        class="top-menu-items"
        v-if="contextMenuService.topIcons && contextMenuService.topIcons.length > 0"
      >
        <MenuItem
          v-for="(menuItem, index) in contextMenuService.topIcons"
          v-bind:key="index"
          v-bind:icon="menuItem.icon"
          v-bind:smallIcon="true"
          v-bind:showBottomLine="false"
          v-on:menuItemClicked="onMenuItemClicked(menuItem)"
        />
      </div>
      <MenuItem
        v-for="(menuItem, index) in contextMenuService.menuItems"
        v-bind:key="menuItem.text + index"
        v-bind:icon="menuItem.icon"
        v-bind:text="menuItem.text"
        v-bind:showBottomLine="index !== contextMenuService.menuItems.length - 1"
        v-on:menuItemClicked="onMenuItemClicked(menuItem)"
      />
    </div>
  </div>
</template>

<style scoped lang="less">
.context-menu-root {
  position: fixed;
  width: 100%;
  height: 100%;

  .top-menu-items {
    display: flex;
    height: 22px;
    border-bottom: 1px solid var(--color_bg-gray);
  }

  .context-menu {
    position: absolute;
    background-color: hsla(0, 0%, 100%, 0.8);
    border: 1px solid var(--color_bg-gray);
    border-radius: 4px;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(3px);
  }
}
</style>
