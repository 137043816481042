import { PortalVm } from "../view-models/portal-vm";
import { ViewStateFacade } from "../backend-wrapper/view-state-facade";
import { PortalVsSyncer } from "./vs-syncer";
import { inject, onBeforeUnmount } from "vue";
import { ISyncerRegistrationService } from "@/services/view-state-service/contract/syncer-registration-service.interface";
import { VsScopeState } from "@/services/view-state-service/vs-scope-state";
import { IVsSyncer } from "@/services/view-state-service/vs-syncer-base";

// TODO: make sure that vs-system registration stays optional for Testing:View-VM
export function useRegisterVsSyncer(
  portalVm: PortalVm,
  portalViewStateFacade: ViewStateFacade,
  vsScopeState?: VsScopeState
): void {
  let vsSyncer: IVsSyncer = null;
  const syncerRegistrationService = inject<ISyncerRegistrationService>(
    "syncerRegistrationService"
  );

  if (!vsScopeState) {
    vsScopeState = inject<VsScopeState>("vsScopeState", null);
  }
  if (!syncerRegistrationService) {
    return;
  }

  if (vsScopeState.isInError.value) {
    return;
  }
  vsSyncer = startSyncer();

  // TODO: in future open sync-jobs should be run till the end.
  //       so on unmount the syncer-instance should be informed,
  //       but not directly 'disposed' and 'unregistered'.
  //       Before doing these last steps the syncer must finish
  //       all open jobs.
  onBeforeUnmount(() => {
    killSyncer();
  });

  function startSyncer(): IVsSyncer {
    const vsSyncer: IVsSyncer = new PortalVsSyncer(
      portalVm,
      vsScopeState,
      portalViewStateFacade
    );

    syncerRegistrationService.register(vsSyncer);
    return vsSyncer;
  }

  function killSyncer(): void {
    if (!vsSyncer) {
      return;
    }

    syncerRegistrationService.unregister(vsSyncer);
    vsSyncer.dispose();
    vsSyncer = null;
  }
}
