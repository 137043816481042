import { IBiColorThresholdsDto } from "../service-clients/generated-clients";
import { exists, existsAndNotZero } from "@/common/object-helper/null-helper";

export class BiColorThresholds {
  threshold?: number = 0;
  rangeLow?: number = 0;
  rangeHigh?: number = 0;

  constructor(dto?: IBiColorThresholdsDto) {
    if (!dto) return;
    this.threshold = dto.threshold;
    this.rangeLow = dto.rangeLow;
    this.rangeHigh = dto.rangeHigh;
  }

  clone(): BiColorThresholds {
    const clone = new BiColorThresholds();

    clone.threshold = this.threshold;
    clone.rangeHigh = this.rangeHigh;
    clone.rangeLow = this.rangeLow;

    return clone;
  }

  isBiColorRangeActive(): boolean {
    const rangeValuesAreSet = exists(this.rangeHigh) && exists(this.rangeLow);
    const notBothRangesZero = !(this.rangeHigh === 0 && this.rangeLow === 0);
    return rangeValuesAreSet && notBothRangesZero;
  }

  isThresholdActive(): boolean {
    return existsAndNotZero(this.threshold);
  }

  isAnyThresholdActive(): boolean {
    return this.isBiColorRangeActive() || this.isThresholdActive();
  }
}
