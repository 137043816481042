import {
  ApplicationDto,
  IApplicationGroupsServiceClient,
  IApplicationVersionsServiceClient,
  IApplicationsServiceClient,
  IPublishedApplicationsServiceClient,
  PublishedApplicationDto,
} from "@/common/service-clients/generated-clients";
import { ApplicationGroup } from "./dto-wrappers";
import { ValueResult } from "@/common/results/value-result";
import { FailedReason } from "@/common/results/failed-reason";
import { IApplicationFacade } from "./application-facade.interface";
import { ApplicationNameLink } from "@/features/application-wizard/backend-wrapper/application-name-link";
import { AppEntry } from "../helper-components/menus/app-entry";

export class ApplicationFacade implements IApplicationFacade {
  constructor(
    private readonly _applicationServiceClient: IApplicationsServiceClient,
    private readonly _applicationVersionsServiceClient: IApplicationVersionsServiceClient,
    private readonly _publishedApplicationServiceClient: IPublishedApplicationsServiceClient,
    private readonly _applicationGroupsServiceClient: IApplicationGroupsServiceClient
  ) {}

  async getAllApplicationGroupsAsync(): Promise<
    ValueResult<ApplicationGroup[], FailedReason>
  > {
    try {
      const applicationGroups =
        await this._applicationGroupsServiceClient.getApplicationGroups();

      return ValueResult.createFromValue(applicationGroups);
    } catch (e) {
      return ValueResult.createFromError(e);
    }
  }

  async getAllApplicationsAsync(): Promise<ValueResult<AppEntry[], FailedReason>> {
    try {
      const applications = await this._applicationServiceClient.getApplications(
        null,
        "Admin",
        ["DeltaMaster"]
      );

      const publishedApplications =
        await this._publishedApplicationServiceClient.getPublishedApplications(
          null,
          "Admin",
          ["Dashboard", "DeltaMaster"]
        );

      const applicationsPublishedByAppWizard: AppEntry[] =
        await this._identifyApplicationsPublishedByAppWizardAsync(
          applications,
          publishedApplications
        );

      return ValueResult.createFromValue(applicationsPublishedByAppWizard);
    } catch (e) {
      return ValueResult.createFromError(e);
    }
  }

  private async _identifyApplicationsPublishedByAppWizardAsync(
    applications: ApplicationDto[],
    publishedApplications: PublishedApplicationDto[]
  ): Promise<AppEntry[]> {
    const applicationsPublishedByAppWizard: AppEntry[] = [];

    for (const publishedApplication of publishedApplications) {
      if (publishedApplication.applicationType === "DeltaMaster") {
        const dashboardApplicationName =
          ApplicationNameLink.getDashboardPublishedApplicationName(
            publishedApplication.name
          );

        const dashboardPubApp = this._findAssociatedDashboardApplication(
          publishedApplications,
          dashboardApplicationName
        );

        if (dashboardPubApp) {
          const application = await this._createApplication(
            applications,
            publishedApplication,
            dashboardPubApp
          );

          if (application) {
            applicationsPublishedByAppWizard.push(application);
          }
        }
      }
    }

    return applicationsPublishedByAppWizard;
  }

  private _findAssociatedDashboardApplication(
    publishedApplications: PublishedApplicationDto[],
    dashboardApplicationName: string
  ): PublishedApplicationDto | undefined {
    return publishedApplications.find(
      (application) =>
        application.applicationType === "Dashboard" &&
        application.name === dashboardApplicationName
    );
  }

  private async _createApplication(
    applications: ApplicationDto[],
    deltaMasterPubApp: PublishedApplicationDto,
    dashboardPubApp: PublishedApplicationDto
  ): Promise<AppEntry | null> {
    const applicationVersion =
      await this._applicationVersionsServiceClient.getApplicationVersion(
        deltaMasterPubApp.applicationVersion.id
      );

    const matchingApplication = applications.filter(
      (application) => application.id === applicationVersion.applicationId
    );

    if (matchingApplication.length === 1) {
      const app = matchingApplication[0];
      return new AppEntry(app, deltaMasterPubApp, dashboardPubApp);
    }
    return null;
  }
}
