import {
  DashboardPortalTilePageViewStateDto,
  KpiDrillStructureFilterViewStateDto,
  KpiViewStateDto,
  SparklineMode,
  SparklineViewStateDto,
  IKpiDrillStructureFilterViewStateDto,
  KpiValueGroupViewStateDto,
  KpiDrillViewStateDto,
  KpiValueViewStateDto,
  KpiDrillQueryViewStateDto,
  KpiDrillSortParameterViewStateDto,
  KpiValueIdDto,
  KpiValueGroupIdDto,
  ElementIdDto,
  IDashboardPortalTilePageViewStateDto,
  IViewStateDto,
  SparklineBarSelectionViewStateDto,
} from "@/common/service-clients/generated-clients";
import { DashboardVm } from "@/features/dashboard/view-models/dashboard-vm";
import { KpiTileVm } from "@/features/dashboard/view-models/kpi-tile-vm";
import { ValueVm } from "@/features/dashboard/view-models/value-vm";
import { getGUID } from "@/common/helper/guid-helper";
import { SharedDashboardStateVm } from "@/features/dashboard/view-models/shared/shared-dashboard-state-vm";
import { StructureElementsListVm } from "@/features/dashboard/view-models/structure-elements-list-vm";
import { SharedDrillInfoVm } from "@/features/dashboard/view-models/shared/shared-drill-info-vm";
import { DtoHelper } from "@/common/service-clients/dto-helper";
import { StructureElementsVm } from "@/features/dashboard/view-models/structure-elements-vm";
import { VsRoot } from "@/services/view-state-service/contract/vs-root";
import { IVsGenerator } from "@/services/view-state-service/contract/vs-generator.interface";
import { KpiDrillStructureFilterFm } from "@/features/dashboard/backend-wrapper/facade-models-dashboard";
import { FmDtoMapperDashboardShared } from "@/features/dashboard-shared/backend-wrapper/fm-dto-mapper-dashboard-shared";
import { FmDtoMapperSparklines } from "@/features/dashboard-shared/sparkline/backend-wrapper/fm-dto-mapper-sparklines";
import { TimeStructureTypeFm } from "@/features/dashboard-shared/sparkline/backend-wrapper/facade-models-sparklines";

export class DashboardVsGenerator implements IVsGenerator {
  private _originalViewState: IDashboardPortalTilePageViewStateDto = null;
  private _dashboardVm: DashboardVm = null;

  private get _sharedState(): SharedDashboardStateVm {
    return this._dashboardVm.sharedState;
  }

  private get _filters(): KpiDrillStructureFilterFm[] {
    return this._dashboardVm.filters.filters;
  }

  private get _kpiTileVms(): KpiTileVm[] {
    return this._dashboardVm.kpiTileVms;
  }

  getViewState(viewModel: VsRoot, originalViewState?: IViewStateDto): IViewStateDto {
    this._originalViewState = originalViewState as IDashboardPortalTilePageViewStateDto;
    this._dashboardVm = viewModel as DashboardVm;
    const viewState = new DashboardPortalTilePageViewStateDto();

    viewState.portalTilePageId = this._dashboardVm.tilePageId;
    viewState.id = this._originalViewState?.id ?? null;
    viewState.portalTileViewStateId =
      this._originalViewState?.portalTileViewStateId ?? null;

    viewState.scaledColumn = this._sharedState.kpiScaleIndex;
    viewState.shownSecondValue = this._getShownSecondValue();
    viewState.sparkline = this._getSparklineViewStateDto();
    viewState.kpiDrillStructureFilters = this._getKpiDrillStructureFilterViewStateDtos();
    viewState.kpis = this._getKpiViewStateDtos();
    viewState.sortIndex = 0;

    const dbSettings = this._dashboardVm.dbSettings;
    if (dbSettings.isExtended && dbSettings.isCompact) {
      viewState.compactStyle = this._dashboardVm.isExpandingKpis ? 1 : 2;
    } else {
      viewState.compactStyle = 0;
    }

    return viewState;
  }

  private _getShownSecondValue(): number {
    if (
      this._sharedState.sparklineState.showSparklines &&
      this._sharedState.kpiScaleIndex < 1
    ) {
      return 0;
    }

    if (this._sharedState.hiddenColumn === 1) {
      return 2;
    }

    if (this._sharedState.hiddenColumn === 2) {
      return 1;
    }

    return 0;
  }

  private _getSparklineViewStateDto(): SparklineViewStateDto {
    const sparklineViewStateDto = new SparklineViewStateDto();

    sparklineViewStateDto.portalTilePageViewStateId = this._originalViewState?.id ?? null;
    sparklineViewStateDto.id = this._originalViewState?.sparkline?.id ?? null;
    sparklineViewStateDto.sparklineMode = this._getSparklineMode();
    sparklineViewStateDto.sparklineBarSelection =
      this._getDefaultSparklineBarSelectionViewStateDto();
    const sparklineBarSelection = this._getSparklineBarSelection();
    this._setSparklineBarSelection(
      sparklineBarSelection,
      sparklineViewStateDto.sparklineBarSelection
    );

    return sparklineViewStateDto;
  }

  private _getDefaultSparklineBarSelectionViewStateDto(): SparklineBarSelectionViewStateDto {
    return new SparklineBarSelectionViewStateDto({
      dayHistoryIndex: 0,
      dayHistoryOffset: -1,
      weekHistoryIndex: 0,
      weekHistoryOffset: -1,
      monthHistoryIndex: 0,
      monthHistoryOffset: -1,
      quarterHistoryIndex: 0,
      quarterHistoryOffset: -1,
      yearHistoryIndex: 0,
      yearHistoryOffset: -1,
      otherHistoryIndex: 0,
      otherHistoryOffset: -1,
    });
  }

  private _getSparklineBarSelection(): Map<
    TimeStructureTypeFm,
    { historyIndex: number; historyOffset: number }
  > {
    const shownTiles = this._dashboardVm.shownKpiTileVms;
    const deltaSparklinesEnabled =
      this._sharedState.sparklineState.deltaSparklinesEnabled;

    const historyData = new Map<
      TimeStructureTypeFm,
      { historyIndex: number; historyOffset: number }
    >();

    for (let i = 0; i < shownTiles.length; i++) {
      const kpiTileVm = shownTiles[i];
      const index = deltaSparklinesEnabled
        ? kpiTileVm.currentValueGroup.getScaleIndex(
            this._dashboardVm.sharedState.kpiScaleIndex
          )
        : 0;
      const valueVm = kpiTileVm.currentValueGroup.kpiValues[index];

      if (!valueVm.hasSparkline) {
        continue;
      }

      const sparklineState = this._dashboardVm.sharedState.sparklineState;
      const timeStructure = valueVm.sparkline.timeStructure;
      let historyIndex = sparklineState.selection[timeStructure].historyIndex;

      if (historyIndex === 0) {
        const isMouseHoverEnabled =
          sparklineState.selection[timeStructure].isMouseHoverEnabled;
        historyIndex = isMouseHoverEnabled ? 0 : -1;
      }

      const historyOffset =
        sparklineState.scrollPosPixel /
        (sparklineState.barMargin + sparklineState.barWidth);

      if (!historyData.has(timeStructure)) {
        historyData.set(timeStructure, { historyIndex, historyOffset });
      }
    }

    return historyData;
  }

  private _setSparklineBarSelection(
    sparklineBarSelection: Map<
      TimeStructureTypeFm,
      { historyIndex: number; historyOffset: number }
    >,
    sparklineBarSelectionVSDto: SparklineBarSelectionViewStateDto
  ): void {
    for (const [
      key,
      { historyIndex, historyOffset },
    ] of sparklineBarSelection.entries()) {
      if (key === "Day") {
        sparklineBarSelectionVSDto.dayHistoryIndex = historyIndex;
        sparklineBarSelectionVSDto.dayHistoryOffset = historyOffset;
      } else if (key === "Week") {
        sparklineBarSelectionVSDto.weekHistoryIndex = historyIndex;
        sparklineBarSelectionVSDto.weekHistoryOffset = historyOffset;
      } else if (key === "Month") {
        sparklineBarSelectionVSDto.monthHistoryIndex = historyIndex;
        sparklineBarSelectionVSDto.monthHistoryOffset = historyOffset;
      } else if (key === "Year") {
        sparklineBarSelectionVSDto.yearHistoryIndex = historyIndex;
        sparklineBarSelectionVSDto.yearHistoryOffset = historyOffset;
      } else if (key === "Quarter") {
        sparklineBarSelectionVSDto.quarterHistoryIndex = historyIndex;
        sparklineBarSelectionVSDto.quarterHistoryOffset = historyOffset;
      } else {
        sparklineBarSelectionVSDto.otherHistoryIndex = historyIndex;
        sparklineBarSelectionVSDto.otherHistoryOffset = historyOffset;
      }
    }
  }

  private _getSparklineMode(): SparklineMode {
    return FmDtoMapperSparklines.mapSparklineModeDto(
      this._sharedState.sparklineState.mode
    );
  }

  private _getKpiDrillStructureFilterViewStateDtos(): KpiDrillStructureFilterViewStateDto[] {
    if (this._filters.length === 0) {
      return [];
    }

    const kpiDrillStructureFilters: KpiDrillStructureFilterViewStateDto[] = [];

    this._filters.forEach((filter, filterIndex) => {
      const kpiDrillStructureFilterViewStateDto =
        new KpiDrillStructureFilterViewStateDto();
      kpiDrillStructureFilterViewStateDto.structureElementName = filter.elementId;
      kpiDrillStructureFilterViewStateDto.structureName = filter.structureNameId;
      kpiDrillStructureFilterViewStateDto.id = this._getKpiDrillStructureFilterId(
        filterIndex,
        kpiDrillStructureFilterViewStateDto
      );
      kpiDrillStructureFilters.push(kpiDrillStructureFilterViewStateDto);
    });

    return kpiDrillStructureFilters;
  }

  private _getKpiDrillStructureFilterId(
    allFiltersIndex: number,
    filterWithoutId: IKpiDrillStructureFilterViewStateDto
  ): string {
    const allFiltersWithIds = this._originalViewState
      ? this._originalViewState.kpiDrillStructureFilters
      : [];

    if (!allFiltersWithIds || allFiltersIndex >= allFiltersWithIds.length) {
      return null;
    }

    const initialFilter = allFiltersWithIds[allFiltersIndex];
    if (DtoHelper.FilterDtos.isSameFilter(initialFilter, filterWithoutId)) {
      return initialFilter.id;
    }

    return null;
  }

  private _getKpiViewStateDtos(): KpiViewStateDto[] {
    return this._kpiTileVms?.map((kpiTileVm, kpiIndex) => {
      const kpiViewStateDto = new KpiViewStateDto();

      kpiViewStateDto.portalTilePageViewStateId = this._originalViewState?.id ?? null;
      kpiViewStateDto.id = this._getKpiViewStateDtoId(kpiIndex);
      kpiViewStateDto.kpiId = FmDtoMapperDashboardShared.mapKpiIdDto(
        kpiTileVm.backingFm.kpiId
      );
      kpiViewStateDto.isShown = kpiTileVm.isShown;
      kpiViewStateDto.kpiValueGroups = this._getKpiValueGroupViewStateDto(
        kpiTileVm,
        kpiViewStateDto.id
      );
      return kpiViewStateDto;
    });
  }

  private _getKpiViewStateDtoId(kpiIndex: number): string {
    if (!this._originalViewState || this._originalViewState.kpis.length <= kpiIndex) {
      return null;
    }

    return this._originalViewState.kpis[kpiIndex].id;
  }

  private _getKpiValueGroupViewStateDto(
    kpiTileVm: KpiTileVm,
    kpiViewStateId: string
  ): KpiValueGroupViewStateDto[] {
    return kpiTileVm.valueGroupVms.map((valueGroupVm, valueGroupIndex) => {
      const kpiValueGroupViewStateDto = new KpiValueGroupViewStateDto();
      kpiValueGroupViewStateDto.kpiViewStateId = kpiViewStateId;
      kpiValueGroupViewStateDto.id = this._getKpiValueGroupViewStateDtoId(
        kpiViewStateId,
        valueGroupIndex
      );
      kpiValueGroupViewStateDto.kpiValueGroupId =
        FmDtoMapperDashboardShared.mapKpiValueGroupIdDto(
          kpiTileVm.backingFm.valueGroups[valueGroupIndex].kpiValueGroupId
        );
      kpiValueGroupViewStateDto.isSelected = kpiTileVm.currentValueGroup === valueGroupVm;
      kpiValueGroupViewStateDto.drills = this._getKpiDrillViewStateDtos(
        valueGroupVm.structureElementsVm.isVisible,
        valueGroupVm.structureElementsVm,
        FmDtoMapperDashboardShared.mapKpiValueGroupIdDto(
          kpiTileVm.backingFm.valueGroups[valueGroupIndex].kpiValueGroupId
        ),
        kpiViewStateId,
        kpiValueGroupViewStateDto.id,
        null,
        null
      );
      kpiValueGroupViewStateDto.values = this._getKpiValueViewStateDtos(
        valueGroupVm.kpiValues,
        kpiViewStateId,
        kpiValueGroupViewStateDto.id
      );
      return kpiValueGroupViewStateDto;
    });
  }

  private _getKpiValueGroupViewStateDtoId(
    kpiViewStateId: string,
    valueGroupIndex: number
  ): string {
    if (!this._originalViewState || !this._originalViewState.kpis) {
      return null;
    }

    const kpi = this._originalViewState.kpis.find((kpi) => kpi.id === kpiViewStateId);
    if (!kpi || !kpi.kpiValueGroups || kpi.kpiValueGroups.length <= valueGroupIndex) {
      return null;
    }

    return kpi.kpiValueGroups[valueGroupIndex].id;
  }

  private _getKpiDrillViewStateDtos(
    showStructureElements: boolean,
    structureElementsVm: StructureElementsVm,
    kpiValueGroupIdDto: KpiValueGroupIdDto,
    kpiViewStateId: string,
    kpiValueGroupViewStateId: string,
    elementId: ElementIdDto = null,
    parentReferenceKey: string = null
  ): KpiDrillViewStateDto[] {
    if (!showStructureElements) {
      return [];
    }
    const structureElementsListVm = structureElementsVm.currentStructureElementsListVm;
    const originalDrill = this._getOriginalKpiDrillViewState(
      structureElementsListVm,
      kpiViewStateId,
      kpiValueGroupViewStateId,
      elementId,
      parentReferenceKey
    );
    const kpiDrillViewStateDtos: KpiDrillViewStateDto[] = [];
    const kpiDrillViewStateDto = new KpiDrillViewStateDto();

    kpiDrillViewStateDto.kpiValueGroupViewStateId = kpiValueGroupViewStateId;
    kpiDrillViewStateDto.id = originalDrill?.id ?? null;
    kpiDrillViewStateDto.parentReferenceKey = parentReferenceKey;
    if (originalDrill) {
      kpiDrillViewStateDto.referenceKey = originalDrill.referenceKey;
    } else {
      kpiDrillViewStateDto.referenceKey = getGUID();
    }
    kpiDrillViewStateDto.shownSecondValue = this._getDrillShownSecondValue(
      structureElementsListVm
    );
    kpiDrillViewStateDto.elementId = elementId;
    kpiDrillViewStateDto.isPercentageMode = structureElementsListVm.isPercentageMode;
    kpiDrillViewStateDto.kpiDrillQuery = this._getDrillQuery(
      kpiDrillViewStateDto.id,
      originalDrill?.kpiDrillQuery?.id,
      kpiValueGroupIdDto,
      structureElementsVm,
      originalDrill?.kpiDrillQuery?.kpiDrillStructureFilters
    );
    kpiDrillViewStateDtos.push(kpiDrillViewStateDto);

    const childrenDrills: KpiDrillViewStateDto[] = [];
    for (let i = 0; i < structureElementsListVm.elementVms.length; i++) {
      if (!structureElementsListVm.elementVms[i].nextStructureElements.isVisible) {
        continue;
      }

      const currentStructureElementsVm =
        structureElementsListVm.elementVms[i].nextStructureElements;
      const elementId = structureElementsListVm.elementVms[i].backingFm.elementId;
      const drills = this._getKpiDrillViewStateDtos(
        true,
        currentStructureElementsVm,
        kpiValueGroupIdDto,
        kpiViewStateId,
        kpiValueGroupViewStateId,
        FmDtoMapperDashboardShared.mapElementIdDto(elementId),
        kpiDrillViewStateDto.referenceKey
      );
      childrenDrills.push(...drills);
    }

    kpiDrillViewStateDtos.push(...childrenDrills);

    return kpiDrillViewStateDtos;
  }

  private _getOriginalKpiDrillViewState(
    structureElementsListVm: StructureElementsListVm,
    kpiViewStateId: string,
    kpiValueGroupViewStateId: string,
    elementId: ElementIdDto = null,
    parentReferenceKey: string = null
  ): KpiDrillViewStateDto {
    if (!this._originalViewState || !this._originalViewState.kpis) {
      return;
    }

    const kpi = this._originalViewState.kpis.find((kpi) => kpi.id === kpiViewStateId);

    if (!kpi || !kpi.kpiValueGroups) {
      return null;
    }

    const valueGroup = kpi.kpiValueGroups.find(
      (valueGroup) => valueGroup.id == kpiValueGroupViewStateId
    );

    if (!valueGroup || !valueGroup.drills) {
      return null;
    }

    const structureId = structureElementsListVm.structureVm.structureId.id;

    return valueGroup.drills.find((drill) =>
      this._isSameDrill(drill, parentReferenceKey, structureId, elementId)
    );
  }

  private _isSameDrill(
    drill: KpiDrillViewStateDto,
    parentReferenceKey: string,
    structureId: number,
    elementId: ElementIdDto
  ): boolean {
    if (
      drill.parentReferenceKey !== parentReferenceKey ||
      structureId !== drill.kpiDrillQuery.groupBy.id
    ) {
      return false;
    }

    const isFirstDrill = drill.elementId === null && elementId === null;

    if (isFirstDrill) {
      return true;
    }

    return (
      drill.elementId !== null &&
      elementId !== null &&
      drill.elementId.id === elementId.id
    );
  }

  private _getKpiValueViewStateDtos(
    kpiValues: ValueVm[],
    kpiViewStateId: string,
    kpiValueGroupViewStateId: string
  ): KpiValueViewStateDto[] {
    return kpiValues.map((kpiValue, valueIndex) => {
      const kpiValueViewStateDto = new KpiValueViewStateDto();

      kpiValueViewStateDto.kpiValueGroupViewStateId = kpiValueGroupViewStateId;
      kpiValueViewStateDto.id = this._getKpiValueViewStateDtoId(
        kpiViewStateId,
        kpiValueGroupViewStateId,
        valueIndex
      );
      kpiValueViewStateDto.kpiValueId = FmDtoMapperDashboardShared.mapKpiValueIdDto(
        kpiValue.backingFm.kpiValueId
      );
      kpiValueViewStateDto.isExcludedFromScaling = kpiValue.excludedFromScaling;

      return kpiValueViewStateDto;
    });
  }

  private _getKpiValueViewStateDtoId(
    kpiViewStateId: string,
    kpiValueGroupViewStateId: string,
    valueIndex: number
  ): string {
    if (!this._originalViewState || !this._originalViewState.kpis) {
      return null;
    }

    const kpi = this._originalViewState.kpis.find((kpi) => kpi.id === kpiViewStateId);
    if (!kpi || !kpi.kpiValueGroups) {
      return null;
    }

    const kpiValueGroup = kpi.kpiValueGroups.find(
      (kpiValueGroup) => kpiValueGroup.id === kpiValueGroupViewStateId
    );

    if (
      !kpiValueGroup ||
      !kpiValueGroup.values ||
      kpiValueGroup.values.length <= valueIndex
    ) {
      return null;
    }

    return kpiValueGroup.values[valueIndex].id;
  }

  private _getDrillShownSecondValue(
    currentStructureElementsListVm: StructureElementsListVm
  ): number {
    if (
      this._sharedState.sparklineState.showSparklines ||
      !currentStructureElementsListVm.deltaValuesSwiperVm
    ) {
      return 0;
    }

    return currentStructureElementsListVm.deltaValuesSwiperVm.activeIndex;
  }

  private _getDrillQuery(
    kpiDrillViewStateId: string,
    kpiDrillQueryViewStateId: string,
    kpiValueGroupIdDto: KpiValueGroupIdDto,
    structureElementsVm: StructureElementsVm,
    originalKpiDrillStructureFilters: KpiDrillStructureFilterViewStateDto[]
  ): KpiDrillQueryViewStateDto {
    const structureElementsListVm = structureElementsVm.currentStructureElementsListVm;
    const kpiDrillQueryViewStateDto = new KpiDrillQueryViewStateDto();

    kpiDrillQueryViewStateDto.kpiDrillViewStateId = kpiDrillViewStateId;
    kpiDrillQueryViewStateDto.id = kpiDrillQueryViewStateId;
    kpiDrillQueryViewStateDto.groupBy = FmDtoMapperDashboardShared.mapStructureIdDto(
      structureElementsListVm.structureVm.structureId
    );
    kpiDrillQueryViewStateDto.resultLimit = structureElementsListVm.drillResultLimit ?? 0;
    kpiDrillQueryViewStateDto.kpiSortParameter = new KpiDrillSortParameterViewStateDto();
    kpiDrillQueryViewStateDto.kpiSortParameter.sortType = structureElementsVm.sortType;
    kpiDrillQueryViewStateDto.kpiSortParameter.valueId = new KpiValueIdDto({
      id: structureElementsVm.sortedColumn,
      kpiValueGroupId: kpiValueGroupIdDto,
    });
    kpiDrillQueryViewStateDto.kpiDrillStructureFilters =
      this._getKpiDrillStructureFilterViewStateDto(
        structureElementsListVm.sharedDrillInfo,
        structureElementsListVm.previousFilters,
        originalKpiDrillStructureFilters
      );

    return kpiDrillQueryViewStateDto;
  }

  private _getKpiDrillStructureFilterViewStateDto(
    sharedDrillInfo: SharedDrillInfoVm,
    previousFilters: KpiDrillStructureFilterFm[],
    originalKpiDrillStructureFilters: KpiDrillStructureFilterViewStateDto[]
  ): KpiDrillStructureFilterViewStateDto[] {
    const drillStructureFilters = [
      ...sharedDrillInfo.dashboardFilter.filters,
      ...previousFilters,
    ];
    return drillStructureFilters.map((filter) => {
      const filterViewState = new KpiDrillStructureFilterViewStateDto();
      const originalVs = originalKpiDrillStructureFilters
        ? originalKpiDrillStructureFilters.find(
            (vsFilter) =>
              vsFilter.structureName === filter.structureNameId &&
              vsFilter.structureElementName === filter.elementId
          )
        : null;
      filterViewState.id = originalVs?.id ? originalVs.id : null;
      filterViewState.structureElementName = filter.elementId;
      filterViewState.structureName = filter.structureNameId;
      return filterViewState;
    });
  }
}
