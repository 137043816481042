<script lang="ts">
import RangeSlider from "@/common/components/range-slider.vue";

import { roundToDecimal } from "@/common/formatting/rounding";
import { IZoomService } from "@/services/zoom-service.interface";
import {
  computed,
  ComputedRef,
  inject,
  ref,
  reactive,
  watch,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  defineComponent,
} from "vue";
import { FontScaler } from "@/common/formatting/font-scaler";
import { IDisposable } from "@/common/disposable.interface";
import { DM7Timeout } from "@/common/helper/dm7-timeout";

export default defineComponent({
  components: {
    RangeSlider,
  },

  setup() {
    const zoomService: IZoomService = inject("zoomService");

    const state = reactive({
      zoomPercent: zoomService.factor,
      hideTimeout: null as DM7Timeout,
      displayText: false,
      scaledFontSize: 16,
      zMax: zoomService.maxZoomFactor,
      zMin: zoomService.minZoomFactor,
    });

    const fontScaler = new FontScaler(16, 33);
    const listeners = ref<IDisposable[]>([]);

    onBeforeMount(() => {
      listeners.value.push(zoomService.factorChanged.on(onZoomSetExternally));
      listeners.value.push(zoomService.maxFactorChanged.on(updateZMax));
    });

    onMounted(() => {
      updateZMax();
    });

    onBeforeUnmount(() => {
      listeners.value.forEach((l) => l.dispose());
    });

    const zoomPercentDisplay: ComputedRef<string> = computed(() => {
      return roundToDecimal(state.zoomPercent * 100, 0) + "";
    });

    function updateZMax() {
      state.zMax = zoomService.maxZoomFactor;
      onZoomSliderChange();
    }

    function onZoomSetExternally(): void {
      state.zoomPercent = zoomService.factor;
      showTimeoutText();
      startHideTextTimeout();
    }

    function onZoomSliderChange(): void {
      zoomService.factor = state.zoomPercent;
      const minScaledValue = 1; // values smaller 100% are not to be scaled
      const fontClass = fontScaler.getFontClass(
        zoomService.factor,
        minScaledValue,
        state.zMax
      );
      state.scaledFontSize = fontScaler.getFontSize(fontClass);
    }

    function onMouseEnter(): void {
      showTimeoutText();
    }

    function onMouseLeave() {
      startHideTextTimeout();
    }

    function showTimeoutText() {
      clearTimeout(state.hideTimeout);
      state.displayText = true;
    }

    function startHideTextTimeout() {
      state.hideTimeout = setTimeout(() => {
        state.displayText = false;
      }, 1500);
    }

    watch(() => state.zoomPercent, onZoomSliderChange);

    return {
      // state & ...
      state,

      // computed:
      zoomPercentDisplay,

      // functions:
      onMouseEnter,
      onMouseLeave,
    };
  },
});
</script>

<template>
  <div
    class="zoomSliderComponent"
    v-on:mouseenter="onMouseEnter"
    v-on:mouseleave="onMouseLeave"
  >
    <div class="slider-wrapper">
      <RangeSlider
        v-bind:min="state.zMin"
        v-bind:max="state.zMax"
        v-bind:step="0.1"
        v-model="state.zoomPercent"
        v-bind:showStepper="true"
        v-bind:markers="[1]"
      />
    </div>

    <div class="zoom-display" v-bind:class="{ show: state.displayText }">
      <span
        class="zoom-number"
        v-bind:style="{ fontSize: state.scaledFontSize + 'px' }"
        >{{ zoomPercentDisplay }}</span
      >
      <span class="percent-sign">%</span>
    </div>
  </div>
</template>

<style lang="less" scoped>
.zoomSliderComponent {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  color: var(--color_headerText);

  .slider-wrapper {
    width: 192px;
    margin-left: -6px;
  }

  .zoom-display {
    opacity: 0;
    transition: opacity 0.5s;
    margin-left: 9px;

    &.show {
      opacity: 1;
    }
    .percent-sign {
      margin-left: 0.2em;
      font-size: 16px;
    }
  }
}
</style>
