import {
  IHistoricKpiValueDto,
  IPeriodDto,
  TimeStructureType,
  SparklineMode,
} from "@/common/service-clients/generated-clients";
import {
  HistoricKpiValueFm,
  PeriodFm,
  TimeStructureTypeFm,
  SparklineModeFm,
} from "@/features/dashboard-shared/sparkline/backend-wrapper/facade-models-sparklines";

export class FmMapperSparklines {
  static mapHistoricDataFms(historicDataDtos: IHistoricKpiValueDto[]) {
    if (!historicDataDtos) {
      return null;
    }

    return historicDataDtos.map((historicDataDto) =>
      this._mapHistoricDataFm(historicDataDto)
    );
  }

  static mapPeriodFm(periodDto: IPeriodDto): PeriodFm {
    const periodFm = new PeriodFm();
    periodFm.id = periodDto.id;
    periodFm.value = periodDto.value;
    return periodFm;
  }

  static mapTimeStructureTypeFm(
    timeStructureType: TimeStructureType
  ): TimeStructureTypeFm {
    return this._mappingTimeStructureType[timeStructureType];
  }

  private static _mapHistoricDataFm(
    historicDataDto: IHistoricKpiValueDto
  ): HistoricKpiValueFm {
    const historicDataFm = new HistoricKpiValueFm();
    historicDataFm.value = historicDataDto.value;
    historicDataFm.sparklineDeviationValue = historicDataDto.sparklineDeviationValue;
    historicDataFm.period = this.mapPeriodFm(historicDataDto.period);
    historicDataFm.dynamicColorValue = historicDataDto.dynamicColorValue;
    return historicDataFm;
  }

  static mappingSparklineMode: { [key in SparklineMode]: SparklineModeFm } = {
    Global: "global",
    Local: "local",
    None: "disabled",
  };

  private static _mappingTimeStructureType: {
    [key in TimeStructureType]: TimeStructureTypeFm;
  } = {
    Day: "Day",
    Month: "Month",
    Other: "Other",
    Quarter: "Quarter",
    Week: "Week",
    Year: "Year",
  };
}
