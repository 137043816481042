import { FailedReason } from "./failed-reason";

export class RequestFailedReason extends FailedReason {
  private readonly _httpStatus: number;

  static BadRequest: RequestFailedReason = new RequestFailedReason(400);
  static Unauthorized: RequestFailedReason = new RequestFailedReason(401);
  static Forbidden: RequestFailedReason = new RequestFailedReason(403);

  constructor(httpStatus: number) {
    super("RequestFailedReason:" + httpStatus);
    this._httpStatus = httpStatus;
  }

  static tryToCreateFrom(status: number): RequestFailedReason {
    if (status === RequestFailedReason.BadRequest._httpStatus) {
      return RequestFailedReason.BadRequest;
    }

    if (status === RequestFailedReason.Unauthorized._httpStatus) {
      return RequestFailedReason.Unauthorized;
    }

    if (status === RequestFailedReason.Forbidden._httpStatus) {
      return RequestFailedReason.Forbidden;
    }

    return null;
  }
}
