import { existsAndNotEmpty } from "@/common/object-helper/null-helper";
import { FailedReason } from "@/common/results/failed-reason";
import { ValueResult } from "@/common/results/value-result";

export class NameUniqueValidator {
  private _retrievedNames: string[] = null;
  private _isLastSpecifiedValueUnique: boolean = false;

  get isLastSpecifiedValueUnique(): boolean {
    return this._isLastSpecifiedValueUnique;
  }

  constructor(
    private _retrieveNamesAsync: () => Promise<ValueResult<string[], FailedReason>>
  ) {}

  async isValueUniqueAsync(value: string): Promise<ValueResult<boolean, FailedReason>> {
    if (!existsAndNotEmpty(value)) {
      return this._createResult(true);
    }

    const retrieveResult = await this._retrieveNamesIfNecessaryAsync();

    if (retrieveResult.succeeded === false) {
      this._isLastSpecifiedValueUnique = false;
      return ValueResult.createFromFailedReason(retrieveResult.failedReason);
    }

    const indexOfTheSameName = retrieveResult.value.findIndex(
      (name) => name === value.trim()
    );

    const isUnique = indexOfTheSameName < 0;

    return this._createResult(isUnique);
  }

  private async _retrieveNamesIfNecessaryAsync(): Promise<
    ValueResult<string[], FailedReason>
  > {
    if (this._retrievedNames !== null) {
      return ValueResult.createFromValue(this._retrievedNames);
    }

    const retrieveNamesResult = await this._retrieveNamesAsync();

    if (retrieveNamesResult.succeeded) {
      this._retrievedNames = retrieveNamesResult.value;
    }

    return retrieveNamesResult;
  }

  private _createResult(isUnique: boolean): ValueResult<boolean, FailedReason> {
    this._isLastSpecifiedValueUnique = isUnique;
    return ValueResult.createFromValue(isUnique);
  }
}
