<script lang="ts">
import ChatIcon from "@/common/components/icons/chat-icon.vue";
import EditIcon from "@/common/components/icons/edit-icon.vue";
import MinimizeIcon from "./icons/minimize-icon.vue";
import BissantzSpinner from "@/common/components/bissantz-spinner.vue";

import { DefaultStyles } from "@/common/styles/default-styles";
import { LiveFeatureTogglesVm } from "@/features/live-feature-toggles/live-feature-toggles-vm";
import { IChatService } from "@/services/chat-service/chat-service.interface";
import { PortalControlsVm } from "./controls-vm";

import {
  inject,
  computed,
  defineComponent,
  reactive,
  PropType,
  onBeforeUnmount,
  watch,
} from "vue";
import { VsScopeState } from "@/services/view-state-service/vs-scope-state";
import { INotificationService } from "@/features/notifications/notification-service.interface";
import { appResources } from "@/app-resources";
import { useHelpText } from "@/services/help-text-service/help-text.cpsl";
import { IMediaQueries } from "@/common/styles/media-queries.interface";
import { IDisposable } from "@/common/disposable.interface";

export default defineComponent({
  components: {
    ChatIcon,
    EditIcon,
    MinimizeIcon,
    BissantzSpinner,
  },

  props: {
    controlsVm: {
      type: Object as PropType<PortalControlsVm>,
      default: null,
      required: true,
    },
  },

  setup(props) {
    const chatService: IChatService = inject("chatService");
    const mediaQueries = inject<IMediaQueries>("mediaQueries");
    const liveToggles = inject("liveToggles") as LiveFeatureTogglesVm;
    const notificationService = inject<INotificationService>("notificationService");
    const helpTextCpsl = useHelpText();

    const state = reactive({
      textResources: appResources.portalTexts,
      defaultStyles: DefaultStyles,
      isChatInputActive: false,
      isSyncing: false,
      isSmallWidth: false,
      eventDisposer: [] as IDisposable[],
    });

    const vsScopeState = inject<VsScopeState>("vsScopeState");

    const unregisterIsSyncingWatch = vsScopeState.isSyncing.isUsedChangedEvent.on(() => {
      state.isSyncing = vsScopeState.isSyncing.isUsed;
    });
    state.eventDisposer.push(unregisterIsSyncingWatch);

    state.isSmallWidth = mediaQueries.isExactlyPhone;
    state.eventDisposer.push(
      mediaQueries.mediaChanged.on(
        () => (state.isSmallWidth = mediaQueries?.isExactlyPhone)
      )
    );

    const alertIfSyncing = (evt: Event) => {
      if (!state.isSyncing) {
        return;
      }

      evt.preventDefault();
      return true;
    };

    window.addEventListener("beforeunload", alertIfSyncing);

    onBeforeUnmount(() => {
      state.eventDisposer.map((d) => d.dispose());
      window.removeEventListener("beforeunload", alertIfSyncing);
    });

    // Computeds:
    // ----------------
    const showChatBot = computed<boolean>(() => {
      return liveToggles?.isEnabled && liveToggles.toggleValues?.dashboardSearch;
    });

    const showEditButton = computed<boolean>(() => {
      const canShowEditBtn = props.controlsVm.hasEditPermission && !state.isSmallWidth;
      return canShowEditBtn;
    });

    const isEditDisabled = computed<boolean>(() => {
      return vsScopeState.isInError.value;
    });

    const showEditSpinner = computed<boolean>(() => {
      return state.isSyncing;
    });

    const showMinimize = computed<boolean>(() => {
      return !!props.controlsVm?.activeFullpageTile;
    });

    // Event Handler Functions:
    // ----------------
    function onMinimizeClick(): void {
      props.controlsVm.activeFullpageTile.isFullpage = false;
    }

    function onChatIconClick(): void {
      state.isChatInputActive = !chatService.isInputMsgBoxVisible;
      chatService.toggleInputMessageBoxAnimated();
    }

    function onEditButtonClick(): void {
      if (isEditDisabled.value) {
        return;
      }

      if (props.controlsVm.isEditing) {
        disableEditing();
      } else {
        enableEditing();
      }
    }

    function disableEditing(): void {
      props.controlsVm.isEditing = false;
      vsScopeState.isPersistVsChangesAllowed.value = false;
      unregisterIsSyncingWatch.dispose();
      state.isSyncing = false;
    }

    function enableEditing(): void {
      if (!props.controlsVm.hasEditPermission) {
        return;
      }

      props.controlsVm.isEditing = true;
      vsScopeState.isPersistVsChangesAllowed.value = true;
    }

    function onVsScopeErrorChanged() {
      if (!vsScopeState.isInError.value) {
        return;
      }

      disableEditing();
      notificationService.error({
        title: state.textResources.errorVsEditNotPossible,
        message: state.textResources.errorVsOutOfSync,
      });
    }

    watch(() => vsScopeState.isInError.value, onVsScopeErrorChanged);

    return {
      state,
      chatService,
      helpTextCpsl,

      // computeds:
      showEditButton,
      showChatBot,
      showEditSpinner,
      isEditDisabled,
      showMinimize,

      // functions
      onChatIconClick,
      onEditButtonClick,
      onMinimizeClick,
    };
  },
});
</script>

<template>
  <div
    v-bind:class="{ 'small-width': state.isSmallWidth }"
    class="portal-controls-component"
  >
    <!-- E D I T    B U T T O N -->
    <div
      v-if="showEditButton"
      class="controls-container"
      v-bind:class="{
        'is-active': $props.controlsVm.isEditing,
        'is-disabled': isEditDisabled,
      }"
    >
      <div
        class="button edit"
        v-on:click="onEditButtonClick"
        v-bind:data-helpText="
          helpTextCpsl.editmodeToggleText($props.controlsVm.isEditing)
        "
      >
        <div class="icon-wrapper">
          <BissantzSpinner v-if="showEditSpinner" />
          <EditIcon
            v-else
            v-bind:mainColor="
              $props.controlsVm.isEditing
                ? state.defaultStyles.colorConstants.darkTextHEX
                : state.defaultStyles.colorConstants.headerTextHEX
            "
          />
        </div>
      </div>
    </div>

    <!-- M I N I M I Z E    B U T T O N -->
    <div v-if="showMinimize" class="controls-container">
      <div
        class="button minimize"
        v-on:click="onMinimizeClick"
        v-bind:data-helpText="helpTextCpsl.fullpageToggleText(true)"
      >
        <div class="icon-wrapper">
          <MinimizeIcon />
        </div>
      </div>
    </div>

    <!-- C H A T    B U T T O N -->
    <div
      class="controls-container"
      v-if="showChatBot"
      v-bind:class="{ isActive: state.isChatInputActive }"
      v-on:click="onChatIconClick"
    >
      <div class="button chat">
        <div class="icon-wrapper">
          <ChatIcon
            v-bind:mainColor="
              state.isChatInputActive
                ? state.defaultStyles.colorConstants.darkTextHEX
                : state.defaultStyles.colorConstants.headerTextHEX
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
.portal-controls-component {
  position: absolute;
  right: 0;
  width: 40px;

  &.small-width {
    position: fixed;
    top: 1px;
    right: 10px;
  }

  .controls-container {
    cursor: pointer;

    &.is-active {
      background-color: var(--color_bg-gray);
    }

    &.is-disabled {
      opacity: 0.4;
      cursor: default;
    }

    .button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;

      .icon-wrapper {
        width: 25px;
        height: 25px;
        font-size: 5px;
        border-color: var(--color_headerText);
      }
    }
  }
}
</style>
