import { SessionLogStorage } from "./session-log-storage";
import { LogLevel, IFullLogLine } from "../log-entry.interface";
import cloneDeep from "lodash/cloneDeep";

// TODO: use locale for correct time but apply a fixed iso-format?
const fmtTime = (time: number) => new Date(time).toLocaleString();

const serilogLevelMapping: { [key: number]: string } = {
  [LogLevel.trace]: "Verbose",
  [LogLevel.debug]: "Debug",
  [LogLevel.info]: "Information",
  [LogLevel.warn]: "Warning",
  [LogLevel.error]: "Error",
};

export class SessionLogExporter {
  static getHumanReadableLog(): string {
    return SessionLogStorage.getStorage()
      .map((line) => `${fmtTime(line.timeStamp)} ${LogLevel[line.level]} ${line.message}`)
      .slice()
      .reverse()
      .join("\n");
  }

  static getStructuredLogFile(): void {
    const file = SessionLogExporter._createJsonFile();

    const link = document.createElement("a");
    link.download = file.name;
    link.href = window.URL.createObjectURL(file);
    link.click();
  }

  private static _createJsonFile(): File {
    const logData = SessionLogStorage.getStorage().map(cloneDeep).reverse();

    for (const line of logData) {
      SessionLogExporter._convertToSerilogLine(line);
    }

    // do not serialize 1 object as json but each log line an individual json:
    // https://stackoverflow.com/questions/10699953/format-for-writing-a-json-log-file
    // (is also important for seriloq-viewer)
    const json_string = logData.map((line) => JSON.stringify(line)).join("\n");
    const blob = new Blob([json_string], { type: "text/plain" });
    const file = new File([blob], new Date().toLocaleString() + " Bissantz Web.json");

    return file;
  }

  private static _convertToSerilogLine(line: IFullLogLine) {
    line["@l"] = serilogLevelMapping[line.level];
    line.level = undefined;

    line["@t"] = new Date(line.timeStamp).toLocaleString();
    line.timeStamp = undefined;

    line["@mt"] = line.message;
    line.message = undefined;
  }
}
