<script lang="ts">
import ActionBar from "./url-action-bar.vue";

import { appResources } from "@/app-resources";

import {
  computed,
  defineComponent,
  inject,
  onMounted,
  PropType,
  reactive,
  Ref,
  ref,
} from "vue";
import { ITileService } from "../tile-service.interface";
import { UrlPageVm } from "./url-page-vm";
import { useBackendDependencies } from "./backend-wrapper/backend-dependencies.cpsl";

class UrlPageState {
  textResources = appResources.portalTexts;
  appNameForErrors: string = "";
}

export default defineComponent({
  components: {
    ActionBar,
  },

  emits: [],

  props: {
    pageVm: { type: Object as PropType<UrlPageVm>, required: true },
  },

  setup: function (props) {
    //
    // Injections:
    // ------------------
    const tileService = inject<Ref<ITileService>>("tileService");

    const { urlAppFacade } = useBackendDependencies();
    //
    // DOM/Comp. refs:
    // ------------------
    const ref_componentElem = ref<HTMLDivElement>();

    // State:
    const state = reactive(new UrlPageState());

    // Life cycle:
    // ------------------
    onMounted(async () => {
      const pubAppId = props.pageVm.pubAppId;
      props.pageVm.urlReportVm.url = await urlAppFacade.getUrlAsync(pubAppId);
      props.pageVm.isInitialized = true;
      props.pageVm.urlReportVm.isInitialized = true;
    });

    //
    // Computeds:
    // ------------------
    const showError = computed<boolean>(() => {
      return !!errorMessage.value;
    });

    const errorMessage = computed<string>(() => {
      return null;
    });

    const dynamicHeight = computed<string>(() => {
      if (tileService.value.width === 2) {
        return 116 + "px";
      } else {
        return tileService.value.width * 116 + "px";
      }
    });

    return {
      // data
      state,
      tileService,

      // Injections, State & Refs:
      ref_componentElem,

      // Computeds:
      showError,
      errorMessage,
      dynamicHeight,

      // Methods:

      // Event Handler:
    };
  },
});
</script>

<template>
  <div
    ref="ref_componentElem"
    class="url-page-component"
    v-bind:class="{
      'is-fullpage': tileService.isInFullpage,
    }"
    v-if="$props.pageVm"
  >
    <!-- ACTIONS -->
    <div v-if="showError" class="error-action-bar" />
    <ActionBar
      v-else
      v-show="!tileService.isInFullpage"
      v-bind:pageVm="$props.pageVm"
      v-bind:hasError="showError"
      v-on="$listeners"
    />

    <div class="result-area">
      <!-- ERRORS  -->
      <div v-if="errorMessage" class="tile-page-error">
        <div class="error-text">
          {{ errorMessage }}
        </div>
        <div class="retry-button" v-if="showError" />
      </div>

      <!-- MAIN CONTENT -->
      <div v-else class="url-result">
        <div v-if="tileService.isAnyTileDragging" class="drag-protect" />
        <iframe
          v-if="$props.pageVm.isInitialized"
          v-bind:src="$props.pageVm.urlReportVm.url"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.url-page-component {
  --action-bar-height: 28px;
  background-color: var(--color_bg_white);
  height: v-bind(dynamicHeight);

  font-size: var(--hostedFontSize);
  position: relative;
  border-radius: 0 0 6px 6px;

  &.is-fullpage {
    border-radius: 0;
    height: 100%;

    .result-area {
      padding: 0;
      height: 100%;
      width: 100%;
    }
  }

  .error-action-bar {
    width: 100%;
    height: 27px;
    border-bottom: 1px solid var(--color_bg-gray);
  }

  .result-area {
    --result-padding: 6px;
    padding: var(--result-padding);
    height: calc((100% - var(--action-bar-height)) - 2 * var(--result-padding));
    width: calc(100% - 2 * var(--result-padding));

    .url-result {
      height: 100%;

      .drag-protect {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
      }

      iframe {
        width: 100%;
        height: 100%;
        border: none;
      }
    }
  }

  .tile-page-error {
    height: 82px;
    white-space: normal;
    height: var(--error-tile-height);
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 0 2em;

    .error-text {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--color_neutralText);
    }
  }
}
</style>
