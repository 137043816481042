import {
  ElementIdFm,
  KpiValueFm,
  DashboardIdFm,
  KpiIdFm,
  KpiValueGroupIdFm,
  StructureIdFm,
  KpiValueIdFm,
} from "@/features/dashboard-shared/backend-wrapper/facade-models-dashboard-shared";
import {
  TimeStructureTypeFm,
  PeriodFm,
} from "@/features/dashboard-shared/sparkline/backend-wrapper/facade-models-sparklines";

export class KpiDashboardFm {
  id: number;
  dashboardId: DashboardIdFm;
  name: string;
  defaultNumberPresentationMode: NumberPresentationModeFm;
  defaultGlobalScaleMode: ScaleModeFm;

  constructor(data?: KpiDashboardFm) {
    if (!data) {
      return;
    }

    this.id = data.id;
    this.dashboardId = new DashboardIdFm(data.dashboardId);
    this.name = data.name;
    this.defaultNumberPresentationMode = data.defaultNumberPresentationMode;
    this.defaultGlobalScaleMode = data.defaultGlobalScaleMode;
  }
}

export class KpiFm {
  dashboardId: number;
  id: number;
  kpiId: KpiIdFm;
  name: string;
  factor: number;
  scaleFactor: number;
  invertSign: boolean;
  scaleGroupId: number;
  valueGroups: KpiValueGroupFm[];
  defaultValueGroupId: number;
  structures: StructureFm[];
  timeStructureType: TimeStructureTypeFm;
  biColorThresholds: BiColorThresholds;

  constructor(data?: KpiFm) {
    if (!data) {
      return;
    }

    this.dashboardId = data.dashboardId;
    this.id = data.id;
    this.kpiId = new KpiIdFm(data.kpiId);
    this.name = data.name;
    this.factor = data.factor;
    this.scaleFactor = data.scaleFactor;
    this.invertSign = data.invertSign;
    this.scaleGroupId = data.scaleGroupId;
    if (data.valueGroups) {
      this.valueGroups = data.valueGroups.map(
        (valueGroup) => new KpiValueGroupFm(valueGroup)
      );
    }
    this.defaultValueGroupId = data.defaultValueGroupId;
    if (data.structures) {
      this.structures = data.structures.map((structure) => new StructureFm(structure));
    }
    this.timeStructureType = data.timeStructureType;
    this.biColorThresholds = data.biColorThresholds;
  }
}

export class KpiValueGroupFm {
  dashboardId: number;
  kpiId: number;
  id: number;
  kpiValueGroupId: KpiValueGroupIdFm;
  period: PeriodFm;
  values: KpiValueFm[];

  constructor(data?: KpiValueGroupFm) {
    if (!data) {
      return;
    }

    this.dashboardId = data.dashboardId;
    this.kpiId = data.kpiId;
    this.id = data.id;
    this.kpiValueGroupId = new KpiValueGroupIdFm(data.kpiValueGroupId);
    this.period = new PeriodFm(data.period);
    if (data.values) {
      this.values = data.values.map((value) => new KpiValueFm(value));
    }
  }
}

export class ElementQueryFm {
  dashboardId: number;
  kpiId: number;
  structureNameId: string;
  elementId: string;

  constructor(data?: ElementQueryFm) {
    if (!data) {
      return;
    }

    this.dashboardId = data.dashboardId;
    this.kpiId = data.kpiId;
    this.structureNameId = data.structureNameId;
    this.elementId = data.elementId;
  }
}

export class ElementQueryResultFm {
  dashboardId: number;
  kpiId: number;
  structureNameId: string;
  element: StructureElementFm;

  constructor(data?: ElementQueryResultFm) {
    if (!data) {
      return;
    }

    this.dashboardId = data.dashboardId;
    this.kpiId = data.kpiId;
    this.structureNameId = data.structureNameId;
    this.element = new StructureElementFm(data.element);
  }
}

export class StructureFm {
  dashboardId: number;
  kpiId: number;
  id: number;
  structureId: StructureIdFm;
  nameId: string;
  displayName: string;

  constructor(data?: StructureFm) {
    if (!data) {
      return;
    }

    this.dashboardId = data.dashboardId;
    this.kpiId = data.kpiId;
    this.id = data.id;
    this.structureId = new StructureIdFm(data.structureId);
    this.nameId = data.nameId;
    this.displayName = data.displayName;
  }
}

export class BiColorThresholds {
  threshold: number;
  rangeLow: number;
  rangeHigh: number;

  constructor(data?: BiColorThresholds) {
    if (!data) {
      return;
    }

    this.threshold = data.threshold;
    this.rangeLow = data.rangeLow;
    this.rangeHigh = data.rangeHigh;
  }
}

export class KpiDrillQueryFm {
  groupByStructureId: number;
  valueGroupIds: number[];
  structureFilters: KpiDrillStructureFilterFm[];
  sortParameter: KpiDrillSortParameterFm;
  resultLimit: number;
  selectedPeriodId: string;

  constructor(data?: KpiDrillQueryFm) {
    if (!data) {
      return;
    }

    this.groupByStructureId = data.groupByStructureId;
    if (data.valueGroupIds) {
      this.valueGroupIds = data.valueGroupIds.map((valueGroupId) => valueGroupId);
    }
    if (data.structureFilters) {
      this.structureFilters = data.structureFilters.map(
        (structureFilter) => new KpiDrillStructureFilterFm(structureFilter)
      );
    }
    this.sortParameter = new KpiDrillSortParameterFm(data.sortParameter);
    this.resultLimit = data.resultLimit;
    this.selectedPeriodId = data.selectedPeriodId;
  }
}

export class KpiDrillStructureFilterFm {
  elementId: string;
  structureNameId: string;

  constructor(data?: KpiDrillStructureFilterFm) {
    if (!data) {
      return;
    }

    this.elementId = data.elementId;
    this.structureNameId = data.structureNameId;
  }

  equals(other: KpiDrillStructureFilterFm): boolean {
    if (!other) {
      return false;
    }

    return (
      this.elementId === other.elementId && this.structureNameId === other.structureNameId
    );
  }
}

export class KpiDrillSortParameterFm {
  sortType: SortTypeFm;
  valueId: KpiValueIdFm;

  constructor(data?: KpiDrillSortParameterFm) {
    if (!data) {
      return;
    }

    this.sortType = data.sortType;
    this.valueId = data.valueId;
  }
}

export class KpiDrillResultFm {
  rows: KpiDrillRowFm[];
  hasMoreRows: boolean;
  totalRowCount: number;
  aggregateInfoByValueId: { [key: string]: KpiDrillAggregateInfoFm };
  historicAggregateInfoHelperIndexByValueId: { [key: string]: number };
  historicAggregateInfoByHelperIndex: { [key: string]: KpiDrillAggregateInfoFm }[];

  constructor(data?: KpiDrillResultFm) {
    if (!data) {
      return;
    }

    if (data.rows) {
      this.rows = data.rows.map((row) => new KpiDrillRowFm(row));
    }
    this.hasMoreRows = data.hasMoreRows;
    this.totalRowCount = data.totalRowCount;
    data.aggregateInfoByValueId = {};
    if (data.aggregateInfoByValueId) {
      for (const key in data.aggregateInfoByValueId) {
        this.aggregateInfoByValueId[key] = data.aggregateInfoByValueId[key];
      }
    }
    this.historicAggregateInfoHelperIndexByValueId =
      data.historicAggregateInfoHelperIndexByValueId;
    if (data.historicAggregateInfoByHelperIndex) {
      this.historicAggregateInfoByHelperIndex = [];
      for (const key in data.historicAggregateInfoByHelperIndex) {
        this.historicAggregateInfoByHelperIndex[key] =
          data.historicAggregateInfoByHelperIndex[key];
      }
    }
  }
}

export class KpiDrillRowFm {
  elementId: ElementIdFm;
  structureElement: StructureElementFm;
  unit: string;
  kpiValues: KpiValueFm[];

  constructor(data?: KpiDrillRowFm) {
    if (!data) {
      return;
    }

    this.elementId = new ElementIdFm(data.elementId);
    this.structureElement = new StructureElementFm(data.structureElement);
    this.unit = data.unit;
    if (data.kpiValues) {
      this.kpiValues = data.kpiValues.map((kpiValue) => new KpiValueFm(kpiValue));
    }
  }
}

export class StructureElementFm {
  id: string;
  displayName: string;

  constructor(data?: StructureElementFm) {
    if (!data) {
      return;
    }

    this.id = data.id;
    this.displayName = data.displayName;
  }
}

export class KpiDrillAggregateInfoFm {
  averageValue: number;
  minValue: number;
  maxValue: number;

  constructor(data?: KpiDrillAggregateInfoFm) {
    if (!data) {
      return;
    }

    this.averageValue = data.averageValue;
    this.minValue = data.minValue;
    this.maxValue = data.maxValue;
  }
}

export type SortTypeFm =
  | "ByValueAscending"
  | "ByValueDescending"
  | "BySortId"
  | "ByNameAscending"
  | "ByNameDescending";

export type NumberPresentationModeFm =
  | "Compact"
  | "DetailedNoBissantzNumbers"
  | "Detailed";

export type ScaleModeFm = "Structure" | "Information" | "Deviation";

export function scaleModeToScaleIndex(scaleMode?: ScaleModeFm): -1 | 0 | 1 | 2 {
  if (!scaleMode) {
    return 2; // application default, if no scale mode is given;
  } else {
    switch (scaleMode) {
      case "Structure":
        return -1;
      case "Information":
        return 0;
      case "Deviation":
        return 1;
    }
  }
}
