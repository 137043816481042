import Vue from "vue";
import en from "@/locales/resources.json";
import de from "@/locales/resources.de.json";

let plainResources = en;
plainResources = JSON.parse(JSON.stringify(en)); // clone

export const appResources = Vue.observable(plainResources); // copy is observable

export function switchAppResourceLanguage(newLanguage: string) {
  // TODO: add more languages
  // TODO: load JSON file from server instead to include all languages into the app bundle
  const languages: object = {
    "de-de": de,
    "de-ch": de,
    "de-at": de,
    en: en,
  };

  const newResources = languages[newLanguage];

  for (const resDirKey in appResources) {
    if (resDirKey != "_comment") {
      const resDir = appResources[resDirKey];

      for (const resKey in resDir) {
        resDir[resKey] = newResources[resDirKey][resKey];
      }
    }
  }
}

/**
 * Copied and modified from "locales/resources.ts.formatString()"
 * @param template must contain sections of the format "{ key }" to be replaced
 * @param args object, with property names being the keys for the replacement and their values being the replacement string.
 * @returns template string with placeholders replaced
 */
export function formatResourceString(
  template: string,
  args: { [key: string]: string }
): string {
  let result = template;
  Object.entries(args).forEach((entry) => {
    const key = entry[0];
    const replacement = `${entry[1]}`;
    const pattern = new RegExp("\\{\\s*" + key + "\\s*\\}", "gm");
    result = result.replace(pattern, replacement);
  });
  return result;
}
