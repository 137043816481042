import { IPortalsServiceClient } from "@/common/service-clients/generated-clients";

export type PortalEntry = { id: string; name: string; children: PortalEntry[] };

export class PortalMenuFacade {
  private readonly _portalServiceClient: IPortalsServiceClient;

  constructor(portalServiceClient: IPortalsServiceClient) {
    this._portalServiceClient = portalServiceClient;
  }

  async getEntries(): Promise<PortalEntry[]> {
    // 'getPortalReferences' pre-sorts portals according to users portal view-states
    const portals = await this._portalServiceClient.getPortalReferences();

    return portals.map((entr) => {
      return { id: entr.id, name: entr.name, children: null };
    });
  }

  async hasEditPermission(portalId: string) {
    const editPortals = await this._portalServiceClient.getPortals(null, "Editor");
    return editPortals.some((p) => p.id === portalId);
  }
}
