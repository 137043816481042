export class FailedReason {
  private readonly _code: string;

  constructor(code: string) {
    this._code = code;
  }

  get code(): string {
    return this._code;
  }

  equals(other: FailedReason): boolean {
    if (other === null || other === undefined) {
      return false;
    }

    return this._code === other._code;
  }
}
