import {
  IKpiValueIdDto,
  KpiValueGroupIdDto,
  IKpiIdDto,
  IEntityIdDto,
  EntityType,
  IKpiValueDto,
  AggregationType,
  StructureIdDto,
  IElementIdDto,
  DashboardIdDto,
} from "@/common/service-clients/generated-clients";
import {
  KpiValueFm,
  KpiValueIdFm,
  KpiValueGroupIdFm,
  KpiIdFm,
  DashboardIdFm,
  EntityIdFm,
  EntityTypeFm,
  AggregationTypeFm,
  StructureIdFm,
  ElementIdFm,
} from "@/features/dashboard-shared/backend-wrapper/facade-models-dashboard-shared";
import { FmMapperSparklines } from "@/features/dashboard-shared/sparkline/backend-wrapper/fm-mapper-sparklines";

export class FmMapperDashboardShared {
  static mapKpiValueFms(values: IKpiValueDto[]): KpiValueFm[] {
    return values.map((value) => this.mapKpiValueFm(value));
  }

  static mapKpiValueFm(kpiValueDto: IKpiValueDto): KpiValueFm {
    const kpiValueFm = new KpiValueFm();
    kpiValueFm.kpiValueId = this.mapKpiValueIdFm(kpiValueDto.kpiValueId);
    kpiValueFm.name = kpiValueDto.name;
    kpiValueFm.value = kpiValueDto.value;
    kpiValueFm.format = kpiValueDto.format;
    kpiValueFm.hasHistoryData = kpiValueDto.hasHistoryData;
    kpiValueFm.historicData = FmMapperSparklines.mapHistoricDataFms(
      kpiValueDto.historicData
    );
    kpiValueFm.isStatus = kpiValueDto.isStatus;
    kpiValueFm.statusTextsByValue = kpiValueDto.statusTextsByValue;
    kpiValueFm.dynamicColorValue = kpiValueDto.dynamicColorValue;
    kpiValueFm.aggregationType =
      this._mappingAggregationTypeFm[kpiValueDto.aggregationType];
    kpiValueFm.isPercentageAdjustmentRequired =
      kpiValueDto.isPercentageAdjustmentRequired;
    kpiValueFm.isKpiSwitchActive = kpiValueDto.isPercentageAdjustmentRequired;
    return kpiValueFm;
  }

  static mapKpiDashboardIdFm(dashboardIdDto: DashboardIdDto) {
    const dashboardIdFm = new DashboardIdFm();
    dashboardIdFm.id = dashboardIdDto.id;
    dashboardIdFm.publishedApplicationId = this._mapEntityIdFm(
      dashboardIdDto.publishedApplicationId
    );
    return dashboardIdFm;
  }

  static mapKpiValueIdFm(kpiValueIdDto: IKpiValueIdDto): KpiValueIdFm {
    const kpiValueIdFm = new KpiValueIdFm();
    kpiValueIdFm.id = kpiValueIdDto.id;
    kpiValueIdFm.kpiValueGroupId = this.mapKpiValueGroupIdFm(
      kpiValueIdDto.kpiValueGroupId
    );
    return kpiValueIdFm;
  }

  static mapKpiValueGroupIdFm(kpiValueGroupIdDto: KpiValueGroupIdDto): KpiValueGroupIdFm {
    const kpiValueGroupIdFm = new KpiValueGroupIdFm();
    kpiValueGroupIdFm.id = kpiValueGroupIdDto.id;
    kpiValueGroupIdFm.kpiId = this.mapKpiIdFm(kpiValueGroupIdDto.kpiId);
    return kpiValueGroupIdFm;
  }

  static mapKpiIdFm(kpiIdDto: IKpiIdDto): KpiIdFm {
    const kpiIdFm = new KpiIdFm();
    kpiIdFm.id = kpiIdDto.id;
    kpiIdFm.dashboardId = this.mapKpiDashboardIdFm(kpiIdDto.dashboardId);
    return kpiIdFm;
  }

  static mapStructureIdFm(structureIdDto: StructureIdDto): StructureIdFm {
    const structureIdFm = new StructureIdFm();
    structureIdFm.id = structureIdDto.id;
    structureIdFm.kpiId = this.mapKpiIdFm(structureIdDto.kpiId);
    return structureIdFm;
  }

  static mapElementIdFm(elementIdDto: IElementIdDto) {
    const elementIdFm = new ElementIdFm();
    elementIdFm.id = elementIdDto.id;
    elementIdFm.structureId = this.mapStructureIdFm(elementIdDto.structureId);
    return elementIdFm;
  }

  private static _mapEntityIdFm(entityIdDto: IEntityIdDto): EntityIdFm {
    const entityIdFm = new EntityIdFm();
    entityIdFm.idType = this._mappingEntityTypeFm[entityIdDto.idType];
    entityIdFm.internalId = entityIdDto.internalId;
    return entityIdFm;
  }

  private static _mappingAggregationTypeFm: {
    [key in AggregationType]: AggregationTypeFm;
  } = {
    None: "None",
    Average: "Average",
    Count: "Count",
    CountDistinct: "CountDistinct",
    Maximum: "Maximum",
    Minimum: "Minimum",
    Sum: "Sum",
  };

  private static _mappingEntityTypeFm: {
    [key in EntityType]: EntityTypeFm;
  } = {
    Application: "Application",
    ApplicationGroup: "ApplicationGroup",
    ApplicationVersion: "ApplicationVersion",
    Axis: "Axis",
    AxisHierarchy: "AxisHierarchy",
    AxisItem: "AxisItem",
    AxisPart: "AxisPart",
    AxisTuple: "AxisTuple",
    AxisTupleSet: "AxisTupleSet",
    Blob: "Blob",
    BlobTransaction: "BlobTransaction",
    Component: "Component",
    ComponentElement: "ComponentElement",
    ComponentElementValue: "ComponentElementValue",
    Connection: "Connection",
    ConnectionGroup: "ConnectionGroup",
    ConnectionSet: "ConnectionSet",
    ConnectionSetGroup: "ConnectionSetGroup",
    Dashboard: "Dashboard",
    DashboardKpi: "DashboardKpi",
    DashboardKpiValue: "DashboardKpiValue",
    DashboardKpiValueGroup: "DashboardKpiValueGroup",
    DashboardOfflineData: "DashboardOfflineData",
    DashboardPortalTilePageViewState: "DashboardPortalTilePageViewState",
    DataDescriptionModel: "DataDescriptionModel",
    DataSource: "DataSource",
    DesignatedConnection: "DesignatedConnection",
    Dm7Application: "Dm7Application",
    DynamicData: "DynamicData",
    Hierarchy: "Hierarchy",
    HierarchyLevel: "HierarchyLevel",
    Information: "Information",
    Kpi: "Kpi",
    KpiDrillQueryViewState: "KpiDrillQueryViewState",
    KpiDrillStructureFilterViewState: "KpiDrillStructureFilterViewState",
    KpiDrillViewState: "KpiDrillViewState",
    KpiValueGroupViewState: "KpiValueGroupViewState",
    KpiValueViewState: "KpiValueViewState",
    KpiViewState: "KpiViewState",
    LicenseAssignment: "LicenseAssignment",
    LicenseData: "LicenseData",
    OlapAttribute: "OlapAttribute",
    OlapAttributeSemantic: "OlapAttributeSemantic",
    OlapCube: "OlapCube",
    OlapHierarchy: "OlapHierarchy",
    OlapHierarchyLevel: "OlapHierarchyLevel",
    OlapMeasure: "OlapMeasure",
    OlapMeasureGroup: "OlapMeasureGroup",
    Portal: "Portal",
    PortalGroup: "PortalGroup",
    PortalPage: "PortalPage",
    PortalTile: "PortalTile",
    PortalTilePage: "PortalTilePage",
    PublishedApplication: "PublishedApplication",
    PublishedApplicationGroup: "PublishedApplicationGroup",
    Query: "Query",
    RelationalDataColumn: "RelationalDataColumn",
    RelationalDataColumnSemantic: "RelationalDataColumnSemantic",
    RelationalDataTable: "RelationalDataTable",
    ReportDefinition: "ReportDefinition",
    ReportingItem: "ReportingItem",
    RoleAssignment: "RoleAssignment",
    ScheduledTask: "ScheduledTask",
    Secret: "Secret",
    Selection: "Selection",
    SemanticModel: "SemanticModel",
    Sparkline: "Sparkline",
    SparklineViewState: "SparklineViewState",
    Structure: "Structure",
    System: "System",
    Tenant: "Tenant",
    Transaction: "Transaction",
    TransactionItem: "TransactionItem",
    Unknown: "Unknown",
    UrlApplicationDefinition: "UrlApplicationDefinition",
    User: "User",
    UserData: "UserData",
    UserGroup: "UserGroup",
    UserGroupData: "UserGroupData",
  };
}
