import { IScalable } from "@/common/formatting/scalable.interface";
import { VsRoot } from "@/services/view-state-service/contract/vs-root";
import { Component } from "vue";
import { TilePageVmType } from "../tile-page-common";
import { ITilePageVm } from "../tile-page-vm.interface";
// import UrlPage from "./url-page.vue";
import { UrlReportVm } from "./url-report-vm";
import UrlPage from "./url-page.vue";

export class UrlPageVm implements ITilePageVm {
  id: string;
  pubAppId: string;
  name: string;

  description: string = "";
  type: TilePageVmType = "Url";
  pageComponent: Component = UrlPage;
  scaleContextValues: IScalable[] = [];

  weatherColor: string;
  isSelected: boolean = false;
  isInitialized: boolean = false;
  isInError: boolean = false;

  urlReportVm: UrlReportVm = null;

  isVsSyncReady(): boolean {
    return this.urlReportVm.isInitialized;
  }

  constructor(pubAppId: string) {
    this.urlReportVm = new UrlReportVm();
    this.urlReportVm.pubAppId = pubAppId;
    this.pubAppId = pubAppId;
  }

  clone(): ITilePageVm {
    const result = new UrlPageVm(this.urlReportVm.pubAppId);

    result.description = this.description;
    result.id = this.id;
    result.name = this.name;
    result.isInitialized = this.isInitialized;
    result.urlReportVm = new UrlReportVm();
    result.urlReportVm.isInitialized = this.urlReportVm.isInitialized;
    result.urlReportVm.url = this.urlReportVm.url;

    return result;
  }

  getVsRoot(): VsRoot {
    return null;
  }
}
