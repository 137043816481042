import { ObjectEmitsOptions } from "vue/types/v3-setup-context";

export type EventValidationFn = ((...args: unknown[]) => unknown) | null;

export class ComponentEvent {
  name: string;
  validationFn: EventValidationFn;

  constructor(name: string, validationFn: EventValidationFn) {
    this.name = name;
    this.validationFn = validationFn;
  }

  toOptions(): ObjectEmitsOptions {
    return { [this.name]: this.validationFn };
  }
}
