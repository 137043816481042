<script lang="ts">
import { HideAndShowHelper } from "./hide-and-show-helper";
import { defineComponent, reactive, computed, ref, watch, onMounted } from "vue";
import { DM7Timer } from "@/common/helper/dm7-timeout";
import type { CSSProperties } from "vue/types/jsx";

class AnimationTimes {
  constructor(
    public moveRight: number,
    public stayRight: number,
    public moveLeft: number
  ) {}
}

class HideAndShowState {
  isScrolling = false;
  textWidth: string;
  animationDurations: AnimationTimes;
  animationDelays: AnimationTimes;
  delayDM7Timer = new DM7Timer(1000);
  scrollDM7Timer = new DM7Timer();
}

export default defineComponent({
  props: {
    disableAnimation: Boolean,
  },

  setup() {
    const ref_hideAndShowInner = ref<HTMLDivElement | null>(null);
    const ref_hideAndShowComponent = ref<HTMLDivElement | null>(null);
    const state = reactive(new HideAndShowState()) as HideAndShowState;

    //
    // Life Cycle:
    // --------------------
    onMounted(() => {
      state.delayDM7Timer.onTimeoutEvent.on(() => (state.isScrolling = true));
      state.scrollDM7Timer.onTimeoutEvent.on(() => (state.isScrolling = false));
    });

    //
    // Computeds:
    // --------------------
    const scrollStyle = computed<CSSProperties>(() => {
      if (!state.isScrolling) {
        return null;
      }

      const animationDurations = state.animationDurations;
      const animationDelays = state.animationDelays;

      return {
        "--textWidth": `${state.textWidth}`,
        "--animationDelays": `${animationDelays.moveRight}s, ${animationDelays.stayRight}s, ${animationDelays.moveLeft}s`,
        "--animationDurations": `${animationDurations.moveRight}s, ${animationDurations.stayRight}s, ${animationDurations.moveLeft}s`,
      };
    });

    //
    // Functions:
    // --------------------
    function startDelayedAutoScroll(): void {
      if (!canStartDelayedAutoScroll()) {
        return;
      }

      state.delayDM7Timer.start();
    }

    function canStartDelayedAutoScroll(): boolean {
      return (
        ref_hideAndShowComponent.value &&
        HideAndShowHelper.isScrollable(ref_hideAndShowComponent.value) &&
        !state.isScrolling
      );
    }

    function stopDelayedAutoScroll(): void {
      state.delayDM7Timer.stop();
    }

    function isEllipsisScrollable(): boolean {
      const hideAndShow = ref_hideAndShowInner.value;
      if (!hideAndShow) {
        return false;
      }

      return hideAndShow.scrollWidth === hideAndShow.clientWidth;
    }

    function getTextWidth(): string {
      if (isEllipsisScrollable()) {
        return `0%`;
      }

      const hideAndShow = ref_hideAndShowInner.value;
      return `calc(${hideAndShow.clientWidth}px - 100%)`;
    }

    function getAnimationDurations(): AnimationTimes {
      if (isEllipsisScrollable()) {
        return new AnimationTimes(0, 1, 0);
      }

      const textSpeed = 0.05; // 50 Pixels per second = 0.05 px/millisecond
      const hideAndShow = ref_hideAndShowInner.value;
      const width_px = Math.abs(hideAndShow.offsetWidth - hideAndShow.scrollWidth);
      const moveRight = width_px / textSpeed / 2000; // Making it faster -> /2
      const stayRight = 1; // fixed 1000ms
      const moveLeft = 0.25; // fixed 250ms

      return new AnimationTimes(moveRight, stayRight, moveLeft);
    }

    function getAnimationDelays(animationDurations: AnimationTimes) {
      return {
        moveRight: 0,
        stayRight: animationDurations.moveRight,
        moveLeft: animationDurations.moveRight + animationDurations.stayRight,
      };
    }

    function getTimeoutDuration(
      animationDurations: AnimationTimes,
      animationDelays: AnimationTimes
    ) {
      const duration_ms = animationDurations.moveLeft + animationDelays.moveLeft;
      const duration_s = duration_ms * 1000;
      const offset_s = 0.2;
      return duration_s + offset_s;
    }

    function onIsScrollingChanged(): void {
      if (!state.isScrolling) {
        return null;
      }

      state.textWidth = getTextWidth();
      state.animationDurations = getAnimationDurations();
      state.animationDelays = getAnimationDelays(state.animationDurations);

      const timerDuration = getTimeoutDuration(
        state.animationDurations,
        state.animationDelays
      );

      state.scrollDM7Timer.start(timerDuration);
    }

    //
    // Watcher:
    // --------------------
    watch(() => state.isScrolling, onIsScrollingChanged);

    return {
      // state & refs:
      state,
      ref_hideAndShowInner,
      ref_hideAndShowComponent,

      // computeds:
      scrollStyle,

      // functions:
      startDelayedAutoScroll,
      stopDelayedAutoScroll,
    };
  },
});
</script>

<template>
  <div
    ref="ref_hideAndShowComponent"
    class="hideAndShow"
    v-if="$props.disableAnimation === true"
  >
    <div ref="ref_hideAndShowInner" class="hideAndShowWrapper">
      <div class="hideAndShowBody">
        <slot />
      </div>
    </div>
  </div>
  <div
    v-else
    ref="ref_hideAndShowComponent"
    class="hideAndShow"
    v-on:mouseenter="startDelayedAutoScroll"
    v-on:mouseleave="stopDelayedAutoScroll"
    v-on:touchstart="startDelayedAutoScroll"
  >
    <div
      ref="ref_hideAndShowInner"
      class="hideAndShowWrapper"
      v-bind:style="scrollStyle"
      v-bind:class="{ autoScroll: state.isScrolling }"
    >
      <div class="hideAndShowBody">
        <slot />
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.hideAndShow {
  display: inline-grid;
  align-items: center;
  min-width: 0;
  height: 100%;
}

.hideAndShowWrapper {
  --textWidth: -50%;
  --animationDurations: 1s, 1s, 1s;
  --animationDelays: 0s, 1s, 2s;
  width: auto;
  position: relative;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.hideAndShowBody {
  display: inline;
}

.autoScroll {
  .hideAndShowBody {
    text-overflow: unset;
    display: inline-block;
    animation-fill-mode: forwards;
    animation-name: scrollRight, stayRight, scrollLeft;
    animation-delay: var(--animationDelays);
    animation-duration: var(--animationDurations);
    animation-timing-function: linear;
  }
}

@keyframes scrollRight {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(var(--textWidth));
  }
}

@keyframes stayRight {
  from {
    transform: translateX(var(--textWidth));
  }
  to {
    transform: translateX(var(--textWidth));
  }
}

@keyframes scrollLeft {
  from {
    transform: translateX(var(--textWidth));
  }
  to {
    transform: translateX(0%);
  }
}
</style>
