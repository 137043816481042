export class Result<TFailedReasonType> {
  private _succeeded: boolean = true;
  private _failedReason: TFailedReasonType = null;

  static createFromFailedReason<TFailedReasonType>(
    failedReason: TFailedReasonType
  ): Result<TFailedReasonType> {
    const result = new Result<TFailedReasonType>();

    result.failedReason = failedReason;

    return result;
  }

  get succeeded(): boolean {
    return this._succeeded;
  }

  protected set succeeded(value: boolean) {
    this._succeeded = value;
  }

  get failedReason(): TFailedReasonType {
    return this._failedReason;
  }

  set failedReason(value: TFailedReasonType) {
    this._succeeded = false;
    this._failedReason = value;
  }
}
