<script lang="ts">
import { IChatService } from "@/services/chat-service/chat-service.interface";
import cloneDeep from "lodash/cloneDeep";
import { inject, onMounted, ref, watch, defineComponent, reactive } from "vue";
import { Bubble } from "@/services/chat-service/bubble";
import { ChatMessage } from "@/services/chat-service/chat-message";
import { PortalCommon } from "../portal/portal-common";

export default defineComponent({
  emits: [PortalCommon.portal_chatViewStateChanged.name],

  setup: function () {
    const chatService: IChatService = inject("chatService");
    const state = reactive({
      bubbles: [] as Bubble[],
      messageCounter: 0,
      charCounter: 0,
      isProcessing: false,
    });
    const ref_chatWindow = ref(null as HTMLDivElement);

    onMounted(() => {
      state.bubbles = cloneDeep(chatService.processedMessages);
      scrollToEnd();
      messageQueueChanged();
    });

    function messageQueueChanged(): void {
      if (!state.isProcessing && chatService.messageQueue.length > 0) {
        initNextBubble();
        processNextMessage();
      }
    }

    function initNextBubble(): void {
      state.isProcessing = true;
      state.messageCounter = 0;
      state.charCounter = 0;

      const nextBubble = new Bubble([], chatService.messageQueue[0].direction);
      state.bubbles.push(nextBubble);
    }

    function processNextMessage(): void {
      const chatMessage = chatService.messageQueue[0].chatMessages[state.messageCounter];
      const type = chatMessage.type;
      const msg = "";
      const nextChatMessage = new ChatMessage(type, msg);
      const lastBubble = state.bubbles[state.bubbles.length - 1];
      lastBubble.chatMessages.push(nextChatMessage);
      setTimeout(() => processMessages(), Math.random() * 10 + 40);
    }

    function processMessages(): void {
      state.isProcessing = true;
      if (isQuestion()) {
        processQuestion();
      } else if (isAnswer()) {
        processAnswer();
      }
    }

    function isQuestion(): boolean {
      return chatService.messageQueue[0].direction === "right";
    }

    function isAnswer(): boolean {
      return chatService.messageQueue[0].direction === "left";
    }

    function messagesLeft(): boolean {
      return chatService.messageQueue.length > 0;
    }

    function processQuestion(): void {
      setFullMessage();
      scrollToEnd();
      processNextBubbleMessage();
    }

    function processAnswer(): void {
      addNextCharacterToMessage();
      scrollToEnd();
      state.charCounter++;

      if (allCharactersProcessed()) {
        state.charCounter = 0;
        state.messageCounter++;

        if (allBubbleMessagesProcessed()) {
          processNextBubbleMessage();
        } else {
          processNextMessage();
        }
      } else {
        setTimeout(() => processMessages(), Math.random() * 10 + 40);
      }
    }

    function processNextBubbleMessage(): void {
      chatService.setFirstMessageAsProcessed();
      if (messagesLeft()) {
        initNextBubble();
        processNextMessage();
      } else {
        state.isProcessing = false;
      }
    }

    function allCharactersProcessed(): boolean {
      return (
        state.charCounter >=
        chatService.messageQueue[0].chatMessages[state.messageCounter].message.length
      );
    }

    function allBubbleMessagesProcessed(): boolean {
      return state.messageCounter >= chatService.messageQueue[0].chatMessages.length;
    }

    function setFullMessage(): void {
      state.bubbles[state.bubbles.length - 1].chatMessages[state.messageCounter].message =
        chatService.messageQueue[0].chatMessages[state.messageCounter].message;
    }

    function addNextCharacterToMessage(): void {
      state.bubbles[state.bubbles.length - 1].chatMessages[
        state.messageCounter
      ].message +=
        chatService.messageQueue[0].chatMessages[state.messageCounter].message[
          state.charCounter
        ];
    }

    function scrollToEnd(): void {
      setTimeout(() => {
        const chatWindow = ref_chatWindow.value as HTMLDivElement;
        if (chatWindow) {
          chatWindow.scrollTop = chatWindow.scrollHeight;
        }
      }, 0);
    }

    function onChatMessageClick(chatMessage): void {
      const chatMsg = chatMessage as ChatMessage;
      if (chatMsg && chatMsg.type === "Link") {
        // TODO
      }
    }

    watch(
      () => chatService.messageQueue.length,
      () => messageQueueChanged()
    );

    return {
      state,
      ref_chatWindow,
      onChatMessageClick,
    };
  },
});
</script>

<template>
  <div class="chatWindow" ref="ref_chatWindow">
    <div
      v-for="bubble in state.bubbles"
      v-bind:key="bubble.id"
      class="bubble"
      v-bind:class="{
        leftBubble: bubble.direction === 'left',
        rightBubble: bubble.direction === 'right',
      }"
    >
      <div class="bubbleMsg">
        <p>
          <i
            v-for="(chatMessage, index) in bubble.chatMessages"
            v-bind:key="bubble.id + '-' + index"
            v-bind:class="{
              textMsg: chatMessage.type === 'Text',
              linkMsg: chatMessage.type === 'Link',
            }"
            v-on:click="onChatMessageClick(chatMessage)"
            >{{ chatMessage.message }}
          </i>
        </p>
      </div>
      <div class="bubbleArrow"></div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.chatWindow {
  width: 500px;
  height: calc(100% - 20px);
  border-radius: 5px;
  margin: 20px 25px 20px 0;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  .bubble {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    font-size: 14px;
    word-break: break-word;

    &.leftBubble {
      align-items: flex-start;
      color: #6c6b6c;
    }

    &.rightBubble {
      align-items: flex-end;
      color: var(--color_bg_white);
    }

    .bubbleMsg {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      height: 100%;
      padding: 15px 25px;
      border-radius: 4px;

      p {
        margin: 0;
        padding: 0;

        i {
          font-style: normal;
        }
      }

      .linkMsg {
        color: var(--color_Weather3);

        &:hover {
          text-decoration-line: underline;
          cursor: pointer;
        }
      }
    }

    .bubbleArrow {
      width: 0;
      height: 0;
      --arrowHeight: 6px;
      border-bottom: var(--arrowHeight) solid transparent;
    }
  }

  .leftBubble > .bubbleMsg {
    background-color: #cdcbcd;
    margin-right: 40px;
  }

  .rightBubble > .bubbleMsg {
    background-color: var(--color_Weather3);
    margin-left: 40px;
  }
  .leftBubble > .bubbleArrow {
    margin-left: 20px;
    border-top: var(--arrowHeight) solid #cdcbcd;
    border-left: var(--arrowHeight) solid #cdcbcd;
    border-right: var(--arrowHeight) solid transparent;
  }
  .rightBubble > .bubbleArrow {
    margin-right: 20px;
    border-top: var(--arrowHeight) solid var(--color_Weather3);
    border-right: var(--arrowHeight) solid var(--color_Weather3);
    border-left: var(--arrowHeight) solid transparent;
  }
}

.chatWindow::-webkit-scrollbar {
  display: none;
}
</style>
