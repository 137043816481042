export type LogLevelKeys = keyof typeof LogLevel;

export enum LogLevel {
  "trace" = 10,
  "debug" = 20,
  "info" = 30,
  "warn" = 40,
  "error" = 50,
}

export interface ILogEntry {
  level: LogLevel;
  message: string;
  data?: object;
}

export interface ILogMeta {
  timeStamp: number;
  file: string;
  line: number;
  column: number;
  language?: string;
  appVersion?: string;
}

export interface ICorreltationId {
  systemId?: string;
  userId?: string;
  sessionId?: string;
  requestId?: string;
}

export interface IFullLogLine extends ILogEntry, ILogMeta, ICorreltationId {
  // log entry
  level: LogLevel;
  message: string;
  data?: object;

  // log meta
  appVersion?: string;
  timeStamp: number;
  language?: string;
  file: string;
  line: number;
  column: number;

  // correlation id
  systemId?: string;
  userId?: string;
  sessionId?: string;
  requestId?: string;
}
