<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    instructionText: { type: String, required: true },
  },

  setup() {},
});
</script>

<template>
  <div class="app-wizard_instruction">
    <p>{{ $props.instructionText }}</p>
  </div>
</template>

<style lang="less" scoped>
@import "../../../common/styles/base-styles.less";
.app-wizard_instruction {
  font-size: 0.9rem;
  width: 80%;
  align-self: center;
  margin-top: 65px;

  color: var(--color_Weather3);

  p {
    text-align: center;
  }
}
</style>
