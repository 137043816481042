<script lang="ts">
import BissantzSpinner from "@/common/components/bissantz-spinner.vue";
import { defineComponent, computed } from "vue";
import type { CSSProperties } from "vue/types/jsx";

export default defineComponent({
  components: {
    BissantzSpinner,
  },

  props: {
    lineWidth: {
      type: Number,
      default: 6,
    },
    size: {
      type: Number,
      default: 60,
    },
    marginTopBottom: {
      type: Number,
      default: 0,
    },
    unit: {
      type: String,
      default: "px",
    },
  },
  setup(props) {
    const sizes = computed<CSSProperties>(() => {
      return {
        "--line-width": `${props.lineWidth}${props.unit}`,
        "--size": `${props.size}${props.unit}`,
        "--margin-top-bottom": `${props.marginTopBottom}${props.unit}`,
      };
    });

    return {
      sizes,
    };
  },
});
</script>

<template>
  <div class="application-wizard_spinner" v-bind:style="sizes">
    <BissantzSpinner />
  </div>
</template>

<style lang="less" scoped>
@import "../../../common/styles/base-styles.less";
.application-wizard_spinner {
  height: var(--size);
  width: var(--size);
  font-size: var(--line-width);
  margin: var(--margin-top-bottom) auto var(--margin-top-bottom);
  border-color: var(--color_Weather3);
}
</style>
