import { ErrorFailedReason } from "./error-failed-reason";
import { FailedReason } from "./failed-reason";
import { RequestFailedReason } from "./request-failed-reason";
import { Result } from "./result";
import isNumber from "lodash/isNumber";
import toNumber from "lodash/toNumber";

export class ValueResult<T, E> extends Result<E> {
  private _value: T = null;

  constructor() {
    super();
  }

  static createFromValue<T, E>(value: T): ValueResult<T, E> {
    const result = new ValueResult<T, E>();

    result.value = value;

    return result;
  }

  static createFromFailedReason<T, E>(failedReason: E): ValueResult<T, E> {
    const result = new ValueResult<T, E>();

    result.failedReason = failedReason;

    return result;
  }

  static createFromError<T>(e): ValueResult<T, FailedReason> {
    const result = new ValueResult<T, FailedReason>();

    result.failedReason = ValueResult._createFailedReason(e);

    return result;
  }

  get value(): T {
    return this._value;
  }

  private set value(value: T) {
    this._value = value;
    super.succeeded = true;
  }

  private static _createFailedReason(e): FailedReason {
    if (isNumber(e)) {
      const errorNumber = toNumber(e);

      const requestFailedReason = RequestFailedReason.tryToCreateFrom(errorNumber);

      if (requestFailedReason !== null) {
        return requestFailedReason;
      }

      return new FailedReason("NumberGeneric:" + errorNumber);
    }

    if (e instanceof Error) {
      const error = e as Error;

      return new ErrorFailedReason(error.name, error.message);
    }

    return new FailedReason("Generic:" + e);
  }
}
