<script lang="ts">
import AppWizardButtons from "../helper-components/app-wizard-buttons.vue";
import AppWizardInstruction from "../helper-components/app-wizard-instruction.vue";
import AppWizardTabTemplate from "../helper-components/app-wizard-tab-template.vue";
import AppWizardSpinner from "../helper-components/app-wizard-spinner.vue";
import AppWizardError from "../helper-components/app-wizard-error.vue";
import LabelWithTooltip from "../helper-components/label-with-tooltip.vue";
import ReportModelling from "./tab-components/modelling/report-modelling.vue";
import { BcDropdown, BcButton } from "@bissantz/controls";
import { reactive, defineComponent, inject, onBeforeMount, computed } from "vue";
import { AppWizardCommon } from "../app-wizard-common";
import { appResources } from "@/app-resources";
import { AppWizardService } from "@/services/app-wizard-service";
import { ModellingVm } from "../view-models/modelling-vm";

export default defineComponent({
  components: {
    AppWizardTabTemplate,
    AppWizardButtons,
    AppWizardInstruction,
    BcDropdown,
    BcButton,
    AppWizardSpinner,
    AppWizardError,
    LabelWithTooltip,
    ReportModelling,
  },

  emits: [AppWizardCommon.events.confirmed, AppWizardCommon.events.cancelled],

  props: {
    viewModel: { type: ModellingVm, required: true },
  },

  setup(props, context) {
    const appWizardService: AppWizardService = inject("appWizardService");

    const state = reactive({
      appWizardTexts: appResources.applicationWizardTexts,
      modellingError: "",
    });

    onBeforeMount(async () => {
      if (!appWizardService.isEditMode) {
        await props.viewModel.initModelling();
        if (props.viewModel.olapCubesRetrievalFailed) {
          state.modellingError = state.appWizardTexts.modelling_errorBody;
        }
      } else {
        await props.viewModel.loadReportDefinitionAsync();
        if (props.viewModel.reportDefinitionLoadingFailed) {
          state.modellingError = state.appWizardTexts.modelling_dataLoadingError;
        }
      }
    });

    const isNotLoadingDataAndNoError = computed(() => {
      return !state.modellingError && !props.viewModel.isSendingDataToBackend;
    });

    async function createSemanticModel() {
      let result = await props.viewModel.createSemanticModelAndReport();
      if (!result) {
        state.modellingError = state.appWizardTexts.modelling_errorBody;
      }
    }

    async function importFromReport() {
      let result = await props.viewModel.importFromReport();
      if (!result) {
        state.modellingError = state.appWizardTexts.modelling_errorBody;
      } else {
        appWizardService.closeApplication();
      }
    }

    async function onWizardCancelled() {
      context.emit(AppWizardCommon.events.cancelled);
    }

    return {
      // general
      state,

      // emits:
      AppWizardCommon,

      //functions:
      importFromReport,
      onWizardCancelled: onWizardCancelled,
      createSemanticModel,

      // computed:
      isNotLoadingDataAndNoError,
    };
  },
});
</script>

<template>
  <div class="application-wizard_modelling application-wizard_tab">
    <AppWizardTabTemplate v-show="isNotLoadingDataAndNoError">
      <template v-slot:inputFields>
        <div v-show="$props.viewModel.isCubeShowing" class="cube-container">
          <LabelWithTooltip
            v-bind:labelText="state.appWizardTexts.modelling_Cube"
            v-bind:tooltipText="state.appWizardTexts.modelling_infotextCube"
          />
          <BcDropdown
            class="center"
            type="select"
            design="7"
            v-model="$props.viewModel.selectedCube"
            v-bind:fitContent="true"
            v-bind:overflow="true"
            v-bind:numberOfVisibleItems="5"
            v-bind:items="$props.viewModel.OLAPCubes"
            itemKeyProperty="name"
            itemTextProperty="name"
            v-bind:placeholder="state.appWizardTexts.general_select"
            style="width: 350px"
          />
          <BcButton
            v-bind:disabled="!$props.viewModel.canCreateSemanticModel"
            design="7"
            size="md"
            style="width: 100px; border: none"
            v-on:click="createSemanticModel"
            >{{ state.appWizardTexts.general_confirm }}</BcButton
          >
        </div>
        <div class="missing-cube" v-show="$props.viewModel.isMissingCube">
          {{ state.appWizardTexts.modelling_missingCube }}
        </div>
      </template>
      <template v-slot:infotext>
        <AppWizardInstruction
          v-show="$props.viewModel.showInstruction"
          v-bind:instructionText="state.appWizardTexts.modelling_instructionText"
        />
      </template>
      <template v-slot:navigation>
        <ReportModelling
          v-show="$props.viewModel.isReportModellingVisible"
          v-bind:modellingVm="$props.viewModel"
        />
      </template>
      <template v-slot:buttons>
        <AppWizardButtons
          v-bind:disabled="!$props.viewModel.areAllRequiredDataToFinishModellingProvided"
          v-on:confirmed="importFromReport"
          v-on:cancelled="onWizardCancelled"
        />
      </template>
    </AppWizardTabTemplate>
    <AppWizardError
      v-show="!!state.modellingError"
      v-bind:errorHeader="state.appWizardTexts.modelling_errorHeader"
      v-bind:errorBody="state.modellingError"
    />
    <AppWizardSpinner v-show="$props.viewModel.isSendingDataToBackend" />
  </div>
</template>

<style lang="less" scoped>
@import "./tabs.less";
.application-wizard_modelling {
  .cube-container {
    display: flex;
    justify-content: center;
    padding-top: 50px;
  }

  .missing-cube {
    padding: 0 clamp(50px, 9vw, 100px);
    color: var(--color_Weather-3);
    text-align: center;
  }
}
</style>
