import { OlapDatabaseCube, PublishedApplication } from "../backend-wrapper/dto-wrappers";
import { MagicButtonListVm } from "./modelling/magic-button-list-vm";
import { IConnectionFacade } from "../backend-wrapper/connection-facade.interface";
import { IModelFacade } from "../backend-wrapper/model-facade.interface";
import { ITransactionFacade } from "@/common/transactions/transaction-facade.interface";
import { ISemanticModelParams } from "../backend-wrapper/semantic-model-params.interface";
import { NavigationVm } from "./modelling/navigation-vm";
import { TypedEvent } from "@/common/events/typed-event";
import { IDisposable } from "@/common/disposable.interface";
import { IReportFacade } from "../backend-wrapper/report-facade-interface";
import { ValueResult } from "@/common/results/value-result";
import { FailedReason } from "@/common/results/failed-reason";

export class ReportImportFinishedEventArgs {
  constructor(public readonly importSucceeded: boolean) {}
}

export class ModellingVm implements IDisposable {
  get selectedCube(): OlapDatabaseCube {
    return this._selectedCube;
  }

  set selectedCube(value: OlapDatabaseCube) {
    this._selectedCube = value;

    this._isCubeSelected =
      this._selectedCube !== null && this._selectedCube !== undefined;
  }

  private _selectedCube: OlapDatabaseCube = undefined;
  private _OLAPCubes: OlapDatabaseCube[] = [];
  private _olapCubesRetrievalFailed: boolean = false;
  private _reportDefinitionLoadingFailed: boolean = false;
  private _isSendingDataToBackend: boolean = false;
  private _isSemanticModellCreated: boolean = false;
  private _semanticModelId: string = null;
  private _reportId: string = null;
  private _isCubeSelected: boolean = false;
  private _isInitialised: boolean = false;
  private _navigationVm: NavigationVm = null;
  private _magicButtonListVm: MagicButtonListVm = null;
  private _isCubeShowing: boolean = true;

  public readonly reportImportFinished = new TypedEvent<ReportImportFinishedEventArgs>();

  get olapCubesRetrievalFailed(): boolean {
    return this._olapCubesRetrievalFailed;
  }

  get reportDefinitionLoadingFailed(): boolean {
    return this._reportDefinitionLoadingFailed;
  }

  get isSendingDataToBackend(): boolean {
    return this._isSendingDataToBackend;
  }

  get OLAPCubes(): OlapDatabaseCube[] {
    return this._OLAPCubes;
  }

  get isMissingCube(): boolean {
    if (!this._isInitialised) {
      return false;
    }

    return this.OLAPCubes.length === 0;
  }

  get isSemanticModellCreated(): boolean {
    return this._isSemanticModellCreated;
  }

  get showInstruction(): boolean {
    return !this.isSendingDataToBackend && !this.isSemanticModellCreated;
  }

  get isReportModellingVisible(): boolean {
    return !this.isSendingDataToBackend && this.isSemanticModellCreated;
  }

  get isCubeSelected(): boolean {
    return this._isCubeSelected;
  }

  get canCreateSemanticModel(): boolean {
    return this._isCubeSelected === true && this._isSemanticModellCreated === false;
  }

  get navigationVm(): NavigationVm {
    return this._navigationVm;
  }

  get isReportModellingAvailable(): boolean {
    return this._navigationVm !== null;
  }

  get magicButtonListVm(): MagicButtonListVm {
    return this._magicButtonListVm;
  }

  get isCubeShowing(): boolean {
    return this._isCubeShowing;
  }

  get areAllRequiredDataToFinishModellingProvided(): boolean {
    if (this._navigationVm === null) {
      return false;
    }

    if (this._magicButtonListVm === null) {
      return false;
    }

    if (this.navigationVm.selectedKpis.length === 0) {
      return false;
    }

    if (!this._magicButtonListVm.isValidMagicButtonSelection) {
      return false;
    }

    if (this._isSendingDataToBackend) {
      return false;
    }

    return true;
  }

  get isEditMode(): boolean {
    return this._isEditMode;
  }

  constructor(
    private readonly _isEditMode: boolean,
    private readonly _connectionFacade: IConnectionFacade,
    private readonly _modelFacade: IModelFacade,
    private readonly _reportFacade: IReportFacade,
    private readonly _transactionFacade: ITransactionFacade,
    private readonly _currentConnectionId?: string,
    private _currentDeltaMasterPublishedApplication?: PublishedApplication,
    private _currentDashboardPublishedApplicationId?: string
  ) {}

  async initModelling(): Promise<void> {
    this._isSendingDataToBackend = true;
    this._OLAPCubes = await this._getOLAPCubes();
    this._isInitialised = true;
    this._isSendingDataToBackend = false;
  }

  async loadReportDefinitionAsync(): Promise<void> {
    this._isSendingDataToBackend = true;
    this._isSemanticModellCreated = true;
    this._isCubeShowing = false;

    try {
      const dm7App = await this._getIdsForEditing(
        this._modelFacade.getDm7ApplicationByPublishedApplicationIdAsync(
          this._currentDeltaMasterPublishedApplication.id
        )
      );

      const semModel = await this._getIdsForEditing(
        this._modelFacade.getSemanticModelForApplicationVersionAsync(
          dm7App.value.applicationVersionId
        )
      );

      const reportingItems = await this._getIdsForEditing(
        this._modelFacade.findReportingItemsAsync(dm7App.value.id, null, false)
      );

      const items = reportingItems.value;
      this._reportId = items[items.length - 1].id;
      this._semanticModelId = semModel.value.id;

      this._createNavigationVm();
      this._createMagicButtonVm();
      await this._magicButtonListVm.initAsync();
      if (this.isEditMode && this._navigationVm) {
        await this._navigationVm.loadCurrentReportData();
      }
    } catch (error) {
      this._reportDefinitionLoadingFailed = true;
    } finally {
      this._isSendingDataToBackend = false;
    }
  }

  private async _getIdsForEditing<T>(
    promise: Promise<ValueResult<T, FailedReason>>
  ): Promise<ValueResult<T, FailedReason>> {
    const result = await promise;

    if (result.succeeded) {
      return result;
    } else {
      throw new Error("Loading report definition failed");
    }
  }

  private _createNavigationVm() {
    this._navigationVm = new NavigationVm(
      this._reportFacade,
      this._currentDashboardPublishedApplicationId,
      this._semanticModelId,
      this._reportId
    );
  }

  private _createMagicButtonVm() {
    this._magicButtonListVm = new MagicButtonListVm(this._reportFacade, this._reportId);
  }

  private async _getOLAPCubes(): Promise<OlapDatabaseCube[]> {
    const result = await this._reportFacade.getOLAPCubesAsync(this._currentConnectionId);

    this._olapCubesRetrievalFailed = !result.succeeded;

    if (result.succeeded) {
      return result.value;
    } else {
      return [];
    }
  }

  async createSemanticModelAndReport(): Promise<boolean> {
    this._isSendingDataToBackend = true;
    this._isCubeShowing = false;

    await this._transactionFacade.beginOrReuseTransaction();

    const applicationVersionId =
      this._currentDeltaMasterPublishedApplication.applicationVersionId;

    const designatedConnection =
      await this._connectionFacade.getDesignatedConnectionFromConnectionSetId(
        this._currentDeltaMasterPublishedApplication.connectionSetId
      );

    const semanticModelParams: ISemanticModelParams = {
      name: this._currentDeltaMasterPublishedApplication.name,
      applicationVersionId: applicationVersionId,
      publishedApplicationId: this._currentDeltaMasterPublishedApplication.id,
      designatedConnectionId: designatedConnection.id,
      connectionId: designatedConnection.connectionId,
      selectedCube: this._selectedCube,
    };

    const result = await this._modelFacade.createSemanticModelAndReportAsync(
      semanticModelParams
    );
    this._isSendingDataToBackend = false;

    if (result.succeeded) {
      this._isSemanticModellCreated = true;
      this._semanticModelId = result.value.semanticModelId;
      this._reportId = result.value.reportId;

      this._createMagicButtonVm();
      this._createNavigationVm();

      await this._magicButtonListVm.initAsync();
    }

    return result.succeeded;
  }

  async importFromReport(): Promise<boolean> {
    this._isSendingDataToBackend = true;

    const resultImportReport = await this._modelFacade.importFromReportAsync(
      this._currentDeltaMasterPublishedApplication.id,
      this._currentDashboardPublishedApplicationId,
      this._reportId,
      this._currentDeltaMasterPublishedApplication.name,
      this._currentDeltaMasterPublishedApplication.publishedApplicationGroupId
    );
    if (!resultImportReport.succeeded) {
      this._transactionFacade.rollbackActiveTransactionIfAny();
    } else {
      await this._transactionFacade.commitActiveTransactionIfAny();
    }

    this.reportImportFinished.emit(
      new ReportImportFinishedEventArgs(resultImportReport.succeeded)
    );
    this._isSendingDataToBackend = false;
    return resultImportReport.succeeded;
  }

  dispose(): void {
    this.reportImportFinished.removeAllListeners();
  }
}
