// TODO: this file has to go!
//       We can not have this working on DOM-strcture and css selectors
export function backgroundClicked(event: UIEvent): boolean {
  const ignoredTargetClicked = event?.composedPath().some((eventTarget) => {
    return isHtmlElement(eventTarget) && isPortalTile(eventTarget);
  });

  return !ignoredTargetClicked;
}

// TODO: remove hard coded css selectors
export function isHtmlElement(eventTarget: EventTarget): boolean {
  return eventTarget instanceof HTMLElement;
}

// TODO: remove hard coded css selectors
export function isPortalTile(eventTarget: EventTarget): boolean {
  const targetElement = eventTarget as HTMLElement;
  return (
    targetElement.className?.includes("title-row") ||
    targetElement.className?.includes("tilepage-host")
  );
}
