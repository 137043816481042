<script lang="ts">
import { defineComponent, computed } from "vue";
import type { CSSProperties } from "vue/types/jsx";

export default defineComponent({
  components: {},
  emits: [],
  props: {
    color: { type: String, default: null },
  },
  setup(props) {
    //
    // Life Cycle:
    // --------------------

    //
    // Computeds:
    // --------------------
    const colorVars = computed<CSSProperties>(() => {
      return {
        "--stroke-color": props.color ? props.color : "var(--color_neutralText)",
      };
    });

    //
    // Functions:
    // --------------------

    //
    // Watcher:
    // --------------------
    return {
      colorVars,
    };
  },
});
</script>

<template>
  <div class="accurate-numbers-icon" v-bind:style="colorVars">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 210 210">
      <path
        class="cls-1"
        d="M141,137.5l9.9-6s3.2,6.7,11.5,6.7,10-3.9,10-8.5-3.7-8.6-11.1-8.6h-6.2v-10h6.3c6,0,9.2-3.1,9.2-7.7s-3.6-7.6-8.5-7.6c-7.1,0-9.2,5.4-9.2,5.4l-9.6-5.5s4.9-11.3,19.8-11.3,20.7,6.8,20.7,17.1-3.5,11.1-9,14.1c6.6,2.9,10.8,8.3,10.8,15.5,0,11.1-9.9,18.5-23,18.5s-21.6-12.1-21.6-12.1Z"
      />
      <path
        class="cls-1"
        d="M78.6,138.6l21.9-21.5c5.2-5.1,7-8.1,7-12.6s-3.5-8.2-8.8-8.2c-7.8,0-11.6,8-11.6,8l-9.6-6.2s5.2-13.6,22.4-13.6,20.7,8,20.7,18.5-3.5,14.1-11.4,21.5l-13.3,12.9h24.2v11.3h-41.6v-10.1Z"
      />
      <path
        class="cls-1"
        d="M52.1,87.4v61.3h-12.1v-45.9l-14.5,9.5-6.3-8.8,23.1-16.1h9.9Z"
      />
    </svg>
  </div>
</template>

<style scoped lang="less">
.accurate-numbers-icon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  svg {
    height: 100%;
    width: 100%;

    .cls-1 {
      fill: var(--stroke-color);
      stroke-width: 0;
    }
  }
}
</style>
