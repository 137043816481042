import { ITransactionsServiceClient } from "../service-clients/generated-clients";
import { ITransactionFacade } from "./transaction-facade.interface";
import { TransactionStorage } from "./transaction-storage";

export class TransactionFacade implements ITransactionFacade {
  private readonly _transactionsClient: ITransactionsServiceClient = null;
  private readonly _transactionStorage: TransactionStorage = null;

  private get _activeTransactionExist(): boolean {
    return TransactionFacade.transactionExist(this._activeTransactionId);
  }

  private get _activeTransactionId(): string {
    return this._transactionStorage.activeTransactionId;
  }

  constructor(
    transactionsClient: ITransactionsServiceClient,
    transactionStorage: TransactionStorage
  ) {
    this._transactionsClient = transactionsClient;
    this._transactionStorage = transactionStorage;
  }

  async beginOrReuseTransaction(): Promise<void> {
    if (!this._activeTransactionExist) {
      const activeTransactionId = await this._transactionsClient.begin();

      this._transactionStorage.activeTransactionId = activeTransactionId;
    }
  }

  async commitActiveTransactionIfAny(): Promise<void> {
    if (this._activeTransactionExist) {
      await this._transactionsClient.commit(this._activeTransactionId);

      this._resetActiveTransaction();
    }
  }

  async rollbackActiveTransactionIfAny(): Promise<void> {
    if (this._activeTransactionExist) {
      await this._transactionsClient.rollback(this._activeTransactionId);

      this._resetActiveTransaction();
    }
  }

  private _resetActiveTransaction() {
    this._transactionStorage.activeTransactionId = "";
  }

  static transactionExist(transactionId: string): boolean {
    return transactionId !== null && transactionId !== undefined && transactionId !== "";
  }
}
