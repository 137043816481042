import { getBrowserType } from "@/common/browser-detection";
import * as htmlToImage from "html-to-image";

export type ScreenshotError = "TooManyElements" | "RenderingFailed";
export type ScreenshotResult = { image: Blob; error: ScreenshotError };

export async function takeScreenshot(element: HTMLElement): Promise<ScreenshotResult> {
  const tempElem = element.cloneNode(true) as HTMLElement;
  preparePortalTile(tempElem);
  removeOuterRoundCorners(tempElem);
  copyCanvasContexts(element, tempElem);
  pruneSimpleGrids(tempElem);
  pruneSwipeItems(tempElem);

  if (!canTakeScreenshot(tempElem)) {
    return { image: null, error: "TooManyElements" };
  }

  document.body.appendChild(tempElem);

  let result: Blob = null;
  try {
    result = await callHtmlToImage(tempElem);
  } catch {
    return { image: null, error: "RenderingFailed" };
  } finally {
    tempElem.remove();
  }

  return { image: result, error: null };
}

async function callHtmlToImage(tempElem: HTMLElement): Promise<Blob> {
  if (getBrowserType() === "safari") {
    await htmlToImage.toBlob(tempElem);
    await htmlToImage.toBlob(tempElem);
    return htmlToImage.toBlob(tempElem);
  } else {
    return htmlToImage.toBlob(tempElem);
  }
}

function canTakeScreenshot(element: HTMLElement) {
  const childCount = countSubElements(element);

  if (childCount > 2000) {
    return false;
  }

  const isPossible = !(getBrowserType() === "safari" && childCount > 1000);

  return isPossible;
}

function countSubElements(element: HTMLElement): number {
  if (!element || !element.childElementCount) {
    return 0;
  }

  let numChildren = element.childElementCount;

  for (let childIdx = 0; childIdx < element.children.length; childIdx++) {
    const childElem = element.children.item(childIdx);
    numChildren += countSubElements(childElem as HTMLElement);
  }

  return numChildren;
}

function copyCanvasContexts(original: HTMLElement, copy: HTMLElement) {
  // canvases of orginal and copy are assumed to be in the same order
  // get the two lists of canvases from both elements
  // copy each context from original to copy by simple for loop index
  const originalCanvases = Array.from(original.getElementsByTagName("canvas"));
  const copyCanvases = Array.from(copy.getElementsByTagName("canvas"));
  for (let i = 0; i < originalCanvases.length; i++) {
    const originalCanvas = originalCanvases[i];
    const copyCanvas = copyCanvases[i];
    copyCanvas.getContext("2d")!.drawImage(originalCanvas, 0, 0);
  }
}

function pruneSimpleGrids(element: HTMLElement): void {
  const simpleGrids = Array.from(element.querySelectorAll(".simple-grid"));
  simpleGrids.forEach((grid) => removeUnusedComponentsFromGrid(grid as HTMLElement));
}

function removeUnusedComponentsFromGrid(grid: HTMLElement) {
  const children = Array.from(grid.children);
  children.forEach((child: HTMLElement) => {
    if (child.style.display === "none") {
      grid.removeChild(child);
    }
  });
}

function removeOuterRoundCorners(element: HTMLElement) {
  // TODO: Remove hard coded css selectors
  const topElem = element.querySelector(".portal-tile_header-title") as HTMLElement;
  const bottomElem = element.querySelector(".dashboard-page-component") as HTMLElement;

  topElem.style.borderRadius = "unset";
  bottomElem.style.borderRadius = "unset";
}

function preparePortalTile(element: HTMLElement) {
  element.style.zIndex = "-2500";
  element.style.transform = "";
  element.style.height = "";
  element.style.scale = "1";
  element.style.userSelect = "none";
  element.style.backgroundColor = "white";
  const tileContent = element.firstChild as HTMLElement;
  tileContent.style.paddingTop = "0";

  // Remove tile control icons for screenshot
  // TODO: Remove hard coded css selectors
  const tileMenuItems = Array.from(
    element.querySelectorAll(".dashboard-page_action-bar .action")
  );
  tileMenuItems.forEach((item) => item.remove());

  // Hide potential close-clone icon:
  const xIcon = element.querySelector(".xIcon") as HTMLElement;
  if (xIcon) {
    xIcon.style.visibility = "hidden";
  }
}

function pruneSwipeItems(element: HTMLElement) {
  const elementSwipers = Array.from(element.querySelectorAll(".swiperSection"));
  elementSwipers.forEach((swiper) => removeItemsFromSwiper(swiper as HTMLElement));
  elementSwipers.map((e) => ((e as HTMLElement).style.height = "100%"));
}

function removeItemsFromSwiper(swiper: HTMLElement) {
  const activeIndex = getActiveIndex(swiper);
  swiper.style.transform = "none";

  const swiperItems = Array.from(swiper.children);
  swiperItems.forEach((item, index) => {
    if (index !== activeIndex) {
      item.remove();
    }
  });
}

function getActiveIndex(swiper: HTMLElement): number {
  return +swiper.style.getPropertyValue("--activeIndex");
}
