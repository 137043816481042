import { ISimpleEvent } from "@/common/events/isimple-event";
import { SimpleEvent } from "@/common/events/simple-event";
import { IMediaQueries } from "./media-queries.interface";
// IMPORTANT: update media definitions in sync with media-queries.less

// inspired by:
// https://css-tricks.com/working-with-javascript-media-queries/

export type DisplayDeviceHint =
  | "mediaHint_isMobile"
  | "mediaHint_isTablet"
  | "mediaHint_isAtLeastTablet"
  | "mediaHint_isLaptop";

export class MediaQueries implements IMediaQueries {
  private readonly _phoneText = "(min-width: 180px)";

  private readonly _tabletText = "(min-width: 481px)";

  private readonly _laptopText = "(min-width: 1024px)";

  private readonly _mouseText = "not all and (hover: none)";

  private readonly _touchText = "(hover: none)";

  private readonly _phoneQuery: MediaQueryList = null;
  private readonly _tabletQuery: MediaQueryList = null;
  private readonly _laptopQuery: MediaQueryList = null;
  private readonly _mouseQuery: MediaQueryList = null;
  private readonly _touchQuery: MediaQueryList = null;

  private _isAtLastPhone: boolean = null;
  private _isAtLastTablet: boolean = null;
  private _isLaptopOrBigger: boolean = null;
  private _isMouse: boolean = null;
  private _isTouch: boolean = null;

  private readonly _mediaChangedEvent = new SimpleEvent();

  private _isResizeToBeEmitted = false;
  private readonly _browserResizedEvent = new SimpleEvent();

  constructor() {
    this._phoneQuery = window.matchMedia(this._phoneText);
    this._phoneQuery.onchange = this._updateQueryResults.bind(this);

    this._tabletQuery = window.matchMedia(this._tabletText);
    this._tabletQuery.onchange = this._updateQueryResults.bind(this);

    this._laptopQuery = window.matchMedia(this._laptopText);
    this._laptopQuery.onchange = this._updateQueryResults.bind(this);

    this._mouseQuery = window.matchMedia(this._mouseText);
    this._mouseQuery.onchange = this._updateQueryResults.bind(this);

    this._touchQuery = window.matchMedia(this._touchText);
    this._touchQuery.onchange = this._updateQueryResults.bind(this);

    this._updateQueryResults();

    window.addEventListener("resize", this._windowResize.bind(this));
  }

  get mediaChanged(): ISimpleEvent {
    return this._mediaChangedEvent;
  }

  get throttledBrowserResized(): ISimpleEvent {
    return this._browserResizedEvent;
  }

  get isExactlyPhone(): boolean {
    return this._isAtLastPhone && !this._isAtLastTablet && !this._isLaptopOrBigger;
  }

  get isExactlyTablet(): boolean {
    return this._isAtLastPhone && this._isAtLastTablet && !this._isLaptopOrBigger;
  }

  get isExactlyLaptop(): boolean {
    return this._isAtLastPhone && this._isAtLastTablet && this._isLaptopOrBigger;
  }

  get isAtlastPhone(): boolean {
    return this._isAtLastPhone;
  }

  get isAtleastTablet(): boolean {
    return this._isAtLastTablet;
  }

  get isAtleastLaptop(): boolean {
    return this._isLaptopOrBigger;
  }

  get isMouse(): boolean {
    return this._isMouse;
  }

  get isTouch(): boolean {
    return this._isTouch;
  }

  // NOTE: do not use a listener on 'window.onResize' for updating: its called serveral hundred times a second during resize!
  //       MediaQueryList.onChange fires only if the registered Query gives another result than before (much better).
  private _updateQueryResults(): void {
    this._isAtLastPhone = this._phoneQuery.matches;
    this._isAtLastTablet = this._tabletQuery.matches;
    this._isLaptopOrBigger = this._laptopQuery.matches;
    this._isMouse = this._mouseQuery.matches;
    this._isTouch = this._touchQuery.matches;

    this._mediaChangedEvent.emit();
  }

  private _windowResize(): void {
    const throttleDelayMs = 100;

    if (this._isResizeToBeEmitted) return; //clearTimeout(this._resizeToBeEmitted); //for debounce
    this._isResizeToBeEmitted = true;
    setTimeout(() => {
      this._browserResizedEvent.emit();
      this._isResizeToBeEmitted = false;
    }, throttleDelayMs);
  }
}
