import { ValueGroupVm } from "./value-group-vm";
import { DashboardFiltersListVm } from "./dashboard-filters-list-vm";
import {
  KpiDashboardFm,
  KpiFm,
  KpiValueGroupFm,
  scaleModeToScaleIndex,
  ElementQueryFm,
  KpiDrillStructureFilterFm,
} from "@/features/dashboard/backend-wrapper/facade-models-dashboard";
import { KpiValueFm } from "@/features/dashboard-shared/backend-wrapper/facade-models-dashboard-shared";
import { SharedDashboardStateVm } from "./shared/shared-dashboard-state-vm";
import { KpiTileVm } from "./kpi-tile-vm";
import { SwiperVm } from "@/common/components/swiper-vm";
import { KpiTileBuilder } from "./builder/kpi-tile-builder";
import { SparklineBuilder } from "./builder/sparkline-builder";
import { FontScaler } from "@/common/formatting/font-scaler";
import { DashboardCommon } from "@/features/dashboard/dashboard-common";
import { DashboardSettings } from "@/features/dashboard-shared/dashboard-settings";
import { CloneHelper } from "@/common/object-helper/clone-helper";
import clamp from "lodash/clamp";
import isNull from "lodash/isNull";
import { ValueVm } from "./value-vm";
import { DashboardId } from "@/features/dashboard-shared/dashboard-id";
import { DashboardSelection } from "@/features/dashboard-shared/dashboard-selection";
import { DashboardError } from "@/features/dashboard/backend-wrapper/dashboard-error";
import { VsRoot } from "@/services/view-state-service/contract/vs-root";
import { IDashboardFacade } from "@/features/dashboard/backend-wrapper/dashboard-facade.interface";
import { SharedRowStateVm } from "@/features/dashboard/view-models/shared/shared-row-state-vm";

const maxFontScaleSize =
  DashboardCommon.kpiConfig.scalingStepEm + DashboardCommon.kpiConfig.maxScaledEm;

export class DashboardVm extends VsRoot {
  private _kpiTileBuilder: KpiTileBuilder = new KpiTileBuilder();
  private _sparklineBuilder: SparklineBuilder = new SparklineBuilder();

  dashboardFm: KpiDashboardFm = null;
  tilePageId: string = null;
  kpiTileVms: KpiTileVm[] = [];
  scrollGroupSwiperVms: { [key in number]: SwiperVm } = {};
  error: DashboardError = null;

  // TODO: try to move shared VMs into vue component. its problematic for cloning
  dbSettings: DashboardSettings = null;
  sharedState: SharedDashboardStateVm = null;
  filters: DashboardFiltersListVm = null;

  dashboardId: DashboardId;
  deltaValuesSwiperVm: SwiperVm = null;

  originalScaleIdx = -1;

  private _dashboardSelection: DashboardSelection;
  private _dashboardFacade: IDashboardFacade;

  isInitialized = false;
  isExpandingKpis = false;

  fontScaler = new FontScaler(
    DashboardCommon.kpiConfig.minScaledEm,
    maxFontScaleSize,
    DashboardCommon.kpiConfig.scalingStepEm
  );

  get shownKpiTileVms(): KpiTileVm[] {
    return this.kpiTileVms.filter((kpiTileVm) => kpiTileVm.isShown);
  }

  get currentValueGroups(): ValueGroupVm[] {
    return this.kpiTileVms.map((kpiTileVm) => kpiTileVm.currentValueGroup);
  }

  get showsAnyStructureElements(): boolean {
    const kpiTileVmState = this.kpiTileVms.find(
      (kpiTileVm) => kpiTileVm.currentValueGroup.structureElementsVm.isVisible
    );
    return !!kpiTileVmState;
  }

  get hasEnoughKpis(): boolean {
    return this.kpiTileVms.length > 1;
  }

  get toggleableKpiIds(): number[] {
    return this.kpiTileVms
      .filter((kpiTileVm) => kpiTileVm.backingFm.id !== this.kpiTileVms[0].backingFm.id)
      .map((kpiTileVm) => kpiTileVm.backingFm.id);
  }

  get maxNumKpiValues(): number {
    let maxNumKpiValues = 0;

    this.shownKpiTileVms
      .map((kpiTileVm) => kpiTileVm.currentValueGroup)
      .find((valueGroup) => {
        if (valueGroup.kpiValues.length > maxNumKpiValues) {
          maxNumKpiValues = valueGroup.kpiValues.length;
        }
      });
    return maxNumKpiValues;
  }

  get areSparklinesCompatible(): boolean {
    if (!this.isInitialized) {
      return false;
    }

    const firstKpiValue = this.kpiTileVms[0].currentValueGroup.kpiValues[0];
    if (!firstKpiValue.hasSparkline) {
      return false;
    }
    const timeStructure = firstKpiValue.sparkline.timeStructure;
    for (let i = 1; i < this.kpiTileVms.length; i++) {
      const currentFirstKpiValue = this.kpiTileVms[i].currentValueGroup.kpiValues[0];
      if (!currentFirstKpiValue.hasSparkline) {
        continue;
      }
      if (currentFirstKpiValue.sparkline.timeStructure !== timeStructure) {
        return false;
      }
      if (
        currentFirstKpiValue.sparkline.sparkBarValues[0].period.displayName !==
        firstKpiValue.sparkline.sparkBarValues[0].period.displayName
      ) {
        return false;
      }
    }
    return true;
  }

  private get _isKpiTileSelected(): boolean {
    return (
      !isNaN(this._dashboardSelection?.kpiTileId) &&
      this._dashboardSelection.isDashboardSelected
    );
  }

  async retryInitializeAsync(): Promise<void> {
    return this.initializeAsync(this._dashboardFacade, this._dashboardSelection);
  }

  async getFilterNames(): Promise<string[]> {
    const elementQueryFms = this._getElementQueryFms();
    const elementQueryResults = await this._dashboardFacade.getElementQueryResultsAsync(
      this.dashboardId.publishedApplicationId,
      elementQueryFms
    );

    if (elementQueryResults.error) {
      return [];
    }

    return elementQueryResults.value.map(
      (elementQueryResultFm) => elementQueryResultFm.element.displayName
    );
  }

  async initializeAsync(
    dashboardFacade: IDashboardFacade,
    dashboardSelection: DashboardSelection
  ): Promise<void> {
    this._dashboardSelection = dashboardSelection;
    this._dashboardFacade = dashboardFacade;

    if (this.isInitialized) {
      return;
    }

    this.dashboardFm = await this._requestDashboardFmAsync();

    if (this.dashboardFm) {
      this._setDefaultNumberPresentationMode();
      this._setScaledColumn();
      this._setHiddenColumn();

      const kpis = await this._requestKpiFmsAsync();
      if (kpis && kpis.length > 0) {
        this.initDeltaValuesSwiper();
        this.createKpiTileVms(kpis);
        this.updateCanShowSparklines();
        this._colorFirstValues();
        this.refreshColorAndScaling();
      }
    }
    this.updateGlobalSparklinesMax();
    this.updateMaxNumberSparklines();
    this.isInitialized = true;
  }

  async applyFiltersAsync(): Promise<void> {
    if (!this.isInitialized || this.error) {
      return;
    }

    const kpiFms = await this._requestKpiFmsAsync();
    if (!kpiFms || kpiFms.length === 0) {
      return;
    }

    this._updateKpiTilesBackingFms(this.kpiTileVms, kpiFms);
    this.refreshColorAndScaling();
    if (!this.sharedState.sparklineState.showSparklines) {
      return;
    }

    this.updateGlobalSparklinesMax();
  }

  createKpiTileVms(kpiTileFms: KpiFm[]): void {
    const publishedApplicationId = this.dashboardId.publishedApplicationId;
    const result: KpiTileVm[] = [];
    for (let i = 0; i < kpiTileFms.length; i++) {
      const kpiTileIndex = i;
      const kpiTileFm = kpiTileFms[kpiTileIndex];
      const scrollGroupId = kpiTileFm.scaleGroupId;
      const activeIndex = this._getValueGroupIndex(kpiTileFm);
      const scrollGroupSwiperVm = this._getSwiperVm(scrollGroupId, activeIndex);
      const kpiTileVm = this._kpiTileBuilder.createKpiTileVm(
        kpiTileFm,
        publishedApplicationId,
        scrollGroupSwiperVm,
        this.deltaValuesSwiperVm,
        this.filters,
        kpiTileIndex,
        this.dbSettings,
        this.sharedState,
        this._dashboardFacade
      );
      result.push(kpiTileVm);
    }
    this.dbSettings.shownKpiIds.push(result[0].kpiInfo.kpiId);
    this.kpiTileVms = result;
    this._setValidInitialKpiScaleIndex();
  }

  initDeltaValuesSwiper(): void {
    if (!this.deltaValuesSwiperVm) {
      const deltaValuesActiveIndex = this.sharedState.hiddenColumn === 1 ? 2 : 1;
      const numberOfItemsToDisplay = 1;
      this.deltaValuesSwiperVm = new SwiperVm(
        numberOfItemsToDisplay,
        deltaValuesActiveIndex
      );
    }
  }

  refreshColorAndScaling(ignoreExcludeFromScaling: boolean = false): void {
    if (!this.kpiTileVms || !this.kpiTileVms.length) {
      return;
    }

    const max = this._getKpiTileVmWithMaxNumValueGroups();
    const endingIndex = max.valueGroupVms.length;
    const startingIndex = endingIndex * -1;
    for (let index = startingIndex; index <= endingIndex; index++) {
      for (const key in this.scrollGroupSwiperVms) {
        const currentValueGroups = this._getValueGroupsAt(index, parseInt(key));
        if (currentValueGroups.length > 0) {
          this._scaleTiles(currentValueGroups, ignoreExcludeFromScaling);
        }
      }
    }
  }

  hideStructureElements(): void {
    this.currentValueGroups.map((currentValueGroup) =>
      currentValueGroup.structureElementsVm.hide()
    );
  }

  updateMaxNumberSparklines(): void {
    const deltaSparklinesEnabled = this.sharedState.sparklineState.deltaSparklinesEnabled;
    let scaleIndex = 0;
    if (deltaSparklinesEnabled) {
      scaleIndex = clamp(this.sharedState.kpiScaleIndex, 0, 2);
    }

    let maxNumBars = 0;
    this.currentValueGroups.map((vg) => {
      if (this._getNumBars(vg) > maxNumBars) {
        maxNumBars = vg.kpiValues[scaleIndex].sparkline.sparkBarValues.length;
      }
    });

    this.sharedState.sparklineState.maxNumBars = maxNumBars;
  }

  updateGlobalSparklinesMax(): void {
    const max = this._getKpiTileVmWithMaxNumValueGroups();
    if (!max) {
      return;
    }
    const endingIndex = max.valueGroupVms.length;
    const startingIndex = endingIndex * -1;

    for (let index = startingIndex; index <= endingIndex; index++) {
      for (const key in this.scrollGroupSwiperVms) {
        const currentValueGroups = this._getValueGroupsAt(index, +key);
        if (currentValueGroups.length > 0) {
          const backingFms = this._getKpiValueFmsWithSparklines(currentValueGroups);
          const sparklinesGlobalMax = this._getSparklinesGlobalMax(backingFms);
          currentValueGroups.map((vg) => (vg.sparklinesGlobalMax = sparklinesGlobalMax));
        }
      }
    }
  }

  updateValueGroupsActiveValues(): void {
    this.kpiTileVms.map((kpiTileVm) => {
      kpiTileVm.valueGroupVms.map((valueGroupVm) => {
        valueGroupVm.kpiValues.map((v) => {
          v.updateActiveValue(this.sharedState.sparklineState);
        });
      });
    });
  }

  updateCanShowSparklines(): void {
    this.sharedState.sparklineState.canShowSparklines = this._getHasAnySparkline();
  }

  shrinkKpiFilter(): void {
    this.isExpandingKpis = false;

    if (this.dbSettings.isCompact) {
      this.dbSettings.applyKpiIdFilter = true;
      this.dbSettings.isCompact = false;
    } else {
      if (this.dbSettings.isExtended && this.areSparklinesCompatible) {
        this.dbSettings.isCompact = true;
        this.hideStructureElements();
      } else {
        this.dbSettings.applyKpiIdFilter = true;
      }
    }
  }

  extendKpiFilter(): void {
    this.isExpandingKpis = true;

    if (
      this.dbSettings.applyKpiIdFilter &&
      this.sharedState.sparklineState.showSparklines
    ) {
      this.disableSparklineAnimationOnlyOnce();
    }

    if (this.dbSettings.applyKpiIdFilter) {
      this.dbSettings.applyKpiIdFilter = false;
      this.dbSettings.isCompact =
        this.areSparklinesCompatible && this.dbSettings.isExtended;
    } else {
      this.dbSettings.isCompact = false;
    }
  }

  disableSparklines(lastKpiIndex: number = null): void {
    if (!isNull(lastKpiIndex)) {
      this.sharedState.kpiScaleIndexBeforeSparklines = lastKpiIndex;
    }
    this.sharedState.sparklineState.disableSparklines();
  }

  onShowSparklinesChanged(): void {
    if (this.sharedState.sparklineState.showSparklines) {
      return;
    }

    this._resetSelectedSparkline();
    this.sharedState.kpiScaleIndex = this.sharedState.kpiScaleIndexBeforeSparklines;
    this.sharedState.hiddenColumn = this.sharedState.kpiScaleIndex === 1 ? 2 : 1;
    this.refreshColorAndScaling();
  }

  async onFiltersChangedAsync(closeDrills = true): Promise<void> {
    this._dashboardSelection.blockSettingNewValues();
    if (closeDrills) {
      this.hideStructureElements();
    }

    await this.applyFiltersAsync();
    this.updateValueGroupsActiveValues();
    this.updateGlobalSparklinesMax();
    this.updateMaxNumberSparklines();
    this.refreshColorAndScaling();
  }

  onKpiFiltersChanged(): void {
    const applyKpiIdFilter = this.dbSettings.applyKpiIdFilter;
    const shownKpisBefore = applyKpiIdFilter
      ? []
      : this.kpiTileVms.filter((kpiTileVm) => kpiTileVm.isShown);
    const newKpisToShow = applyKpiIdFilter
      ? []
      : this.kpiTileVms.filter((kpiTileVm) => !kpiTileVm.isShown);

    this._setShownKpiIds();
    this.onValueGroupChanged();

    if (applyKpiIdFilter && this._isKpiTileSelected) {
      this.selectFirstKpiTile();
      this.refreshColorAndScaling();
    }

    if (applyKpiIdFilter) {
      this.hideStructureElements();
      if (this.shownKpiTileVms.length) {
        this.shownKpiTileVms[0].valueGroupVms.map((valueGroupVm) =>
          valueGroupVm.kpiValues.map((kpiValue) => (kpiValue.excludedFromScaling = false))
        );
      }
    } else {
      this._updatePeriodIndexes(shownKpisBefore, newKpisToShow);
    }

    this.updateCanShowSparklines();
  }

  private _updatePeriodIndexes(src: KpiTileVm[], dst: KpiTileVm[]): void {
    const groupedTimeStructures = this._groupByTimeStructure(src);
    this._applyTimeStructureIndexes(dst, groupedTimeStructures);
  }

  private _groupByTimeStructure(kpiTileVms: KpiTileVm[]): { [key: string]: number } {
    return kpiTileVms.reduce(
      (result: { [key: string]: number }, kpiTileVm: KpiTileVm) => {
        if (!kpiTileVm.currentValueGroup.kpiValues[0].hasSparkline) {
          return result;
        }

        const timeStructure =
          kpiTileVm.currentValueGroup.kpiValues[0].sparkline.timeStructure;

        if (!Object.prototype.hasOwnProperty.call(result, timeStructure)) {
          result[timeStructure] = kpiTileVm.currentValueGroup.periodSwiperVm.activeIndex;
        }
        return result;
      },
      {} as { [key: string]: number }
    );
  }

  private _applyTimeStructureIndexes(
    dst: KpiTileVm[],
    groupedTimeStructures: { [key: string]: number }
  ): void {
    for (let i = 0; i < dst.length; i++) {
      if (!dst[i].currentValueGroup.kpiValues[0].hasSparkline) {
        continue;
      }

      const timeStructure = dst[i].currentValueGroup.kpiValues[0].sparkline.timeStructure;
      const index = groupedTimeStructures[timeStructure];

      if (
        !Object.prototype.hasOwnProperty.call(groupedTimeStructures, timeStructure) ||
        dst[i].periodSwiperVm.activeIndex === index
      ) {
        continue;
      }

      dst[i].periodSwiperVm.swipeTo(index, false);
    }
  }

  onValueGroupChanged(): void {
    if (
      this.sharedState.kpiScaleIndex <= 0 &&
      !this.currentValueGroups[0].kpiValues[0].weatherColor
    ) {
      this.refreshColorAndScaling(true);
    }
    this.refreshColorAndScaling();
    if (this._getHasAnySparkline()) {
      this.updateGlobalSparklinesMax();
    }
    this.updateCanShowSparklines();
  }

  onExcludeFromScalingChanged(): void {
    this.refreshColorAndScaling();
    const notExcludedValues = this._getNotExcludedValues();
    if (notExcludedValues.length === 1) {
      this._dashboardSelection.kpiTileId = notExcludedValues[0].kpiInfo.kpiId;
    }
    this.refreshColorAndScaling();
  }

  onSparklinesModeChanged(): void {
    if (this.sharedState.sparklineState.isDisabled) {
      return;
    }

    this.sharedState.kpiScaleIndexBeforeSparklines = this.sharedState.kpiScaleIndex;
    const tmpKpiScaleIndex = this.sharedState.kpiScaleIndex;
    this.sharedState.kpiScaleIndex = 0;
    this.updateMaxNumberSparklines();
    this.refreshColorAndScaling();
    if (tmpKpiScaleIndex < 0) {
      this.sharedState.kpiScaleIndex = tmpKpiScaleIndex;
    }
  }

  selectFirstKpiTile(): void {
    this.sharedState.kpiTileIndex = 0;
    this._dashboardSelection.kpiTileId = this.kpiTileVms[0].kpiInfo.kpiId;
  }

  closeTilesSameScaleGroup(kpiId: number): void {
    const selectedKpiTileVm = this.kpiTileVms.find(
      (kpiTileVm) => kpiTileVm.kpiInfo.kpiId === kpiId
    );
    if (!selectedKpiTileVm) {
      return;
    }

    this.kpiTileVms.map((kpiTileVm) => {
      if (kpiTileVm.scrollGroupId === selectedKpiTileVm.scrollGroupId) {
        kpiTileVm.currentValueGroup.structureElementsVm.hide();
      }
    });
  }

  disableSparklineAnimationOnlyOnce(): void {
    const sparklineState = this.sharedState.sparklineState;
    if (sparklineState.animationsCurrentlyEnabled) {
      sparklineState.disableAnimationOnce();
    }
  }

  private _setShownKpiIds(): void {
    if (this.dbSettings.applyKpiIdFilter) {
      this.toggleableKpiIds.forEach((idToRemove) => {
        const index = this.dbSettings.shownKpiIds.indexOf(idToRemove);
        if (index !== -1) {
          this.dbSettings.shownKpiIds.splice(index, 1);
        }
      });
    } else {
      this.toggleableKpiIds.forEach((idToAdd) => {
        const index = this.dbSettings.shownKpiIds.indexOf(idToAdd);
        if (index === -1) {
          this.dbSettings.shownKpiIds.push(idToAdd);
        }
      });
    }
  }

  private async _requestDashboardFmAsync(): Promise<KpiDashboardFm> {
    const { value, error } = await this._dashboardFacade.getDashboardsAsync(
      this.dashboardId.publishedApplicationId
    );

    if (error) {
      this.error = error;
      return null;
    }

    const dashboardFm = value.find((db) => db.id === this.dashboardId.dashboardId);
    if (!dashboardFm) {
      this.error = "InvalidDashboardId";
      return;
    }
    return dashboardFm;
  }

  private async _requestKpiFmsAsync(): Promise<KpiFm[]> {
    const { value, error } = await this._dashboardFacade.getDashboardKpisAsync(
      this.dashboardId.publishedApplicationId,
      this.dashboardId.dashboardId,
      this.filters.filters
    );

    if (error) {
      this.error = error;
      return [];
    }

    return value;
  }

  private _resetSelectedSparkline(): void {
    this.sharedState.sparklineState.resetSelection();
    this.sharedState.sparklineState.scrollPosPixel = 0;
    this.kpiTileVms.map((kpiTileVm) => this._resetSparkline(kpiTileVm));
  }

  private _resetSparkline(kpiTileVm: KpiTileVm): void {
    const lastPeriodIndex = kpiTileVm.periodSwiperVm.numberOfItems - 1;
    if (lastPeriodIndex < 0) {
      return;
    }

    const animateSwipe = false;
    kpiTileVm.periodSwiperVm.swipeTo(lastPeriodIndex, animateSwipe);
    kpiTileVm.valueGroupVms.map(
      (valueGroupVm) => (valueGroupVm.drillInfo.selectedPeriodId = null)
    );
  }

  private _scaleTiles(
    valueGroups: ValueGroupVm[],
    ignoreExcludeFromScaling: boolean
  ): void {
    const scaledValues: ValueVm[] = [];
    valueGroups.forEach((vg) => {
      const scaledValue = vg.getScaledValue(this.sharedState, ignoreExcludeFromScaling);
      if (scaledValue) {
        scaledValues.push(scaledValue);
      }
      vg.kpiValues.forEach((v) => v.updateActiveValue(this.sharedState.sparklineState));
    });

    this.fontScaler.scaleValues(scaledValues);
  }

  private _colorFirstValues(): void {
    const valuesToScale = this.currentValueGroups.map(
      (valueGroup) => valueGroup.kpiValues[0]
    );
    this.fontScaler.scaleValues(valuesToScale);
  }

  private _updateKpiTilesBackingFms(kpiTileVms: KpiTileVm[], kpiFms: KpiFm[]): void {
    kpiTileVms.map((kpiTileVm) => {
      const dashboardId = kpiTileVm.dashboardId;
      const kpiId = kpiTileVm.kpiInfo.kpiId;
      const kpiFm = this._getKpiFm(kpiFms, dashboardId, kpiId);
      if (kpiFm) {
        this._updateValueGroupsBackingFms(kpiTileVm.valueGroupVms, kpiFm.valueGroups);
      }
    });
    this._setValidInitialKpiScaleIndex();
  }

  private _updateValueGroupsBackingFms(
    valueGroupVms: ValueGroupVm[],
    kpiValueGroupFm: KpiValueGroupFm[]
  ): void {
    valueGroupVms.map((valueGroupVm) => {
      const valueGroupFm = this._getValueGroupFm(
        kpiValueGroupFm,
        valueGroupVm.valueGroupId
      );
      if (valueGroupFm) {
        this._updateKpiValuesBackingFms(valueGroupVm.kpiValues, valueGroupFm.values);
      }
    });
  }

  private _updateKpiValuesBackingFms(
    kpiValues: ValueVm[],
    kpiValueFms: KpiValueFm[]
  ): void {
    kpiValues.map((kpiValue, valueIndex) => {
      const kpiValueFm = this._getKpiValueFm(kpiValueFms, kpiValue.id);
      kpiValue.backingFm.value = kpiValueFm.value;
      kpiValue.backingFm.hasHistoryData = kpiValueFm.hasHistoryData;
      kpiValue.backingFm.historicData = kpiValueFm.historicData;
      this._updateParentRowState(kpiValue.parentRowState, valueIndex, kpiValueFm);
      this._updateSparkline(kpiValue, kpiValueFm);
    });
  }

  private _updateParentRowState(
    sharedRowStateVm: SharedRowStateVm,
    valueIndex: number,
    kpiValueFm: KpiValueFm
  ): void {
    if (kpiValueFm.hasHistoryData) {
      kpiValueFm.historicData.map(
        (histData, histDataIndex) =>
          (sharedRowStateVm.sharedValues[histDataIndex].values[valueIndex] =
            histData.value)
      );
    } else {
      sharedRowStateVm.sharedValues[0].values[valueIndex] = kpiValueFm.value;
    }
  }

  private _updateSparkline(kpiValue: ValueVm, kpiValueFm: KpiValueFm): void {
    if (!kpiValueFm.hasHistoryData) {
      return;
    }

    const newSparklines = this._sparklineBuilder.buildSparkline(
      kpiValue,
      kpiValue.kpiInfo
    );
    newSparklines.animations = kpiValue.sparkline.animations;
    kpiValue.sparkline = newSparklines;
  }

  private _setDefaultNumberPresentationMode(): void {
    this.sharedState.defaultNumberPresentationMode =
      this.dashboardFm.defaultNumberPresentationMode;
  }

  private _setScaledColumn(): void {
    const scaleMode = this.dashboardFm.defaultGlobalScaleMode;
    this.sharedState.kpiScaleIndex = scaleModeToScaleIndex(scaleMode);
  }

  private _setHiddenColumn(): void {
    this.sharedState.hiddenColumn = this.sharedState.kpiScaleIndex === 1 ? 2 : 1;
  }

  private _setValidInitialKpiScaleIndex(): void {
    const tileLengths = this.currentValueGroups.map((tvm) => tvm.kpiValues.length);

    const maxColCount = Math.max(...tileLengths);

    if (this.sharedState.kpiScaleIndex < maxColCount) {
      return;
    }

    this.sharedState.kpiScaleIndex = maxColCount - 1;
  }

  private _getHasAnySparkline(): boolean {
    const showDeltaSparklines = this.sharedState.sparklineState.deltaSparklinesEnabled;
    if (
      showDeltaSparklines &&
      !this.sharedState.sparklineState.showSparklines &&
      this.sharedState.kpiScaleIndex === -1
    ) {
      return false;
    }

    const index = showDeltaSparklines ? clamp(this.sharedState.kpiScaleIndex, 0, 2) : 0;

    const shownKpiTileVms = this.shownKpiTileVms;
    return shownKpiTileVms.some((kpiTileVm) => {
      if (index < kpiTileVm.currentValueGroup.kpiValues.length) {
        return kpiTileVm.currentValueGroup.kpiValues[index].hasSparkline;
      }
    });
  }

  private _getNumBars(valueGroup: ValueGroupVm): number {
    const scaleIndex = clamp(this.sharedState.kpiScaleIndex, 0, 2);
    return scaleIndex < valueGroup.kpiValues.length &&
      valueGroup.kpiValues[scaleIndex].hasSparkline
      ? valueGroup.kpiValues[scaleIndex].sparkline.sparkBarValues.length
      : 0;
  }

  private _getSwiperVm(scrollGroupId: number, activeIndex: number = 0): SwiperVm {
    const existingVm = this.scrollGroupSwiperVms[scrollGroupId];
    if (existingVm) return existingVm;

    const numberOfItemsToDisplay = 1;
    const infiniteLoop = true;
    activeIndex = infiniteLoop ? activeIndex + 1 : activeIndex;
    const newSwiperVm = new SwiperVm(numberOfItemsToDisplay, activeIndex, infiniteLoop);

    this.scrollGroupSwiperVms[scrollGroupId] = newSwiperVm;

    return newSwiperVm;
  }

  private _getKpiTileVmWithMaxNumValueGroups(): KpiTileVm {
    if (!this.kpiTileVms || !this.kpiTileVms.length) {
      return null;
    }

    return this.kpiTileVms.reduce((prev, curr) =>
      prev.valueGroupSwiperVm.numberOfItems > curr.valueGroupSwiperVm.numberOfItems
        ? prev
        : curr
    );
  }

  private _getValueGroupsAt(index: number, scaleGroupId: number): ValueGroupVm[] {
    const currentValueGroups: ValueGroupVm[] = [];
    const kpiTileVms = this.kpiTileVms;

    for (let i = 0; i < kpiTileVms.length; i++) {
      const kpiTileVm = kpiTileVms[i];
      if (kpiTileVm.scrollGroupId === scaleGroupId) {
        const currIndex = kpiTileVm.valueGroupSwiperVm.getRealIndex() + index;
        if (currIndex >= 0 && currIndex < kpiTileVm.valueGroupVms.length) {
          currentValueGroups.push(kpiTileVm.valueGroupVms[currIndex]);
        }
      }
    }

    return currentValueGroups;
  }

  private _getKpiValueFmsWithSparklines(valueGroups: ValueGroupVm[]): KpiValueFm[] {
    const deltaSparklinesEnabled = this.sharedState.sparklineState.deltaSparklinesEnabled;
    const kpiValueIndex = deltaSparklinesEnabled
      ? clamp(this.sharedState.kpiScaleIndex, 0, 2)
      : 0;

    return valueGroups
      .filter(
        (cvg) =>
          kpiValueIndex < cvg.kpiValues.length &&
          cvg.kpiValues[kpiValueIndex].hasSparkline &&
          !cvg.kpiValues[kpiValueIndex].excludedFromScaling
      )
      .map((cvg) => {
        const clone: KpiValueFm = CloneHelper.clone(
          cvg.kpiValues[kpiValueIndex].backingFm
        );
        const scaleFactor = cvg.kpiValues[kpiValueIndex].isAnyPercentageType
          ? 1
          : cvg.kpiValues[kpiValueIndex].kpiInfo.scaleFactor ?? 1;
        clone.historicData.map((h) => (h.value /= scaleFactor));
        return clone;
      });
  }

  private _getSparklinesGlobalMax(backingFms: KpiValueFm[]): number {
    let sparklinesGlobalMax = 0.0;
    if (backingFms.length > 0) {
      sparklinesGlobalMax = Math.max(
        ...backingFms.map((fm) =>
          Math.max(...fm.historicData.map((h) => Math.abs(h.value)))
        )
      );
    }
    return sparklinesGlobalMax;
  }

  private _getKpiFm(kpiFms: KpiFm[], dashboardId: number, kpiId: number): KpiFm {
    const fms = kpiFms.filter((k) => dashboardId === k.dashboardId && kpiId === k.id);
    if (fms.length === 1) {
      return fms[0];
    }
    return null;
  }

  private _getValueGroupFm(
    kpiValueGroupFm: KpiValueGroupFm[],
    valueGroupId: number
  ): KpiValueGroupFm {
    const fms = kpiValueGroupFm.filter((vg) => valueGroupId === vg.id);
    if (fms.length === 1) {
      return fms[0];
    }
    return null;
  }

  private _getKpiValueFm(kpiValues: KpiValueFm[], valueId: number): KpiValueFm {
    return kpiValues.filter((v) => v.kpiValueId.id === valueId)[0];
  }

  private _getValueGroupIndex(kpiFm: KpiFm): number {
    const valueGroupId = this._getValueGroupId(kpiFm);
    const defaultGroupIndex = kpiFm.valueGroups.findIndex((vg) => vg.id === valueGroupId);
    if (defaultGroupIndex < 0) {
      return 0;
    }
    return defaultGroupIndex;
  }

  private _getValueGroupId(kpiFm: KpiFm): number {
    return kpiFm.defaultValueGroupId;
  }

  private _getNotExcludedValues(): ValueVm[] {
    const shownKpiTileVms = this.kpiTileVms.filter(
      (kpiTileVmState) => kpiTileVmState.isShown
    );
    const values: ValueVm[] = [];
    for (let i = 0; i < shownKpiTileVms.length; i++) {
      const scaledValue = shownKpiTileVms[i].currentValueGroup.getScaledValue(
        this.sharedState
      );
      if (scaledValue && !scaledValue.excludedFromScaling) {
        values.push(scaledValue);
      }
    }
    return values;
  }

  private _getElementQueryFms(): ElementQueryFm[] {
    const elementQueryFms: ElementQueryFm[] = [];
    for (let i = 0; i < this.filters.filters.length; i++) {
      const filter = this.filters.filters[i];
      const kpiTileVm = this._getFirstKpiTileVmWithFilter(filter);

      if (!kpiTileVm) {
        continue;
      }

      const elementQueryFm = new ElementQueryFm();
      elementQueryFm.elementId = filter.elementId;
      elementQueryFm.structureNameId = filter.structureNameId;
      elementQueryFm.kpiId = kpiTileVm.kpiInfo.kpiId;
      elementQueryFm.dashboardId = kpiTileVm.dashboardId;
      elementQueryFms.push(elementQueryFm);
    }

    return elementQueryFms;
  }

  private _getFirstKpiTileVmWithFilter(filter: KpiDrillStructureFilterFm): KpiTileVm {
    for (let i = 0; i < this.kpiTileVms.length; i++) {
      const kpiTileVm = this.kpiTileVms[i];

      for (let j = 0; j < kpiTileVm.valueGroupVms.length; j++) {
        const valueGroupVm = kpiTileVm.valueGroupVms[j];
        const filterFound = valueGroupVm.structures.some(
          (structureVm) => structureVm.nameId === filter.structureNameId
        );

        if (filterFound) {
          return kpiTileVm;
        }
      }
    }

    return null;
  }
}
