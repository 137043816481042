<script lang="ts">
import { BcTooltip } from "@bissantz/controls";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    BcTooltip,
  },

  props: {
    informationText: { type: String, default: "" },
  },
});
</script>

<template>
  <div>
    <BcTooltip
      class="application-wizard_info-tooltip"
      contentDesign="7"
      v-bind:content="$props.informationText"
      v-bind:offset="[5, 15]"
      v-bind:arrow="false"
      v-bind:triggerDesign="'7'"
    >
    </BcTooltip>
  </div>
</template>

<style lang="less" scoped>
.application-wizard_info-tooltip {
  margin: 4px 8px 0 0;

  &.bissantzControls.tooltipControl::v-deep {
    .content[data-design="7"][data-color="blue"] {
      max-width: 600px;
    }
  }
}
</style>
