import { TileVm } from "@/features/portal/tile/tile-vm";
import { PlacementType, defaultTileConfiguration } from "@bissantz/tile-grid";
import { TileDescriptorFm } from "@/features/portal/backend-wrapper/tile-descriptor-fm";
import { reactive } from "vue";
import { ProtectedPortalSelectionVm } from "../view-models/protected-portal-selection";
import { TilePageFactory } from "./tile-page-factory";

const tilePageFactory = new TilePageFactory();

export class PortalTileBuilder {
  // TODO: remove dependencies on dashboard, instead move to tilePage
  createPortalTileVm(
    tileDescriptor: TileDescriptorFm,
    portalSelection: ProtectedPortalSelectionVm,
    sortOder?: number
  ): TileVm {
    const result = reactive(new TileVm()) as TileVm;
    result.portalSelection = portalSelection;
    result.id = tileDescriptor.tileDto.id;
    result.name = tileDescriptor.tileDto.name;
    result.description = tileDescriptor.tileDto.description;

    result.publishedApplicationId = tileDescriptor.tilePageDto?.publishedApplicationId;

    result.tileConfig = {
      ...defaultTileConfiguration,
      x: 0,
      y: 0,
      w: 2,
      h: 1,
      i: "-1",
      isResizable: false,
      placement: "normal" as PlacementType,
      order: null,
    };
    if (sortOder) {
      result.tileConfig.order = sortOder;
    }
    result.tileConfig.i = result.id;

    const pageVm = tilePageFactory.getTilePageVm(
      tileDescriptor.tilePageDto,
      tileDescriptor.tilePageType
    );
    result.tilePageVms.push(pageVm);

    result.error = tileDescriptor.error;

    return result;
  }

  clone(original: TileVm): TileVm {
    const clone = new TileVm();
    clone.id = null;
    clone.name = original.name;
    clone.isShown = original.isShown;
    clone.disableTransitions = original.disableTransitions;
    clone.publishedApplicationId = original.publishedApplicationId;
    clone.portalSelection = original.portalSelection;

    // should normally be null
    clone.error = original.error;

    const tilePageClone = original.activePage.clone();
    clone.tilePageVms.push(tilePageClone);

    clone.id = null;
    clone.name = original.name;
    clone.description = original.description;

    clone.tileConfig = { ...original.tileConfig };
    clone.tileConfig.i = clone.id;

    return clone;
  }
}
