import { PageHintType } from "./page-hint-type";

export class PageHintHelper {
  private _normalPageHintWidth = 14; // 8(font) + 3(left margin)+3(right margin) = 14px
  private _maxAvailableWidthInPixels: number = 0;
  private _numberOfPageHints: number = 0;

  set maxAvailableWidthInPixels(value: number) {
    this._maxAvailableWidthInPixels = value;
  }

  set numberOfPageHints(value: number) {
    this._numberOfPageHints = value;
  }

  get numberOfVisiblePageHints(): number {
    return this.computeNumberOfNormalPageHints(
      this._maxAvailableWidthInPixels,
      this._numberOfPageHints
    );
  }

  computeMaxNumberOfNormalPageHintsWhichCanBeDisplayed(
    maxAvailableWidthInPixels: number
  ): number {
    const maxNumberOfNormalItems = maxAvailableWidthInPixels / this._normalPageHintWidth;

    return Math.floor(maxNumberOfNormalItems);
  }

  computeNumberOfNormalPageHints(
    maxAvailableWidthInPixels: number,
    numberOfPageHints: number
  ) {
    const maxNumberOfNormalItems =
      this.computeMaxNumberOfNormalPageHintsWhichCanBeDisplayed(
        maxAvailableWidthInPixels
      );

    if (numberOfPageHints < maxNumberOfNormalItems) {
      // there is enough space to display all the page hints using the normal size
      return numberOfPageHints;
    } else {
      // there is not enough space: only some of them can be displayed in normal size

      if (maxNumberOfNormalItems % 2 == 0) {
        return maxNumberOfNormalItems - 1; // same number of hints should be display beside active index
      } else {
        return maxNumberOfNormalItems;
      }
    }
  }

  static getPageHintType(
    numberOfPageHints: number,
    numberOfVisiblePageHints: number,
    index: number,
    activeIndex: number
  ): PageHintType {
    if (numberOfPageHints == numberOfVisiblePageHints) return "normal";

    if (numberOfVisiblePageHints == 0) return "normal";

    if (numberOfVisiblePageHints <= 3) {
      const distance = Math.abs(activeIndex - index);

      if (distance == 1) return "small";
      if (distance == 2) return "tiny";

      return "normal";
    }

    const numberOfVisibleItemsLeftAndRightFromActiveIndex = Math.floor(
      (numberOfVisiblePageHints - 1) / 2
    );

    const initialVisibleMiddleIndex = numberOfVisibleItemsLeftAndRightFromActiveIndex + 1;

    if (activeIndex < initialVisibleMiddleIndex) {
      return PageHintHelper._getHintTypeWhenAllLeftHintsAreDisplayed(
        numberOfVisiblePageHints,
        index
      );
    } else {
      if (activeIndex >= numberOfPageHints - initialVisibleMiddleIndex) {
        return PageHintHelper._getHintTypeWhenAllRightHintsAreDisplayed(
          numberOfVisiblePageHints,
          numberOfPageHints,
          index
        );
      } else {
        return PageHintHelper._getHintTypeWhenSomeLeftAndRightHintsAreNotDisplayed(
          numberOfVisibleItemsLeftAndRightFromActiveIndex,
          index,
          activeIndex
        );
      }
    }
  }

  private static _getHintTypeWhenAllLeftHintsAreDisplayed(
    numberOfVisiblePageHints: number,
    index: number
  ): PageHintType {
    const lastVisibleRightIndex = numberOfVisiblePageHints - 1; // index is zero based, first page has index = 0

    if (index >= lastVisibleRightIndex) return "tiny";
    if (index == lastVisibleRightIndex - 1) return "small";

    return "normal";
  }

  private static _getHintTypeWhenAllRightHintsAreDisplayed(
    numberOfVisiblePageHints: number,
    numberOfPageHints: number,
    index: number
  ): PageHintType {
    const lastVisibleLeftIndex = numberOfPageHints - numberOfVisiblePageHints;

    if (index <= lastVisibleLeftIndex) return "tiny";
    if (index == lastVisibleLeftIndex + 1) return "small";

    return "normal";
  }

  private static _getHintTypeWhenSomeLeftAndRightHintsAreNotDisplayed(
    numberOfVisibleItemsLeftAndRightFromActiveIndex: number,
    index: number,
    activeIndex: number
  ): PageHintType {
    const lastVisibleLeftIndex =
      activeIndex - numberOfVisibleItemsLeftAndRightFromActiveIndex;
    const lastVisibleRightIndex =
      activeIndex + numberOfVisibleItemsLeftAndRightFromActiveIndex;

    if (index <= lastVisibleLeftIndex || index >= lastVisibleRightIndex) return "tiny";

    if (index == lastVisibleLeftIndex + 1 || index == lastVisibleRightIndex - 1)
      return "small";

    return "normal";
  }

  determinePageHintType(index: number, activeIndex: number): PageHintType {
    if (this._maxAvailableWidthInPixels == 0) return "normal";

    return PageHintHelper.getPageHintType(
      this._numberOfPageHints,
      this.numberOfVisiblePageHints,
      index,
      activeIndex
    );
  }
}
