import { IPublishedApplicationsServiceClient } from "@/common/service-clients/generated-clients";
import { ITileFacade } from "./tile-facade.interface";

export class TileFacade implements ITileFacade {
  private _pubAppClient: IPublishedApplicationsServiceClient = null;

  constructor(pubAppClient: IPublishedApplicationsServiceClient) {
    this._pubAppClient = pubAppClient;
  }

  async getPubAppName(pubAppId: string): Promise<string> {
    const pubAppDto = await this._pubAppClient.getPublishedApplication(pubAppId);

    return pubAppDto.name;
  }
}
