<script lang="ts">
import { defineComponent, computed } from "vue";
import type { CSSProperties } from "vue/types/jsx";

export default defineComponent({
  props: {
    color: { type: String, default: null },
    isActive: { type: Boolean, default: false },
    rotate: { type: Number, default: null },
    isHidden: { type: Boolean, default: false },
  },

  setup(props) {
    const svgStyle = computed<CSSProperties>(() => {
      return {
        "--svgColor": props.color,
        "--rotateDegree": props.rotate + "deg",
      };
    });

    return {
      svgStyle,
    };
  },
});
</script>

<template>
  <div class="navigationIcon">
    <svg
      v-bind:style="svgStyle"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 43.87 87.74"
      xml:space="preserve"
    >
      <polygon
        class="st0"
        v-bind:class="{
          isActive: isActive && !isHidden,
          isHidden: isHidden,
        }"
        points="43.87,87.74 0,43.87 43.87,0 "
      />
    </svg>
  </div>
</template>

<style lang="less" scoped>
.navigationIcon {
  display: flex;
  align-items: center;

  svg {
    height: 100%;
    width: auto;
    background-color: transparent;
    fill: var(--svgColor);
    transform: rotate(var(--rotateDegree));

    polygon {
      fill-rule: evenodd;
      clip-rule: evenodd;
      opacity: 0;

      &.isActive {
        opacity: 1;
      }

      &.isHidden {
        opacity: 0;
      }
    }
  }
}
</style>
