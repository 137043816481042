<script lang="ts">
import ChatWindow from "./chat-window.vue";
import InputMessageBox from "./input-message-box.vue";
import { inject, computed, ComputedRef, defineComponent } from "vue";
import { IChatService } from "@/services/chat-service/chat-service.interface";

export default defineComponent({
  components: { ChatWindow, InputMessageBox },

  setup: function () {
    const chatService: IChatService = inject("chatService");
    const inputMsgBoxPlaceHolder: ComputedRef<string> = computed(() => {
      return "Suche, bewerte, erkläre, teile ...";
    });

    return {
      inputMsgBoxPlaceHolder,
      chatService,
    };
  },
});
</script>

<template>
  <div class="chatFrame">
    <div class="frameFirstRow">
      <slot></slot>
      <ChatWindow
        v-if="chatService.hasMessages && chatService.isInputMsgBoxVisible"
      ></ChatWindow>
    </div>
    <div v-if="chatService.isInputMsgBoxVisible" class="frameSecondRow">
      <InputMessageBox v-bind:placeHolder="inputMsgBoxPlaceHolder"></InputMessageBox>
    </div>
  </div>
</template>

<style lang="less">
.chatFrame {
  display: flex;
  flex-direction: column;
  height: 100%;

  .frameFirstRow {
    display: flex;
    height: calc(100% - var(--chat-input-text-box-height));

    .chat {
      width: 200px;
      height: 100%;
    }
  }

  .frameSecondRow {
    height: var(--chat-input-text-box-height);
  }

  .frameFirstRow:last-child {
    height: 100%;
  }
}
</style>
