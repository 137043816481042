<script lang="ts">
import { defineComponent, computed } from "vue";

export default defineComponent({
  components: {},
  emits: [],
  props: {
    markerColor: { type: String, required: true },
    markerWidth: { type: String, required: true },
    markerHeight: { type: String, required: true },
  },
  setup(props) {
    //
    // Life Cycle:
    // --------------------

    //
    // Computeds:
    // --------------------
    const kpiMarkerStyle = computed(() => {
      return {
        "--markerColor": props.markerColor,
        "--markerWidth": props.markerWidth,
        "--markerHeight": props.markerHeight,
      };
    });

    //
    // Functions:
    // --------------------

    //
    // Watcher:
    // --------------------

    return {
      kpiMarkerStyle,
    };
  },
});
</script>

<template>
  <div class="kpi-marker" v-bind:style="kpiMarkerStyle"></div>
</template>

<style scoped lang="less">
.kpi-marker {
  position: absolute;
  left: calc(var(--markerWidth) * -1);
  background-color: var(--markerColor);
  width: var(--markerWidth);
  height: var(--markerHeight);
}
</style>
