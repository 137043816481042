<script lang="ts">
import PortalTile from "./tile/tile.vue";
import { useTileCloning } from "./tile/tile-cloning.cpsl";
import { useHelpText } from "@/services/help-text-service/help-text.cpsl";
import {
  TileGrid,
  Tile,
  TileGridService,
  TileGridConfiguration,
  defaultTileGridConfiguration,
  TileConfiguration,
  SimpleDefaultPlacementStrategy,
} from "@bissantz/tile-grid";

import { IDisposable } from "@/common/disposable.interface";
import { appResources } from "@/app-resources";
import { TileVm } from "./tile/tile-vm";
import { SharedPageState } from "./view-models/shared-page-state";
import { PortalPageVm } from "./view-models/portal-page-vm";
import { IZoomService } from "@/services/zoom-service.interface";
import { gridConstants } from "./portal-grid/grid-common";
import * as scrollHelper from "@/common/scroll-helpers";
import { dragHandle } from "@bissantz/tile-grid";
import { GridCollisionHandler } from "./portal-grid/grid-collision-handler";

import {
  reactive,
  defineComponent,
  PropType,
  computed,
  onBeforeUnmount,
  onMounted,
  onBeforeMount,
  nextTick,
  ref,
  inject,
  watch,
  provide,
  Ref,
} from "vue";
import { IScalable } from "@/common/formatting/scalable.interface";
import { IScalingContext } from "./tile-pages/scaling-context.interface";
import { CSSProperties } from "vue/types/jsx";
import { resizeHelper } from "@/common/resize-helper";

class PortalPageState {
  textResources = appResources.portalTexts;
  isScrollToTileAllowed = false;
  pageHeight: number = null;

  gridLayout: TileConfiguration[] = [];
  sharedPageState = new SharedPageState();

  disposableListeners: IDisposable[] = [];

  tileGridService: TileGridService = null;
  dragHandleClass = dragHandle;
  tileGridConfig: TileGridConfiguration = Object.assign(
    {},
    defaultTileGridConfiguration,
    {
      colNum: 12,
      verticalScroll: true,
      bottomFreeRows: gridConstants.grid.bottomFreeRows,
      rowHeight: gridConstants.grid.rowHeight,
      isResizable: false,
      minColWidthPx: gridConstants.grid.minColWidth,
      useStyleCursor: false,
      margin: [
        gridConstants.grid.marginHorizontalPx,
        gridConstants.grid.marginVerticalPx,
      ],
      verticalCompact: true,
      layoutStrategy: new SimpleDefaultPlacementStrategy(),
      layoutCollisionHandler: new GridCollisionHandler(),
    }
  );
}

export default defineComponent({
  components: {
    PortalTile,
    TileGrid,
    Tile,
  },

  props: {
    portalPageVm: { type: Object as PropType<PortalPageVm>, required: true },
    isShowing: { type: Boolean, required: true },
    isEditing: { type: Boolean, default: false },
  },

  setup: function (props) {
    const zoomService: IZoomService = inject("zoomService");

    // DOM refs:
    const ref_tileGrid = ref<InstanceType<typeof TileGrid>>();
    const ref_cloneTile = ref<InstanceType<typeof Tile>>();
    const ref_cloneDragArea = ref<HTMLDivElement>();
    const ref_tilegridHost = ref<HTMLDivElement>();

    // non-reactives:
    // -----------------
    const helpTextCpsl = useHelpText();

    // State
    const state = reactive(new PortalPageState()) as PortalPageState;

    const cloningCpsl = useTileCloning(
      props.portalPageVm,
      computed<TileConfiguration[]>(() => state.gridLayout),
      computed<TileGridService>(() => state.tileGridService),
      ref_tileGrid,
      ref_cloneDragArea,
      ref_cloneTile,
      computed<boolean>(() => props.isEditing)
    );

    const globalScalingContext = computed<IScalingContext>(() => {
      const values: IScalable[] = [];
      let allInitialized = true;

      const tiles = props.portalPageVm.tiles;
      for (const tile of tiles) {
        const tilePage = tile?.tilePageVms[0];
        if (!tilePage?.isInitialized) {
          allInitialized = false;
          break;
        }

        const tempValues = tilePage?.scaleContextValues;
        if (!tempValues || !tempValues.length) {
          continue;
        }

        values.push(...tempValues);
      }

      return { values, allInitialized };
    });
    provide<Ref<IScalingContext>>("globalScalingContext", globalScalingContext);

    //
    // Life Cycle:
    // --------------------
    onBeforeMount(async () => {
      state.disposableListeners.push(zoomService.factorChanged.on(handleZoomChanged));
    });

    onMounted(async () => {
      await init();
      state.tileGridService?.refreshGrid();
    });

    // TODO: better not use setTimeout-Loop, instead computed + watcher
    //             seems cleaner and more vue idiomatic
    function watchTilesAndRefreshGrid(): void {
      const shownTiles = props.portalPageVm.shownTiles;
      // TODO: with tilePages we will have a better way to determine the number of initialized tiles.
      //       Would be good to have here (in page.vue) only to reference tile.isInitialized (or similar)
      const allTilesInitialised =
        shownTiles.filter(
          (tile) => tile.error || tile.isVsSyncReady() || tile.activePage.isInError
        ).length === shownTiles.length;

      // idea: refresh the grid, once all tiles are rendered.
      if (allTilesInitialised) {
        setTimeout(() => {
          state.tileGridService?.refreshGrid();
          state.isScrollToTileAllowed = true;
        }, 900);
      } else {
        setTimeout(() => watchTilesAndRefreshGrid(), 200);
      }
    }

    onBeforeUnmount(() => {
      state.disposableListeners.map((disposer) => disposer.dispose());
    });

    async function init(): Promise<void> {
      const collisionHandler = state.tileGridConfig
        .layoutCollisionHandler as GridCollisionHandler;
      collisionHandler.tgService = state.tileGridService as TileGridService;
      collisionHandler.gridRef = ref_tileGrid?.value?.$el as HTMLElement;

      state.gridLayout = initGridLayout();

      handleZoomChanged({ oldFactor: null, newFactor: zoomService.factor });
    }

    //
    // Computeds:
    // --------------------
    const errorMessage = computed<string>(() => {
      if (!props.portalPageVm.hasNoTiles) {
        return null;
      }

      return state.textResources.errorNoTiles;
    });

    const fullpageStyle = computed<CSSProperties>(() => {
      return {
        width: "100%",
        height: state.pageHeight + "px",
        "z-index": 1,
        transform: "unset",
        scale: 1,
      };
    });

    //
    // Functions:
    // --------------------
    function initGridLayout(): TileConfiguration[] {
      let gridItemLayouts = props.portalPageVm.tileLayout;

      gridItemLayouts.push(cloningCpsl.state.cloneTileConfiguration);

      // don't animate the initial placement of the tiles
      nextTick(() => {
        props.portalPageVm.shownTiles.map((tile) => {
          tile.isShown = true;
          setTimeout(() => {
            tile.disableTransitions = false;
          }, 500);
        });
      });

      return gridItemLayouts;
    }

    function handleZoomChanged(ev: { oldFactor: number; newFactor: number }): void {
      state.tileGridService?.setZoom(ev.newFactor);
      const collisionHandler = state.tileGridConfig
        .layoutCollisionHandler as GridCollisionHandler;
      collisionHandler.enableZoomHandling({
        factor: ev.newFactor,
        previousFactor: ev.oldFactor,
      });
    }

    function onShownTilesChanged(newValues: TileVm[], oldValues: TileVm[]): void {
      const deletedTiles = getDeletedTiles(newValues, oldValues);
      if (deletedTiles.length === 0) {
        return;
      }

      const scalingContexts = getScalingContexts(deletedTiles);
      deleteScalingContexts(scalingContexts);
    }

    function getDeletedTiles(newValues: TileVm[], oldValues: TileVm[]): TileVm[] {
      if (oldValues.length > newValues.length) {
        return oldValues.filter((ov) => !newValues.find((nv) => nv === ov));
      }
      return [];
    }

    function getScalingContexts(portalTileVms: TileVm[]): IScalable[] {
      const scalingContexts: IScalable[] = [];
      portalTileVms.forEach((v) => scalingContexts.push(...v.portalScaledValueCopies));
      return scalingContexts;
    }

    function deleteScalingContexts(scalingContexts: IScalable[]): void {
      scalingContexts.forEach((scalingContext) => deleteScalingContext(scalingContext));
    }

    function deleteScalingContext(scalableValue: IScalable): void {
      const indexToRemove = state.sharedPageState.portaScalingContext.findIndex(
        (portalScalingContext) => portalScalingContext === scalableValue
      );
      if (indexToRemove === -1) {
        return;
      }

      state.sharedPageState.portaScalingContext.splice(indexToRemove, 1);
    }

    function onFullpageVmChanged(fullpageTile: TileVm): void {
      props.portalPageVm.fullpageTileVm = fullpageTile;
      const showFullpage = !!fullpageTile;
      if (showFullpage) {
        state.tileGridConfig.verticalScroll = false;
      } else {
        state.tileGridConfig.verticalScroll = true;
      }
    }

    function onTilegridHostShown(): void {
      if (!ref_tilegridHost.value) {
        return;
      }

      state.disposableListeners.push({
        dispose: resizeHelper.observe(
          ref_tilegridHost.value,
          (entry: ResizeObserverEntry) => {
            state.pageHeight = entry.contentRect.height;
          }
        ),
      });
      stopWatchTileGridHost();
    }

    function onGridSingleColumned(isSingleCol: boolean, wasSingleCol: boolean): void {
      if (!isSingleCol && wasSingleCol) {
        props.portalPageVm.tiles
          .filter((t) => t.tileConfig.w === 1)
          .map((t) => (t.tileConfig.w = 2));
      }

      wasSingleCol = isSingleCol;
    }

    //
    // Watcher:
    // --------------------
    watch(() => state.tileGridConfig.colNum, onNumColumnsChanged, { immediate: true });
    function onNumColumnsChanged() {
      state.sharedPageState.numberOfGridColumns = state.tileGridConfig.colNum;
    }

    watch(() => props.isShowing, onIsShowing, { immediate: true });
    async function onIsShowing(): Promise<void> {
      state.sharedPageState.isShowing = props.isShowing;
      if (!props.isShowing || state.sharedPageState.wasShown) {
        return;
      }

      state.sharedPageState.wasShown = true;
      watchTilesAndRefreshGrid();
    }

    watch(() => props.portalPageVm.shownTiles, onShownTilesChanged);
    watch(
      () => props.portalPageVm.tiles.find((tile) => tile.isFullpage),
      onFullpageVmChanged,
      { immediate: true }
    );
    const stopWatchTileGridHost = watch(
      () => !!ref_tilegridHost.value,
      onTilegridHostShown,
      { immediate: true }
    );
    watch(() => state.tileGridConfig.colNum < 2, onGridSingleColumned, {
      immediate: true,
    });

    async function scrollToTile(
      element: HTMLElement,
      childClassNames: string[]
    ): Promise<void> {
      if (!state.isScrollToTileAllowed) {
        return;
      }

      await nextTick();
      scrollHelper.scrollToChild(
        element,
        childClassNames,
        state.tileGridService as TileGridService
      );
    }

    return {
      // data
      helpTextCpsl,

      // reactive:
      state,
      cloningCpsl,
      ref_tileGrid,
      ref_cloneTile,
      ref_cloneDragArea,
      ref_tilegridHost,

      // Computed:
      errorMessage,
      fullpageStyle,

      // Methods:
      scrollToTile,
    };
  },
});
</script>

<template>
  <div
    class="portal-page-component"
    v-if="portalPageVm"
    v-bind:class="{
      'is-fullpage': !!$props.portalPageVm.fullpageTileVm,
    }"
  >
    <div v-if="portalPageVm.isLoading" class="interactProtect" />
    <div v-if="errorMessage" class="page-error-box">{{ errorMessage }}</div>
    <div
      v-else
      class="tile-grid-portal"
      ref="ref_tilegridHost"
      v-on:mousemove="cloningCpsl.handleMousemove_OnGrid"
      v-on:mouseleave="cloningCpsl.handleMouseleave_OnGrid"
    >
      <TileGrid
        ref="ref_tileGrid"
        v-bind:tileGridConfiguration="state.tileGridConfig"
        v-bind:tileGridLayout.sync="state.gridLayout"
        v-on:tile-grid-service="state.tileGridService = $event"
      >
        <Tile
          v-for="tileVm in $props.portalPageVm.shownTiles"
          v-bind:key="tileVm.id"
          v-bind:data-id="tileVm.id"
          v-bind:layoutTile="tileVm.tileConfig"
          v-bind:class="{
            'show-tile': tileVm.isShown,
            'disable-transitons': tileVm.disableTransitions,
          }"
          v-bind:style="tileVm.isFullpage ? fullpageStyle : {}"
          v-on:move="cloningCpsl.state.isNormalDragging = true"
          v-on:mouseup.native="cloningCpsl.handleDragend_AtTile"
        >
          <PortalTile
            v-bind:tileVm="tileVm"
            v-bind:isEditing="$props.isEditing"
            v-bind:sharedPageState="state.sharedPageState"
            v-bind:class="{ opaqueTile: cloningCpsl.state.isCloneDragging }"
            v-on:common_focusElementChanged="scrollToTile"
            v-on:portalTile_deleteTile="cloningCpsl.onDeleteTile"
            v-on:portalTile_clone="cloningCpsl.onAutomaticClone"
            v-on:mouseenter.native="cloningCpsl.handleMouseenter_AtTile(tileVm)"
            v-on:mousedown.native="cloningCpsl.handleMousedown_AtTile"
            v-on="$listeners"
          />
        </Tile>
        <Tile
          ref="ref_cloneTile"
          class="clone-tile disable-transitons"
          v-bind:key="'cloneTile'"
          v-bind:layoutTile="cloningCpsl.state.cloneTileConfiguration"
          v-on:move="cloningCpsl.handleDragmove_AtCloneTile"
          v-on:mouseup.native="cloningCpsl.handleDragend_AtCloneTile"
          v-on:mousedown.native="cloningCpsl.handleMousedown_AtCloneTile"
        >
          <div
            ref="ref_cloneDragArea"
            class="clone-tile-content"
            v-bind:data-helpText="helpTextCpsl.clonePortalTileText()"
            v-bind:class="[state.dragHandleClass]"
          />
        </Tile>
      </TileGrid>
    </div>
  </div>
</template>

<style lang="less" scoped>
.portal-page-component {
  height: calc(100% - 4px);
  width: 100%;
  padding: 2px 0;
  position: relative;

  &.is-fullpage {
    padding: 0;
    height: 100%;
  }

  .interactProtect {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
  }

  .tile-grid-portal {
    .bissantz-tile.clone-tile {
      opacity: 1;

      .clone-tile-content {
        height: calc(100% - 28px);
        width: 100%;
        cursor: copy;
      }
    }
  }

  .page-error-box {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
  }
}
</style>

<style lang="less">
.portal-page-component {
  width: 100%;

  .tile-grid-portal {
    height: 100%;

    .tile-grid-frame {
      margin: 0;
    }

    // grid override styles
    .bissantz-tile-grid {
      background: none;

      .bissantz-tile.bissantz-placeholder-tile {
        background-color: var(--color_Weather0);
      }

      .bissantz-tile:not(.bissantz-placeholder-tile) {
        background: none;
        border: none;
      }

      .bissantz-tile {
        @keyframes blend-in-animation {
          0% {
            opacity: 0;
          }

          100% {
            opacity: 1;
          }
        }

        opacity: 0;

        .opaqueTile {
          opacity: 0.3;
        }

        &.disable-transitons {
          transition: none;
        }

        &.show-tile {
          animation: blend-in-animation;
          animation-duration: 0.4s;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
        }
      }
    }
  }
}
</style>
