import { BiColorThresholds } from "@/common/formatting/bicolor-thresholds";
import { TimeStructureTypeFm } from "@/features/dashboard-shared/sparkline/backend-wrapper/facade-models-sparklines";
import { IColorInfo } from "@/common/formatting/color-info-interface";
import { StructureVm } from "../structure-vm";

export class SharedKpiInfo implements IColorInfo {
  factor: number = null;
  scaleFactor?: number = null;
  invertSign: boolean = null;
  kpiId: number = null;

  timeStructure: TimeStructureTypeFm = null;
  biColorThresholds?: BiColorThresholds = null;
  kpiStructures: StructureVm[] = [];

  constructor(other?: Partial<SharedKpiInfo>) {
    // todo: properly clone StructureVms ...for now its okay as these will not change in any scenario
    if (other) {
      Object.assign(this, other);

      this.biColorThresholds = new BiColorThresholds();
      if (other.biColorThresholds) {
        this.biColorThresholds.rangeHigh = other.biColorThresholds.rangeHigh;
        this.biColorThresholds.rangeLow = other.biColorThresholds.rangeLow;
        this.biColorThresholds.threshold = other.biColorThresholds.threshold;
      }

      if (other.kpiStructures)
        this.kpiStructures = other.kpiStructures.map((str) => new StructureVm(str));
    }
  }

  clone(cloneStructures?: StructureVm[]): SharedKpiInfo {
    const clone = new SharedKpiInfo();

    clone.factor = this.factor;
    clone.scaleFactor = this.scaleFactor;
    clone.invertSign = this.invertSign;
    clone.kpiId = this.kpiId;
    clone.timeStructure = this.timeStructure;
    clone.biColorThresholds = this.biColorThresholds?.clone();
    clone.kpiStructures = cloneStructures ?? this.kpiStructures.map((str) => str.clone());

    return clone;
  }
}
