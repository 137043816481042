<script lang="ts">
import { useHoverHelper } from "@/common/composables/hover-helper.cpsl";
import { DefaultStyles } from "@/common/styles/default-styles";
import { defineComponent, computed, Ref, ref } from "vue";
import type { CSSProperties } from "vue/types/jsx";

const colorConstants = DefaultStyles.colorConstants;

export default defineComponent({
  props: {
    initialColor: { type: String, default: colorConstants.headerTextHEX },
    hoverColor: { type: String, default: colorConstants.darkTextHEX },
  },
  setup(props) {
    const ref_el: Ref<HTMLDivElement> = ref(null);
    const hoverHelperCpsl = useHoverHelper(ref_el);

    //
    // Computeds:
    // --------------------
    const svgStyle = computed<CSSProperties>(() => {
      const isHover = props.hoverColor !== null && hoverHelperCpsl.isHover.value;
      return {
        "--stroke-color": isHover ? props.hoverColor : props.initialColor,
      };
    });

    return {
      ref_el,
      svgStyle,
    };
  },
});
</script>

<template>
  <div ref="ref_el" class="minimize-icon" v-bind:style="svgStyle">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 145.32 145.32">
      <g>
        <polyline points="86.488 18.302 86.488 58.832 127.018 58.832" stroke-width="14" />
        <line x1="135.82" y1="9.5" x2="86.488" y2="58.832" />
      </g>
      <g>
        <polyline points="58.832 127.018 58.832 86.488 18.302 86.488" stroke-width="14" />
        <line x1="9.5" y1="135.82" x2="58.832" y2="86.488" />
      </g>
    </svg>
  </div>
</template>

<style scoped lang="less">
.minimize-icon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  svg {
    height: 100%;
    width: 100%;
    fill: none;
    stroke: var(--stroke-color);
    stroke-width: 16;
    stroke-miterlimit: 10;
  }
}
</style>
