<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["click"],

  props: {
    mainColor: {
      type: String,
      required: true,
    },
  },
  setup(_props, context) {
    async function onIconClicked() {
      context.emit("click");
    }
    return {
      // functions:
      onIconClicked,
    };
  },
});
</script>

<template>
  <div class="create-icon" v-on:click.stop="onIconClicked">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      xml:space="preserve"
      width="24"
      height="24"
    >
      <g>
        <line x1="12" y1="0" x2="12" y2="23" />
        <line x1="0" y1="12" x2="23" y2="12" />
      </g>
    </svg>
  </div>
</template>

<style lang="less" scoped>
.create-icon {
  cursor: inherit;

  svg {
    float: right;
    stroke: v-bind("$props.mainColor");
    stroke-width: 2px;
  }
}
</style>
