import {
  IKpiValueGroupDto,
  IKpiDto,
  IKpiDashboardDto,
  NumberPresentationMode,
  ScaleMode,
  IStructureDto,
  IBiColorThresholdsDto,
  KpiDrillResultDto,
  KpiDrillRowDto,
  KpiDrillAggregateInfoDto,
  SortType,
  StructureElementDto,
  ElementQueryResultDto,
} from "@/common/service-clients/generated-clients";
import {
  KpiDashboardFm,
  KpiDrillResultFm,
  KpiValueGroupFm,
  KpiFm,
  NumberPresentationModeFm,
  ScaleModeFm,
  StructureFm,
  BiColorThresholds,
  KpiDrillRowFm,
  KpiDrillAggregateInfoFm,
  SortTypeFm,
  StructureElementFm,
  ElementQueryResultFm,
} from "@/features/dashboard/backend-wrapper/facade-models-dashboard";
import { FmMapperDashboardShared } from "@/features/dashboard-shared/backend-wrapper/fm-mapper-dashboard-shared";
import { FmMapperSparklines } from "@/features/dashboard-shared/sparkline/backend-wrapper/fm-mapper-sparklines";

export class FmMapperDashboard {
  static mapKpiDashboardFms(kpiDashboardDtos: IKpiDashboardDto[]): KpiDashboardFm[] {
    return kpiDashboardDtos.map((kpiDashboardDto) =>
      this._mapKpiDashboardFm(kpiDashboardDto)
    );
  }

  static mapKpiFms(kpiDtos: IKpiDto[]) {
    return kpiDtos.map((kpiDto) => this._mapKpiDto(kpiDto));
  }

  static mapKpiDrillResultFm(kpiDrillResultDto: KpiDrillResultDto) {
    const kpiDrillResultFm = new KpiDrillResultFm();
    kpiDrillResultFm.rows = this._mapKpiDrillRowFms(kpiDrillResultDto.rows);
    kpiDrillResultFm.hasMoreRows = kpiDrillResultDto.hasMoreRows;
    kpiDrillResultFm.totalRowCount = kpiDrillResultDto.totalRowCount;
    kpiDrillResultFm.aggregateInfoByValueId = this._mapKpiDrillAggregateInfoFm(
      kpiDrillResultDto.aggregateInfoByValueId
    );
    kpiDrillResultFm.historicAggregateInfoHelperIndexByValueId =
      kpiDrillResultDto.historicAggregateInfoHelperIndexByValueId;
    kpiDrillResultFm.historicAggregateInfoByHelperIndex =
      this._mapKpiDrillAggregateInfoFms(
        kpiDrillResultDto.historicAggregateInfoByHelperIndex
      );
    return kpiDrillResultFm;
  }

  static mapSortTypeFm(sortType: SortType): SortTypeFm {
    return FmMapperDashboard._mappingSortType[sortType];
  }

  static mapElementQueryResultFms(
    elementQueryResultDtos: ElementQueryResultDto[]
  ): ElementQueryResultFm[] {
    return elementQueryResultDtos.map((elementQueryResultDto) =>
      this._mapElementQueryResultFm(elementQueryResultDto)
    );
  }

  private static _mapValueGroupFms(valueGroupDtos: IKpiValueGroupDto[]) {
    return valueGroupDtos.map((valueGroupDto) => this._mapValueGroupFm(valueGroupDto));
  }

  private static _mapValueGroupFm(valueGroupDto: IKpiValueGroupDto): KpiValueGroupFm {
    const valueGroupFm = new KpiValueGroupFm();

    valueGroupFm.dashboardId = valueGroupDto.dashboardId;
    valueGroupFm.kpiId = valueGroupDto.kpiId;
    valueGroupFm.id = valueGroupDto.id;
    valueGroupFm.kpiValueGroupId = FmMapperDashboardShared.mapKpiValueGroupIdFm(
      valueGroupDto.kpiValueGroupId
    );
    valueGroupFm.period = FmMapperSparklines.mapPeriodFm(valueGroupDto.period);
    valueGroupFm.values = FmMapperDashboardShared.mapKpiValueFms(valueGroupDto.values);
    return valueGroupFm;
  }

  private static _mapKpiDto(kpiDto: IKpiDto) {
    const kpiFm = new KpiFm();
    kpiFm.dashboardId = kpiDto.dashboardId;
    kpiFm.id = kpiDto.id;
    kpiFm.kpiId = FmMapperDashboardShared.mapKpiIdFm(kpiDto.kpiId);
    kpiFm.name = kpiDto.name;
    kpiFm.factor = kpiDto.factor;
    kpiFm.scaleFactor = kpiDto.scaleFactor;
    kpiFm.invertSign = kpiDto.invertSign;
    kpiFm.scaleGroupId = kpiDto.scaleGroupId;
    kpiFm.valueGroups = this._mapValueGroupFms(kpiDto.valueGroups);
    kpiFm.defaultValueGroupId = kpiDto.defaultValueGroupId;
    kpiFm.structures = this._mapStructureFms(kpiDto.structures);
    kpiFm.timeStructureType = FmMapperSparklines.mapTimeStructureTypeFm(
      kpiDto.timeStructureType
    );
    kpiFm.biColorThresholds = this._mapBiColorThresholdFms(kpiDto.biColorThresholds);
    return kpiFm;
  }

  private static _mapKpiDashboardFm(kpiDashboardDto: IKpiDashboardDto): KpiDashboardFm {
    const kpiDashboardFm = new KpiDashboardFm();
    kpiDashboardFm.id = kpiDashboardDto.id;
    kpiDashboardFm.dashboardId = FmMapperDashboardShared.mapKpiDashboardIdFm(
      kpiDashboardDto.dashboardId
    );
    kpiDashboardFm.name = kpiDashboardDto.name;
    kpiDashboardFm.defaultNumberPresentationMode =
      this._mappingNumberPresentationMode[kpiDashboardDto.defaultNumberPresentationMode];
    kpiDashboardFm.defaultGlobalScaleMode =
      this._mappingScaleMode[kpiDashboardDto.defaultGlobalScaleMode];
    return kpiDashboardFm;
  }

  private static _mapStructureFms(structureDtos: IStructureDto[]) {
    return structureDtos.map((structureDto) => this._mapStructureFm(structureDto));
  }

  private static _mapStructureFm(structureDto: IStructureDto) {
    const structureFm = new StructureFm();
    structureFm.dashboardId = structureDto.dashboardId;
    structureFm.kpiId = structureDto.kpiId;
    structureFm.id = structureDto.id;
    structureFm.structureId = FmMapperDashboardShared.mapStructureIdFm(
      structureDto.structureId
    );
    structureFm.nameId = structureDto.nameId;
    structureFm.displayName = structureDto.displayName;
    return structureFm;
  }

  private static _mapBiColorThresholdFms(
    biColorThresholdsDto: IBiColorThresholdsDto
  ): BiColorThresholds {
    const biColorThresholdsFm = new BiColorThresholds();
    biColorThresholdsFm.threshold = biColorThresholdsDto.threshold;
    biColorThresholdsFm.rangeLow = biColorThresholdsDto.rangeLow;
    biColorThresholdsFm.rangeHigh = biColorThresholdsDto.rangeHigh;
    return biColorThresholdsFm;
  }

  private static _mapKpiDrillRowFms(kpiDrillRowDtos: KpiDrillRowDto[]): KpiDrillRowFm[] {
    return kpiDrillRowDtos.map((kpiDrillRowDto) =>
      this._mapKpiDrillRowFm(kpiDrillRowDto)
    );
  }

  private static _mapKpiDrillRowFm(kpiDrillRowDto: KpiDrillRowDto): KpiDrillRowFm {
    const kpiDrillRowFm = new KpiDrillRowFm();
    kpiDrillRowFm.elementId = FmMapperDashboardShared.mapElementIdFm(
      kpiDrillRowDto.elementId
    );
    kpiDrillRowFm.structureElement = this._mapStructureElementFm(
      kpiDrillRowDto.structureElement
    );
    kpiDrillRowFm.unit = kpiDrillRowDto.unit;
    kpiDrillRowFm.kpiValues = FmMapperDashboardShared.mapKpiValueFms(
      kpiDrillRowDto.kpiValues
    );
    return kpiDrillRowFm;
  }

  private static _mapKpiDrillAggregateInfoFms(
    kpiDrillAggregateInfoDtos: {
      [key: string]: KpiDrillAggregateInfoDto;
    }[]
  ): { [key: string]: KpiDrillAggregateInfoFm }[] {
    return kpiDrillAggregateInfoDtos.map((kpiDrillAggregateInfoDto) =>
      this._mapKpiDrillAggregateInfoFm(kpiDrillAggregateInfoDto)
    );
  }

  private static _mapKpiDrillAggregateInfoFm(kpiDrillAggregateInfoDto: {
    [key: string]: KpiDrillAggregateInfoDto;
  }): { [key: string]: KpiDrillAggregateInfoFm } {
    const kpiDrillAggregateInfoFms: { [key: string]: KpiDrillAggregateInfoFm } = {};
    for (const key in kpiDrillAggregateInfoDto) {
      kpiDrillAggregateInfoFms[key] = kpiDrillAggregateInfoDto[key];
    }
    return kpiDrillAggregateInfoFms;
  }

  private static _mapStructureElementFm(
    structureElementDto: StructureElementDto
  ): StructureElementFm {
    const structureElementFm = new StructureElementFm();
    structureElementFm.id = structureElementDto.id;
    structureElementFm.displayName = structureElementDto.displayName;
    return structureElementFm;
  }

  private static _mapElementQueryResultFm(elementQueryResultDto: ElementQueryResultDto) {
    const elementQueryResultFm = new ElementQueryResultFm();
    elementQueryResultFm.dashboardId = elementQueryResultDto.dashboardId;
    elementQueryResultFm.kpiId = elementQueryResultDto.kpiId;
    elementQueryResultFm.structureNameId = elementQueryResultDto.structureNameId;
    elementQueryResultFm.element = this._mapStructureElementFm(
      elementQueryResultDto.element
    );
    return elementQueryResultFm;
  }

  private static _mappingSortType: { [key in SortType]: SortTypeFm } = {
    ByValueAscending: "ByValueAscending",
    ByValueDescending: "ByValueDescending",
    BySortId: "BySortId",
    ByNameAscending: "ByNameAscending",
    ByNameDescending: "ByNameDescending",
  };

  private static _mappingNumberPresentationMode: {
    [key in NumberPresentationMode]: NumberPresentationModeFm;
  } = {
    Compact: "Compact",
    Detailed: "Detailed",
    DetailedNoBissantzNumbers: "DetailedNoBissantzNumbers",
  };

  private static _mappingScaleMode: {
    [key in ScaleMode]: ScaleModeFm;
  } = {
    Structure: "Structure",
    Information: "Information",
    Deviation: "Deviation",
  };
}
