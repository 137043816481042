<script lang="ts">
import { computed, ref, watch, defineComponent, reactive } from "vue";
import type { CSSProperties } from "vue/types/jsx";

export default defineComponent({
  // hard coded strings is okay here, because these events are v-model defaults
  emits: ["update:modelValue", "input"],

  props: {
    min: { type: Number, default: 0.1 },
    max: { type: Number, default: 10 },
    step: { type: Number, default: 0.1 },
    // defaults must be null to know which value is set (by v-model)
    modelValue: { type: Number, default: null }, // needed in vue3.
    value: { type: Number, default: null }, // needed in vue2.
    showStepper: { type: Boolean, default: false },
    markers: {
      type: Array<number>,
      default: () => [],
    },
  },

  setup(props, context) {
    const initialValue = props.modelValue === null ? props.value : props.modelValue;
    const ref_trackItems = ref(null);
    const state = reactive({
      // TODO: rangeSliderValue should normally be dynamically computed depending on the props (v-model update should also update props!)
      rangeSliderValue: initialValue,
      thumbPosition: getPositionInSlider(initialValue),
      isDragging: false,
      startX: 0,
      startWidth: 0,
    });

    const thumbStyle = computed(() => {
      return {
        "--thumbPosition": `${state.thumbPosition}%`,
      };
    });

    function getMarkerStyle(marker: number): CSSProperties {
      const markerPos = getPositionInSlider(marker);
      const isVisible = 0 <= markerPos && markerPos <= 100;
      return {
        "--markerPosition": `${markerPos}%`,
        visibility: isVisible ? "initial" : "hidden",
      };
    }

    function onMarkerClicked(marker: number): void {
      updateRangeSliderValue(marker);
    }

    function emitUpdateValue(): void {
      context.emit("update:modelValue", state.rangeSliderValue); // needed in vue3.
      context.emit("input", state.rangeSliderValue); //needed for vue2. (can be deleted in future)
    }

    function getPositionInSlider(value: number) {
      return ((value - props.min) / (props.max - props.min)) * 100;
    }

    function updateRangeSliderValue(value: number): void {
      state.rangeSliderValue = value;
      emitUpdateValue();
    }

    function onMinusClicked(): void {
      const value = Math.max(state.rangeSliderValue - props.step, props.min);

      updateSliderAfterStepperClicked(value);
    }

    function onPlusClicked(): void {
      const value = Math.min(state.rangeSliderValue + props.step, props.max);

      updateSliderAfterStepperClicked(value);
    }

    function updateSliderAfterStepperClicked(value: number) {
      updateRangeSliderValue(value);
      updateThumbByRangeSliderValue(state.rangeSliderValue);
    }

    function updateThumbByRangeSliderValue(value: number) {
      const thumbPosition = getPositionInSlider(value);
      setThumbPosition(thumbPosition);
    }

    function onPointerDown(event: PointerEvent): void {
      addEventListenersOnDocument();
      moveThumbToPosition(event);
    }

    function addEventListenersOnDocument(): void {
      document.addEventListener("pointermove", dragThumb);
      document.addEventListener("pointerup", endDrag);
      document.addEventListener("pointercancel", endDrag);
    }

    function removeEventListenersOnDocument(): void {
      document.removeEventListener("pointermove", dragThumb);
      document.removeEventListener("pointerup", endDrag);
      document.removeEventListener("pointercancel", endDrag);
    }

    function calculateNewRangeSliderValue(value: number): number {
      const decimalValue = (value / 100) * (props.max - props.min);
      const roundedValue = Math.round(decimalValue / props.step) * props.step;
      const finalValue = roundedValue + props.min;
      return finalValue;
    }

    function setThumbPosition(value: number): void {
      state.thumbPosition = value;
    }

    function moveThumbToPosition(event: PointerEvent): void {
      event.preventDefault();
      state.isDragging = true;

      const track = ref_trackItems.value.getBoundingClientRect();
      const trackWidth = track.width;
      const clickX = event.clientX - track.left;
      const newPos = (Math.max(0, Math.min(trackWidth, clickX)) / trackWidth) * 100;
      const newRangeSliderValue = calculateNewRangeSliderValue(newPos);

      updateRangeSliderValue(newRangeSliderValue);
      setThumbPosition(newPos);
      setStartValuesForDragging(event.clientX, state.thumbPosition);
      startDrag(event);
    }

    function dragThumb(event: PointerEvent): void {
      if (state.isDragging) {
        event.preventDefault();

        const track = ref_trackItems.value.getBoundingClientRect();
        const trackWidth = track.width;
        const clientX = event.clientX;
        const diffX = clientX - state.startX;
        const circleX = (state.startWidth / 100) * trackWidth;
        const newWidth =
          (Math.max(0, Math.min(circleX + diffX, trackWidth)) / trackWidth) * 100;
        const newRangeSliderValue = calculateNewRangeSliderValue(newWidth);

        updateRangeSliderValue(newRangeSliderValue);
        setThumbPosition(newWidth);
      }
    }

    function setStartValuesForDragging(clientX: number, width: number): void {
      state.startX = clientX;
      state.startWidth = width;
    }

    function startDrag(event: PointerEvent): void {
      event.preventDefault();
      state.isDragging = true;
      setStartValuesForDragging(event.clientX, state.thumbPosition);
    }

    function endDrag(): void {
      state.isDragging = false;
      setStartValuesForDragging(0, 0);
      removeEventListenersOnDocument();
    }

    watch([() => props.value, () => props.max, () => props.min], (newValues) => {
      const propsValue = newValues[0];
      state.rangeSliderValue = propsValue;
      updateThumbByRangeSliderValue(state.rangeSliderValue);
    });

    return {
      state,
      ref_trackItems,
      thumbStyle,
      getMarkerStyle,
      onPointerDown,
      onMinusClicked,
      onPlusClicked,
      onMarkerClicked,
    };
  },
});
</script>

<template>
  <div class="rangeSliderComponent">
    <div class="minus button" v-on:click="onMinusClicked">
      <div class="horizontalLine" />
    </div>
    <div class="track" v-on:pointerdown="onPointerDown">
      <div class="horizontalLine" />
      <div class="trackItems" ref="ref_trackItems">
        <div
          v-for="(marker, index) in $props.markers"
          v-bind:key="index"
          class="marker-wrapper"
          v-on:pointerdown.stop="onMarkerClicked(marker)"
          v-bind:style="getMarkerStyle(marker)"
        >
          <div class="marker" />
        </div>
        <div class="thumb" v-bind:style="thumbStyle"></div>
      </div>
    </div>
    <div class="plus button" v-on:click="onPlusClicked">
      <div class="horizontalLine" />
      <div class="verticalLine" />
    </div>
  </div>
</template>

<style lang="less" scoped>
.rangeSliderComponent {
  --rangeSize: 22px;
  --lineThickness: 2px;
  --color: var(--color_headerText);
  --marginButtonLine: 6px;

  --markerSize: 6px;
  --markerLength: calc(var(--markerSize) + 2 * var(--lineThickness));

  --thumbSize: 10px;
  --thumbLength: calc(var(--thumbSize) + 2 * var(--lineThickness));

  --buttonSize: var(--rangeSize);
  --buttonLineWidth: calc(var(--buttonSize) - (2 * var(--marginButtonLine)));
  --marginTrackLine: calc(var(--buttonLineWidth) - var(--marginButtonLine));

  opacity: 0.7;
  display: flex;
  height: var(--rangeSize);
  touch-action: none;

  .track {
    position: relative;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .horizontalLine {
      width: calc(100% - 2 * var(--marginTrackLine));
    }

    .trackItems {
      position: absolute;
      display: flex;
      align-items: center;
      width: calc(100% - 2 * var(--marginTrackLine) - var(--thumbLength));

      .marker-wrapper {
        display: flex;
        position: absolute;
        left: calc(var(--markerPosition) - var(--markerLength));
        justify-content: center;
        align-items: center;
        width: calc(var(--markerLength) * 2);
        height: calc(var(--markerLength) * 2);
        cursor: pointer;
      }

      .marker {
        width: var(--markerSize);
        height: var(--markerSize);
        border: var(--lineThickness) solid var(--color);
        border-radius: 50%;
        background-color: var(--color);
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        cursor: pointer;
      }

      .thumb {
        position: absolute;
        left: calc(var(--thumbPosition) - var(--thumbLength) / 2);
        width: var(--thumbSize);
        height: var(--thumbSize);
        border: var(--lineThickness) solid var(--color);
        border-radius: 50%;
        background-color: var(--color_bg_white);
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        cursor: pointer;
      }
    }
  }

  .button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--buttonSize);
    width: var(--buttonSize);
    cursor: pointer;
  }

  .horizontalLine {
    width: calc(100% - 2 * var(--marginButtonLine));
    height: var(--lineThickness);
    background-color: var(--color);
  }

  .verticalLine {
    position: absolute;
    width: var(--lineThickness);
    height: calc(100% - 2 * var(--marginButtonLine));
    background-color: var(--color);
  }
}
</style>
