export type ResizeCallback = (entry: ResizeObserverEntry) => void;

export type Unobserver = () => void;

class ResizeHelper {
  private _resizeObserver: ResizeObserver = null;
  private _handlers: WeakMap<HTMLElement, ResizeCallback> = new WeakMap();

  constructor() {
    this._resizeObserver = new ResizeObserver(this._handleResizeEvent.bind(this));
  }

  /**
   * Make sure to call unobserve before vue component is destroyed
   * @param element to observe
   * @param callback called when the element is resized
   */
  observe(element: HTMLElement, callback: ResizeCallback): Unobserver {
    this._resizeObserver.observe(element);
    this._handlers.set(element, callback);

    return () => this.unobserve(element);
  }

  /**
   *
   * @param element
   */
  unobserve(element: HTMLElement): void {
    this._resizeObserver.unobserve(element);
    this._handlers.delete(element);
  }

  private _handleResizeEvent(entries: ResizeObserverEntry[]): void {
    for (const entry of entries) {
      const handler = this._handlers.get(entry.target as HTMLElement);
      if (handler) {
        handler(entry);
      }
    }
  }
}

export const resizeHelper = new ResizeHelper();
