import { IBackgroundChangeService } from "./background-change-service.interface";
import { DefaultStyles } from "@/common/styles/default-styles";

const design7BackgroundImage = "design7-background-image";
const design7BackgroundColor = "--design7BackgroundColor";

export class BackgroundChangeService implements IBackgroundChangeService {
  constructor() {
    this.setDefaultBackgroundColor();
  }

  beforeDestroy() {
    this.setDefaultBackgroundColor();
  }

  setDefaultBackgroundColor(): void {
    const design7 = this._getDesign7();
    if (design7) {
      design7.classList.remove(design7BackgroundImage);
    }
  }

  setBackgroundColor(color: string): void {
    if (color === DefaultStyles.colorConstants.neutralTextHEX) {
      this.setDefaultBackgroundColor();
      return;
    }

    const design7 = this._getDesign7();
    if (design7) {
      design7.style.setProperty(design7BackgroundColor, color);
      design7.classList.add(design7BackgroundImage);
    }
  }

  private _getDesign7(): HTMLElement {
    const elements = document.getElementsByClassName("design7");
    if (elements.length > 0) {
      return elements[0] as HTMLElement;
    }
    return null;
  }
}
