import { TileError } from "../errors";
import {
  IPortalTileDto,
  IPortalTilePageDto,
  PortalTilePageType,
} from "@/common/service-clients/generated-clients";

export interface ITileDescriptorFm {
  tileDto: IPortalTileDto;
  tilePageDto: IPortalTilePageDto;
  tilePageType: PortalTilePageType | null;

  error: TileError;
}
export class TileDescriptorFm implements ITileDescriptorFm {
  tileDto: IPortalTileDto = null;
  tilePageDto: IPortalTilePageDto = null;
  tilePageType: PortalTilePageType = null;
  error: TileError = null;

  static fromJS(td: ITileDescriptorFm): TileDescriptorFm {
    const result = new TileDescriptorFm();
    result.error = td.error;
    result.tilePageType = td.tilePageType;
    result.tileDto = td.tileDto;
    result.tilePageDto = td.tilePageDto;

    return result;
  }
}
