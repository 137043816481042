import { watch, WatchStopHandle } from "vue";
import debounce from "lodash/debounce";
import { IViewStateDto } from "@/common/service-clients/generated-clients";
import { ITypedEvent } from "@/common/events/ityped-event";
import { TypedEvent } from "@/common/events/typed-event";
import { watcherDebounceTime_ms } from "@/services/view-state-service/vs-common";
import { VsRoot } from "@/services/view-state-service/contract/vs-root";
import { IVmWatchList } from "@/services/view-state-service/contract/vm-watch-list.interface";
import { IVsGenerator } from "@/services/view-state-service/contract/vs-generator.interface";

export class VmWatcher {
  // Events
  private readonly _vsChanged = new TypedEvent<IViewStateDto>();

  // DebouncedHandlers
  private readonly _onVmChangeDebounced: (value: unknown, oldValue: unknown) => void;

  // relevant state
  private readonly _viewModel: VsRoot;
  private _lastViewState: IViewStateDto;

  // Vue WatchStopHandles
  private _viewModelChangesWsHandle: WatchStopHandle | undefined;

  // helper
  private _vmWatchList: IVmWatchList = null;
  private _vsGenerator: IVsGenerator = null;

  constructor(viewModel: VsRoot, vmWatchList: IVmWatchList, vsGenerator: IVsGenerator) {
    this._viewModel = viewModel;
    this._vmWatchList = vmWatchList;
    this._vsGenerator = vsGenerator;

    this._onVmChangeDebounced = debounce(
      (value: unknown, oldValue: unknown) => this._onVmChanged(value, oldValue),
      watcherDebounceTime_ms
    );
  }

  updateLastViewState(vs: IViewStateDto): void {
    this._lastViewState = vs;
  }

  startWatching(): void {
    this._viewModelChangesWsHandle?.();
    const watchList = this._vmWatchList.get(this._viewModel);
    this._viewModelChangesWsHandle = watch(watchList, this._onVmChangeDebounced);
  }

  stopWatching(): void {
    this._viewModelChangesWsHandle?.();
  }

  /**
   * Finds change to previous VS only by vue watch-change-registration
   * in the underlying VM. There is no explicit comparison!
   *
   * NOTE: Also check current implementation
   */
  get vsChangedEvent(): ITypedEvent<IViewStateDto> {
    return this._vsChanged;
  }

  generateVs(): IViewStateDto {
    const newViewState = this._vsGenerator.getViewState(
      this._viewModel,
      this._lastViewState
    );

    return newViewState;
  }

  dispose(): void {
    this.stopWatching();
  }

  // for 'value' && 'oldValue' debugging
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private _onVmChanged(value: unknown, oldValue: unknown): void {
    const newViewState = this.generateVs();

    this._lastViewState = newViewState;

    this._vsChanged.emit(newViewState);
  }
}
