<script lang="ts">
import ChatFrame from "@/features/chat/chat-frame.vue";
import Swiper from "@/common/components/swiper.vue";
import PortalPage from "./page.vue";
import PortalHeader from "./header.vue";
import DropZone from "@/features/dnd-upload/drop-zone.vue";
import Controls from "./helper-components/controls.vue";
import { useBackendDependencies } from "./backend-wrapper/backend-dependencies.cpsl";

import { Application } from "@bissantz/smartforms";
import { IDisposable } from "@/common/disposable.interface";
import { SwiperVm } from "@/common/components/swiper-vm";
import { PortalVm } from "./view-models/portal-vm";
import { appResources } from "@/app-resources";
import { IStatusBarService } from "@/services/status-bar-service.interface";
import { IZoomService } from "@/services/zoom-service.interface";
import { resizeHelper, Unobserver } from "@/common/resize-helper";
import { UploadMessageReceiver } from "@/features/dnd-upload/upload-message-receiver";
import { useRegisterVsSyncer as useRegisterVsSyncer } from "./view-states/register-vs-syncer.cpsl";

import {
  computed,
  defineComponent,
  inject,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  provide,
  reactive,
  ref,
  watch,
} from "vue";
import { VsScopeState } from "@/services/view-state-service/vs-scope-state";
import { ProtectedPortalSelectionVm } from "./view-models/protected-portal-selection";
import { IBackgroundChangeService } from "@/services/background-change-service.interface";
import { ClickHelper } from "@/common/events/click-helper";
import { backgroundClicked } from "@/common/validation-helper";
import { TileVm } from "./tile/tile-vm";

class PortalState {
  textResources = appResources.portalTexts;
  dndTextResoucres = appResources.dndUploadTexts;

  portalVm: PortalVm = null;

  pageSwiperVm: SwiperVm = null;

  eventDisposer: IDisposable[] = [];
  unobserveCallback: Unobserver = null;

  uploadMessageReceiver = new UploadMessageReceiver();

  documentClickHelper = new ClickHelper();
}

export default defineComponent({
  components: {
    ChatFrame,
    Controls,
    PortalPage,
    PortalHeader,
    Swiper,
    DropZone,
  },

  setup() {
    // injections:
    const backgroundChangeService: IBackgroundChangeService = inject(
      "backgroundChangeService"
    );
    const statusBarService = inject<IStatusBarService>("statusBarService");
    const zoomService = inject<IZoomService>("zoomService");

    const portalSelection = inject("portalSelection") as ProtectedPortalSelectionVm;
    portalSelection.resetPageSelection();
    const application = inject("application") as Application;

    const facades = useBackendDependencies();

    // State:
    const state = reactive(new PortalState()) as PortalState;
    state.portalVm = reactive(
      new PortalVm(facades.portalFacade, portalSelection)
    ) as PortalVm;
    state.documentClickHelper.setOnLeftClickAction(() =>
      portalSelection.resetTileSelection()
    );

    const vsScopeState = new VsScopeState();
    provide("vsScopeState", vsScopeState);

    // DOM/Comp. refs:
    const ref_portalComponent = ref<HTMLDivElement>();

    useRegisterVsSyncer(state.portalVm, facades.portalViewStateFacade, vsScopeState);

    // lifecycle hooks
    // ----------------------
    onBeforeMount(async () => {
      const portalId = portalSelection.portalId;

      const isPortalIdValid = await facades.portalFacade.isPortalIdValid(portalId);

      if (!isPortalIdValid) {
        await handleInvalidPortalId(portalId);
      } else {
        await initPortal(portalId);
      }
    });

    onMounted(() => {
      initZoomService();
      document.addEventListener("mousedown", onMouseDown);
      document.addEventListener("mouseup", onMouseUp);
    });

    onBeforeUnmount(() => {
      document.removeEventListener("mousedown", onMouseDown);
      document.removeEventListener("mouseup", onMouseUp);
      state.eventDisposer.map((disp) => disp.dispose());
      state.unobserveCallback?.();
    });

    // computeds:
    // ----------------------
    const shouldShowLoadingMsg = computed<boolean>(() => {
      if (state.portalVm.isEmptyPortal) {
        return false;
      }

      const selectedPage = state.portalVm.selectedPage;

      return selectedPage?.isLoading;
    });

    const loadingMessage = computed<string | undefined>(() => {
      if (shouldShowLoadingMsg.value) {
        return state.textResources.loadingPortal;
      }

      if (state.uploadMessageReceiver.hasMessage) {
        return state.uploadMessageReceiver.message;
      }

      return undefined;
    });

    const errorMessage = computed<string>(() => {
      if (state.portalVm.error === "noPages") {
        return state.textResources.errorNoPages;
      } else if (state.portalVm.error === "noPortal") {
        return state.textResources.errorNoPortal;
      }

      return null;
    });

    const pageTitles = computed<string[]>(() => {
      const hasNoPages = !state.portalVm.portalPages.length;

      if (!state.portalVm.arePagesLoaded) {
        return [];
      } else if (hasNoPages || state.portalVm.isEmptyPortal) {
        const replacementTitle = state.portalVm.title ?? state.textResources.header;
        return [replacementTitle];
      }

      if (state.portalVm.isVsSyncReady) {
        return state.portalVm.portalPages.map((portalPage) => portalPage.title);
      } else {
        return [];
      }
    });

    // Funtions:
    // -----------

    function onMouseDown(event: MouseEvent): void {
      if (backgroundClicked(event)) {
        state.documentClickHelper.mouseDown(event);
      }
    }

    function onMouseUp(event: MouseEvent): void {
      if (backgroundClicked(event)) {
        state.documentClickHelper.mouseUp(event);
      }
    }

    async function initPortal(portalId: string): Promise<void> {
      state.portalVm.controlVm.hasEditPermission =
        await facades.portalFacade.hasEditPermission(portalId);

      // register event listener
      state.eventDisposer.push(
        statusBarService.activePageChanged.on(onActivePageChanged)
      );

      loadPortal(portalId);
    }

    async function handleInvalidPortalId(originalPortalId: string): Promise<void> {
      const fallbackPortalId = await facades.portalFacade.getFallbackPortalId();
      const isOriginalIdEmpty = !originalPortalId;

      if (fallbackPortalId) {
        portalSelection.portalId = fallbackPortalId;

        application.content.find((c) => c.contentId == "portal").navigate();
      } else if (!isOriginalIdEmpty) {
        portalSelection.portalId = null;

        application.content.find((c) => c.contentId == "portal").navigate();
      } else {
        // TODO: unclear when a portal is normally considered an empty portal?
        //       This flag is not set after page loading
        state.portalVm.isEmptyPortal = true;
        state.portalVm.error = "noPortal";
      }
    }

    function initZoomService() {
      let componentElem = ref_portalComponent.value;

      zoomService.currentPortalWidth = componentElem.getBoundingClientRect().width;

      state.unobserveCallback = resizeHelper.observe(
        componentElem,
        (entry: ResizeObserverEntry) => {
          zoomService.currentPortalWidth = entry.contentRect.width;
        }
      );
    }

    async function loadPortal(portalId: string): Promise<void> {
      await state.portalVm.loadData(portalId);
      state.portalVm.portalSelection.pageId = state.portalVm.selectedPage.id;

      const initialActiveIdx = 0;
      state.pageSwiperVm = new SwiperVm(1, initialActiveIdx, false, false);
    }

    function closeMainMenu(): void {
      application.mainMenuOpen = false;
    }

    // Event Handler Functions:
    // ------------------------
    function onActivePageChanged(index: number): void {
      if (!state.portalVm?.portalPages?.length) {
        return;
      }
      state.pageSwiperVm.swipeTo(index);
      const page = state.portalVm.portalPages[state.pageSwiperVm.activeIndex];
      portalSelection.pageId = page.id;
      state.portalVm.controlVm.activeFullpageTile = page.fullpageTileVm;
    }

    async function reloadPortal(): Promise<void> {
      await application.content.find((c) => c.contentId == "portal").navigate();
    }

    function onPageTitlesChanged(): void {
      const idx = state.portalVm.selectedPageIndex;
      statusBarService.updatePortalPageHints(idx, pageTitles.value);
    }

    const selectionWeatherColor = computed<string>(() => {
      const anyTilePageSelected = state.portalVm.portalSelection.tilePageId !== null;
      let selectionWeather: string = null;

      if (anyTilePageSelected) {
        selectionWeather = state.portalVm.portalSelection.getSelectedWeatherColor(
          state.portalVm
        );
      }

      return selectionWeather;
    });

    function onSelectionWeatherChanged(): void {
      if (selectionWeatherColor.value) {
        backgroundChangeService.setBackgroundColor(selectionWeatherColor.value);
        statusBarService.updateBackgroundColor(selectionWeatherColor.value);
      } else {
        backgroundChangeService.setDefaultBackgroundColor();
        statusBarService.updateBackgroundColor(null);
      }
    }

    function onTileMaximized(fullpageTileVm: TileVm): void {
      state.portalVm.controlVm.activeFullpageTile = fullpageTileVm;
    }

    // Watcher
    // -----------------
    watch(() => application.locale, reloadPortal);
    watch(() => selectionWeatherColor.value, onSelectionWeatherChanged);
    watch(() => pageTitles.value, onPageTitlesChanged);
    watch(() => state.portalVm?.selectedPage?.fullpageTileVm, onTileMaximized, {
      immediate: true,
    });

    return {
      // refs:
      state,
      ref_portalComponent,

      // computed:
      errorMessage,
      loadingMessage,
      pageTitles,
      portalSelection,

      closeMainMenu,
    };
  },
});
</script>

<template>
  <div class="portalComponent" ref="ref_portalComponent">
    <DropZone
      class="drop-area"
      v-bind:canDropFiles="state.portalVm.controlVm.hasEditPermission"
      v-bind:portalId="portalSelection.portalId"
      v-bind:portalPageId="state.portalVm?.selectedPage?.id"
      v-bind:messageReceiver="state.uploadMessageReceiver"
      v-on:pointerdown.native="closeMainMenu"
    >
      <PortalHeader
        v-bind:pageTitles="pageTitles"
        v-bind:loadingMessage="loadingMessage"
        v-bind:pageSwiperVm="state.pageSwiperVm"
      />

      <div class="portalBody">
        <Controls v-bind:controlsVm="state.portalVm.controlVm" />
        <ChatFrame>
          <div v-if="errorMessage" class="errorBox">{{ errorMessage }}</div>
          <Swiper
            v-else-if="state.pageSwiperVm && state.portalVm.isVsSyncReady"
            class="pageSwiper"
            v-bind:swiperVm="state.pageSwiperVm"
            v-bind:itemsVms="state.portalVm.portalPages"
            v-bind:blockSwipe="true"
          >
            <template v-slot:swipeItems="sProps">
              <PortalPage
                v-bind:portalPageVm="sProps.swipeItem"
                v-bind:isShowing="sProps.realIndex === state.pageSwiperVm.activeIndex"
                v-bind:isEditing="state.portalVm.controlVm.isEditing"
                v-on="$listeners"
              />
            </template>
          </Swiper>
        </ChatFrame>
      </div>
    </DropZone>
  </div>
</template>

<style lang="less" scoped>
.portalComponent {
  -webkit-tap-highlight-color: transparent;
  /* prevent DOM elem highlighting on touch on iOS devices*/
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  overflow: hidden;

  // at vw === 480px -> 8px
  // at vw === 1920px -> 16px
  font-size: calc(0.5rem + ((1vw - 4.8px) * 0.5556));

  --height_header: 4.95em;

  .errorBox {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: calc(100% - var(--height_header));
  }

  .drop-area {
    height: 100%;
  }

  .portalBody {
    height: calc(100% - var(--height_header));
  }
}
</style>

<style lang="less">
.portalComponent {
  .pageSwiper {
    height: 100%;

    .swiperSection {
      height: 100%;
    }
  }
}
</style>
