<script lang="ts">
import { defineComponent, computed, PropType, onBeforeMount, reactive, ref } from "vue";
import DashboardValue, {
  DashboardValueEvents,
} from "@/features/dashboard/helper-components/dashboard-value.vue";
import type { CSSProperties } from "vue/types/jsx";
import { ValueVm } from "@/features/dashboard/view-models/value-vm";
import { SharedDashboardStateVm } from "@/features/dashboard/view-models/shared/shared-dashboard-state-vm";
import NavigationCell from "@/common/components/cell-basics/navigation-cell.vue";
import { ClickHelper } from "@/common/events/click-helper";
import { TouchHelper } from "@/common/events/touch-helper";
import { isInRect } from "@/common/events/dom-event-helper";
import { DashboardCommon } from "@/features/dashboard/dashboard-common";
import { HideAndShowHelper } from "@/common/components/hide-and-show-helper";
import { useHelpText } from "@/services/help-text-service/help-text.cpsl";
import { DashboardSelection } from "@/features/dashboard-shared/dashboard-selection";

class TitleValueState {
  clickHelper = new ClickHelper();
  touchHelper = new TouchHelper();
  isInValue = false;
}

export default defineComponent({
  components: { NavigationCell, DashboardValue },
  emits: [DashboardValueEvents.valueRightClicked],
  props: {
    valueVm: { type: Object as PropType<ValueVm>, default: null },
    sharedState: { type: Object as PropType<SharedDashboardStateVm>, required: true },
    dashboardSelection: {
      type: Object as PropType<DashboardSelection>,
      required: true,
    },
    title: { type: String, default: null },
    titlePadding: { type: Number, default: 0 },
    firstValueWidth: { type: Number, required: true },
    isClickEnabled: { type: Boolean, default: false },
    isPercentageMode: { type: Boolean, default: false },
    disableAnimation: { type: Boolean, default: false },
    canSort: { type: Boolean, default: true },
  },
  setup(props, context) {
    const ref_titleValue = ref<HTMLElement | null>(null);
    const state = reactive(new TitleValueState());
    const helpTextCpsl = useHelpText();

    //
    // Life Cycle:
    // --------------------
    onBeforeMount(() => {
      state.clickHelper.touchHelper = state.touchHelper;
      state.clickHelper.setOnLeftClickAction(onLeftClick);
      state.touchHelper.setTapAction(onTap);
      state.clickHelper.setOnRightClickAction(onRightClick);
      state.touchHelper.setDoubleTapAction(onDoubleTap);
    });

    //
    // Computeds:
    // --------------------
    const drillDepthPadding = computed<CSSProperties>(() => {
      const width = props.titlePadding + "px";
      return { width: width, minWidth: width };
    });

    const titleValueStyle = computed<CSSProperties>(() => {
      return {
        "--firstValueWidth": props.firstValueWidth,
      };
    });

    //
    // Functions:
    // --------------------
    function onLeftClick(): void {
      context.emit(DashboardCommon.elementTitleSection_toggleDrill);
    }

    function onTap(): void {
      const navigationCell = ref_titleValue.value;
      const isScrollable = HideAndShowHelper.isScrollable(navigationCell.children[0]);
      if (isScrollable) {
        return;
      }

      context.emit(DashboardCommon.elementTitleSection_toggleDrill);
    }

    function onRightClick(ev: MouseEvent): void {
      processRightClickOutsideValue(ev.clientX, ev.clientY);
    }

    function onDoubleTap(): void {
      const navigationCell = ref_titleValue.value;
      const isScrollable = HideAndShowHelper.isScrollable(navigationCell.children[0]);
      if (!isScrollable) {
        return;
      }

      context.emit(DashboardCommon.elementTitleSection_toggleDrill);
    }

    function processRightClickOutsideValue(x: number, y: number): void {
      if (!isInValue(x, y)) {
        return;
      }

      context.emit(DashboardValueEvents.valueRightClicked, props.valueVm);
    }

    function getTitleRect(): DOMRect {
      const navigationCell = ref_titleValue.value;
      return navigationCell.children[1].children[0].getBoundingClientRect();
    }

    function isInValue(x: number, y: number): boolean {
      if (!props.valueVm) {
        return false;
      }

      const navigationCell = ref_titleValue.value;
      const width = navigationCell.clientWidth - props.firstValueWidth;
      const titleRect = getTitleRect();

      if (titleRect.x + width > x) {
        return false;
      }

      return !isInRect(x, y, titleRect);
    }

    function onMouseMove(ev: MouseEvent): void {
      state.isInValue = isInValue(ev.clientX, ev.clientY);
    }

    function onPointerUp(ev: PointerEvent): void {
      const valueIndex = isInValue(ev.clientX, ev.clientY) ? 0 : -1;
      props.dashboardSelection.setKpiValue(valueIndex);
    }

    //
    // Watcher:
    // --------------------

    return {
      state,
      helpTextCpsl,
      ref_titleValue,
      drillDepthPadding,
      titleValueStyle,
      onMouseMove,
      onPointerUp,
    };
  },
});
</script>

<template>
  <div
    ref="ref_titleValue"
    class="title-value"
    v-bind:style="titleValueStyle"
    v-bind:data-helpText="
      $props.valueVm && state.isInValue
        ? helpTextCpsl.elementValueText(
            !$props.valueVm.isAnyPercentageType,
            $props.canSort
          )
        : helpTextCpsl.elementTitleText($props.canSort)
    "
    v-on:pointerdown="onPointerUp"
  >
    <div class="drill-depth-spacer" v-bind:style="drillDepthPadding" />
    <NavigationCell
      v-bind:displayText="$props.title"
      v-bind:justifyContent="'flex-start'"
      v-bind:showCursor="$props.isClickEnabled"
      v-bind:fontColor="'var(--color_neutralText)'"
      v-bind:disableAnimation="$props.disableAnimation"
      v-on:mousedown.native="state.clickHelper.mouseDown($event)"
      v-on:mouseup.native="state.clickHelper.mouseUp($event)"
      v-on:touchstart.native="state.touchHelper.touchStart($event)"
      v-on:touchmove.native="state.touchHelper.cancelTouch($event)"
      v-on:touchend.native="state.touchHelper.touchEnd($event)"
      v-on:mousemove.native="onMouseMove"
      v-on="$listeners"
    />
    <DashboardValue
      v-if="$props.valueVm"
      v-bind:valueVm="$props.valueVm"
      v-bind:sharedState="$props.sharedState"
      v-bind:alignCenter="true"
      v-bind:disableBrackets="true"
      v-bind:defaultTextColor="'var(--valueTextColor)'"
      v-bind:enableColorAndScaling="true"
      v-bind:isClickEnabled="$props.isClickEnabled"
      v-bind:isElement="true"
      v-bind:isPercentageMode="$props.isPercentageMode"
      v-on:mousemove.native="onMouseMove"
      v-on="$listeners"
    />
  </div>
</template>

<style scoped lang="less">
.title-value {
  display: flex;

  .navigation-cell {
    padding: 0;
    flex-grow: 1;
    flex-shrink: 1;
  }

  .common-scalable-value {
    height: 100%;
    flex-shrink: 0;
    flex-grow: 1;
    max-width: calc(var(--firstValueWidth) * 1px);
  }
}
</style>
