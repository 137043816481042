<script lang="ts">
import { defineComponent, computed } from "vue";
import type { CSSProperties } from "vue/types/jsx";

export default defineComponent({
  props: {
    color: { type: String, default: null },
  },

  setup(props) {
    const colorVars = computed<CSSProperties>(() => {
      return {
        "--main-color": props.color ? props.color : "var(--color_neutralText)",
      };
    });

    return {
      colorVars,
    };
  },
});
</script>

<template>
  <div class="sort-by-value-asc">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 210 210"
      v-bind:style="colorVars"
    >
      <line class="cls-1" x1="119.6" y1="58.4" x2="152.4" y2="58.4" />
      <line class="cls-1" x1="98.9" y1="89.5" x2="152.4" y2="89.5" />
      <line class="cls-1" x1="78.3" y1="120.5" x2="152.4" y2="120.5" />
      <line class="cls-1" x1="57.6" y1="151.6" x2="152.4" y2="151.6" />
    </svg>
  </div>
</template>

<style lang="less" scoped>
.sort-by-value-asc {
  height: 100%;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 100%;
    width: 100%;

    .cls-1 {
      fill: none;
      stroke: var(--main-color);
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 12px;
    }
  }
}
</style>
