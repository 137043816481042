<script lang="ts">
import Swiper from "@/common/components/swiper.vue";
import PagesExpose from "./helper-components/pages-expose.vue";
import LoadingText from "@/common/components/loading-text.vue";
import HideAndShow from "@/common/components/hide-and-show.vue";
import { useHelpText } from "@/services/help-text-service/help-text.cpsl";

import { IMediaQueries } from "@/common/styles/media-queries.interface";
import { IStatusBarService } from "@/services/status-bar-service.interface";
import { SwiperVm } from "@/common/components/swiper-vm";
import { IDisposable } from "@/common/disposable.interface";

import {
  onBeforeUnmount,
  onMounted,
  ref,
  inject,
  computed,
  PropType,
  defineComponent,
  reactive,
  watch,
} from "vue";

class PortalHeaderState {
  showExposee: boolean = false;
  blockTitleClick: boolean = false;
  exposeeTouched: boolean = false;
  titleSwiperVm: SwiperVm = new SwiperVm(1, 0, false);
  eventDisposer: IDisposable[] = [];
}

export default defineComponent({
  components: {
    Swiper,
    PagesExpose,
    LoadingText,
    HideAndShow,
  },

  props: {
    pageTitles: { type: Array as PropType<string[]>, required: true },
    loadingMessage: { type: String as PropType<string | null>, default: null },
    pageSwiperVm: { type: Object as PropType<SwiperVm | null>, default: null },
  },

  setup(props) {
    const mediaQueries: IMediaQueries = inject("mediaQueries");
    const statusBarService: IStatusBarService = inject("statusBarService");
    const helpTextCpsl = useHelpText();

    const state = reactive(new PortalHeaderState()) as PortalHeaderState;

    // DOM/component refs:
    const ref_titleSwiper = ref(null);

    //---vue life cycle hooks
    // Mounted hook should not be async, so that PortalPage is waiting for this component to be mounted before
    onMounted(() => {
      if (props.pageSwiperVm?.activeIndex) {
        state.titleSwiperVm.swipeTo(props.pageSwiperVm.activeIndex, false);
      }
      state.titleSwiperVm.jumpToOtherEndEnabled = false;

      state.eventDisposer.push(mediaQueries.throttledBrowserResized.on(closeExposee));
      document.addEventListener("click", closeExposee);
    });

    onBeforeUnmount(() => {
      state.eventDisposer.map((disp) => disp.dispose());
      document.removeEventListener("click", closeExposee);
    });

    //---end vue life cycle hooks

    const hasMultiplePages = computed(() => {
      return props.pageTitles.length > 1;
    });

    watch(
      () => props.pageSwiperVm?.activeIndex,
      (newVal, oldVal) => {
        if (newVal === oldVal) return;

        state.titleSwiperVm.swipeTo(newVal, false);
        state.showExposee = false;
      }
    );

    function onTitleSwiperStarted(): void {
      state.blockTitleClick = true;
    }

    function onTitleSwiperStopped(): void {
      setTimeout(() => {
        state.blockTitleClick = false;
      }, 100);
    }

    function onTitleSwiperFinished(): void {
      if (!props.pageTitles.length) {
        return;
      }

      statusBarService.updatePortalPageHints(state.titleSwiperVm.activeIndex);
    }

    function onTitleSwiperClick(ev: MouseEvent) {
      if (
        state.blockTitleClick ||
        state.titleSwiperVm.isMoving ||
        state.titleSwiperVm.isSwiping ||
        props.pageSwiperVm?.isMoving ||
        props.pageSwiperVm?.isSwiping
      ) {
        return;
      }
      //TODO: check type of titleSwiper, be more specific in type declaration
      const pageSwiperElem = ref_titleSwiper.value.$el as HTMLElement;
      const rect = pageSwiperElem.getBoundingClientRect();
      const xPos = ev.x - rect.x;

      const isLeft = xPos < rect.width / 2;
      let activeIdx = null;
      if (isLeft) {
        activeIdx = Math.max(state.titleSwiperVm.activeIndex - 1, 0);
      } else {
        activeIdx = Math.min(
          state.titleSwiperVm.activeIndex + 1,
          props.pageTitles.length - 1
        );
      }

      statusBarService.updatePortalPageHints(activeIdx);
    }

    function closeExposee(): void {
      if (!state.showExposee) return;

      state.showExposee = false;
    }

    function toggleExposee(ev: PointerEvent) {
      if (
        !hasMultiplePages.value ||
        mediaQueries.isExactlyPhone ||
        state.titleSwiperVm.isMoving ||
        state.titleSwiperVm.isSwiping
      ) {
        state.showExposee = false;
        return;
      }

      state.showExposee = !state.showExposee;

      if (state.showExposee && ev.pointerType === "touch") {
        state.exposeeTouched = true;
      }

      if (!state.showExposee) {
        state.exposeeTouched = false;
      }
    }

    function onSelectViaExposee(index: number): void {
      state.showExposee = false;

      statusBarService.updatePortalPageHints(index);
    }

    return {
      state,
      ref_titleSwiper,
      hasMultiplePages,
      helpTextCpsl,
      toggleExposee,
      onSelectViaExposee,
      onTitleSwiperStarted,
      onTitleSwiperStopped,
      onTitleSwiperFinished,
      onTitleSwiperClick,
    };
  },
});
</script>

<template>
  <div class="portalHeaderComponent" v-on:touchmove.prevent="() => {}">
    <PagesExpose
      v-if="state.showExposee"
      v-bind:texts="$props.pageTitles"
      v-bind:selectedIndex="state.titleSwiperVm.activeIndex"
      v-bind:openedByTouch="state.exposeeTouched"
      v-on:click.native.stop="toggleExposee"
      v-on:portal_pageIndexSelected="onSelectViaExposee"
    />
    <Swiper
      v-else-if="$props.pageTitles && $props.pageTitles.length"
      ref="ref_titleSwiper"
      v-bind:swiperVm="state.titleSwiperVm"
      v-bind:itemsVms="pageTitles"
      v-on:swiper-moved="onTitleSwiperStarted"
      v-on:swiper-stopped="onTitleSwiperStopped"
      v-on:swiper-finished="onTitleSwiperFinished"
      v-on:click.native.stop="onTitleSwiperClick"
    >
      <template v-slot:swipeItems="sProps">
        <div
          class="portalPageTitle"
          v-bind:data-helpText="helpTextCpsl.switchPortalPagesText(hasMultiplePages)"
        >
          <div class="titleFrame">
            <HideAndShow v-on:click.native.stop="toggleExposee"
              ><strong
                v-bind:class="{ clickable: hasMultiplePages }"
                v-bind:data-helpText="
                  helpTextCpsl.showAvailablePortalPagesText(hasMultiplePages)
                "
                >{{ sProps.swipeItem }}</strong
              >
            </HideAndShow>
          </div>
        </div>
      </template>
    </Swiper>
    <LoadingText
      v-if="!!$props.loadingMessage"
      class="portalLoadingText"
      v-bind:loadingText="$props.loadingMessage"
    />
  </div>
</template>

<style lang="less">
@import "../../common/styles/media-queries.less";

.portalHeaderComponent {
  position: relative;
  height: var(--height_header);
  min-height: 40px;
  color: var(--color_Weather3);
  flex-grow: 0;
  flex-shrink: 0;
  background: var(--color_bg_white);
  display: flex;
  align-items: center;

  .media_isAtleastTablet({
    margin-bottom: 0;
    display: block;
  });

  .portalPageTitle {
    display: flex;
    justify-content: center;
    font-size: 2.5em;

    .titleFrame {
      width: 80%;

      div > div > div > strong.clickable {
        cursor: pointer;
      }
    }

    .media_isAtleastTablet({
      padding: 0;
    });
    text-align: center;
  }

  .portalLoadingText {
    position: absolute;
    bottom: 2px;
    padding-left: 0;
  }
}
</style>
