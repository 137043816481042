import { IMenuNodeLoader } from "@/features/main-menu/application-menu/helper-components/menus/menu-node-loader.interface";
import { MenuNodeVm } from "@/features/main-menu/application-menu/helper-components/menus/menu-node-vm";
import { FailedReason } from "@/common/results/failed-reason";
import { ValueResult } from "@/common/results/value-result";
import { IApplicationFacade } from "../../backend-wrapper/application-facade.interface";
import { ApplicationGroup } from "../../backend-wrapper/dto-wrappers";
import { AppEntry } from "../../helper-components/menus/app-entry";

export class ApplicationGroupLoader implements IMenuNodeLoader {
  private _allApplicationGroups: ApplicationGroup[] = null;
  private _allAppEntries: AppEntry[] = null;

  constructor(
    private readonly _applicationFacade: IApplicationFacade,
    private readonly _idRootNode: string
  ) {}

  clearCache(): void {
    this._allApplicationGroups = null;
    this._allAppEntries = null;
  }

  async loadAsync(id: string): Promise<ValueResult<MenuNodeVm[], FailedReason>> {
    if (id === this._idRootNode) {
      id = null;
    }

    const applicationGroups = await this._getApplicationGroupsAsync(id);

    if (applicationGroups.succeeded === false) {
      return ValueResult.createFromFailedReason(applicationGroups.failedReason);
    }

    const appEntries = await this._getApplicationsAsync(id);

    if (appEntries.succeeded === false) {
      return ValueResult.createFromFailedReason(appEntries.failedReason);
    }

    const nodes: MenuNodeVm[] = [];

    for (const applicationGroup of this._orderByName(
      applicationGroups.value,
      (applicationGroup) => applicationGroup.name
    )) {
      if (this._containApplication(applicationGroup.id)) {
        const node = new MenuNodeVm(applicationGroup.id, applicationGroup.name, this);
        nodes.push(node);
      }
    }

    const orderedApps = this._orderByName(
      appEntries.value.map((value) => value.application),
      (application) => application.name
    );

    for (let index = 0; index < orderedApps.length; index++) {
      const app = orderedApps[index];
      const node = new MenuNodeVm(app.id, app.name);
      node.value = appEntries.value[index];
      nodes.push(node);
    }

    return ValueResult.createFromValue(nodes);
  }

  private _orderByName<T>(instances: T[], retrieveName: (T) => string): T[] {
    return instances.sort((a, b) => this._compareByName(a, b, retrieveName));
  }

  private _compareByName<T>(left: T, right: T, retrieveName: (T) => string): number {
    const leftName = retrieveName(left).toUpperCase();
    const rightName = retrieveName(right).toUpperCase();

    if (leftName < rightName) {
      return -1;
    }
    if (leftName > rightName) {
      return 1;
    }

    // names must be equal
    return 0;
  }

  private _containApplication(applicationGroupId: string): boolean {
    if (this._allApplicationGroups === null) {
      return false;
    }

    if (this._allAppEntries === null) {
      return false;
    }

    const applicationIndex = this._allAppEntries.findIndex(
      (appEntry) => appEntry.application.applicationGroupId === applicationGroupId
    );

    if (applicationIndex >= 0) {
      return true;
    }

    const childApplicationGroups = this._allApplicationGroups.filter(
      (applicationGroup) =>
        applicationGroup.parentApplicationGroupId === applicationGroupId
    );

    for (const applicationGroup of childApplicationGroups) {
      if (this._containApplication(applicationGroup.id) === true) {
        return true;
      }
    }

    return false;
  }

  private async _getApplicationGroupsAsync(
    parentGroupId: string
  ): Promise<ValueResult<ApplicationGroup[], FailedReason>> {
    if (this._allApplicationGroups === null) {
      const applicationGroups =
        await this._applicationFacade.getAllApplicationGroupsAsync();

      if (applicationGroups.succeeded === false) {
        return applicationGroups;
      }

      this._allApplicationGroups = applicationGroups.value;
    }

    return this._filter(
      this._allApplicationGroups,
      (applicationGroup) => applicationGroup.parentApplicationGroupId === parentGroupId
    );
  }

  private async _getApplicationsAsync(
    applicationGroupId: string
  ): Promise<ValueResult<AppEntry[], FailedReason>> {
    if (this._allAppEntries === null) {
      const applications = await this._applicationFacade.getAllApplicationsAsync();

      if (applications.succeeded === false) {
        return applications;
      }

      this._allAppEntries = applications.value;
    }

    return this._filter(
      this._allAppEntries,
      (appEntry) => appEntry.application.applicationGroupId === applicationGroupId
    );
  }

  private _filter<T>(
    instances: T[],
    predicate: (instance: T) => boolean
  ): ValueResult<T[], FailedReason> {
    const filteredInstances = instances.filter(
      (instance) => predicate(instance) === true
    );

    return ValueResult.createFromValue(filteredInstances);
  }
}
