import { FailedReason } from "@/common/results/failed-reason";
import { HierarchyLevel } from "../../../../backend-wrapper/dto-wrappers";
import { ITreeNodeLoader } from "../trees/tree-node-loader.interface";
import { TreeNodeVm } from "../trees/tree-node-vm";
import { DimensionRetriever } from "./dimension-retriever";
import { ValueResult } from "@/common/results/value-result";

export class ElementNodeLoader implements ITreeNodeLoader {
  private readonly _dimensionRetriever: DimensionRetriever;
  private readonly _level: HierarchyLevel;
  private readonly _publishedApplicationId: string;

  constructor(
    dimensionRetriever: DimensionRetriever,
    level: HierarchyLevel,
    publishedApplicationId: string
  ) {
    this._dimensionRetriever = dimensionRetriever;
    this._level = level;
    this._publishedApplicationId = publishedApplicationId;
  }

  async loadAsync(_id: string): Promise<ValueResult<TreeNodeVm[], FailedReason>> {
    const loadElementsResult =
      await this._dimensionRetriever.loadHierarchyLevelElementsAsync(
        this._level,
        this._publishedApplicationId
      );

    if (loadElementsResult.succeeded === false) {
      return ValueResult.createFromError(loadElementsResult.failedReason);
    }

    const result = [];

    for (const element of loadElementsResult.value.elements) {
      const treeItem = new TreeNodeVm(element.name, element.caption);
      treeItem.value = element;

      result.push(treeItem);
    }

    return ValueResult.createFromValue(result);
  }
}
