<script lang="ts">
import {
  defineComponent,
  PropType,
  inject,
  computed,
  ref,
  reactive,
  onBeforeMount,
  watch,
} from "vue";
import { SwiperVm } from "@/common/components/swiper-vm";
import Swiper from "@/common/components/swiper.vue";
import NavigationCell from "@/common/components/cell-basics/navigation-cell.vue";
import {
  isMouseLeftHover,
  getNumberPxsOutOfCenter,
  isMouseRightHover,
} from "@/common/events/dom-event-helper";
import { ClickHelper } from "@/common/events/click-helper";
import { TouchHelper } from "@/common/events/touch-helper";
import { IMediaQueries } from "@/common/styles/media-queries.interface";
import type { CSSProperties } from "vue/types/jsx";

export class SwipeableNavigationCell {
  static navigationCellClicked = "navigationCellClicked";
  static navigationCellBeforeClick = "navigationCellBeforeClick";
}

class SwipeableNavigationCellState {
  clickHelper: ClickHelper = null;
  touchHelper: TouchHelper = null;
  isMouseLeftHover = false;
  isMouseRightHover = false;
}

type PositionType = "left" | "right" | "none";

export default defineComponent({
  components: {
    NavigationCell,
    Swiper,
  },
  emits: [SwipeableNavigationCell.navigationCellClicked],
  props: {
    swiperVm: { type: Object as PropType<SwiperVm>, required: true },
    swipeItems: { type: Array as PropType<string[]>, required: true },
    zoomFactor: { type: Number, default: 1 },
    justifyContent: { type: String, default: "flex-end" },
    isRightClickEnabled: { type: Boolean, default: true },
    isLeftClickEnabled: { type: Boolean, default: false },
    showArrows: { type: Boolean, default: true },
    isClickEnabled: { type: Boolean, default: true },
    animateSwipe: { type: Boolean, default: true },
    maxFontSize: { type: String, default: null },
    fontSize: { type: String, default: "12px" },
    fontColor: { type: String, default: "var(--color_bg_white)" },
    applyFontOpacity: { type: Boolean, default: false },
    separatorColor: { type: String, default: "var(--color_bg_white)" },
    navigationCellStyle: { type: Object as PropType<CSSProperties>, default: null },
  },
  setup(props, context) {
    const ref_swipeableNavigationCell = ref(null);
    const mediaQueries: IMediaQueries = inject("mediaQueries");
    const state = reactive(
      new SwipeableNavigationCellState()
    ) as SwipeableNavigationCellState;

    //
    // Life Cycle:
    // --------------------
    onBeforeMount(() => {
      state.clickHelper = new ClickHelper();
      state.touchHelper = new TouchHelper();
      state.clickHelper.setOnLeftClickAction(onClick);
      state.touchHelper.setTapAction(onTap);
    });

    //
    // Computeds:
    // --------------------
    const swipeableNavigationCellStyle = computed(() => {
      return { "--separatorColor": props.separatorColor };
    });

    const isRightClickable = computed<boolean>(() => {
      return (
        props.isClickEnabled &&
        props.isRightClickEnabled &&
        props.swiperVm.canGoRight &&
        !props.swiperVm.isSwiping &&
        !props.swiperVm.isMoving
      );
    });

    const isLeftClickable = computed<boolean>(() => {
      return (
        props.isClickEnabled &&
        props.isLeftClickEnabled &&
        props.swiperVm.canGoLeft &&
        !props.swiperVm.isSwiping &&
        !props.swiperVm.isMoving
      );
    });

    const showCursor = computed<boolean>(() => {
      return (
        !mediaQueries.isTouch &&
        ((isLeftClickable.value && state.isMouseLeftHover) ||
          (isRightClickable.value && state.isMouseRightHover) ||
          props.isClickEnabled)
      );
    });

    const showItemSeparator = computed<boolean>(() => {
      if (props.swiperVm.isSwiping) {
        return true;
      }

      const scrolledPixel = Math.abs(props.swiperVm.scrolledPixel);
      const isScrolled = 0 < scrolledPixel && scrolledPixel < props.swiperVm.itemWidth;
      return isScrolled;
    });

    //
    // Functions:
    // --------------------
    function onClick(): void {
      context.emit(SwipeableNavigationCell.navigationCellBeforeClick);
      if (state.isMouseLeftHover) {
        props.swiperVm.swipeLeft(props.animateSwipe);
      } else if (state.isMouseRightHover) {
        props.swiperVm.swipeRight(props.animateSwipe);
      }

      context.emit(SwipeableNavigationCell.navigationCellClicked);
    }

    function onTap(event: TouchEvent): void {
      context.emit(SwipeableNavigationCell.navigationCellBeforeClick);
      const position = getPosition(event.changedTouches[0].clientX);
      if (position === "left") {
        props.swiperVm.swipeLeft(props.animateSwipe);
      } else if (position === "right") {
        props.swiperVm.swipeRight(props.animateSwipe);
      }

      context.emit(SwipeableNavigationCell.navigationCellClicked);
    }

    function onMouseMove(event: MouseEvent): void {
      if (
        !props.isClickEnabled ||
        props.swiperVm.isSwiping ||
        props.swiperVm.scrolledPixel !== 0
      ) {
        event.stopPropagation();
        return;
      }

      const position = getPosition(event.clientX);
      if (position === "left") {
        state.isMouseRightHover = false;
        state.isMouseLeftHover = true;
      } else if (position === "right") {
        state.isMouseLeftHover = false;
        state.isMouseRightHover = true;
      }
    }

    function getPosition(positionX: number): PositionType {
      if (props.isRightClickEnabled && props.isLeftClickEnabled) {
        return getPositionForTwoClickOptions(positionX);
      } else if (props.isRightClickEnabled) {
        return "right";
      } else if (props.isLeftClickEnabled) {
        return "left";
      }
    }

    function getPositionForTwoClickOptions(positionX: number): PositionType {
      const navigationCell = ref_swipeableNavigationCell.value as HTMLDivElement;
      const minOffset_px = 5;
      if (isMouseLeftHover(positionX, navigationCell)) {
        if (getNumberPxsOutOfCenter(positionX, navigationCell) < minOffset_px) {
          return "none";
        }

        return "left";
      } else if (isMouseRightHover(positionX, navigationCell)) {
        if (getNumberPxsOutOfCenter(positionX, navigationCell) < minOffset_px) {
          return "none";
        }

        return "right";
      }

      if (positionX === -1) {
        return "left";
      } else if (positionX === 1) {
        return "right";
      }
    }

    function onMouseLeave(): void {
      state.isMouseRightHover = false;
      state.isMouseLeftHover = false;
    }

    function showLeftArrow(index: number): boolean {
      if (mediaQueries.isTouch || (!props.swiperVm.infiniteLoop && index === 0)) {
        return false;
      }

      if (!props.showArrows) {
        return false;
      }

      if (props.swiperVm.isSwiping && state.isMouseLeftHover) {
        return true;
      }

      return (
        state.isMouseLeftHover &&
        props.isClickEnabled &&
        props.isLeftClickEnabled &&
        props.swiperVm.canGoLeft
      );
    }

    function showRightArrow(index: number): boolean {
      if (
        mediaQueries.isTouch ||
        (!props.swiperVm.infiniteLoop && index === props.swiperVm.numberOfItems - 1)
      ) {
        return false;
      }

      if (!props.showArrows) {
        return false;
      }

      if (props.swiperVm.isSwiping && state.isMouseRightHover) {
        return true;
      }

      return (
        state.isMouseRightHover &&
        props.isClickEnabled &&
        props.isRightClickEnabled &&
        props.swiperVm.canGoRight
      );
    }

    //
    // Watcher:
    // --------------------
    watch(() => props.isClickEnabled, onMouseLeave);

    return {
      state,
      ref_swipeableNavigationCell,

      swipeableNavigationCellStyle,
      isRightClickable,
      isLeftClickable,
      showCursor,

      showItemSeparator,
      onMouseMove,
      onMouseLeave,
      showLeftArrow,
      showRightArrow,
    };
  },
});
</script>

<template>
  <div
    ref="ref_swipeableNavigationCell"
    class="swipeable-navigation-cell"
    v-bind:style="swipeableNavigationCellStyle"
    v-on:mousemove="onMouseMove"
    v-on:mouseleave="onMouseLeave"
  >
    <Swiper
      v-if="$props.swipeItems.length > 1"
      v-bind:swiperVm="$props.swiperVm"
      v-bind:itemsVms="swipeItems"
      v-bind:zoomFactor="$props.zoomFactor"
      v-bind:blockSwipe="!$props.isClickEnabled"
      v-on:mousedown.native="state.clickHelper.mouseDown($event)"
      v-on:mouseup.native="state.clickHelper.mouseUp($event)"
      v-on:touchstart.native="state.touchHelper.touchStart($event)"
      v-on:touchend.native="state.touchHelper.touchEnd($event)"
      v-on="$listeners"
    >
      <template v-slot:swipeItems="sProps">
        <NavigationCell
          v-bind:class="{ 'item-separator': showItemSeparator }"
          v-bind:displayText="sProps.swipeItem"
          v-bind:justifyContent="$props.justifyContent"
          v-bind:showLeftArrow="showLeftArrow(sProps.index)"
          v-bind:showRightArrow="showRightArrow(sProps.index)"
          v-bind:showCursor="showCursor"
          v-bind:fontSize="$props.fontSize"
          v-bind:maxFontSize="$props.maxFontSize"
          v-bind:fontColor="$props.fontColor"
          v-bind:cellStyle="$props.navigationCellStyle"
          v-bind:applyFontOpacity="
            $props.applyFontOpacity && sProps.index !== sProps.activeIndex
          "
          v-on="$listeners"
        />
      </template>
    </Swiper>
    <NavigationCell
      v-else
      v-bind:displayText="$props.swipeItems.length === 1 ? $props.swipeItems[0] : ''"
      v-bind:justifyContent="$props.justifyContent"
      v-bind:showLeftArrow="false"
      v-bind:showRightArrow="false"
      v-bind:showCursor="showCursor"
      v-bind:fontSize="$props.fontSize"
      v-bind:maxFontSize="$props.maxFontSize"
      v-bind:fontColor="$props.fontColor"
      v-bind:cellStyle="$props.navigationCellStyle"
      v-bind:applyFontOpacity="false"
      v-on="$listeners"
    />
  </div>
</template>

<style scoped lang="less">
.swipeable-navigation-cell {
  display: flex;
  align-items: center;

  .navigation-cell {
    &.item-separator {
      border-right: 1px solid var(--separatorColor);
    }
  }
}
</style>
