<script lang="ts">
import {
  ScalableValue,
  ScalableValueVm,
} from "@/features/dashboard-shared/scalable-value";

import { ValueVm } from "@/features/dashboard/view-models/value-vm";
import { SharedDashboardStateVm } from "@/features/dashboard/view-models/shared/shared-dashboard-state-vm";
import { DashboardCommon } from "@/features/dashboard/dashboard-common";
import { IContentLocaleOption } from "@bissantz/smartforms";
import { appResources } from "@/app-resources";

import { computed, inject, reactive, watch, defineComponent, PropType } from "vue";
import type { CSSProperties } from "vue/types/jsx";

export class DashboardValueEvents {
  static valueLeftClicked = "valueLeftClicked";
  static valueRightClicked = "valueRightClicked";
}

export default defineComponent({
  components: {
    ScalableValue,
  },

  emits: [DashboardValueEvents.valueLeftClicked, DashboardValueEvents.valueRightClicked],

  props: {
    valueVm: { type: Object as PropType<ValueVm>, required: true },
    isElement: { type: Boolean },
    sharedState: {
      type: Object as PropType<SharedDashboardStateVm | null>,
      required: true,
    },
    colorizeBackground: { type: Boolean, required: false, default: true },
    isPercentageMode: { type: Boolean },
    isPercentageModeFont: { type: Boolean },
    alignCenter: { type: Boolean },
    enableColorAndScaling: { type: Boolean, default: true },
    disableBrackets: { type: Boolean },
    defaultTextColor: { type: String, default: null },
    isClickEnabled: { type: Boolean, default: false },
  },

  setup: function (props, context) {
    const application = inject("application") as IContentLocaleOption;

    const state = reactive({
      scalableValueVm: new ScalableValueVm(),
    });

    //
    // Life Cycle:
    // --------------------
    init();

    //
    // Computeds:
    // --------------------
    const dashboardValueStyle = computed<CSSProperties>(() => {
      if (!props.isClickEnabled) return {};

      return {
        cursor: "pointer",
      };
    });

    const fontConfig = computed(() => {
      if (props.isElement) {
        return DashboardCommon.elementConfig;
      }

      return DashboardCommon.kpiConfig;
    });

    const displayValue = computed(() => {
      const isSortedByStructure = props.sharedState.kpiScaleIndex === -1;

      // reactive for changes in 'application.language'
      props.valueVm.formatElementValue(
        application.effectiveContentLocale,
        appResources.valueFormatTexts,
        props.isPercentageMode,
        isSortedByStructure,
        props.sharedState.defaultNumberPresentationMode
      );

      const statusText = props.valueVm.statusText;
      if (statusText) return statusText;

      if (
        state.scalableValueVm.showFormatted ||
        props.sharedState.kpiScaleIndex === -1 ||
        props.isPercentageMode
      ) {
        return props.valueVm.valueFormatted;
      }

      return props.valueVm.valueRaw;
    });

    const displayUnit = computed(() => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const neededForReactivity = displayValue.value;

      if (props.valueVm.statusText || !props.valueVm.unitFormatted) return "";

      const showFmt = state.scalableValueVm.showFormatted;
      const isAbsolute = props.valueVm.valueType === "absolute";

      return showFmt || !isAbsolute || props.isPercentageMode
        ? props.valueVm.unitFormatted
        : "";
    });

    const showColorAndScaling = computed(() => {
      const enabled = props.enableColorAndScaling ?? true;
      const maxRowIdx = props.valueVm.parentRowState
        ? props.valueVm.parentRowState.values.length - 1
        : 0;
      return (
        enabled &&
        props.valueVm.columnIdx === Math.min(maxRowIdx, props.sharedState.kpiScaleIndex)
      );
    });

    //
    // Functions:
    // --------------------
    function init() {
      // configuration properties that don't change
      state.scalableValueVm.showSign = props.valueVm.columnIdx !== 0;
      state.scalableValueVm.alignCenter = props.alignCenter;

      if (props.isElement) {
        state.scalableValueVm.colorizeBackground = false;
      } else {
        state.scalableValueVm.colorizeBackground = props.colorizeBackground;
      }
      state.scalableValueVm.format = props.valueVm.format;
      state.scalableValueVm.fontSize = props.valueVm.oldFontSize;
      state.scalableValueVm.fontSize = props.valueVm.fontSize;

      updateValue();
    }

    function onValueLeftClicked(): void {
      context.emit(DashboardValueEvents.valueLeftClicked, props.valueVm);
    }

    function onValueRightClicked(): void {
      context.emit(DashboardValueEvents.valueRightClicked, props.valueVm);
    }

    function dynamicInit() {
      state.scalableValueVm.showFormatted = props.sharedState.kpiScaleIndex != -1;
    }

    function updateValue(): void {
      props.valueVm.updateActiveValue(props.sharedState.sparklineState);
      state.scalableValueVm.value = props.valueVm.value;
    }

    function setIsPercentageModeFont(): void {
      if (!props.sharedState.isSwipingDeltaValues) {
        state.scalableValueVm.showItalic = props.isPercentageModeFont;
      }
    }

    function onColorizeBackgroundChanged(): void {
      if (!state.scalableValueVm) {
        return;
      }

      state.scalableValueVm.colorizeBackground = props.colorizeBackground;
    }

    //
    // Watcher:
    // --------------------
    watch(() => props.sharedState.kpiScaleIndex, dynamicInit, { immediate: true });

    watch(
      () => props.valueVm.weatherColor,
      () => {
        state.scalableValueVm.weatherColor = props.valueVm.weatherColor;
      },
      { immediate: true }
    );

    watch(
      () => props.valueVm.fontSize,
      () => {
        state.scalableValueVm.fontSize = props.valueVm.fontSize;
      },
      { immediate: true }
    );

    watch(
      () => props.valueVm.excludedFromScaling,
      () => {
        state.scalableValueVm.excludedFromScaling = props.valueVm.excludedFromScaling;
      },
      { immediate: true }
    );

    watch(
      () => displayValue.value,
      () => {
        state.scalableValueVm.displayValue = displayValue.value;
        state.scalableValueVm.valueRaw = props.valueVm.valueRaw;
      },
      { immediate: true }
    );

    watch(
      () => displayUnit.value,
      () => {
        state.scalableValueVm.displayUnit = displayUnit.value;
      },
      { immediate: true }
    );

    watch(
      () => showColorAndScaling.value,
      () => {
        state.scalableValueVm.showColorAndScaling = showColorAndScaling.value;
      },
      { immediate: true }
    );

    watch(() => props.sharedState.isSwipingDeltaValues, setIsPercentageModeFont, {
      immediate: true,
    });

    watch(() => props.colorizeBackground, onColorizeBackgroundChanged);

    return {
      state,
      dashboardValueStyle,
      fontConfig,
      onValueLeftClicked,
      onValueRightClicked,
    };
  },
});
</script>

<template>
  <ScalableValue
    v-bind:style="dashboardValueStyle"
    v-bind:valueVm="state.scalableValueVm"
    v-bind:fontConfig="fontConfig"
    v-bind:disableBrackets="$props.disableBrackets"
    v-bind:defaultTextColor="$props.defaultTextColor"
    v-on:kpiValue_valueLeftClicked="onValueLeftClicked"
    v-on:kpiValue_valueRightClicked="onValueRightClicked"
    v-on="$listeners"
  />
</template>
