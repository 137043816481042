<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import HideAndShow from "@/common/components/hide-and-show.vue";
import XIcon from "@/common/components/icons/x-icon.vue";
import type { CSSProperties } from "vue/types/jsx";
import { TouchHelper } from "@/common/events/touch-helper";
import { HideAndShowHelper } from "@/common/components/hide-and-show-helper";

export default defineComponent({
  components: { XIcon, HideAndShow },
  emits: [],
  props: {
    text: { type: String, required: true },
  },
  setup() {
    const ref_bubbleText = ref(null);
    const showX = ref(false);
    const autoscroll = ref(false);
    const initialTextWidth = ref(0);
    const firstCharWidth = ref(0);
    const touchHelper = new TouchHelper();

    //
    // Life Cycle:
    // --------------------

    //
    // Computeds:
    // --------------------
    const bubbleTextStyle = computed<CSSProperties>(() => {
      return {
        "--text-initial-width": initialTextWidth.value,
        "--first-char-width": firstCharWidth.value,
      };
    });

    //
    // Functions:
    // --------------------
    function onMouseEnter(): void {
      if (touchHelper.isTouch) {
        return;
      }

      const bubbleText = ref_bubbleText.value as HTMLDivElement;
      const firstChar = bubbleText.children[0].children[0] as HTMLDivElement;
      const remainingChars =
        bubbleText.children[0].children.length === 1
          ? firstChar
          : (bubbleText.children[0].children[1] as HTMLDivElement);

      autoscroll.value = HideAndShowHelper.isScrollable(remainingChars);
      initialTextWidth.value = bubbleText.getBoundingClientRect().width;
      firstCharWidth.value = autoscroll.value
        ? 0
        : firstChar.getBoundingClientRect().width;
      showX.value = true;
    }

    function onMouseLeave(): void {
      if (touchHelper.isTouch) {
        return;
      }

      showX.value = false;
    }

    //
    // Watcher:
    // --------------------

    return {
      ref_bubbleText,
      bubbleTextStyle,
      showX,
      autoscroll,
      touchHelper,
      onMouseEnter,
      onMouseLeave,
    };
  },
});
</script>

<template>
  <div
    ref="ref_bubbleText"
    class="bubble-text"
    v-bind:class="{ 'show-x': showX }"
    v-bind:style="bubbleTextStyle"
    v-on:touchstart="touchHelper.touchStart($event)"
    v-on:touchend="touchHelper.touchEnd($event)"
    v-on:mouseenter="onMouseEnter"
    v-on:mouseleave="onMouseLeave"
  >
    <div>
      <HideAndShow v-if="!autoscroll">{{ $props.text[0] }}</HideAndShow>
      <HideAndShow class="bubble-text-inner" v-bind:disableAnimation="!autoscroll">{{
        autoscroll ? $props.text : $props.text.slice(1, $props.text.length)
      }}</HideAndShow>
    </div>
    <XIcon
      v-if="showX"
      v-bind:initialColor="'var(--font-color)'"
      v-bind:strokeWidth="5"
    />
  </div>
</template>

<style scoped lang="less">
.bubble-text {
  --font-color: #999999;
  --x-size: 10px;
  --x-margin-rigth: -2px;
  --x-margin-left: 1px;
  --x-margin-top: -2px;
  --x-total-width: calc(var(--x-size) + var(--x-margin-rigth) + var(--x-margin-left));
  --text-min-width: 22px;
  --text-padding: 11px;
  --text-total-padding: calc(2 * var(--text-padding));
  display: flex;
  flex-shrink: 0;
  min-height: 22px;
  max-height: 22px;
  min-width: calc(
    var(--text-min-width) + var(--x-total-width) + var(--text-total-padding)
  );
  max-width: 40%;
  box-sizing: border-box;
  border-radius: 24px;
  align-content: center;
  margin: 3px;
  padding: 0 var(--text-padding);
  align-items: center;
  justify-content: center;
  background-color: var(--color_bg-gray);
  color: var(--font-color);

  .xIcon {
    display: none;
  }

  &.show-x {
    cursor: pointer;
    justify-content: space-between;
    --font-color: #4d4d4d;

    .bubble-text-inner {
      max-width: calc(
        var(--text-initial-width) * 1px - var(--x-total-width) - var(--text-total-padding) -
          var(--first-char-width) * 1px
      );
    }

    .xIcon {
      display: block;
      margin-right: var(--x-margin-rigth);
      margin-top: var(--x-margin-top);
      margin-left: var(--x-margin-left);
      width: var(--x-size);
      height: var(--x-size);
    }
  }
}
</style>
