import { RequestFailedReason } from "@/common/results/request-failed-reason";
import { Connection, ConnectionGroup } from "../backend-wrapper/dto-wrappers";
import { IConnectionFacade } from "../backend-wrapper/connection-facade.interface";
import { ITransactionFacade } from "@/common/transactions/transaction-facade.interface";
import { ConnectionCreationNotAllowedReason } from "../backend-wrapper/connection-creation-not-allowed-reason";
import { Result } from "@/common/results/result";
import { ConnectionNameUniqueValidator } from "../validators/connection-name-unique-validator";
import { exists } from "@/common/object-helper/null-helper";

type DatasourceMode = "Overview" | "AddNew" | "UseExistent";

export class DatasourceVm {
  get selectedConnection(): Connection {
    return this._selectedConnection;
  }

  set selectedConnection(value: Connection) {
    this._selectedConnection = value;

    this._mode =
      this._selectedConnection?.id === this._addNewConnectionId
        ? "AddNew"
        : "UseExistent";
  }

  get isInAddNewConnectionMode(): boolean {
    return this._mode === "AddNew";
  }

  get isInUseExistingConnectionMode(): boolean {
    return this._mode === "UseExistent";
  }

  get isConnectionGroupMissing(): boolean {
    return this._mode === "AddNew" && this._connectionGroups.length === 0;
  }

  get connectionGroups(): ConnectionGroup[] {
    return this._connectionGroups;
  }

  get connections(): Connection[] {
    return this._connections;
  }

  get userNotAllowedToCreateConnection(): boolean {
    return this._userNotAllowedToCreateConnection;
  }

  get userNotAllowedToCreateConnectionReason(): ConnectionCreationNotAllowedReason {
    return this._userNotAllowedToCreateConnectionReason;
  }

  _selectedConnectionGroup: ConnectionGroup = undefined;

  get selectedConnectionGroup(): ConnectionGroup {
    return this._selectedConnectionGroup;
  }

  set selectedConnectionGroup(value: ConnectionGroup) {
    this._selectedConnectionGroup = value;
    this._nameValidator = new ConnectionNameUniqueValidator(
      this._connectionFacade,
      this._selectedConnectionGroup?.id
    );
  }

  enteredConnectionName: string = null;
  enteredConnectionString: string = null;
  isSendingDataToBackend = false;

  get currentConnectionId(): string {
    return this._createdConnectionId ?? this.selectedConnection.id;
  }

  get allConnectionRetrievalFailed(): boolean {
    return this._allConnectionRetrievalFailed;
  }

  get connectionGroupsRetrievalFailed(): boolean {
    return this._connectionGroupsRetrievalFailed;
  }

  get userIsNotAuthorized(): boolean {
    return this._userIsNotAuthorized;
  }

  get canContinueToNextStep(): boolean {
    return this.canCreateNewConnection || this.isExistentConnectionSelected;
  }

  get canCreateNewConnection(): boolean {
    if (this.isInUseExistingConnectionMode) {
      return false;
    }

    if (!this.selectedConnectionGroup) {
      return false;
    }

    if (!this.enteredConnectionName) {
      return false;
    }

    if (
      this._nameValidator !== null &&
      this._nameValidator.isLastSpecifiedValueUnique === false
    ) {
      return false;
    }

    if (!this.isValidConnectionString(this.enteredConnectionString)) {
      return false;
    }

    if (this.userNotAllowedToCreateConnection) {
      return false;
    }

    return true;
  }

  get isExistentConnectionSelected(): boolean {
    return exists(this.selectedConnection) && this.isInUseExistingConnectionMode;
  }

  get isConnectionSelectionDisabled(): boolean {
    return this.connections.length <= 1 && !!this.selectedConnection;
  }

  get connectionNameValidator(): ConnectionNameUniqueValidator {
    return this._nameValidator;
  }

  private readonly _connectionFacade: IConnectionFacade = null;
  private readonly _transactionFacade: ITransactionFacade = null;
  private readonly _addNewConnectionId: string = "add_new_connection_id";

  private _addNewConnectionText: string = "+";
  private _selectedConnection: Connection = undefined;
  private _allConnectionRetrievalFailed: boolean;
  private _connectionGroupsRetrievalFailed: boolean;
  private _userIsNotAuthorized: boolean;
  private _createdConnectionId: string = null;

  private _mode: DatasourceMode = "Overview";
  private _connections: Connection[] = [];
  private _connectionGroups: ConnectionGroup[] = [];
  private _userNotAllowedToCreateConnection: boolean = false;
  private _userNotAllowedToCreateConnectionReason: ConnectionCreationNotAllowedReason =
    null;
  private _nameValidator = new ConnectionNameUniqueValidator(
    this._connectionFacade,
    this._selectedConnectionGroup?.id
  );

  constructor(
    connectionFacade: IConnectionFacade,
    transactionFacade: ITransactionFacade
  ) {
    this._connectionFacade = connectionFacade;
    this._transactionFacade = transactionFacade;
  }

  async init(addNewConnectionText: string): Promise<void> {
    this._addNewConnectionText = addNewConnectionText;

    this._connections = await this._getAllConnections();

    if (this.allConnectionRetrievalFailed) {
      return;
    }

    this._connections.push(this._buildAddNewConnectionOption());

    this._connectionGroups = await this._getConnectionGroups();

    this._useAddNewModeIfNoConnectionIsAvailable();
  }

  async checkIfUserIsAllowedToCreateNewConnection(): Promise<void> {
    if (!this.isInAddNewConnectionMode) {
      this._userNotAllowedToCreateConnection = false;
      this._userNotAllowedToCreateConnectionReason = null;
      return;
    }

    const result = await this._isUserAllowedToCreateConnection();

    if (!result.succeeded) {
      this._userNotAllowedToCreateConnection = true;
      this._userNotAllowedToCreateConnectionReason = result.failedReason;
    }
  }

  // ToDo: If database types other than OLAP are permitted,
  // validation must take place via the backend, as the validation logic differs for each driver
  isValidConnectionString(value: string): boolean {
    const isValid = new RegExp(
      /^(?=.*(?:Data Source)=)(?=.*(?:Initial Catalog|Database)=)(?:Data Source|Initial Catalog|Database|Password|User Id)=.*$/i
    ).test(value);

    return isValid;
  }

  private async _getAllConnections(): Promise<Connection[]> {
    const result = await this._connectionFacade.getAllConnections();

    if (result.succeeded) {
      return result.value;
    } else {
      this._allConnectionRetrievalFailed = true;

      if (result.failedReason.equals(RequestFailedReason.Unauthorized)) {
        this._userIsNotAuthorized = true;
      }
    }

    return [];
  }

  private async _getConnectionGroups(): Promise<ConnectionGroup[]> {
    const result = await this._connectionFacade.getConnectionGroups();

    if (result.succeeded) {
      return result.value;
    } else {
      this._connectionGroupsRetrievalFailed = true;

      return [];
    }
  }

  async createNewConnectionAsync(): Promise<boolean> {
    this.isSendingDataToBackend = true;
    await this._transactionFacade.beginOrReuseTransaction();
    const result = await this._connectionFacade.createNewConnection(
      this.enteredConnectionName,
      this.selectedConnectionGroup.id,
      this.enteredConnectionString
    );
    this.isSendingDataToBackend = false;
    if (result.succeeded) {
      this._createdConnectionId = result.value;
    }
    return result.succeeded;
  }

  private async _isUserAllowedToCreateConnection(): Promise<
    Result<ConnectionCreationNotAllowedReason>
  > {
    return await this._connectionFacade.isUserAllowedToCreateConnection();
  }

  private _buildAddNewConnectionOption(): Connection {
    return {
      id: this._addNewConnectionId,
      name: this._addNewConnectionText,
    };
  }

  async getCurrentConnectionSetId(): Promise<string> {
    if (!exists(this.currentConnectionId)) {
      return null;
    }

    return await this._connectionFacade.getConnectionSetIdFromConnectionId(
      this.currentConnectionId
    );
  }

  private _useAddNewModeIfNoConnectionIsAvailable(): void {
    if (
      this.connections.length === 1 &&
      this.connections[0].id === this._addNewConnectionId
    ) {
      this._mode = "AddNew";
      this._selectedConnection = this.connections[0];
    }
  }
}
