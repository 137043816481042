import {
  IPortalTilePageDto,
  PortalTilePageDto,
  PortalTilePageType,
} from "@/common/service-clients/generated-clients";
import { ITilePageVm } from "../tile-pages/tile-page-vm.interface";
import { DashboardPageBuilder } from "../tile-pages/dashboard/dashboard-page-builder";
import { UrlPageBuilder } from "../tile-pages/url-report/url-page-builder";

const dashboardPageBuilder = new DashboardPageBuilder();
const urlPageBuilder = new UrlPageBuilder();

export class TilePageFactory {
  getTilePageVm(
    tilePageDto: IPortalTilePageDto,
    pageType: PortalTilePageType
  ): ITilePageVm {
    let result: ITilePageVm = null;

    switch (pageType) {
      case "DashboardPortalTilePage":
        result = dashboardPageBuilder.createVm(tilePageDto);
        break;

      case "UrlPortalTilePage":
        result = urlPageBuilder.createVm(tilePageDto);
        break;
    }

    return result;
  }

  getTilePageDto(tilePageVm: ITilePageVm): PortalTilePageDto {
    let result: PortalTilePageDto = null;

    switch (tilePageVm.type) {
      case "Dashboard":
        result = dashboardPageBuilder.createDto(tilePageVm);
        break;

      case "Url":
        result = urlPageBuilder.createDto(tilePageVm);
        break;
    }

    return result;
  }
}
