<script lang="ts">
import { computed, defineComponent } from "vue";
import RotatableArrowIcon from "../../../helper-components/rotatable-arrow-icon.vue";

export default defineComponent({
  components: {
    RotatableArrowIcon,
  },

  props: {
    isRotated: { type: Boolean, required: true },
    text: { type: String, required: true },
    addAdditionalSpaceToTheTop: { type: Boolean, required: false },
    changeArrowColorOnRotation: { type: Boolean, required: false, default: true },
    changeTextColorOnRotation: { type: Boolean, required: false, default: true },
    changeTextColorOnHovering: { type: Boolean, required: false, default: true },
  },

  setup(props) {
    const isSelected = computed(() => {
      return props.isRotated && props.changeTextColorOnRotation;
    });

    return {
      isSelected: isSelected,
    };
  },
});
</script>

<template>
  <div
    class="application-wizard_rotatable-arrow-with-text selection-wrapper"
    v-bind:class="{ 'distance-top': $props.addAdditionalSpaceToTheTop }"
  >
    <RotatableArrowIcon
      v-bind:isRotated="$props.isRotated"
      v-bind:changeColorOnRotation="$props.changeArrowColorOnRotation"
    />

    <div
      v-bind:class="{
        'is-selected': isSelected,
        'clickable-item': $props.changeTextColorOnHovering,
      }"
    >
      {{ $props.text }}
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "../../../../../common/styles/base-styles.less";
@import "./report-modelling.less";
</style>
