<script lang="ts">
import StructureElementsList from "./structure-elements-list.vue";
import Swiper from "@/common/components/swiper.vue";
import SwipeableNavigationCell from "@/common/components/cell-basics/swipeable-navigation-cell.vue";

import { StructureElementsVm } from "../view-models/structure-elements-vm";
import { SharedDashboardStateVm } from "../view-models/shared/shared-dashboard-state-vm";
import { ValueVm } from "../view-models/value-vm";
import { SwiperVm } from "@/common/components/swiper-vm";
import { StructureElementsListVm } from "../view-models/structure-elements-list-vm";

import {
  ref,
  reactive,
  inject,
  computed,
  onMounted,
  watch,
  PropType,
  defineComponent,
} from "vue";
import type { CSSProperties } from "vue/types/jsx";
import { DashboardCommon } from "@/features/dashboard/dashboard-common";
import { useHelpText } from "@/services/help-text-service/help-text.cpsl";
import { IMediaQueries } from "@/common/styles/media-queries.interface";
import {
  DashboardStatusType,
  DashboardStatus,
} from "@/features/dashboard/status-configs/dashboard-status";
import { DashboardSelection } from "@/features/dashboard-shared/dashboard-selection";

class StructureElementsState {
  activeStructureId: number = null; /// ActiveStructure after swiperFinished
  structureBarHeight = "calc(1.4em + 4px)";
  widths: string[] = [];
}

export default defineComponent({
  components: {
    SwipeableNavigationCell,
    Swiper,
    StructureElementsList,
  },

  props: {
    structureElementsVm: {
      type: Object as PropType<StructureElementsVm>,
      required: true,
    },
    sharedState: { type: Object as PropType<SharedDashboardStateVm>, required: true },
    parentValueVm: { type: Object as PropType<ValueVm>, required: true },
    dashboardSelection: {
      type: Object as PropType<DashboardSelection>,
      required: true,
    },
    scaleColor: { type: String, default: null },
    drillDepth: { type: Number, default: 1 },
    deltaValuesActiveIndex: { type: Number, required: true },
    hasSparklines: { type: Boolean, default: false },
    dashboardStatusType: {
      type: String as () => DashboardStatusType,
      default: "ThreeValuesNoSparklinesScaleIndexGreaterZero",
    },
    firstValueWidth: { type: Number, required: true },
    structureNames: { type: Array as PropType<string[]>, required: true },
    zoomFactor: { type: Number, default: 1 },
  },

  setup(props) {
    const mediaQueries: IMediaQueries = inject("mediaQueries");
    const ref_componentElem = ref<HTMLDivElement | null>(null);
    const helpTextCpsl = useHelpText();
    const state = reactive(new StructureElementsState()) as StructureElementsState;

    //
    // Life Cycle:
    // --------------------
    onMounted(() => {
      swiperVm.value.scrolledPixel = 0;
      const index = swiperVm.value.getRealIndex();
      state.activeStructureId =
        props.structureElementsVm.structureElementsListVms[index].structureVm.id;
    });

    //
    // Computeds:
    // --------------------
    const isExtended = computed<boolean>(() =>
      DashboardStatus.isExtended(props.dashboardStatusType)
    );

    const drillSpacerWidth = computed<number>(() => {
      return isExtended.value
        ? DashboardCommon.getDrillSpacerWidthExtended(props.drillDepth)
        : DashboardCommon.getDrillSpacerWidth(props.drillDepth);
    });

    const structureElementsStyle = computed<CSSProperties>(() => {
      return { "--barHeight": state.structureBarHeight };
    });

    const structureBarStyle = computed<CSSProperties>(() => {
      const margin = isExtended.value
        ? DashboardCommon.getDrillSpacerWidthExtended(props.drillDepth - 1)
        : DashboardCommon.getDrillSpacerWidth(props.drillDepth);
      const padding = isExtended.value ? 24 : 5;

      return {
        backgroundColor: props.scaleColor,
        marginLeft: margin + "px",
        paddingLeft: padding + "px",
        width: "calc(100% - " + margin + "px)",
      };
    });

    const swiperVm = computed<SwiperVm>(() => {
      return props.structureElementsVm.structureElementsSwiperVm;
    });

    const isSwipingOrScrolling = computed<boolean>(() => {
      return Math.abs(swiperVm.value.scrolledPixel) > 1 || swiperVm.value.isSwiping;
    });

    //
    // Functions:
    // --------------------
    function onSwiperFinished(): void {
      state.activeStructureId =
        props.structureElementsVm.currentStructureElementsListVm.structureVm.id;

      props.structureElementsVm.structureElementsListVms.map((structureElementListVm) =>
        structureElementListVm.deltaValuesSwiperVm?.swipeTo(
          props.deltaValuesActiveIndex,
          false
        )
      );
    }

    function isRealActiveStructure(
      structureElementsListVm: StructureElementsListVm,
      activeItem: StructureElementsListVm
    ): boolean {
      return structureElementsListVm.structureVm.id === activeItem.structureVm.id;
    }

    function isFirstOrLastStructure(index: number): boolean {
      const isFirstItem = index === 0;
      const isLastItem =
        index === props.structureElementsVm.structureElementsListVms.length + 1;

      return isFirstItem || isLastItem;
    }

    function onSwiperChanged(): void {
      props.structureElementsVm.closeDrills();
      props.structureElementsVm.resetPercentageModes();
    }

    //
    // Watcher:
    // --------------------
    watch(
      () => props.structureElementsVm.structureElementsSwiperVm.isMoving,
      onSwiperChanged
    );

    return {
      state,
      helpTextCpsl,
      ref_componentElem,
      structureElementsStyle,
      mediaQueries,

      // Computeds:
      swiperVm,
      isSwipingOrScrolling,
      drillSpacerWidth,
      structureBarStyle,

      // Functions:
      onSwiperFinished,
      onSwiperChanged,
      isRealActiveStructure,
      isFirstOrLastStructure,
    };
  },
});
</script>

<template>
  <div
    ref="ref_componentElem"
    class="structureElements"
    v-bind:style="structureElementsStyle"
  >
    <SwipeableNavigationCell
      v-bind:swiperVm="swiperVm"
      v-bind:swipeItems="$props.structureNames"
      v-bind:zoomFactor="$props.zoomFactor"
      v-bind:justifyContent="'flex-start'"
      v-bind:navigationCellStyle="structureBarStyle"
      v-bind:isLeftClickEnabled="mediaQueries.isMouse"
      v-bind:isRightClickEnabled="mediaQueries.isMouse"
      v-bind:isClickEnabled="$props.structureNames.length > 1"
      v-bind:separatorColor="$props.scaleColor"
      v-bind:applyFontOpacity="true"
      v-bind:showArrows="false"
      v-on:navigationCellBeforeClick="onSwiperChanged"
      v-on:mousedown.native="$props.dashboardSelection.clearElement()"
      v-bind:data-helpText="
        helpTextCpsl.changeStructureText($props.structureNames.length > 1)
      "
    />
    <Swiper
      v-bind:swiperVm="swiperVm"
      v-bind:itemsVms="$props.structureElementsVm.structureElementsListVms"
      v-bind:alignItems="'flex-start'"
      v-bind:zoomFactor="$props.zoomFactor"
      v-on:swiper-finished="onSwiperFinished"
      v-on="$listeners"
    >
      <template v-slot:swipeItems="sProps">
        <StructureElementsList
          v-bind:structureElementsListVm="sProps.swipeItem"
          v-bind:isRealActiveStructure="
            isRealActiveStructure(sProps.swipeItem, sProps.activeItem)
          "
          v-bind:activeStructureId="state.activeStructureId"
          v-bind:isFirstOrLastStructure="isFirstOrLastStructure(sProps.index)"
          v-bind:isLoadingStructures="$props.structureElementsVm.isUpdating"
          v-bind:isSwipingOrScrolling="isSwipingOrScrolling"
          v-bind:hasSparklines="$props.hasSparklines"
          v-bind:sharedState="$props.sharedState"
          v-bind:drillDepth="$props.drillDepth"
          v-bind:deltaValuesActiveIndex="$props.deltaValuesActiveIndex"
          v-bind:parentValueVm="$props.parentValueVm"
          v-bind:dashboardStatusType="$props.dashboardStatusType"
          v-bind:firstValueWidth="$props.firstValueWidth"
          v-bind:drillSpacerWidth="drillSpacerWidth"
          v-bind:sortType="$props.structureElementsVm.sortType"
          v-bind:sortedValueId="$props.structureElementsVm.sortedColumn"
          v-bind:dashboardSelection="dashboardSelection"
          v-on="$listeners"
        />
      </template>
    </Swiper>
  </div>
</template>

<style lang="less" scoped>
.structureElements {
  position: relative;

  .swipeable-navigation-cell {
    border-top: 1px solid var(--color_bg_white);
    height: var(--barHeight);
  }
}
</style>
