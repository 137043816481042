<script lang="ts">
import { defineComponent, ref, computed, Ref } from "vue";
import type { CSSProperties } from "vue/types/jsx";
import { useHoverHelper } from "@/common/composables/hover-helper.cpsl";

export default defineComponent({
  props: {
    initialColor: { type: String, required: true },
    hoverColor: { type: String, default: null },
  },

  setup(props) {
    const ref_el: Ref<HTMLDivElement> = ref(null);
    const hoverHelperCpsl = useHoverHelper(ref_el);

    const svgStyle = computed<CSSProperties>(() => {
      const isHover = props.hoverColor !== null && hoverHelperCpsl.isHover.value;
      return {
        "enable-background": "new 0 0 160.8 122.524",
        "--stroke-color": isHover ? props.hoverColor : props.initialColor,
      };
    });

    return {
      ref_el,
      svgStyle,
    };
  },
});
</script>

<template>
  <div ref="ref_el" class="cloneIcon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 160.8 122.524"
      xml:space="preserve"
      v-bind:style="svgStyle"
    >
      <rect class="rect-polyline" x="26.138" y="43.175" width="72.349" height="72.349" />
      <polyline
        class="rect-polyline"
        points="62.313,43.175 62.313,7 134.662,7 134.662,79.349 98.487,79.349 "
      />
    </svg>
  </div>
</template>

<style lang="less" scoped>
.cloneIcon {
  height: 100%;

  svg {
    height: 100%;

    .rect-polyline {
      fill: none;
      stroke: var(--stroke-color);
      stroke-width: 14;
      stroke-miterlimit: 10;
    }
  }
}
</style>
