﻿import { WorkItemState } from "@/common/service-clients/generated-clients";
import { delay } from "@/common/helper/async-helper";

type Progress = (value: number) => void;

// keep this as close as possible to the dto definition
interface IWorkItemDto<T> {
  id: string;
  state: WorkItemState;
  progressInPercentage: number | null;
  result: T | null;
}

type WorkLaunchFunc<T, V> = (input: V) => Promise<IWorkItemDto<T>>;
type WorkStatusFunc<T, V> = (input: V, workId: string) => Promise<IWorkItemDto<T>>;

export interface LongRunningWorkOptions {
  timeout: number;
  delay: number;
}

export class LongRunningWorkServiceClient {
  private readonly _options: LongRunningWorkOptions;

  constructor(options?: Partial<LongRunningWorkOptions>) {
    this._options = { delay: 1000, timeout: -1, ...options };
  }

  async complete<T, V>(
    input: V,
    launch: WorkLaunchFunc<T, V>,
    status: WorkStatusFunc<T, V>,
    progress: Progress
  ): Promise<T> {
    const deadline =
      this._options.timeout > 0 ? new Date().getTime() + this._options.timeout : -1;

    let work = await launch(input);
    while (work.state === "Todo" || work.state === "InProgress") {
      progress(work.progressInPercentage || 0);

      await delay(this._options.delay);

      work = await status(input, work.id);

      if (deadline > 0 && new Date().getTime() > deadline) {
        throw new Error("work item did not complete in time");
      }
    }

    progress(work.progressInPercentage || 0);

    if (work.state === "Faulted") {
      const errorResult = work.result ? ` status=${work.result}` : "";
      throw new Error(`work item did not complete successfully${errorResult}`);
    }

    return work.result;
  }
}
