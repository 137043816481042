import { ValueVm } from "@/features/dashboard/view-models/value-vm";
import { TileConfiguration } from "@bissantz/tile-grid";
import { TileError } from "../errors";
import { ITilePageVm } from "../tile-pages/tile-page-vm.interface";
import { ProtectedPortalSelectionVm } from "../view-models/protected-portal-selection";

export class TileVm {
  // Must be unique within a PortalPage: needed by tile-grid
  id: string = null;
  name: string = null;
  description: string = null;

  publishedApplicationId: string;

  tileConfig: TileConfiguration = null;
  isTemporary: boolean = false;
  animationsEnabled: boolean = true;
  disableTransitions: boolean = true;
  isShown: boolean = false;
  isFullpage: boolean = false;
  portalSelection: ProtectedPortalSelectionVm;

  tilePageVms: ITilePageVm[] = [];
  activeTilePageIdx: number = 0;

  portalScaledValueCopies: ValueVm[] = [];
  error: TileError = null;

  get hasPageContent(): boolean {
    return !!this.activePage;
  }

  get isInitialized(): boolean {
    return this.activePage?.isInitialized;
  }

  get activePage(): ITilePageVm {
    return this.tilePageVms[this.activeTilePageIdx];
  }

  get canClone(): boolean {
    return !this.error && !this.activePage.isInError && this.isInitialized;
  }

  getShownTitle(): string {
    let resultTitle = "";
    if (!this.error && !this.isInitialized) {
      resultTitle = "";
    } else {
      resultTitle = this.activePage?.name ?? this.name;
    }

    return resultTitle;
  }

  isVsSyncReady(): boolean {
    return this.activePage?.isVsSyncReady() ?? false;
  }
}
