import { SimpleEvent } from "@/common/events/simple-event";

export type DM7Timeout = string | number | NodeJS.Timeout;

export class DM7Timer {
  private _timeOutId: DM7Timeout = null;
  private readonly _onTimeoutEvent = new SimpleEvent();
  delay_ms: number;

  get onTimeoutEvent(): SimpleEvent {
    return this._onTimeoutEvent;
  }

  constructor(delay_ms: number = 0) {
    this.delay_ms = delay_ms;
  }

  start(delay_ms: number = null): void {
    if (delay_ms !== null) {
      this.delay_ms = delay_ms;
    }

    this.stop();
    this._timeOutId = setTimeout(() => this._onTimeoutEvent.emit(), this.delay_ms);
  }

  stop(): void {
    clearTimeout(this._timeOutId);
  }
}
