import { VsRoot } from "@/services/view-state-service/contract/vs-root";
import { PortalVm } from "../../view-models/portal-vm";
import {
  IVmReadyChecker,
  WatchBehaviour,
} from "@/services/view-state-service/contract/vm-ready-checker.interface";

export class PortalVmReadyChecker implements IVmReadyChecker {
  getWatchBehaviour(): WatchBehaviour {
    return { watchList: null, automatic: true };
  }

  isVmReady(vm: VsRoot): { isVmReady: boolean; hasAnyError: boolean } {
    const portalVm = vm as PortalVm;
    const hasAnyError = !!portalVm.error;
    const isPageLoadingDone = portalVm.isEmptyPortal || portalVm.arePagesLoaded;

    const isVmReady = !hasAnyError && isPageLoadingDone;

    return {
      isVmReady,
      hasAnyError,
    };
  }
}
