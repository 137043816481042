export class CanvasVm {
  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D;
  width: number;
  height: number;

  constructor(
    canvas: HTMLCanvasElement,
    context: CanvasRenderingContext2D,
    width: number,
    height: number
  ) {
    this.canvas = canvas;
    this.context = context;
    this.width = width;
    this.height = height;
  }
}
