import { PortalPageVm } from "./portal-page-vm";
import { PortalFacade } from "../backend-wrapper/portal-facade";
import { clear } from "@/common/helper/array-helper";
import { VsRoot } from "@/services/view-state-service/contract/vs-root";
import { PortalControlsVm } from "../helper-components/controls-vm";
import { IPortalPageFm } from "../backend-wrapper/facade-models";
import { ProtectedPortalSelectionVm } from "./protected-portal-selection";

export type PortalError = "noPortal" | "noPages";
export class PortalVm extends VsRoot {
  private _portalFacade: PortalFacade = null;

  id: string = null;
  title: string = "";
  portalSelection: ProtectedPortalSelectionVm = null;
  portalPages: PortalPageVm[] = [];

  isEmptyPortal: boolean = false;
  arePagesLoaded: boolean = false;
  error: PortalError = null;
  controlVm: PortalControlsVm = new PortalControlsVm();

  constructor(portalFacade: PortalFacade, portalSelection: ProtectedPortalSelectionVm) {
    super();
    this.portalSelection = portalSelection;
    this._portalFacade = portalFacade;
  }

  async loadData(portalId: string): Promise<void> {
    const portalFm = await this._portalFacade.getPortal(portalId);
    this.title = portalFm.name;
    this.id = portalFm.id;

    // Vs related:
    this.viewStateId = portalFm.defaultPortalViewStateId;
    this.parentViewStateId = null;

    await this._loadPages(portalId);
  }

  get selectedPageIndex(): number {
    if (!this.portalSelection.pageId || !this.portalPages.length) {
      return 0;
    }

    const pageIdx = this.portalPages.findIndex(
      (page) => page.id === this.portalSelection.pageId
    );

    return pageIdx;
  }

  get selectedPage(): PortalPageVm {
    const page = this.portalPages[this.selectedPageIndex];
    return page ?? null;
  }

  private async _loadPages(portalId: string): Promise<void> {
    this.arePagesLoaded = false;
    const pages = await this._portalFacade.getPages(portalId);

    clear(this.portalPages);

    for (const page of pages) {
      const pageVm = this._createPage(page);
      await pageVm.getPortalTileVmsAsync();
      this.portalPages.push(pageVm);
    }
    this.arePagesLoaded = true;

    if (!this.portalPages.length) {
      // TODO: why not set this.emptyPortal here?
      this.error = "noPages";
    }
  }

  private _createPage(pageFm: IPortalPageFm): PortalPageVm {
    const pageVm = new PortalPageVm(this._portalFacade, this.portalSelection);

    pageVm.title = pageFm.name;
    pageVm.id = pageFm.id;

    return pageVm;
  }
}
