import { SparklineMode } from "@/common/service-clients/generated-clients";
import { SparklineModeFm } from "@/features/dashboard-shared/sparkline/backend-wrapper/facade-models-sparklines";

export class FmDtoMapperSparklines {
  static mapSparklineModeDto(sparklineMode: SparklineModeFm): SparklineMode {
    return this._mappingSparklineMode[sparklineMode];
  }

  private static _mappingSparklineMode: { [key in SparklineModeFm]: SparklineMode } = {
    global: "Global",
    local: "Local",
    disabled: "None",
  };
}
