import { SwiperVm } from "@/common/components/swiper-vm";
import { SharedDrillInfoVm } from "../shared/shared-drill-info-vm";
import { SharedKpiInfo } from "../shared/shared-kpi-info";
import { SharedRowStateVm } from "../shared/shared-row-state-vm";
import { StructureElementsListVm } from "../structure-elements-list-vm";
import { ElementBuilder } from "./element-builder";
import { StructureVm } from "../structure-vm";
import { KpiDrillStructureFilterFm } from "@/features/dashboard/backend-wrapper/facade-models-dashboard";
import { IDashboardFacade } from "@/features/dashboard/backend-wrapper/dashboard-facade.interface";

/**
 * Solves circular dependency between this class and ElementBuilder
 *
 * Elements and StructureElements
 * are by definition (software requirements) circular dependent
 */
let globalElemBuilder: ElementBuilder = null;
function getElementBuilder(): ElementBuilder {
  if (!globalElemBuilder) {
    globalElemBuilder = new ElementBuilder();
  }

  return globalElemBuilder;
}

export class StructureElementsListBuilder {
  createStructureElementsList(
    structureVm: StructureVm,
    availableStructures: StructureVm[],
    nextStructureVm: StructureVm[],
    previousFilters: KpiDrillStructureFilterFm[],
    drillInfo: SharedDrillInfoVm,
    sharedKpiInfo: SharedKpiInfo,
    parentRowState: SharedRowStateVm = null,
    dashboardFacade: IDashboardFacade
  ): StructureElementsListVm {
    const result = new StructureElementsListVm();

    result.dashboardFacade = dashboardFacade;
    result.structureVm = structureVm;
    result.availableStructureVms = availableStructures;
    result.nextStructureVms = nextStructureVm;
    result.sharedDrillInfo = drillInfo;
    result.sharedKpiInfo = sharedKpiInfo;
    result.parentRowState = parentRowState;
    result.previousFilters = previousFilters;

    return result;
  }

  clone(
    original: StructureElementsListVm,
    cloneDrillInfo: SharedDrillInfoVm,
    cloneKpiInfo: SharedKpiInfo,
    cloneParentRowState: SharedRowStateVm
  ): StructureElementsListVm {
    const clone = new StructureElementsListVm();

    clone.elementVms = original.elementVms.map((elem) =>
      getElementBuilder().clone(elem, cloneDrillInfo, cloneKpiInfo, cloneParentRowState)
    );
    clone.structureVm = new StructureVm(original.structureVm);
    clone.availableStructureVms = original.availableStructureVms.map(
      (availableStructureVm) => new StructureVm(availableStructureVm)
    );
    clone.nextStructureVms = original.nextStructureVms.map(
      (nextStructureVm) => new StructureVm(nextStructureVm)
    );
    clone.hasMoreRows = original.hasMoreRows;
    clone.deltaValuesSwiperVm = original.deltaValuesSwiperVm
      ? new SwiperVm(1, original.deltaValuesSwiperVm.activeIndex)
      : null;
    clone.sparklinesGlobalMax = original.sparklinesGlobalMax;
    clone.sharedDrillInfo = cloneDrillInfo;
    clone.sharedKpiInfo = cloneKpiInfo;
    clone.parentRowState = cloneParentRowState;
    clone.drillResultLimit = original.drillResultLimit;
    clone.numberElementsInDrill = original.numberElementsInDrill;
    clone.previousFilters = original.previousFilters.map(
      (filter) => new KpiDrillStructureFilterFm(filter)
    );
    clone.nextStructureVms = original.nextStructureVms.map(
      (structureVm) => new StructureVm(structureVm)
    );
    clone.dashboardFacade = original.dashboardFacade;

    return clone;
  }
}
