<script lang="ts">
import ShareButton from "@/features/sharing/share-button.vue";
import CloneIcon from "@/common/components/icons/clone-icon.vue";
import SparklineIcon from "@/common/components/icons/sparkline-icon.vue";
import WidthToggle from "../../helper-components/width-toggle.vue";
import { useHelpText } from "@/services/help-text-service/help-text.cpsl";

import { DefaultStyles } from "@/common/styles/default-styles";

import { computed, defineComponent, inject, PropType, Ref } from "vue";
import { DashboardPageVm } from "./dashboard-page-vm";

import { getBrowserType, isMobile } from "@/common/browser-detection";
import { SessionVm } from "@/session-vm";
import { ITileService } from "../tile-service.interface";
import { LiveFeatureTogglesVm } from "@/features/live-feature-toggles/live-feature-toggles-vm";
import { TilePageCommon } from "../tile-page-common";
import HeightToggle from "@/features/portal/tile-pages/dashboard/height-toggle.vue";
import AccurateScaledNumbersToggle from "@/features/portal/tile-pages/dashboard/accurate-scaled-numbers-toggle.vue";

const colorConstants = DefaultStyles.colorConstants;

export default defineComponent({
  components: {
    AccurateScaledNumbersToggle,
    HeightToggle,
    CloneIcon,
    ShareButton,
    SparklineIcon,
    WidthToggle,
  },

  emits: [TilePageCommon.tilePage_clone.name],

  props: {
    pageVm: { type: Object as PropType<DashboardPageVm>, required: true },
    sparklineAnimationRunning: {
      type: Promise as PropType<Promise<void> | null>,
      default: null,
    },
    isPreviewEnabled: { type: Boolean, default: true },
  },

  setup: function (props, context) {
    const session = inject("session") as SessionVm;
    const tileService = inject<Ref<ITileService>>("tileService");
    const liveToggles = inject("liveToggles") as LiveFeatureTogglesVm;

    const helpTextCpsl = useHelpText();

    //
    // Life Cycle:
    // --------------------

    //
    // Computeds:
    // --------------------
    const isShowAllKpiActive = computed<boolean>(() => {
      return !props.pageVm.dashboardVm.dbSettings.applyKpiIdFilter;
    });

    const canShowToggleKpisButton = computed<boolean>(() => {
      return isShowAllKpiActive.value && props.pageVm.dashboardVm.hasEnoughKpis;
    });

    const canShowButtons = computed<boolean>(() => {
      return props.pageVm.dashboardVm?.isInitialized;
    });

    const canShowSparklinesButton = computed<boolean>(() => {
      return (
        canShowButtons.value &&
        props.pageVm.dashboardVm?.sharedState.sparklineState.canShowSparklines
      );
    });

    const canShowShareButton = computed<boolean>(() => {
      if (!canShowButtons.value) {
        return false;
      }

      if (session.isEmbeddedMode) {
        return false;
      }

      const isDesktop = !isMobile();
      const isSafari = getBrowserType() === "safari";

      return isDesktop || isSafari;
    });

    const canExtend = computed<boolean>(() => {
      return tileService.value.maxWidth >= 4;
    });

    const isExtended = computed<boolean>(() => {
      return props.pageVm.dashboardVm?.dbSettings.isExtended;
    });

    const showLargeSideIcons = computed<boolean>(
      () =>
        isShowAllKpiActive.value &&
        props.pageVm.dashboardVm.dbSettings.isCompact &&
        props.pageVm.dashboardVm.dbSettings.isExtended
    );

    const areSparklinesDisabled = computed<boolean>(() => {
      if (!props.pageVm.dashboardVm) {
        return true;
      }

      return props.pageVm.dashboardVm.sharedState.sparklineState.isDisabled;
    });

    const sparklinesHoverColor = computed<string>(() => {
      if (canShowSparklinesButton.value) {
        return areSparklinesDisabled.value
          ? colorConstants.darkTextHEX
          : colorConstants.headerTextHEX;
      }

      return areSparklinesDisabled.value
        ? colorConstants.headerTextHEX
        : colorConstants.darkTextHEX;
    });

    const showLargeSparklineIcon = computed<boolean>(() => {
      return props.pageVm.dashboardVm?.sharedState.sparklineState.mode !== "global";
    });

    const globalSparklinesEnabled = computed<boolean>(() => {
      return liveToggles?.isEnabled && liveToggles.toggleValues.sparkline_globalScaling;
    });

    const showTitleSeparator = computed(() => {
      if (!props.pageVm.dashboardVm) {
        return true;
      }

      return props.pageVm.dashboardVm.filters.filters.length > 0;
    });

    const canExtendWidth = computed(() => {
      return props.pageVm.dashboardVm?.maxNumKpiValues > 1;
    });

    const tileWidthModel = computed<number>({
      set: (value: number) => {
        tileService.value.setWidth(value);
      },

      get: () => {
        return tileService.value.width;
      },
    });

    //
    // Functions:
    // --------------------
    function onCloneTile(): void {
      context.emit(TilePageCommon.tilePage_clone.name);
    }

    function onSparklinesButtonClick(): void {
      if (!canShowButtons.value) {
        return;
      }

      props.pageVm.dashboardVm?.sharedState.sparklineState.toggleSparklinesMode(
        globalSparklinesEnabled.value
      );
    }

    function onAccurateScaledNumbersClicked(): void {
      if (props.pageVm.dashboardVm.sharedState.sparklineState.mode === "disabled") {
        return;
      }

      const sharedState = props.pageVm.dashboardVm.sharedState;
      sharedState.kpiScaleIndexBeforeSparklines = sharedState.kpiScaleIndex;
    }

    //
    // Watcher:
    // --------------------

    return {
      helpTextCpsl,
      colorConstants,
      tileService,

      // computeds:
      isShowAllKpiActive,
      canShowToggleKpisButton,
      canShowButtons,
      canShowSparklinesButton,
      canShowShareButton,
      areSparklinesDisabled,
      sparklinesHoverColor,
      showLargeSideIcons,
      showLargeSparklineIcon,
      canExtend,
      isExtended,
      showTitleSeparator,
      canExtendWidth,
      tileWidthModel,

      // functions:
      onCloneTile,
      onSparklinesButtonClick,
      onAccurateScaledNumbersClicked,
    };
  },
});
</script>

<template>
  <div
    class="dashboard-page_action-bar"
    v-bind:class="{
      'no-bottom-line': showTitleSeparator,
      'is-extended-width': isExtended,
    }"
  >
    <!-- ACTION: Show KPIs -->
    <div
      class="show-kpis-button side-action action"
      v-bind:class="{
        isActive: canShowToggleKpisButton,
        'disabled-icon': !$props.pageVm.dashboardVm.hasEnoughKpis,
        'hidden-icon': !$props.pageVm.dashboardVm.hasEnoughKpis,
        'large-side-action': showLargeSideIcons,
      }"
    >
      <HeightToggle
        v-bind:isShowAllKpiActive="isShowAllKpiActive"
        v-bind:isCompact="$props.pageVm.dashboardVm.dbSettings.isCompact"
        v-bind:isExtended="$props.pageVm.dashboardVm.dbSettings.isExtended"
        v-bind:areSparklinesCompatible="$props.pageVm.dashboardVm.areSparklinesCompatible"
        v-bind:isExpandingKpis="$props.pageVm.dashboardVm.isExpandingKpis"
        v-on:shrinkClicked="$props.pageVm.dashboardVm.shrinkKpiFilter()"
        v-on:expandClicked="$props.pageVm.dashboardVm.extendKpiFilter()"
      />
    </div>

    <div class="action-group">
      <!-- Share Tile Button  -->
      <div class="shareButtonWrapper middle-action action" v-if="canShowShareButton">
        <ShareButton
          v-bind:toBeShared="tileService.tileHtmlElem"
          v-bind:isEnabled="canShowButtons"
          v-bind:screenshotName="$props.pageVm.name"
          v-bind:waitScreenshotFor="$props.sparklineAnimationRunning"
          v-on="$listeners"
        />
      </div>

      <!-- Show Sparklines  -->
      <div
        class="show-sparkline-button middle-action action"
        v-on:click="onSparklinesButtonClick"
        v-bind:class="{ 'disabled-icon': !canShowSparklinesButton }"
        v-bind:data-helpText="
          helpTextCpsl.sparklineButtonText(canShowSparklinesButton, areSparklinesDisabled)
        "
      >
        <SparklineIcon
          v-bind:largeIcon="showLargeSparklineIcon"
          v-bind:initialColor="
            areSparklinesDisabled
              ? colorConstants.headerTextHEX
              : colorConstants.darkTextHEX
          "
          v-bind:hoverColor="sparklinesHoverColor"
        />
      </div>

      <!-- Accurate / Scaled Numbers -->
      <div
        class="scaled-numbers-button middle-action action"
        v-bind:class="{
          'disabled-icon': !canShowButtons,
        }"
      >
        <AccurateScaledNumbersToggle
          v-bind:isPreviewEnabled="$props.isPreviewEnabled"
          v-model="$props.pageVm.dashboardVm.sharedState.kpiScaleIndex"
          v-on:AccurateScaledNumbersClicked="onAccurateScaledNumbersClicked"
        />
      </div>

      <!-- Clone Tile  -->
      <div
        v-on:click="onCloneTile"
        class="clone-button middle-action action"
        v-bind:class="{ 'disabled-icon': !canShowButtons }"
        v-bind:data-helpText="helpTextCpsl.clonePortalTileText()"
      >
        <CloneIcon
          v-bind:initialColor="colorConstants.headerTextHEX"
          v-bind:hoverColor="colorConstants.darkTextHEX"
        />
      </div>
    </div>

    <!-- ACTION: Width Toggle -->
    <div
      class="width-toggle-button side-action action"
      v-bind:class="{
        'disabled-icon': !canShowButtons,
        'large-side-action': showLargeSideIcons,
      }"
    >
      <WidthToggle
        v-bind:enabled="canShowButtons"
        v-bind:canExtend="tileWidthModel < 4 && canExtendWidth && canExtend"
        v-bind:canShrink="tileWidthModel > 2"
        v-bind:helpTexts="{
          shrink: helpTextCpsl.shrinkDashboardText(),
          expand: helpTextCpsl.expandDashboardText(),
        }"
        v-model="tileWidthModel"
      />
    </div>
  </div>
</template>

<style scoped lang="less">
.dashboard-page_action-bar {
  --side-action-width: 21.59px;
  width: 100%;
  height: 28px;
  box-sizing: border-box;

  background-color: var(--color_bg_white);
  color: var(--color_neutralText);
  font-size: var(--hostedFontSize);

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid var(--color_bg-gray);

  &.no-bottom-line {
    border-bottom: 0;
  }

  &.is-extended-width {
    padding-left: 20px;
    padding-right: 16px;
  }

  // action entries:
  .action-group {
    height: 100%;
    align-self: center;
    display: flex;
  }

  .action {
    &.side-action {
      width: var(--side-action-width);

      &:first-child {
        align-self: flex-start;
        margin-left: 7px;
      }
      &:last-child {
        align-self: flex-end;
        margin-right: 7px;
      }

      &.large-side-action {
        width: calc(2 * var(--side-action-width));
      }
    }
    &.middle-action {
      width: 22px;
      margin: 0 18px;
    }

    height: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: var(--color_headerText);
    cursor: pointer;

    &.disabled-icon {
      &.hidden-icon {
        opacity: 0;
      }

      opacity: 0.2;
      cursor: auto;

      & > div {
        cursor: auto;
      }
    }
  }

  .width-toggle-button {
    cursor: auto;

    .widthToggle {
      justify-content: flex-end;
    }
  }

  .sparklineIcon {
    height: 60%;
  }

  .cloneIcon {
    height: 70%;
  }
}
</style>
