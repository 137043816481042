import { IDisposable } from "../disposable.interface";
import { ISimpleEvent } from "./isimple-event";

export interface SimpleListener {
  (): void;
}

export class SimpleEvent implements ISimpleEvent {
  private listeners: SimpleListener[] = [];
  private listenersOncer: SimpleListener[] = [];

  on(listener: SimpleListener): IDisposable {
    this.listeners.push(listener);

    return {
      dispose: () => this.removeListener(listener),
    };
  }

  once(listener: SimpleListener): void {
    this.listenersOncer.push(listener);
  }

  removeListener(listener: SimpleListener) {
    const callbackIndex = this.listeners.indexOf(listener);

    if (callbackIndex > -1) this.listeners.splice(callbackIndex, 1);
  }

  removeAllListeners(): void {
    this.listeners.splice(0, this.listeners.length);
    this.listenersOncer.splice(0, this.listenersOncer.length);
  }

  // Not part of the interface:
  // Because clients that register listeners should never be able to emit!
  emit() {
    /** Update any general listeners */
    this.listeners.forEach((listener) => listener());

    /** Clear the `once` queue */
    if (this.listenersOncer.length > 0) {
      const toCall = this.listenersOncer;
      this.listenersOncer = [];
      toCall.forEach((listener) => listener());
    }
  }
}
