export type ValueType = "absolute" | "percent" | "percentagePoint" | "timeSpan";

const timeSpanRegex = /timespan[0-9]{1},[0-9]{1}/;

export function getValueType(format: string): ValueType {
  let trimmedFormat = format;
  if (format) trimmedFormat = format.trim();

  let resultValueType: ValueType;

  if (!trimmedFormat) return "absolute";
  else if (trimmedFormat === "%-pt") return "percentagePoint";
  else if (trimmedFormat.includes("%")) return "percent";
  else if (trimmedFormat.match(timeSpanRegex)) return "timeSpan";
  else resultValueType = "absolute";

  return resultValueType;
}

export function isAnyPercentageType(valueType: ValueType): boolean {
  return valueType === "percent" || valueType === "percentagePoint";
}
