import { ITypedEvent } from "@/common/events/ityped-event";

export type KeyId = "Shift" | "Control" | "Alt" | "Escape";
export type KeyEventEntry = { keyId: string; isDown: boolean };
export interface IInputEventsService {
  isDown(keyId: KeyId): boolean;
  get anyKeyChanged(): ITypedEvent<KeyEventEntry>;
  getkeyChangedEvent(keyId: string): ITypedEvent<boolean>;
}

export class KeyIds {
  static shift: KeyId = "Shift";
  static control: KeyId = "Control";
  static alt: KeyId = "Alt";
  static esc: KeyId = "Escape";
}
