<script lang="ts">
import { defineComponent, computed } from "vue";
import type { CSSProperties } from "vue/types/jsx";

export default defineComponent({
  props: {
    initialColor: { type: String, default: "#002d71" },
  },

  setup(props, context) {
    const colorVars = computed<CSSProperties>(() => {
      return {
        "--initial-color": props.initialColor,
      };
    });

    function onMouseOver() {
      context.emit("mouseover");
    }

    function onMouseLeave() {
      context.emit("mouseleave");
    }

    function xIconClicked() {
      context.emit("xIconClicked");
    }

    return {
      colorVars,
      onMouseOver,
      onMouseLeave,
      xIconClicked,
    };
  },
});
</script>

<template>
  <div
    class="x-in-circle-icon"
    v-bind:style="colorVars"
    v-on:mouseover="onMouseOver"
    v-on:mouseleave="onMouseLeave"
    v-on:click="xIconClicked"
  >
    <svg
      viewBox="0 0 28.395084 28.395079"
      version="1.1"
      xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="matrix(0.26458333,0,0,0.26458333,-7.1013725,0.41712635)">
        <g style="overflow: visible" transform="translate(-31.495249,-27.464919)">
          <path
            d="m 111.99513,25.888369 c -29.635004,0 -53.660004,24.025 -53.660004,53.66 0,29.635001 24.025,53.660001 53.660004,53.660001 29.635,0 53.66,-24.025 53.66,-53.660001 0,-29.635 -24.024,-53.66 -53.66,-53.66 z"
            sodipodi:nodetypes="sssss"
            style="fill: #ffffff"
          />
        </g>
        <path
          d="m 80.49988,-1.5765508 c -29.635001,0 -53.66,24.0250008 -53.66,53.6600008 0,29.635 24.025,53.66 53.66,53.66 29.635,0 53.66,-24.025 53.66,-53.66 0,-29.635 -24.024,-53.6600008 -53.66,-53.6600008 z m 28.626,71.6790008 -10.608001,10.607 -18.017999,-18.019 -18.018,18.019 -10.607,-10.607 18.018999,-18.019 -18.019,-18.019 10.606,-10.606 18.019,18.018 18.017999,-18.018 10.607002,10.606 -18.018,18.019 z"
        />
      </g>
    </svg>
  </div>
</template>

<style lang="less">
.x-in-circle-icon {
  cursor: pointer;

  svg {
    height: 100%;
    fill: var(--initial-color);
  }
}
</style>
