import { ISimpleEvent } from "../events/isimple-event";
import { SimpleEvent } from "../events/simple-event";
import { getGUID } from "./guid-helper";

export type ReleaseCallback = () => void;

export interface ISemaphoreCounter {
  use(): string;
  useReleaseCb(): ReleaseCallback;
  release(clientId: string): void;
  isUsed: boolean;

  isUsedChangedEvent: ISimpleEvent;
}

export class SemaphoreCounter implements ISemaphoreCounter {
  private readonly _usedEvent = new SimpleEvent();
  private readonly _registeredClients: string[] = [];

  private constructor() {}
  static create(): ISemaphoreCounter {
    return new SemaphoreCounter();
  }

  use(): string {
    const clientId = getGUID();
    this._registeredClients.push(clientId);
    if (this._registeredClients.length === 1) {
      this._usedEvent.emit();
    }
    return clientId;
  }

  useReleaseCb(): ReleaseCallback {
    const clientId = this.use();
    return () => {
      this.release(clientId);
    };
  }

  release(clientId: string): void {
    const clientIdx = this._registeredClients.findIndex(
      (storedId) => storedId === clientId
    );

    if (clientIdx === -1) {
      throw Error("Tried to release a semaphore lock twice.");
    }

    this._registeredClients.splice(clientIdx, 1);
    if (this._registeredClients.length === 0) {
      this._usedEvent.emit();
    }
  }

  get isUsed(): boolean {
    return this._registeredClients.length > 0;
  }

  get isUsedChangedEvent(): ISimpleEvent {
    return this._usedEvent;
  }
}
