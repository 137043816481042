<script lang="ts">
import { defineComponent, PropType, computed, onMounted, ref, watch } from "vue";
import type { CSSProperties } from "vue/types/jsx";
import { Cell, Separator } from "@/common/components/simple-grid/contracts/cell";

export const gridId = "gridId";

export default defineComponent({
  components: {},
  emits: [],
  props: {
    cells: { type: Array as PropType<Cell[]>, required: true },
    separators: { type: Array as PropType<Separator[]>, default: null },
    cellWidths: { type: Array as PropType<string[]>, required: true },
    separatorWidths: { type: Array as PropType<string[]>, required: true },
    cellHeights: { type: Array as PropType<string[]>, required: true },
    separatorHeights: { type: Array as PropType<string[]>, required: true },
  },
  setup(props, context) {
    const ref_simpleGrid = ref(null);

    //
    // Life Cycle:
    // --------------------
    onMounted(() => {
      setSlotComponentStyles();
      updateSeparators();
    });

    //
    // Computeds:
    // --------------------
    const simpleGridStyle = computed<CSSProperties>(() => {
      const colWidths = getCalculatedSizes(props.cellWidths, props.separatorWidths);
      const rowHeights = getCalculatedSizes(props.cellHeights, props.separatorHeights);
      return {
        gridTemplateColumns: colWidths,
        gridTemplateRows: rowHeights,
      };
    });

    //
    // Functions:
    // --------------------
    function getCalculatedSizes(sizes: string[], separatorSizes: string[]): string {
      const calculatedSizes: string[] = [];
      const totalSeparatorSize = separatorSizes.reduce(
        (acc, value) => acc + parseFloat(value),
        0
      );
      const defaultCellWidths = sizes.filter((value) => !value.endsWith("fr"));
      const totalCellWidths =
        defaultCellWidths.length > 0 ? defaultCellWidths.join(" - ") : "0px";
      const totalFrUnits = sizes
        .filter((value) => value.endsWith("fr"))
        .map((value) => parseFloat(value))
        .reduce((acc, value) => acc + value, 0);

      for (let i = 0; i < separatorSizes.length - 1; i++) {
        calculatedSizes.push(separatorSizes[i]);

        if (sizes[i].endsWith("fr")) {
          const frUnit = parseFloat(sizes[i]);
          const calcValue = `calc(((100% - ${totalSeparatorSize}px - ${totalCellWidths}) / ${totalFrUnits}) * ${frUnit})`;
          calculatedSizes.push(calcValue);
        } else {
          calculatedSizes.push(sizes[i]);
        }
      }

      calculatedSizes.push(separatorSizes[separatorSizes.length - 1]);
      return calculatedSizes.join(" ");
    }

    function setSlotComponentStyles(): void {
      for (const child of getChildComponents()) {
        const componentName = child.dataset[gridId];
        if (!componentName) {
          continue;
        }

        const cell = props.cells.find((cells) => cells.id === componentName);
        if (cell) {
          updateCellStyle(child, cell);
        } else {
          child.style.display = "none";
        }
      }
    }

    function updateCellStyle(child: HTMLElement, cell: Cell): void {
      const newStyles = getCellStyle(cell);
      const differentStyles = getDifferentInlineStyles(child, newStyles);
      Object.assign(child.style, differentStyles);
    }

    function getDifferentInlineStyles(
      element: HTMLElement,
      newStyles: CSSProperties
    ): CSSProperties {
      const differentStyles: CSSProperties = {};

      Object.keys(newStyles).forEach((key) => {
        const currentStyleValue = element.style[key];
        const newStyleValue = newStyles[key];
        const normalizedCurrentStyle = normalizeStyle(currentStyleValue);
        const normalizedNewStyle = normalizeStyle(newStyleValue);

        if (normalizedCurrentStyle !== normalizedNewStyle) {
          differentStyles[key] = newStyles[key];
        }
      });

      return differentStyles;
    }

    function normalizeStyle(value: string) {
      if (value === undefined || value === null) {
        return "";
      }
      return value
        .toString()
        .replace(
          /\b(\d*\.?\d+)(px|em|rem|%|vh|vw|pt|in|cm|mm|ex|ch|vmin|vmax|pc)\b/g,
          "$1"
        )
        .trim();
    }

    function getChildComponents(): HTMLElement[] {
      const simpleGrid = ref_simpleGrid.value as HTMLElement;

      if (!simpleGrid) {
        return [];
      }

      return Array.from(simpleGrid.children) as HTMLElement[];
    }

    function getCellStyle(cell: Cell): CSSProperties {
      return {
        gridColumn: getCellGridColumn(cell),
        gridRow: getCellGridRow(cell),
        padding: cell.padding ?? "0",
        display: "flex",
        alignItems: "center",
        height: "100%",
        boxSizing: "border-box",
      };
    }

    function getCellGridColumn(cell: Cell): string {
      const colSpan =
        cell.colSpan === -1 ? props.cellWidths.length * 2 + 1 : cell.colSpan * 2 - 1;
      return `${cell.col + 1} / span ${colSpan || 1}`;
    }

    function getCellGridRow(cell: Cell): string {
      const rowSpan =
        cell.rowSpan === -1 ? props.cellHeights.length * 2 + 1 : cell.rowSpan * 2 - 1;
      return `${cell.row + 1} / span ${rowSpan || 1}`;
    }

    function updateSeparators(): void {
      removeSeparators();
      addSeparators();
    }

    function removeSeparators(): void {
      const simpleGrid = ref_simpleGrid.value as HTMLElement;
      const childComponents = getChildComponents();

      for (const child of childComponents) {
        if (child.dataset[gridId]) {
          continue;
        }
        simpleGrid.removeChild(child);
      }
    }

    function addSeparators(): void {
      if (!props.separators) {
        return;
      }

      const simpleGrid = ref_simpleGrid.value as HTMLElement;
      const separators = props.separators;
      for (let i = 0; i < separators.length; i++) {
        const separatorDiv = document.createElement("div");
        Object.assign(separatorDiv.style, getSeparatorStyle(separators[i]));
        simpleGrid.appendChild(separatorDiv);
      }
    }

    function getSeparatorStyle(separator: Separator) {
      return {
        gridColumn: getSeparatorGridColumn(separator),
        gridRow: getSeparatorGridRow(separator),
        backgroundColor: separator.backgroundColor,
      };
    }

    function getSeparatorGridColumn(separator: Separator): string {
      const colSpan =
        separator.colSpan === -1
          ? props.separatorWidths.length * 2 + 1
          : separator.colSpan;
      return `${separator.col + 1} / span ${colSpan || 1}`;
    }

    function getSeparatorGridRow(separator: Separator): string {
      const rowSpan =
        separator.rowSpan === -1
          ? props.separatorHeights.length * 2 + 1
          : separator.rowSpan;
      return `${separator.row + 1} / span ${rowSpan || 1}`;
    }

    function onRequestUpdateCells(): void {
      setSlotComponentStyles();
      // setTimeout because components can be added / removed via v-if after setting the styles.
      setTimeout(() => setSlotComponentStyles(), 0);
    }

    //
    // Watcher:
    // --------------------
    watch(() => props.separators, updateSeparators);

    watch(
      () => props.cells,
      () => onRequestUpdateCells(),
      { deep: true }
    );

    return {
      ref_simpleGrid,
      simpleGridStyle,
      context,
    };
  },
});
</script>

<template>
  <div ref="ref_simpleGrid" class="simple-grid" v-bind:style="simpleGridStyle">
    <slot></slot>
  </div>
</template>

<style scoped lang="less">
.simple-grid {
  width: 100%;
  height: 100%;
  display: grid;
}
</style>
