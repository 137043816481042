// import { Component } from "vue";
import { Component } from "vue";
import { ITilePageVm } from "../tile-page-vm.interface";
import { DashboardVm } from "@/features/dashboard/view-models/dashboard-vm";
import DashboardPage from "./dashboard-page.vue";
import { IScalable } from "@/common/formatting/scalable.interface";
import { DashboardSelection } from "@/features/dashboard-shared/dashboard-selection";
import { DashboardBuilder } from "@/features/dashboard/view-models/builder/dashboard-builder";
import { TilePageVmType } from "../tile-page-common";
import { VsRoot } from "@/services/view-state-service/contract/vs-root";

const dashboardBuilder = new DashboardBuilder();
export class DashboardPageVm implements ITilePageVm {
  id: string = null;
  name: string = null;
  description: string = null;
  type: TilePageVmType = "Dashboard";

  pubAppId: string = null;
  isShown: boolean = false;
  isSelected: boolean = false;
  isInitialized: boolean = false;
  weatherColor: string = null;

  pageComponent: Component = DashboardPage;

  dashboardSelection: DashboardSelection = new DashboardSelection(); // TODO: can probably be moved into dashboard

  dashboardVm: DashboardVm = null;
  needUpdatePortalScalingContext: boolean = false;

  scaleContextValues: IScalable[] = [];

  constructor(tilePageId: string, pubAppId: string, dashboardVm: DashboardVm) {
    this.id = tilePageId;
    this.pubAppId = pubAppId;
    this.name = dashboardVm?.dashboardFm?.name ?? null;
    this.dashboardVm = dashboardVm;
  }

  get isInError(): boolean {
    return this.dashboardVm.error !== null;
  }

  isVsSyncReady(): boolean {
    return this.dashboardVm.isVsSyncReady;
  }

  clone(): ITilePageVm {
    const cloneDbSettings = this.dashboardVm.dbSettings.clone();
    const cloneDashboard = dashboardBuilder.clone(this.dashboardVm, cloneDbSettings);
    const clone = new DashboardPageVm(this.id, this.pubAppId, cloneDashboard);

    clone.isInitialized = this.isInitialized;
    clone.scaleContextValues = this.scaleContextValues;
    clone.dashboardVm.sharedState.sparklineState.disableAnimationOnce();

    return clone;
  }

  getVsRoot(): VsRoot {
    return this.dashboardVm;
  }
}
