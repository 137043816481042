<script lang="ts">
import { defineComponent, PropType } from "vue";
import BubbleText from "@/features/dashboard/helper-components/bubble-text.vue";

export class BubblesBarCommon {
  static bubbleTextClicked = "bubbleTextClicked";
}
export default defineComponent({
  components: { BubbleText },
  emits: [],
  props: {
    bubbles: { type: Array as PropType<string[]>, required: true },
  },
  setup(props, context) {
    //
    // Life Cycle:
    // --------------------
    //
    // Computeds:
    // --------------------
    //
    // Functions:
    // --------------------
    function onBubbleTextClicked(index: number): void {
      context.emit(BubblesBarCommon.bubbleTextClicked, index);
    }
    //
    // Watcher:
    // --------------------
    return {
      onBubbleTextClicked,
    };
  },
});
</script>
<template>
  <div class="bubbles-bar">
    <BubbleText
      v-for="(bubble, index) in $props.bubbles"
      v-bind:key="index"
      v-bind:text="bubble"
      v-on:click.native="onBubbleTextClicked(index)"
    />
  </div>
</template>
<style scoped lang="less">
.bubbles-bar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;
  margin-top: 4px;
}
</style>
