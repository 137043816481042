<script lang="ts">
import { PropType, defineComponent } from "vue";
import WizardButton from "./wizard-button.vue";
import { AppWizardCommon } from "../app-wizard-common";
import { ButtonVm } from "../view-models/modelling/button-vm";

export default defineComponent({
  components: {
    WizardButton,
  },

  props: {
    buttonList: {
      type: Array as PropType<ButtonVm[]>,
      required: true,
    },
    areButtonsDeletable: { type: Boolean, required: false, default: false },
  },

  setup(props, context) {
    async function xIconClicked(buttonId: string) {
      context.emit(AppWizardCommon.events.xIconClicked, buttonId);
    }

    return { xIconClicked };
  },
});
</script>

<template>
  <div class="wizard-button-list">
    <TransitionGroup name="button-list" class="transition-wrapper">
      <WizardButton
        v-for="button in $props.buttonList"
        v-bind:key="button.id"
        v-bind:buttonVm="button"
        v-bind:areButtonsDeletable="$props.areButtonsDeletable"
        v-on:xIconClicked="xIconClicked"
        class="button-list-item"
      />
    </TransitionGroup>
  </div>
</template>

<style lang="less" scoped>
@import "../../../common/styles/base-styles.less";

.wizard-button-list {
  .transition-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
    gap: 12px;
  }

  .button-list-enter,
  .button-list-leave-to {
    opacity: 0;
    transform: translateY(5px);
  }

  .button-list-item {
    transition: all 0.4s;
  }
}
</style>
