export type DashboardStatusType =
  | "OneValueNoSparklines"
  | "ThreeValuesNoSparklinesScaleIndexGreaterZero"
  | "ThreeValuesNoSparklinesScaleIndexZero"
  | "NotExtendedAndSparklines"
  | "ExtendedOneValueNoSparklines"
  | "ExtendedNoSparklines"
  | "ExtendedAndSparklines"
  | "ExtendedHalfCompactAndSparklines"
  | "ExtendedCompactOneValueNoSparklines"
  | "ExtendedCompactNoSparklines"
  | "ExtendedCompactAndSparklines";

export class DashboardStatus {
  static getConfig(
    numValues: number,
    scaleIndex: number,
    showSparklines: boolean,
    isExtended: boolean,
    isCompact: boolean,
    isCompactAllowed: boolean
  ): DashboardStatusType {
    return isExtended
      ? this._getExtendedStatus(numValues, showSparklines, isCompact, isCompactAllowed)
      : this._getNotExtendedStatus(numValues, scaleIndex, showSparklines);
  }

  private static _getNotExtendedStatus(
    numValues: number,
    scaleIndex: number,
    showSparklines: boolean
  ): DashboardStatusType {
    if (showSparklines) {
      return "NotExtendedAndSparklines";
    }

    if (numValues <= 1) {
      return "OneValueNoSparklines";
    }

    return scaleIndex > 0
      ? "ThreeValuesNoSparklinesScaleIndexGreaterZero"
      : "ThreeValuesNoSparklinesScaleIndexZero";
  }

  private static _getExtendedStatus(
    numValues: number,
    showSparklines: boolean,
    isCompact: boolean,
    isCompactAllowed: boolean
  ): DashboardStatusType {
    if (showSparklines) {
      return this._getExtendedSparklinesStatus(isCompact, isCompactAllowed);
    }

    if (isCompact && isCompactAllowed) {
      return numValues <= 1
        ? "ExtendedCompactOneValueNoSparklines"
        : "ExtendedCompactNoSparklines";
    } else {
      return numValues <= 1 ? "ExtendedOneValueNoSparklines" : "ExtendedNoSparklines";
    }
  }
  private static _getExtendedSparklinesStatus(
    isCompact: boolean,
    isCompactAllowed: boolean
  ): DashboardStatusType {
    if (isCompact) {
      return isCompactAllowed
        ? "ExtendedCompactAndSparklines"
        : "ExtendedHalfCompactAndSparklines";
    }

    return "ExtendedAndSparklines";
  }

  static isExtended(dashboardStatusType: DashboardStatusType): boolean {
    return (
      this.isExtendedNotCompact(dashboardStatusType) ||
      this.isExtendedCompact(dashboardStatusType)
    );
  }

  static isExtendedNotCompact(dashboardStatusType: DashboardStatusType): boolean {
    return (
      dashboardStatusType === "ExtendedNoSparklines" ||
      dashboardStatusType === "ExtendedAndSparklines" ||
      dashboardStatusType === "ExtendedOneValueNoSparklines" ||
      dashboardStatusType === "ExtendedHalfCompactAndSparklines"
    );
  }

  static isExtendedCompact(dashboardStatusType: DashboardStatusType): boolean {
    return (
      dashboardStatusType === "ExtendedCompactNoSparklines" ||
      dashboardStatusType === "ExtendedCompactOneValueNoSparklines" ||
      dashboardStatusType === "ExtendedCompactAndSparklines"
    );
  }
}
