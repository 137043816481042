<script lang="ts">
import { defineComponent, computed, Ref, ref } from "vue";
import { useHoverHelper } from "@/common/composables/hover-helper.cpsl";

export default defineComponent({
  props: {
    initialColor: { type: String, required: true },
    hoverColor: { type: String, default: null },
    largeIcon: { type: Boolean, default: false },
  },

  setup(props) {
    const ref_el: Ref<HTMLDivElement> = ref(null);
    const hoverHelperCpsl = useHoverHelper(ref_el);

    const colorVars = computed(() => {
      const isHover = props.hoverColor !== null && hoverHelperCpsl.isHover.value;
      return {
        "--stroke-color": isHover ? props.hoverColor : props.initialColor,
      };
    });

    return {
      ref_el,
      colorVars,
    };
  },
});
</script>

<template>
  <div ref="ref_el" class="sparklineIcon">
    <svg
      v-if="largeIcon"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 160.769 121.99"
      style="enable-background: new 0 0 160.769 121.99"
      xml:space="preserve"
      v-bind:style="colorVars"
    >
      <polyline points="104.846,31.532 55.923,31.532 55.923,121.99 "></polyline>
      <polyline points="55.923,56.064 7,56.064 7,121.99 "></polyline>
      <polyline points="104.846,121.99 104.846,7 153.769,7 153.769,121.99 "></polyline>
    </svg>
    <svg
      v-else
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 160.769 121.99"
      style="enable-background: new 0 0 160.769 121.99"
      xml:space="preserve"
      v-bind:style="colorVars"
    >
      <polyline points="55.923,121.99 55.923,90.596 104.846,90.596 " />
      <polyline points="55.923,105.128 7,105.128 7,121.99 " />
      <polyline points="104.846,121.99 104.846,76.064 153.769,76.064 153.769,121.99 " />
    </svg>
  </div>
</template>

<style lang="less" scoped>
.sparklineIcon {
  height: 100%;
  cursor: pointer;
  svg {
    stroke: var(--stroke-color);
    stroke-width: 16;
    stroke-miterlimit: 10;
    height: 100%;
    fill: none;
  }
}
</style>
