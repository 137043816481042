export class SwipeDetectionHelper {
  private _startingX: number = null;
  private _startingY: number = null;
  private _isVerticalSwipe: boolean = false;
  private _isHorizontalSwipe: boolean = false;

  get startingX(): number {
    return this._startingX;
  }

  get isVerticalSwipe(): boolean {
    return this._isVerticalSwipe;
  }

  get isHorizontalSwipe(): boolean {
    return this._isHorizontalSwipe;
  }

  setStartPosition(x: number, y: number) {
    this._startingX = x;
    this._startingY = y;
    this._isVerticalSwipe = false;
    this._isHorizontalSwipe = false;
  }

  setEndPosition(x: number, y: number): void {
    if (this.isVerticalSwipe || this.isHorizontalSwipe) {
      return;
    }

    const deltaX = Math.abs(this._startingX - x);
    const deltaY = Math.abs(this._startingY - y);
    this._isVerticalSwipe = deltaY > deltaX * 0.6;
    this._isHorizontalSwipe = deltaX > deltaY * 0.6;
  }
}
