<script lang="ts">
import { computed, defineComponent } from "vue";
import type { CSSProperties } from "vue/types/jsx";

export default defineComponent({
  props: {
    mainColor: {
      type: String,
      default: "#e6e6e6",
    },
    svgHeight: {
      type: String,
      default: "11px",
    },
    hoverColor: { type: String, default: null },
  },

  setup(props) {
    const colorVars = computed<CSSProperties>(() => {
      // if no hovColor defined disable hover by using mainColor during hover
      const hoverColor = props.hoverColor ?? props.mainColor;

      return {
        "--main-color": props.mainColor,
        "--hover-color": hoverColor,
      };
    });

    const heightVars = computed<CSSProperties>(() => {
      const svgHeight = props.svgHeight;
      return {
        "--svg-height": svgHeight,
      };
    });

    return {
      colorVars,
      heightVars,
    };
  },
});
</script>

<template>
  <div class="app-wizard-icon" v-bind:style="[colorVars, heightVars]">
    <svg
      viewBox="0 0 61.68602 5.9639773"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <defs />
      <g transform="translate(-46.433618,-145.3303)">
        <g transform="matrix(0.26458333,0,0,0.26458333,46.433618,143.72639)">
          <g>
            <path
              d="m 131.119,18.908 h -12.613 c -2.691,0 -3.697,0.2 -4.419,0.563 -0.722,0.363 -1.16,0.887 -1.379,1.474 -0.219,0.586 -0.219,1.235 -0.024,1.835 0.194,0.6 0.583,1.153 1.339,1.508 0.756,0.356 1.88,0.515 4.483,0.515 h 12.613 z M 128.78,9.877 H 110.217 V 6.076 h 19.581 c 3.259,0 5.902,2.466 5.902,5.509 v 17.018 h -18.083 c -2.444,0 -4.464,-0.024 -6.303,-0.908 -1.839,-0.884 -3.496,-2.628 -3.335,-6.057 0.129,-2.182 1.193,-3.878 2.805,-4.966 1.612,-1.087 3.772,-1.565 8.048,-1.565 H 131.12 V 12.06 c -0.001,-1.206 -1.048,-2.183 -2.34,-2.183"
            />
            <path
              d="m 82.836,6.08 c -4.302,-0.022 -5.996,1.375 -6.89,2.948 -0.894,1.572 -0.987,3.32 -0.281,4.833 0.707,1.513 2.213,2.791 6.337,3.453 4.465,0.727 8.93,1.453 13.396,2.18 2.662,0.433 3.389,1.91 3.141,3.128 -0.247,1.217 -1.469,2.176 -3.743,2.176 -6.161,0.001 -12.499,0.002 -18.836,0.003 v 3.801 c 6.338,0 12.675,-0.001 19.504,-0.002 3.786,-0.021 5.953,-1.654 6.98,-3.446 1.027,-1.792 0.912,-3.743 0.144,-5.454 -0.768,-1.711 -2.188,-3.184 -6.403,-3.937 -4.597,-0.748 -9.194,-1.496 -13.791,-2.244 -1.658,-0.27 -2.467,-0.601 -2.688,-1.444 -0.221,-0.842 0.145,-2.196 2.408,-2.196 h 19.708 V 6.08 Z"
            />
            <polygon
              points="205.151,28.603 233.144,28.603 233.144,24.802 212.737,24.802 233.144,9.395 233.144,6.062 205.754,6.062 205.754,9.862 226.027,9.88 205.151,25.641 "
            />
            <polygon
              points="189.769,28.603 189.769,9.862 201.173,9.862 201.173,6.062 173.784,6.062 173.784,9.862 185.188,9.862 185.188,28.603 "
            />
            <polygon
              points="141.361,28.603 145.942,28.603 145.942,11.324 165.059,28.603 169.354,28.603 169.354,6.062 164.773,6.062 164.773,23.055 145.973,6.062 141.361,6.062 "
            />
            <path
              d="m 50.396,6.08 c -4.3,-0.022 -5.994,1.375 -6.888,2.948 -0.893,1.572 -0.987,3.32 -0.281,4.833 0.706,1.513 2.213,2.791 6.335,3.453 4.464,0.727 8.928,1.453 13.392,2.18 2.661,0.433 3.388,1.91 3.14,3.128 -0.247,1.217 -1.469,2.176 -3.741,2.176 -6.159,0.001 -12.495,0.002 -18.83,0.003 v 3.801 c 6.336,0 12.672,-0.001 19.498,-0.002 3.785,-0.021 5.952,-1.654 6.978,-3.446 1.026,-1.792 0.912,-3.743 0.144,-5.454 -0.767,-1.711 -2.188,-3.184 -6.401,-3.937 -4.596,-0.748 -9.191,-1.496 -13.787,-2.244 -1.658,-0.27 -2.466,-0.601 -2.687,-1.444 -0.221,-0.842 0.145,-2.196 2.407,-2.196 H 69.378 V 6.08 Z"
            />
            <rect x="32.827" y="6.0619998" width="4.5809999" height="22.541" />
            <path
              d="m 4.581,9.879 v 5.228 h 13.316 c 0.277,0 0.555,-0.017 0.829,-0.06 0.23,-0.035 0.458,-0.088 0.679,-0.164 0.186,-0.064 0.367,-0.145 0.537,-0.247 0.147,-0.087 0.285,-0.191 0.411,-0.31 0.113,-0.107 0.215,-0.227 0.302,-0.36 0.083,-0.126 0.153,-0.262 0.209,-0.405 0.113,-0.291 0.165,-0.609 0.166,-0.926 10e-4,-0.335 -0.055,-0.67 -0.169,-0.981 -0.058,-0.16 -0.132,-0.313 -0.22,-0.456 C 20.549,11.048 20.44,10.909 20.32,10.785 20.188,10.648 20.041,10.528 19.884,10.426 19.705,10.309 19.512,10.214 19.315,10.139 19.08,10.05 18.838,9.988 18.592,9.948 18.298,9.898 18,9.879 17.702,9.879 Z m 0,9.029 v 5.894 h 14.798 c 0.312,0 0.624,-0.019 0.933,-0.066 0.26,-0.039 0.517,-0.098 0.767,-0.183 0.211,-0.072 0.416,-0.162 0.61,-0.277 0.167,-0.099 0.326,-0.215 0.469,-0.35 0.129,-0.122 0.246,-0.258 0.346,-0.407 0.095,-0.142 0.176,-0.297 0.239,-0.459 0.129,-0.329 0.189,-0.688 0.189,-1.047 C 22.933,21.635 22.869,21.258 22.74,20.907 22.674,20.726 22.59,20.553 22.489,20.392 22.384,20.223 22.26,20.066 22.123,19.926 21.972,19.772 21.805,19.637 21.626,19.522 21.422,19.39 21.204,19.284 20.98,19.2 20.715,19.101 20.44,19.032 20.163,18.986 19.832,18.931 19.496,18.909 19.161,18.91 H 4.581 Z M 0,28.603 V 6.078 h 18.4 c 0.502,0 1.004,0.026 1.502,0.086 0.483,0.058 0.962,0.147 1.427,0.273 0.463,0.125 0.912,0.286 1.338,0.485 0.425,0.198 0.826,0.433 1.19,0.705 0.362,0.269 0.686,0.574 0.968,0.905 0.274,0.323 0.506,0.671 0.688,1.038 0.175,0.35 0.304,0.716 0.39,1.09 0.079,0.347 0.119,0.7 0.123,1.053 0.018,2.183 -1.041,3.871 -2.244,5.061 2.515,1.203 4.017,3.088 3.999,5.652 -0.003,0.387 -0.044,0.774 -0.123,1.154 -0.085,0.409 -0.215,0.811 -0.39,1.195 -0.183,0.401 -0.415,0.783 -0.689,1.137 -0.281,0.364 -0.606,0.698 -0.967,0.993 -0.364,0.297 -0.766,0.555 -1.191,0.773 -0.426,0.218 -0.875,0.394 -1.338,0.531 -0.465,0.138 -0.944,0.236 -1.427,0.299 -0.497,0.066 -1,0.094 -2.91,0.094 H 0 Z"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<style lang="less" scoped>
@import "../..//styles/base-styles.less";

.app-wizard-icon {
  display: flex;
  align-items: center;
  cursor: inherit;

  svg {
    fill: var(--main-color);
    height: var(--svg-height);

    &:hover {
      fill: var(--hover-color);
    }
  }
}
</style>
