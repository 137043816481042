import { DashboardStatusType } from "@/features/dashboard/status-configs/dashboard-status";
import { Cell } from "@/common/components/simple-grid/contracts/cell";
import {
  getSharedDashboardCellWidths,
  getSharedDashboardSeparatorWidths,
} from "@/features/dashboard/status-configs/shared-widths";
import { GridIds as gridIds } from "@/features/dashboard/status-configs/grid-ids";

// Use any CSS grid unit (px, %, fr, auto) for widths and heights.
// Specify an integer for colSpan/rowSpan to indicate the number of columns/rows to span.
// Set colSpan/rowSpan to -1 to use all available columns/rows.

export class StatusConfigElement {
  static getCells(dashboardStatusType: DashboardStatusType): Cell[] {
    switch (dashboardStatusType) {
      case "OneValueNoSparklines":
        return [{ id: gridIds.titleValue, row: 1, col: 1, colSpan: -1 }];

      case "ThreeValuesNoSparklinesScaleIndexGreaterZero":
      case "ThreeValuesNoSparklinesScaleIndexZero":
        return [
          { id: gridIds.titleValue, row: 1, col: 1, colSpan: 2 },
          { id: gridIds.deltaValues, row: 1, col: 5 },
        ];

      case "NotExtendedAndSparklines":
        return [
          { id: gridIds.titleValue, row: 1, col: 1 },
          { id: gridIds.sparkline, row: 1, col: 3, padding: "6px 0" },
          { id: gridIds.valueGroupValue, row: 1, col: 5, padding: "0 10px 0 5px" },
        ];

      case "ExtendedOneValueNoSparklines":
      case "ExtendedCompactOneValueNoSparklines":
        return [{ id: gridIds.titleValue, row: 1, col: 1, colSpan: 2 }];

      case "ExtendedNoSparklines":
      case "ExtendedCompactNoSparklines":
        return [
          { id: gridIds.titleValue, row: 1, col: 1, colSpan: 2 },
          { id: gridIds.deltaValue_1, row: 1, col: 5, padding: "0 10px 0 5px" },
          { id: gridIds.deltaValue_2, row: 1, col: 7, padding: "0 24px 0 5px" },
        ];

      case "ExtendedAndSparklines":
      case "ExtendedHalfCompactAndSparklines":
      case "ExtendedCompactAndSparklines":
        return [
          { id: gridIds.titleValue, row: 1, col: 1 },
          { id: gridIds.sparkline, row: 1, col: 3, padding: "6px 0" },
          { id: gridIds.valueGroupValue, row: 1, col: 5, padding: "0 10px 0 5px" },
          { id: gridIds.deltaValue_1, row: 1, col: 7, padding: "0 10px 0 5px" },
          { id: gridIds.deltaValue_2, row: 1, col: 9, padding: "0 24px 0 5px" },
        ];
    }
  }

  // The widths are shared with the ValueGroup
  static getCellWidths(dashboardStatusType: DashboardStatusType): string[] {
    return getSharedDashboardCellWidths(dashboardStatusType);
  }

  // The separator widths (separatorStrokeWidth) needs to be considered too
  static getSeparatorWidths(dashboardStatusType: DashboardStatusType): string[] {
    return getSharedDashboardSeparatorWidths(dashboardStatusType);
  }

  // There is not a getCellHeights or similar because elements have only one
  // height and this height does not change. It is specified in the html template.
}
