<script lang="ts">
import { defineComponent, computed, PropType, Component } from "vue";
import type { CSSProperties } from "vue/types/jsx";

export class ContextMenuItemEvents {
  static menuItemClicked = "menuItemClicked";
}

export default defineComponent({
  components: {},
  emits: [ContextMenuItemEvents.menuItemClicked],
  props: {
    icon: { type: Object as PropType<Component>, default: null },
    smallIcon: { type: Boolean, default: false },
    text: { type: String, default: null },
    enabled: { type: Boolean, default: true },
    showBottomLine: { type: Boolean, default: true },
  },
  setup(props, context) {
    //
    // Life Cycle:
    // --------------------

    //
    // Computeds:
    // --------------------
    const contextMenuItemStyle = computed<CSSProperties>(() => {
      return {
        "--menu-item-icon-height": props.smallIcon ? "25px" : "34px",
        "--menu-item-icon-width": props.smallIcon ? "34px" : "40px",
        "--menu-item-font-color": "#A7A7A7",
      };
    });

    //
    // Functions:
    // --------------------
    function onMenuItemClick(): void {
      context.emit(ContextMenuItemEvents.menuItemClicked);
    }

    //
    // Watcher:
    // --------------------
    return { contextMenuItemStyle, onMenuItemClick };
  },
});
</script>

<template>
  <div
    class="context-menu-item"
    v-on:click="onMenuItemClick"
    v-bind:style="contextMenuItemStyle"
  >
    <div class="menu-item-wrapper" v-bind:class="{ 'is-enabled': $props.enabled }">
      <component
        class="menu-item-icon"
        v-if="$props.icon"
        v-bind:is="$props.icon"
        v-bind:color="'var(--menu-item-font-color)'"
      />
      <div
        v-if="$props.text"
        class="menu-item-text"
        v-bind:class="{ 'left-margin': !$props.icon }"
      >
        {{ $props.text }}
      </div>
    </div>
    <div class="menu-item-line-bottom" v-if="$props.showBottomLine" />
  </div>
</template>

<style scoped lang="less">
.context-menu-item {
  .menu-item-wrapper {
    display: flex;
    align-items: center;
    height: var(--menu-item-icon-height);
    color: var(--menu-item-font-color);

    .menu-item-icon {
      flex: 1;
      padding: 6px;
      max-width: var(--menu-item-icon-width);
      min-width: var(--menu-item-icon-width);
      box-sizing: border-box;
    }

    .menu-item-text {
      padding: 0 30px 0 2px;
    }

    &.is-enabled:hover {
      cursor: pointer;
      --menu-item-font-color: #4d4d4d;
    }
  }

  .menu-item-line-bottom {
    height: 1px;
    flex-grow: 1;
    --menu-item-line-spacer: 10px;
    margin-left: var(--menu-item-line-spacer);
    margin-right: var(--menu-item-line-spacer);
    background-color: var(--color_mild-gray);
  }
}
</style>
