<script lang="ts">
import HideAndShow from "@/common/components/hide-and-show.vue";
import { ValueGroupVm } from "../view-models/value-group-vm";
import { PropType, defineComponent } from "vue";

export default defineComponent({
  components: { HideAndShow },

  props: {
    valueGroupVm: { type: Object as PropType<ValueGroupVm>, required: true },
    titleTextColor: { type: String, default: "" },
  },

  setup() {
    //
    // Life Cycle:
    // --------------------

    //
    // Computeds:
    // --------------------

    //
    // Functions:
    // --------------------

    //
    // Watcher:
    // --------------------

    return {};
  },
});
</script>

<template>
  <div class="dashboard-title" v-bind:style="{ color: $props.titleTextColor }">
    <HideAndShow>
      {{ $props.valueGroupVm.title }}
    </HideAndShow>
  </div>
</template>

<style lang="less">
@import "../../../common/styles/fonts.less";

.dashboard-title {
  display: flex;
  align-items: center;
  color: var(--color_neutralText);
  background-color: var(--color_bg_white);
  font-size: 1.45em;
  font-family: @fontSemi;
  height: var(--titleHeight);
  border-bottom: solid 1px var(--color_bg_white);
  padding-left: 5px;
}
</style>
