import { SimpleResult } from "@/common/results/simple-result";
import {
  IPortalsServiceClient,
  PortalTilePageViewStateDto,
  IDashboardPortalTilePageViewStateDto,
  DashboardPortalTilePageViewStateDto,
} from "@/common/service-clients/generated-clients";

export class ViewStateFacade {
  private _portalServiceClient: IPortalsServiceClient;

  constructor(portalServiceClient: IPortalsServiceClient) {
    this._portalServiceClient = portalServiceClient;
  }

  async getDashboardVsAsync(
    dashboardVsId: string
  ): Promise<SimpleResult<IDashboardPortalTilePageViewStateDto, object | string>> {
    const result: SimpleResult<IDashboardPortalTilePageViewStateDto, object | string> = {
      value: null,
      error: null,
    };
    const portalTilePageViewStateId = dashboardVsId;
    try {
      const dto = await this._portalServiceClient.getPortalTilePageViewState(
        portalTilePageViewStateId
      );
      result.value = dto as DashboardPortalTilePageViewStateDto;
    } catch (e) {
      result.error = e;
    }

    return result;
  }

  async updateDashboardVsAsync(
    vsDto: PortalTilePageViewStateDto
  ): Promise<SimpleResult<IDashboardPortalTilePageViewStateDto, object | string>> {
    const result: SimpleResult<IDashboardPortalTilePageViewStateDto, object | string> = {
      value: null,
      error: null,
    };
    try {
      result.value = (await this._portalServiceClient.updatePortalTilePageViewState(
        vsDto.id,
        vsDto
      )) as unknown as IDashboardPortalTilePageViewStateDto;
    } catch (e) {
      result.error = e;
    }

    return result;
  }

  async createDashboardVsAsync(
    vsDto: PortalTilePageViewStateDto
  ): Promise<SimpleResult<IDashboardPortalTilePageViewStateDto, object | string>> {
    const result: SimpleResult<IDashboardPortalTilePageViewStateDto, object | string> = {
      value: null,
      error: null,
    };
    try {
      result.value = (await this._portalServiceClient.createPortalTilePageViewState(
        vsDto.portalTilePageId,
        vsDto.portalTileViewStateId,
        vsDto
      )) as unknown as IDashboardPortalTilePageViewStateDto;
    } catch (e) {
      result.error = e;
    }

    return result;
  }
}
