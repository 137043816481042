import { KpiDrillStructureFilterFm } from "@/features/dashboard/backend-wrapper/facade-models-dashboard";

export class DashboardFiltersListVm {
  private _structureFilters: KpiDrillStructureFilterFm[] = [];

  get filters(): KpiDrillStructureFilterFm[] {
    return this._structureFilters;
  }

  addStructureFilter(filter: KpiDrillStructureFilterFm): void {
    if (!filter || !filter.elementId || !filter.structureNameId)
      throw new Error("ERROR: Tried to apply an invalid filter to a dashboard.");

    if (!this.hasFilter(filter)) {
      this._structureFilters.push(filter);
    }
  }

  hasFilter(filter: KpiDrillStructureFilterFm): boolean {
    const foundFilter = this._structureFilters.find((currentFilter) =>
      currentFilter.equals(filter)
    );
    return !!foundFilter;
  }

  removeFilter(index: number): void {
    if (index < 0 || index >= this._structureFilters.length) {
      return;
    }

    this._structureFilters.splice(index, 1);
  }

  clone(): DashboardFiltersListVm {
    const result = new DashboardFiltersListVm();

    result._structureFilters = this._structureFilters.map((filter) => filter);

    return result;
  }
}
