import { isInRect } from "@/common/events/dom-event-helper";
import { TileVm } from "./tile-vm";
import {
  PlacementType,
  Tile,
  TileConfiguration,
  TileGrid,
  TileGridService,
  defaultTileConfiguration,
} from "@bissantz/tile-grid";
import { RequestError } from "@/common/request-error";
import { PortalPageVm } from "../view-models/portal-page-vm";
import { PortalTileBuilder } from "../backend-wrapper/tile-builder";
import { PortalFacade } from "../backend-wrapper/portal-facade";
import { IDisposable } from "@/common/disposable.interface";
import { IInputEventsService, KeyIds } from "@/services/input-events-service.interface";

import { Ref, inject, onBeforeUnmount, onMounted, reactive, nextTick } from "vue";
import { VsScopeState } from "@/services/view-state-service/vs-scope-state";

class TileCloningState {
  disposableListeners: IDisposable[] = [];
  isCloningEnabled: boolean = false;
  isCloneDragging: boolean = false;
  isNormalDragging: boolean = false;
  cursorPos = { x: 0, y: 0 };

  toBeCloned: TileVm = null;
  cloneTileConfiguration: TileConfiguration = Object.assign(
    {},
    defaultTileConfiguration,
    {
      x: 0,
      y: 0,
      h: 0,
      w: 0,
      order: 0,
      i: "bissantzService-cloneTile",
      isResizable: false,
      placement: "ignore" as PlacementType,
    }
  );
  isCloning: boolean = false;
}

export function useTileCloning(
  portalPageVm: PortalPageVm,
  gridLayout: Ref<TileConfiguration[]>,
  tileGridService: Ref<TileGridService>,
  ref_tileGrid: Ref<InstanceType<typeof TileGrid>>,
  ref_cloneDragArea: Ref<HTMLDivElement>,
  ref_cloneTile: Ref<InstanceType<typeof Tile>>,
  isEditEnabled: Ref<boolean>
) {
  //
  // Injections
  // --------------------
  const portalFacade: PortalFacade = inject("portalFacade");
  const inputEventsService = inject("inputEventsService") as IInputEventsService;
  const vsScopeState = inject<VsScopeState>("vsScopeState", null);

  // State:
  // --------------------
  const state = reactive(new TileCloningState());

  // Non-reactives:
  // --------------------
  const tileBuilder = new PortalTileBuilder();

  // Lifecycle:
  // --------------------
  onMounted(async () => {
    window.addEventListener("blur", handleTabFocusLost);
    state.disposableListeners.push(
      inputEventsService.getkeyChangedEvent(KeyIds.control).on(handleCtrlDownChanged)
    );
    state.disposableListeners.push(
      inputEventsService.getkeyChangedEvent(KeyIds.esc).on(handleEscDownChanged)
    );
  });

  onBeforeUnmount(() => {
    state.disposableListeners.map((disposer) => disposer.dispose());
    window.removeEventListener("blur", handleTabFocusLost);
  });

  // Methods:
  // --------------------
  // START: Cloning by mouse
  function handleMouseenter_AtTile(tileVm: TileVm) {
    if (!state.isCloningEnabled) return;
    if (state.isNormalDragging) return;
    if (state.isCloneDragging) return;
    if (tileVm.canClone) {
      _updateCloneTile(tileVm);
    } else {
      _deactivateCloneTile();
    }
  }

  function handleDragmove_AtCloneTile(): void {
    if (!state.isCloningEnabled) return;
    if (state.isCloneDragging) return;
    if (!state.toBeCloned) return;

    state.isCloneDragging = true;

    _copyHtml();
  }

  function _copyHtml(): void {
    // TODO: hardcoded css selector: not good/stable
    const cssSelector = `[data-id='${state.toBeCloned.id}'] > .portal-tile-component`;
    const toBeClonedHtml = document.querySelector(cssSelector);

    const cloneElement = ref_cloneDragArea.value;
    cloneElement.innerHTML = toBeClonedHtml.outerHTML;

    // copy every canvas context:
    const origCanvasList = document.querySelectorAll(
      cssSelector + " canvas"
    ) as NodeListOf<HTMLCanvasElement>;
    if (origCanvasList.length < 1) {
      return;
    }
    const cloneCanvasList = cloneElement.querySelectorAll("canvas");
    for (let idx = 0; idx < origCanvasList.length; idx++) {
      const origCanv = origCanvasList[idx];
      const cloneContext = cloneCanvasList[idx].getContext("2d");
      cloneContext.drawImage(origCanv, 0, 0);
    }
  }

  async function handleDragend_AtCloneTile(): Promise<void> {
    if (!state.isCloneDragging) {
      _deactivateCloneTile();
      return;
    }

    state.isCloneDragging = false;
    const cloneElement = ref_cloneDragArea.value;
    cloneElement.innerHTML = null;

    const currentClone = tileBuilder.clone(state.toBeCloned as TileVm);
    setCloneName(currentClone);
    currentClone.disableTransitions = true;

    copyTilePositionAndSize(currentClone.tileConfig, state.cloneTileConfiguration);
    _deactivateCloneTile();
    currentClone.tileConfig.placement = "normal" as PlacementType;

    const { hasError } = await addTileToPortal(currentClone);
    if (hasError) {
      return;
    }

    setTimeout(() => {
      currentClone.disableTransitions = false;
      currentClone.animationsEnabled = true;
      tileGridService.value.correctLayout.bind(tileGridService.value)();
    }, 100);
    state.toBeCloned.tileConfig.placement = "normal" as PlacementType;
    state.toBeCloned.animationsEnabled = true;
    state.toBeCloned = null;
  }

  async function handleMousedown_AtCloneTile(ev: MouseEvent): Promise<void> {
    if (!state.isCloningEnabled) return;
    if (!state.toBeCloned.canClone) return;

    state.cloneTileConfiguration.placement = "normal" as PlacementType;
    state.toBeCloned.tileConfig.placement = "static" as PlacementType;

    state.toBeCloned.animationsEnabled = false;

    await nextTick();
    _transitionEventToCloneTile(ev);
  }

  function handleMousedown_AtTile(ev: MouseEvent): void {
    if (state.isCloningEnabled) return;
    if (state.isCloneDragging) return;
    if (!ev.ctrlKey) return;

    _activateCloneTile();
    if (!state.toBeCloned?.canClone) return;

    state.toBeCloned.tileConfig.placement = "static" as PlacementType;
    state.toBeCloned.animationsEnabled = false;

    _transitionEventToCloneTile(ev);
  }

  function _transitionEventToCloneTile(ev: MouseEvent): void {
    const cloneElement = ref_cloneDragArea.value;
    cloneElement.dispatchEvent(
      new PointerEvent("pointerdown", {
        bubbles: true,
        pointerType: "mouse",
        clientX: ev?.clientX,
        clientY: ev?.clientY,
      })
    );
  }

  function handleDragend_AtTile(): void {
    state.isNormalDragging = false;
  }

  function handleCtrlDownChanged(ctrlIsDown: boolean): void {
    if (!portalPageVm?.tiles?.length) return;

    const isLoading = !portalPageVm || portalPageVm.isLoading;
    state.isCloningEnabled = ctrlIsDown && !isLoading;

    if (state.isCloningEnabled && !state.isNormalDragging && !portalPageVm.isLoading) {
      _activateCloneTile();
    } else if (!state.isCloneDragging) {
      _deactivateCloneTile();
    }
  }

  function handleEscDownChanged(isEscDown: boolean): void {
    if (!isEscDown) return;

    cancelCloning();
  }

  function handleTabFocusLost() {
    cancelCloning();

    state.isCloningEnabled = false;
  }
  // END: Cloning by mouse

  function cancelCloning() {
    if (!state.isCloneDragging) return;
    if (!portalPageVm?.tiles?.length) return;

    state.isCloneDragging = false;
    const cloneElement = ref_cloneDragArea.value;
    cloneElement.innerHTML = null;

    _deactivateCloneTile();

    if (!state.toBeCloned) return;

    state.toBeCloned.tileConfig.placement = "normal" as PlacementType;
    state.toBeCloned.animationsEnabled = true;
    state.toBeCloned = null;
    setTimeout(tileGridService.value.correctLayout.bind(tileGridService.value), 100);
  }

  function _activateCloneTile(): void {
    const hoveredTile = _getHoveredTile();
    if (!hoveredTile) return;
    if (!hoveredTile.vm?.canClone) return;

    _updateCloneTile(hoveredTile.vm);
  }

  function _updateCloneTile(dbTileVm: TileVm) {
    copyTilePositionAndSize(state.cloneTileConfiguration, dbTileVm.tileConfig);
    (state.toBeCloned as TileVm) = dbTileVm;
  }

  function _deactivateCloneTile() {
    copyTilePositionAndSize(state.cloneTileConfiguration, null);
    state.cloneTileConfiguration.placement = "ignore" as PlacementType;
  }

  function handleMousemove_OnGrid(ev: MouseEvent): void {
    state.cursorPos.x = ev.clientX;
    state.cursorPos.y = ev.clientY;
  }

  function handleMouseleave_OnGrid(): void {
    if (!state.isCloneDragging) return;

    ref_cloneTile.value.$el.dispatchEvent(
      new PointerEvent("pointerup", { bubbles: true, pointerType: "mouse" })
    );
    handleDragend_AtCloneTile();
  }

  function _getHoveredTile(): { vm: TileVm; elem: HTMLElement } {
    const tgElement = ref_tileGrid.value.$el;
    const tiles = Array.from(
      tgElement.querySelectorAll(".bissantz-tile[data-id]")
    ) as HTMLElement[];
    const tileMaps = tiles.map((tile) => {
      return { id: tile.dataset.id, elem: tile };
    });

    const hitMap = tileMaps.filter((map) =>
      isInRect(state.cursorPos.x, state.cursorPos.y, map.elem.getBoundingClientRect())
    );
    let foundTile: TileVm = null;
    if (hitMap.length === 1) {
      const foundId = hitMap[0].id;
      foundTile = portalPageVm.tiles.find((tile) => tile.id === foundId);
      return { vm: foundTile, elem: hitMap[0].elem };
    }
  }

  function copyTilePositionAndSize(to: TileConfiguration, from?: TileConfiguration) {
    if (!from) {
      to.h = 0;
      to.w = 0;
      to.x = 0;
      to.y = 0;
    } else {
      to.h = from.h;
      to.w = from.w;
      to.x = from.x;
      to.y = from.y;
    }
  }

  async function onDeleteTile(id: string, isTemporary: boolean): Promise<void> {
    let canRemoveVisible = true;
    if (!isTemporary) {
      const error = await removeData(id);
      canRemoveVisible = !error;
    }

    if (canRemoveVisible) {
      removeTileVisibly(id);
    }
  }

  async function removeData(id: string): Promise<RequestError> {
    let error: RequestError = null;

    const result = await vsScopeState.requestMutex.runExclusive(
      async () => await portalFacade.removeTileFromPortalDefinition(id)
    );
    error = result?.error;
    return error;
  }

  function removeTileVisibly(id: string): void {
    const configIdx = gridLayout.value.findIndex((l) => l.i === id);
    const tileIdx = portalPageVm.tiles.findIndex((tile) => tile.id === id);

    portalPageVm.tiles.splice(tileIdx, 1);
    gridLayout.value.splice(configIdx, 1);

    setTimeout(tileGridService.value.correctLayout.bind(tileGridService.value), 100);
  }

  function getAutomaticClonePosition(
    origConfig: TileConfiguration,
    maxCols: number
  ): { x: number; y: number } {
    const position: { x: number; y: number } = { x: origConfig.x, y: origConfig.y };

    const isRightSideFree = origConfig.x + 2 * origConfig.w <= maxCols;
    if (isRightSideFree) {
      position.x = origConfig.x + origConfig.w;
    }
    //place below
    else {
      position.y = origConfig.y + origConfig.h;
    }

    return position;
  }

  // if too many issues come up with this:
  // simply use getGUID() instead of this.
  // Current code is for more user friendly Tile-Names
  // than guids/uuids would provide.
  function setCloneName(originalTileVm: TileVm): void {
    const cloneText = "Clone-";
    const paddingLength = 7;
    const tileNames = portalPageVm.tiles.map((t) => t.name);

    // Prepare original name (remove prev. clone name)
    let originalName = originalTileVm.name;
    const isCloneName = originalName.endsWith(
      cloneText,
      originalName.length - paddingLength
    );
    if (isCloneName) {
      const lenOfText = cloneText.length + paddingLength;
      originalName = originalName.substring(0, originalName.length - lenOfText);
    }

    let cloneIdx = 1;

    const combineName = () =>
      originalName + " " + cloneText + cloneIdx.toString().padStart(paddingLength, "0");
    const isCombinedNameUnique = () => tileNames.every((name) => name !== combineName());

    const loopMax = 9999999;
    while (!isCombinedNameUnique() && cloneIdx < loopMax) {
      cloneIdx++;
    }

    if (!isCombinedNameUnique()) {
      throw Error("Couldn't clone: No unique name for tile was found.");
    }

    originalTileVm.name = combineName();
    originalTileVm.id = combineName();
  }

  async function addTileToPortal(cloneTileVm: TileVm): Promise<{ hasError: boolean }> {
    cloneTileVm.isTemporary = true;

    //TODO: currently disabled, make this (adding and deleting tiles) work properly
    // eslint-disable-next-line no-constant-condition, sonarjs/no-redundant-boolean
    if (isEditEnabled.value && false) {
      cloneTileVm.isTemporary = false;
      const error = await vsScopeState.requestMutex.runExclusive(
        async () => await portalFacade.addNewTileToPortal(portalPageVm.id, cloneTileVm)
      );
      if (error) {
        vsScopeState.isInError.value = true;
        return { hasError: true };
      }
    }

    cloneTileVm.tileConfig.i = cloneTileVm.id;

    portalPageVm.tiles.push(cloneTileVm);
    gridLayout.value.push(cloneTileVm.tileConfig);

    return { hasError: false };
  }

  async function onAutomaticClone(originalTileVm: TileVm) {
    if (state.isCloning) {
      return;
    }

    state.isCloning = true;
    const colNum = tileGridService.value.getGridColNumber();
    const newPosition = getAutomaticClonePosition(originalTileVm.tileConfig, colNum);

    const tileClone = tileBuilder.clone(originalTileVm);
    setCloneName(tileClone);
    tileClone.tileConfig.x = newPosition.x;
    tileClone.tileConfig.y = newPosition.y;

    tileClone.disableTransitions = true;
    tileClone.tileConfig.placement = "static" as PlacementType;
    originalTileVm.tileConfig.placement = "static" as PlacementType;

    const { hasError } = await addTileToPortal(tileClone);
    if (hasError) {
      state.isCloning = false;
      return;
    }

    setTimeout(() => {
      // Reorders other tiles not taking place in cloning
      tileGridService.value.correctLayout.bind(tileGridService.value)();
      tileClone.tileConfig.placement = "normal" as PlacementType;
      tileClone.disableTransitions = false;
      originalTileVm.tileConfig.placement = "normal" as PlacementType;
      setTimeout(() => {
        // Reorders all tiles (including clone and original tile)
        tileGridService.value.correctLayout.bind(tileGridService.value)(
          tileClone.tileConfig
        );
        state.isCloning = false;
      }, 100);
    }, 100);
  }

  return {
    // Reactives:
    state,

    // Methods:
    handleMouseenter_AtTile,
    handleDragmove_AtCloneTile,
    handleDragend_AtCloneTile,
    handleMousedown_AtCloneTile,
    handleMousedown_AtTile,
    handleDragend_AtTile,
    handleMousemove_OnGrid,
    handleMouseleave_OnGrid,
    onDeleteTile,
    onAutomaticClone,
  };
}
