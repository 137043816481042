import { DimensionRetriever } from "./dimension-retriever";
import { ElementNodeLoader } from "./element-node-loader";
import { ITreeNodeLoader } from "../trees/tree-node-loader.interface";
import { TreeNodeVm } from "../trees/tree-node-vm";
import { FailedReason } from "@/common/results/failed-reason";
import { ValueResult } from "@/common/results/value-result";

export class PeriodHierarchyNodeLoader implements ITreeNodeLoader {
  private readonly _dimensionRetriever: DimensionRetriever;
  private readonly _publishedApplicationId: string;

  constructor(dimensionRetriever: DimensionRetriever, publishedApplicationId: string) {
    this._dimensionRetriever = dimensionRetriever;
    this._publishedApplicationId = publishedApplicationId;
  }

  async loadAsync(_id: string): Promise<ValueResult<TreeNodeVm[], FailedReason>> {
    const loadPeriodResult = await this._dimensionRetriever.getPeriodAsync();

    if (loadPeriodResult.succeeded === false) {
      return ValueResult.createFromError(loadPeriodResult.failedReason);
    }

    const periodDimension = loadPeriodResult.value;

    const dimensionLoadResult = await this._dimensionRetriever.loadHierarchieLevelsAsync(
      periodDimension
    );

    if (dimensionLoadResult.succeeded === false) {
      return ValueResult.createFromError(dimensionLoadResult.failedReason);
    }

    const result = [];

    for (const level of dimensionLoadResult.value.allHierarchyLevels) {
      const treeItem = new TreeNodeVm(
        level.id,
        level.name,
        new ElementNodeLoader(
          this._dimensionRetriever,
          level,
          this._publishedApplicationId
        )
      );
      treeItem.value = level;

      result.push(treeItem);
    }

    return ValueResult.createFromValue(result);
  }
}
