import { Mutex } from "async-mutex";

// Idea: store up to two request, reject any additional
// requests, because: if a request runs it uses the last (most recent)
// VS anyways.
// This guarantees that all changes are transmitted
export class ProcessingQueue {
  private _queueMutex = new Mutex();
  private _requestMutex = new Mutex();
  private _toBeProcessed: (() => Promise<void>)[] = [];
  counter = 0;
  calledCounter = 0;

  async enqueue(func: () => Promise<void>): Promise<void> {
    const release = await this._queueMutex.acquire();
    if (this._toBeProcessed.length >= 2) {
      release();
    }

    this._toBeProcessed.push(func);

    this._process();
    release();
  }

  private async _process() {
    const release = await this._requestMutex.acquire();
    const func = this._toBeProcessed[0];
    if (!func) {
      release();
      return;
    }

    await func();
    this._toBeProcessed.shift();
    release();
    this._process();
  }
}
