export class DashboardSelection {
  private _kpiTileId: number = null;
  private _valueIndex: number = null;
  private _elementIndex: number[] = [];
  private _selectionColor: string = null;
  private _isBlocked: boolean = false;
  private _blockedTimeoutId: NodeJS.Timeout = null;
  private _isDashboardSelected: boolean = false;

  set isDashboardSelected(value: boolean) {
    this._isDashboardSelected = value;
    if (!value) {
      this._selectionColor = null;
      this._kpiTileId = null;
    }
  }

  get isDashboardSelected(): boolean {
    return this._isDashboardSelected;
  }

  set kpiTileId(value: number) {
    if (this.isBlocked) {
      return;
    }
    this._kpiTileId = value;
  }

  set selectionColor(value: string) {
    this._selectionColor = value;
  }

  get selectionColor(): string {
    return this._selectionColor;
  }

  get kpiTileId(): number {
    return this._kpiTileId;
  }

  get valueIndex(): number {
    return this._valueIndex;
  }

  get elementIndex(): number[] {
    return this._elementIndex.map((e) => e);
  }

  get isBlocked(): boolean {
    return this._isBlocked;
  }

  isKpiSelected(kpiTileId: number): boolean {
    return this.isDashboardSelected && this.kpiTileId === kpiTileId;
  }

  setSelectedKpi(kpiTileId: number): void {
    if (this.isBlocked) {
      return;
    }

    this.kpiTileId = kpiTileId;
  }

  blockSettingNewValues(): void {
    clearTimeout(this._blockedTimeoutId);
    this._isBlocked = true;
    this._blockedTimeoutId = setTimeout(() => {
      this._isBlocked = false;
    }, 900);
  }

  clearElement(): void {
    this._elementIndex.splice(0);
  }

  setElement(elementIndex: number) {
    this._elementIndex.push(elementIndex);
  }

  setKpiValue(valueIndex: number): void {
    this._valueIndex = valueIndex;
  }
}
