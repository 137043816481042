import {
  DashboardStatusType,
  DashboardStatus,
} from "@/features/dashboard/status-configs/dashboard-status";
import {
  getSharedDashboardCellWidths,
  getSharedDashboardSeparatorWidths,
  separatorStrokeWidth,
} from "@/features/dashboard/status-configs/shared-widths";
import { Cell, Separator } from "@/common/components/simple-grid/contracts/cell";
import { GridIds as gridIds } from "@/features/dashboard/status-configs/grid-ids";
import { DefaultStyles } from "@/common/styles/default-styles";

// Use any CSS grid unit (px, %, fr, auto) for widths and heights.
// Specify an integer for colSpan/rowSpan to indicate the number of columns/rows to span.
// Set colSpan/rowSpan to -1 to use all available columns/rows.

const sepColorWhite = DefaultStyles.colorConstants.bgWhite;
const sepColorGray = DefaultStyles.colorConstants.bgGray;

export class StatusConfigValueGroup {
  static getSeparatorWidths(dashboardStatusType: DashboardStatusType): string[] {
    return getSharedDashboardSeparatorWidths(dashboardStatusType);
  }

  static getCellWidths(dashboardStatusType: DashboardStatusType): string[] {
    return getSharedDashboardCellWidths(dashboardStatusType);
  }

  static getSeparatorHeights(dashboardStatusType: DashboardStatusType): string[] {
    if (DashboardStatus.isExtendedCompact(dashboardStatusType)) {
      return ["0", "0"];
    }
    if (DashboardStatus.isExtendedNotCompact(dashboardStatusType)) {
      return ["0", separatorStrokeWidth, separatorStrokeWidth, "0"];
    }
    return ["0", "0", separatorStrokeWidth, "0"];
  }

  static getCellHeights(dashboardStatusType: DashboardStatusType): string[] {
    if (DashboardStatus.isExtendedCompact(dashboardStatusType)) {
      return ["48.7px"];
    }
    if (DashboardStatus.isExtendedNotCompact(dashboardStatusType)) {
      return ["28px", "48.7px", "auto"];
    }
    return ["22px", "20px", "auto"];
  }

  static getCells(dashboardStatusType: DashboardStatusType): Cell[] {
    switch (dashboardStatusType) {
      case "OneValueNoSparklines":
        return [
          { id: gridIds.title, row: 1, col: 1, colSpan: -1 },
          { id: gridIds.periodEmptyValue, row: 5, col: 1 },
          { id: gridIds.periodTexts, row: 3, col: 1 },
          { id: gridIds.valueGroupTexts, row: 3, col: 3 },
          { id: gridIds.valueGroupValues, row: 5, col: 3 },
        ];

      case "ThreeValuesNoSparklinesScaleIndexGreaterZero":
      case "ThreeValuesNoSparklinesScaleIndexZero":
        return [
          { id: gridIds.title, row: 1, col: 1, colSpan: -1 },
          { id: gridIds.periodTexts, row: 3, col: 1 },
          { id: gridIds.periodEmptyValue, row: 5, col: 1 },
          { id: gridIds.valueGroupTexts, row: 3, col: 3 },
          { id: gridIds.valueGroupValues, row: 5, col: 3 },
          { id: gridIds.deltaTexts, row: 3, col: 5 },
          { id: gridIds.deltaValues, row: 5, col: 5 },
        ];

      case "NotExtendedAndSparklines":
        return [
          { id: gridIds.title, row: 1, col: 1, colSpan: -1 },
          { id: gridIds.periodTexts, row: 3, col: 1 },
          { id: gridIds.periodDeviationValues, row: 5, col: 1 },
          { id: gridIds.sparkline, row: 3, col: 3, rowSpan: 3 },
          { id: gridIds.valueGroupTexts, row: 3, col: 5 },
          { id: gridIds.valueGroupValues, row: 5, col: 5 },
        ];

      case "ExtendedOneValueNoSparklines":
        return [
          { id: gridIds.title, row: 3, col: 1, colSpan: 1, padding: "0 0 0 24px" },
          { id: gridIds.periodTexts, row: 1, col: 1, padding: "0 10px 0 19px" },
          { id: gridIds.valueGroupTexts, row: 1, col: 3 },
          { id: gridIds.valueGroupValues, row: 3, col: 3 },
        ];

      case "ExtendedCompactOneValueNoSparklines":
        return [
          { id: gridIds.title, row: 1, col: 1, colSpan: 1, padding: "0 0 0 24px" },
          { id: gridIds.valueGroupValues, row: 1, col: 3 },
        ];

      case "ExtendedNoSparklines":
        return [
          { id: gridIds.title, row: 3, col: 1, colSpan: 1, padding: "0 0 0 24px" },
          { id: gridIds.periodTexts, row: 1, col: 1, padding: "0 10px 0 19px" },
          { id: gridIds.valueGroupTexts, row: 1, col: 3 },
          { id: gridIds.valueGroupValues, row: 3, col: 3 },
          { id: gridIds.deltaText_1, row: 1, col: 5, padding: "0 10px 0 5px" },
          { id: gridIds.deltaText_2, row: 1, col: 7, padding: "0 24px 0 5px" },
          { id: gridIds.deltaValue_1, row: 3, col: 5, padding: "0 10px 0 5px" },
          { id: gridIds.deltaValue_2, row: 3, col: 7, padding: "0 24px 0 5px" },
        ];

      case "ExtendedCompactNoSparklines":
        return [
          { id: gridIds.title, row: 1, col: 1, colSpan: 1, padding: "0 0 0 24px" },
          { id: gridIds.valueGroupValues, row: 1, col: 3 },
          { id: gridIds.deltaValue_1, row: 1, col: 5, padding: "0 10px 0 5px" },
          { id: gridIds.deltaValue_2, row: 1, col: 7, padding: "0 24px 0 5px" },
        ];

      case "ExtendedAndSparklines":
        return [
          { id: gridIds.title, row: 3, col: 1, padding: "0 0 0 24px" },
          { id: gridIds.periodTexts, row: 1, col: 1, padding: "0 10px 0 19px" },
          {
            id: gridIds.sparkline,
            row: 1,
            col: 3,
            rowSpan: 3,
            padding: "17px 0 5.5px 0",
          },
          { id: gridIds.valueGroupTexts, row: 1, col: 5 },
          { id: gridIds.valueGroupValues, row: 3, col: 5 },
          { id: gridIds.deltaText_1, row: 1, col: 7, padding: "0 10px 0 5px" },
          { id: gridIds.deltaText_2, row: 1, col: 9, padding: "0 24px 0 5px" },
          { id: gridIds.deltaValue_1, row: 3, col: 7, padding: "0 10px 0 5px" },
          { id: gridIds.deltaValue_2, row: 3, col: 9, padding: "0 24px 0 5px" },
        ];

      case "ExtendedHalfCompactAndSparklines":
        return [
          { id: gridIds.title, row: 3, col: 1, padding: "0 0 0 24px" },
          { id: gridIds.periodTexts, row: 1, col: 1, padding: "0 10px 0 19px" },
          { id: gridIds.sparkline, row: 2, col: 3, rowSpan: 2, padding: "5px 0 5px 0" },
          { id: gridIds.valueGroupTexts, row: 1, col: 5 },
          { id: gridIds.valueGroupValues, row: 3, col: 5 },
          { id: gridIds.deltaText_1, row: 1, col: 7, padding: "0 10px 0 5px" },
          { id: gridIds.deltaText_2, row: 1, col: 9, padding: "0 24px 0 5px" },
          { id: gridIds.deltaValue_1, row: 3, col: 7, padding: "0 10px 0 5px" },
          { id: gridIds.deltaValue_2, row: 3, col: 9, padding: "0 24px 0 5px" },
        ];

      case "ExtendedCompactAndSparklines":
        return [
          { id: gridIds.title, row: 1, col: 1, padding: "0 0 0 24px" },
          { id: gridIds.sparkline, row: 1, col: 3, padding: "5px 0 5px 0" },
          { id: gridIds.valueGroupValues, row: 1, col: 5 },
          { id: gridIds.deltaValue_1, row: 1, col: 7, padding: "0 10px 0 5px" },
          { id: gridIds.deltaValue_2, row: 1, col: 9, padding: "0 24px 0 5px" },
        ];
    }
  }

  static getSeparators(dashboardStatusType: DashboardStatusType): Separator[] {
    switch (dashboardStatusType) {
      case "OneValueNoSparklines":
        return [
          { row: 2, col: 2, rowSpan: 3, backgroundColor: sepColorWhite },
          {
            row: 4,
            col: 0,
            colSpan: -1,
            backgroundColor: sepColorWhite,
          },
        ];

      case "ThreeValuesNoSparklinesScaleIndexGreaterZero":
      case "ThreeValuesNoSparklinesScaleIndexZero":
        return [
          { row: 2, col: 2, rowSpan: 3, backgroundColor: sepColorWhite },
          { row: 2, col: 4, rowSpan: 3, backgroundColor: sepColorWhite },
          {
            row: 4,
            col: 0,
            colSpan: -1,
            backgroundColor: sepColorWhite,
          },
        ];

      case "ExtendedOneValueNoSparklines":
        return [
          { row: 0, col: 2, rowSpan: 3, backgroundColor: sepColorGray },
          {
            row: 2,
            col: 0,
            colSpan: -1,
            backgroundColor: sepColorGray,
          },
        ];

      case "ExtendedNoSparklines":
        return [
          { row: 0, col: 4, rowSpan: 3, backgroundColor: sepColorGray },
          { row: 0, col: 6, rowSpan: 3, backgroundColor: sepColorGray },
          {
            row: 2,
            col: 0,
            colSpan: -1,
            backgroundColor: sepColorGray,
          },
        ];

      case "NotExtendedAndSparklines":
      case "ExtendedCompactOneValueNoSparklines":
      case "ExtendedCompactNoSparklines":
      case "ExtendedAndSparklines":
      case "ExtendedHalfCompactAndSparklines":
      case "ExtendedCompactAndSparklines":
        return [];
    }
  }
}
