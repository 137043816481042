<script lang="ts">
import { defineComponent, computed } from "vue";
import type { CSSProperties } from "vue/types/jsx";

export default defineComponent({
  props: {
    color: { type: String, default: null },
  },

  setup(props) {
    const colorVars = computed<CSSProperties>(() => {
      return {
        "--main-color": props.color ? props.color : "var(--color_neutralText)",
      };
    });

    return {
      colorVars,
    };
  },
});
</script>

<template>
  <div class="percentage-mode-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 210 210"
      v-bind:style="colorVars"
    >
      <line class="cls-1" x1="50.6" y1="159.4" x2="159.6" y2="50.4" />
      <circle class="cls-1" cx="138.5" cy="138.5" r="24" />
      <circle class="cls-1" cx="71.5" cy="71.5" r="24" />
    </svg>
  </div>
</template>

<style lang="less" scoped>
.percentage-mode-icon {
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 100%;
    width: 100%;

    .cls-1 {
      fill: none;
      stroke: var(--main-color);
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 12px;
    }
  }
}
</style>
