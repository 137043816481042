﻿<script lang="ts">
import { INotificationService } from "@/features/notifications/notification-service.interface";
import { appResources } from "@/app-resources";
import { existsAndNotEmpty } from "@/common/object-helper/null-helper";
import { Application } from "@bissantz/smartforms";
import { UploadStatusItemVm } from "./upload-status-item-vm";
import { useBackendDependencies } from "./backend-wrapper/backend-dependencies.cpsl";
import { UploadMessageReceiver } from "./upload-message-receiver";
import { formatResourceString } from "@/common/formatting/textFormatting";

import {
  onMounted,
  ref,
  reactive,
  onBeforeUnmount,
  inject,
  defineComponent,
  PropType,
  watch,
} from "vue";

export default defineComponent({
  props: {
    canDropFiles: { type: Boolean, default: false, required: true },
    portalId: { type: String, default: null },
    portalPageId: { type: String, default: null },
    messageReceiver: { type: Object as PropType<UploadMessageReceiver>, default: null },
  },

  setup: function (props) {
    // Refs
    const ref_dropZoneComponent = ref(null as HTMLDivElement);

    // Injections
    const notificationService: INotificationService = inject("notificationService");
    const application = inject("application") as Application;

    const { uploadFacade } = useBackendDependencies();

    // State
    const state = reactive({
      // TODO: disable drag for now, so the upload does not open the file (rework as part of PBI 25981)
      isEnabled: false,
      dndTextResoucres: appResources.dndUploadTexts,
      uploadStatusList: [] as UploadStatusItemVm[],
    });

    // Lifecycle Hooks
    // ------------------
    onMounted(() => {
      document.addEventListener("dragover", onDrag);
      document.addEventListener("dragleave", onDrag);
      document.addEventListener("dragenter", onDrag);
    });

    onBeforeUnmount(() => {
      document.removeEventListener("dragover", onDrag);
      document.removeEventListener("dragleave", onDrag);
      document.removeEventListener("dragenter", onDrag);
    });

    // Functions:
    // -----------------
    function isDragOverElement(event: DragEvent, element: Element): boolean {
      return event.target === element || element.contains(event.target as Node);
    }

    function isElementValid(divElement: Element): boolean {
      if (!divElement) {
        return false;
      }

      return divElement.clientWidth > 0 && divElement.clientHeight > 0;
    }

    async function validate(files: File[]): Promise<boolean> {
      if (!props.canDropFiles) {
        return false;
      }

      if (!pageExists()) {
        return false;
      }

      return fileNamesAreOkay(files);
    }

    function pageExists(): boolean {
      if (existsAndNotEmpty(props.portalPageId)) {
        return true;
      }

      notificationService.error({
        title: state.dndTextResoucres.errorUploadErrorTitle,
        message: state.dndTextResoucres.errorNoPageDefined,
      });
    }

    function fileNamesAreOkay(files: File[]): boolean {
      // keep this close to the backend version in SqliteFileTypeValidator.cs
      const isDatabaseFile = (file: File): boolean => file.name.endsWith(".db");
      let wrongFileNames: string[] = [];
      let allOkay = true;
      for (const file of files) {
        if (!isDatabaseFile(file)) {
          allOkay = false;
          wrongFileNames.push(file.name);
        }
      }
      if (!allOkay) {
        notificationService.error({
          title: state.dndTextResoucres.errorUploadErrorTitle,
          message:
            state.dndTextResoucres.errorWrongFileName +
            `\n\n${wrongFileNames.join("\n")}`,
        });
      }
      return allOkay;
    }

    async function scheduleUploadTask(file: File) {
      const status = new UploadStatusItemVm(file);
      state.uploadStatusList.push(status);

      try {
        const metadata = {
          portalId: props.portalId,
          portalPageId: props.portalPageId,
        };

        return await uploadFacade.uploadAndImport(
          file,
          metadata,
          (_, percent) => (status.percent = percent)
        );
      } catch (exception) {
        notificationService.error({
          title: state.dndTextResoucres.errorUploadErrorTitle,
          message: state.dndTextResoucres.errorUploadUnknown,
        });
      } finally {
        state.uploadStatusList.splice(state.uploadStatusList.indexOf(status), 1);
      }
    }

    async function startUpload(files: File[]): Promise<void> {
      await Promise.all(files.map(scheduleUploadTask));
      application.content.find((c) => c.contentId === "portal").refresh();
    }

    // Event Handler Functions:
    // ------------------------
    function onDrag(ev: DragEvent) {
      const dropZoneElement = ref_dropZoneComponent.value as HTMLDivElement;

      if (!isElementValid(dropZoneElement)) {
        return;
      }

      application.mainMenuOpen = false;
      const isOverDropZone = isDragOverElement(ev, dropZoneElement);

      if (state.isEnabled && props.canDropFiles && isOverDropZone) {
        ev.dataTransfer.dropEffect = "copy";
        return;
      }

      ev.dataTransfer.dropEffect = "none";
      ev.preventDefault();
    }

    async function onDrop(event: DragEvent) {
      if (!state.isEnabled) {
        event.preventDefault();
        return;
      }

      const files = Array.from(event.dataTransfer.files);

      const isValidDrop = await validate(files);
      if (!isValidDrop) {
        return;
      }

      startUpload(files);
    }

    function updateMessageReceiver() {
      if (!state.uploadStatusList?.length || state.uploadStatusList.length <= 0) {
        props.messageReceiver.hasMessage = false;
        props.messageReceiver.message = null;
        return;
      }

      let message = null;
      if (state.uploadStatusList.length == 1) {
        message = state.dndTextResoucres.uploadSingleFile;
      } else {
        message = formatResourceString(state.dndTextResoucres.uploadMultipleFiles, {
          n: `${state.uploadStatusList.length}`,
        });
      }

      props.messageReceiver.hasMessage = true;
      props.messageReceiver.message = message;
    }

    // Watchers:
    // --------------
    watch(() => state.uploadStatusList.length, updateMessageReceiver);

    return {
      ref_dropZoneComponent,

      onDrop,
    };
  },
});
</script>

<template>
  <div
    class="dropZoneComponent"
    ref="ref_dropZoneComponent"
    v-on:dragover.prevent
    v-on:dragleave.prevent
    v-on:drop.prevent="onDrop"
  >
    <slot />
  </div>
</template>
