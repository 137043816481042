<script lang="ts">
import { defineComponent, computed, ref, Ref } from "vue";
import { useHoverHelper } from "@/common/composables/hover-helper.cpsl";
import { DefaultStyles } from "@/common/styles/default-styles";

const colorConstants = DefaultStyles.colorConstants;

export default defineComponent({
  props: {
    initialColor: { type: String, default: colorConstants.headerTextHEX },
    hoverColor: { type: String, default: colorConstants.darkTextHEX },
    rotationDegree: { type: Number, default: 0 },
  },

  setup(props) {
    const ref_el: Ref<HTMLDivElement> = ref(null);
    const hoverHelperCpsl = useHoverHelper(ref_el);

    const colorVars = computed(() => {
      const isHover = props.hoverColor !== null && hoverHelperCpsl.isHover.value;
      return {
        "--stroke-color": isHover ? props.hoverColor : props.initialColor,
      };
    });

    const usedRotation = computed(() => {
      const rotationDegree = props.rotationDegree ?? 0;
      return `rotate(${rotationDegree},15,15)`;
    });

    return {
      ref_el,
      colorVars,
      usedRotation,
    };
  },
});
</script>

<template>
  <div ref="ref_el" class="arrowIcon" v-bind:style="colorVars">
    <svg class="arrowSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <polyline
        points="12,6 17,15 12,24"
        v-bind:transform="usedRotation"
        fill="none"
      ></polyline>
    </svg>
  </div>
</template>

<style lang="less" scoped>
.arrowIcon {
  height: 100%;
  cursor: pointer;

  svg {
    stroke: var(--stroke-color);
    stroke-width: 3px;
    height: 100%;
  }
}
</style>
