<script lang="ts">
import { BcButton } from "@bissantz/controls";
import XInCircleIcon from "@/common/components/icons/x-in-circle-icon.vue";
import { AppWizardCommon } from "../app-wizard-common";
import { PropType, computed, defineComponent, reactive } from "vue";
import { ButtonVm } from "../view-models/modelling/button-vm";

export default defineComponent({
  components: {
    BcButton,
    XInCircleIcon,
  },

  emits: [AppWizardCommon.events.click],

  props: {
    buttonVm: {
      type: Object as PropType<ButtonVm>,
      required: true,
    },
    isSelected: { type: Boolean, required: false, default: true },
    disabled: { type: Boolean, required: false, default: false },
    isOpaque: { type: Boolean, required: false, default: false },
    isHoverable: { type: Boolean, required: false, default: false },
    areButtonsDeletable: { type: Boolean, required: false, default: false },
  },

  setup(props, context) {
    const state = reactive({
      isHoveredOnXIcon: false,
    });

    const opaqueButton = computed(() => {
      if (!props.isOpaque) {
        return {};
      }
      return {
        "pointer-events": "none",
        opacity: "0.3",
      };
    });

    const hoverableCssClass = computed(() => {
      return props.isHoverable ? "hoverable" : "not-hoverable";
    });

    async function onWizardButtonClicked() {
      context.emit(AppWizardCommon.events.click);
    }

    async function xIconClicked(buttonId: string) {
      context.emit(AppWizardCommon.events.xIconClicked, buttonId);
    }

    function onMouseOver() {
      state.isHoveredOnXIcon = true;
    }

    function onMouseLeave() {
      state.isHoveredOnXIcon = false;
    }

    return {
      state,
      opaqueButton,
      hoverableCssClass,
      onWizardButtonClicked,
      onMouseOver,
      onMouseLeave,
      xIconClicked,

      // emits:
      AppWizardCommon,
    };
  },
});
</script>

<template>
  <div class="wizard-button">
    <BcButton
      class="single-button"
      v-bind:class="[
        { selected: $props.isSelected },
        { isHoveredOnXIcon: state.isHoveredOnXIcon },
        hoverableCssClass,
      ]"
      v-bind:style="opaqueButton"
      v-bind:disabled="$props.disabled"
      v-on:click="onWizardButtonClicked"
      outlined
    >
      {{ $props.buttonVm.name }}
    </BcButton>
    <XInCircleIcon
      v-if="$props.areButtonsDeletable"
      v-on:mouseover="onMouseOver"
      v-on:mouseleave="onMouseLeave"
      v-on:xIconClicked="xIconClicked($props.buttonVm.id)"
      class="close-icon"
    />
  </div>
</template>

<style lang="less" scoped>
@import "../../../common/styles/base-styles.less";

.wizard-button {
  position: relative;

  .single-button[type="button"] {
    padding: 6px 7px;
    letter-spacing: 1px;
    background-color: white;
    color: var(--color_headerText);
    border-color: var(--color_headerText);
    font-family: @fontRegular;

    &.hoverable:hover,
    &.selected {
      background-color: white;
      color: var(--color_Weather3);
      border-color: var(--color_Weather3);
    }

    &.isHoveredOnXIcon {
      background-color: var(--color_Weather3);
      color: white;
    }

    &.not-hoverable.outlined {
      cursor: unset;
    }
  }

  .close-icon {
    position: absolute;
    height: 14px;
    top: -7px;
    right: -7px;
  }
}
</style>
