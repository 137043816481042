import { existsAndNotEmpty } from "@/common/object-helper/null-helper";
import { IPortalFacade } from "../backend-wrapper/portal-facade.interface";
import { UniqueNameGenerator } from "@/features/application-wizard/backend-wrapper/unique-name-generator";

export class PortalVm {
  constructor(
    private readonly _portalFacade: IPortalFacade,
    public portalId: string,
    public portalPageId: string
  ) {}

  async createPortalTileForApplicationAsync(
    dashboardApplicationId: string,
    dashboardApplicationName: string
  ): Promise<boolean> {
    if (!existsAndNotEmpty(this.portalId)) {
      return false;
    }

    if (!existsAndNotEmpty(this.portalPageId)) {
      return false;
    }

    if (!existsAndNotEmpty(dashboardApplicationId)) {
      return false;
    }

    if (!existsAndNotEmpty(dashboardApplicationName)) {
      return false;
    }

    // in version 1 app.wizard can create max 1 dashboard pro created application
    const dashboardId = 1;

    const portalTileNamesResult = await this._portalFacade.getAllPortalTileNamesAsync(
      this.portalPageId
    );

    let portalTileName = dashboardApplicationName;

    if (portalTileNamesResult.succeeded) {
      portalTileName = UniqueNameGenerator.generateUniqueName(
        portalTileName,
        portalTileNamesResult.value
      );
    }

    const result = await this._portalFacade.createPortalTileAsync(
      this.portalPageId,
      dashboardApplicationId,
      portalTileName,
      dashboardId
    );

    return result.succeeded;
  }
}
