<script lang="ts">
import { defineComponent, onMounted, reactive, ref, watch, computed } from "vue";
import { ClickHelper } from "@/common/events/click-helper";
import { TouchHelper } from "@/common/events/touch-helper";
import { DM7Timeout } from "@/common/helper/dm7-timeout";
import { useHoverHelper } from "@/common/composables/hover-helper.cpsl";
import { appResources } from "@/app-resources";
import ScaledNumbersIcon from "@/common/components/icons/scaled-numbers-icon.vue";
import AccurateNumbersIcon from "@/common/components/icons/accurate-numbers-icon.vue";
import { DefaultStyles } from "@/common/styles/default-styles";

const colorConstants = DefaultStyles.colorConstants;
const HOVER_DELAY = 400;

export class AccurateScaledNumbersToggleEvents {
  static AccurateScaledNumbersClicked = "AccurateScaledNumbersClicked";
}

class AccurateScaledNumbersToggle {
  clickHelper = new ClickHelper();
  touchHelper = new TouchHelper();
  lastKpiScaleIndex: number = null;
  clicked = false;
  accurateScaledNumbersTimeout: DM7Timeout = null;
}

export default defineComponent({
  components: { AccurateNumbersIcon, ScaledNumbersIcon },
  emits: [
    "update:modelValue",
    "input",
    AccurateScaledNumbersToggleEvents.AccurateScaledNumbersClicked,
  ],
  props: {
    isPreviewEnabled: { type: Boolean, default: true },
    // v-model pattern:
    // defaults must be null to know which value is set (by v-model)
    modelValue: { type: Number, default: null }, // needed in vue3.
    value: { type: Number, default: null }, // needed in vue2
  },
  setup(props, context) {
    const ref_accurateScaledNumbers = ref<HTMLDivElement>();
    const hoverHelperAccurateScaledNumbersCpsl = useHoverHelper(
      ref_accurateScaledNumbers
    );
    const state = reactive(new AccurateScaledNumbersToggle());

    //
    // Life Cycle:
    // --------------------
    onMounted(() => {
      state.clickHelper.touchHelper = state.touchHelper;
      state.clickHelper.setOnLeftClickAction(onAccurateScaledNumbersButtonClick);
      state.touchHelper.setTapAction(onAccurateScaledNumbersButtonTap);
    });

    //
    // Computeds:
    // --------------------
    const kpiScaleIndex = computed<number>(() => {
      if (props.value === null) {
        return props.modelValue;
      } else {
        return props.value;
      }
    });

    const accurateScaledNumbersButtonText = computed<string>(() => {
      if (
        props.isPreviewEnabled &&
        state.lastKpiScaleIndex === null &&
        state.clicked === false
      ) {
        return "";
      }

      if (state.clicked) {
        return kpiScaleIndex.value === -1
          ? appResources.helpTexts.showScaledNumbers
          : appResources.helpTexts.showAccurateNumbers;
      }

      return kpiScaleIndex.value === -1
        ? appResources.helpTexts.showAccurateNumbers
        : appResources.helpTexts.showScaledNumbers;
    });

    const canShowAccurateNumbersButton = computed<boolean>(() => {
      if (props.isPreviewEnabled) {
        return kpiScaleIndex.value === -1;
      } else {
        const isHover = hoverHelperAccurateScaledNumbersCpsl.isHover.value;
        return isHover ? kpiScaleIndex.value >= 0 : kpiScaleIndex.value === -1;
      }
    });

    const canShowScaledNumbersButton = computed<boolean>(() => {
      if (props.isPreviewEnabled) {
        return kpiScaleIndex.value >= 0;
      } else {
        const isHover = hoverHelperAccurateScaledNumbersCpsl.isHover.value;
        return isHover ? kpiScaleIndex.value === -1 : kpiScaleIndex.value >= 0;
      }
    });

    //
    // Functions:
    // --------------------
    function onAccurateScaledNumbersButtonClick(): void {
      clearTimeout(state.accurateScaledNumbersTimeout);
      state.clicked = true;

      if (state.lastKpiScaleIndex === null) {
        onAccurateScaledNumbersButtonTap();
        return;
      }

      context.emit(AccurateScaledNumbersToggleEvents.AccurateScaledNumbersClicked);
      state.lastKpiScaleIndex = null;
    }

    function onAccurateScaledNumbersButtonTap(): void {
      emitUpdateValue(kpiScaleIndex.value >= 0 ? -1 : 0);
      context.emit(AccurateScaledNumbersToggleEvents.AccurateScaledNumbersClicked);
    }

    function accurateScaledNumbersHoverChanged(): void {
      if (!props.isPreviewEnabled) {
        return;
      }

      const isHover = hoverHelperAccurateScaledNumbersCpsl.isHover.value;

      if (isHover) {
        processIsHover();
      } else {
        processIsNotHover();
      }
    }

    function processIsHover(): void {
      state.clicked = false;
      state.accurateScaledNumbersTimeout = setTimeout(onHoverTimeout, HOVER_DELAY);
    }

    function onHoverTimeout(): void {
      if (!hoverHelperAccurateScaledNumbersCpsl.isHover.value) {
        return;
      }

      state.lastKpiScaleIndex = kpiScaleIndex.value;
      emitUpdateValue(kpiScaleIndex.value >= 0 ? -1 : 0);
    }

    function processIsNotHover(): void {
      clearTimeout(state.accurateScaledNumbersTimeout);

      if (state.lastKpiScaleIndex === null) {
        return;
      }

      const lastIndex = state.lastKpiScaleIndex;
      state.lastKpiScaleIndex = null;
      emitUpdateValue(lastIndex);
    }

    // v-model pattern:
    function emitUpdateValue(newValue: number): void {
      context.emit("update:modelValue", newValue); // needed in vue3.
      context.emit("input", newValue); //needed for vue2. (can be deleted in future)
    }

    //
    // Watcher:
    // --------------------
    watch(
      () => hoverHelperAccurateScaledNumbersCpsl.isHover.value,
      accurateScaledNumbersHoverChanged
    );

    return {
      ref_accurateScaledNumbers,
      state,
      colorConstants,
      hoverHelperAccurateScaledNumbersCpsl,
      accurateScaledNumbersButtonText,
      canShowAccurateNumbersButton,
      canShowScaledNumbersButton,
    };
  },
});
</script>

<template>
  <div
    ref="ref_accurateScaledNumbers"
    class="accurate-scaled-numbers-toggle"
    v-on:mousedown="state.clickHelper.mouseDown($event)"
    v-on:mouseup="state.clickHelper.mouseUp($event)"
    v-on:touchstart="state.touchHelper.touchStart($event)"
    v-on:touchmove="state.touchHelper.cancelTouch($event)"
    v-on:touchend="state.touchHelper.touchEnd($event)"
    v-bind:data-helpText="accurateScaledNumbersButtonText"
  >
    <AccurateNumbersIcon
      v-bind:class="{
        'hidden-toggle-icon': !canShowAccurateNumbersButton,
      }"
      v-bind:color="colorConstants.darkTextHEX"
    />
    <ScaledNumbersIcon
      v-bind:class="{
        'hidden-toggle-icon': !canShowScaledNumbersButton,
      }"
      v-bind:color="
        hoverHelperAccurateScaledNumbersCpsl.isHover.value &&
        (state.lastKpiScaleIndex !== null || !$props.isPreviewEnabled)
          ? colorConstants.darkTextHEX
          : colorConstants.headerTextHEX
      "
    />
  </div>
</template>

<style scoped lang="less">
.accurate-scaled-numbers-toggle {
  width: 100%;
  height: 100%;

  .hidden-toggle-icon {
    display: none;
  }
}
</style>
