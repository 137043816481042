import { IUrlApplicationDefinitionsServiceClient } from "@/common/service-clients/generated-clients";

export class UrlAppFacade {
  private _urlAppclient: IUrlApplicationDefinitionsServiceClient = null;

  constructor(urlAppClient: IUrlApplicationDefinitionsServiceClient) {
    this._urlAppclient = urlAppClient;
  }

  async getUrlAsync(pubAppId: string): Promise<string> {
    const urlApp = await this._urlAppclient.getByPublishedApplication(pubAppId);

    return urlApp.url;
  }
}
