<script lang="ts">
import { reactive, defineComponent } from "vue";
import { BcButton } from "@bissantz/controls";
import { appResources } from "@/app-resources";
import { AppWizardCommon } from "../app-wizard-common";

export default defineComponent({
  components: {
    BcButton,
  },

  emits: [AppWizardCommon.events.confirmed, AppWizardCommon.events.cancelled],

  props: {
    textButtons: { type: Object, default: null },
    disabled: { type: Boolean, default: false },
  },

  setup(props) {
    const state = reactive({
      buttonTexts: appResources.applicationWizardTexts,
    });

    return {
      //general
      props,
      state,
      // refs
      AppWizardCommon,
    };
  },
});
</script>

<template>
  <div class="application-wizard_buttons">
    <BcButton
      design="7"
      size="lg"
      v-on:click.stop="$emit(AppWizardCommon.events.confirmed)"
      v-bind:disabled="$props.disabled"
      class="button confirmButton"
      >{{ state.buttonTexts.general_confirm }}</BcButton
    >
    <BcButton
      design="7"
      size="lg"
      outlined
      v-on:click.stop="$emit(AppWizardCommon.events.cancelled)"
      class="button cancelButton"
      >{{ state.buttonTexts.general_cancel }}</BcButton
    >
  </div>
</template>

<style lang="less" scoped>
@import "../../../common/styles/base-styles.less";

.application-wizard_buttons {
  display: flex;
  justify-content: center;

  .confirmButton {
    border: none;
  }

  button.button {
    width: 100px;
    font-size: 1rem;
  }
}
</style>
