<script lang="ts">
import { useHoverHelper } from "@/common/composables/hover-helper.cpsl";
import { DefaultStyles } from "@/common/styles/default-styles";
import { defineComponent, computed, Ref, ref } from "vue";
import type { CSSProperties } from "vue/types/jsx";

const colorConstants = DefaultStyles.colorConstants;

export default defineComponent({
  props: {
    initialColor: { type: String, default: colorConstants.headerTextHEX },
    hoverColor: { type: String, default: colorConstants.darkTextHEX },
  },
  setup(props) {
    const ref_el: Ref<HTMLDivElement> = ref(null);
    const hoverHelperCpsl = useHoverHelper(ref_el);

    //
    // Computeds:
    // --------------------
    const svgStyle = computed<CSSProperties>(() => {
      const isHover = props.hoverColor !== null && hoverHelperCpsl.isHover.value;
      return {
        "--stroke-color": isHover ? props.hoverColor : props.initialColor,
      };
    });

    return {
      ref_el,
      svgStyle,
    };
  },
});
</script>

<template>
  <div ref="ref_el" class="maximize-icon" v-bind:style="svgStyle">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 145.32 145.32">
      <polyline points="7 47.53 7 7 47.53 7" stroke-width="14" />
      <polyline points="138.32 47.53 138.32 7 97.79 7" stroke-width="14" />
      <polyline points="7 97.79 7 138.32 47.53 138.32" stroke-width="14" />
      <polyline points="138.32 97.79 138.32 138.32 97.79 138.32" stroke-width="14" />
      <line x1="7" y1="7" x2="60.332" y2="60.332" stroke-width="16" />
      <line x1="138.32" y1="7" x2="84.988" y2="60.332" fill="none" stroke-width="16" />
      <line x1="7" y1="138.32" x2="60.332" y2="84.988" stroke-width="16" />
      <line x1="138.32" y1="138.32" x2="84.988" y2="84.988" stroke-width="16" />
    </svg>
  </div>
</template>

<style scoped lang="less">
.maximize-icon {
  margin-top: 3px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  svg {
    height: 100%;
    width: 100%;
    fill: none;
    stroke: var(--stroke-color);
    stroke-width: 6;
    stroke-miterlimit: 10;
  }
}
</style>
