export class GridIds {
  static readonly title = "title";
  static readonly periodTexts = "periodTexts";
  static readonly periodEmptyValue = "periodEmptyValue";
  static readonly periodDeviationValues = "periodDeviationValues";
  static readonly sparkline = "sparkline";
  static readonly valueGroupTexts = "valueGroupTexts";
  static readonly valueGroupValues = "valueGroupValues";
  static readonly valueGroupValue = "valueGroupValue";
  static readonly deltaTexts = "deltaTexts";
  static readonly deltaValues = "deltaValues";
  static readonly deltaText_1 = "deltaText_1";
  static readonly deltaText_2 = "deltaText_2";
  static readonly deltaValue_1 = "deltaValue_1";
  static readonly deltaValue_2 = "deltaValue_2";
  static readonly titleValue = "titleValue";
}
