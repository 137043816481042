import { KpiValueFm } from "@/features/dashboard-shared/backend-wrapper/facade-models-dashboard-shared";
import { NumberPresentationModeFm } from "@/features/dashboard/backend-wrapper/facade-models-dashboard";
import { SharedKpiInfo } from "./shared/shared-kpi-info";
import { IScalable } from "@/common/formatting/scalable.interface";
import { ValueFormatter } from "@/common/formatting/value-formatter";
import { NumberFormatMode } from "@/common/formatting/number-format-mode";
import { ValueFormatResources } from "@/common/formatting/value-format-resources.interface";
import { SharedRowStateVm } from "./shared/shared-row-state-vm";
import {
  getValueType,
  isAnyPercentageType,
  ValueType,
} from "@/common/formatting/value-type";
import { SharedSparklineState, SparklineVm } from "@/features/dashboard-shared/sparkline";
import isNull from "lodash/isNull";
import { PeriodVm } from "@/features/dashboard/view-models/period-vm";
import { TimeStructureType } from "@/common/service-clients/generated-clients";

const formatter: ValueFormatter = new ValueFormatter();
export class ValueVm implements IScalable {
  private _fontSize: string = null;
  private _oldFontSize: string = null;

  value: number = null;
  period: PeriodVm = null;
  backingFm: KpiValueFm;
  sparkline: SparklineVm = null;
  rowUnit: string = null;
  valueRaw: string;
  valueFormatted: string;
  unitFormatted: string;
  weatherColor: string = null;
  excludedFromScaling: boolean = false;
  /**
   * Should be 0, 1 or 2. InformationValue being 0, DeviationValues the other two.
   */
  columnIdx: number;

  // TODO: remove from VM and instead share these via vue-component props?
  parentRowState: SharedRowStateVm;
  kpiInfo: SharedKpiInfo;

  updateFrom(other: ValueVm): void {
    this.backingFm = new KpiValueFm(other.backingFm);
    this.sparkline = other.sparkline;
    this.kpiInfo = other.kpiInfo;
    this.parentRowState = other.parentRowState;
    this.period = other.period;
    this.value = other.value;

    this.valueRaw = other.valueRaw;
    this.valueFormatted = other.valueFormatted;
    this.unitFormatted = other.unitFormatted;
    this.weatherColor = other.weatherColor;
    this.rowUnit = other.rowUnit;

    this.columnIdx = other.columnIdx;

    this.fontSize = other.fontSize;
  }

  formatElementValue(
    locale: string,
    formatTexts: ValueFormatResources,
    isPercentageMode: boolean,
    isSortedByStructure: boolean,
    defaultNumberPresentationMode: NumberPresentationModeFm
  ): void {
    formatter.setCulture(formatTexts, locale);
    const value = this._calculateValue(isPercentageMode);
    const rawValue = this._calculateRawValue(isPercentageMode);
    const format = this._getFormat(isPercentageMode);
    const rawFormat = this.backingFm.format;
    const showSign = this.columnIdx !== 0;
    const numberFormatMode = this._getNumberFormatMode(
      defaultNumberPresentationMode,
      isPercentageMode,
      isSortedByStructure
    );

    [this.valueFormatted, this.unitFormatted] = formatter.formatValue(
      value,
      format,
      showSign,
      numberFormatMode
    );
    [this.valueRaw] = formatter.formatValue(
      rawValue,
      rawFormat,
      showSign,
      NumberFormatMode.Detailed
    );
  }

  updateActiveValue(sparklineState: SharedSparklineState): void {
    if (
      this.allValuesHaveSparklines ||
      (sparklineState && sparklineState.showSparklines && this.hasSparkline)
    ) {
      return this._updateSparklinesValue(sparklineState);
    }

    if (sparklineState && sparklineState.showSparklines && !this.hasSparkline) {
      return this._updateSparklinesValueWithoutSparklines(sparklineState);
    }

    this.parentRowState.selectedIndex = 0;
    this.value = this.backingFm.value;
  }

  private _updateSparklinesValue(sparklineState: SharedSparklineState): void {
    this.parentRowState.selectedIndex = this.sparkline.getCurrentHistIdx(sparklineState);
    const currentSparklineVal = this.sparkline.getCurrentValue(sparklineState);
    if (currentSparklineVal) {
      this.value = currentSparklineVal.value;
    }
  }

  private _updateSparklinesValueWithoutSparklines(
    sparklineState: SharedSparklineState
  ): void {
    const allZero = this._isFirstSparklineSelected(sparklineState);
    if (allZero) {
      this.value = this.backingFm.value;
    } else {
      this.value = null;
    }
  }

  private _isFirstSparklineSelected(sparklineState: SharedSparklineState): boolean {
    let allZero = true;
    for (const key in sparklineState.selection) {
      const val = sparklineState.selection[key as TimeStructureType];
      if (val.historyIndex > 0) {
        allZero = false;
        break;
      }
    }
    return allZero;
  }

  get id(): number {
    return this.backingFm.kpiValueId.id;
  }

  get caption(): string {
    return this.backingFm.name;
  }

  get isAnyPercentageType(): boolean {
    return isAnyPercentageType(this.valueType);
  }

  get valueType(): ValueType {
    return getValueType(this.backingFm.format);
  }

  get isDeltaValue(): boolean {
    return this.columnIdx !== 0;
  }

  get dynamicColorValue(): number {
    return this.backingFm.dynamicColorValue;
  }

  get hasSparkline(): boolean {
    return (
      this.backingFm.hasHistoryData &&
      !!this.backingFm.historicData &&
      this.backingFm.historicData.length > 0
    );
  }

  get allValuesHaveSparklines(): boolean {
    const sharedValues = this.parentRowState.sharedValues;

    return (
      this.hasSparkline &&
      sharedValues.length > 1 &&
      sharedValues[1]?.values.length === sharedValues[0]?.values.length
    );
  }

  get format(): string {
    return this.backingFm.format;
  }

  get statusText(): string {
    const valFm = this.backingFm;
    if (valFm.isStatus) return valFm.statusTextsByValue[valFm.value];

    return null;
  }

  set fontSize(newFontSize: string) {
    if (newFontSize === this._fontSize) return;

    this._oldFontSize = this._fontSize;
    this._fontSize = newFontSize;
  }

  get fontSize() {
    return this._fontSize;
  }

  get oldFontSize() {
    return this._oldFontSize;
  }

  private _calculateValue(isPercentageMode: boolean): number {
    if (isPercentageMode) {
      return this.value / Math.abs(this.parentRowState.values[this.columnIdx]);
    }
    return this._calculateRawValue(isPercentageMode);
  }

  private _calculateRawValue(isPercentageMode: boolean): number {
    if (Number.isNaN(this.value) || isNull(this.value)) {
      return null;
    }

    const value = this.value;

    if (isPercentageMode || this.isAnyPercentageType) {
      return this.kpiInfo.invertSign
        ? value * -1 * Math.sign(this.kpiInfo.scaleFactor)
        : value * Math.sign(this.kpiInfo.scaleFactor);
    }

    const factor = this.kpiInfo.invertSign
      ? -1.0 / this.kpiInfo.scaleFactor
      : 1.0 / this.kpiInfo.scaleFactor;
    return value * factor;
  }

  private _getFormat(isPercentageMode: boolean): string {
    if (isPercentageMode) {
      return "%";
    } else if (this.backingFm.isKpiSwitchActive) {
      return this.rowUnit;
    } else {
      return this.backingFm.format;
    }
  }

  private _getNumberFormatMode(
    defaultNumberPresentationMode: NumberPresentationModeFm,
    isPercentageMode: boolean,
    isSortedByStructure: boolean
  ): NumberFormatMode {
    const deviationValuesExist = this.parentRowState?.deviationValuesExist;

    if (
      this.columnIdx === 0 &&
      !deviationValuesExist &&
      isSortedByStructure &&
      !this.isAnyPercentageType
    ) {
      return NumberFormatMode.WithoutDecimal;
    }

    if (isSortedByStructure && !this.isAnyPercentageType && !isPercentageMode) {
      return NumberFormatMode.WithDecimal;
    }

    if (defaultNumberPresentationMode === "Detailed") {
      return NumberFormatMode.Detailed;
    }

    return NumberFormatMode.CompactWithWords;
  }
}
