import { FailedReason } from "@/common/results/failed-reason";
import { MenuNodeEventNotifier } from "./menu-node-event-notifier";
import { IMenuNodeLoader } from "./menu-node-loader.interface";
import { AppEntry } from "./app-entry";

export class MenuNodeVm {
  private readonly _childLoader: IMenuNodeLoader;
  private readonly _id: string;
  private _name: string;
  private _value: AppEntry;
  private _eventNotifier: MenuNodeEventNotifier;
  private _children: MenuNodeVm[] = [];
  private _isOpen: boolean = false;
  private _isSelected: boolean = false;
  private _isRoot: boolean = true;
  private _areChildrenLoaded: boolean = false;
  private _isLoadingData: boolean = false;
  private _dataLoadingFailed: boolean = false;
  private _dataLoadingFailedReason: FailedReason = null;

  constructor(id: string, name: string, childLoader?: IMenuNodeLoader) {
    this._childLoader = childLoader;
    this._id = id;
    this._name = name;
  }

  get id(): string {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get value(): AppEntry {
    return this._value;
  }

  set value(v: AppEntry) {
    this._value = v;
  }

  get children(): MenuNodeVm[] {
    return this._children;
  }

  get isOpen(): boolean {
    return this._isOpen;
  }

  get isRoot(): boolean {
    return this._isRoot;
  }

  get isSelectable(): boolean {
    return !this._canLoadChildren;
  }

  get isSelected(): boolean {
    return this._isSelected;
  }

  get eventNotifier(): MenuNodeEventNotifier {
    return this._eventNotifier;
  }

  get isLoadingData(): boolean {
    return this._isLoadingData;
  }

  get dataLoadingFailed(): boolean {
    return this._dataLoadingFailed;
  }

  get dataLoadingFailedReason(): FailedReason {
    return this._dataLoadingFailedReason;
  }

  set eventNotifier(eventNotifier: MenuNodeEventNotifier) {
    this._eventNotifier = eventNotifier;
  }

  get mightHaveChildren(): boolean {
    if (this._areChildrenLoaded) {
      return this._children.length > 0;
    }

    if (this._canLoadChildren === false) {
      return false;
    }

    return true;
  }

  private get _canLoadChildren(): boolean {
    return !(this._childLoader === null || this._childLoader === undefined);
  }

  async toggleAsync(): Promise<void> {
    if (this._toggleSelectionIfNodeIsSelectable()) {
      return;
    }

    await this._loadChildrenIfNotAlreadyLoadedAsync();

    this._isOpen = !this._isOpen;
  }

  toggleNodeSelection(nodeId: string): Promise<void> {
    if (this.id === nodeId) {
      this._toggleSelectionIfNodeIsSelectable();
      return;
    }

    for (const child of this._children) {
      child.toggleNodeSelection(nodeId);
    }
  }

  clearCache() {
    if (this._areChildrenLoaded === false) {
      return;
    }

    if (this._canLoadChildren) {
      this._childLoader.clearCache();
      this._children = [];
      this._areChildrenLoaded = false;
    }
  }

  private _toggleSelectionIfNodeIsSelectable(): boolean {
    if (this.isSelectable) {
      this._isSelected = !this._isSelected;
      this._raiseSelectionChanged();
      return true;
    }

    return false;
  }

  private _raiseSelectionChanged() {
    if (this._eventNotifier !== null && this._eventNotifier !== undefined) {
      this._eventNotifier.nodeSelectionChanged.emit(this);
    }
  }

  async _loadChildrenIfNotAlreadyLoadedAsync(): Promise<void> {
    if (this._areChildrenLoaded) {
      return;
    }

    this._isLoadingData = true;

    try {
      const loadResult = await this._childLoader.loadAsync(this._id);

      this._dataLoadingFailed = !loadResult.succeeded;
      this._dataLoadingFailedReason = loadResult.failedReason;

      if (loadResult.succeeded === true) {
        this._children = loadResult.value;
        this._areChildrenLoaded = true;

        for (const child of this._children) {
          child._isRoot = false;
          child.eventNotifier = this._eventNotifier;
        }
      }
    } finally {
      this._isLoadingData = false;
    }
  }
}
