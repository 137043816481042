<script lang="ts">
import { defineComponent, computed } from "vue";
import type { CSSProperties } from "vue/types/jsx";

export default defineComponent({
  props: {
    color: { type: String, default: null },
  },

  setup(props) {
    //
    // Life Cycle:
    // --------------------

    //
    // Computeds:
    // --------------------
    const colorVars = computed<CSSProperties>(() => {
      return {
        "--main-color": props.color ? props.color : "var(--color_neutralText)",
      };
    });

    //
    // Functions:
    // --------------------

    //
    // Watcher:
    // --------------------

    return {
      colorVars,
    };
  },
});
</script>

<template>
  <div class="exclude-from-scaling-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 210 210"
      v-bind:style="colorVars"
    >
      <path
        class="cls-2"
        d="M159.2,143.5c-12,16.9-31.8,28-54.2,28-36.7,0-66.5-29.8-66.5-66.5s29.8-66.5,66.5-66.5,42.2,11,54.2,28"
      />
      <g>
        <path
          class="cls-1"
          d="M110,88.3h62.2c3.9,0,7.1,3.2,7.1,7.1v19.3c0,3.9-3.2,7.1-7.1,7.1h-62.2v-33.4h0Z"
        />
        <polygon class="cls-1" points="121.8 137.3 121.8 72.7 76.1 105 121.8 137.3" />
      </g>
    </svg>
  </div>
</template>

<style lang="less" scoped>
.exclude-from-scaling-icon {
  height: 100%;

  svg {
    height: 100%;

    .cls-1 {
      fill: var(--main-color);
      stroke-width: 0;
    }

    .cls-2 {
      fill: none;
      stroke: var(--main-color);
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 12px;
    }
  }
}
</style>
