<script lang="ts">
// Inspired by:
//                  https://loading.io/css/
// (added dynamic size and line width via css)

/**
 * USAGE:
 *
 * Create a wrapper around this control. This spinner will then
 * use the wrappers *width* and *height*.
 *
 * The wrappers *font-size* is used to change the spinners line
 * width.
 *
 * The wrappers *border-color* is used to the the spinners color.
 *
 * e.g.:
 * .spinnerWrapper{
 *   // sets spinner size:
 *   height: 6em;
 *   width: 6em;
 *
 *   // sets spinner line width:
 *   font-size: 6px;
 * }
 */

import { defineComponent } from "vue";

export default defineComponent({});
</script>

<template>
  <div class="spinnerContianer">
    <div class="spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.spinnerContianer {
  height: 100%;
  width: 100%;
  position: relative;
  border-color: inherit;
}

.spinner {
  position: absolute;
  height: 100%;
  width: 100%;
  border-color: inherit;
}

.spinner div {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
  border-width: 1em;
  border-style: solid;
  border-radius: 50%;
  animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: inherit;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
}

.spinner div:nth-child(1) {
  animation-delay: -0.45s;
}

.spinner div:nth-child(2) {
  animation-delay: -0.3s;
}

.spinner div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
