import { WatchStopHandle, watch } from "vue";
import { IDisposable } from "../disposable.interface";
import { DM7Timeout } from "./dm7-timeout";

export type SimpleWatchExpression<T> = () => T;
export type WatchCondition<T> = (changedItem: T) => boolean;
export type WaitResult = { timedOut: boolean };

export class VueHelper {
  static stopWatchToDisposable(stopWatch: WatchStopHandle): IDisposable {
    return { dispose: stopWatch };
  }

  /**
   * Waits until the reactive value (of 'expr') changes
   * or if given: the timeout ends.
   */
  static waitForChange<T>(
    expr: SimpleWatchExpression<T>,
    timeoutMs?: number
  ): Promise<WaitResult> {
    return new Promise((resolve) => {
      let timeout: DM7Timeout = null;
      let stopHandle: WatchStopHandle = null;

      if (timeoutMs) {
        timeout = setTimeout(() => {
          stopHandle?.();
          resolve({
            timedOut: true,
          });
        }, timeoutMs);
      }
      stopHandle = watch(expr, () => {
        if (timeout) {
          clearTimeout(timeout);
        }
        stopHandle?.();
        resolve({
          timedOut: false,
        });
      });
    });
  }

  /**
   * Waits until the reactive value  (of 'expr') changes so that
   * 'condition' is true, or the timeout ends.
   */
  static waitForChangeAndCondition<T>(
    expr: SimpleWatchExpression<T>,
    condition: WatchCondition<T>,
    timeoutMs?: number
  ): Promise<WaitResult> {
    return new Promise((resolve) => {
      let timeout: DM7Timeout = null;
      let stopHandle: WatchStopHandle = null;

      if (timeoutMs) {
        timeout = setTimeout(() => {
          stopHandle?.();
          resolve({ timedOut: true });
        }, timeoutMs);
      }

      const onChangeHandler = () => {
        const currentValue = expr();
        const isConditionFullfilled = condition(currentValue);

        if (!isConditionFullfilled) {
          return;
        }

        if (timeout) {
          clearTimeout(timeout);
        }

        stopHandle?.();
        resolve({ timedOut: false });
      };

      stopHandle = watch(expr, onChangeHandler);
    });
  }
}
