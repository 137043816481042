import { FailedReason } from "@/common/results/failed-reason";
import { TreeNodeEventNotifier } from "./tree-node-event-notifier";
import { ITreeNodeLoader } from "./tree-node-loader.interface";

export class TreeNodeVm {
  private readonly _childLoader: ITreeNodeLoader;
  private readonly _id: string;
  private readonly _name: string;
  private _value: unknown;
  private _treeItemEventNotifier: TreeNodeEventNotifier;
  private _children: TreeNodeVm[] = [];
  private _isOpen: boolean = false;
  private _isSelected: boolean = false;
  private _isRoot: boolean = true;
  private _areChildrenLoaded: boolean = false;
  private _isLoadingData: boolean = false;
  private _dataLoadingFailed: boolean = false;
  private _dataLoadingFailedReason: FailedReason = null;

  constructor(id: string, name: string, childLoader?: ITreeNodeLoader) {
    this._childLoader = childLoader;
    this._id = id;
    this._name = name;
  }

  get id(): string {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  get value(): unknown {
    return this._value;
  }

  set value(v: unknown) {
    this._value = v;
  }

  get children(): TreeNodeVm[] {
    return this._children;
  }

  get isOpen(): boolean {
    return this._isOpen;
  }

  get isRoot(): boolean {
    return this._isRoot;
  }

  get isSelectable(): boolean {
    return !this._canLoadChildren;
  }

  set isSelected(isSelected: boolean) {
    this._isSelected = isSelected;
  }

  get isSelected(): boolean {
    return this._isSelected;
  }

  get eventNotifier(): TreeNodeEventNotifier {
    return this._treeItemEventNotifier;
  }

  get isLoadingData(): boolean {
    return this._isLoadingData;
  }

  get dataLoadingFailed(): boolean {
    return this._dataLoadingFailed;
  }

  get dataLoadingFailedReason(): FailedReason {
    return this._dataLoadingFailedReason;
  }

  set eventNotifier(eventNotifier: TreeNodeEventNotifier) {
    this._treeItemEventNotifier = eventNotifier;
  }

  private get _canLoadChildren(): boolean {
    return !(this._childLoader === null || this._childLoader === undefined);
  }

  async toggleAsync(): Promise<void> {
    if (this._toggleSelectionIfNodeIsSelectable()) {
      return;
    }

    await this._loadChildrenIfNotAlreadyLoadedAsync();

    this._isOpen = !this._isOpen;
  }

  toggleNodeSelection(nodeId: string): Promise<void> {
    if (this.id === nodeId) {
      this._toggleSelectionIfNodeIsSelectable();
      return;
    }

    for (const child of this._children) {
      child.toggleNodeSelection(nodeId);
    }
  }

  togglePeriodElementSelection(elementTreeItem: TreeNodeVm) {
    for (const child of this._children) {
      if (child.isSelected) {
        child.isSelected = false;
      }
      child.togglePeriodElementSelection(elementTreeItem);
    }
    elementTreeItem.isSelected = true;
  }

  private _toggleSelectionIfNodeIsSelectable(): boolean {
    if (this.isSelectable) {
      this.isSelected = !this._isSelected;
      this._raiseSelectionChanged();
      return true;
    }

    return false;
  }

  private _raiseSelectionChanged() {
    if (
      this._treeItemEventNotifier !== null &&
      this._treeItemEventNotifier !== undefined
    ) {
      this._treeItemEventNotifier.nodeSelectionChanged.emit(this);
    }
  }

  async _loadChildrenIfNotAlreadyLoadedAsync(): Promise<void> {
    if (this._areChildrenLoaded) {
      return;
    }

    this._isLoadingData = true;

    try {
      const loadResult = await this._childLoader.loadAsync(this._id);

      this._dataLoadingFailed = !loadResult.succeeded;
      this._dataLoadingFailedReason = loadResult.failedReason;

      if (loadResult.succeeded === true) {
        this._children = loadResult.value;

        for (const child of this._children) {
          child._isRoot = false;
          child.eventNotifier = this._treeItemEventNotifier;
        }
      }
    } finally {
      this._isLoadingData = false;
    }

    this._areChildrenLoaded = true;
  }
}
