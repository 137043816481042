<script lang="ts">
import StructureElements from "@/features/dashboard/structure-elements/structure-elements.vue";
import { ValueGroupVm } from "../view-models/value-group-vm";
import { DashboardSettings } from "@/features/dashboard-shared/dashboard-settings";
import { DashboardCommon } from "../dashboard-common";
import { DefaultStyles } from "@/common/styles/default-styles";
import { SharedDashboardStateVm } from "../view-models/shared/shared-dashboard-state-vm";
import { IZoomService } from "@/services/zoom-service.interface";
import { DashboardSelection } from "@/features/dashboard-shared/dashboard-selection";
import {
  reactive,
  inject,
  computed,
  onMounted,
  watch,
  defineComponent,
  PropType,
  onBeforeMount,
  ref,
  onBeforeUnmount,
} from "vue";
import { IContentLocaleOption } from "@bissantz/smartforms";
import { ValueVm } from "@/features/dashboard/view-models/value-vm";
import { useStructureElements } from "@/features/dashboard/structure-elements/structure-elements.cpsl";
import SimpleGrid from "@/common/components/simple-grid/simple-grid.vue";
import { appResources } from "@/app-resources";
import { usePeriodSparklinesSync } from "@/features/dashboard/value-group/period-sparklines-sync.cpsl";
import {
  DashboardStatus,
  DashboardStatusType,
} from "@/features/dashboard/status-configs/dashboard-status";
import { StatusConfigValueGroup } from "@/features/dashboard/status-configs/sc-value-group";
import { GridIds } from "@/features/dashboard/status-configs/grid-ids";
import { Cell, Separator } from "@/common/components/simple-grid/contracts/cell";
import EmptyCell from "@/common/components/cell-basics/empty-cell.vue";
import DashboardTitle from "@/features/dashboard/value-group/dashboard-title.vue";
import SwipeableNavigationCell from "@/common/components/cell-basics/swipeable-navigation-cell.vue";
import SwipeableDashboardValue from "@/features/dashboard/helper-components/swipeable-dashboard-value.vue";
import DashboardValue from "@/features/dashboard/helper-components/dashboard-value.vue";
import NavigationCell from "@/common/components/cell-basics/navigation-cell.vue";
import SparklineBox from "@/features/dashboard-shared/sparkline/sparkline-box.vue";
import { replace } from "@/common/helper/array-helper";
import { clamp } from "lodash";
import { useHelpTextHelper } from "@/features/dashboard/value-group/help-text-helper.cpsl";
import { resizeHelper, Unobserver } from "@/common/resize-helper";

class ValueGroupState {
  zoomFactor = null as number;
  cells: Cell[] = [];
  separators: Separator[] = [];
  separatorWidths: string[];
  cellWidths: string[];
  cellHeights: string[];
  separatorHeights: string[];
  dashboardStatusType: DashboardStatusType =
    "ThreeValuesNoSparklinesScaleIndexGreaterZero";
  firstValueWidth: number = 200;
  unobserveCallback: Unobserver = null;
}

export default defineComponent({
  components: {
    SparklineBox,
    NavigationCell,
    DashboardValue,
    SwipeableDashboardValue,
    SwipeableNavigationCell,
    DashboardTitle,
    EmptyCell,
    SimpleGrid,
    StructureElements,
  },

  emits: [
    DashboardCommon.common_excludeFromScalingChanged,
    DashboardCommon.common_hideSparklines,
    DashboardCommon.valueGroup_drillClosed,
  ],

  props: {
    valueGroupVms: { type: Array as PropType<Array<ValueGroupVm>>, required: true },
    valueGroupVm: { type: Object as PropType<ValueGroupVm>, required: true },
    dashboardSelection: {
      type: Object as PropType<DashboardSelection>,
      required: true,
    },
    dashboardSettings: { type: Object as PropType<DashboardSettings>, required: true },
    sharedState: { type: Object as PropType<SharedDashboardStateVm>, required: true },
    isSelected: { type: Boolean, required: true },
    areSparklinesCompatible: { type: Boolean, default: true },
    isCompactAllowed: { type: Boolean, default: true },
  },

  setup: function (props, context) {
    const ref_firstValue = ref<InstanceType<typeof SwipeableDashboardValue> | null>(null);
    const application = inject<IContentLocaleOption>("application");
    const zoomService = inject<IZoomService>("zoomService");
    const state = reactive(new ValueGroupState()) as ValueGroupState;
    const gridIds = GridIds;
    const structureElementsCpsl = useStructureElements(
      context,
      computed(() => props.valueGroupVm),
      props.sharedState,
      computed(() => props.dashboardSettings.isExtended),
      computed(() => props.isSelected)
    );
    const helpTextHelperCpsl = useHelpTextHelper(
      computed(() => isExcludedFromScaling.value),
      computed(() => props.sharedState.sparklineState.showSparklines),
      computed(() => canSwipePeriod.value),
      computed(() => kpiScaleIndex.value),
      computed(() => props.valueGroupVm.hasStructures),
      computed(() => props.valueGroupVm.structureElementsVm.isVisible),
      computed(() => canClickValueGroupTexts.value),
      computed(() => canSwipe.value),
      computed(() => props.valueGroupVm.deltaValuesSwiperVm),
      computed(() => props.dashboardSettings.shownKpiIds.length > 1)
    );
    usePeriodSparklinesSync(
      computed(() => props.valueGroupVm.getScaledValue(props.sharedState, true)),
      computed(() => props.valueGroupVm.kpiValues),
      props.sharedState.sparklineState,
      computed(() => props.valueGroupVm.periodSwiperVm),
      computed(() => props.valueGroupVm.getPeriodSwiperActiveIndex(props.sharedState)),
      context
    );

    //
    // Life Cycle:
    // --------------------
    onBeforeMount(() => {
      initialize();
      initGrid();
    });

    onMounted(() => {
      state.unobserveCallback = resizeHelper.observe(
        ref_firstValue.value.ref_swipeableDashboardValue as HTMLElement,
        setFirstValueWidth
      );
      props.valueGroupVm.deltaIndexForDrill =
        props.valueGroupVm.deltaValuesSwiperVm.activeIndex;
    });

    onBeforeUnmount(() => {
      state.unobserveCallback?.();
    });

    //
    // Computeds:
    // --------------------
    const scaleIndex = computed<number>(() => {
      const currentMaxIdx = props.valueGroupVm.kpiValues.length - 1;
      return clamp(kpiScaleIndex.value, 0, currentMaxIdx);
    });

    const isExcludedFromScaling = computed<boolean>(() => {
      if (kpiScaleIndex.value < 0) {
        return false;
      }

      return props.valueGroupVm.kpiValues[scaleIndex.value]?.excludedFromScaling;
    });

    const tileBGColor = computed(() => {
      return isExcludedFromScaling.value
        ? DefaultStyles.colorConstants.neutralTextHEX
        : disableableScaleColor.value;
    });

    const valueGroupStyle = computed(() => {
      let headerTextColor = DefaultStyles.colorConstants.bgWhite;
      let valueTextColor = DefaultStyles.colorConstants.bgWhite;
      let headerBackgroundColor = tileBGColor.value;
      let valueBackgroundColor = tileBGColor.value;

      if (props.dashboardSettings.isExtended) {
        headerTextColor = DefaultStyles.colorConstants.headerTextHEX;
        valueTextColor = DefaultStyles.colorConstants.neutralTextHEX;
      } else if (showSparkline.value) {
        headerTextColor = tileBGColor.value;
        headerBackgroundColor = DefaultStyles.colorConstants.bgWhite;
      }
      let headerOffset = `calc(${state.cellHeights[0]} + ${state.separatorHeights[0]} * 1px)`;

      if (props.dashboardSettings.isExtended) {
        headerOffset = "0";
      }

      return {
        "--headerOffset": headerOffset,
        "--headerTextColor": headerTextColor,
        "--valueTextColor": valueTextColor,
        "--scaledColor": disableableScaleColor.value,
        "--headerBackgroundColor": headerBackgroundColor,
        "--valueBackgroundColor": valueBackgroundColor,
        "--lineWidth": "2px",
      };
    });

    const canSwipePeriod = computed<boolean>(
      () =>
        props.valueGroupVm.allValuesHaveSparkline ||
        (props.valueGroupVm.firstValueHasSparkline &&
          props.sharedState.sparklineState.showSparklines)
    );

    const canClickValueGroupTexts = computed<boolean>(
      () =>
        !props.sharedState.sparklineState.showSparklines && props.valueGroupVms.length > 1
    );

    const canDrill = computed<boolean>(() => props.valueGroupVm.hasStructures);

    const canSwipe = computed<boolean>(
      () => !props.sharedState.sparklineState.showSparklines
    );

    const valueGroupCellTexts = computed<string[]>(() =>
      props.valueGroupVms.map((valueGroupVm) => valueGroupVm.kpiValues[0].caption.trim())
    );

    const valueGroupCellValues = computed<ValueVm[]>(() =>
      props.valueGroupVms.map((valueGroupVm) => valueGroupVm.kpiValues[0])
    );

    const periodDeviationValues = computed<string[]>(() => {
      if (!props.valueGroupVm.kpiValues[0].hasSparkline) {
        return [];
      }

      const scaleFactor = props.valueGroupVm.kpiInfo.scaleFactor;
      const invertSign = props.valueGroupVm.kpiInfo.invertSign;
      const formatTexts = appResources.valueFormatTexts;

      return props.valueGroupVm.kpiValues[0].sparkline.sparkBarValues
        .map((sparkBarValue) =>
          sparkBarValue.getDeviationValueAndUnit(
            scaleFactor,
            invertSign,
            application.effectiveContentLocale,
            formatTexts
          )
        )
        .reverse();
    });

    const deltaCellTexts = computed<string[]>(() =>
      deltaCellValues.value.map((swipeItem) => swipeItem.caption)
    );

    const deltaCellValues = computed<ValueVm[]>(() =>
      props.valueGroupVm.kpiValues.length > 2
        ? props.valueGroupVm.kpiValues.slice(1, props.valueGroupVm.kpiValues.length)
        : []
    );

    const structureNames = computed<string[]>(() =>
      props.valueGroupVm.availableStructures.map((structure) => structure.displayName)
    );

    const showSparkline = computed(() => {
      return props.valueGroupVm.getShowSparklines(props.sharedState);
    });

    const useColorOrScaling = computed(() => {
      return props.valueGroupVm.getUseColorOrScaling(props.sharedState.kpiScaleIndex);
    });

    const disableableScaleColor = computed(() => {
      if (useColorOrScaling.value) {
        const ignoreExcludeFromScaling = props.sharedState.kpiScaleIndex < 0;
        const selectedValue = props.valueGroupVm.getScaledValue(
          props.sharedState,
          ignoreExcludeFromScaling
        );

        if (selectedValue) {
          return selectedValue.weatherColor;
        }
      }

      return "";
    });

    const scaleColor = computed<string>(() => {
      return props.valueGroupVm.getWeatherColor(props.sharedState.kpiScaleIndex);
    });

    const kpiScaleIndex = computed<number>(() =>
      clamp(props.sharedState.kpiScaleIndex, -1, props.valueGroupVm.kpiValues.length - 1)
    );

    const showSparklines = computed<boolean>(() => {
      return props.dashboardSettings.isExtended
        ? props.sharedState.sparklineState.showSparklines
        : props.valueGroupVm.getShowSecondSectionSparklines(props.sharedState);
    });

    //
    // Functions:
    // --------------------
    function initialize() {
      setZoomFactor();
      zoomService.factorChanged.on(setZoomFactor);
    }

    function setFirstValueWidth(): void {
      if (!ref_firstValue.value) {
        return;
      }
      const firstValue = ref_firstValue.value.ref_swipeableDashboardValue;

      if (!firstValue || !firstValue.clientWidth) {
        return;
      }

      const firstValColWidth = firstValue.clientWidth;
      const colStyles = getComputedStyle(firstValue as Element);
      const paddingRight = parseInt(
        colStyles.getPropertyValue("padding-right").replace("px", "")
      );
      const paddingLeft = parseInt(
        colStyles.getPropertyValue("padding-left").replace("px", "")
      );
      const firstValueWidth = firstValColWidth - paddingRight - paddingLeft;

      if (!firstValueWidth) {
        return;
      }

      state.firstValueWidth = firstValueWidth;
    }

    function setZoomFactor(): void {
      state.zoomFactor = zoomService.factor;
    }

    function onSelectionChanged(): void {
      if (!props.isSelected) {
        return;
      }

      if (isExcludedFromScaling.value) {
        props.dashboardSelection.selectionColor = null;
      } else {
        props.dashboardSelection.selectionColor = tileBGColor.value;
      }
    }

    function initGrid() {
      const numValues = props.valueGroupVm.kpiValues.length;
      const scaleIndex = props.sharedState.kpiScaleIndex;
      const isExtended = props.dashboardSettings.isExtended;
      const isCompact = props.dashboardSettings.isCompact;
      const isCompactAllowed = props.isCompactAllowed;

      if (!props.areSparklinesCompatible) {
        props.dashboardSettings.isCompact = false;
      }

      const dashboardStatusType = DashboardStatus.getConfig(
        numValues,
        scaleIndex,
        showSparklines.value,
        isExtended,
        isCompact,
        isCompactAllowed
      );
      const separatorHeights =
        StatusConfigValueGroup.getSeparatorHeights(dashboardStatusType);
      const cellHeights = StatusConfigValueGroup.getCellHeights(dashboardStatusType);
      const separatorWidths =
        StatusConfigValueGroup.getSeparatorWidths(dashboardStatusType);
      const cellWidths = StatusConfigValueGroup.getCellWidths(dashboardStatusType);
      const cells = StatusConfigValueGroup.getCells(dashboardStatusType);
      const separators = StatusConfigValueGroup.getSeparators(dashboardStatusType);

      state.dashboardStatusType = dashboardStatusType;
      replace(state.cells, cells);
      state.cellHeights = cellHeights;
      state.separatorHeights = separatorHeights;
      state.separatorWidths = separatorWidths;
      state.cellWidths = cellWidths;
      state.separators = separators;
      setTimeout(setFirstValueWidth);
    }

    //
    // Watcher:
    // --------------------
    watch([() => props.isSelected, () => tileBGColor.value], onSelectionChanged);

    watch(
      [
        () => props.valueGroupVm,
        () => props.sharedState.kpiScaleIndex,
        () => props.sharedState.sparklineState.showSparklines,
        () => props.dashboardSettings.isExtended,
        () => props.dashboardSettings.isCompact,
      ],
      initGrid
    );

    return {
      ref_firstValue,
      state,
      helpTextHelperCpsl,
      structureElementsCpsl,
      canSwipePeriod,
      canDrill,
      canSwipe,
      periodDeviationValues,
      valueGroupCellTexts,
      canClickValueGroupTexts,
      valueGroupCellValues,
      deltaCellTexts,
      deltaCellValues,
      structureNames,
      disableableScaleColor,
      useColorOrScaling,
      showSparkline,
      tileBGColor,
      scaleColor,
      valueGroupStyle,
      gridIds,
    };
  },
});
</script>

<template>
  <div
    class="valueGroup"
    v-bind:class="{
      showsSparkline: showSparkline,
      showsDrill: $props.valueGroupVm.structureElementsVm.isVisible,
      'is-extended': $props.dashboardSettings.isExtended,
      'is-compact': $props.dashboardSettings.isCompact,
    }"
    v-bind:style="valueGroupStyle"
  >
    <SimpleGrid
      class="backgroundImage"
      v-bind:cells="state.cells"
      v-bind:separators="state.separators"
      v-bind:separatorWidths="state.separatorWidths"
      v-bind:cellWidths="state.cellWidths"
      v-bind:separatorHeights="state.separatorHeights"
      v-bind:cellHeights="state.cellHeights"
      v-on:pointerdown.native="$props.dashboardSelection.clearElement()"
      v-on="$listeners"
    >
      <DashboardTitle
        v-bind:data-grid-id="gridIds.title"
        v-bind:valueGroupVm="$props.valueGroupVm"
        v-bind:titleTextColor="disableableScaleColor"
        v-on="$listeners"
      />
      <SwipeableNavigationCell
        v-bind:data-grid-id="gridIds.periodTexts"
        v-bind:swiperVm="$props.valueGroupVm.periodSwiperVm"
        v-bind:swipeItems="$props.valueGroupVm.periodCellTexts"
        v-bind:zoomFactor="state.zoomFactor"
        v-bind:isLeftClickEnabled="true"
        v-bind:isClickEnabled="canSwipePeriod"
        v-bind:justifyContent="'flex-start'"
        v-bind:fontColor="'var(--headerTextColor)'"
        v-bind:animateSwipe="false"
        v-bind:data-helpText="helpTextHelperCpsl.periodTexts.value"
        v-on="$listeners"
      />
      <EmptyCell
        v-bind:data-grid-id="gridIds.periodEmptyValue"
        v-bind:isClickable="canDrill"
        v-on:emptyCellLeftClicked="structureElementsCpsl.clickHandler(-1)"
        v-bind:data-helpText="helpTextHelperCpsl.periodEmptyValue.value"
        v-on:pointerdown.native="$props.dashboardSelection.setKpiValue(-1)"
        v-on="$listeners"
      />
      <SwipeableNavigationCell
        v-bind:data-grid-id="gridIds.periodDeviationValues"
        v-bind:swiperVm="$props.valueGroupVm.periodSwiperVm"
        v-bind:swipeItems="periodDeviationValues"
        v-bind:zoomFactor="state.zoomFactor"
        v-bind:isRightClickEnabled="false"
        v-bind:isClickEnabled="canSwipePeriod || canDrill"
        v-bind:animateSwipe="false"
        v-bind:fontColor="'var(--valueTextColor)'"
        v-bind:maxFontSize="'2.3em'"
        v-bind:separatorColor="'transparent'"
        v-bind:style="{ backgroundColor: tileBGColor }"
        v-on:navigationCellClicked="structureElementsCpsl.clickHandler(-1)"
        v-bind:data-helpText="helpTextHelperCpsl.periodDeviationValues.value"
        v-on="$listeners"
      />
      <SparklineBox
        v-bind:data-grid-id="gridIds.sparkline"
        v-bind:sparklineVm="$props.valueGroupVm.kpiValues[0].sparkline"
        v-bind:kpiScaleIndex="0"
        v-bind:sparklineDisplayState="$props.sharedState.sparklineState"
        v-bind:sparklinesGlobalMax="$props.valueGroupVm.sparklinesGlobalMax"
        v-bind:colorInfo="$props.valueGroupVm.kpiInfo"
        v-bind:zoomFactor="state.zoomFactor"
        v-bind:excludeFromScaling="$props.valueGroupVm.kpiValues[0].excludedFromScaling"
        v-bind:sparklineHeight="
          $props.dashboardSettings.isCompact && $props.dashboardSettings.isExtended
            ? 38
            : 55
        "
        v-on="$listeners"
      />
      <SwipeableNavigationCell
        v-bind:data-grid-id="gridIds.valueGroupTexts"
        v-bind:swiperVm="$props.valueGroupVm.valueGroupSwiperVm"
        v-bind:swipeItems="valueGroupCellTexts"
        v-bind:zoomFactor="state.zoomFactor"
        v-bind:fontColor="'var(--headerTextColor)'"
        v-bind:isClickEnabled="canClickValueGroupTexts"
        v-bind:data-helpText="helpTextHelperCpsl.valueGroupTexts.value"
        v-on="$listeners"
      />
      <SwipeableDashboardValue
        ref="ref_firstValue"
        v-bind:data-grid-id="gridIds.valueGroupValues"
        v-bind:swiperVm="$props.valueGroupVm.valueGroupSwiperVm"
        v-bind:swipeItems="valueGroupCellValues"
        v-bind:zoomFactor="state.zoomFactor"
        v-bind:isClickEnabled="canDrill"
        v-bind:isSwipeEnabled="canClickValueGroupTexts"
        v-bind:isScaled="$props.sharedState.kpiScaleIndex === 0"
        v-bind:sharedState="$props.sharedState"
        v-bind:useColorOrScaling="useColorOrScaling"
        v-bind:colorizeBackground="!$props.dashboardSettings.isExtended"
        v-bind:alignCenter="false"
        v-bind:data-helpText="helpTextHelperCpsl.valueGroupValues.value"
        v-bind:style="{
          backgroundColor: $props.dashboardSettings.isExtended ? 'unset' : tileBGColor,
        }"
        v-on:swipeableDashboardValue_clicked="structureElementsCpsl.onValueLeftClicked"
        v-on:pointerdown.native="$props.dashboardSelection.setKpiValue(0)"
        v-on="$listeners"
      />
      <SwipeableNavigationCell
        v-if="$props.valueGroupVm.kpiValues.length > 1"
        v-bind:data-grid-id="gridIds.deltaTexts"
        v-bind:swiperVm="$props.valueGroupVm.deltaValuesSwiperVm"
        v-bind:swipeItems="deltaCellTexts"
        v-bind:zoomFactor="state.zoomFactor"
        v-bind:fontColor="'var(--headerTextColor)'"
        v-bind:isClickEnabled="canSwipe"
        v-bind:data-helpText="helpTextHelperCpsl.deltaTexts.value"
        v-on="$listeners"
      />
      <SwipeableDashboardValue
        v-if="deltaCellValues.length > 1"
        v-bind:data-grid-id="gridIds.deltaValues"
        v-bind:swiperVm="$props.valueGroupVm.deltaValuesSwiperVm"
        v-bind:swipeItems="deltaCellValues"
        v-bind:zoomFactor="state.zoomFactor"
        v-bind:isClickEnabled="canDrill"
        v-bind:isSwipeEnabled="canSwipe"
        v-bind:isScaled="$props.sharedState.kpiScaleIndex > 0"
        v-bind:sharedState="$props.sharedState"
        v-bind:useColorOrScaling="useColorOrScaling"
        v-bind:colorizeBackground="!$props.dashboardSettings.isExtended"
        v-bind:alignCenter="false"
        v-bind:data-helpText="helpTextHelperCpsl.deltaValues.value"
        v-on:swipeableDashboardValue_clicked="structureElementsCpsl.onValueLeftClicked"
        v-on:pointerdown.native="
          $props.dashboardSelection.setKpiValue(
            $props.valueGroupVm.deltaValuesSwiperVm.activeIndex
          )
        "
        v-on="$listeners"
      />
      <NavigationCell
        v-if="$props.valueGroupVm.kpiValues.length > 2"
        v-bind:data-grid-id="gridIds.deltaText_1"
        v-bind:displayText="$props.valueGroupVm.kpiValues[2].caption"
        v-bind:fontColor="'var(--headerTextColor)'"
        v-on="$listeners"
      />
      <NavigationCell
        v-if="$props.valueGroupVm.kpiValues.length > 1"
        v-bind:data-grid-id="gridIds.deltaText_2"
        v-bind:displayText="$props.valueGroupVm.kpiValues[1].caption"
        v-bind:fontColor="'var(--headerTextColor)'"
        v-on="$listeners"
      />
      <DashboardValue
        v-if="$props.valueGroupVm.kpiValues.length > 2"
        v-bind:data-grid-id="gridIds.deltaValue_1"
        v-bind:valueVm="$props.valueGroupVm.kpiValues[2]"
        v-bind:sharedState="$props.sharedState"
        v-bind:defaultTextColor="'var(--valueTextColor)'"
        v-bind:enableColorAndScaling="useColorOrScaling"
        v-bind:colorizeBackground="false"
        v-bind:disableBrackets="
          $props.sharedState.kpiScaleIndex !== $props.valueGroupVm.kpiValues[2].columnIdx
        "
        v-bind:isClickEnabled="canDrill"
        v-bind:data-helpText="helpTextHelperCpsl.deltaValue_1.value"
        v-on:valueLeftClicked="structureElementsCpsl.onValueLeftClicked"
        v-on:pointerdown.native="$props.dashboardSelection.setKpiValue(2)"
        v-on="$listeners"
      />
      <DashboardValue
        v-if="$props.valueGroupVm.kpiValues.length > 1"
        v-bind:data-grid-id="gridIds.deltaValue_2"
        v-bind:valueVm="$props.valueGroupVm.kpiValues[1]"
        v-bind:sharedState="$props.sharedState"
        v-bind:defaultTextColor="'var(--valueTextColor)'"
        v-bind:enableColorAndScaling="useColorOrScaling"
        v-bind:colorizeBackground="false"
        v-bind:disableBrackets="
          $props.sharedState.kpiScaleIndex !== $props.valueGroupVm.kpiValues[1].columnIdx
        "
        v-bind:isClickEnabled="canDrill"
        v-bind:data-helpText="helpTextHelperCpsl.deltaValue_2.value"
        v-on:valueLeftClicked="structureElementsCpsl.onValueLeftClicked"
        v-on:pointerdown.native="$props.dashboardSelection.setKpiValue(1)"
        v-on="$listeners"
      />
    </SimpleGrid>
    <StructureElements
      v-if="$props.valueGroupVm.structureElementsVm.isVisible"
      v-bind:structureElementsVm="$props.valueGroupVm.structureElementsVm"
      v-bind:scaleColor="scaleColor ? scaleColor : ''"
      v-bind:sharedState="$props.sharedState"
      v-bind:deltaValuesActiveIndex="$props.valueGroupVm.deltaIndexForDrill"
      v-bind:hasSparklines="$props.valueGroupVm.firstValueHasSparkline"
      v-bind:parentValueVm="$props.valueGroupVm.getScaledValue(sharedState, true)"
      v-bind:dashboardStatusType="state.dashboardStatusType"
      v-bind:firstValueWidth="state.firstValueWidth"
      v-bind:structureNames="structureNames"
      v-bind:zoomFactor="state.zoomFactor"
      v-bind:dashboardSelection="dashboardSelection"
      v-on="$listeners"
    />
  </div>
</template>

<style lang="less" scoped>
.valueGroup {
  background-color: var(--color_bg_white);
  position: relative;
  left: 0;
  width: 100%;
  padding-top: 5px;

  &.is-extended {
    padding-top: 0;
    border-bottom: 5px solid var(--color_bg-gray);

    &.is-compact {
      border-bottom: 1px solid var(--color_bg-gray);
    }

    div:last-child > & {
      border-bottom: 6px solid var(--color_bg_white);
    }
  }

  .backgroundImage {
    background-image: linear-gradient(
      var(--color_bg_white) calc(var(--headerOffset)),
      var(--headerBackgroundColor) calc(var(--headerOffset)) calc(var(--headerOffset))
    );
    background-repeat: no-repeat;
  }
}
</style>
