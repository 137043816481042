import { onBeforeMount, onBeforeUnmount, watch } from "vue";
import { IDisposable } from "./common/disposable.interface";
import { isMobile } from "./common/browser-detection";
import { INotificationService } from "./features/notifications/notification-service.interface";
import { Dm7App } from "./app";
import { IMediaQueries } from "./common/styles/media-queries.interface";
import { appResources } from "./app-resources";

export function useMenuHeightWarning(
  application: Dm7App,
  mediaQueries: IMediaQueries,
  notificationService: INotificationService
) {
  const state = { disposables: [] as IDisposable[], wasTooSmall: false };

  onBeforeMount(() => {
    if (isMobile()) {
      registerMobileMenuOrientationHandler();
    } else {
      registerDesktopMenuHeightHandler();
    }
  });

  onBeforeUnmount(() => {
    state.disposables.map((disp) => disp.dispose());
  });

  function registerMobileMenuOrientationHandler(): void {
    watch(() => application.mainMenuOpen, onMobileMenuHeight);

    screen.orientation.addEventListener("change", onMobileMenuHeight);
    state.disposables.push({
      dispose: () => screen.orientation.removeEventListener("change", onMobileMenuHeight),
    });
  }

  function registerDesktopMenuHeightHandler(): void {
    const disp = mediaQueries.throttledBrowserResized.on(onDesktopMenuHeight);
    state.disposables.push(disp);

    watch(() => application.mainMenuOpen, onDesktopMenuHeight);
  }

  function onMobileMenuHeight(): void {
    const isHeightEnoughForMenu = window.innerHeight > 480;
    if (isHeightEnoughForMenu) {
      return;
    }

    // include "landscape" so that we can check if the user actually has means to change the height
    const isLandscape = screen.orientation.type.startsWith("landscape");
    if (isLandscape && application?.mainMenuOpen) {
      notificationService.warn({
        title: appResources.menuTexts.errorMobileMenuPortraitTitle,
        message: appResources.menuTexts.errorMobileMenuPortraitMessage,
      });
    }
  }

  function onDesktopMenuHeight(): void {
    const isShowingMenu = application?.mainMenuOpen;
    const isTooSmall = window.innerHeight < 480;

    if (isTooSmall === state.wasTooSmall) {
      return;
    }

    if (isShowingMenu && isTooSmall && !state.wasTooSmall) {
      state.wasTooSmall = true;
      notificationService.warn({
        title: appResources.menuTexts.errorDesktopMenuHeightTitle,
        message: appResources.menuTexts.errorDesktopMenuHeightMessage,
      });
    } else {
      state.wasTooSmall = false;
    }
  }

  return {};
}
