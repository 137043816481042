<script lang="ts">
import { useHoverHelper } from "@/common/composables/hover-helper.cpsl";
import { DefaultStyles } from "@/common/styles/default-styles";
import { Ref, computed, defineComponent, ref } from "vue";
import type { CSSProperties } from "vue/types/jsx";

const colorConstants = DefaultStyles.colorConstants;

export default defineComponent({
  props: {
    mainColor: {
      type: String,
      default: "#000",
    },
    hoverColor: { type: String, default: colorConstants.darkTextHEX },
  },
  setup(props) {
    const ref_el: Ref<HTMLDivElement> = ref(null);
    const hoverHelperCpsl = useHoverHelper(ref_el);

    const colorVars = computed<CSSProperties>(() => {
      const isHover = props.hoverColor !== null && hoverHelperCpsl.isHover.value;

      const usedColor = props.mainColor;
      return {
        "--main-color": isHover ? props.hoverColor : usedColor,
      };
    });

    return {
      ref_el,
      colorVars,
    };
  },
});
</script>

<template>
  <div ref="ref_el" class="editIcon" v-bind:style="colorVars">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 132.166 88.113"
      style="enable-background: new 0 0 132.166 88.113"
      xml:space="preserve"
    >
      <path
        d="M34.081,85.057H0v-5.299h34.081V85.057z M34.081,53.172H0v5.299h34.081V53.172z M60.667,26.586H0v5.299h60.667V26.586z
       M87.253,0H0v5.299h87.253V0z M68.909,81.12L53.922,66.133l-6.993,21.98L68.909,81.12z M132.166,17.864L117.178,2.876L59.542,60.513
      L74.529,75.5L132.166,17.864z"
      />
    </svg>
  </div>
</template>

<style lang="less" scoped>
.editIcon {
  width: 100%;
  height: 100%;
  cursor: inherit;

  svg {
    width: 100%;
    height: 100%;
    fill: var(--main-color);
    stroke: var(--main-color);
    stroke-miterlimit: 10;
    width: 100%;
  }
}
</style>
