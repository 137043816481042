import { ChatMessage } from "@/services/chat-service/chat-message";
import { BubbleDirection } from "@/services/chat-service/bubble-direction";
import { getGUID } from "@/common/helper/guid-helper";

export class Bubble {
  chatMessages: ChatMessage[] = [];
  direction: BubbleDirection = "left";
  readonly id: string = getGUID();
  constructor(message: ChatMessage[] = [], direction: BubbleDirection) {
    this.chatMessages = message;
    this.direction = direction;
  }
}
