<script lang="ts">
import AppWizardButtons from "../helper-components/app-wizard-buttons.vue";
import AppWizardInstruction from "../helper-components/app-wizard-instruction.vue";
import AppWizardTabTemplate from "../helper-components/app-wizard-tab-template.vue";
import AppWizardSpinner from "../helper-components/app-wizard-spinner.vue";
import AppWizardError from "../helper-components/app-wizard-error.vue";
import LabelWithTooltip from "../helper-components/label-with-tooltip.vue";
import { BcDropdown } from "@bissantz/controls";
import { reactive, defineComponent, computed, onBeforeMount } from "vue";
import { AppWizardCommon } from "../app-wizard-common";
import { appResources } from "@/app-resources";
import { ApplicationVm } from "../view-models/application-vm";
import WizardInput from "../helper-components/wizard-input.vue";
import { BcInputUniqueValidator } from "../validators/bc-input-unique-validator";

export default defineComponent({
  components: {
    AppWizardTabTemplate,
    AppWizardButtons,
    AppWizardInstruction,
    BcDropdown,
    AppWizardSpinner,
    AppWizardError,
    LabelWithTooltip,
    WizardInput,
  },

  emits: [AppWizardCommon.events.confirmed, AppWizardCommon.events.cancelled],

  props: {
    viewModel: { type: ApplicationVm, required: true },
    showTabTemplate: Boolean,
  },

  setup(props, context) {
    const state = reactive({
      appWizardTexts: appResources.applicationWizardTexts,
      applicationError: "",
    });

    const textsError = new Map<string, string>([
      ["valueMissing", state.appWizardTexts.general_errorMinValue],
      [
        BcInputUniqueValidator.NotUniqueErrorCode,
        state.appWizardTexts.general_errorUniqueNameRequired,
      ],
    ]);

    onBeforeMount(async () => {
      await props.viewModel.init("+ " + state.appWizardTexts.application_newApplication);
    });

    const isNotLoadingDataAndNoError = computed(() => {
      return props.showTabTemplate && !state.applicationError;
    });

    async function onApplicationConfirmed() {
      if (!props.viewModel.isInAddNewApplicationMode) {
        await props.viewModel.useSelectedApplicationAsync();
        context.emit(AppWizardCommon.events.confirmed);
      } else {
        let result = await props.viewModel.createNewApplicationAsync();
        if (!result.succeeded) {
          state.applicationError = state.appWizardTexts.application_errorBody;
        } else {
          context.emit(AppWizardCommon.events.confirmed);
        }
      }
    }

    async function onWizardCancelled() {
      context.emit(AppWizardCommon.events.cancelled);
    }

    return {
      // general
      state,
      textsError,

      // emits:
      AppWizardCommon,

      //functions:
      onApplicationConfirmed: onApplicationConfirmed,
      onWizardCancelled: onWizardCancelled,

      // computed:
      isNotLoadingDataAndNoError,
    };
  },
});
</script>

<template>
  <div class="application-wizard_application application-wizard_tab">
    <AppWizardTabTemplate v-show="isNotLoadingDataAndNoError" class="padding-template">
      <template v-slot:inputFields>
        <div class="input-container">
          <LabelWithTooltip
            v-bind:labelText="state.appWizardTexts.application_publishedApplications"
            v-bind:tooltipText="state.appWizardTexts.application_infotextPubApp"
          />
          <BcDropdown
            class="lastDropdownItem center"
            type="select"
            design="7"
            v-model="$props.viewModel.selectedPublishedApplication"
            v-bind:fitContent="true"
            v-bind:numberOfVisibleItems="5"
            v-bind:items="$props.viewModel.publishedApplications"
            v-bind:disabled="$props.viewModel.isPubAppDisabled"
            itemKeyProperty="id"
            itemTextProperty="name"
            v-bind:placeholder="state.appWizardTexts.general_select"
          />
        </div>
        <div class="input-container" v-show="$props.viewModel.isInAddNewApplicationMode">
          <LabelWithTooltip
            v-bind:labelText="state.appWizardTexts.application_applicationGroup"
            v-bind:tooltipText="state.appWizardTexts.application_infotextAppGroup"
          />
          <BcDropdown
            overflowElement=".application-wizard"
            class="center"
            type="select"
            design="7"
            v-model="$props.viewModel.selectedApplicationGroup"
            v-bind:fitContent="true"
            v-bind:numberOfVisibleItems="5"
            v-bind:items="$props.viewModel.applicationGroups"
            itemKeyProperty="id"
            itemTextProperty="name"
            v-bind:placeholder="state.appWizardTexts.general_select"
          />
        </div>
        <div class="missing-group" v-if="$props.viewModel.isMissingAppGroup">
          {{ state.appWizardTexts.application_missingApplicationGroup }}
        </div>
        <div class="input-container" v-show="$props.viewModel.isInAddNewApplicationMode">
          <LabelWithTooltip
            v-bind:labelText="state.appWizardTexts.application_publishedApplicationGroup"
            v-bind:tooltipText="state.appWizardTexts.application_infotextPubAppGroup"
          />
          <BcDropdown
            overflowElement=".application-wizard"
            class="center"
            type="select"
            design="7"
            v-model="$props.viewModel.selectedPublishedApplicationGroup"
            v-bind:fitContent="true"
            v-bind:numberOfVisibleItems="5"
            v-bind:items="$props.viewModel.publishedApplicationGroups"
            itemKeyProperty="id"
            itemTextProperty="name"
            v-bind:placeholder="state.appWizardTexts.general_select"
          />
        </div>
        <div class="missing-group" v-if="$props.viewModel.isMissingPubAppGroup">
          {{ state.appWizardTexts.application_missingPubApplicationGroup }}
        </div>
        <div class="input-container" v-show="$props.viewModel.isInAddNewApplicationMode">
          <LabelWithTooltip
            v-bind:labelText="state.appWizardTexts.application_applicationName"
            v-bind:tooltipText="state.appWizardTexts.application_infotextAppName"
          />
          <WizardInput
            class="center"
            v-model="$props.viewModel.enteredApplicationName"
            v-bind:required="true"
            v-bind:fitContent="true"
            v-bind:errors="textsError"
            v-bind:uniqueValidator="$props.viewModel.applicationNameValidator"
          />
        </div>
      </template>
      <template v-slot:infotext>
        <AppWizardInstruction
          v-show="!$props.viewModel.isInAddNewApplicationMode"
          v-bind:instructionText="state.appWizardTexts.application_instructionText"
        />
      </template>
      <template v-slot:buttons>
        <AppWizardButtons
          v-on:confirmed="onApplicationConfirmed"
          v-on:cancelled="onWizardCancelled"
          v-bind:disabled="!$props.viewModel.canContinueToNextStep"
        />
      </template>
    </AppWizardTabTemplate>
    <AppWizardError
      v-show="!!state.applicationError"
      v-bind:errorHeader="state.appWizardTexts.application_errorHeader"
      v-bind:errorBody="state.applicationError"
    />
    <AppWizardSpinner v-show="$props.viewModel.isSendingDataToBackend" />
  </div>
</template>

<style lang="less" scoped>
@import "./tabs.less";

.application-wizard_application {
  .padding-template {
    padding: 75px clamp(50px, 9vw, 100px) 0 clamp(50px, 9vw, 100px);
  }
}
</style>
