<script lang="ts">
import XIcon from "@/common/components/icons/x-icon.vue";
import BissantzLogo from "@/common/components/logos/bissantz-logo.vue";
import AppWizardLogo from "@/common/components/logos/app-wizard-logo.vue";
import AppWizardDatasource from "./tabs/app-wizard-datasource.vue";
import AppWizardApplication from "./tabs/app-wizard-application.vue";
import AppWizardModelling from "./tabs/app-wizard-modelling.vue";
import AppWizardTabsOverview from "./helper-components/app-wizard-tabs-overview.vue";

import { appResources } from "@/app-resources";
import { DefaultStyles } from "@/common/styles/default-styles";
import { inject, reactive, defineComponent, computed, onBeforeUnmount } from "vue";
import { AppWizardService } from "@/services/app-wizard-service";
import { AppWizardVm, WizardFinishedEventArgs } from "./view-models/app-wizard-vm";
import { useBackendDependencies } from "./backend-wrapper/backend-dependencies.cpsl";
import { useTransaction } from "@/common/transactions/transaction.cspl";
import { IPortalSelection } from "../portal/contract/portal-selection.interface";
import { PortalVm } from "./view-models/portal-vm";
import { Application } from "@bissantz/smartforms";

export default defineComponent({
  components: {
    XIcon,
    AppWizardLogo,
    BissantzLogo,
    AppWizardDatasource,
    AppWizardApplication,
    AppWizardModelling,
    AppWizardTabsOverview,
  },

  setup() {
    const portalSelection = inject("portalSelection") as IPortalSelection;
    const appWizardService: AppWizardService = inject("appWizardService");
    const application = inject("application") as Application;

    const {
      connectionFacade,
      applicationFacade,
      modelFacade,
      reportFacade,
      portalFacade,
    } = useBackendDependencies();
    const transactionFacade = useTransaction();

    const colorIcon = DefaultStyles.colorConstants.weatherMinus0;
    const colorBissantzLogo = DefaultStyles.colorConstants.bgGray;
    const colorBissantzLogoHover = DefaultStyles.colorConstants.weatherPlus3;

    const portalViewModel = new PortalVm(
      portalFacade,
      portalSelection.portalId,
      portalSelection.pageId
    );

    const appWizardViewModel = new AppWizardVm(
      connectionFacade,
      applicationFacade,
      modelFacade,
      reportFacade,
      transactionFacade,
      portalViewModel,
      appWizardService.isEditMode,
      appWizardService.appEntry
    );

    const state = reactive({
      appWizardTabs: [
        {
          name: appResources.applicationWizardTexts.general_labelDatasource,
          component: "app-wizard-datasource",
        },
        {
          name: appResources.applicationWizardTexts.general_labelApplication,
          component: "app-wizard-application",
        },
        {
          name: appResources.applicationWizardTexts.general_labelModelling,
          component: "app-wizard-modelling",
        },
      ],
      appWizardVm: appWizardViewModel,
      activeTabViewModel: appWizardViewModel.activeTabVm,
      isNavigatingToNextStep: false,
      disposableListeners: [],
    });

    state.disposableListeners.push(
      appWizardViewModel.wizardFinished.on(onWizardFinished)
    );

    onBeforeUnmount(() => {
      state.disposableListeners.map((disposer) => disposer.dispose());
      appWizardViewModel?.dispose();
    });

    const showTemplate = computed(() => {
      return !state.appWizardVm.isSendingDataToBackend && !state.isNavigatingToNextStep;
    });

    async function onWizardCancelled() {
      await state.appWizardVm.cancel();
      appWizardService.closeApplication();
    }

    async function onWizardStepConfirmed() {
      state.isNavigatingToNextStep = true;

      const newStepNumber = state.appWizardVm.activeTab + 1;

      state.activeTabViewModel = await state.appWizardVm.getViewModelForStep(
        newStepNumber
      );

      state.appWizardVm.activeTab = newStepNumber;

      state.isNavigatingToNextStep = false;
    }

    async function onWizardFinished(e: WizardFinishedEventArgs) {
      if (appWizardService.isEditMode && e.wizardSucceeded) {
        await reloadPortalAsync();
      }
      if (e.wizardSucceeded && e.portalTileHasBeenCreated) {
        await reloadPortalAsync();
      }
    }

    async function reloadPortalAsync(): Promise<void> {
      await application.content.find((c) => c.contentId == "portal").navigate();
    }

    return {
      // general
      state,

      // refs:
      appWizardService,
      colorIcon,
      colorBissantzLogo,
      colorBissantzLogoHover,

      //functions:
      onWizardCancelled: onWizardCancelled,
      onWizardStepConfirmed: onWizardStepConfirmed,

      // computed
      showTemplate,
    };
  },
});
</script>

<template>
  <div class="application-wizard">
    <div class="banner">
      <AppWizardLogo />
      <div v-on:click="appWizardService.closeApplication()" class="close">
        <XIcon v-bind:initialColor="colorIcon" />
      </div>
    </div>
    <div>
      <AppWizardTabsOverview
        v-bind:activeTab="state.appWizardVm.activeTab"
        v-bind:names="state.appWizardTabs.map((tab) => tab.name)"
      />
    </div>

    <component
      class="active-component"
      v-bind:is="
        state.appWizardTabs.map((tab) => tab.component)[state.appWizardVm.activeTab]
      "
      v-bind:viewModel="state.activeTabViewModel"
      v-bind:showTabTemplate="showTemplate"
      v-on:confirmed="onWizardStepConfirmed"
      v-on:cancelled="onWizardCancelled"
    />
    <div>
      <a class="logo" href="https://bissantz.de/" target="_blank"
        ><BissantzLogo v-bind:hoverColor="colorBissantzLogoHover"
      /></a>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "../../common/styles/base-styles.less";

.application-wizard {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  background-color: var(--color_bg_white);
  box-shadow: 0px 0px 12px 6px #0000000d;
  color: var(--color_darkText);

  border: none;
  width: clamp(200px, 90vw, 900px);
  height: 630px;
  border-radius: 6px;
  overflow: hidden;
  padding-bottom: 32px;

  .banner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    background-color: var(--color_bg-gray);
  }

  .close {
    position: absolute;
    right: 11px;
    top: 11px;
    height: 12px;
    width: 12px;
    cursor: pointer;
  }

  .logo {
    display: inline-block;
    margin-left: 38px;
  }
}
</style>
