import { IApplicationFacade } from "../backend-wrapper/application-facade.interface";
import { IConnectionFacade } from "../backend-wrapper/connection-facade.interface";
import { IModelFacade } from "../backend-wrapper/model-facade.interface";
import { ITransactionFacade } from "@/common/transactions/transaction-facade.interface";
import { DatasourceVm } from "./datasource-vm";
import { ApplicationVm } from "./application-vm";
import { ModellingVm, ReportImportFinishedEventArgs } from "./modelling-vm";
import { PortalVm } from "./portal-vm";
import { TypedEvent } from "@/common/events/typed-event";
import { AppEntry } from "@/features/main-menu/application-menu/helper-components/menus/app-entry";
import { IReportFacade } from "../backend-wrapper/report-facade-interface";

export class WizardFinishedEventArgs {
  constructor(
    public readonly wizardSucceeded: boolean,
    public readonly portalTileHasBeenCreated: boolean
  ) {}
}

export class AppWizardVm {
  private readonly _datasourceVm: DatasourceVm = null;
  private _applicationVm: ApplicationVm = null;
  private _modellingVm: ModellingVm = null;
  activeTabVm: DatasourceVm | ApplicationVm | ModellingVm;
  activeTab: number = 0;

  public readonly wizardFinished = new TypedEvent<WizardFinishedEventArgs>();

  get isSendingDataToBackend() {
    if (this._datasourceVm?.isSendingDataToBackend) {
      return true;
    }

    if (this._applicationVm?.isSendingDataToBackend === true) {
      return true;
    }

    if (this._modellingVm?.isSendingDataToBackend === true) {
      return true;
    }

    return false;
  }

  get portalTileHasBeenCreated(): boolean {
    return this._applicationVm?.portalTileHasBeenCreated;
  }

  get dataSourceVm(): DatasourceVm {
    return this._datasourceVm;
  }

  constructor(
    private readonly _connectionFacade: IConnectionFacade,
    private readonly _applicationFacade: IApplicationFacade,
    private readonly _modelFacade: IModelFacade,
    private readonly _reportFacade: IReportFacade,
    private readonly _transactionFacade: ITransactionFacade,
    private readonly _portalVm: PortalVm,
    private readonly _isEditMode: boolean,
    private readonly _appEntry: AppEntry
  ) {
    this._datasourceVm = new DatasourceVm(_connectionFacade, _transactionFacade);

    if (!_isEditMode) {
      this.activeTabVm = this.dataSourceVm;
    } else {
      this._buildAppAndModellingVm();
    }
  }

  private async _buildAppAndModellingVm() {
    this.activeTab = 2;
    this._modellingVm?.dispose();

    this.activeTabVm = new ModellingVm(
      this._isEditMode,
      this._connectionFacade,
      this._modelFacade,
      this._reportFacade,
      this._transactionFacade,
      "",
      this._appEntry.deltaMasterPubApp,
      this._appEntry.dashboardPubApp.id
    );

    this._modellingVm = this.activeTabVm;
    this._modellingVm.reportImportFinished.on((e) => this._onReportImportFinished(e));
  }

  async getViewModelForStep(
    stepNumber: number
  ): Promise<DatasourceVm | ApplicationVm | ModellingVm> {
    if (stepNumber === 0) {
      return this._datasourceVm;
    }

    if (stepNumber === 1) {
      this._applicationVm = await this._buildApplicationVm();
      return this._applicationVm;
    }

    if (stepNumber === 2) {
      this._modellingVm?.dispose();
      this._modellingVm = await this._buildModellingVm();
      this._modellingVm.reportImportFinished.on((e) => this._onReportImportFinished(e));
      return this._modellingVm;
    }

    return null;
  }

  async cancel() {
    await this._transactionFacade.rollbackActiveTransactionIfAny();
  }

  private async _buildApplicationVm(): Promise<ApplicationVm> {
    const connectionSetId = await this._datasourceVm.getCurrentConnectionSetId();

    if (connectionSetId === null) {
      return null;
    }

    return new ApplicationVm(
      this._applicationFacade,
      this._transactionFacade,
      this._portalVm,
      connectionSetId
    );
  }

  private async _buildModellingVm(): Promise<ModellingVm> {
    const currentPublishedApplication =
      await this._applicationVm.getCurrentPublishedApplication();

    if (currentPublishedApplication === null) {
      return null;
    }

    return new ModellingVm(
      this._isEditMode,
      this._connectionFacade,
      this._modelFacade,
      this._reportFacade,
      this._transactionFacade,
      this._datasourceVm.currentConnectionId,
      currentPublishedApplication,
      this._applicationVm.currentDashboardPublishedApplicationId
    );
  }

  private _onReportImportFinished(e: ReportImportFinishedEventArgs): void {
    this.wizardFinished.emit(
      new WizardFinishedEventArgs(e.importSucceeded, this.portalTileHasBeenCreated)
    );
  }

  dispose(): void {
    this._modellingVm?.dispose();
    this.wizardFinished.removeAllListeners();
  }
}
