<script lang="ts">
import { defineComponent, computed } from "vue";
import type { CSSProperties } from "vue/types/jsx";

export default defineComponent({
  props: {
    color: { type: String, default: null },
  },

  setup(props) {
    //
    // Life Cycle:
    // --------------------

    //
    // Computeds:
    // --------------------
    const colorVars = computed<CSSProperties>(() => {
      return {
        "--main-color": props.color ? props.color : "var(--color_neutralText)",
      };
    });

    //
    // Functions:
    // --------------------

    //
    // Watcher:
    // --------------------

    return {
      colorVars,
    };
  },
});
</script>

<template>
  <div class="exclude-from-scaling-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 210 210"
      v-bind:style="colorVars"
    >
      <circle class="cls-1" cx="105" cy="105" r="66.5" />
      <line class="cls-1" x1="58.4" y1="58.4" x2="151.6" y2="151.6" />
    </svg>
  </div>
</template>

<style lang="less" scoped>
.exclude-from-scaling-icon {
  height: 100%;

  svg {
    height: 100%;

    .cls-1 {
      fill: none;
      stroke: var(--main-color);
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 12px;
    }
  }
}
</style>
