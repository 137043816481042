import { appResources } from "@/app-resources";

export function useHelpText() {
  function kpiHeaderTexts(canClick: boolean, columnIndex: number): string {
    if (!canClick) {
      return "";
    }
    if (columnIndex === -1) {
      return appResources.helpTexts.changePeriod;
    }
    if (columnIndex === 0) {
      return appResources.helpTexts.changeValueGroup;
    }
    return appResources.helpTexts.changeDeviation;
  }

  function kpiValueTexts(
    isExcludedFromScaling: boolean,
    kpiScaleIndex: number,
    columnIndex: number,
    hasStructures: boolean,
    showSparkline: boolean,
    showStructureElements: boolean,
    canExcludeFromScaling: boolean
  ): string {
    if (kpiScaleIndex !== columnIndex) {
      return otherKpiValueText(
        kpiScaleIndex,
        columnIndex,
        hasStructures,
        showSparkline,
        showStructureElements
      );
    }

    return sameKpiValueText(
      isExcludedFromScaling,
      kpiScaleIndex,
      hasStructures,
      showSparkline,
      showStructureElements,
      canExcludeFromScaling
    );
  }

  function otherKpiValueText(
    kpiScaleIndex: number,
    columnIndex: number,
    hasStructures: boolean,
    showSparkline: boolean,
    showStructureElements: boolean
  ): string {
    if (kpiScaleIndex === -1) {
      return showStructureElements
        ? appResources.helpTexts.closeDrill
        : appResources.helpTexts.drill;
    }

    if (columnIndex === -1) {
      return joinTexts([
        appResources.helpTexts.showAccurateNumbers,
        changePeriodText(showSparkline),
      ]);
    }

    if (showSparkline) {
      return hasStructures ? drillText(showStructureElements) : "";
    }

    return appResources.helpTexts.changeTypographicalScaling;
  }

  function sameKpiValueText(
    isExcludedFromScaling: boolean,
    kpiScaleIndex: number,
    hasStructures: boolean,
    showSparkline: boolean,
    showStructureElements: boolean,
    canExcludeFromScaling: boolean
  ): string {
    if (!hasStructures) {
      return periodScalingText(isExcludedFromScaling, showSparkline, kpiScaleIndex);
    }

    if (!canExcludeFromScaling) {
      return drillText(showStructureElements);
    }

    return joinTexts([
      drillText(showStructureElements),
      periodScalingText(isExcludedFromScaling, showSparkline, kpiScaleIndex),
    ]);
  }

  function periodScalingText(
    isExcludedFromScaling: boolean,
    showSparkline: boolean,
    kpiScaleIndex: number
  ): string {
    return kpiScaleIndex === -1
      ? changePeriodText(showSparkline)
      : scalingText(isExcludedFromScaling);
  }

  function scalingText(isExcludedFromScaling: boolean): string {
    if (isExcludedFromScaling) {
      return appResources.helpTexts.includeToScaling;
    }

    return appResources.helpTexts.excludeFromScaling;
  }

  function drillText(showStructureElemenst: boolean): string {
    return showStructureElemenst
      ? appResources.helpTexts.closeDrill
      : appResources.helpTexts.drill;
  }

  function changePeriodText(showSparkline: boolean): string {
    return showSparkline ? appResources.helpTexts.changePeriod : "";
  }

  function elementDrillText(
    structureElementsVisible: boolean,
    hasStructures: boolean
  ): string {
    if (!hasStructures) {
      return "";
    }
    return structureElementsVisible
      ? appResources.helpTexts.closeElementDrill
      : appResources.helpTexts.drillElement;
  }

  function sparklineText(isValueSelectionEnabled: boolean): string {
    return isValueSelectionEnabled
      ? appResources.helpTexts.changeSparkline
      : appResources.helpTexts.selectSparkline;
  }

  function showAvailablePortalPagesText(hasMultiplePages: boolean): string {
    return hasMultiplePages ? appResources.helpTexts.showAvailablePortalPages : "";
  }

  function switchPortalPagesText(hasMultiplePages: boolean): string {
    return hasMultiplePages ? appResources.helpTexts.switchPortalPages : "";
  }

  function sparklineButtonText(
    canShowSparklinesButton: boolean,
    isSparklineDisabled: boolean
  ): string {
    if (!canShowSparklinesButton) {
      return "";
    }
    return isSparklineDisabled
      ? appResources.helpTexts.showSparklines
      : appResources.helpTexts.hideSparklines;
  }

  function kpiShrinkExpandText(
    shrink: boolean,
    isShowAllKpiActive: boolean,
    isCompact: boolean,
    isExtended: boolean,
    isCompactAllowed: boolean
  ): string {
    return shrink
      ? kpiShrinkText(isShowAllKpiActive, isCompact, isExtended, isCompactAllowed)
      : kpiExpandText(isShowAllKpiActive, isCompact, isExtended);
  }

  function kpiShrinkText(
    isShowAllKpiActive: boolean,
    isCompact: boolean,
    isExtended: boolean,
    isCompactAllowed: boolean
  ): string {
    if (isCompactAllowed && isExtended && isShowAllKpiActive && !isCompact) {
      return appResources.helpTexts.collapseKpisToCompact;
    }

    return appResources.helpTexts.collapseKpis;
  }

  function kpiExpandText(
    isShowAllKpiActive: boolean,
    isCompact: boolean,
    isExtended: boolean
  ): string {
    if (isExtended && isShowAllKpiActive && isCompact) {
      return appResources.helpTexts.expandKpisToDetailed;
    }

    return appResources.helpTexts.expandKpis;
  }

  function shareButtonText(isDisabled: boolean): string {
    return isDisabled ? "" : appResources.helpTexts.takeScreenshot;
  }

  function deletePortalTileText(): string {
    return appResources.helpTexts.deletePortalTile;
  }

  function elementValueText(
    canToggle: boolean,
    canSort: boolean,
    canChangeDeviation = false
  ): string {
    const texts = [""];

    if (canSort && canToggle) {
      texts.push(appResources.helpTexts.sortToggleElementValue);
    } else if (canSort) {
      texts.push(appResources.helpTexts.sortElementValue);
    } else if (canToggle) {
      texts.push(appResources.helpTexts.toggleElementValue);
    }

    if (canChangeDeviation) {
      texts.push(appResources.helpTexts.changeElementDeviation);
    }

    return joinTexts(texts);
  }

  function changeStructureText(hasStructurtes: boolean): string {
    return hasStructurtes ? appResources.helpTexts.changeStructure : "";
  }

  function elementTitleText(canSort: boolean): string {
    if (canSort) {
      return appResources.helpTexts.setFilterSortElementText;
    }

    return appResources.helpTexts.setFilterElementText;
  }

  function clonePortalTileText(): string {
    return appResources.helpTexts.clonePortalTile;
  }

  function shrinkDashboardText(): string {
    return appResources.helpTexts.shrinkDashboard;
  }

  function expandDashboardText(): string {
    return appResources.helpTexts.expandDashboard;
  }

  function joinTexts(texts: string[]): string {
    let text = "";
    for (let i = 0; i < texts.length; i++) {
      if (!texts[i] || texts[i].length === 0) {
        continue;
      }

      if (text.length === 0) {
        text = texts[i];
      } else {
        text = [text, texts[i]].join(" | ");
      }
    }

    return text;
  }

  const tileWidthToggleDefaultText = {
    shrink: appResources.helpTexts.shrinkTile,
    expand: appResources.helpTexts.enlargeTile,
  };

  function fullpageToggleText(isFullpage: boolean): string {
    return isFullpage
      ? appResources.helpTexts.minimizeTile
      : appResources.helpTexts.maximizeTile;
  }

  function editmodeToggleText(isEditing: boolean): string {
    return isEditing
      ? appResources.helpTexts.disableEditPortal
      : appResources.helpTexts.enableEditPortal;
  }

  return {
    kpiHeaderTexts,
    kpiValueTexts,
    elementDrillText,
    sparklineText,
    showAvailablePortalPagesText,
    switchPortalPagesText,
    sparklineButtonText,
    kpiShrinkExpandText,
    shareButtonText,
    deletePortalTileText,
    elementValueText,
    changeStructureText,
    elementTitleText,
    clonePortalTileText,
    shrinkDashboardText,
    expandDashboardText,
    tileWidthToggleDefaultText,
    fullpageToggleText,
    editmodeToggleText,
  };
}
