/**
 * A.k.a: isNotNullOrUndefined
 */
export function exists(value: unknown): boolean {
  //could also use: value != null, but this is more explicit
  return value !== null && value !== undefined;
}

export function existsAndNotZero(value: number) {
  return exists(value) && value !== 0;
}

export function existsAndNotEmpty(value: string) {
  return exists(value) && value.trim() !== "";
}
