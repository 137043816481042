<script lang="ts">
import BissantzSpinner from "@/common/components/bissantz-spinner.vue";

import { defineComponent } from "vue";

export default defineComponent({
  components: { BissantzSpinner },
});
</script>

<template>
  <div class="kpiPlaceHolder">
    <div class="topTitle paddingLeft"><span>|</span></div>

    <div class="kpiFrame loadingBackgroundColor">
      <div class="firstSection borderRight">&nbsp;</div>
      <div class="secondSection borderRight">&nbsp;</div>
      <div class="thirdSection">&nbsp;</div>
    </div>
    <div class="empty loadingBackgroundColor">
      <div class="spinnerWrapper">
        <BissantzSpinner></BissantzSpinner>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.kpiPlaceHolder {
  background-color: var(--color_bg_white);
  position: relative;
  left: 0;
  width: 100%;

  .topTitle {
    background-color: var(--color_bg_white);
    color: transparent;
    width: 0;
    height: calc(1px * var(--headerHeight) + 5px);
  }

  .kpiFrame {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    height: calc(1px * var(--headerHeight) - 2px);
    border-bottom: solid 1px var(--color_bg_white);

    .firstSection {
      min-width: 75px;
      max-width: 75px;
    }

    .secondSection {
      flex: 1 1 40.4%;
      display: flex;
      flex-direction: row;
    }

    .thirdSection {
      flex: 1 1 37.6%;
      display: flex;
      flex-direction: row;
    }
  }

  .empty {
    height: 32px;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .loadingBackgroundColor {
    background-color: var(--color_Weather0);
  }

  .spinnerWrapper {
    margin: auto;
    height: 5em;
    width: 5em;
    font-size: 4.5px;
    border-color: var(--color_headerText);
  }

  .borderRight {
    border-right: 1px solid var(--color_bg_white);
  }

  .paddingLeft {
    padding-left: 5px;
  }
}
</style>
