<script lang="ts">
import { defineComponent } from "vue";
import BissantzSpinner from "@/common/components/bissantz-spinner.vue";
import { DefaultStyles } from "@/common/styles/default-styles";

export default defineComponent({
  components: {
    BissantzSpinner,
  },

  props: {
    spinnerColor: {
      type: String,
      required: false,
      default: () => DefaultStyles.colorConstants.headerTextHEX,
    },
  },
});
</script>
<template>
  <div class="spinner-wrapper">
    <BissantzSpinner />
  </div>
</template>

<style lang="less" scoped>
.spinner-wrapper {
  height: 24px;
  width: 24px;
  // sets spinner line width:
  font-size: 3px;
  margin: auto;
  border-color: v-bind("$props.spinnerColor");
}
</style>
