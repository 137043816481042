<script lang="ts">
import ReportModellingAvailableOptions from "./report-modelling-available-options.vue";
import ReportModellingSelectedOptions from "./report-modelling-selected-options.vue";
import AppWizardSpinner from "../../../helper-components/app-wizard-spinner.vue";
import { reactive, defineComponent } from "vue";
import { ModellingVm } from "../../../view-models/modelling-vm";
import { appResources } from "@/app-resources";

export default defineComponent({
  components: {
    ReportModellingAvailableOptions: ReportModellingAvailableOptions,
    ReportModellingSelectedOptions: ReportModellingSelectedOptions,
    AppWizardSpinner,
  },

  props: {
    modellingVm: { type: ModellingVm, required: true },
  },

  setup() {
    const state = reactive({
      appWizardTexts: appResources.applicationWizardTexts,
    });

    return {
      state,
    };
  },
});
</script>

<template>
  <div
    class="application-wizard_report-modelling"
    v-if="$props.modellingVm.isReportModellingAvailable"
  >
    <ReportModellingAvailableOptions
      v-bind:navigationVm="$props.modellingVm.navigationVm"
    />
    <ReportModellingSelectedOptions v-bind:modellingVm="$props.modellingVm" />
    <div
      class="data-loading-spinner"
      v-show="$props.modellingVm.navigationVm.isLoadingData"
    >
      <AppWizardSpinner />
    </div>
  </div>
</template>

<style lang="less" scoped>
.application-wizard_report-modelling {
  position: relative;
  padding: 0 50px;
  display: flex;
  width: 100%;
  flex: 1;
  margin: 18px 0px;

  .data-loading-spinner {
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    left: 0;
  }
}
</style>
