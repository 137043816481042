<script lang="ts">
import { defineComponent } from "vue";
import AppWizardInfoTooltip from "./app-wizard-info-tooltip.vue";

export default defineComponent({
  components: { AppWizardInfoTooltip },

  props: {
    labelText: { type: String, default: "" },
    tooltipText: { type: String, default: "" },
  },
});
</script>

<template>
  <div class="label-alignment">
    <AppWizardInfoTooltip v-bind:informationText="$props.tooltipText" />
    <label v-text="$props.labelText" />
  </div>
</template>
