import { inject, provide } from "vue";
import { AxiosInstance } from "axios";

import {
  UrlApplicationDefinitionsServiceClient,
  IUrlApplicationDefinitionsServiceClient,
} from "@/common/service-clients/generated-clients";
import { UrlAppFacade } from "./url-app-facade";

export function useBackendDependencies() {
  const axiosInstance = inject("axiosInstance", null) as AxiosInstance;

  // list of used service clients:
  let urlAppClient: IUrlApplicationDefinitionsServiceClient = null;

  instantiateUsedServiceClients(axiosInstance);

  // Create Facades:
  // ---------------
  let urlAppFacade = inject<UrlAppFacade>("urlAppFacade", null);
  if (!urlAppFacade) {
    urlAppFacade = new UrlAppFacade(urlAppClient);
    provide("urlAppFacade", urlAppFacade);
  }

  // Functions:
  // ---------------
  function instantiateUsedServiceClients(axiosInstance: AxiosInstance): void {
    urlAppClient = new UrlApplicationDefinitionsServiceClient(null, axiosInstance);
  }

  return {
    urlAppFacade,
  };
}
