import {
  DataSourceAutoModelCreationRequestDto,
  Dm7ApplicationCreationRequestDto,
  IDashboardsServiceClient,
  IDataSourceAutoModelCreationRequestDto,
  IDm7ApplicationCreationRequestDto,
  IDm7ApplicationsServiceClient,
  IOlapCubeAutoModelCreationRequestDto,
  IOlapDatabaseCubeDto,
  IPublishedApplicationsServiceClient,
  IReportCreationRequestDto,
  IReportGroupCreationRequestDto,
  IReportGroupsServiceClient,
  IReportsServiceClient,
  ISemanticModelCreationRequestDto,
  ISemanticModelsServiceClient,
  OlapCubeAutoModelCreationRequestDto,
  ReportCreationRequestDto,
  ReportGroupCreationRequestDto,
  SemanticModelCreationRequestDto,
  WorkItemDtoOfImportFromReportResultState,
  Dm7ApplicationDto,
  SemanticModelDto,
  ReportingItemDto,
  IReportingItemFilterDto,
  ReportDto,
  ReportingItemFilterDto,
  IReportingItemsServiceClient,
} from "@/common/service-clients/generated-clients";
import { LongRunningWorkServiceClient } from "@/common/service-clients/long-running-work-service-client";
import { IModelFacade } from "./model-facade.interface";
import { ISemanticModelParams } from "./semantic-model-params.interface";
import { Result } from "@/common/results/result";
import { FailedReason } from "@/common/results/failed-reason";
import { ValueResult } from "@/common/results/value-result";
import { ReportCreationResult } from "./report-creation-result";
import { ImportFromReportParams } from "./import-from-report-params";
import { ApplicationNameLink } from "./application-name-link";

export class ModelFacade implements IModelFacade {
  constructor(
    private readonly _publishedApplicationClient: IPublishedApplicationsServiceClient,
    private readonly _semanticModelClient: ISemanticModelsServiceClient,
    private readonly _dm7ApplicationsClient: IDm7ApplicationsServiceClient,
    private readonly _reportGroupsClient: IReportGroupsServiceClient,
    private readonly _reportsClient: IReportsServiceClient,
    private readonly _dashboardsClient: IDashboardsServiceClient,
    private readonly _longRunningWorkClient: LongRunningWorkServiceClient,
    private readonly _reportingItemsClient: IReportingItemsServiceClient
  ) {}

  async createSemanticModelAndReportAsync(
    params: ISemanticModelParams
  ): Promise<ValueResult<ReportCreationResult, FailedReason>> {
    try {
      return await this._createSemanticModelAndReportAsync(params);
    } catch (error) {
      return ValueResult.createFromFailedReason(error);
    }
  }

  private async _createSemanticModelAndReportAsync(
    params: ISemanticModelParams
  ): Promise<ValueResult<ReportCreationResult, FailedReason>> {
    try {
      const semanticModelId = await this._createSemanticModelAsync(
        params.name,
        params.applicationVersionId
      );

      const dataSourceId = await this._createDataSourceAsync(
        params.designatedConnectionId,
        semanticModelId
      );

      await this._createOlapCubeAsync(
        dataSourceId,
        params.selectedCube,
        params.publishedApplicationId
      );

      const dm7ApplicationId = await this._createDm7ApplicationAsync(
        params.applicationVersionId,
        params.name,
        semanticModelId
      );

      const reportGroupId = await this._createReportGroupAsync(
        dm7ApplicationId,
        params.name
      );
      const reportId = await this._createReportAsync(reportGroupId, params.name);

      const reportCreationResult = new ReportCreationResult(semanticModelId, reportId);

      return ValueResult.createFromValue(reportCreationResult);
    } catch (error) {
      return ValueResult.createFromError(error);
    }
  }

  async importFromReportAsync(
    deltaMasterPublishedApplicationId: string,
    dashboardPublishedApplicationId: string,
    reportId: string,
    name: string,
    publishedApplicationGroupId: string
  ): Promise<Result<FailedReason>> {
    try {
      await this._importFromReportAsync(
        deltaMasterPublishedApplicationId,
        dashboardPublishedApplicationId,
        reportId,
        name,
        publishedApplicationGroupId
      );
    } catch (e) {
      return Result.createFromFailedReason(e);
    }
    return new Result();
  }

  private async _createSemanticModelAsync(
    name: string,
    applicationVersionId: string
  ): Promise<string> {
    const semanticModelCreationRequest: ISemanticModelCreationRequestDto = {
      name: name,
      description: null,
      applicationVersionId,
    };
    return await this._semanticModelClient.createSemanticModel(
      new SemanticModelCreationRequestDto(semanticModelCreationRequest)
    );
  }

  private async _createDataSourceAsync(
    designatedConnectionId: string,
    semanticModelId: string
  ): Promise<string> {
    const dataSourceCreationRequest: IDataSourceAutoModelCreationRequestDto = {
      designatedConnectionId,
      semanticModelId,
    };
    return await this._semanticModelClient.createDataSourceAutoModel(
      new DataSourceAutoModelCreationRequestDto(dataSourceCreationRequest)
    );
  }

  private async _createOlapCubeAsync(
    dataSourceId: string,
    olapDatabaseCube: IOlapDatabaseCubeDto,
    publishedApplicationId: string
  ): Promise<string> {
    const olapCubeCreationRequest: IOlapCubeAutoModelCreationRequestDto = {
      dataSourceId,
      olapDatabaseCube,
      publishedApplicationId,
    };
    return await this._semanticModelClient.createOlapCubeAutoModel(
      new OlapCubeAutoModelCreationRequestDto(olapCubeCreationRequest)
    );
  }

  private async _createDm7ApplicationAsync(
    applicationVersionId: string,
    name: string,
    semanticModelId: string
  ): Promise<string> {
    const dm7ApplicationCreationRequest: IDm7ApplicationCreationRequestDto = {
      applicationVersionId: applicationVersionId,
      description: "",
      name: name,
      semanticModelId: semanticModelId,
    };

    return await this._dm7ApplicationsClient.createDm7Application(
      new Dm7ApplicationCreationRequestDto(dm7ApplicationCreationRequest)
    );
  }

  private async _createReportGroupAsync(
    dm7ApplicationId: string,
    name: string
  ): Promise<string> {
    const reportGroupCreationRequest: IReportGroupCreationRequestDto = {
      parentGroupId: null,
      dm7ApplicationId,
      name: name,
      description: "",
    };
    return await this._reportGroupsClient.createReportGroup(
      new ReportGroupCreationRequestDto(reportGroupCreationRequest)
    );
  }

  private async _createReportAsync(reportGroupId: string, name: string): Promise<string> {
    const reportCreationRequest: IReportCreationRequestDto = {
      parentGroupId: reportGroupId,
      reportDefinitionType: "DashboardReport",
      name: name,
      description: "",
    };
    return await this._reportsClient.createReport(
      new ReportCreationRequestDto(reportCreationRequest)
    );
  }

  private async _importFromReportAsync(
    deltaMasterPublishedApplicationId: string,
    dashboardPublishedApplicationId: string,
    reportId: string,
    name: string,
    publishedApplicationGroupId: string
  ): Promise<void> {
    const importParams = new ImportFromReportParams(
      deltaMasterPublishedApplicationId,
      dashboardPublishedApplicationId,
      name,
      publishedApplicationGroupId,
      reportId
    );

    await this._longRunningWorkClient.complete(
      importParams,
      (input) => this._launchReportImportAsync(input),
      (input, workId) => this._getReportImportStatusAsync(workId),
      () => ""
    );
  }

  private async _launchReportImportAsync(
    importParams: ImportFromReportParams
  ): Promise<WorkItemDtoOfImportFromReportResultState> {
    const sourcePublishedApplicationId = importParams.deltaMasterPublishedApplicationId;
    let targetPublishedApplicationId = importParams.dashboardPublishedApplicationId;

    if (targetPublishedApplicationId === null) {
      targetPublishedApplicationId = await this._getTargetDashboardApplicationIdAsync(
        importParams.publishedApplicationGroupId,
        importParams.name
      );
    }

    return await this._dashboardsClient.importFromReport(
      sourcePublishedApplicationId,
      importParams.reportId,
      targetPublishedApplicationId
    );
  }

  private async _getReportImportStatusAsync(
    workId: string
  ): Promise<WorkItemDtoOfImportFromReportResultState> {
    return await this._dashboardsClient.getImportFromReportStatus(workId);
  }

  private async _getTargetDashboardApplicationIdAsync(
    publishedApplicationGroupId: string,
    name: string
  ): Promise<string> {
    const publishedApplications = (
      await this._publishedApplicationClient.getPublishedApplications(
        publishedApplicationGroupId
      )
    ).filter(
      (p) =>
        p.applicationType === "Dashboard" &&
        p.name === ApplicationNameLink.getDashboardPublishedApplicationName(name)
    );
    const publishedApplication =
      publishedApplications.length > 0 ? publishedApplications[0] : null;

    return publishedApplication.id;
  }

  async getSemanticModelForApplicationVersionAsync(
    applicationVersionId: string
  ): Promise<ValueResult<SemanticModelDto, FailedReason>> {
    try {
      const semanticModel =
        await this._semanticModelClient.getSemanticModelForApplicationVersion(
          applicationVersionId
        );
      return ValueResult.createFromValue(semanticModel);
    } catch (error) {
      return ValueResult.createFromError(error);
    }
  }

  async getDm7ApplicationByPublishedApplicationIdAsync(
    publishedApplicationId: string
  ): Promise<ValueResult<Dm7ApplicationDto, FailedReason>> {
    try {
      const dm7Application =
        await this._dm7ApplicationsClient.getDm7ApplicationByPublishedApplicationId(
          publishedApplicationId
        );

      return ValueResult.createFromValue(dm7Application);
    } catch (error) {
      return ValueResult.createFromError(error);
    }
  }

  async findReportingItemsAsync(
    dm7ApplicationId: string,
    parentId: string | null,
    rootReportGroupsOnly: boolean
  ): Promise<ValueResult<ReportingItemDto[], FailedReason>> {
    const reportingFilter: IReportingItemFilterDto = {
      dm7ApplicationId: dm7ApplicationId,
      parentId: parentId,
      rootReportGroupsOnly: rootReportGroupsOnly,
    };

    try {
      const reportingItems = await this._reportingItemsClient.findReportingItems(
        new ReportingItemFilterDto(reportingFilter)
      );

      const filteredItems = reportingItems.filter((value) => value instanceof ReportDto);

      return ValueResult.createFromValue(filteredItems);
    } catch (error) {
      return ValueResult.createFromError(error);
    }
  }
}
