<script lang="ts">
import StructureElements from "./structure-elements.vue";
import { useHelpText } from "@/services/help-text-service/help-text.cpsl";
import { ElementVm } from "../view-models/element-vm";
import { isMobile } from "@/common/browser-detection";
import { SharedDashboardStateVm } from "../view-models/shared/shared-dashboard-state-vm";
import { SwiperVm } from "@/common/components/swiper-vm";
import { DashboardCommon } from "../dashboard-common";

import {
  reactive,
  computed,
  onBeforeMount,
  ComputedRef,
  Component,
  watch,
  PropType,
  defineComponent,
} from "vue";
import SimpleGrid from "@/common/components/simple-grid/simple-grid.vue";
import TitleValue from "@/features/dashboard/structure-elements/title-value.vue";
import { Cell } from "@/common/components/simple-grid/contracts/cell";
import { ValueVm } from "@/features/dashboard/view-models/value-vm";
import type { CSSProperties } from "vue/types/jsx";
import {
  DashboardStatusType,
  DashboardStatus,
} from "@/features/dashboard/status-configs/dashboard-status";
import { StatusConfigElement } from "@/features/dashboard/status-configs/sc-element";
import { GridIds } from "@/features/dashboard/status-configs/grid-ids";
import SparklineBox from "@/features/dashboard-shared/sparkline/sparkline-box.vue";
import DashboardValue from "@/features/dashboard/helper-components/dashboard-value.vue";
import SwipeableDashboardValue from "@/features/dashboard/helper-components/swipeable-dashboard-value.vue";
import { replace } from "@/common/helper/array-helper";
import { appResources } from "@/app-resources";
import { DashboardSelection } from "@/features/dashboard-shared/dashboard-selection";

class ElementState {
  zoomFactor: number;
  cells: Cell[] = [];
  cellWidths: string[];
  separatorWidths: string[];
}

export default defineComponent({
  components: {
    SwipeableDashboardValue,
    DashboardValue,
    SparklineBox,
    TitleValue,
    SimpleGrid,
  },

  emits: [
    DashboardCommon.element_onMouseOverElement,
    DashboardCommon.element_onMouseLeaveElement,
  ],

  props: {
    drillDepth: { type: Number, required: true },
    elementVm: { type: Object as PropType<ElementVm>, required: true },
    elementIndex: { type: Number, required: true },
    sharedState: { type: Object as PropType<SharedDashboardStateVm>, required: true },
    dashboardSelection: {
      type: Object as PropType<DashboardSelection>,
      required: true,
    },
    swiperVm: { type: Object as PropType<SwiperVm>, required: true },
    activeIndex: { type: Number, required: true },
    sparklinesGlobalMax: { type: Number, default: null },
    hasSparklines: { type: Boolean, default: false },
    dashboardStatusType: {
      type: String as () => DashboardStatusType,
      default: "ThreeValuesNoSparklinesScaleIndexGreaterZero",
    },
    firstValueWidth: { type: Number, required: true },
    drillSpacerWidth: { type: Number, default: 0 },
    showBorderBottom: { type: Boolean, default: true },
    showBorderTop: { type: Boolean, default: false },
    canSort: { type: Boolean, default: false },
  },

  setup(props, context) {
    const state = reactive(new ElementState());
    const helpTextCpsl = useHelpText();
    const gridIds = GridIds;

    //
    // Life Cycle:
    // --------------------
    onBeforeMount(() => {
      props.elementVm.init(props.drillDepth);

      initGrid();
    });

    //
    // Computeds:
    // --------------------
    const canDrill = computed(() => props.elementVm.nextStructureVms.length > 0);

    const elementLinesStyle = computed<CSSProperties>(() => {
      const width = props.drillSpacerWidth + "px";
      return { "--spacerWidth": width };
    });

    const structureNames = computed<string[]>(() =>
      props.elementVm.nextStructureVms.map((structure) => structure.displayName)
    );

    const deltaValues = computed<ValueVm[]>(() => {
      if (props.elementVm.elementValues.length <= 1) {
        return [];
      }

      return [props.elementVm.elementValues[1], props.elementVm.elementValues[2]];
    });

    const structureElementsComponent: ComputedRef<Component> = computed(() => {
      return StructureElements;
    });

    const scaleIndex = computed(() => {
      return props.elementVm.getScaleIndex(props.sharedState);
    });

    const elementScaleColor = computed(() => {
      return props.elementVm.getScaleColor(props.sharedState);
    });

    const isExtended = computed<boolean>(() =>
      DashboardStatus.isExtended(props.dashboardStatusType)
    );

    const titleName = computed<string>(() => {
      if (!props.elementVm.name || props.elementVm.name.trim().length === 0) {
        return appResources.structureElements.hasNoElementName;
      }

      return props.elementVm.name;
    });

    const titleValue = computed<ValueVm>(() => {
      if (
        props.sharedState.sparklineState.showSparklines &&
        (props.elementVm.elementValues[0].hasSparkline || isExtended.value)
      ) {
        return null;
      }

      return props.elementVm.elementValues[0];
    });

    //
    // Functions:
    // --------------------
    function onMouseEnterKpiFrameElement(): void {
      if (isMobile()) {
        return;
      }

      context.emit(DashboardCommon.element_onMouseOverElement, props.drillDepth);
    }

    function toggleDrill() {
      const kpiScaleIndex = props.sharedState.kpiScaleIndex;
      props.elementVm.toggleStructures(kpiScaleIndex);
      props.dashboardSelection.clearElement();
    }

    function initGrid(): void {
      state.cellWidths = StatusConfigElement.getCellWidths(props.dashboardStatusType);
      state.separatorWidths = StatusConfigElement.getSeparatorWidths(
        props.dashboardStatusType
      );
      const cells = StatusConfigElement.getCells(props.dashboardStatusType);
      replace(state.cells, cells);
    }

    function onActiveIndexChanged(): void {
      props.swiperVm.swipeTo(props.activeIndex, false);

      const percentageModes = props.elementVm.parentRowState.isPercentageModeActive;
      for (let i = 0; i < percentageModes.length; i++) {
        percentageModes[i] = false;
      }
    }

    function onMouseLeaveKpiFrameElement(): void {
      context.emit(DashboardCommon.element_onMouseLeaveElement, props.drillDepth);
    }

    //
    // Watcher:
    // --------------------
    watch(() => props.dashboardStatusType, initGrid);

    watch(() => props.activeIndex, onActiveIndexChanged);

    return {
      state,
      helpTextCpsl,
      gridIds,
      structureNames,
      structureElementsComponent,
      scaleIndex,
      canDrill,
      elementScaleColor,
      elementLinesStyle,
      titleName,
      titleValue,
      deltaValues,
      toggleDrill,
      onMouseEnterKpiFrameElement,
      onMouseLeaveKpiFrameElement,
    };
  },
});
</script>

<template>
  <div
    v-on:pointerdown="$props.dashboardSelection.setElement($props.elementIndex)"
    class="kpi-element"
    v-bind:class="{
      drilled: elementVm.nextStructureElements.isVisible,
    }"
  >
    <div
      class="element-lines"
      v-bind:class="{
        'line-top': $props.showBorderTop,
        'line-bottom': $props.showBorderBottom,
      }"
      v-bind:style="elementLinesStyle"
    />
    <SimpleGrid
      class="kpi-frame-element"
      v-bind:cells="state.cells"
      v-bind:separators="[]"
      v-bind:separatorWidths="state.separatorWidths"
      v-bind:cellWidths="state.cellWidths"
      v-bind:separatorHeights="['0', '0']"
      v-bind:cellHeights="['35px']"
      v-on:mouseenter.native="onMouseEnterKpiFrameElement"
      v-on:mouseleave.native="onMouseLeaveKpiFrameElement"
      v-on:pointerdown.native="$props.dashboardSelection.clearElement()"
      v-bind:data-helpText="
        helpTextCpsl.elementDrillText(
          elementVm.nextStructureElements.isVisible,
          elementVm.nextStructureVms.length > 0
        )
      "
    >
      <TitleValue
        v-bind:data-grid-id="gridIds.titleValue"
        v-bind:valueVm="titleValue"
        v-bind:sharedState="$props.sharedState"
        v-bind:title="titleName"
        v-bind:titlePadding="$props.drillSpacerWidth"
        v-bind:dashboardSelection="dashboardSelection"
        v-bind:firstValueWidth="$props.firstValueWidth"
        v-bind:isClickEnabled="canDrill"
        v-bind:disableAnimation="false"
        v-bind:canSort="$props.canSort"
        v-bind:isPercentageMode="
          $props.elementVm.parentRowState.isPercentageModeActive[0]
        "
        v-on:valueLeftClicked="toggleDrill()"
        v-on:elementTitleSection_toggleDrill="toggleDrill()"
        v-on="$listeners"
      />
      <DashboardValue
        v-bind:data-grid-id="gridIds.valueGroupValue"
        v-bind:valueVm="$props.elementVm.elementValues[0]"
        v-bind:isElement="true"
        v-bind:sharedState="$props.sharedState"
        v-bind:isPercentageMode="
          $props.elementVm.parentRowState.isPercentageModeActive[0]
        "
        v-bind:isClickEnabled="canDrill"
        v-bind:alignCenter="true"
        v-bind:defaultTextColor="'var(--valueTextColor)'"
        v-bind:disableBrackets="true"
        v-on:valueLeftClicked="toggleDrill()"
        v-bind:data-helpText="
          helpTextCpsl.elementValueText(
            !$props.elementVm.elementValues[0].isAnyPercentageType,
            $props.canSort
          )
        "
        v-on:pointerdown.native="$props.dashboardSelection.setKpiValue(0)"
        v-on="$listeners"
      />
      <SparklineBox
        v-bind:data-grid-id="gridIds.sparkline"
        v-bind:sparklineVm="$props.elementVm.elementValues[0].sparkline"
        v-bind:kpiScaleIndex="0"
        v-bind:sparklineDisplayState="$props.sharedState.sparklineState"
        v-bind:sparklinesGlobalMax="$props.sparklinesGlobalMax"
        v-bind:colorInfo="$props.elementVm.sharedKpiInfo"
        v-bind:zoomFactor="state.zoomFactor"
        v-bind:drillDepth="$props.drillDepth"
        v-bind:style="{ padding: '6px 0' }"
        v-on="$listeners"
      />
      <SwipeableDashboardValue
        v-if="deltaValues.length > 1"
        v-bind:data-grid-id="gridIds.deltaValues"
        v-bind:swiperVm="$props.swiperVm"
        v-bind:swipeItems="deltaValues"
        v-bind:zoomFactor="state.zoomFactor"
        v-bind:isClickEnabled="canDrill"
        v-bind:isSwipeEnabled="true"
        v-bind:isScaled="$props.sharedState.kpiScaleIndex > 0"
        v-bind:sharedState="$props.sharedState"
        v-bind:useColorOrScaling="true"
        v-bind:alignCenter="true"
        v-bind:isElement="true"
        v-bind:percentageModes="$props.elementVm.parentRowState.isPercentageModeActive"
        v-bind:data-helpText="
          helpTextCpsl.elementValueText(
            !deltaValues[$props.swiperVm.getRealIndex()].isAnyPercentageType,
            canSort,
            true
          )
        "
        v-on:swipeableDashboardValue_clicked="toggleDrill()"
        v-on:pointerdown.native="
          $props.dashboardSelection.setKpiValue($props.swiperVm.activeIndex)
        "
        v-on="$listeners"
      />
      <DashboardValue
        v-if="$props.elementVm.elementValues.length > 2"
        v-bind:data-grid-id="gridIds.deltaValue_1"
        v-bind:valueVm="$props.elementVm.elementValues[2]"
        v-bind:sharedState="$props.sharedState"
        v-bind:defaultTextColor="'var(--valueTextColor)'"
        v-bind:enableColorAndScaling="true"
        v-bind:colorizeBackground="false"
        v-bind:isClickEnabled="canDrill"
        v-bind:isElement="true"
        v-bind:isPercentageMode="
          $props.elementVm.parentRowState.isPercentageModeActive[2]
        "
        v-bind:data-helpText="
          helpTextCpsl.elementValueText(
            !$props.elementVm.elementValues[2].isAnyPercentageType,
            $props.canSort
          )
        "
        v-on:pointerdown.native="$props.dashboardSelection.setKpiValue(2)"
        v-on:valueLeftClicked="toggleDrill()"
        v-on="$listeners"
      />
      <DashboardValue
        v-if="$props.elementVm.elementValues.length > 1"
        v-bind:data-grid-id="gridIds.deltaValue_2"
        v-bind:valueVm="$props.elementVm.elementValues[1]"
        v-bind:sharedState="$props.sharedState"
        v-bind:defaultTextColor="'var(--valueTextColor)'"
        v-bind:enableColorAndScaling="true"
        v-bind:colorizeBackground="false"
        v-bind:isClickEnabled="canDrill"
        v-bind:isElement="true"
        v-bind:isPercentageMode="
          $props.elementVm.parentRowState.isPercentageModeActive[1]
        "
        v-bind:data-helpText="
          helpTextCpsl.elementValueText(
            !$props.elementVm.elementValues[1].isAnyPercentageType,
            $props.canSort
          )
        "
        v-on:valueLeftClicked="toggleDrill()"
        v-on:pointerdown.native="$props.dashboardSelection.setKpiValue(1)"
        v-on="$listeners"
      />
    </SimpleGrid>
    <component
      v-if="$props.elementVm.nextStructureElements.isVisible"
      v-bind:is="structureElementsComponent"
      v-bind:structureElementsVm="$props.elementVm.nextStructureElements"
      v-bind:scaleColor="elementScaleColor"
      v-bind:dashboardSelection="dashboardSelection"
      v-bind:drillDepth="$props.drillDepth + 1"
      v-bind:sharedState="$props.sharedState"
      v-bind:deltaValuesActiveIndex="$props.activeIndex"
      v-bind:hasSparklines="$props.hasSparklines"
      v-bind:parentValueVm="$props.elementVm.getScaledElementValue($props.sharedState)"
      v-bind:dashboardStatusType="dashboardStatusType"
      v-bind:structureNames="structureNames"
      v-bind:firstValueWidth="$props.firstValueWidth"
      v-bind:zoomFactor="state.zoomFactor"
      v-on="$listeners"
    />
  </div>
</template>

<style lang="less" scoped>
.kpi-element {
  position: relative;
  background-color: var(--color_bg_white);

  .element-lines {
    box-sizing: border-box;
    position: absolute;
    height: 100%;
    margin-left: var(--spacerWidth);
    width: calc(100% - var(--spacerWidth));

    &.line-top {
      border-top: 4px solid var(--color_bg-gray);
    }

    &.line-bottom {
      border-bottom: 1px solid var(--color_bg-gray);
    }
  }

  .kpi-frame-element {
    height: 100%;
    position: relative;
    z-index: 1;
  }
}
</style>
