import { Action } from "@/features/dashboard/contract/action";
import { DashboardSelection } from "@/features/dashboard-shared/dashboard-selection";
import { DashboardVm } from "@/features/dashboard/view-models/dashboard-vm";
import { appResources } from "@/app-resources";
import { IDashboardActions } from "@/features/dashboard/contract/dashboard-actions.interface";
import { ElementVm } from "@/features/dashboard/view-models/element-vm";
import { StructureElementsVm } from "@/features/dashboard/view-models/structure-elements-vm";
import Vue from "vue";
import ApplyFilterIcon from "@/features/dashboard/icons/apply-filter.vue";
import AbsoluteNumberModeIcon from "@/features/dashboard/icons/absolute-number-mode.vue";
import PercentageModeIcon from "@/features/dashboard/icons/percentage-mode.vue";
import ExcludeFromScalingIcon from "@/features/dashboard/icons/exclude-from-scaling.vue";
import IncludeForScalingIcon from "@/features/dashboard/icons/include-for-scaling.vue";
import SortByValueAsc from "@/features/dashboard/icons/sort-by-value-asc.vue";
import SortByNameAsc from "@/features/dashboard/icons/sort-by-name-asc.vue";
import SortByNameDesc from "@/features/dashboard/icons/sort-by-name-desc.vue";
import SortByValueDesc from "@/features/dashboard/icons/sort-by-value-desc.vue";
import SortBySortId from "@/features/dashboard/icons/sort-by-sort-id.vue";

export class DashboardActions implements IDashboardActions {
  private _dashboardVm: DashboardVm;
  private _dashboardSelection: DashboardSelection;

  constructor(dashboardVm: DashboardVm, dashboardSelection: DashboardSelection) {
    this._dashboardVm = dashboardVm;
    this._dashboardSelection = dashboardSelection;
  }

  excludeFromScaling(): Action {
    const action = new Action();
    action.icon = ExcludeFromScalingIcon;
    action.text = appResources.dashboardCommandTexts.excludeFromScaling;
    const kpiTile = this._dashboardVm.kpiTileVms.find(
      (k) => k.kpiInfo.kpiId === this._dashboardSelection.kpiTileId
    );
    const valueIndex = this._dashboardSelection.valueIndex;
    const kpiValue = kpiTile?.currentValueGroup.kpiValues[valueIndex];
    action.callback = this.canExcludeFromScaling()
      ? () => {
          kpiValue.excludedFromScaling = true;
          this._dashboardVm.onExcludeFromScalingChanged();
        }
      : null;
    return action;
  }

  includeForScaling(): Action {
    const action = new Action();
    action.icon = IncludeForScalingIcon;
    action.text = appResources.dashboardCommandTexts.includeToScaling;
    const kpiTile = this._dashboardVm.kpiTileVms.find(
      (k) => k.kpiInfo.kpiId === this._dashboardSelection.kpiTileId
    );
    const valueIndex = this._dashboardSelection.valueIndex;
    const kpiValue = kpiTile?.currentValueGroup.kpiValues[valueIndex];
    action.callback = this.canIncludeForScaling()
      ? () => {
          kpiValue.excludedFromScaling = false;
          this._dashboardVm.onExcludeFromScalingChanged();
        }
      : null;
    return action;
  }

  applyFilter(): Action {
    const action = new Action();
    action.icon = ApplyFilterIcon;
    action.text = appResources.dashboardCommandTexts.applyFilter;
    const elementVm = this._getSelectedElement();
    action.callback = this.canApplyFilter()
      ? () =>
          elementVm.sharedDrillInfo.dashboardFilter.addStructureFilter(elementVm.filter)
      : null;
    return action;
  }

  showAbsoluteMode(): Action {
    const action = new Action();
    action.icon = AbsoluteNumberModeIcon;
    action.text = appResources.dashboardCommandTexts.absoluteNumbers;
    const elementVm = this._getSelectedElement();
    const valueIndex = this._dashboardSelection.valueIndex;
    action.callback = this.canShowAbsoluteMode()
      ? () => Vue.set(elementVm.parentRowState.isPercentageModeActive, valueIndex, false)
      : null;
    return action;
  }

  showPercentageMode(): Action {
    const action = new Action();
    action.icon = PercentageModeIcon;
    action.text = appResources.dashboardCommandTexts.percentageNumbers;
    const elementVm = this._getSelectedElement();
    const valueIndex = this._dashboardSelection.valueIndex;
    action.callback = this.canShowPercentageMode()
      ? () => Vue.set(elementVm.parentRowState.isPercentageModeActive, valueIndex, true)
      : null;
    return action;
  }

  sortAscending(): Action {
    const action = new Action();
    action.text = appResources.dashboardCommandTexts.sortAscending;
    const drillDepth = this._dashboardSelection.elementIndex.length;
    const structureElementsVm = this._getSelectedStructureElements(drillDepth);
    const valueIndex = this._dashboardSelection.valueIndex;
    action.icon = valueIndex === -1 ? SortByNameAsc : SortByValueAsc;
    action.callback = this.canSortAscending()
      ? () => {
          structureElementsVm.sortedColumn = valueIndex + 1;
          structureElementsVm.sortType =
            valueIndex === -1 ? "ByNameAscending" : "ByValueAscending";
        }
      : null;
    return action;
  }

  sortDescending(): Action {
    const action = new Action();
    action.text = appResources.dashboardCommandTexts.sortDescending;
    const drillDepth = this._dashboardSelection.elementIndex.length;
    const structureElementsVm = this._getSelectedStructureElements(drillDepth);
    const valueIndex = this._dashboardSelection.valueIndex;
    action.icon = valueIndex === -1 ? SortByNameDesc : SortByValueDesc;
    action.callback = this.canSortDescending()
      ? () => {
          structureElementsVm.sortedColumn = valueIndex + 1;
          structureElementsVm.sortType =
            valueIndex === -1 ? "ByNameDescending" : "ByValueDescending";
        }
      : null;
    return action;
  }

  sortBySortId(): Action {
    const action = new Action();
    action.text = appResources.dashboardCommandTexts.sortById;
    const drillDepth = this._dashboardSelection.elementIndex.length;
    const structureElementsVm = this._getSelectedStructureElements(drillDepth);
    action.icon = SortBySortId;
    action.callback = this.canSortBySortId()
      ? () => {
          structureElementsVm.sortedColumn = 0;
          structureElementsVm.sortType = "BySortId";
        }
      : null;
    return action;
  }

  canExcludeFromScaling(): boolean {
    if (this._dashboardVm.shownKpiTileVms.length === 1) {
      return false;
    }

    const valueIndex = this._dashboardSelection.valueIndex;

    if (
      this._dashboardSelection.elementIndex.length > 0 ||
      valueIndex < 0 ||
      valueIndex !== this._dashboardVm.sharedState.kpiScaleIndex
    ) {
      return false;
    }

    const kpiTile = this._dashboardVm.kpiTileVms.find(
      (k) => k.kpiInfo.kpiId === this._dashboardSelection.kpiTileId
    );

    if (!kpiTile) {
      return false;
    }

    return !kpiTile.currentValueGroup.kpiValues[valueIndex].excludedFromScaling;
  }

  canIncludeForScaling(): boolean {
    const valueIndex = this._dashboardSelection.valueIndex;

    if (
      this._dashboardSelection.elementIndex.length > 0 ||
      valueIndex < 0 ||
      valueIndex !== this._dashboardVm.sharedState.kpiScaleIndex
    ) {
      return false;
    }

    const kpiTile = this._dashboardVm.kpiTileVms.find(
      (k) => k.kpiInfo.kpiId === this._dashboardSelection.kpiTileId
    );

    if (!kpiTile) {
      return false;
    }

    return kpiTile.currentValueGroup.kpiValues[valueIndex].excludedFromScaling;
  }

  canApplyFilter(): boolean {
    const elementVm = this._getSelectedElement();
    if (!elementVm) {
      return false;
    }

    return this._dashboardSelection.valueIndex === -1;
  }

  canShowAbsoluteMode(): boolean {
    if (this._dashboardSelection.valueIndex === null) {
      return false;
    }

    const elementVm = this._getSelectedElement();
    if (!elementVm) {
      return false;
    }

    if (
      this._dashboardSelection.valueIndex < 0 ||
      this._dashboardSelection.valueIndex >= elementVm.elementValues.length
    ) {
      return false;
    }

    return (
      !elementVm.elementValues[this._dashboardSelection.valueIndex].isAnyPercentageType &&
      elementVm.parentRowState.isPercentageModeActive[this._dashboardSelection.valueIndex]
    );
  }

  canShowPercentageMode(): boolean {
    if (this._dashboardSelection.valueIndex === null) {
      return false;
    }

    const elementVm = this._getSelectedElement();
    if (!elementVm) {
      return false;
    }

    if (
      this._dashboardSelection.valueIndex < 0 ||
      this._dashboardSelection.valueIndex >= elementVm.elementValues.length
    ) {
      return false;
    }

    return (
      !elementVm.elementValues[this._dashboardSelection.valueIndex].isAnyPercentageType &&
      !elementVm.parentRowState.isPercentageModeActive[
        this._dashboardSelection.valueIndex
      ]
    );
  }

  canSortAscending(): boolean {
    if (this._dashboardSelection.valueIndex === null) {
      return false;
    }

    const structureElementsVm = this._getSelectedStructureElements(
      this._dashboardSelection.elementIndex.length
    );

    if (!structureElementsVm) {
      return false;
    }

    if (structureElementsVm.currentElementVms.length <= 1) {
      return false;
    }

    if (structureElementsVm.sortedColumn !== this._dashboardSelection.valueIndex + 1) {
      return true;
    }

    return (
      structureElementsVm.sortType !== "ByValueAscending" &&
      structureElementsVm.sortType !== "ByNameAscending"
    );
  }

  canSortDescending(): boolean {
    if (this._dashboardSelection.valueIndex === null) {
      return false;
    }

    const structureElementsVm = this._getSelectedStructureElements(
      this._dashboardSelection.elementIndex.length
    );

    if (!structureElementsVm) {
      return false;
    }

    if (structureElementsVm.currentElementVms.length <= 1) {
      return false;
    }

    if (structureElementsVm.sortedColumn !== this._dashboardSelection.valueIndex + 1) {
      return true;
    }

    return (
      structureElementsVm.sortType !== "ByValueDescending" &&
      structureElementsVm.sortType !== "ByNameDescending"
    );
  }

  canSortBySortId(): boolean {
    if (this._dashboardSelection.valueIndex !== -1) {
      return false;
    }

    const structureElementsVm = this._getSelectedStructureElements(
      this._dashboardSelection.elementIndex.length
    );

    if (!structureElementsVm) {
      return false;
    }

    if (structureElementsVm.currentElementVms.length <= 1) {
      return false;
    }

    return structureElementsVm.sortType !== "BySortId";
  }

  private _getSelectedStructureElements(
    drillDepth: number,
    structureElementsVm: StructureElementsVm = null
  ): StructureElementsVm {
    if (this._dashboardSelection.elementIndex.length === 0) {
      return null;
    }

    structureElementsVm = structureElementsVm ?? this._getFirstStructureElements();

    if (
      !structureElementsVm ||
      !structureElementsVm.isVisible ||
      structureElementsVm.currentStructureElementsListVm.drillDepth === drillDepth
    ) {
      return structureElementsVm;
    }

    const elementIndex =
      this._dashboardSelection.elementIndex[
        this._dashboardSelection.elementIndex.length -
          structureElementsVm.currentStructureElementsListVm.drillDepth
      ];

    const nextStructureElements =
      structureElementsVm.currentElementVms[elementIndex].nextStructureElements;

    if (!nextStructureElements || !nextStructureElements.isVisible) {
      return null;
    }

    return this._getSelectedStructureElements(drillDepth, nextStructureElements);
  }

  private _getSelectedElement(
    drillDepth: number = 0,
    elementVm: ElementVm = null
  ): ElementVm {
    if (drillDepth === this._dashboardSelection.elementIndex.length) {
      return elementVm;
    }

    const structureElementsVm = elementVm
      ? elementVm.nextStructureElements
      : this._getFirstStructureElements();

    if (!structureElementsVm) {
      return null;
    }

    if (!structureElementsVm.isVisible) {
      return null;
    }

    const elementIndex =
      this._dashboardSelection.elementIndex[
        this._dashboardSelection.elementIndex.length - 1 - drillDepth
      ];

    const elements = structureElementsVm.currentStructureElementsListVm.elementVms;
    elementVm = elements.length > elementIndex ? elements[elementIndex] : null;
    return this._getSelectedElement(drillDepth + 1, elementVm);
  }

  private _getFirstStructureElements(): StructureElementsVm {
    const kpiTileVm = this._dashboardVm.kpiTileVms.find(
      (kpiTileVm) => kpiTileVm.kpiInfo.kpiId === this._dashboardSelection.kpiTileId
    );

    if (!kpiTileVm || !kpiTileVm.currentValueGroup.structureElementsVm.isVisible) {
      return null;
    }

    return kpiTileVm.currentValueGroup.structureElementsVm;
  }
}
