import {
  IPortalPageViewStateDto,
  IPortalViewStateDto,
  IViewStateDto,
  PortalPageViewStateDto,
  PortalTileViewStateDto,
  ScrollFrameDto,
} from "@/common/service-clients/generated-clients";
import { PortalVm } from "../../view-models/portal-vm";
import { PortalViewStateDto } from "@/common/service-clients/generated-clients";
import { IVsGenerator } from "@/services/view-state-service/contract/vs-generator.interface";
import { PortalPageVm } from "../../view-models/portal-page-vm";
import { TileVm } from "../../tile/tile-vm";

export class PortalViewStateGenerator implements IVsGenerator {
  private _prevViewState: IPortalViewStateDto = null;

  getViewState(rootVm: PortalVm, prevViewState?: IPortalViewStateDto): IViewStateDto {
    const portalVm = rootVm;

    if (prevViewState) {
      this._prevViewState = prevViewState;
    }

    if (!portalVm) {
      return null;
    }

    if (!portalVm.id) {
      throw new Error("ViewState generator always needs the id for the original VM/dto.");
    }

    const portalVs = new PortalViewStateDto();
    portalVs.id = this._prevViewState?.id ?? null;
    portalVs.portalId = portalVm.id;
    portalVs.sortIndex = this._prevViewState?.sortIndex ?? 0;
    // in vs generation use selectedPage. Handles the case of none active page set explicitly
    // (alternative would be possible locally here: if active page is null, use id of first page)
    portalVs.activePortalPageId = portalVm.selectedPage?.id;

    portalVs.portalPages = portalVm.portalPages.map((ppVm, index) =>
      this._getPortalPageVs(ppVm, index, portalVs.id)
    );

    return portalVs;
  }

  private _getPortalPageVs(
    portalPageVm: PortalPageVm,
    arrayIndex: number,
    portalVsId?: string
  ): PortalPageViewStateDto {
    const vs = new PortalPageViewStateDto();
    const prevVs = this._getPrevPortalPageVs(portalPageVm.id);

    // ids:
    vs.id = prevVs?.id ?? null;
    vs.portalPageId = portalPageVm.id;
    vs.portalViewStateId = portalVsId;

    vs.sortIndex = arrayIndex;

    if (!portalPageVm.isLoading) {
      vs.portalTiles = portalPageVm.tiles
        .filter((tVm) => !tVm.isTemporary)
        .map((tileVm) => this._getPortalTileVs(tileVm, prevVs));
    }

    return vs;
  }

  private _getPortalTileVs(
    vm: TileVm,
    prevPortalPageVs?: IPortalPageViewStateDto
  ): PortalTileViewStateDto {
    const vs = new PortalTileViewStateDto();

    const prevVs: PortalTileViewStateDto = this._getPreviousTileVs(
      vm.id,
      prevPortalPageVs?.portalTiles
    );

    // ids:
    vs.id = prevVs?.id;
    vs.portalPageViewStateId = prevPortalPageVs?.id;
    vs.portalTileId = vm.id;
    vs.activePortalTilePageId = vm.activePage?.id;

    // viewstate:
    vs.sortIndex = vm.tileConfig.order ?? 0;
    vs.frame = new ScrollFrameDto({
      positionX: 0,
      positionY: 0,
      width: vm.tileConfig.w,
      height: vm.tileConfig.h,
      scrollPositionX: 0,
      scrollPositionY: 0,
      isFullScreen: vm.isFullpage,
    });

    vs.portalTilePages = [];

    return vs;
  }

  private _getPreviousTileVs(
    tileId: string,
    tiles: PortalTileViewStateDto[]
  ): PortalTileViewStateDto {
    if (!tiles || !tiles?.length) {
      return null;
    }

    const result = tiles.find((tileVs) => tileVs.portalTileId === tileId);
    return result;
  }

  private _getPrevPortalPageVs(portalPageId: string): PortalPageViewStateDto {
    if (!this._prevViewState?.portalPages || !this._prevViewState?.portalPages?.length) {
      return null;
    }

    const result = this._prevViewState.portalPages.find(
      (ppVs) => ppVs.portalPageId === portalPageId
    );
    return result;
  }
}
