import {
  DashboardPortalTilePageDto,
  PortalTileDto,
  PortalsServiceClient,
} from "@/common/service-clients/generated-clients";
import { IPortalFacade } from "./portal-facade.interface";
import { FailedReason } from "@/common/results/failed-reason";
import { Result } from "@/common/results/result";
import { ValueResult } from "@/common/results/value-result";

export class PortalFacade implements IPortalFacade {
  constructor(private readonly _portalServiceClient: PortalsServiceClient) {}

  async createPortalTileAsync(
    portalPageId: string,
    dashboardApplicationId: string,
    dashboardApplicationName: string,
    dashboardId: number
  ): Promise<Result<FailedReason>> {
    const portalTile = new PortalTileDto({
      name: dashboardApplicationName,
      id: null,
      portalPageId: portalPageId,
      description: "App.Wizard",
      portalPage: null,
      portalTilePages: null,
    });

    try {
      const portalTileId = await this._portalServiceClient.createPortalTile(portalTile);

      const portalTilePage = new DashboardPortalTilePageDto({
        id: null,
        name: dashboardApplicationName,
        description: "App.Wizard",
        portalTilePageType: "DashboardPortalTilePage",
        publishedApplicationId: dashboardApplicationId,
        dashboardId: dashboardId,
        portalTileId: portalTileId,
        portalTile: null,
        publishedApplication: null,
      });

      await this._portalServiceClient.createPortalTilePage(portalTilePage);
    } catch (error) {
      return Result.createFromFailedReason(error);
    }

    return new Result();
  }

  async getAllPortalTileNamesAsync(
    portalPageId: string
  ): Promise<ValueResult<string[], FailedReason>> {
    try {
      const portalTiles = await this._portalServiceClient.getPortalTiles(portalPageId);
      const names = portalTiles.map((portalTile) => portalTile.name);
      return ValueResult.createFromValue(names);
    } catch (e) {
      return ValueResult.createFromError(e);
    }
  }
}
