<script lang="ts">
import Element from "./element.vue";
import LoadingText from "@/common/components/loading-text.vue";
import { SharedDashboardStateVm } from "../view-models/shared/shared-dashboard-state-vm";
import { StructureElementsListVm } from "../view-models/structure-elements-list-vm";
import cloneDeep from "lodash/cloneDeep";
import { SortTypeFm } from "@/features/dashboard/backend-wrapper/facade-models-dashboard";
import { DashboardCommon } from "../dashboard-common";
import { appResources } from "@/app-resources";
import { IStatusBarService } from "@/services/status-bar-service.interface";
import { ValueVm } from "../view-models/value-vm";
import { IContentLocaleOption } from "@bissantz/smartforms";

import {
  ref,
  reactive,
  inject,
  computed,
  onMounted,
  watch,
  PropType,
  defineComponent,
} from "vue";
import type { CSSProperties } from "vue/types/jsx";
import {
  DashboardStatusType,
  DashboardStatus,
} from "@/features/dashboard/status-configs/dashboard-status";
import { DashboardSelection } from "@/features/dashboard-shared/dashboard-selection";

class StructureElementsListState {
  textResources = appResources.structureElements;
  showElementsContainer: boolean = false;
  widths: string[];
}

export default defineComponent({
  components: { LoadingText, StructureElement: Element },

  emits: [DashboardCommon.common_focusElementChanged],

  props: {
    structureElementsListVm: {
      type: Object as PropType<StructureElementsListVm>,
      required: true,
    },
    sharedState: { type: Object as PropType<SharedDashboardStateVm>, required: true },
    parentValueVm: { type: Object as PropType<ValueVm>, required: true },
    activeStructureId: { type: Number, default: null },
    isRealActiveStructure: { type: Boolean },
    isFirstOrLastStructure: { type: Boolean },
    hasSparklines: { type: Boolean },
    drillDepth: { type: Number, required: true },
    isSwipingOrScrolling: { type: Boolean },
    deltaValuesActiveIndex: { type: Number, required: true },
    isLoadingStructures: { type: Boolean },
    dashboardStatusType: {
      type: String as () => DashboardStatusType,
      default: "ThreeValuesNoSparklinesScaleIndexGreaterZero",
    },
    dashboardSelection: {
      type: Object as PropType<DashboardSelection>,
      required: true,
    },
    firstValueWidth: { type: Number, required: true },
    drillSpacerWidth: { type: Number, default: 0 },
    sortType: { type: String as () => SortTypeFm, required: true },
    sortedValueId: { type: Number, required: true },
  },

  setup(props, context) {
    const statusBarService: IStatusBarService = inject("statusBarService");
    const application: IContentLocaleOption = inject("application");
    const ref_componentElem = ref<HTMLDivElement | null>(null);
    const state = reactive(
      new StructureElementsListState()
    ) as StructureElementsListState;

    //
    // Life Cycle:
    // --------------------
    onMounted(() => {
      props.structureElementsListVm.init(props.drillDepth);
    });

    //
    // Computeds:
    // --------------------
    const isExtended = computed<boolean>(() =>
      DashboardStatus.isExtended(props.dashboardStatusType)
    );

    const isActiveStructure = computed(() => {
      return props.activeStructureId === props.structureElementsListVm.structureVm.id;
    });

    const elementVms = computed(() => {
      return props.structureElementsListVm.elementVms;
    });

    const hasNoElements = computed(() => {
      return (
        elementVms.value &&
        elementVms.value.length === 0 &&
        !(props.isSwipingOrScrolling || props.structureElementsListVm.updating)
      );
    });

    const elementDrillSpacerWidth = computed<number>(() => {
      return isExtended.value ? props.drillSpacerWidth : props.drillSpacerWidth + 5;
    });

    const elementPaddingStyle = computed<CSSProperties>(() => {
      const padding = isExtended.value
        ? elementDrillSpacerWidth.value + 9
        : elementDrillSpacerWidth.value;
      return {
        paddingLeft: padding + "px",
      };
    });

    //
    // Functions:
    // --------------------
    async function updateElementsAsync(extendRows: boolean = false): Promise<void> {
      await props.structureElementsListVm.updateElementsAsync(
        extendRows,
        props.sharedState.kpiScaleIndex,
        props.deltaValuesActiveIndex,
        props.sortType,
        props.sortedValueId
      );

      scaleValues();
    }

    async function extendRowsAsync(): Promise<void> {
      const extendsRows = true;
      await updateElementsAsync(extendsRows);
      waitForElementsAndFocusElement();
    }

    function waitForElementsAndFocusElement(): void {
      const elements = ref_componentElem.value?.querySelectorAll(".kpi-element");
      if (elements?.length === elementVms.value.length) {
        focusElement();
      } else {
        setTimeout(() => waitForElementsAndFocusElement(), 0);
      }
    }

    function onMouseLeaveElement(drillDepth: number): void {
      if (drillDepth !== props.drillDepth) {
        return;
      }

      statusBarService.hideText();
    }

    async function onMouseOverElement(drillDepth: number): Promise<void> {
      if (drillDepth !== props.drillDepth) {
        return;
      }

      if (props.parentValueVm.isAnyPercentageType) {
        return statusBarService.hideText();
      }

      if (props.structureElementsListVm.numberElementsInDrill < 0) {
        statusBarService.showText(state.textResources.loading + "...");
        await props.structureElementsListVm.updateNumberElementsInDrillAsync(
          props.sortType
        );
      }

      // Validate again, because of async method call, this can change.
      if (props.structureElementsListVm.numberElementsInDrill >= 0) {
        showText(props.structureElementsListVm.numberElementsInDrill);
      }
    }

    function showText(numberElementsInDrill: number): void {
      const scaledValue = props.parentValueVm;
      let valueAndUnit: string = null;

      if (scaledValue.isAnyPercentageType) {
        return statusBarService.hideText();
      } else {
        const clonedValue = cloneDeep(scaledValue);
        clonedValue.value /= numberElementsInDrill;
        clonedValue.formatElementValue(
          application.effectiveContentLocale,
          appResources.valueFormatTexts,
          clonedValue.isAnyPercentageType,
          props.sharedState.kpiScaleIndex === -1,
          props.sharedState.defaultNumberPresentationMode
        );
        valueAndUnit = clonedValue.valueFormatted + " " + clonedValue.unitFormatted;
      }

      const elementText =
        numberElementsInDrill === 1
          ? state.textResources.element
          : state.textResources.elements;
      statusBarService.showText(
        numberElementsInDrill + " " + elementText + ", Ø " + valueAndUnit
      );
    }

    function disableShowElementsContainer(): void {
      setTimeout(() => {
        if (props.isLoadingStructures) {
          disableShowElementsContainer();
        } else {
          state.showElementsContainer = false;
          focusElement();
        }
      }, 10);
    }

    function focusElement(): void {
      if (!props.structureElementsListVm.isInitialized) {
        return;
      }

      context.emit(
        DashboardCommon.common_focusElementChanged,
        ref_componentElem.value.parentNode.parentNode.parentNode.parentNode.parentNode,
        // TODO: remove hard coded css selectors
        [".kpi-element"]
      );
    }

    async function onActiveStructureChanged(): Promise<void> {
      if (props.isFirstOrLastStructure) {
        return;
      }

      if (isActiveStructure.value) {
        state.showElementsContainer = true;

        if (props.structureElementsListVm.elementVms.length === 0) {
          await updateElementsAsync();
          waitForElementsAndFocusElement();
        } else {
          focusElement();
        }
      } else if (state.showElementsContainer) {
        disableShowElementsContainer();
      }
    }

    function scaleValues(): void {
      if (props.isFirstOrLastStructure) {
        return;
      }

      props.structureElementsListVm.scaleValuesForDrill(props.sharedState);
    }

    function onKpiScaleIndexChanged(): void {
      if (props.isFirstOrLastStructure) {
        return;
      }

      props.structureElementsListVm.updateSparklinesGlobalMax(
        props.sharedState.kpiScaleIndex
      );
    }

    async function onSortingChanged(): Promise<void> {
      if (props.isFirstOrLastStructure || !isActiveStructure.value) {
        return;
      }

      await props.structureElementsListVm.onSortingChanged(
        props.sharedState.kpiScaleIndex,
        props.sortType,
        props.sortedValueId
      );
      scaleValues();
    }

    //
    // Watcher:
    // --------------------
    watch(() => isActiveStructure.value, onActiveStructureChanged);

    watch(
      [
        () => props.sharedState.kpiScaleIndex,
        () => props.structureElementsListVm.deltaValuesSwiperVm,
        () => props.sharedState.sparklineState.showSparklines,
      ],
      scaleValues
    );

    watch(() => props.sharedState.sparklineState.selection, scaleValues, { deep: true });

    watch(() => props.sharedState.kpiScaleIndex, onKpiScaleIndexChanged);

    watch([() => props.sortType, () => props.sortedValueId], onSortingChanged);

    return {
      state,
      ref_componentElem,
      elementVms,
      elementDrillSpacerWidth,
      elementPaddingStyle,
      hasNoElements,
      onMouseLeaveElement,
      onMouseOverElement,
      extendRowsAsync,
    };
  },
});
</script>

<template>
  <div ref="ref_componentElem" class="structureElementsList">
    <div
      class="elementsContainer"
      v-if="state.showElementsContainer && elementVms && elementVms.length > 0"
    >
      <StructureElement
        v-for="(elementVm, index) in elementVms"
        v-bind:key="elementVm.uniqueId"
        v-bind:elementVm="elementVm"
        v-bind:elementIndex="index"
        v-bind:sharedState="$props.sharedState"
        v-bind:drillDepth="$props.drillDepth"
        v-bind:dashboardSelection="dashboardSelection"
        v-bind:swiperVm="$props.structureElementsListVm.deltaValuesSwiperVm"
        v-bind:activeIndex="$props.deltaValuesActiveIndex"
        v-bind:sparklinesGlobalMax="$props.structureElementsListVm.sparklinesGlobalMax"
        v-bind:hasSparklines="$props.hasSparklines"
        v-bind:dashboardStatusType="$props.dashboardStatusType"
        v-bind:firstValueWidth="$props.firstValueWidth"
        v-bind:canSort="elementVms.length > 1"
        v-bind:drillSpacerWidth="elementDrillSpacerWidth"
        v-bind:showBorderTop="
          index > 0 && elementVms[index - 1].nextStructureElements.isVisible
        "
        v-bind:showBorderBottom="
          (index + 1 < elementVms.length || $props.structureElementsListVm.hasMoreRows) &&
          !elementVm.nextStructureElements.isVisible
        "
        v-on:element_onMouseLeaveElement="onMouseLeaveElement"
        v-on:element_onMouseOverElement="onMouseOverElement"
        v-on="$listeners"
      />
      <div
        class="extendRows"
        v-if="
          $props.structureElementsListVm.hasMoreRows &&
          !$props.structureElementsListVm.updating
        "
        v-bind:style="elementPaddingStyle"
        v-on:click="extendRowsAsync"
      >
        …
      </div>
    </div>
    <LoadingText
      v-if="$props.structureElementsListVm.updating"
      class="loadingElements"
      v-bind:style="elementPaddingStyle"
      v-bind:loadingText="state.textResources.loading"
    />
    <div
      class="noElementsBox"
      v-else-if="hasNoElements"
      v-bind:style="elementPaddingStyle"
    >
      {{ state.textResources.hasNoElements }}
    </div>
  </div>
</template>

<style lang="less" scoped>
.structureElementsList {
  .extendRows {
    --extendRowsPaddingLeft: 10px;
    color: var(--color_neutralText);
    background-color: var(--color_bg_white);
    padding-left: var(--extendRowsPaddingLeft);
    padding-bottom: 5px;
    cursor: pointer;
  }

  .loadingElements {
    background-color: var(--color_bg_white);
  }

  .noElementsBox {
    color: var(--color_neutralText);
    padding-left: 15px;
  }
}
</style>
