import { inject, provide } from "vue";
import { AxiosInstance } from "axios";
import { PortalFacade } from "./portal-facade";
import { ViewStateFacade } from "./view-state-facade";
import { TileFacade } from "./tile-facade";

import {
  IPortalsServiceClient,
  IPublishedApplicationsServiceClient,
  PortalsServiceClient,
  PublishedApplicationsServiceClient,
} from "@/common/service-clients/generated-clients";

export function useBackendDependencies() {
  const axiosInstance = inject("axiosInstance", null) as AxiosInstance;

  // list of used service clients:
  let portalClient: IPortalsServiceClient = null;
  let publishedApplicationsClient: IPublishedApplicationsServiceClient = null;

  instantiateUsedServiceClients(axiosInstance);

  // Create Facades:
  // ---------------
  let portalFacade = inject<PortalFacade>("portalFacade", null);
  if (!portalFacade) {
    portalFacade = new PortalFacade(portalClient);
    provide("portalFacade", portalFacade);
  }

  let portalViewStateFacade = inject<ViewStateFacade>("portalViewStateFacade", null);
  if (!portalViewStateFacade) {
    portalViewStateFacade = new ViewStateFacade(portalClient);
    provide("portalViewStateFacade", portalViewStateFacade);
  }

  let tileFacade = inject<TileFacade>("tileFacade", null);
  if (!tileFacade) {
    tileFacade = new TileFacade(publishedApplicationsClient);
    provide("tileFacade", tileFacade);
  }

  // Functions:
  // ---------------
  function instantiateUsedServiceClients(axiosInstance: AxiosInstance): void {
    portalClient = new PortalsServiceClient(null, axiosInstance);
    publishedApplicationsClient = new PublishedApplicationsServiceClient(
      null,
      axiosInstance
    );
  }

  return {
    tileFacade,
    portalFacade,
    portalViewStateFacade,
  };
}
