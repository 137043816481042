/**
 * Copied and modified from "locales/resources.ts.formatString()"
 * @param template must contain sections of the format "{ key }" to be replaced
 * @param args object, with property names being the keys for the replacement and their values being the replacement string.
 * @returns template string with placeholders replaced
 */
export function formatResourceString(
  template: string,
  args: { [key: string]: string }
): string {
  let result = template;
  Object.entries(args).forEach((entry) => {
    const key = entry[0];
    const replacement = `${entry[1]}`;
    const pattern = new RegExp("\\{\\s*" + key + "\\s*\\}", "gm");
    result = result.replace(pattern, replacement);
  });
  return result;
}
