import { ContextMenuItem } from "@/common/components/context-menu/context-menu-item";
import { inject, onMounted } from "vue";
import { IContextMenuService } from "@/common/components/context-menu/context-menu-service.interface";
import { ClickHelper } from "@/common/events/click-helper";
import { TouchHelper } from "@/common/events/touch-helper";
import { DashboardVm } from "@/features/dashboard/view-models/dashboard-vm";
import { DashboardSelection } from "@/features/dashboard-shared/dashboard-selection";
import { DashboardActions } from "@/features/dashboard/contract/dashboard-actions";
import { Action } from "@/features/dashboard/contract/action";

export function useContextMenuItems(
  dashboardVm: DashboardVm,
  dashboardSelection: DashboardSelection
) {
  const contextMenuService = inject<IContextMenuService>("contextMenuService", null);
  const clickHelper = new ClickHelper();
  const touchHelper = new TouchHelper();
  const dashboardActions = new DashboardActions(dashboardVm, dashboardSelection);

  onMounted(() => {
    clickHelper.setOnRightClickAction(onRightClick);
    touchHelper.setLongTouchAction(onLongTouch);
    clickHelper.touchHelper = touchHelper;
  });

  function onRightClick(ev: MouseEvent): void {
    const x = ev.clientX;
    const y = ev.clientY;
    showMenu(x, y);
  }

  function onLongTouch(ev: TouchEvent): void {
    const x = ev.changedTouches[0].clientX;
    const y = ev.changedTouches[0].clientY;
    showMenu(x, y);
  }

  function showMenu(posX: number, posY: number): void {
    if (!contextMenuService) {
      return;
    }

    let actions: Action[] = [];

    actions.push(dashboardActions.excludeFromScaling());
    actions.push(dashboardActions.includeForScaling());
    actions.push(dashboardActions.applyFilter());
    actions.push(dashboardActions.sortAscending());
    actions.push(dashboardActions.sortDescending());
    actions.push(dashboardActions.sortBySortId());
    actions.push(dashboardActions.showAbsoluteMode());
    actions.push(dashboardActions.showPercentageMode());

    const contextMenuItems: ContextMenuItem[] = [];
    actions = actions.filter((action) => !!action.callback);
    actions.map((action) => contextMenuItems.push(toMenuItem(action)));

    contextMenuService.onShowContextMenu(posX, posY, contextMenuItems);
  }

  function toMenuItem(action: Action): ContextMenuItem {
    return new ContextMenuItem(action.icon, action.text, action.callback);
  }

  return {
    clickHelper,
    touchHelper,
  };
}
