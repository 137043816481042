import {
  IPortalTilePageDto,
  PortalTilePageDto,
  IUrlPortalTilePageDto,
  UrlPortalTilePageDto,
} from "@/common/service-clients/generated-clients";
import { IPageVmBuilder } from "../page-vm-builder.interface";
import { ITilePageVm } from "../tile-page-vm.interface";
import { UrlPageVm } from "./url-page-vm";

export class UrlPageBuilder implements IPageVmBuilder {
  createVm(tilePageDto: IPortalTilePageDto): ITilePageVm {
    const urlPageDto = tilePageDto as IUrlPortalTilePageDto;

    const result = new UrlPageVm(urlPageDto.publishedApplicationId);
    result.id = urlPageDto.id;
    result.name = urlPageDto.name;
    result.description = urlPageDto.description;

    return result;
  }
  createDto(pageVm: ITilePageVm): PortalTilePageDto {
    if (!this._isUrlPage(pageVm)) {
      throw Error("Wrong TilePage type used in UrlPageBuilder. Got type: " + pageVm.type);
    }

    const result = UrlPortalTilePageDto.fromJS({
      id: pageVm.id,
      name: pageVm.name,
      description: pageVm.description,
      publishedApplicationId: pageVm.pubAppId,

      portalTile: null,
      portalTilePageType: "UrlPortalTilePage",
      publishedApplication: null,
    } as IUrlPortalTilePageDto);

    return result;
  }

  private _isUrlPage(tilePageVm: ITilePageVm): tilePageVm is UrlPageVm {
    return tilePageVm.type === "Url";
  }
}
