import {
  ConnectionDto,
  ConnectionFilterDto,
  ConnectionSetDto,
  ConnectionSetGroupDto,
  DatabaseType,
  DesignatedConnectionDto,
  IConnectionGroupsServiceClient,
  IConnectionSetGroupsServiceClient,
  IConnectionSetsServiceClient,
  IConnectionsServiceClient,
} from "@/common/service-clients/generated-clients";
import { IConnectionFacade } from "./connection-facade.interface";
import { Connection, ConnectionGroup, DesignatedConnection } from "./dto-wrappers";
import { ConnectionCreationNotAllowedReason } from "./connection-creation-not-allowed-reason";
import { ValueResult } from "@/common/results/value-result";
import { FailedReason } from "@/common/results/failed-reason";
import { Result } from "@/common/results/result";
import { UniqueNameGenerator } from "@/features/application-wizard/backend-wrapper/unique-name-generator";
export class ConnectionFacade implements IConnectionFacade {
  private readonly _friendlyIdShouldBeGeneratedByService: number = -1;
  private readonly _supportedDatabaseType: DatabaseType =
    "MicrosoftSqlServerAnalysisServices";

  constructor(
    private readonly _connectionsClient: IConnectionsServiceClient,
    private readonly _connectionSetGroupsClient: IConnectionSetGroupsServiceClient,
    private readonly _connectionSetsClient: IConnectionSetsServiceClient,
    private readonly _connectionGroupsClient: IConnectionGroupsServiceClient
  ) {}

  async getConnection(connectionId: string): Promise<ConnectionDto> {
    return await this._connectionsClient.getConnection(connectionId);
  }

  async getDesignatedConnectionFromConnectionSetId(
    connectionSetId: string
  ): Promise<DesignatedConnection> {
    return await this._getDesignatedConnectionFromConnectionSetId(connectionSetId);
  }

  async getConnectionSetIdFromConnectionId(connectionId: string): Promise<string> {
    const designatedConnection = await this._getDesignatedConnectionForConnectionId(
      connectionId
    );

    if (designatedConnection === null || designatedConnection === undefined) return null;

    return designatedConnection.connectionSetId;
  }

  async getConnectionGroups(): Promise<ValueResult<ConnectionGroup[], FailedReason>> {
    try {
      const connectionGroups = await this._connectionGroupsClient.getConnectionGroups(
        null,
        "Admin"
      );

      return ValueResult.createFromValue(connectionGroups);
    } catch (e) {
      return ValueResult.createFromError(e);
    }
  }

  async getAllConnectionNamesFor(
    connectionGroupId: string
  ): Promise<ValueResult<string[], FailedReason>> {
    try {
      const connections = await this._connectionsClient.getConnections(connectionGroupId);
      const connectionNames = connections.map((connection) => connection.name);
      return ValueResult.createFromValue(connectionNames);
    } catch (e) {
      return ValueResult.createFromError(e);
    }
  }

  async getAllConnections(): Promise<ValueResult<Connection[], FailedReason>> {
    try {
      const connections = await this._connectionsClient.find(
        new ConnectionFilterDto({
          hasAtLeasOneDesignatedConnection: true,
          databaseTypes: [this._supportedDatabaseType],
        })
      );

      return ValueResult.createFromValue(connections);
    } catch (e) {
      return ValueResult.createFromError(e);
    }
  }

  async isUserAllowedToCreateConnection(): Promise<
    Result<ConnectionCreationNotAllowedReason>
  > {
    const getConnectionGroupsResult = await this.getConnectionGroups();

    if (!getConnectionGroupsResult.succeeded) {
      return Result.createFromFailedReason("NoConnectionGroupExistWhereUserIsAdmin");
    }

    if (getConnectionGroupsResult.value.length === 0) {
      return Result.createFromFailedReason("NoConnectionGroupExistWhereUserIsAdmin");
    }

    const connectionSetGroupId = await this._getConnectionSetGroupIdToUse();

    if (connectionSetGroupId === null) {
      return Result.createFromFailedReason("NoConnectionSetGroupExistWhereUserIsAdmin");
    }

    return new Result<ConnectionCreationNotAllowedReason>();
  }

  async createNewConnection(
    connectionName: string,
    connectionGroupId: string,
    connectionString: string
  ): Promise<ValueResult<string, FailedReason>> {
    try {
      const isUserAllowedToCreateConnectionResult =
        await this.isUserAllowedToCreateConnection();

      if (!isUserAllowedToCreateConnectionResult.succeeded) {
        throw new Error(
          "User is not allowed to create a new connection. More details: " +
            isUserAllowedToCreateConnectionResult.failedReason
        );
      }

      const connectionId = await this._createConnection(
        connectionName,
        connectionGroupId,
        connectionString
      );

      const connectionSetGroupId = await this._getConnectionSetGroupIdToUse();

      const connectionSetId = await this._createConnectionSet(
        connectionName,
        connectionSetGroupId
      );

      await this._createDesignatedConnection(
        connectionName,
        connectionId,
        connectionSetId
      );

      return ValueResult.createFromValue(connectionId);
    } catch (error) {
      return ValueResult.createFromError(error);
    }
  }

  private async _createConnection(
    connectionName: string,
    connectionGroupId: string,
    connectionString: string
  ): Promise<string> {
    const connection = new ConnectionDto({
      id: null,
      description: null,
      friendlyId: this._friendlyIdShouldBeGeneratedByService,
      name: connectionName,
      databaseType: this._supportedDatabaseType,
      authenticationMethodType: "None",
      connectionGroupId: connectionGroupId,
      connectionGroup: null,
      connectionString: connectionString,
    });

    return await this._connectionsClient.createConnection(connection);
  }

  private async _getConnectionSetGroupIdToUse(): Promise<string> {
    const connectionSetGroups =
      await this._connectionSetGroupsClient.getConnectionSetGroups(null, "Admin");

    if (connectionSetGroups.length > 0) {
      const connectionSetGroupsWithWizardUsageTips = connectionSetGroups.filter((o) =>
        ConnectionFacade._containsAppWizardUsageTip(o)
      );

      if (connectionSetGroupsWithWizardUsageTips.length > 0) {
        return connectionSetGroupsWithWizardUsageTips[0].id;
      }

      return connectionSetGroups[0].id;
    }

    return null;
  }

  private static _containsAppWizardUsageTip(
    connectionSetGroup: ConnectionSetGroupDto
  ): boolean {
    const wizardUsageTip = "App.Wizard";

    if (connectionSetGroup === null || connectionSetGroup === undefined) {
      return false;
    }

    if (
      connectionSetGroup.description === null ||
      connectionSetGroup.description === undefined
    ) {
      return false;
    }

    return connectionSetGroup.description.includes(wizardUsageTip);
  }

  private async _createConnectionSet(
    connectionName: string,
    connectionSetGroupId: string
  ): Promise<string> {
    const connectionSetName = await UniqueNameGenerator.generateUniqueNameAsync(
      connectionName,
      () => this._connectionSetsClient.getConnectionSets(connectionSetGroupId),
      (connectionSet) => connectionSet.name
    );

    const connectionSet = new ConnectionSetDto({
      id: null,
      friendlyId: this._friendlyIdShouldBeGeneratedByService,
      connectionSetGroup: null,
      connectionSetGroupId: connectionSetGroupId,
      description: null,
      name: connectionSetName,
    });

    return await this._connectionSetsClient.createConnectionSet(connectionSet);
  }

  private async _createDesignatedConnection(
    connectionName: string,
    connectionId: string,
    connectionSetId: string
  ): Promise<void> {
    const referenceCode = await UniqueNameGenerator.generateUniqueNameAsync(
      connectionName + "-Olap",
      () => this._connectionSetsClient.getDesignatedConnections(),
      (designatedConnection) => designatedConnection.referenceCode
    );

    const designatedConnection = new DesignatedConnectionDto({
      id: null,
      connection: null,
      connectionId: connectionId,
      connectionSet: null,
      connectionSetId: connectionSetId,
      referenceCode: referenceCode,
    });

    await this._connectionSetsClient.createDesignatedConnection(designatedConnection);
  }

  private async _getDesignatedConnectionFromConnectionSetId(
    connectionSetId: string
  ): Promise<DesignatedConnectionDto> {
    if (connectionSetId === null || connectionSetId === undefined) {
      return null;
    }

    const designatedConnections =
      await this._connectionSetsClient.getDesignatedConnections(connectionSetId);

    if (designatedConnections.length === 0) return null;

    return designatedConnections[0];
  }

  private async _getDesignatedConnectionForConnectionId(
    connectionId: string
  ): Promise<DesignatedConnectionDto> {
    if (connectionId === null || connectionId === undefined) {
      return null;
    }

    const designatedConnections =
      await this._connectionSetsClient.getDesignatedConnections();

    for (const designatedConnection of designatedConnections)
      if (designatedConnection.connectionId === connectionId) {
        return designatedConnection;
      }

    return null;
  }
}
