import cloneDeep from "lodash/cloneDeep";

export const CloneHelper = {
  clone<T>(original: T): T {
    if (original["clone"]) {
      return original["clone"]();
    }

    return cloneDeep(original);
  },
};
