<script lang="ts">
import { defineComponent, computed } from "vue";
import type { CSSProperties } from "vue/types/jsx";

export default defineComponent({
  props: {
    color: { type: String, default: null },
  },

  setup(props) {
    const colorVars = computed<CSSProperties>(() => {
      return {
        "--main-color": props.color ? props.color : "var(--color_neutralText)",
      };
    });

    return {
      colorVars,
    };
  },
});
</script>

<template>
  <div class="sort-by-name-asc">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 210 210"
      v-bind:style="colorVars"
    >
      <rect class="cls-2" x="35.3" y="35.3" width="109.5" height="109.5" />
      <polygon
        class="cls-2"
        points="144.8 81.7 144.8 144.8 81.7 144.8 81.7 174.7 174.7 174.7 174.7 81.7 144.8 81.7"
      />
      <g class="cls-3">
        <g class="cls-3">
          <path
            class="cls-1"
            d="M84.3,60.8h13l24.1,59.4h-14.3l-4.7-12.5h-23.7l-4.7,12.5h-13.7l24.1-59.4ZM98.6,97.5l-8.1-21.7-8.3,21.7h16.5Z"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<style lang="less" scoped>
.sort-by-name-asc {
  height: 100%;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 100%;

    .cls-1 {
      fill: var(--main-color);
      stroke-width: 0;
    }

    .cls-2 {
      fill: none;
      stroke: var(--main-color);
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 12px;
    }

    .cls-3 {
      isolation: isolate;
    }
  }
}
</style>
