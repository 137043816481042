﻿import AppRoot from "@/app-root.vue";

import { Bootstrapping } from "@/bootstrapping";
import Axios from "axios";
import {
  Application,
  ApplicationDefinition,
  BissantzUserProfileClient,
  FormsAuthentication,
  getBaseUrl,
  OidcAuthentication,
  startupApp,
  SystemAuthorization,
  User,
} from "@bissantz/smartforms";
import { Dm7App } from "./app";
import {
  initLogger,
  logGlobalUnhandledErrors,
  logUnhandledRejection,
  logVueErrors,
} from "./common/logging/logging-instance";
import appDefJson from "@/app.json";
import { appResources } from "./app-resources";
import { combinePaths } from "@/common/helper/combine-paths";
import { login } from "@/common/internal-auth-provider";
import { Routes } from "@/routes";
import { ExternalUsersServiceClient } from "@/common/service-clients/generated-clients";
import { getCommonAxiosInstance } from "@/common/common-axios-instance";
import { portalSelection } from "@/features/portal/contract/portal-selection.interface";

import Vue from "vue";
import { Versions } from "./versions";

async function main(): Promise<void> {
  // TODO: remove copy&paste for startupTexts
  const startup = document.getElementById("startup");
  startup.innerHTML = appResources.startupTexts.connectingServer;

  await initLogger();
  // add global error handlers
  window.addEventListener("error", logGlobalUnhandledErrors);
  window.addEventListener("unhandledrejection", logUnhandledRejection);

  const baseUrl = getBaseUrl();

  const absoluteServerUri = location.protocol + "//" + location.host;
  const apiUrl = combinePaths(absoluteServerUri, combinePaths(baseUrl, "api"));

  Axios.defaults.baseURL = baseUrl; // must NOT contain /api, since generated clients contain that path information
  let hostId = "";
  let idsApiVersion = null;

  try {
    const urlParams = new URLSearchParams(window.location.search);
    const autoLogin = urlParams.get("autoLogin");
    const loginKey = urlParams.get("loginKey");
    if (autoLogin && loginKey) {
      Axios.defaults.headers.common["X-Login-Key"] = loginKey;
    }

    await Bootstrapping.initialize();

    const idsVersion = Bootstrapping.idsVersion
      ? Number(Bootstrapping.idsVersion.match(/\d+\.\d+/[0]))
      : null;
    if (idsVersion !== null && (idsVersion >= 2.7 || idsVersion === 0)) {
      hostId = await (
        await Axios.get(
          combinePaths(
            Bootstrapping.apiSettings.authorityUrl,
            `Application/GetHostId?applicationId=${Bootstrapping.apiSettings.clientId}`
          )
        )
      ).data;
    }

    idsApiVersion = Bootstrapping.idsApiVersion;
  } catch (exception) {
    startup.innerHTML = appResources.startupTexts.bootstrappingFailed;
    throw exception;
  }

  if (idsApiVersion && Number(idsApiVersion.match(/\d+/)[0]) != Versions.IdsApiVersion) {
    startup.innerHTML = appResources.startupTexts.idsIsIncompatible.replace(
      "{0}",
      idsApiVersion
    );
    throw new Error(startup.innerHTML);
  }

  startup.innerHTML = appResources.startupTexts.startup;

  Vue.config.errorHandler = logVueErrors;
  const appDef: ApplicationDefinition = appDefJson as object as ApplicationDefinition;

  startupApp({
    apiUrl: apiUrl,
    render: (h) => {
      return h(AppRoot);
    },
    element: "#app",
    dataScopeType:
      Bootstrapping.apiSettings.authenticationMode == "IdentityServer"
        ? "system"
        : undefined,
    appType: Dm7App,
    configureApp: (application) => {
      window["application"] = application; // for easy debugging via console

      application.hostId = hostId;
      application.addDefinition(appDef);
      application.toc.show = true;
      application.selectedContent = application.content[0]; // [Workaround] should be default behavior???
      application.design = "7"; // TODO: configurable in app.json
      application.toc.back = true; // TODO: extend SmartForms, make application.toc configurable in app.json
      application.toc.template = undefined; // TODO: extend SmartForms, make application.toc configurable in app.json
      application.templates.spacerBottomHeader = "menu-footer"; // content before the built-in footer
      application.allowSelectSystem = true;
      application.openMainMenuOnRightClick = false;
      application.mainMenuRightClickTarget = "portalBody";
      application.userInfoOptions.showRoles = false; // not used in DM7
      application.userInfoOptions.showGroups = true;
      application.userInfoOptions.oidcAuthDisplayName = "Bissantz IDS";
      application.userInfoOptions.formsAuthDisplayName =
        appResources.generalTexts.formsAuthDisplayName; // must be updated after language change! TODO: add formsAuthDisplayLabelId

      if (Bootstrapping.apiSettings.authenticationMode != "Internal")
        application.userInfoOptions.userDisplayName = "username";

      application.locale = detectDefaultLanguage(application); // TODO: still needed? isn't that default behavior of SmartForms??
      application.useUserLocale = true;

      // selectable languages are based on the labels, so add empty dicts, even if not used
      application.labels.add("de-de", {});
      application.labels.add("de-at", {});
      application.labels.add("de-ch", {});

      // TODO: saveUserLanguage for FormsAuth and OidcAuth

      if (Bootstrapping.apiSettings.subPath)
        application.storage.subPath = combinePaths(
          combinePaths("/", Bootstrapping.apiSettings.subPath),
          "/"
        );
      else application.storage.subPath = "/";

      application.authorization = new SystemAuthorization(application);

      application.placeholders.variables.values = {
        session: application.session,
        portalSelection,
        applicationId: () => this.session.appObj?.applicationId,
      };

      if (application.dataScope)
        application.dataScope.requestBehavior.sendSystemId = true;

      if (Bootstrapping.apiSettings.authenticationMode == "IdentityServer") {
        application.saveUserLocale = true;
        application.userProfileClient = new BissantzUserProfileClient(
          Bootstrapping.apiSettings.authorityUrl,
          () => application
        );
        application.afterUserTenantValidated = async () => {
          startup.innerHTML = appResources.startupTexts.userLoaded;

          const externalUsersService = new ExternalUsersServiceClient(
            null,
            getCommonAxiosInstance()
          );

          await externalUsersService.startSession();
        };
      } /* else {
        // TODO: use saveUserLocale instead of custom implementation
        application.userProfileClient = new DefaultUserProfileClient(application.dataService, 'InternalUser/UpdateLocale');
      }*/
    },

    configureAuth: (application) => {
      const user = Bootstrapping.user;
      if (user) {
        application.user = new User({
          allowAllSystems: user.allowAllSystems,
          allowedSystemIds: user.allowedSystemIds,
          groups: user.groups,
          name: user.username,
          tenantId: user.tenantId,
        } as User);
        return undefined;
      }

      startup.innerHTML = appResources.startupTexts.startupAuthentication;

      // TODO: customize client proxy code gen -> enum names should start lower-case
      if (Bootstrapping.apiSettings.authenticationMode == "Internal") {
        const formsAuth = new FormsAuthentication(login, application.dataService);
        application.authentication = formsAuth;
        application.userAdminRoles = ["systemAdmin"];
        application.userAdminLink = combinePaths(
          "/",
          combinePaths(baseUrl, "/admin/internal-users")
        );
        return formsAuth;
      } else {
        if (!Bootstrapping.apiSettings.authorityUrl)
          throw "The authorityUrl must be configured in app settings, when authenticationMode is IdentityServer.";

        if (!Bootstrapping.apiSettings.clientId)
          throw "The clientId must be configured in app settings, when authenticationMode is IdentityServer.";

        application.userAdminLink = combinePaths(
          "/",
          combinePaths(baseUrl, combinePaths("/admin/", application.dataScope.path))
        );

        return OidcAuthentication.withDefaultBissantzSettings({
          authority: Bootstrapping.apiSettings.authorityUrl,
          client_id: Bootstrapping.apiSettings.clientId,
          scope: "openid profile email bissantzservice_dashboard",
          tenantId: application.dataScope?.tenant?.id,
          systemId: application.dataScope?.system?.id,
        });
      }
    },

    configureRoutes: () => new Routes(),
  });
}

function detectDefaultLanguage(application: Application): string {
  const lang = navigator.language;

  const shortKeys = application.resources.getLocales();
  for (let i = 0; i < shortKeys.length; i++) {
    if (lang.toLowerCase().startsWith(shortKeys[i].toLowerCase())) return shortKeys[i];
  }

  return lang;
}

main();
