import { Application, PublishedApplication } from "../../backend-wrapper/dto-wrappers";

export class AppEntry {
  private _application: Application;
  private _deltaMasterPubApp: PublishedApplication;
  private _dashboardPubApp: PublishedApplication;

  get application(): Application {
    return this._application;
  }

  get deltaMasterPubApp(): PublishedApplication {
    return this._deltaMasterPubApp;
  }

  get dashboardPubApp(): PublishedApplication {
    return this._dashboardPubApp;
  }

  constructor(
    application: Application,
    deltaMasterPubApp: PublishedApplication,
    dashboardPubApp: PublishedApplication
  ) {
    this._application = application;
    this._deltaMasterPubApp = deltaMasterPubApp;
    this._dashboardPubApp = dashboardPubApp;
  }
}
