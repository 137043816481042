export type SparkOrientation = "negative" | "positive" | "mixed";

export class SparklineCommon {
  static maxDurations = {
    disabledToEnabled: 1000,
    enabledToEnabled: 500,
    enabledToAvg: 500,
    avgToEnabled: 1000,
    enabledToDisabled: 500,
  };

  // currently changing from "enabled to avg and to enabled" is the longest transition
  static generalAnimationMaxDuration =
    this.maxDurations.enabledToAvg + this.maxDurations.avgToEnabled;

  static events = {
    sparklineBox_histIdxChanged: "sparklineBox_histIdxChanged",
  };
}

export class SparklineState {
  barOrientation: SparkOrientation;
  maxHeight: number = 0;

  get localSparklineMax(): number {
    if (this.barOrientation === "positive") {
      return this.positiveMax;
    }
    if (this.barOrientation === "negative") {
      return this.negativeMax;
    }
    return Math.max(this.positiveMax, this.negativeMax);
  }

  positiveMax: number = 0;
  negativeMax: number = 0;
  localSparklineAvg: number = 0;
  localSparklineAvgMaxGap: number = 0;
}
