import { ITypedEvent } from "@/common/events/ityped-event";
import { TypedEvent } from "@/common/events/typed-event";
import { IZoomService } from "./zoom-service.interface";
import { roundToDecimal } from "@/common/formatting/rounding";

const stepWidth = 0.1;
const minZoomFactor = 0.5;
const default100Percent = 1.0; // maximum zoom is at least 100%
const minimalTileWidth = 520;
// 520 =
// 2x 200px minColWidth +
// 3x 40px colMargin
export class ZoomService implements IZoomService {
  private _factorChanged = new TypedEvent<{ oldFactor: number; newFactor: number }>();
  private _maxFactorChanged = new TypedEvent<{ oldMax: number; newMax: number }>();
  private _currentZoomFactor: number = default100Percent;
  private _wasZoomed: boolean = false;
  private _maxZoomFactor: number = 0;
  private _useMetaKey: boolean = false;

  // No 'removeEventListener' here because the InputEventsServer is a singleton
  constructor(useMetaKey: boolean = false) {
    this._useMetaKey = useMetaKey;
    this._addEventListeners();
  }

  get maxFactorChanged(): ITypedEvent<{ oldMax: number; newMax: number }> {
    return this._maxFactorChanged;
  }

  get factorChanged(): ITypedEvent<{ oldFactor: number; newFactor: number }> {
    return this._factorChanged;
  }

  setBackToNormal(): void {
    this._wasZoomed = false;
    this._setZoom(1.0);
  }

  get factor(): number {
    return this._currentZoomFactor;
  }

  set factor(value: number) {
    if (value > this.maxZoomFactor) {
      value = this.maxZoomFactor;
    }

    this._setZoom(value);
  }

  set currentPortalWidth(value: number) {
    const currentPortalWidth = value;

    const calculatedMax =
      Math.floor(currentPortalWidth / minimalTileWidth / stepWidth) * stepWidth;

    const newMax = Math.max(calculatedMax, default100Percent);

    if (this._maxZoomFactor !== newMax) {
      const oldMax = this._maxZoomFactor;
      this._maxZoomFactor = newMax;
      this._maxFactorChanged.emit({ oldMax, newMax });
    }

    if (this._currentZoomFactor > this._maxZoomFactor) {
      this._setZoom(this._maxZoomFactor);
    }
  }

  get maxZoomFactor(): number {
    return this._maxZoomFactor;
  }

  get minZoomFactor(): number {
    return minZoomFactor;
  }

  private _setZoom(newFactor: number): void {
    const oldFactor = this._currentZoomFactor;
    if (!this._isFactorInRange(newFactor)) return;

    this._currentZoomFactor = roundToDecimal(newFactor, 2);

    if (oldFactor != this._currentZoomFactor) {
      this._factorChanged.emit({ oldFactor, newFactor });
    }

    if (this._wasZoomed && newFactor === default100Percent) {
      this._wasZoomed = false;
    } else {
      this._wasZoomed = true;
    }
  }

  private _isFactorInRange(factor: number): boolean {
    return factor >= minZoomFactor && factor <= this.maxZoomFactor;
  }

  private _addEventListeners(): void {
    window.addEventListener("wheel", this._handleWheel.bind(this), { passive: false });
    window.addEventListener("keydown", this._handleZoomKeys.bind(this));
    this._disableNativeZooming();
  }

  private _disableNativeZooming() {
    window.addEventListener(
      "wheel",
      (ev) => {
        if (!this._isActionKey(ev)) {
          return;
        }
        ev.preventDefault();
        return false;
      },
      { passive: false }
    );

    window.addEventListener("keydown", (ev) => {
      const isZoom = this._isZoomKey(ev);

      if (isZoom && this._isActionKey(ev)) {
        ev.preventDefault();
        return false;
      }
    });
  }

  /**
   * Currently only handles/tested with german and english keyboard layouts
   */
  private _isZoomKey(ev: KeyboardEvent): boolean {
    const isZoom = this._isPlusKey(ev) || this._isMinusKey(ev) || this._isZeroKey(ev);
    return isZoom;
  }

  private _isPlusKey(ev: KeyboardEvent): boolean {
    const isPlus =
      (ev.key === "=" && !ev.shiftKey) || // english
      ev.key === "+" || // english + shift OR german
      (ev.key === "*" && ev.shiftKey && ev.code !== "Digit8"); // german + shift
    return isPlus;
  }

  private _isMinusKey(ev: KeyboardEvent): boolean {
    const isMinus = ev.key === "-" || ev.key === "_";
    return isMinus;
  }

  private _isZeroKey(ev: KeyboardEvent): boolean {
    const isZero = ev.key === "0";
    return isZero;
  }

  private _handleWheel(ev: WheelEvent) {
    if (!this._isActionKey(ev)) {
      return;
    }
    // if deltaY is positive, zoom out (zoomFactor - 0.1)
    // if deltaY is negative, zoom in (zoomFactor + 0.1)
    if (ev.deltaY > 0) {
      this._setZoom(this._currentZoomFactor - stepWidth);
    } else {
      this._setZoom(this._currentZoomFactor + stepWidth);
    }
  }

  private _isActionKey(ev: KeyboardEvent | WheelEvent): boolean {
    const isAction = this._useMetaKey ? ev.metaKey : ev.ctrlKey;
    return isAction;
  }

  private _handleZoomKeys(ev: KeyboardEvent) {
    const isZoom = this._isZoomKey(ev);

    if (!isZoom) return;
    if (!this._isActionKey(ev)) return;

    if (this._isZeroKey(ev)) {
      this._setZoom(1.0);
    } else if (this._isMinusKey(ev)) {
      this._setZoom(this._currentZoomFactor - 0.1);
    } else if (this._isPlusKey(ev)) {
      this._setZoom(this._currentZoomFactor + 0.1);
    }
  }
}
