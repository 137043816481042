// TODO VS: create FM for the Dtos!
import { IPortalViewStateDto } from "@/common/service-clients/generated-clients";
import { PortalVm } from "../view-models/portal-vm";
import { ViewStateFacade } from "../backend-wrapper/view-state-facade";

import { VSGetter, VsSyncerBase } from "@/services/view-state-service/vs-syncer-base";
import { VsScopeState } from "@/services/view-state-service/vs-scope-state";
import { ProtalVmWatchList } from "./syncer-helper/vm-watch-list";
import { PortalViewStateGenerator } from "./syncer-helper/vs-generator";
import { PortalVmReadyChecker } from "./syncer-helper/vm-ready-checker";
import { PortalVsApplicator } from "./syncer-helper/vs-applicator";
import { SimpleResult } from "@/common/results/simple-result";

export class PortalVsSyncer extends VsSyncerBase<IPortalViewStateDto> {
  private _viewStateFacade: ViewStateFacade = null;

  constructor(vm: PortalVm, vsScopeState: VsScopeState, facade: ViewStateFacade) {
    super(
      vm,
      vsScopeState,
      new ProtalVmWatchList(),
      new PortalViewStateGenerator(),
      new PortalVmReadyChecker(),
      new PortalVsApplicator()
    );
    this._viewStateFacade = facade;
  }

  protected async _updateViewStateAsync(
    getLatestVsCopy: VSGetter<IPortalViewStateDto>
  ): Promise<IPortalViewStateDto> {
    const vs = getLatestVsCopy();

    const jobResult = await this._vsScopeState.requestMutex.runExclusive(
      async () => await this._viewStateFacade.updatePortalVsAsync(vs)
    );
    if (jobResult.error) {
      this._vsScopeState.isInError.value = true;
    }

    return jobResult.value;
  }

  protected async _createViewStateAsync(
    getLatestVsCopy: VSGetter<IPortalViewStateDto>
  ): Promise<IPortalViewStateDto> {
    const vs = getLatestVsCopy();

    const jobResult = await this._vsScopeState.requestMutex.runExclusive(
      async () => await this._viewStateFacade.createPortalVsAsync(vs)
    );

    if (jobResult.error) {
      this._vsScopeState.isInError.value = true;
    }

    return jobResult.value;
  }

  async _getViewStateAsync(): Promise<IPortalViewStateDto> {
    let portalResult: SimpleResult<IPortalViewStateDto, object | string> = null;

    if (this._viewModel.viewStateId) {
      portalResult = await this._viewStateFacade.getPortalVsAsync(
        this._viewModel.viewStateId
      );
    }

    if (portalResult.error) {
      this._vsScopeState.isInError.value = true;
    }

    return portalResult.value;
  }
}
