import {
  ExecuteOrUndoReportCommandRequestDtoOfDashboardReportCommandType,
  IReportsServiceClient,
  ISemanticModelsServiceClient,
  DashboardReportCommandInfoDto,
  ReportCommandInfoDto,
  CreateDashboardReportPeriodSelectionRequestDto,
} from "@/common/service-clients/generated-clients";
import {
  OlapDatabaseCube,
  MagicButton,
  HierarchyLevel,
  Dimension,
  PeriodElement,
  Kpi,
} from "./dto-wrappers";
import { Result } from "@/common/results/result";
import { FailedReason } from "@/common/results/failed-reason";
import { ValueResult } from "@/common/results/value-result";
import { IReportFacade } from "./report-facade-interface";

export class ReportFacade implements IReportFacade {
  constructor(
    private readonly _semanticModelClient: ISemanticModelsServiceClient,
    private readonly _reportsClient: IReportsServiceClient
  ) {}

  async getOLAPCubesAsync(
    connectionId: string
  ): Promise<ValueResult<OlapDatabaseCube[], FailedReason>> {
    try {
      const allOlapCubes =
        await this._semanticModelClient.getOlapDatabaseCubesFromConnection(connectionId);
      return ValueResult.createFromValue(allOlapCubes);
    } catch (e) {
      return ValueResult.createFromError(e);
    }
  }

  async getMagicButtonsAsync(
    reportId: string
  ): Promise<ValueResult<MagicButton[], FailedReason>> {
    try {
      const commandTypes = await this._getAvaibleReportCommandTypesAsync(reportId);
      const magicButtons = commandTypes.map(
        (rci) => new MagicButton(rci.reportCommandType, rci.isExecuted)
      );
      return ValueResult.createFromValue(magicButtons);
    } catch (error) {
      return ValueResult.createFromError(error);
    }
  }

  async applyMagicButtonAsync(
    reportId: string,
    selectedMagicButton: MagicButton
  ): Promise<ValueResult<MagicButton[], FailedReason>> {
    try {
      const singleMB = await this._executeReportCommandAsync(
        reportId,
        selectedMagicButton
      );
      if (singleMB.isFaulted) {
        throw new Error();
      }
      const reportTypes = await this._getAvaibleReportCommandTypesAsync(reportId);
      const allMagicButtons = reportTypes.map(
        (rci) => new MagicButton(rci.reportCommandType, rci.isExecuted)
      );
      return ValueResult.createFromValue(allMagicButtons);
    } catch (e) {
      return ValueResult.createFromError(e);
    }
  }

  private async _getAvaibleReportCommandTypesAsync(
    reportId: string
  ): Promise<DashboardReportCommandInfoDto[]> {
    const reportCommandInfo = await this._reportsClient.getReportCommands(reportId);

    const commandTypes = reportCommandInfo.filter(
      (value) =>
        value instanceof DashboardReportCommandInfoDto &&
        value.isVisible &&
        value.isAvailable
    );
    return commandTypes as DashboardReportCommandInfoDto[];
  }

  private async _executeReportCommandAsync(
    reportId: string,
    selectedMagicButton: MagicButton
  ): Promise<ReportCommandInfoDto> {
    const singleMB = await this._reportsClient.executeOrUndoReportCommand(
      reportId,
      new ExecuteOrUndoReportCommandRequestDtoOfDashboardReportCommandType({
        commandType: selectedMagicButton.magicButtonType,
        undo: selectedMagicButton.isExecuted,
      })
    );
    return singleMB;
  }

  async getHierarchiesAsync(
    semanticModelId: string
  ): Promise<ValueResult<Dimension[], FailedReason>> {
    try {
      const allHierarchies = await this._semanticModelClient.getHierarchies(
        semanticModelId
      );

      const allDimension = allHierarchies.map((levelValue) => {
        const hierarchyLevels = levelValue.hierarchyLevels
          .map(
            (hierarchy, index) =>
              new HierarchyLevel(hierarchy.id, levelValue.id, hierarchy.name, index, "")
          )
          .filter((value) => {
            if (levelValue.contentType === "Period") {
              return true;
            }
            return value.levelOrdinal !== 0;
          });
        return new Dimension(
          levelValue.id,
          levelValue.name,
          hierarchyLevels,
          levelValue.contentType
        );
      });

      return ValueResult.createFromValue(allDimension);
    } catch (error) {
      return ValueResult.createFromError(error);
    }
  }

  async loadHierarchieLevelsAsync(
    dimension: Dimension
  ): Promise<ValueResult<Dimension, FailedReason>> {
    try {
      await Promise.all(
        dimension.allHierarchyLevels.map(async (level) => {
          const singleLevel = await this._semanticModelClient.getHierarchyLevel(level.id);
          level.componentId = singleLevel.componentId;
        })
      );

      return ValueResult.createFromValue(dimension);
    } catch (error) {
      return ValueResult.createFromError(error);
    }
  }

  async loadHierarchyLevelElementsAsync(
    level: HierarchyLevel,
    pubAppId: string
  ): Promise<ValueResult<HierarchyLevel, FailedReason>> {
    try {
      const periodElements =
        await this._semanticModelClient.getComponentElementInfosByHierarchyLevel(
          level.id,
          pubAppId
        );

      const allElements = periodElements.map((element) => {
        return new PeriodElement(element.name, element.caption, level.id);
      });

      level.elements = allElements;

      return ValueResult.createFromValue(level);
    } catch (error) {
      return ValueResult.createFromError(error);
    }
  }

  async getKpisAsync(semanticModelId: string): Promise<ValueResult<Kpi[], FailedReason>> {
    try {
      const kpis = await this._semanticModelClient.getKpis(semanticModelId);

      return ValueResult.createFromValue(kpis);
    } catch (error) {
      return ValueResult.createFromError(error);
    }
  }

  async createDashboardReportKpiAsync(
    reportId: string,
    index: number,
    kpiId: string
  ): Promise<Result<FailedReason>> {
    try {
      await this._reportsClient.createDashboardReportKpi(reportId, index, kpiId);
    } catch (error) {
      return Result.createFromFailedReason(error);
    }
    return new Result();
  }

  async deleteDashboardReportKpiAsync(
    reportId: string,
    kpiId: string
  ): Promise<Result<FailedReason>> {
    try {
      await this._reportsClient.deleteDashboardReportKpi(reportId, kpiId);
    } catch (error) {
      return Result.createFromFailedReason(error);
    }
    return new Result();
  }

  async createDashboardReportNavigationAsync(
    reportId: string,
    hierarchyLevelId: string,
    index: number
  ): Promise<Result<FailedReason>> {
    try {
      await this._reportsClient.createDashboardReportNavigation(
        reportId,
        hierarchyLevelId,
        index
      );
    } catch (error) {
      return Result.createFromFailedReason(error);
    }
    return new Result();
  }

  async deleteDashboardReportNavigationAsync(
    reportId: string,
    dimensionComponentId: string,
    dimensionHierarchyLevelId?: string | undefined
  ): Promise<Result<FailedReason>> {
    try {
      await this._reportsClient.deleteDashboardReportNavigation(
        reportId,
        dimensionComponentId,
        dimensionHierarchyLevelId
      );
    } catch (error) {
      return Result.createFromFailedReason(error);
    }
    return new Result();
  }

  async createDashboardReportPeriodSelectionAsync(
    publishedApplicationId: string,
    reportId: string,
    hierarchyLevelId: string,
    componentElementName: string
  ): Promise<Result<FailedReason>> {
    try {
      await this._reportsClient.createDashboardReportPeriodSelection(
        reportId,
        new CreateDashboardReportPeriodSelectionRequestDto({
          publishedApplicationId,
          hierarchyLevelId,
          componentElementName,
        })
      );
    } catch (error) {
      return Result.createFromFailedReason(error);
    }
    return new Result();
  }

  async deleteDashboardReportPeriodSelectionAsync(
    reportId: string
  ): Promise<Result<FailedReason>> {
    try {
      await this._reportsClient.deleteDashboardReportPeriodSelection(reportId);
    } catch (error) {
      return Result.createFromFailedReason(error);
    }
    return new Result();
  }

  async getDashboardReportKpisAsync(
    reportId: string
  ): Promise<ValueResult<Kpi[], FailedReason>> {
    try {
      const kpiSelection = await this._reportsClient.getDashboardReportKpis(reportId);

      return ValueResult.createFromValue(kpiSelection);
    } catch (error) {
      return ValueResult.createFromError(error);
    }
  }

  async getDashboardReportNavigationsAsync(
    reportId: string
  ): Promise<ValueResult<HierarchyLevel[], FailedReason>> {
    try {
      const navSelection = await this._reportsClient.getDashboardReportNavigations(
        reportId
      );

      const allNavigations = navSelection.map((value, index) => {
        return new HierarchyLevel(
          value.id,
          value.hierarchyId,
          value.name,
          index,
          value.componentId
        );
      });

      return ValueResult.createFromValue(allNavigations);
    } catch (error) {
      return ValueResult.createFromError(error);
    }
  }

  async getDashboardReportPeriodSelectionAsync(
    reportId: string
  ): Promise<ValueResult<PeriodElement, FailedReason>> {
    try {
      const elem = await this._reportsClient.getDashboardReportPeriodSelection(reportId);

      const periodSelection = new PeriodElement(elem?.name, elem?.name, elem?.id);

      return ValueResult.createFromValue(periodSelection);
    } catch (error) {
      return ValueResult.createFromError(error);
    }
  }

  async getReportCommandsAsync(
    reportId: string
  ): Promise<ValueResult<ReportCommandInfoDto[], FailedReason>> {
    try {
      const magicButtons = await this._reportsClient.getReportCommands(reportId);

      return ValueResult.createFromValue(magicButtons);
    } catch (error) {
      return ValueResult.createFromError(error);
    }
  }
}
