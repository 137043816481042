<script lang="ts">
import HideAndShow from "@/common/components/hide-and-show.vue";
import XIcon from "@/common/components/icons/x-icon.vue";

import { defineComponent } from "vue";
import { dragHandle } from "@bissantz/tile-grid";
import { DefaultStyles } from "@/common/styles/default-styles";
import { useHelpText } from "@/services/help-text-service/help-text.cpsl";
import { PortalCommon } from "../portal-common";

const iconColor = DefaultStyles.colorConstants.headerTextHEX;

export default defineComponent({
  components: {
    HideAndShow,
    XIcon,
  },

  emits: [PortalCommon.portalTile_deleteTile.name],

  props: {
    title: { type: String, required: true },
    canShowDeleteButton: { type: Boolean, required: true },
    onDeleteCallback: { type: Function, required: true },
  },

  setup: function () {
    const helpTextCpsl = useHelpText();

    return {
      dragHandle,
      iconColor,
      helpTextCpsl,
    };
  },
});
</script>

<template>
  <div
    class="portal-tile_header-title"
    v-bind:class="[$props.canShowDeleteButton ? 'clone' : '', dragHandle]"
  >
    <div v-if="canShowDeleteButton" class="title-side" />
    <HideAndShow>{{ $props.title }}</HideAndShow>
    <XIcon
      v-if="canShowDeleteButton"
      v-bind:initialColor="iconColor"
      v-on:click.native="$props.onDeleteCallback"
      v-bind:data-helpText="helpTextCpsl.deletePortalTileText()"
    />
  </div>
</template>

<style scoped lang="less">
@import "../../../common/styles/base-styles.less";

.portal-tile_header-title {
  --title-padding: 6px;
  padding: 0 var(--title-padding);
  width: calc(100% - 2 * var(--title-padding));
  min-width: 0;
  background-color: var(--color_bg_white);
  color: var(--color_neutralText);

  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;

  // TODO: borders into css variable
  border-radius: 6px 6px 0 0;
  font-size: 18px;
  font-family: @fontSemi;
  white-space: nowrap;
  cursor: grab;

  & span {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
  }

  &.clone {
    justify-content: space-between;
  }

  .title-side {
    visibility: hidden;
    min-width: 0;
    flex: 0 0 auto;
    flex-basis: 30px;
  }

  .xIcon {
    height: 40%;
    min-width: 0;
    flex: 0 0 auto;
    flex-basis: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
