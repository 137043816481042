import { IReportFacade } from "@/features/application-wizard/backend-wrapper/report-facade-interface";
import { FailedReason } from "@/common/results/failed-reason";
import { Dimension, HierarchyLevel } from "../../../../backend-wrapper/dto-wrappers";
import { ValueResult } from "@/common/results/value-result";

export class DimensionRetriever {
  private readonly _reportFacade: IReportFacade;
  private readonly _semanticModelId: string;
  private _dimensionsHaveBeenLoaded: boolean = false;
  private _dimensions: Dimension[] = [];

  constructor(reportFacade: IReportFacade, semanticModelId: string) {
    this._reportFacade = reportFacade;
    this._semanticModelId = semanticModelId;
  }

  async getPeriodAsync(): Promise<ValueResult<Dimension, FailedReason>> {
    const dimensionLoadResult = await this._getDimensionsAsync();

    if (dimensionLoadResult.succeeded === false) {
      return ValueResult.createFromError(dimensionLoadResult.failedReason);
    }

    const period = dimensionLoadResult.value.find(
      (dimension) => dimension.contentType === "Period"
    );

    return ValueResult.createFromValue(period);
  }

  async getStandardDimensionsAsync(): Promise<ValueResult<Dimension[], FailedReason>> {
    const dimensionLoadResult = await this._getDimensionsAsync();

    if (dimensionLoadResult.succeeded === false) {
      return ValueResult.createFromError(dimensionLoadResult.failedReason);
    }

    const standardDimensions = dimensionLoadResult.value.filter(
      (dimension) => dimension.contentType === "None"
    );

    return ValueResult.createFromValue(standardDimensions);
  }

  async loadHierarchieLevelsAsync(
    dimension: Dimension
  ): Promise<ValueResult<Dimension, FailedReason>> {
    return await this._reportFacade.loadHierarchieLevelsAsync(dimension);
  }

  async loadHierarchyLevelElementsAsync(
    level: HierarchyLevel,
    pubAppId: string
  ): Promise<ValueResult<HierarchyLevel, FailedReason>> {
    return await this._reportFacade.loadHierarchyLevelElementsAsync(level, pubAppId);
  }

  private async _getDimensionsAsync(): Promise<ValueResult<Dimension[], FailedReason>> {
    if (this._dimensionsHaveBeenLoaded === true) {
      return ValueResult.createFromValue(this._dimensions);
    }

    const dimensionsResult = await this._reportFacade.getHierarchiesAsync(
      this._semanticModelId
    );

    this._dimensions = dimensionsResult.succeeded === true ? dimensionsResult.value : [];

    this._dimensionsHaveBeenLoaded = true;

    return dimensionsResult;
  }
}
