import { SimpleWatchExpression } from "@/common/helper/vue-helper";
import { IVmWatchList } from "@/services/view-state-service/contract/vm-watch-list.interface";
import { PortalVm } from "../../view-models/portal-vm";
import { PortalPageVm } from "../../view-models/portal-page-vm";
import { TileVm } from "../../tile/tile-vm";

export class ProtalVmWatchList implements IVmWatchList {
  get(vm: PortalVm): SimpleWatchExpression<unknown>[] {
    const watchListResult: SimpleWatchExpression<unknown>[] = [];

    watchListResult.push(...this._getPortalWL(vm));

    return watchListResult;
  }

  private _getPortalWL(vm: PortalVm): SimpleWatchExpression<unknown>[] {
    const watchListResult: SimpleWatchExpression<unknown>[] = [];

    // switching the selected page:
    watchListResult.push(() => vm.portalSelection.pageId);

    for (let pageIdx = 0; pageIdx < vm.portalPages.length; pageIdx++) {
      watchListResult.push(...this._getPortalPageWL(vm.portalPages[pageIdx]));
    }

    return watchListResult;
  }

  private _getPortalPageWL(vm: PortalPageVm): SimpleWatchExpression<unknown>[] {
    const watchListResult: SimpleWatchExpression<unknown>[] = [];

    // adding/deleting, or reordering a tile:
    watchListResult.push(() => vm.tileOrder);

    for (let tileIdx = 0; tileIdx < vm.tiles.length; tileIdx++) {
      watchListResult.push(...this._getTileWL(vm.tiles[tileIdx]));
    }

    return watchListResult;
  }

  private _getTileWL(vm: TileVm): SimpleWatchExpression<unknown>[] {
    const watchListResult: SimpleWatchExpression<unknown>[] = [];

    // TODO: How to do handle intial relayouting on portal loading
    //       adepting tile layout to changed screensize etc.?

    // move/resize a tile:
    // watchListResult.push(() => vm.tileConfig.x);
    // watchListResult.push(() => vm.tileConfig.y);
    watchListResult.push(() => vm.tileConfig.w);
    // watchListResult.push(() => vm.tileConfig.h);

    watchListResult.push(() => vm.isFullpage);

    return watchListResult;
  }
}
