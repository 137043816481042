export abstract class VsRoot {
  parentViewStateId: string = null;

  viewStateId: string = null;

  /**
   * ViewState system is initialized and now the
   * respective view can finally be shown
   */
  isVsSyncReady: boolean = false;

  cloneVsRoot(_: VsRoot): void {
    // Don't clone the other properties:
    // these should differ after cloning
    // (same IDs for Data, different ViewState)
    this.viewStateId = null;
    this.isVsSyncReady = false;
  }
}
