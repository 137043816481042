import { DashboardStatusType } from "@/features/dashboard/status-configs/dashboard-status";

// Use any CSS grid unit (px, %, fr, auto) for widths and heights.
// Specify an integer for colSpan/rowSpan to indicate the number of columns/rows to span.
// Set colSpan/rowSpan to -1 to use all available columns/rows.

export const separatorStrokeWidth: string = "1px";

// Separator Widths:
export function getSharedDashboardSeparatorWidths(
  dashboardStatusType: DashboardStatusType
): string[] {
  switch (dashboardStatusType) {
    case "OneValueNoSparklines":
      return ["0", separatorStrokeWidth, "0"];

    case "ThreeValuesNoSparklinesScaleIndexGreaterZero":
    case "ThreeValuesNoSparklinesScaleIndexZero":
      return ["0", separatorStrokeWidth, separatorStrokeWidth, "0"];

    case "ExtendedOneValueNoSparklines":
      return ["0", "0", separatorStrokeWidth, "0", "0"];

    case "ExtendedNoSparklines":
      return ["0", "0", separatorStrokeWidth, separatorStrokeWidth, "0"];

    case "NotExtendedAndSparklines":
      return ["0", "0", "0", "0"];

    case "ExtendedCompactOneValueNoSparklines":
    case "ExtendedCompactNoSparklines":
      return ["0", "0", "0", "0", "0"];

    case "ExtendedAndSparklines":
    case "ExtendedHalfCompactAndSparklines":
    case "ExtendedCompactAndSparklines":
      return ["0", "0", "0", "0", "0", "0"];
  }
}

// Cell Widths:
export function getSharedDashboardCellWidths(
  dashboardStatusType: DashboardStatusType
): string[] {
  switch (dashboardStatusType) {
    case "OneValueNoSparklines":
      return ["85px", "1fr"];

    case "ThreeValuesNoSparklinesScaleIndexGreaterZero":
      return ["85px", "39.96%", "1fr"];

    case "ThreeValuesNoSparklinesScaleIndexZero":
      return ["85px", "50.6%", "1fr"];

    case "NotExtendedAndSparklines":
      return ["1fr", "132px", "1fr"];

    case "ExtendedOneValueNoSparklines":
    case "ExtendedCompactOneValueNoSparklines":
      return ["1fr", "17%", "26%", "30%"];

    case "ExtendedNoSparklines":
    case "ExtendedCompactNoSparklines":
      return ["1fr", "17%", "26%", "30%"];

    case "ExtendedAndSparklines":
    case "ExtendedHalfCompactAndSparklines":
    case "ExtendedCompactAndSparklines":
      return ["1fr", "132px", "17%", "19%", "22%"];
  }
}
