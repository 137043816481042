<script lang="ts">
import { defineComponent, computed } from "vue";
import type { CSSProperties } from "vue/types/jsx";

export default defineComponent({
  props: {
    color: { type: String, default: null },
  },

  setup(props) {
    //
    // Life Cycle:
    // --------------------

    //
    // Computeds:
    // --------------------
    const colorVars = computed<CSSProperties>(() => {
      return {
        "--main-color": props.color ? props.color : "var(--color_neutralText)",
      };
    });

    //
    // Functions:
    // --------------------

    //
    // Watcher:
    // --------------------

    return {
      colorVars,
    };
  },
});
</script>

<template>
  <div class="apply-filter-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 210 210"
      v-bind:style="colorVars"
    >
      <polygon
        class="cls-1"
        points="30.1 45 30.1 70.7 83.3 127.4 83.3 127.4 83.3 165 126.7 165 126.7 127.4 126.7 127.4 179.9 70.7 179.9 45 30.1 45"
      />
    </svg>
  </div>
</template>

<style lang="less" scoped>
.apply-filter-icon {
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 100%;
    width: 100%;

    .cls-1 {
      fill: none;
      stroke: var(--main-color);
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 12px;
    }
  }
}
</style>
