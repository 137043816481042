import {
  IVsSyncer,
  VSGetter,
  VsSyncerBase,
} from "@/services/view-state-service/vs-syncer-base";
import { DashboardVm } from "../view-models/dashboard-vm";
import { VsScopeState } from "@/services/view-state-service/vs-scope-state";
import {
  IDashboardPortalTilePageViewStateDto,
  PortalTilePageViewStateDto,
} from "@/common/service-clients/generated-clients";
import { VueHelper } from "@/common/helper/vue-helper";
import { DashboardVmWatchList } from "./syncer-helper/vm-watch-list";
import { DashboardVsGenerator } from "./syncer-helper/vs-generator";
import { DashboardVmReadyChecker } from "./syncer-helper/vm-ready-checker";
import { ViewStateFacade } from "@/features/dashboard/backend-wrapper/view-state-facade";
import { DashboardVsApplicator } from "./syncer-helper/vs-applicator";
import { exists } from "@/common/object-helper/null-helper";
import { SimpleResult } from "@/common/results/simple-result";

export class DashboardVsSyncer
  extends VsSyncerBase<IDashboardPortalTilePageViewStateDto>
  implements IVsSyncer
{
  private _viewStateFacade: ViewStateFacade = null;

  constructor(vm: DashboardVm, vsScopeState: VsScopeState, facade: ViewStateFacade) {
    super(
      vm,
      vsScopeState,
      new DashboardVmWatchList(),
      new DashboardVsGenerator(),
      new DashboardVmReadyChecker(),
      new DashboardVsApplicator()
    );

    this._viewStateFacade = facade;
  }

  protected async _updateViewStateAsync(
    getLatestVsCopy: VSGetter<IDashboardPortalTilePageViewStateDto>
  ): Promise<IDashboardPortalTilePageViewStateDto> {
    const viewState = getLatestVsCopy() as unknown as PortalTilePageViewStateDto;

    const jobResult = await this._vsScopeState.requestMutex.runExclusive(
      async () => await this._viewStateFacade.updateDashboardVsAsync(viewState)
    );

    if (!jobResult || jobResult.error) {
      this._vsScopeState.isInError.value = true;
    }

    return jobResult?.value;
  }

  protected async _createViewStateAsync(
    getLatestVsCopy: VSGetter<IDashboardPortalTilePageViewStateDto>
  ): Promise<IDashboardPortalTilePageViewStateDto> {
    let isMissingParendVsId: boolean = false;

    // Get parent VS id (needed) or wait for it
    if (!exists(this._viewModel.parentViewStateId)) {
      const waitResult = await VueHelper.waitForChangeAndCondition(
        () => this._viewModel.parentViewStateId,
        exists,
        1000 * 60 * 1 // wait max. 1 min
      );
      isMissingParendVsId = waitResult.timedOut;
    }

    // continue if you got the parent VS id:
    let jobResult: SimpleResult<IDashboardPortalTilePageViewStateDto, object | string> =
      null;
    if (!isMissingParendVsId) {
      const viewState = getLatestVsCopy() as unknown as PortalTilePageViewStateDto;
      viewState.portalTilePageId = (this._viewModel as DashboardVm).tilePageId;
      viewState.portalTileViewStateId = this._viewModel.parentViewStateId;

      jobResult = await this._vsScopeState.requestMutex.runExclusive(
        async () => await this._viewStateFacade.createDashboardVsAsync(viewState)
      );
    }

    if (isMissingParendVsId || jobResult?.error) {
      this._vsScopeState.isInError.value = true;
    }

    return jobResult?.value;
  }

  protected async _getViewStateAsync(): Promise<IDashboardPortalTilePageViewStateDto> {
    const vsId = this._viewModel.viewStateId;
    const result = await this._viewStateFacade.getDashboardVsAsync(vsId);

    if (result.error) {
      this._vsScopeState.isInError.value = true;
    }
    return result?.value;
  }
}
