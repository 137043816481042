import { Ref, onMounted, computed, onBeforeUnmount, ref } from "vue";

// Why this hover-helper ?
// _________________________________________________________________
// In a normal scenario, after tapping an HtmlElement, the hovered
// state remains active until another HtmlElement is tapped. The
// provided code avoids this by disabling hover on tap and allowing
// hovering only on real mouse hover events.
//
// How to use?
// _________________________________________________________________
// In your Vue component:
// const ref_el: Ref<HTMLDivElement> = ref(null);
// const hoverHelperCpsl = useHoverHelper(ref_el);
//
// then use the hoverHelperCsl.isHover.value where needed.

export function useHoverHelper(ref_el: Ref<HTMLElement>) {
  const isMouseHover = ref(false);
  const isTouch = ref(false);

  //
  // Life Cycle:
  // --------------------
  onMounted(() => {
    const el = ref_el.value as HTMLDivElement;
    el.addEventListener("touchstart", onTouchStart);
    el.addEventListener("mouseenter", setIsMouseHover);
    el.addEventListener("mouseleave", clear);
  });

  onBeforeUnmount(() => {
    const el = ref_el.value as HTMLDivElement;
    el.removeEventListener("touchstart", onTouchStart);
    el.removeEventListener("mouseenter", setIsMouseHover);
    el.removeEventListener("mouseleave", clear);
  });

  //
  // Computeds:
  // --------------------
  const isHover = computed(() => {
    return !isTouch.value && isMouseHover.value;
  });

  //
  // Functions:
  // --------------------
  function onTouchStart(): void {
    isTouch.value = true;
  }

  function setIsMouseHover(): void {
    isMouseHover.value = true;
  }

  function clear(): void {
    isMouseHover.value = false;
    isTouch.value = false;
  }

  //
  // Watcher:
  // --------------------

  return {
    isHover,
  };
}
