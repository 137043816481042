import { IKpiDrillStructureFilterViewStateDto } from "./generated-clients";

export const DtoHelper = {
  FilterDtos: {
    isSameFilter(
      filter1: IKpiDrillStructureFilterViewStateDto,
      filter2: IKpiDrillStructureFilterViewStateDto
    ): boolean {
      return (
        filter1.structureElementName === filter2.structureElementName &&
        filter1.structureName === filter2.structureName
      );
    },
  },
};
