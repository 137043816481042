<script lang="ts">
import { PropType, computed, defineComponent } from "vue";
import { PageHintType } from "./page-hint-type";
import { PortalFooterCommon } from "../portal-footer-common";

const fontSizes = {
  normal: "8px",
  small: "6px",
  tiny: "4px",
};

export default defineComponent({
  emits: [PortalFooterCommon.events.pageHints_pageHintClicked],

  props: {
    isActive: { type: Boolean, required: true },
    useAlternativeColors: { type: Boolean, required: true },
    sizeType: { type: String as PropType<PageHintType>, default: "normal" },
  },

  setup(props) {
    const fontSizeStyle = computed(() => {
      return `font-size: ${fontSizes[props.sizeType]}`;
    });

    return {
      fontSizeStyle,
      PortalFooterCommon,
    };
  },
});
</script>

<template>
  <div
    class="portal-footer_page-hint"
    v-bind:class="{ 'shows-bg-color': $props.useAlternativeColors }"
    v-on:click.stop="$emit(PortalFooterCommon.events.pageHints_pageHintClicked)"
  >
    <div
      class="page-hint"
      v-bind:class="{ selected: $props.isActive }"
      v-bind:style="fontSizeStyle"
    />
  </div>
</template>

<style lang="less" scoped>
.portal-footer_page-hint {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8px;
  cursor: pointer;

  .page-hint {
    margin: 0px 3px;
    height: 1em;
    width: 1em;
    background-color: var(--color_bg-gray);

    &.selected {
      background-color: var(--color_Weather3);
      cursor: default;
    }

    :not(&.selected) {
      transition: font-size 0.4s linear(0.83 -0.74%, 1 100%);
    }
  }
  &.shows-bg-color {
    .page-hint {
      background-color: var(--color_transparent50_white);
    }
    .page-hint.selected {
      background-color: var(--color_bg_white);
    }
  }
}
</style>
