import { Ref, onMounted } from "vue";
import { getGUID } from "@/common/helper/guid-helper";

export const compIdTag = "compId";
export const compTypeTag = "compType";

export function useCompAddress(compRootElement: Ref<HTMLElement>, compType: string) {
  const id: string = getGUID();

  //
  // Life Cycle:
  // --------------------
  onMounted(() => {
    initComponentAddress();
  });

  //
  // Computeds:
  // --------------------

  //
  // Functions:
  // --------------------
  function initComponentAddress(): void {
    compRootElement.value.dataset[compIdTag] = id;
    compRootElement.value.dataset[compTypeTag] = compType;
  }

  function isElementFromType(element: HTMLElement, compType: string): boolean {
    return compTypeTag in element.dataset && element.dataset[compTypeTag] === compType;
  }

  //
  // Watcher:
  // --------------------

  return {
    id,
    isElementFromType,
  };
}
