import { IFullLogLine, LogLevel } from "../log-entry.interface";
import { ITransport } from "./transport.interface";

const methLevelMapping: { [key in LogLevel]: (input: string) => void } = {
  [LogLevel.trace]: console.trace,
  [LogLevel.debug]: console.debug,
  [LogLevel.info]: console.info,
  [LogLevel.warn]: console.warn,
  [LogLevel.error]: console.error,
};

export class ConsoleTransport implements ITransport {
  writeLog(line: IFullLogLine): void {
    const logMethod = methLevelMapping[line.level];

    logMethod(`${LogLevel[line.level]} ${line.message}`);
  }
}
