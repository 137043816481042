import { inject, provide } from "vue";
import { AxiosInstance } from "axios";

import {
  IPortalsServiceClient,
  IDashboardsServiceClient,
  PortalsServiceClient,
  DashboardsServiceClient,
} from "@/common/service-clients/generated-clients";
import { ViewStateFacade } from "@/features/dashboard/backend-wrapper/view-state-facade";
import { DashboardFacade } from "@/features/dashboard/backend-wrapper/dashboard-facade";
import { IDashboardFacade } from "@/features/dashboard/backend-wrapper/dashboard-facade.interface";

export function useBackendDependencies() {
  const axiosInstance = inject("axiosInstance", null) as AxiosInstance;

  // list of used service clients:
  let portalClient: IPortalsServiceClient = null;
  let dashboardClient: IDashboardsServiceClient = null;

  instantiateUsedServiceClients(axiosInstance);

  // Create Facades:
  // ---------------
  let dashboardViewStateFacade = inject<ViewStateFacade>(
    "dashboardViewStateFacade",
    null
  );
  if (!dashboardViewStateFacade) {
    dashboardViewStateFacade = new ViewStateFacade(portalClient);
    provide("dashboardViewStateFacade", dashboardViewStateFacade);
  }

  let dashboardFacade = inject<IDashboardFacade>("dashboardFacade", null);
  if (!dashboardFacade) {
    dashboardFacade = new DashboardFacade(dashboardClient);
    provide("dashboardFacade", dashboardFacade);
  }

  // Functions:
  // ---------------
  function instantiateUsedServiceClients(_axiosInstance: AxiosInstance): void {
    portalClient = new PortalsServiceClient(null, axiosInstance);
    dashboardClient = new DashboardsServiceClient(null, axiosInstance);
  }

  return {
    dashboardFacade,
    dashboardViewStateFacade,
  };
}
