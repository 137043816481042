<script lang="ts">
import { computed, defineComponent } from "vue";
import { BcMenuItem as MenuItem } from "@bissantz/controls";
import HideAndShow from "@/common/components/hide-and-show.vue";
import { MenuNodeVm } from "./menu-node-vm";
import { MenuNodeStyle } from "./menu-node-style";
import MenuNodeSpinner from "./menu-node-spinner.vue";

export default defineComponent({
  name: "MenuNode",

  components: {
    MenuItem,
    HideAndShow,
    MenuNodeSpinner,
  },

  props: {
    model: { type: MenuNodeVm, required: false, default: null },
    styleSettings: {
      type: MenuNodeStyle,
      required: false,
      default: () => new MenuNodeStyle(),
    },
    nodeFontSizeInEmUnits: { type: Number, required: false, default: 1 },
  },

  setup(props) {
    async function toggle() {
      await props.model.toggleAsync();
    }

    const beginMenuGroup = computed(() => {
      return props.model.isRoot;
    });

    const nodeFontSizeInEmUnitsFormatted = computed(() => {
      return props.nodeFontSizeInEmUnits + "em";
    });

    const isClosedAndMightHaveChildren = computed(() => {
      return props.model.isOpen === false && props.model.mightHaveChildren === true;
    });

    const isSpinnerHidden = computed(() => {
      return props.model.isLoadingData === false;
    });

    const nameClasses = computed(() => {
      const classes = [];

      if (isClosedAndMightHaveChildren.value) {
        classes.push("closed-with-children");
      }

      return classes;
    });

    return {
      beginMenuGroup,
      nodeFontSizeInEmUnitsFormatted,
      nameClasses,
      isSpinnerHidden,
      toggle,
    };
  },
});
</script>
<template>
  <div class="menu-node">
    <MenuItem
      class="menu-entry"
      v-bind:data-testid="$props.model.id"
      v-bind:hideOnClose="false"
      v-on:click="toggle"
    >
      <template v-slot:title>
        <HideAndShow v-bind:class="nameClasses">
          <MenuNodeSpinner v-if="$props.model.isLoadingData" />
          <div v-if="isSpinnerHidden">
            {{ $props.model.name }}
          </div>
        </HideAndShow>
      </template>
      <template v-slot:component>
        <MenuNode
          v-for="child in $props.model.children"
          v-bind:model="child"
          v-bind:styleSettings="$props.styleSettings"
          v-bind:key="child.id"
        />
      </template>
    </MenuItem>
  </div>
</template>

<style lang="less" scoped>
@import "../../../../../common/styles/base-styles.less";

.menu-node {
  .menu-entry {
    padding-left: v-bind("$props.styleSettings.leftindentationInEmUnits");
    font-size: v-bind("nodeFontSizeInEmUnitsFormatted");
    font-family: @fontLight;
    cursor: pointer;
  }

  .closed-with-children {
    font-family: @fontSemi;
  }

  .data-loading-failed {
    color: var(--color_Weather-3);
  }

  .bissantzControls.menuItemControl::v-deep {
    .component {
      background-color: inherit;
    }
  }
}
</style>
