<script lang="ts">
import DeltaAppLogo from "./common/components/delta-app-logo.vue";
import { appResources } from "@/app-resources";
import { Bootstrapping } from "./bootstrapping";

import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  components: {
    DeltaAppLogo,
  },
  setup() {
    const statusText = appResources.aboutTexts.statusText;
    const version = computed(() => Bootstrapping.serviceVersion ?? "");

    let showInfo = ref(false);

    function toggleInfo() {
      if (screen.width < 900 || window.innerWidth < 900) {
        this.showInfo = false;
      } else {
        this.showInfo = !this.showInfo;
      }
    }

    return {
      statusText,
      showInfo,
      toggleInfo,
      version,
    };
  },
});
</script>

<template>
  <div class="appLabelComponent">
    <div class="appLabel" v-if="!showInfo" v-on:click="toggleInfo()">
      <DeltaAppLogo v-bind:fillColor="'#fff'" />
    </div>
    <div class="status" v-if="showInfo" v-on:click="toggleInfo()">
      {{ statusText.replace("{0}", version ?? " ") }}
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "./common/styles/media-queries.less";
@import "./common/styles/fonts.less";

.appLabelComponent {
  .appLabel {
    position: absolute;
    z-index: 1000;
    bottom: 0px;
    right: calc(1em - 40px);
    width: 10em;
    font-size: calc(0.5rem + ((1vw - 4.8px) * 0.5556));
    height: 30px;
    display: flex;
    align-items: flex-end;

    @media (min-width: 481px) {
      bottom: 0px;
      right: 0px;
      width: 10em;
      cursor: pointer;
    }

    max-width: 120px;
    max-height: 40px;
  }

  .status {
    position: relative;
    z-index: 10000;
    bottom: 3.75%;
    right: 3.75%;
    font-size: 12px;
    font-family: @fontLight;
    bottom: 0.5em;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: flex-end;
    color: #fff;

    @media (min-width: 900px) {
      cursor: pointer;
      bottom: 0px;
      right: 0px;
    }
  }
}
</style>
