import { isInRect } from "@/common/events/dom-event-helper";
import { computed } from "vue";
import { DashboardSelection } from "@/features/dashboard-shared/dashboard-selection";

export function useKpiSelector(dashboardSelection: DashboardSelection, kpiId: number) {
  //
  // Life Cycle:
  // --------------------

  //
  // Computeds:
  // --------------------
  const isKpiSelected = computed<boolean>(() => {
    return dashboardSelection.isKpiSelected(kpiId);
  });

  //
  // Functions:
  // --------------------
  function onPointerDown(ev: PointerEvent): void {
    if (!isInKpi(ev)) {
      return;
    }

    dashboardSelection.setSelectedKpi(kpiId);
  }

  function isInKpi(ev: PointerEvent): boolean {
    const rect = (ev.target as HTMLDivElement).getBoundingClientRect();

    if (rect.x === undefined && rect.y === undefined) {
      return true;
    }

    return isInRect(ev.x, ev.y, rect);
  }

  //
  // Watcher:
  // --------------------
  return {
    isKpiSelected,
    onPointerDown,
  };
}
