import { ILoggerAdapter, TransportType } from "./adapter/logger-adapter.interface";
import { LogLevel, LogLevelKeys } from "./log-entry.interface";
import { ILogger } from "./logger.interface";

export class Logger implements ILogger {
  // having no adapter mutes the logger, thus test do not need to mock anything
  private _loggerAdapter: ILoggerAdapter = null;
  private _options: {
    transportTypes: TransportType[];
    level: LogLevel;
  } = { transportTypes: ["console"], level: LogLevel.trace };

  constructor(loggerAdapter: ILoggerAdapter = null) {
    const isDebug = import.meta.env.DEV;
    const transportTypes: TransportType[] = isDebug
      ? ["console", "sessionStorage"]
      : ["sessionStorage"];
    const level: LogLevel = isDebug ? LogLevel.trace : LogLevel.info;
    this._options = { transportTypes: transportTypes, level };

    if (loggerAdapter) {
      this._loggerAdapter = loggerAdapter;
      this._loggerAdapter.initialize(this._options);
    }
  }

  async log(
    level: LogLevel | LogLevelKeys,
    message: string,
    data: object = null,
    error: Error = null
  ): Promise<void> {
    if (!this._loggerAdapter) return;

    // ensure level is of type LogLevel
    if (typeof level === "string") level = LogLevel[level];

    if (!(level >= this._options.level)) return;

    if (level === LogLevel.error && error) {
      message += `\nStack Trace:\n${error.stack}`;
    }

    this._loggerAdapter.log({
      level: level,
      message: message,
      data: data,

      timeStamp: Date.now(),
      language: navigator.language,
      file: "unknown file",
      line: -1,
      column: -1,
      appVersion: "unknown", //TODO

      systemId: "unknown", //TODO
      userId: "unknown", //TODO
      sessionId: "unknown", //TODO
      requestId: "unknown", //TODO
    });
  }
}
