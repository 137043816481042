<script lang="ts">
import ArrowIcon from "@/common/components/icons/arrow-icon.vue";

import { DefaultStyles } from "@/common/styles/default-styles";
import { PropType, computed, defineComponent } from "vue";
import { useHelpText } from "@/services/help-text-service/help-text.cpsl";

const colorConstants = DefaultStyles.colorConstants;

export default defineComponent({
  components: {
    ArrowIcon,
  },

  emits: ["update:modelValue", "input"],

  props: {
    canExtend: { type: Boolean, default: false },
    canShrink: { type: Boolean, default: false },
    helpTexts: {
      type: Object as PropType<{ shrink: string; expand: string }>,
      default: () => {
        return { shrink: null, expand: null };
      },
    },
    enabled: { type: Boolean, default: true },
    stepWidth: { type: Number, default: 2 },
    // v-model pattern:
    // defaults must be null to know which value is set (by v-model)
    modelValue: { type: Number, default: null }, // needed in vue3.
    value: { type: Number, default: null }, // needed in vue2
  },

  setup(props, context) {
    const helpTextCpsl = useHelpText();

    // Computeds:
    // -----------------
    const shrinkColor = computed<string>(() => {
      if (props.canExtend) {
        return DefaultStyles.colorConstants.headerTextHEX;
      }

      return DefaultStyles.colorConstants.darkTextHEX;
    });

    const extendColor = computed<string>(() => {
      return DefaultStyles.colorConstants.headerTextHEX;
    });

    const effValue = computed<number>(() => {
      if (props.value) {
        return props.value;
      } else {
        return props.modelValue;
      }
    });

    const showsBoth = computed<boolean>(() => {
      return props.canExtend && props.canShrink;
    });
    // Functions:
    // -----------------

    // v-model pattern:
    function emitUpdateValue(newValue: number): void {
      context.emit("update:modelValue", newValue); // needed in vue3.
      context.emit("input", newValue); //needed for vue2. (can be deleted in future)
    }

    // Event Handler Functions:
    // -------------------------
    function onExtendClick() {
      if (!props.enabled || !props.canExtend) {
        return;
      }

      emitUpdateValue(effValue.value + props.stepWidth);
    }

    function onShrinkClick() {
      if (!props.enabled || !props.canShrink) {
        return;
      }

      emitUpdateValue(effValue.value - props.stepWidth);
    }

    return {
      colorConstants,
      shrinkColor,
      extendColor,
      showsBoth,

      helpTextCpsl,

      // Event Handler
      onExtendClick,
      onShrinkClick,
    };
  },
});
</script>

<template>
  <div class="widthToggle" v-bind:class="{ 'shows-both': showsBoth }">
    <!-- S H R I N K     Button -->
    <ArrowIcon
      class="shrink"
      v-bind:data-helpText="$props.helpTexts.shrink"
      v-show="$props.canShrink"
      v-bind:initialColor="shrinkColor"
      v-bind:hoverColor="colorConstants.darkTextHEX"
      v-bind:rotationDegree="180"
      v-on:click.native="onShrinkClick"
    />

    <!-- E X T E N D     Button -->
    <ArrowIcon
      class="extend"
      v-bind:data-helpText="$props.helpTexts.expand"
      v-show="$props.canExtend"
      v-bind:initialColor="extendColor"
      v-bind:hoverColor="colorConstants.darkTextHEX"
      v-bind:rotationDegree="0"
      v-on:click.native="onExtendClick"
    />
  </div>
</template>

<style lang="less" scoped>
.widthToggle {
  height: 100%;
  width: 100%;
  display: inline-flex;
  justify-content: space-evenly;
  cursor: auto;

  &.shows-both {
    justify-content: space-between;
  }

  .arrowIcon {
    height: 80%;
    cursor: pointer;
  }
}
</style>
