import { getValueType, isAnyPercentageType } from "@/common/formatting/value-type";

export class ScalableValueVm {
  _fontSize: string = null;
  _oldFontSize: string = null;

  value: number = null;
  format: string = null;

  valueRaw: string = null;
  weatherColor: string = null;

  // TODO: the correct domain name would be 'showBrackets', but requires more refactoring
  excludedFromScaling: boolean = false;

  isPercentageModeFontActive = false;
  showSign = false;
  showFormatted = false;
  showItalic = false;
  alignCenter = false;
  colorizeBackground = false;
  displayValue: string = null;
  displayUnit: string = null;
  showColorAndScaling: boolean = false;

  get isAnyPercentageType(): boolean {
    const valueType = getValueType(this.format);
    return isAnyPercentageType(valueType);
  }

  set fontSize(newFontSize: string) {
    if (newFontSize === this._fontSize) return;

    this._oldFontSize = this._fontSize;
    this._fontSize = newFontSize;
  }

  get fontSize() {
    return this._fontSize;
  }

  get oldFontSize() {
    return this._oldFontSize;
  }
}
