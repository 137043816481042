import { inject, provide } from "vue";
import { AxiosInstance } from "axios";

import {
  ApplicationGroupsServiceClient,
  ApplicationVersionsServiceClient,
  ApplicationsServiceClient,
  IApplicationGroupsServiceClient,
  IApplicationVersionsServiceClient,
  IApplicationsServiceClient,
  IPortalsServiceClient,
  IPublishedApplicationsServiceClient,
  PortalsServiceClient,
  PublishedApplicationsServiceClient,
} from "@/common/service-clients/generated-clients";
import { PortalMenuFacade } from "./portal-menu-facade";
import { ApplicationFacade } from "../application-menu/backend-wrapper/application-facade";

export function useBackendDependencies() {
  const axiosInstance = inject("axiosInstance", null) as AxiosInstance;

  // list of used service clients:
  let portalClient: IPortalsServiceClient = null;
  let applicationsServiceClient: IApplicationsServiceClient = null;
  let applicationVersionsServiceClient: IApplicationVersionsServiceClient = null;
  let publishedApplicationsServiceClient: IPublishedApplicationsServiceClient = null;
  let applicationGroupsServiceClient: IApplicationGroupsServiceClient = null;

  instanciateUsedServiceClients(axiosInstance);

  // TODO:for now we just provide the ServiceClients
  // Create Facades:
  // ---------------
  let portalMenuFacade = inject<PortalMenuFacade>("portalMenuFacade", null);
  if (!portalMenuFacade) {
    portalMenuFacade = new PortalMenuFacade(portalClient);
    provide("portalMenuFacade", portalMenuFacade);
  }

  let applicationFacade = inject<ApplicationFacade>("applicationFacade", null);
  if (!applicationFacade) {
    applicationFacade = new ApplicationFacade(
      applicationsServiceClient,
      applicationVersionsServiceClient,
      publishedApplicationsServiceClient,
      applicationGroupsServiceClient
    );
    provide("applicationFacade", applicationFacade);
  }

  // Functions:
  // ---------------
  function instanciateUsedServiceClients(axiosInstance: AxiosInstance): void {
    portalClient = new PortalsServiceClient(null, axiosInstance);
    applicationsServiceClient = new ApplicationsServiceClient(null, axiosInstance);
    applicationVersionsServiceClient = new ApplicationVersionsServiceClient(
      null,
      axiosInstance
    );
    publishedApplicationsServiceClient = new PublishedApplicationsServiceClient(
      null,
      axiosInstance
    );
    applicationGroupsServiceClient = new ApplicationGroupsServiceClient(
      null,
      axiosInstance
    );
  }

  return {
    portalMenuFacade,
    applicationFacade,
  };
}
