<script lang="ts">
import CloneIcon from "@/common/components/icons/clone-icon.vue";
import WidthToggle from "../../helper-components/width-toggle.vue";
import MaximizeIcon from "../../helper-components/icons/maximize-icon.vue";

import { DefaultStyles } from "@/common/styles/default-styles";

import {
  computed,
  defineComponent,
  inject,
  PropType,
  reactive,
  Ref,
  ref,
  watch,
} from "vue";

import { ITileService } from "../tile-service.interface";
import { TilePageCommon } from "../tile-page-common";
import { UrlPageVm } from "./url-page-vm";
import { useHelpText } from "@/services/help-text-service/help-text.cpsl";

const colorConstants = DefaultStyles.colorConstants;

export default defineComponent({
  components: {
    CloneIcon,
    MaximizeIcon,
    WidthToggle,
  },

  emits: [TilePageCommon.tilePage_clone.name],

  props: {
    pageVm: { type: Object as PropType<UrlPageVm>, required: true },
    hasError: { type: Boolean, required: true },
  },

  setup: function (props, context) {
    const ref_componentElem = ref<HTMLDivElement>();

    const tileService = inject<Ref<ITileService>>("tileService");

    const helpTextCpsl = useHelpText();

    const state = reactive({
      stepWidth: 2,
    });

    //
    // Computeds:
    // ------------------
    const canShowButtons = computed<boolean>(() => {
      return props.pageVm.urlReportVm?.isInitialized && !props.hasError;
    });

    const canShowCloneButton = computed<boolean>(() => {
      const isInFullpageMode = tileService.value.isInFullpage;

      // Feature-Toggle like disabling of cloning
      // shall be re-enabled via PBI: 26647
      // eslint-disable-next-line sonarjs/no-redundant-boolean
      return tileService.value.isInEdit && !isInFullpageMode && false;
    });

    const isExtended = computed<boolean>(() => {
      return tileService.value.width > 2;
    });

    const canWidthToggle = computed<{ shrink: boolean; extend: boolean }>(() => {
      return {
        shrink: tileWidthModel.value > 2,
        extend:
          tileWidthModel.value < tileService.value.maxWidth &&
          !tileService.value.isInFullpage,
      };
    });

    const tileWidthModel = computed<number>({
      set: (value: number) => {
        let effWidth: number = null;
        if (value > tileService.value.maxWidth) {
          effWidth = tileService.value.maxWidth;
        } else {
          effWidth = value;
        }

        effWidth = Math.max(2, effWidth);

        if (effWidth === tileService.value.maxWidth && !tileService.value.isInFullpage) {
          tileService.value.toggleFullpage();
        } else {
          tileService.value.setWidth(effWidth);
        }
      },

      get: () => {
        return tileService.value.width;
      },
    });

    //
    // Methods:
    // ------------------
    function onCloneTile(): void {
      context.emit(TilePageCommon.tilePage_clone.name);
    }

    function onMaximize(): void {
      tileService.value.toggleFullpage();
    }

    async function onMaxWidthChanged() {
      if (tileService.value.width > tileService.value.maxWidth) {
        tileService.value.setWidth(tileService.value.maxWidth);
      }
    }

    // Watchers:
    // -----------------
    watch(() => tileService.value.maxWidth, onMaxWidthChanged);

    return {
      state,
      ref_componentElem,
      colorConstants,
      tileService,
      helpTextCpsl,

      // computeds:
      canShowButtons,
      canShowCloneButton,
      isExtended,
      tileWidthModel,
      canWidthToggle,

      // functions:
      onCloneTile,
      onMaximize,
    };
  },
});
</script>

<template>
  <div
    ref="ref_componentElem"
    class="url-page_action-bar"
    v-bind:class="{
      'is-extended-width': isExtended,
    }"
  >
    <!-- ACTION: Show KPIs -->
    <div class="side-action action"></div>

    <div class="action-group">
      <!-- Clone Tile  -->
      <div
        v-if="canShowCloneButton"
        v-on:click="onCloneTile"
        class="clone-button middle-action action"
        v-bind:class="{ 'disabled-icon': !canShowButtons }"
      >
        <CloneIcon
          v-bind:initialColor="colorConstants.headerTextHEX"
          v-bind:hoverColor="colorConstants.darkTextHEX"
        />
      </div>
    </div>

    <div class="action-group">
      <!-- Fullpage View -->
      <div
        class="middle-action action maximize"
        v-on:click="onMaximize"
        v-bind:data-helpText="helpTextCpsl.fullpageToggleText(false)"
      >
        <MaximizeIcon />
      </div>

      <!-- ACTION: Width Toggle -->
      <div
        v-if="canWidthToggle.extend || canWidthToggle.shrink"
        class="width-toggle-button side-action action"
        v-bind:class="{ 'disabled-icon': !canShowButtons }"
      >
        <WidthToggle
          v-bind:enabled="canShowButtons"
          v-bind:canExtend="canWidthToggle.extend"
          v-bind:canShrink="canWidthToggle.shrink"
          v-bind:stepWidth="2"
          v-bind:helpTexts="helpTextCpsl.tileWidthToggleDefaultText"
          v-model="tileWidthModel"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.url-page_action-bar {
  width: 100%;
  height: 28px;
  box-sizing: border-box;

  background-color: var(--color_bg_white);
  color: var(--color_neutralText);
  font-size: var(--hostedFontSize);

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid var(--color_bg-gray);

  &.is-extended-width {
    padding-left: 20px;
    padding-right: 16px;
  }

  // action entries:
  .action-group {
    height: 100%;
    align-self: center;
    display: flex;
  }

  .action {
    &.side-action {
      width: 25px;

      &:first-child {
        align-self: flex-start;
        margin-left: 7px;
      }
      &:last-child {
        align-self: flex-end;
        margin-right: 7px;
      }
    }
    &.middle-action {
      width: 22px;
      margin: 0 18px;
    }

    height: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: var(--color_headerText);
    cursor: pointer;

    &.disabled-icon {
      &.hidden-icon {
        opacity: 0;
      }

      opacity: 0.2;
      cursor: auto;

      & > div {
        cursor: auto;
      }
    }
  }

  .width-toggle-button.side-action.action {
    cursor: auto;
    width: 55px;
  }

  .maximize.action {
    height: 65%;
  }

  .arrowIcon {
    height: 80%;
  }

  .sparklineIcon {
    height: 60%;
  }

  .cloneIcon {
    height: 70%;
  }
}
</style>
