import { getValueType, isAnyPercentageType } from "@/common/formatting/value-type";
import { IColorInfo } from "@/common/formatting/color-info-interface";

export interface IMinMaxable {
  value?: number;
  kpiInfo: IColorInfo;
  format: string;
}
export class MinMaxInfo {
  min: number;
  max: number;

  constructor() {
    this.min = 0;
    this.max = 0;
  }
}

/**
 * Always returns min = 0, determines the max on Math.abs(value)
 */
export class MinMaxCalculator {
  static getMax(values: IMinMaxable[]): MinMaxInfo {
    const result = new MinMaxInfo();

    values.map((value) => MinMaxCalculator._updateMaxOnly(value, result));

    return result;
  }

  private static _updateMaxOnly(minMaxAble: IMinMaxable, minMaxInfo: MinMaxInfo): void {
    const valueType = getValueType(minMaxAble.format);
    const isPercent = isAnyPercentageType(valueType);
    let scaleFactor = minMaxAble.kpiInfo.scaleFactor ?? 1;
    if (isPercent) {
      scaleFactor = Math.sign(scaleFactor) * 1;
    }

    const absVal = Math.abs(minMaxAble.value / scaleFactor);
    minMaxInfo.max = Math.max(absVal, minMaxInfo.max ?? absVal);
  }
}
