import { SparkOrientation } from "../sparkline-common";

export class BarAnimation {
  private _durations: number[];
  private _heights: number[];
  private _ranges: number[];
  private _maxHeight: number;
  private _x: number;
  private _width: number;
  private _currentHeight: number;
  private _orientation: SparkOrientation;
  private _color: string;
  private _isAnimationDone: boolean;
  private _positiveMax: number;
  private _negativeMax: number;

  globalSparklinesEnabled: boolean = false;

  get orientation(): SparkOrientation {
    return this._orientation;
  }
  get color(): string {
    return this._color;
  }

  get x(): number {
    return this._x;
  }

  get y(): number {
    if (this.orientation === "positive") {
      return this._maxHeight - this.barHeight;
    } else if (this.orientation === "negative") {
      return 0;
    }

    if (!this.globalSparklinesEnabled) {
      const maxNegative =
        (this._maxHeight * this._negativeMax) / (this._positiveMax + this._negativeMax);
      return this._maxHeight - maxNegative - this.barHeight;
    }

    return this._maxHeight / 2 - this.barHeight;
  }

  get barWidth(): number {
    return this._width;
  }

  get barHeight(): number {
    const height = this._currentHeight ?? this._heights[this._heights.length - 1];

    if (this.orientation === "mixed" && !this.globalSparklinesEnabled) {
      return (this._maxHeight * height) / (this._positiveMax + this._negativeMax);
    }

    return height;
  }

  get isAnimationDone(): boolean {
    return this._isAnimationDone;
  }

  constructor(
    maxHeight: number,
    orientation: SparkOrientation,
    color: string,
    width: number,
    x: number,
    positiveMax: number,
    negativeMax: number
  ) {
    this._maxHeight = maxHeight;
    this._orientation = orientation;
    this._color = color;
    this._width = width;
    this._x = x;
    this._isAnimationDone = false;
    this._positiveMax = positiveMax;
    this._negativeMax = negativeMax;
  }

  init(durations_ms: number[], heights_px: number[]): void {
    if (heights_px.length < 2 || heights_px.length != durations_ms.length + 1) {
      throw new Error("Bar animation init has invalid data.");
    }
    this._durations = durations_ms;
    this._heights = heights_px;
    this._ranges = this._calculateRanges();
    this._isAnimationDone = false;
  }

  disableAnimation(): void {
    const currentHeight = this._heights[this._heights.length - 1];
    this._currentHeight = this._correctCurrentHeight(currentHeight);
    this._isAnimationDone = true;
  }

  update(elapsed_ms: number): void {
    const elapsedInfo = this._getElapsedInfo(elapsed_ms);
    const stepIndex = elapsedInfo.stepIndex;
    const range = this._ranges[stepIndex];
    let currentHeight: number;

    if (stepIndex === -1) {
      this.disableAnimation();
      return;
    }
    if (range === 0) {
      const to = this._heights[stepIndex + 1];
      currentHeight = to;
    } else {
      this._isAnimationDone = false;
      const from = this._heights[stepIndex];
      const to = this._heights[stepIndex + 1];
      const duration = this._durations[stepIndex];
      elapsed_ms -= elapsedInfo.duration;

      if (from > to) {
        currentHeight = from - (range * elapsed_ms) / duration;
      } else if (from < to) {
        currentHeight = from + (range * elapsed_ms) / duration;
      }
    }
    this._currentHeight = this._correctCurrentHeight(currentHeight);
  }

  private _correctCurrentHeight(height: number): number {
    if (this.orientation === "mixed") {
      return height / 2;
    }

    return Math.abs(height);
  }

  private _calculateRanges(): number[] {
    const ranges = [];
    for (let i = 0; i < this._heights.length - 1; i++) {
      const range = Math.abs(this._heights[i] - this._heights[i + 1]);
      ranges.push(range);
    }
    return ranges;
  }

  private _getElapsedInfo(elapsed_ms: number): { stepIndex: number; duration: number } {
    let currentDuration = 0;
    for (let i = 0; i < this._durations.length; i++) {
      const oldDuration = currentDuration;
      currentDuration += this._durations[i];
      if (elapsed_ms < currentDuration) {
        return { stepIndex: i, duration: oldDuration };
      }
    }
    return { stepIndex: -1, duration: currentDuration };
  }
}
