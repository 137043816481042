<script lang="ts">
import { reactive, defineComponent, onMounted, onBeforeUnmount } from "vue";
import { NavigationVm } from "../../../view-models/modelling/navigation-vm";
import { appResources } from "@/app-resources";
import TreeNode from "./tree-node.vue";
import { TreeNodeVm } from "./trees/tree-node-vm";
import { Listener } from "@/common/events/typed-event";
import { exists } from "@/common/object-helper/null-helper";

export default defineComponent({
  components: {
    TreeNode: TreeNode,
  },

  props: {
    navigationVm: { type: NavigationVm, required: true },
  },

  setup(props) {
    const state = reactive({
      appWizardTexts: appResources.applicationWizardTexts,
      isPeriodOpen: false,
      eventListeners: [],
    });

    onMounted(() => {
      listenToSelectinChangedEvent(
        props.navigationVm.periods,
        onPeriodElementSelectionChanged
      );

      listenToSelectinChangedEvent(
        props.navigationVm.dimensions,
        onDimensionLevelSelectionChanged
      );

      listenToSelectinChangedEvent(props.navigationVm.kpis, onKpiSelectionChanged);
    });

    onBeforeUnmount(() => {
      state.eventListeners.map((disp) => disp.dispose());
    });

    function listenToSelectinChangedEvent(
      treeItem: TreeNodeVm,
      listener: Listener<TreeNodeVm>
    ) {
      if (exists(treeItem.eventNotifier)) {
        const eventListener = treeItem.eventNotifier.nodeSelectionChanged.on(listener);
        state.eventListeners.push(eventListener);
      }
    }

    function onPeriodElementSelectionChanged(elementTreeItem: TreeNodeVm) {
      props.navigationVm.periods.togglePeriodElementSelection(elementTreeItem);
      props.navigationVm.togglePeriodElementSelection(elementTreeItem);
    }

    function onDimensionLevelSelectionChanged(levelTreeItem: TreeNodeVm) {
      props.navigationVm.toggleLevelSelection(levelTreeItem);
    }

    function onKpiSelectionChanged(kpiTreeItem: TreeNodeVm) {
      props.navigationVm.toggleKpiSelection(kpiTreeItem);
    }

    return {
      state,
    };
  },
});
</script>

<template>
  <div class="application-wizard_report-modelling-available-options report-wrapper">
    <!-- PERIOD -->
    <TreeNode v-bind:model="$props.navigationVm.periods" />

    <!-- Not Clickable Separator -->
    <div class="separator" />

    <!-- DIMENSIONS -->
    <TreeNode v-bind:model="$props.navigationVm.dimensions" />

    <!-- Not Clickable Separator -->
    <div class="separator" />

    <!-- MEASURES -->
    <TreeNode v-bind:model="$props.navigationVm.kpis" />
  </div>
</template>

<style lang="less" scoped>
@import "../../../../../common/styles/base-styles.less";
@import "./report-modelling.less";

.application-wizard_report-modelling-available-options {
  display: flex;
  flex: 0 0 31%;
  flex-direction: column;
  border-right: 1px solid var(--color_bg-gray);
}
</style>
