import { KpiValueFm } from "@/features/dashboard-shared/backend-wrapper/facade-models-dashboard-shared";
import {
  KpiDrillQueryFm,
  KpiDrillResultFm,
  KpiDrillRowFm,
  KpiDrillStructureFilterFm,
  SortTypeFm,
} from "@/features/dashboard/backend-wrapper/facade-models-dashboard";
import { SharedRowStateVm } from "./shared/shared-row-state-vm";
import { SharedDashboardStateVm } from "./shared/shared-dashboard-state-vm";
import { ElementVm } from "./element-vm";
import { SharedKpiInfo } from "./shared/shared-kpi-info";
import { SwiperVm } from "@/common/components/swiper-vm";
import { ElementBuilder } from "./builder/element-builder";
import { FontScaler } from "@/common/formatting/font-scaler";
import { StructureVm } from "./structure-vm";
import { SharedDrillInfoVm } from "./shared/shared-drill-info-vm";
import { DashboardCommon } from "@/features/dashboard/dashboard-common";
import { IDashboardFacade } from "@/features/dashboard/backend-wrapper/dashboard-facade.interface";
import { ValueVm } from "@/features/dashboard/view-models/value-vm";
import debounce from "lodash/debounce";

export type DrillRequestData = {
  publishedApplicationId: string;
  dashboardId: number;
  kpiId: number;
  kpiDrillQuery: KpiDrillQueryFm;
};

export class StructureElementsListVm {
  dashboardFacade: IDashboardFacade = null;
  elementBuilder = new ElementBuilder();

  structureVm: StructureVm = null;
  availableStructureVms: StructureVm[] = null;
  nextStructureVms: StructureVm[] = null;
  previousFilters: KpiDrillStructureFilterFm[] = [];

  elementVms: ElementVm[] = [];
  hasMoreRows: boolean = false;
  deltaValuesSwiperVm: SwiperVm = null;
  sparklinesGlobalMax: number = null;

  sharedDrillInfo: SharedDrillInfoVm = null;
  sharedKpiInfo: SharedKpiInfo = null;
  parentRowState: SharedRowStateVm = null;

  updating: boolean = false;
  drillResultLimit: number = null;
  numberElementsInDrill: number = -1;
  drillDepth: number = null;

  fontScaler = new FontScaler(
    DashboardCommon.elementConfig.minScaledEm,
    DashboardCommon.elementConfig.maxScaledEm +
      DashboardCommon.elementConfig.scalingStepEm,
    DashboardCommon.elementConfig.scalingStepEm
  );

  private _scaleTimeoutId: NodeJS.Timeout = null;
  private _drillLimit: number = 50;

  scaleValuesForDrill = debounce(
    (sharedDashboardStateVm: SharedDashboardStateVm) =>
      this._scaleValuesForDrill(sharedDashboardStateVm),
    50
  );

  get isInitialized(): boolean {
    return this.elementVms && this.elementVms.length > 0;
  }

  get isPercentageMode(): boolean[] {
    if (this.elementVms.length > 0) {
      return this.elementVms[0].parentRowState.isPercentageModeActive;
    }

    return [];
  }

  init(drillDepth: number): void {
    this.drillDepth = drillDepth;
    this.numberElementsInDrill = -1;
  }

  async updateElementsAsync(
    extendRows: boolean = false,
    kpiScaleIndex: number,
    deltaValuesActiveIndex: number,
    sortType: SortTypeFm,
    sortedValueId: number
  ): Promise<void> {
    this.updating = true;
    this._initDelaValuesSwiperVm(deltaValuesActiveIndex);

    if (!this.isInitialized || extendRows) {
      await this._loadNewElementVmsAsync(
        extendRows,
        this.structureVm.id,
        sortedValueId,
        sortType
      );
    }

    this.updateSparklinesGlobalMax(kpiScaleIndex);
    this.updating = false;
  }

  async onSortingChanged(
    kpiScaleIndex: number,
    sortType: SortTypeFm,
    sortedValueId: number
  ): Promise<void> {
    this.updating = true;
    await this._loadNewElementVmsAsync(
      false,
      this.structureVm.id,
      sortedValueId,
      sortType
    );

    this.updateSparklinesGlobalMax(kpiScaleIndex);
    this.updating = false;
  }

  async updateNumberElementsInDrillAsync(sortType: SortTypeFm): Promise<void> {
    let numberElementsInDrill = 0;
    const request = this._createDrillAllRequest(this.structureVm.id, sortType);
    const kpiDrillResultFm = await this._drillDashboardKpiAsync(request);
    if (kpiDrillResultFm) {
      numberElementsInDrill = kpiDrillResultFm.rows.length;
    }
    this.numberElementsInDrill = numberElementsInDrill;
  }

  _scaleValuesForDrill(sharedDashboardStateVm: SharedDashboardStateVm): void {
    if (this.updating) {
      return;
    }

    const scaledValues: ValueVm[] = [];
    this.elementVms.forEach((e) => {
      const scaledValue = e.getScaledElementValue(sharedDashboardStateVm);
      if (scaledValue) {
        scaledValues.push(scaledValue);
      }

      e.elementValues.forEach((ev) =>
        ev.updateActiveValue(sharedDashboardStateVm.sparklineState)
      );
    });
    this.fontScaler.scaleValues(scaledValues);
  }

  updateSparklinesGlobalMax(kpiScaleIndex: number): void {
    let sparklinesGlobalMax: number = null;
    const backingFms = this._getKpiValueFmsWithSparklines(kpiScaleIndex);
    if (backingFms.length > 0) {
      sparklinesGlobalMax = Math.max(
        ...backingFms.map((fm) =>
          Math.max(
            ...fm.historicData.map((h) =>
              Math.abs(h.value / this.sharedKpiInfo.scaleFactor)
            )
          )
        )
      );
    }

    this.sparklinesGlobalMax = sparklinesGlobalMax;
  }

  resetPercentageModes(): void {
    this.elementVms.map((elementVm) => {
      const percentageModes = elementVm.parentRowState.isPercentageModeActive;
      for (let i = 0; i < percentageModes.length; i++) {
        percentageModes[i] = false;
      }
    });
  }

  private _getKpiValueFmsWithSparklines(kpiScaleIndex: number): KpiValueFm[] {
    const scaleIndex = Math.min(Math.max(kpiScaleIndex, 0), 2);
    return this.elementVms
      .filter((evm) => evm.elementValues[scaleIndex].hasSparkline)
      .map((evm) => evm.elementValues[scaleIndex].backingFm);
  }

  private _updateDrillResultLimit(extendRows: boolean): void {
    if (extendRows) {
      this.drillResultLimit += this._drillLimit;
    } else {
      this.drillResultLimit = this.drillResultLimit ?? this._drillLimit;
    }
  }

  private async _loadNewElementVmsAsync(
    extendRows: boolean = false,
    structureVmId: number,
    valueId: number,
    sortType: SortTypeFm
  ): Promise<void> {
    const request = this._createDrillRequest(
      structureVmId,
      extendRows,
      valueId,
      sortType
    );
    const kpiDrillResultFm = await this._drillDashboardKpiAsync(request);
    this._updateElements(kpiDrillResultFm);
  }

  private _createDrillRequest(
    structureId: number,
    extendRows: boolean,
    valueId: number,
    sortType: SortTypeFm
  ): DrillRequestData {
    this._updateDrillResultLimit(extendRows);
    const query = this.sharedDrillInfo.createDrillQuery(
      structureId,
      sortType,
      this.previousFilters,
      extendRows,
      valueId,
      this.drillResultLimit
    );

    return {
      publishedApplicationId: this.sharedDrillInfo.publishedApplicationId,
      dashboardId: this.sharedDrillInfo.dashboardId,
      kpiId: this.sharedDrillInfo.kpiId,
      kpiDrillQuery: query,
    };
  }

  private _createDrillAllRequest(
    structureId: number,
    sortType: SortTypeFm
  ): DrillRequestData {
    const extendRows = false;
    const drillResultLimit = 0; // Zero returns all elements
    const valueId = 0;

    const query = this.sharedDrillInfo.createDrillQuery(
      structureId,
      sortType,
      this.previousFilters,
      extendRows,
      valueId,
      drillResultLimit
    );

    return {
      publishedApplicationId: this.sharedDrillInfo.publishedApplicationId,
      dashboardId: this.sharedDrillInfo.dashboardId,
      kpiId: this.sharedDrillInfo.kpiId,
      kpiDrillQuery: query,
    };
  }

  private async _drillDashboardKpiAsync(
    request: DrillRequestData
  ): Promise<KpiDrillResultFm> {
    const result = await this.dashboardFacade.drillDashboardKpiAsync(
      request.publishedApplicationId,
      request.dashboardId,
      request.kpiId,
      request.kpiDrillQuery
    );

    if (result.error) {
      return null;
    }

    return result.value;
  }

  private _updateElements(kpiDrillResultFm: KpiDrillResultFm): void {
    let rows: KpiDrillRowFm[] = [];
    let hasMoreRows: boolean = false;

    if (kpiDrillResultFm) {
      rows = kpiDrillResultFm.rows;
      hasMoreRows = kpiDrillResultFm.hasMoreRows;
    }

    const elemVms = this.elementBuilder.createElements(
      rows,
      this.sharedKpiInfo,
      this.sharedDrillInfo,
      this.parentRowState,
      this.structureVm.nameId,
      this.nextStructureVms,
      this.previousFilters,
      this.dashboardFacade
    );

    this.hasMoreRows = hasMoreRows;
    this.elementVms = elemVms;
  }

  private _initDelaValuesSwiperVm(deltaValuesActiveIndex: number): void {
    if (!this.deltaValuesSwiperVm) {
      const numberOfItemsToDisplay = 1;
      this.deltaValuesSwiperVm = new SwiperVm(
        numberOfItemsToDisplay,
        deltaValuesActiveIndex
      );
    }
  }
}
