import { FailedReason } from "./failed-reason";

export class ErrorFailedReason extends FailedReason {
  name: string;
  message: string;
  constructor(name: string, message: string) {
    super("ErrorFailedReason:" + message);

    this.name = name;
    this.message = message;
  }
}
