import { exists } from "../../../common/object-helper/null-helper";

export class UniqueNameGenerator {
  static async generateUniqueNameAsync<T>(
    startName: string,
    retrieveExistentObjectsAsync: () => Promise<T[]>,
    retrieveNameFromObject: (instance: T) => string
  ): Promise<string> {
    let existentObjects: T[] = [];

    try {
      existentObjects = await retrieveExistentObjectsAsync();
    } catch (e) {
      existentObjects = [];
    }

    const existentNames = existentObjects.map((instance) =>
      retrieveNameFromObject(instance)
    );

    return UniqueNameGenerator.generateUniqueName(startName, existentNames);
  }

  static generateUniqueName(startName: string, existentNames: string[]): string {
    if (!exists(existentNames)) {
      return startName;
    }

    let uniqueName = startName;
    let counter: number = 1;

    while (existentNames.includes(uniqueName)) {
      uniqueName = startName + " " + counter++;
    }

    return uniqueName;
  }
}
