import { Dimension } from "../../../../backend-wrapper/dto-wrappers";
import { DimensionRetriever } from "./dimension-retriever";
import { ITreeNodeLoader } from "../trees/tree-node-loader.interface";
import { TreeNodeVm } from "../trees/tree-node-vm";
import { FailedReason } from "@/common/results/failed-reason";
import { ValueResult } from "@/common/results/value-result";

export class HierarchyNodeLoader implements ITreeNodeLoader {
  private readonly _dimensionRetriever: DimensionRetriever;
  private readonly _dimension: Dimension;

  constructor(dimensionRetriever: DimensionRetriever, dimension: Dimension) {
    this._dimensionRetriever = dimensionRetriever;
    this._dimension = dimension;
  }

  async loadAsync(_id: string): Promise<ValueResult<TreeNodeVm[], FailedReason>> {
    const dimensionLoadResult = await this._dimensionRetriever.loadHierarchieLevelsAsync(
      this._dimension
    );

    if (dimensionLoadResult.succeeded === false) {
      return ValueResult.createFromError(dimensionLoadResult.failedReason);
    }

    const result = [];

    for (const level of dimensionLoadResult.value.allHierarchyLevels) {
      const treeItem = new TreeNodeVm(level.id, level.name);
      treeItem.value = level;

      result.push(treeItem);
    }

    return ValueResult.createFromValue(result);
  }
}
