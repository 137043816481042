<script lang="ts">
import HideAndShow from "@/common/components/hide-and-show.vue";

import { getValueType } from "@/common/formatting/value-type";
import { DefaultStyles } from "@/common/styles/default-styles";
import { ScalableValueVm } from "./scalable-value-vm";
import { ClickHelper } from "@/common/events/click-helper";
import { TouchHelper } from "@/common/events/touch-helper";
import { HideAndShowHelper } from "@/common/components/hide-and-show-helper";
import { FontConfig, ScalableValueCommon } from "./scalable-value-common";
import { defineComponent, reactive, computed, ref, PropType } from "vue";

const incrementDurationInSeconds = 0.25;

export default defineComponent({
  components: {
    HideAndShow,
  },

  emits: [
    ScalableValueCommon.kpiValue_valueLeftClicked,
    ScalableValueCommon.kpiValue_valueRightClicked,
  ],

  props: {
    valueVm: { type: Object as PropType<ScalableValueVm>, required: true },
    fontConfig: { type: Object as PropType<FontConfig>, required: true },
    isPercentageMode: { type: Boolean, default: false },
    disableBrackets: { type: Boolean, default: false },
    defaultTextColor: { type: String, default: null },
  },

  setup: function (props, context) {
    const ref_scalableValueCell = ref(null as HTMLDivElement);

    const state = reactive({
      isPercentageModeFontActive: false,
      incrementsDuration: null as number,
      _isKpiValue: false,
      _clickHelper: null as ClickHelper,
      _touchHelper: null as TouchHelper,
      _emptyAreaClickHelper: null as ClickHelper,
      _emptyAreaTouchHelper: null as TouchHelper,
    });

    //---vue life cycle hooks
    function init() {
      state._emptyAreaClickHelper = new ClickHelper();
      state._emptyAreaTouchHelper = new TouchHelper();
      state._clickHelper = new ClickHelper();
      state._touchHelper = new TouchHelper();
      state._emptyAreaClickHelper.touchHelper = state._emptyAreaTouchHelper;
      state._clickHelper.touchHelper = state._touchHelper;

      state._clickHelper.setOnLeftClickAction(onKpiValueLeftClicked);
      state._clickHelper.setOnRightClickAction(onKpiValueRightClicked);
      state._touchHelper.setLongTouchAction(onKpiValueRightClicked);

      state._emptyAreaClickHelper.setOnLeftClickAction(onKpiValueLeftClicked);
      state._emptyAreaClickHelper.setOnRightClickAction(onKpiValueRightClicked);
      state._emptyAreaTouchHelper.setTapAction(onKpiValueLeftClicked);
      state._emptyAreaTouchHelper.setLongTouchAction(onKpiValueRightClicked);

      state.incrementsDuration = props.fontConfig.getIncrementsDuration(
        incrementDurationInSeconds
      );
    }

    init();
    //---end vue life cycle hooks

    const defaultTextColorStyle = computed(() => {
      const defaultColor = props.defaultTextColor ?? "var(--color_bg_white)";
      return {
        "--defaultTextColor": defaultColor,
      };
    });

    const showBrackets = computed(() => {
      if (!props.disableBrackets) return props.valueVm.excludedFromScaling;

      return false;
    });

    const hoverText = computed(() => {
      const _neededForReactivity = props.valueVm.displayValue;
      let unit = "";
      if (props.valueVm.isAnyPercentageType) unit = props.valueVm.displayUnit;

      const internalHoverText = (props.valueVm.valueRaw + " " + unit).trim();
      const displayedText = (
        props.valueVm.displayValue +
        " " +
        props.valueVm.displayUnit
      ).trim();

      if (internalHoverText === displayedText) return "";
      return internalHoverText;
    });

    const textColorStyle = computed(() => {
      if (props.valueVm.colorizeBackground) return {};

      if (props.valueVm.showColorAndScaling) {
        return { color: props.valueVm.weatherColor };
      }

      return { color: DefaultStyles.colorConstants.neutralTextHEX };
    });

    const dynamicSizeStyle = computed(() => {
      let fontSizeNew = props.fontConfig.defaultFontSize;
      if (!props.valueVm.showColorAndScaling) {
        fontSizeNew = props.fontConfig.minScaledEm;
        return {
          "font-size": `${fontSizeNew}em`,
        };
      }

      if (useMediumFont.value) {
        return null;
      }

      let duration = "0";
      if (props.valueVm.fontSize) {
        fontSizeNew = parseFloat(props.valueVm.fontSize.replace("em", ""));
        const fontSizeOld = props.valueVm.oldFontSize
          ? parseInt(props.valueVm.oldFontSize.replace("em", ""))
          : 0;
        duration = fontSizeNew > fontSizeOld ? _calcAnimationDuration(fontSizeNew) : "0";
      }

      return {
        "font-size": `${fontSizeNew}em`,
        transition: `font-size ${duration}s`,
      };
    });

    const useMediumFont = computed(() => {
      const valueType = getValueType(props.valueVm.format);
      return valueType === "timeSpan";
    });

    const maxFontSizeStyle = computed(() => {
      let max = props.fontConfig.maxScaledEm;

      return { fontSize: `${max}em` };
    });

    function setIsKpiValue(): void {
      state._isKpiValue = true;
    }

    function onMouseDown(ev: MouseEvent): void {
      setIsKpiValue();
      state._clickHelper.mouseDown(ev);
    }

    function onMouseUp(ev: MouseEvent): void {
      state._clickHelper.mouseUp(ev);
    }

    function onTouchStart(ev: TouchEvent): void {
      setIsKpiValue();
      const isScrollable = HideAndShowHelper.isScrollable(ref_scalableValueCell.value);

      if (isScrollable) {
        state._touchHelper.setTapAction(null);
        state._touchHelper.setDoubleTapAction(onKpiValueLeftClicked);
      } else {
        state._touchHelper.setDoubleTapAction(null);
        state._touchHelper.setTapAction(onKpiValueLeftClicked);
      }

      state._touchHelper.touchStart(ev);
    }

    function onTouchMove(): void {
      state._touchHelper.cancelTouch();
    }

    function onTouchEnd(ev: TouchEvent): void {
      state._touchHelper.touchEnd(ev);
    }

    function onKpiValueLeftClicked(): void {
      context.emit(ScalableValueCommon.kpiValue_valueLeftClicked);
    }

    function onKpiValueRightClicked(): void {
      context.emit(ScalableValueCommon.kpiValue_valueRightClicked);
    }

    function onEmptyAreaMouseDown(ev: MouseEvent): void {
      if (state._isKpiValue) {
        state._isKpiValue = false;
        return;
      }
      state._emptyAreaClickHelper.mouseDown(ev);
    }

    function onEmptyAreaMouseUp(ev: MouseEvent): void {
      state._emptyAreaClickHelper.mouseUp(ev);
    }

    function onEmptyAreaTouchStart(ev: TouchEvent): void {
      if (state._isKpiValue) {
        state._isKpiValue = false;
        return;
      }
      state._emptyAreaTouchHelper.touchStart(ev);
    }

    function onEmptyAreaTouchMove(): void {
      state._emptyAreaTouchHelper.cancelTouch();
    }

    function onEmptyAreaTouchEnd(ev: TouchEvent): void {
      state._emptyAreaTouchHelper.touchEnd(ev);
    }

    function _calcAnimationDuration(fontSizeNew: number) {
      const increments =
        Math.abs(fontSizeNew - props.fontConfig.defaultFontSize) /
        props.fontConfig.scalingStepEm;
      return (increments * state.incrementsDuration).toFixed(4);
    }

    return {
      state,
      ref_scalableValueCell,

      // computeds:
      defaultTextColorStyle,
      textColorStyle,
      hoverText,
      maxFontSizeStyle,
      showBrackets,
      dynamicSizeStyle,
      useMediumFont,

      // Functions / EventHandler
      onEmptyAreaMouseDown,
      onEmptyAreaMouseUp,
      onEmptyAreaTouchStart,
      onEmptyAreaTouchMove,
      onEmptyAreaTouchEnd,
      onMouseDown,
      onMouseUp,
      onTouchStart,
      onTouchMove,
      onTouchEnd,
    };
  },
});
</script>

<template>
  <div
    class="common-scalable-value horizontal padding-right"
    ref="ref_scalableValueCell"
    v-bind:class="{
      'align-center': $props.valueVm.alignCenter,
      'show-italic': $props.valueVm.showItalic,
    }"
    v-bind:style="defaultTextColorStyle"
  >
    <span class="max-size-setter" v-bind:style="maxFontSizeStyle">|</span>
    <div
      class="formatted-value"
      v-bind:style="textColorStyle"
      v-bind:title="hoverText"
      v-on:mousedown="onEmptyAreaMouseDown"
      v-on:mouseup="onEmptyAreaMouseUp"
      v-on:touchstart="onEmptyAreaTouchStart"
      v-on:touchmove="onEmptyAreaTouchMove"
      v-on:touchend="onEmptyAreaTouchEnd"
    >
      <HideAndShow
        v-on:mousedown.native="onMouseDown"
        v-on:mouseup.native="onMouseUp"
        v-on:touchstart.native="onTouchStart"
        v-on:touchmove.native="onTouchMove"
        v-on:touchend.native="onTouchEnd"
      >
        <span v-if="showBrackets">[</span>
        <span
          v-if="!$props.valueVm.alignCenter"
          class="max-size-setter"
          v-bind:style="maxFontSizeStyle"
          >|</span
        >
        <span
          ref="value"
          class="value"
          v-bind:class="{ 'medium-font': useMediumFont }"
          v-bind:style="dynamicSizeStyle"
          >{{ $props.valueVm.displayValue }}</span
        >
        <span class="unit" v-if="$props.valueVm.displayUnit || isPercentageMode"
          >&nbsp;{{ $props.valueVm.displayUnit }}</span
        >
        <span v-if="showBrackets">]</span>
        <span v-if="state.isPercentageModeFontActive">&nbsp;&nbsp;</span>
      </HideAndShow>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "../../../common/styles/fonts.less";

.common-scalable-value {
  white-space: nowrap;
  color: var(--color_neutralText);

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  max-width: 100%;

  &.align-center {
    align-items: center;
  }

  .formatted-value {
    white-space: nowrap;
    color: var(--defaultTextColor);
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
    width: 100%;

    .value {
      color: inherit;
      transition: font-size 0.25s;
      font-size: inherit;

      &.medium-font {
        font-family: @fontSemi;
      }
    }

    .unit {
      color: inherit;
    }
  }

  .show-italic {
    font-style: italic;
  }
}

.max-size-setter {
  // needed for safari:
  // spans without content are not rendered in safari
  color: transparent;
  display: inline-block;
  width: 0;
}

.horizontal > div {
  display: inline-block;
}

.padding-right {
  padding-right: 10px;
}
</style>
