import { IContextMenuService } from "@/common/components/context-menu/context-menu-service.interface";
import { ContextMenuItem } from "@/common/components/context-menu/context-menu-item";

export class ContextMenuService implements IContextMenuService {
  isMenuOpen: boolean = false;
  posX: number = 0;
  posY: number = 0;
  menuItems: ContextMenuItem[] = [];
  topIcons: ContextMenuItem[] = [];

  onShowContextMenu(
    posX: number,
    posY: number,
    menuItems: ContextMenuItem[],
    topIcons: ContextMenuItem[] = []
  ): void {
    if (menuItems.length === 0 && topIcons.length === 0) {
      return;
    }

    this.isMenuOpen = true;
    this.posX = posX;
    this.posY = posY;
    this.menuItems = menuItems;
    this.topIcons = topIcons;
  }

  onHideContextMenu(): void {
    this.isMenuOpen = false;
    this.posX = 0;
    this.posY = 0;
    this.menuItems = [];
  }
}
