import { IApplicationFacade } from "../backend-wrapper/application-facade.interface";
import { MenuNodeVm } from "../helper-components/menus/menu-node-vm";
import { ApplicationGroupLoader } from "./menu-loaders/application-group-loader";

export class ApplicationMenuVm {
  private readonly _rootNode: MenuNodeVm = null;

  constructor(applicationFacade: IApplicationFacade, rootNodeName: string) {
    this._rootNode = new MenuNodeVm(
      "applicationsId",
      rootNodeName,
      new ApplicationGroupLoader(applicationFacade, "applicationsId")
    );
  }

  get rootNode(): MenuNodeVm {
    return this._rootNode;
  }

  set rootNodeName(value: string) {
    this._rootNode.name = value;
  }
}
