﻿// taken with minor changes from DM6 WebClient

import { ValueFormatResources } from "./value-format-resources.interface";
import { NumberFormatMode } from "./number-format-mode";
import { getValueType, ValueType, isAnyPercentageType } from "./value-type";
import { BissantzTimeSpan } from "./bassantz-time-span";
import { roundToDecimal } from "./rounding";
import toInteger from "lodash/toInteger";

export type CustomFormat = "#.##" | "#.#" | "###" | "#" | "%.00" | "%.0";
const AllCustomFormatsList: CustomFormat[] = ["#.##", "#.#", "###", "#", "%.00", "%.0"];

type NumFmtDictionary = { [key: string]: Intl.NumberFormat };
const formatDictionaries: { [locale: string]: NumFmtDictionary } = {};
export class ValueFormatter {
  private _valueFormatResources: ValueFormatResources;
  private _culture: string;
  private _maxNumCharacters = 5;

  setCulture(valueFormatResources: ValueFormatResources, culture: string) {
    if (culture === this._culture) return;

    this._valueFormatResources = valueFormatResources;
    this._culture = culture;
  }

  getCustomFormat(rawFormat: string): CustomFormat {
    let result = null;
    for (let idx = 0; idx < AllCustomFormatsList.length; idx++) {
      const customFormat = AllCustomFormatsList[idx];
      if (rawFormat.substring(0, customFormat.length) === customFormat) {
        result = customFormat;
        break;
      }
    }

    return result;
  }

  getUnit(
    rawFormat: string,
    valueType: ValueType,
    customFormat: CustomFormat = null
  ): string {
    let result: string = null;
    const formatWithoutCustom = customFormat
      ? rawFormat.substring(customFormat.length).trim()
      : rawFormat;

    switch (valueType) {
      case "absolute":
        result = formatWithoutCustom ? formatWithoutCustom : "";
        break;
      case "percent":
        result = customFormat && customFormat.includes("%") ? "" : "%";
        break;
      case "percentagePoint":
        result = this._valueFormatResources.percentagePoint;
        break;
      case "timeSpan":
        result = "";
        break;
      default:
        throw new Error(
          "Unknown value type for getting value unit. Format string was: " + rawFormat
        );
    }

    return result;
  }

  formatValue(
    value: number,
    format: string,
    showSign: boolean,
    numberFormat = NumberFormatMode.CompactWithWords
  ): string[] {
    format = format ?? "";
    // derive init data
    const valueType = getValueType(format);
    const customFormat = this.getCustomFormat(format);
    let unitResult = this.getUnit(format, valueType, customFormat);
    let valueResult = "";
    const isAnyPercentType = isAnyPercentageType(valueType);

    // guards
    if (value === undefined || value === null) {
      return [this._valueFormatResources.notAvailable, unitResult];
    }

    if (!valueType) {
      Error(
        `valueType must be defined for value formatting. Given ValueType was: '${valueType}'`
      );
    }

    if (isAnyPercentType) value *= 100;

    // format according to input:
    if (customFormat) {
      valueResult = this._formatWithCustomFormat(value, customFormat);
      if (value === 0 && valueResult === "") showSign = false;
    } else if (valueType === "timeSpan") {
      valueResult = this._formatTimeSpan(value, format);
    } else if (numberFormat === NumberFormatMode.CompactWithWords) {
      value = isAnyPercentType ? value / 100 : value;
      const compactResult = this._formatCompactNumberWithWords(value, valueType);
      valueResult = compactResult[0];
      unitResult = (compactResult[1] + " " + unitResult).trim();
    } else if (numberFormat === NumberFormatMode.Detailed) {
      valueResult = this._formatDetailed(value, isAnyPercentType);
    } else if (numberFormat === NumberFormatMode.WithDecimal) {
      valueResult = this._formatWithDecimal(value);
    } else if (numberFormat === NumberFormatMode.WithoutDecimal) {
      valueResult = this._formatWithoutDecimal(value);
    } else {
      // .net, date, MDX format: can not be handled
      valueResult = value.toLocaleString();
    }

    // add '+' sign if needed
    if (
      showSign &&
      value >= 0 &&
      !(format.length >= 4 && format.substr(0, 4) == "date") &&
      !valueResult.includes("+")
    ) {
      valueResult = "+" + valueResult;
    }

    return [valueResult, unitResult];
  }

  private _formatDetailed(value: number, isPercent: boolean): string {
    const absValue = Math.abs(value);
    const len = Math.trunc(Math.log10(absValue));

    if (len < -2) return "0";

    let detailedValue: string;
    let roundedValue: number;
    let numberFmt: Intl.NumberFormat;

    if (len >= 3) {
      roundedValue = roundToDecimal(absValue, 0);
      numberFmt = this.getNumberFormat("n0");
      detailedValue = numberFmt.format(roundedValue);
    } else if (isPercent) {
      roundedValue = roundToDecimal(absValue, 3 - len);
      numberFmt = this.getNumberFormat("n1");
      detailedValue = numberFmt.format(roundedValue);
    } else {
      roundedValue = roundToDecimal(absValue, 2);
      numberFmt = this.getNumberFormat("n2");
      detailedValue = numberFmt.format(roundedValue);
      detailedValue = this._removeTrailingZeros(detailedValue);
    }

    if (value < 0) {
      detailedValue = "-" + detailedValue;
    }

    return detailedValue;
  }

  private _formatWithDecimal(value: number): string {
    const absValue = Math.abs(value);
    const length = Math.log10(roundToDecimal(absValue, 0));

    if (length > 0) {
      return this._formatWithoutDecimal(value);
    } else {
      const formatedValue = this._format(value, "n2", 2);
      if (formatedValue.endsWith("00")) {
        return formatedValue.slice(0, formatedValue.length - 3);
      }
      if (formatedValue.endsWith("0")) {
        return formatedValue.slice(0, formatedValue.length - 1);
      }
      return formatedValue;
    }
  }

  private _formatWithoutDecimal(value: number): string {
    return this._format(value, "n0");
  }

  private _format(value: number, key: string, decimals: number = 0): string {
    const isNegative = value < 0;
    const roundedValue = roundToDecimal(Math.abs(value), decimals);
    const numberFmt = this.getNumberFormat(key);
    let detailedValue = numberFmt.format(roundedValue);
    if (isNegative && roundedValue != 0) {
      detailedValue = "-" + detailedValue;
    }
    return detailedValue;
  }

  private _formatWithCustomFormat(value: number, customFormat: CustomFormat): string {
    let result = "";

    let numberFmt = "";
    let prefix = "";
    let rmTrailing0 = false;

    switch (customFormat) {
      case "#.##":
        numberFmt = "n2";
        rmTrailing0 = true;
        break;
      case "#.#":
        numberFmt = "n1";
        rmTrailing0 = true;
        break;
      case "###":
        numberFmt = "n0";
        break;
      case "#":
        numberFmt = "n0";
        break;
      case "%.00":
        numberFmt = "n2";
        prefix = "%";
        break;
      case "%.0":
        numberFmt = "n1";
        prefix = "%";
        break;
    }

    if (value === 0 && prefix !== "%") {
      return "";
    }

    result = this.getNumberFormat(numberFmt).format(value);
    const percentWrongStart = "0" + this.getCurrentDecimalSeparator();
    if (result.startsWith(percentWrongStart)) {
      result = result.substring(1);
    }

    if (prefix) result = `${value < 0 ? "-" : ""}${prefix}${result.replace(/^-/, "")}`;

    if (rmTrailing0) result = this._removeTrailingZeros(result);

    return result;
  }

  private _formatCompactNumberWithWords(value: number, valueType: ValueType): string[] {
    try {
      value = valueType === "absolute" ? value : value * 100;
      const length = this._getValueLength(value);
      const format = this._getFormat(length);
      const numFormat = this.getNumberFormat("n0");
      const sign = Math.sign(value) >= 0 ? "" : "-";
      const compactValue = this._getCompactValue(length, value, valueType);
      const compactValueNoDecimals = roundToDecimal(compactValue.asNumber);
      const compactValueAbsNoDecimals = Math.abs(compactValueNoDecimals);
      compactValue.asString = sign + compactValue.asString;

      if (
        numFormat.format(compactValueAbsNoDecimals).length <= 3 ||
        format === this._valueFormatResources.wordBillion ||
        format === this._valueFormatResources.wordTrillion
      ) {
        return [compactValue.asString, format];
      }

      if (numFormat.format(compactValueNoDecimals).length > length) {
        value = isAnyPercentageType(valueType)
          ? compactValue.asNumber / 100
          : compactValue.asNumber;
      } else if (format === this._valueFormatResources.wordThousand) {
        value = isAnyPercentageType(valueType)
          ? compactValue.asNumber * 10
          : compactValue.asNumber * 1000;
      } else if (isAnyPercentageType(valueType)) {
        value = compactValue.asNumber * 10000;
      } else {
        value = compactValue.asNumber * 1000000;
      }

      return this._formatCompactNumberWithWords(value, valueType);
    } catch (ex) {
      return [value.toString(), null];
    }
  }

  private _getValueLength(value: number): number {
    const result = Math.log10(Math.abs(value));

    if (!Number.isFinite(result)) {
      return result;
    }

    if (result < 0) {
      return toInteger(result);
    }

    return Math.floor(result);
  }

  private _getFormat(length: number): string {
    if (length >= 12) return this._valueFormatResources.wordTrillion;
    if (length >= 9) return this._valueFormatResources.wordBillion;
    if (length >= 6) return this._valueFormatResources.wordMillion;
    if (length >= 3) return this._valueFormatResources.wordThousand;

    return "";
  }

  private _getCompactValue(
    length: number,
    value: number,
    valueType: ValueType
  ): { asString: string; asNumber: number } {
    let compactValue: string;
    let roundedValue: number;
    let numFormat = this.getNumberFormat("n1");
    value = Math.abs(value);

    if (value === 0) {
      if (valueType === "absolute") {
        compactValue = "0";
        roundedValue = 0;
      } else {
        compactValue = numFormat.format(0);
        roundedValue = 0.0;
      }
      return { asString: compactValue, asNumber: roundedValue };
    }

    if (length < 0) {
      numFormat = this.getNumberFormat("n2");
      return { asString: numFormat.format(value), asNumber: roundToDecimal(value, 2) };
    }

    if (length >= 12) {
      roundedValue = roundToDecimal(value / Math.pow(10, 12), 1);
      numFormat = this.getNumberFormat("n1");
      compactValue = numFormat.format(roundedValue);
    } else if (length >= 9) {
      roundedValue = roundToDecimal(value / Math.pow(10, 9), 1);
      numFormat = this.getNumberFormat("n1");
      compactValue = numFormat.format(roundedValue);
    } else if (length >= 6) {
      roundedValue = roundToDecimal(value / Math.pow(10, 6), 1);
      numFormat = this.getNumberFormat("n1");
      compactValue = numFormat.format(roundedValue);
    } else if (valueType === "absolute") {
      const decimalPlaces = length > 2 ? 0 : 2 - length;
      roundedValue =
        length >= 3
          ? roundToDecimal(value / Math.pow(10, 3))
          : roundToDecimal(value, decimalPlaces);
      numFormat = this.getNumberFormat("n" + decimalPlaces);
      compactValue = numFormat.format(roundedValue);
      const currentDecimalSeparator = this.getCurrentDecimalSeparator();
      if (compactValue.includes(currentDecimalSeparator)) {
        compactValue = this._removeTrailingZeros(compactValue);
      }
    } else if (length >= 3) {
      roundedValue = roundToDecimal(value / Math.pow(10, 3));
      numFormat = this.getNumberFormat("n0");
      compactValue = numFormat.format(roundedValue);
    } else if (length >= 1) {
      roundedValue = roundToDecimal(value, 2 - length);
      numFormat = this.getNumberFormat("n1");
      compactValue = numFormat.format(roundedValue);
    } else {
      roundedValue = value;
      numFormat = this.getNumberFormat("n1");
      compactValue = numFormat.format(roundedValue);
    }

    if (compactValue.length >= this._maxNumCharacters) {
      const index = compactValue.indexOf(this.getCurrentDecimalSeparator());
      if (index === 3) {
        const valueAsString = value.toString();
        const indexLeft = compactValue[0] === valueAsString[0] ? 3 : 2;
        const indexRight = this.isDecimalSignAt(valueAsString, indexLeft)
          ? indexLeft + 1
          : indexLeft;

        const left = +valueAsString.substring(0, indexLeft);
        const right = +valueAsString.substring(indexRight, indexRight + 1);
        roundedValue = left + right / 10;
      }

      roundedValue = roundToDecimal(roundedValue);
      numFormat = this.getNumberFormat("n0");
      compactValue = numFormat.format(roundedValue);
    }

    return { asString: compactValue, asNumber: roundedValue };
  }

  private isDecimalSignAt(value: string, index: number): boolean {
    return index < value.length && (value[index] === "." || value[index] === ",");
  }

  private _removeTrailingZeros(formattedValue: string): string {
    const decimalSeparator = this.getCurrentDecimalSeparator();
    const splitValue = formattedValue.split(decimalSeparator);

    if (splitValue.length === 1) return;

    if (splitValue.length > 2)
      throw Error(
        `ERROR: Tried to remove trailing zeros for value formatting. Preformatted input value '${formattedValue}' had multiple decimal separators which is invalid`
      );

    splitValue[1] = splitValue[1].replace(/0+$/, "");

    formattedValue = splitValue[1] ? splitValue.join(decimalSeparator) : splitValue[0];
    return formattedValue;
  }

  public getCurrentDecimalSeparator(): string {
    const testValue = 1.1;
    const separatorSymbol = testValue.toLocaleString(this._culture).substring(1, 2);
    return separatorSymbol;
  }

  private _formatTimeSpan(val: number, format: string): string {
    if (val == null) return this._valueFormatResources.notAvailable;

    let result = "";

    let decimalCount = 0;
    const absValue = Math.abs(val);
    const formatTypes = format.substring(8).split(",");

    // parse the number as timeSpan according to given format 0
    let timeSpan: BissantzTimeSpan = null;
    switch (formatTypes[0]) {
      case "0":
        timeSpan = BissantzTimeSpan.fromDays(absValue);
        break;
      case "1":
        timeSpan = BissantzTimeSpan.fromHours(absValue);
        break;
      case "2":
        timeSpan = BissantzTimeSpan.fromMinutes(absValue);
        break;
      case "3":
        timeSpan = BissantzTimeSpan.fromSeconds(absValue);
        break;
      case "4":
        timeSpan = BissantzTimeSpan.fromMilliseconds(absValue);
        break;
      default:
        return this._valueFormatResources.notAvailable;
    }

    // get full days:
    let formattedDays = "";
    if (timeSpan.days > 0) {
      formattedDays = `${timeSpan.days.toLocaleString(this._culture)} ${
        this._valueFormatResources.timeSpanFormatDay
      }, `;
    }

    // get timeOfDay:
    const timeOfDay = timeSpan.timeOfDay;

    // get milliseconds (of day)
    let millisecondsOfDay = "";
    if (formatTypes.length > 1) {
      decimalCount = parseInt(formatTypes[1]);
      if (decimalCount > 0) {
        const millisecInSeconds = timeSpan.milliseconds / 1000;
        millisecondsOfDay = roundToDecimal(millisecInSeconds, decimalCount)
          .toFixed(decimalCount)
          .substr(1, decimalCount + 1);
      }
    }

    result = formattedDays + timeOfDay + millisecondsOfDay;
    if (val < 0) result = "-" + result;
    return result;
  }

  getNumberFormat(key: string): Intl.NumberFormat {
    let dict = formatDictionaries[this._culture];
    if (!dict) {
      dict = {};
      formatDictionaries[this._culture] = dict;
    }

    if (dict[key]) return dict[key];

    return (dict[key] = this._createNumberFormat(key));
  }

  private _createNumberFormat(key: string): Intl.NumberFormat {
    switch (key.toLowerCase()) {
      case "g7":
        return new Intl.NumberFormat([this._culture], {
          maximumFractionDigits: 7,
          useGrouping: false,
        });

      case "n9":
        return new Intl.NumberFormat([this._culture], {
          maximumFractionDigits: 9,
          minimumFractionDigits: 9,
        });

      case "n8":
        return new Intl.NumberFormat([this._culture], {
          maximumFractionDigits: 8,
          minimumFractionDigits: 8,
        });

      case "n7":
        return new Intl.NumberFormat([this._culture], {
          maximumFractionDigits: 7,
          minimumFractionDigits: 7,
        });

      case "n6":
        return new Intl.NumberFormat([this._culture], {
          maximumFractionDigits: 6,
          minimumFractionDigits: 6,
        });

      case "n5":
        return new Intl.NumberFormat([this._culture], {
          maximumFractionDigits: 5,
          minimumFractionDigits: 5,
        });

      case "n4":
        return new Intl.NumberFormat([this._culture], {
          maximumFractionDigits: 4,
          minimumFractionDigits: 4,
        });

      case "n3":
        return new Intl.NumberFormat([this._culture], {
          maximumFractionDigits: 3,
          minimumFractionDigits: 3,
        });

      case "n2":
        return new Intl.NumberFormat([this._culture], {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });

      case "n1":
        return new Intl.NumberFormat([this._culture], {
          maximumFractionDigits: 1,
          minimumFractionDigits: 1,
        });

      case "n0":
        return new Intl.NumberFormat([this._culture], {
          maximumFractionDigits: 0,
        });

      case "p9":
        return new Intl.NumberFormat([this._culture], {
          style: "percent",
          minimumFractionDigits: 9,
          maximumFractionDigits: 9,
        });

      case "p8":
        return new Intl.NumberFormat([this._culture], {
          style: "percent",
          minimumFractionDigits: 8,
          maximumFractionDigits: 8,
        });

      case "p7":
        return new Intl.NumberFormat([this._culture], {
          style: "percent",
          minimumFractionDigits: 7,
          maximumFractionDigits: 7,
        });

      case "p6":
        return new Intl.NumberFormat([this._culture], {
          style: "percent",
          minimumFractionDigits: 6,
          maximumFractionDigits: 6,
        });

      case "p5":
        return new Intl.NumberFormat([this._culture], {
          style: "percent",
          minimumFractionDigits: 5,
          maximumFractionDigits: 5,
        });

      case "p4":
        return new Intl.NumberFormat([this._culture], {
          style: "percent",
          minimumFractionDigits: 4,
          maximumFractionDigits: 4,
        });

      case "p3":
        return new Intl.NumberFormat([this._culture], {
          style: "percent",
          minimumFractionDigits: 3,
          maximumFractionDigits: 3,
        });

      case "p2":
        return new Intl.NumberFormat([this._culture], {
          style: "percent",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

      case "p1":
        return new Intl.NumberFormat([this._culture], {
          style: "percent",
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        });

      case "p0":
        return new Intl.NumberFormat([this._culture], {
          style: "percent",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
    }

    return null;
  }
}
