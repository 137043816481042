import debounce from "lodash/debounce";
import { IStatusBarService } from "../status-bar-service.interface";
import { appResources } from "@/app-resources";
import { removedFromBodyObserver } from "@/common/helper/removed-from-body-observer";
import { DM7Timeout } from "@/common/helper/dm7-timeout";
import { isInRect } from "@/common/events/dom-event-helper";

export class HelpTextService {
  watcherDebounceTime_ms = 200;
  watchCounterStart = 10;
  watchInterval_ms = 100;
  statusBarService: IStatusBarService = null;
  attributeName: string = "helptext";
  textsOfOverlappingElements: string[];
  private _lastTarget: HTMLElement = null;
  private _watchCounter = 0;
  private _timeoutWatcher: DM7Timeout = null;

  constructor(statusBarService: IStatusBarService) {
    this.statusBarService = statusBarService;
    this._addEventListeners();
    this.setOverlappingTexts();
  }

  setOverlappingTexts(): void {
    this.textsOfOverlappingElements = [
      appResources.helpTexts.excludeFromScaling,
      appResources.helpTexts.includeToScaling,
      appResources.helpTexts.drillElement,
      appResources.helpTexts.closeElementDrill,
      appResources.helpTexts.changeElementDeviation,
      appResources.helpTexts.changePeriod,
    ];
  }

  private _updateHelpText(target: HTMLElement, x?: number, y?: number): void {
    this._unobserveLastTarget();

    if (!target) {
      return;
    }

    const kpiDrillInformation = this.statusBarService.getCurrentElementStatusText();
    const statusText = [kpiDrillInformation, ...this.getHelpTextAttributes(target)]
      .filter((text) => text.length > 0)
      .join(" | ");
    const canShowText = this._canShowText(target, x, y);

    if (statusText.length > 0 && canShowText) {
      this.statusBarService.showText(statusText, true);
      this._observeTarget(target);
    } else {
      this.statusBarService.hideText();
    }
  }

  private _canShowText(target: HTMLElement, x?: number, y?: number): boolean {
    const hasValidXY = x != null && y != null;

    if (!hasValidXY) {
      return true;
    }

    const firstTarget = this.getFirstHelpTextTarget(target);

    if (!firstTarget) {
      return true;
    }

    const rect = firstTarget.getBoundingClientRect();
    return isInRect(x, y, rect);
  }

  getFirstHelpTextTarget(element: HTMLElement): HTMLElement {
    if (element === null) {
      return null;
    }

    if (Object.prototype.hasOwnProperty.call(element.dataset, this.attributeName)) {
      return element;
    }

    return this.getFirstHelpTextTarget(element.parentElement);
  }

  getHelpTextAttributes(element: HTMLElement | null, helpTexts: string[] = []): string[] {
    if (element == null) {
      return helpTexts;
    }

    const text = element?.dataset[this.attributeName];
    const isUniqueOverlappingText =
      this.textsOfOverlappingElements.includes(text) && !helpTexts.includes(text);

    if (text != null && (helpTexts.length === 0 || isUniqueOverlappingText)) {
      helpTexts.push(text);
    }

    return this.getHelpTextAttributes(element.parentElement, helpTexts);
  }

  private _addEventListeners(): void {
    window.addEventListener("pointerup", this._onDashboardChangeDebounced.bind(this));
    window.addEventListener("pointermove", this._onDashboardChangeDebounced.bind(this));
  }

  private _onDashboardChangeDebounced = debounce((ev: PointerEvent) => {
    clearTimeout(this._timeoutWatcher);
    this._watchCounter = this.watchCounterStart;
    if (ev.target !== null && ev.pointerType === "mouse") {
      this._updateTarget(ev.target as HTMLElement);
    }
  }, this.watcherDebounceTime_ms);

  private _updateTarget(target: HTMLElement, x?: number, y?: number): void {
    if (this._watchCounter === 0) {
      return;
    }

    this._updateHelpText(target, x, y);
    this._watchCounter--;
    this._timeoutWatcher = setTimeout(
      () => this._updateTarget(target, x, y),
      this.watchInterval_ms
    );
  }

  private _observeTarget(target: HTMLElement): void {
    this._lastTarget = target;
    removedFromBodyObserver.observe(target, this._onTargetRemoved.bind(this));
  }

  private _unobserveLastTarget(): void {
    if (!this._lastTarget) {
      return;
    }

    removedFromBodyObserver.unobserve(this._lastTarget);
    this._lastTarget = null;
  }

  private _onTargetRemoved(): void {
    // As soon as one target disappears, all the help texts are removed.
    // Not just the help texts from the target.
    const currentText = this.statusBarService.getCurrentElementStatusText();
    if (currentText.length === 0) {
      this.statusBarService.hideText();
    } else {
      this.statusBarService.showText(currentText);
    }
  }
}
