<script lang="ts">
import { defineComponent, computed, Ref, ref } from "vue";
import type { CSSProperties } from "vue/types/jsx";
import { useHoverHelper } from "@/common/composables/hover-helper.cpsl";

export default defineComponent({
  components: {},
  emits: [],
  props: {
    color: { type: String, default: null },
    hoverColor: { type: String, default: null },
  },
  setup(props) {
    const ref_el: Ref<HTMLDivElement> = ref(null);
    const hoverHelperCpsl = useHoverHelper(ref_el);

    //
    // Life Cycle:
    // --------------------

    //
    // Computeds:
    // --------------------
    const colorVars = computed<CSSProperties>(() => {
      const color = props.color ?? "var(--color_neutralText)";
      const isHover = props.hoverColor !== null && hoverHelperCpsl.isHover.value;
      return {
        "--stroke-color": isHover ? props.hoverColor : color,
      };
    });

    //
    // Functions:
    // --------------------

    //
    // Watcher:
    // --------------------
    return {
      ref_el,
      colorVars,
    };
  },
});
</script>

<template>
  <div ref="ref_el" class="accurate-numbers-icon" v-bind:style="colorVars">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 210 210">
      <path
        class="cls-1"
        d="M56.1,106.3v42.4h-10.9v-29.8l-8.8,5.6-5.1-7.3,15.7-10.9h9.1Z"
      />
      <path
        class="cls-1"
        d="M78.6,138.6l21.9-21.5c5.2-5.1,7-8.1,7-12.6s-3.5-8.2-8.8-8.2c-7.8,0-11.6,8-11.6,8l-9.6-6.2s5.2-13.6,22.4-13.6,20.7,8,20.7,18.5-3.5,14.1-11.4,21.5l-13.3,12.9h24.2v11.3h-41.6v-10.1Z"
      />
      <path
        class="cls-1"
        d="M135,132.3l10.7-6.6s5.5,10.1,18.7,10.1,18.1-6.8,18.1-15.1-7-15.3-19.1-15.3h-8.8v-11h9.1c10.3,0,16.2-5.7,16.2-13.6s-6.4-13.5-15.6-13.5-15.6,8.4-15.6,8.4l-10.4-6s6.9-14.9,27-14.9,28.8,9.7,28.8,24-5.6,16.8-14.2,20.7c10.1,3.9,16.8,12.1,16.8,22.6,0,15.5-13.8,26.2-32.2,26.2s-29.5-16-29.5-16Z"
      />
    </svg>
  </div>
</template>

<style scoped lang="less">
.accurate-numbers-icon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  svg {
    height: 100%;
    width: 100%;

    .cls-1 {
      fill: var(--stroke-color);
      stroke-width: 0;
    }
  }
}
</style>
