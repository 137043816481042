import { ITypedEvent } from "@/common/events/ityped-event";
import { TypedEvent } from "@/common/events/typed-event";
import { Ref, ref } from "vue";
import {
  IInputEventsService,
  KeyEventEntry,
  KeyId,
  KeyIds,
} from "./input-events-service.interface";
export class InputEventsService implements IInputEventsService {
  private _specificKeyChangeEvents: { keyId: string; event: TypedEvent<boolean> }[] = [];
  private _anyKeyChanged = new TypedEvent<KeyEventEntry>();
  private _downKeys: Ref<string[]> = ref([]);

  // No 'removeEventListener' here because the InputEventsServer is a singleton
  constructor() {
    window.addEventListener("keydown", this._onAnyKeyDown.bind(this));
    window.addEventListener("keyup", this._onAnyKeyUp.bind(this));
    window.addEventListener("dragend", this._resetDonwKeys.bind(this));
    window.addEventListener("blur", this._removeAll.bind(this));
    window.addEventListener("mousemove", this._onMouseEvent.bind(this));
  }

  isDown(keyId: KeyId): boolean {
    return this._downKeys.value.includes(keyId);
  }

  get anyKeyChanged(): ITypedEvent<KeyEventEntry> {
    return this._anyKeyChanged;
  }

  getkeyChangedEvent(keyId: string): ITypedEvent<boolean> {
    if (!keyId) return;

    const existingEvent = this._tryGetSpecificEvent(keyId);
    if (existingEvent) {
      return existingEvent;
    }

    const newEvent = { keyId: keyId, event: new TypedEvent<boolean>() };
    this._specificKeyChangeEvents.push(newEvent);
    return newEvent.event;
  }

  private _onMouseEvent(ev: MouseEvent): void {
    ev.ctrlKey ? this._addKey(KeyIds.control) : this._removeKey(KeyIds.control);
    ev.altKey ? this._addKey(KeyIds.alt) : this._removeKey(KeyIds.alt);
    ev.shiftKey ? this._addKey(KeyIds.shift) : this._removeKey(KeyIds.shift);
  }

  private _onAnyKeyDown(ev: KeyboardEvent) {
    this._addKey(ev.key);
  }

  private _onAnyKeyUp(ev: KeyboardEvent): void {
    this._removeKey(ev.key);
  }

  private _resetDonwKeys(): void {
    const numberOfDownKeys = this._downKeys.value.length;
    this._downKeys.value.splice(0, numberOfDownKeys);
  }

  private _addKey(key: string): void {
    if (!key) return;

    if (!this._downKeys.value.includes(key)) {
      this._downKeys.value.push(key);
      this._emit(key, true);
    }
  }

  private _removeKey(key: string): void {
    if (!key || !this._downKeys.value.includes(key)) return;

    const idx = this._downKeys.value.indexOf(key);
    this._downKeys.value.splice(idx, 1);
    this._emit(key, false);
  }

  private _removeAll(): void {
    this._downKeys.value.map((key) => this._removeKey(key));
  }

  private _emit(keyId: string, isDown: boolean): void {
    this._anyKeyChanged.emit({ keyId, isDown });

    const specificEvt = this._tryGetSpecificEvent(keyId);
    specificEvt?.emit(isDown);
  }

  private _tryGetSpecificEvent(keyId: string): TypedEvent<boolean> {
    const foundEvent = this._specificKeyChangeEvents.find((evt) => evt.keyId === keyId);
    return foundEvent?.event;
  }
}
