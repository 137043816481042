<script lang="ts">
import { BcInput } from "@bissantz/controls";
import { PropType, computed, defineComponent, ref, watch } from "vue";
import { IUniqueValidator } from "../validators/unique-validator.interface";
import { BcInputUniqueValidator } from "../validators/bc-input-unique-validator";

export default defineComponent({
  components: {
    BcInput,
  },

  emits: ["update:modelValue", "input"],

  props: {
    modelValue: {
      type: [String, Number, null, undefined],
      required: false,
      default: null,
    },
    errors: { type: Map<string, string>, required: false, default: null },
    fullWidth: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    fitContent: { type: Boolean, default: false },
    value: { type: [String, Number, null, undefined], default: null }, // needed in vue2
    uniqueValidator: {
      type: Object as PropType<IUniqueValidator>,
      required: false,
      default: null,
    },
  },

  setup(props, context) {
    const ref_bcInput = ref<InstanceType<typeof BcInput>>(null);

    const internalModelValue = computed({
      // v-model pattern:
      get() {
        return props.modelValue === null ? props.value : props.modelValue;
      },
      set(_value: unknown) {},
    });

    const uniqueInputValidator = computed(() => {
      if (props.uniqueValidator === null && props.uniqueValidator === undefined) {
        return null;
      }

      return new BcInputUniqueValidator(ref_bcInput.value, props.uniqueValidator);
    });

    // v-model pattern:
    async function emitUpdateValue(newValue: unknown) {
      context.emit("update:modelValue", newValue); // needed in vue3.
      context.emit("input", newValue); //needed for vue2. (can be deleted in future)

      await validateIfNecessary();
    }

    async function validateIfNecessary() {
      if (uniqueInputValidator.value !== null) {
        await uniqueInputValidator.value.validateAsync();
      }
    }

    function onBlur() {
      if (uniqueInputValidator.value !== null) {
        uniqueInputValidator.value.serialize();
      }
    }

    watch(
      () => props.uniqueValidator,
      async () => await validateIfNecessary()
    );

    return {
      ref_bcInput,
      internalModelValue,
      // emits:

      emitUpdateValue,
      onBlur,
    };
  },
});
</script>

<template>
  <BcInput
    ref="ref_bcInput"
    design="7"
    v-bind:fullWidth="$props.fullWidth"
    v-model="internalModelValue"
    v-bind:required="$props.required"
    v-bind:fitContent="$props.fitContent"
    v-bind:errors="$props.errors"
    v-on:input="emitUpdateValue"
    v-on:blur="onBlur"
  />
</template>
