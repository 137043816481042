<script lang="ts">
import ModalDialog from "./helper-components/modal-dialog.vue";
import ApplicationWizard from "./application-wizard.vue";
import AppWizardScreenPopup from "./helper-components/app-wizard-screen-popup.vue";
import { defineComponent, inject } from "vue";
import { AppWizardService } from "@/services/app-wizard-service";

export default defineComponent({
  components: {
    ModalDialog,
    ApplicationWizard,
    AppWizardScreenPopup,
  },

  setup() {
    const appWizardService: AppWizardService = inject("appWizardService");

    return { appWizardService };
  },
});
</script>

<template>
  <div class="modal-application-wizard" v-show="appWizardService.isShowing">
    <ModalDialog>
      <Transition>
        <ApplicationWizard v-if="appWizardService.isShowing" class="show-app-wizard" />
      </Transition>
      <div class="hide-app-wizard">
        <AppWizardScreenPopup />
      </div>
    </ModalDialog>
  </div>
</template>

<style lang="less" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.4s ease;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}

.modal-application-wizard {
  .show-app-wizard {
    display: flex;
  }

  .hide-app-wizard {
    display: none;
  }

  @media (max-width: 750px), (max-height: 630px) {
    .show-app-wizard {
      display: none;
    }

    .hide-app-wizard {
      display: flex;
      justify-content: center;
    }
  }
}
</style>
