<script lang="ts">
import HeaderTitle from "./header-title.vue";

import { TileVm } from "./tile-vm";
import { appResources } from "@/app-resources";
import { SharedPageState } from "../view-models/shared-page-state";
import { PortalCommon } from "../portal-common";
import { gridConstants } from "../portal-grid/grid-common";
import { TileGridService } from "@bissantz/tile-grid";
import { resizeHelper, Unobserver } from "@/common/resize-helper";

import {
  computed,
  defineComponent,
  inject,
  onBeforeUnmount,
  onMounted,
  PropType,
  provide,
  reactive,
  ref,
  watch,
} from "vue";
import { Mutex } from "async-mutex";
import { ITileService } from "../tile-pages/tile-service.interface";

class PortalTileState {
  textResources = appResources.portalTexts;
  blink = false;
  isInitialized = false;
  deleteTriggered = new Mutex();
}

export default defineComponent({
  components: {
    HeaderTitle,
  },

  emits: [PortalCommon.portalTile_deleteTile.name, PortalCommon.portalTile_clone.name],

  props: {
    tileVm: { type: Object as PropType<TileVm>, required: true },
    isEditing: { type: Boolean, default: false },
    sharedPageState: { type: Object as PropType<SharedPageState>, required: true },
  },

  setup: function (props, context) {
    //
    // Injections:
    // ------------------
    const tileGridService: TileGridService = inject("tileGridService");
    //
    // Provide:
    // ------------------
    provide(
      "tileService",
      computed(() => {
        return {
          width: props.tileVm.tileConfig.w,
          maxWidth: props.sharedPageState.numberOfGridColumns,
          tileHtmlElem: ref_componentElem.value?.parentElement,
          setWidth(newWidth: number) {
            props.tileVm.tileConfig.w = newWidth;
          },
          isViewStatesEnabled: !props.tileVm.isTemporary,
          isAnimationsEnabled: props.tileVm.animationsEnabled,
          isInError: !!errorMessage.value,
          isInEdit: props.isEditing,
          isInFullpage: props.tileVm.isFullpage,
          toggleFullpage() {
            props.tileVm.isFullpage = !props.tileVm.isFullpage;
          },
          // TODO: TileGrid, must expand public interface!
          isAnyTileDragging: tileGridService["_gridInternalSettings"]?.isDragging,
        } as ITileService;
      })
    );
    //
    // DOM/Comp. refs:
    // ------------------
    const ref_componentElem = ref<HTMLDivElement>();
    let unobserveCallback: Unobserver = null;

    // State:
    const state = reactive(new PortalTileState());

    // Life cycle:
    // ------------------
    onMounted(() => {
      if (!props.tileVm.isInitialized) {
        return;
      }

      onTileInitialized();
    });

    onBeforeUnmount(() => {
      unobserveCallback?.();
    });

    //
    // Computeds:
    // ------------------
    const canExtend = computed<boolean>(() => {
      return props.sharedPageState.numberOfGridColumns >= 4;
    });

    const title = computed<string>(() => {
      return props.tileVm.getShownTitle();
    });

    const errorMessage = computed<string>(() => {
      let errorMsg = null;
      if (props.tileVm?.error) {
        errorMsg = state.textResources.errorUnkownTileError;
        if (props.tileVm.error === "NoTilePage") {
          errorMsg = state.textResources.errorNoTilePage;
        }
      }

      return errorMsg;
    });

    //
    // Methods:
    // ------------------

    function startBlink() {
      state.blink = true;
      setTimeout(() => {
        state.blink = false;
      }, 1000);
    }

    async function onDeleteThisTile(): Promise<void> {
      // TODO: this line is strange: the tile can only be deleted once. Also: an "acquire" should also have a conrresponding "release"
      await state.deleteTriggered.acquire();

      const isClonedTile = props.tileVm.isTemporary;
      context.emit(
        PortalCommon.portalTile_deleteTile.name,
        props.tileVm.id,
        isClonedTile
      );
    }

    function getNewHeight(): number {
      const newHeight = ref_componentElem.value?.clientHeight;
      if (!newHeight) {
        return null;
      }
      const heightInRows = Math.ceil(newHeight / props.sharedPageState.rowHeight);
      return Math.max(heightInRows, gridConstants.minimalTileRows);
    }

    function onScreenshotRendered(): void {
      startBlink();
    }

    const onTileResize = (() => {
      // variable scoped to all calls of below function
      let previousResizeCall: Promise<void> = null;
      return async function (): Promise<void> {
        if (!state.isInitialized || props.tileVm.isFullpage) {
          return;
        }

        const height = getNewHeight();
        const tileIndex = props.tileVm.tileConfig.i;
        const width = props.tileVm.tileConfig.w;

        if (previousResizeCall) {
          previousResizeCall.then(() => {
            previousResizeCall = tileGridService.setTileSize(tileIndex, width, height);
            toggleWidthIfChanged(width);
          });
        } else {
          previousResizeCall = tileGridService.setTileSize(tileIndex, width, height);
          toggleWidthIfChanged(width);
        }
      };
    })();

    function toggleWidthIfChanged(width: number): void {
      if (width === props.tileVm.tileConfig.w) {
        return;
      }

      props.tileVm.tileConfig.w = width;
    }

    function onGridColsChanged(): void {
      if (!(props.tileVm.tileConfig.w > 2)) {
        return;
      }

      if (canExtend.value) {
        return;
      }

      props.tileVm.tileConfig.w = 2;
    }

    function onPageInitializedChanged(): void {
      if (!props.tileVm.activePage?.isInitialized) {
        return;
      }

      onTileInitialized();
    }

    function onTileInitialized(): void {
      unobserveCallback = resizeHelper.observe(ref_componentElem.value, onTileResize);
      state.isInitialized = true;
    }

    function onTileSelected(): void {
      props.tileVm.portalSelection.tileId = props.tileVm.id;
      props.tileVm.portalSelection.tilePageId = props.tileVm.activePage?.id;
    }

    function onPortalSelectionChanged(): void {
      if (!props.sharedPageState.isShowing) {
        return;
      }

      const portalSelection = props.tileVm.portalSelection;
      const isTileSelected = props.tileVm.id === portalSelection.tileId;

      props.tileVm.tilePageVms.map((tp) => {
        if (tp) {
          tp.isSelected = isTileSelected && tp.id === portalSelection.tilePageId;
        }
      });
    }

    function forwardCloningTriggered() {
      context.emit(PortalCommon.portalTile_clone.name, props.tileVm);
    }

    // Watchers:
    // -----------------
    watch(() => props.sharedPageState.numberOfGridColumns, onGridColsChanged);
    watch(() => props.tileVm.activePage?.isInitialized, onPageInitializedChanged);
    watch(
      [
        () => props.sharedPageState.isShowing,
        () => props.tileVm.portalSelection.tileId,
        () => props.tileVm.portalSelection.tilePageId,
      ],
      onPortalSelectionChanged
    );

    return {
      // Injections, State & Refs:
      state,
      ref_componentElem,

      // Computeds:
      title,
      errorMessage,

      // Event Handler:
      onDeleteThisTile,
      onTileSelected,
      onScreenshotRendered,
      forwardCloningTriggered,
    };
  },
});
</script>

<template>
  <div
    ref="ref_componentElem"
    class="portal-tile-component"
    v-bind:class="{
      blink: state.blink,
      'is-fullpage': $props.tileVm.isFullpage,
    }"
    v-if="$props.tileVm"
  >
    <!-- TITLE -->
    <HeaderTitle
      v-if="!$props.tileVm.isFullpage"
      v-bind:title="title"
      v-bind:canShowDeleteButton="$props.tileVm.isTemporary"
      v-bind:onDeleteCallback="onDeleteThisTile"
    />

    <!-- T I L E - - - P A G E -->
    <div v-if="errorMessage" class="tile-error">
      <div class="error-spacer" />
      <div class="error-text">
        {{ errorMessage }}
      </div>
    </div>
    <component
      v-on:pointerdown.native="onTileSelected"
      class="tilepage-host"
      v-else-if="$props.sharedPageState.wasShown && $props.tileVm.hasPageContent"
      v-bind:is="$props.tileVm.activePage.pageComponent"
      v-bind:pageVm="$props.tileVm.activePage"
      v-on:screenshot_rendered="onScreenshotRendered"
      v-on:tilePage_clone="forwardCloningTriggered"
      v-on="$listeners"
    />
  </div>
</template>

<style lang="less">
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.portal-tile-component {
  padding-top: 28px;
  white-space: nowrap;
  opacity: 1;

  --hostedFontSize: 12px;
  --tile_width: 100%;
  --kpi-bar_height: 5em;
  --error-tile-height: 116px;

  width: var(--tile_width);

  display: flex;
  flex-direction: column;

  &.is-fullpage {
    padding-top: 0;
    width: 100%;
    height: 100%;
  }

  &.blink {
    animation-name: blink;
    animation-duration: 300ms;
  }

  .tile-error {
    white-space: normal;
    height: var(--error-tile-height);
    background-color: var(--color_bg_white);
    border-radius: 0 0 6px 6px;
    font-size: var(--hostedFontSize);

    .error-spacer {
      width: 100%;
      height: 27px;
      border-bottom: 1px solid var(--color_bg-gray);
    }

    .error-text {
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--color_neutralText);
      margin: 16px 2em;
      overflow: hidden;
    }
  }
}
</style>
