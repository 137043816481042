<script lang="ts">
import { SwiperVm } from "@/common/components/swiper-vm";
import Swiper from "@/common/components/swiper.vue";
import PageHint from "./page-hint.vue";

import { StatusBarService } from "@/services/status-bar-service";
import { PortalPageHints } from "@/services/status-bar-service.interface";
import {
  inject,
  reactive,
  watch,
  onBeforeMount,
  onBeforeUnmount,
  defineComponent,
} from "vue";
import { IDisposable } from "@/common/disposable.interface";
import { PageHintType } from "./page-hint-type";
import { PageHintHelper as PageHintHelper } from "./page-hint-helper";

const minimumVisiblePages = 5;
class PageHintsState {
  pageHints: PortalPageHints = { activePageIdx: null, pageTitles: [] };
  swiperVm: SwiperVm = new SwiperVm(minimumVisiblePages, 0, false, false);
  disposeListeners: IDisposable[] = [];
  isUpdating: boolean = false;
  pageHintHelper: PageHintHelper = new PageHintHelper();
}

export default defineComponent({
  components: {
    Swiper,
    PageHint,
  },

  props: {
    showsBgColor: { type: Boolean, required: true },
    maxAvailableWidthInPixels: { type: Number, required: true },
  },

  setup(props) {
    const statusBarService: StatusBarService = inject("statusBarService");

    const state = reactive(new PageHintsState()) as PageHintsState;

    state.pageHintHelper.maxAvailableWidthInPixels = props.maxAvailableWidthInPixels;

    onBeforeMount(() => {
      {
        // init:
        updatePageHints(statusBarService.currentPageHints);

        //  change listeners for page hints:
        state.disposeListeners.push(
          statusBarService.activePageChanged.on(onActivePageIdxChanged),
          statusBarService.pageTitlesChanged.on(onPageTitlesChanged)
        );
      }
    });

    onBeforeUnmount(() => {
      state.disposeListeners.map((dl) => dl?.dispose());
    });

    function updatePageHints(pageHints: PortalPageHints): void {
      const newPageTiles = [...pageHints.pageTitles];

      if (newPageTiles.length !== state.pageHints.pageTitles.length) {
        state.isUpdating = state.pageHints.pageTitles.length !== 0;
        state.pageHints.pageTitles = newPageTiles;

        state.pageHintHelper.numberOfPageHints = pageHints.pageTitles.length;
        state.swiperVm.numberOfItemsToDisplay =
          state.pageHintHelper.numberOfVisiblePageHints;
      }
      state.pageHints.activePageIdx = pageHints.activePageIdx;
    }

    function selectPage(index: number): void {
      statusBarService.updatePortalPageHints(index);
    }

    function swipeTo() {
      const animateSwipe = !state.isUpdating;
      state.swiperVm.swipeToCenter(state.pageHints.activePageIdx, animateSwipe);
    }

    function getPageHintSize(index: number): PageHintType {
      return state.pageHintHelper.determinePageHintType(
        index,
        state.pageHints.activePageIdx
      );
    }

    function onPageTitlesChanged(pageHints: PortalPageHints) {
      updatePageHints(pageHints);
    }

    function onActivePageIdxChanged(newIdx: number): void {
      state.pageHints.activePageIdx = newIdx;
    }

    function onAvailablePageHintSpaceChanged(maxAvailableWidthInPixels: number): void {
      state.isUpdating = true;
      selectPage(state.pageHints.activePageIdx);
      swipeTo();

      state.pageHintHelper.maxAvailableWidthInPixels = maxAvailableWidthInPixels;

      state.swiperVm.numberOfItemsToDisplay =
        state.pageHintHelper.numberOfVisiblePageHints;

      swipeTo();
      state.isUpdating = false;
    }

    function onSwiperFinished() {
      state.isUpdating = false;
    }

    function onPageHintClicked(index: number) {
      if (index !== state.pageHints.activePageIdx) {
        selectPage(index);
      }
    }

    watch(
      [() => state.pageHints.activePageIdx, () => state.swiperVm.numberOfItems],
      swipeTo,
      { immediate: true }
    );

    watch(
      () => props.maxAvailableWidthInPixels,
      () => onAvailablePageHintSpaceChanged(props.maxAvailableWidthInPixels)
    );

    return {
      // refs:
      state,

      //functions:
      getPageHintSize,
      onPageHintClicked,
      onSwiperFinished,
    };
  },
});
</script>

<template>
  <div class="portal-footer_page-hint-list">
    <Swiper
      v-bind:swiperVm="state.swiperVm"
      v-bind:itemsVms="state.pageHints.pageTitles"
      v-bind:blockSwipe="true"
      v-on:swiper-finished="onSwiperFinished"
    >
      <template v-slot:swipeItems="sProps">
        <PageHint
          v-bind:isActive="sProps.realIndex === state.pageHints.activePageIdx"
          v-bind:useAlternativeColors="$props.showsBgColor"
          v-bind:sizeType="getPageHintSize(sProps.realIndex)"
          v-on:pageHints_pageHintClicked="onPageHintClicked(sProps.realIndex)"
        />
      </template>
    </Swiper>
  </div>
</template>

<style lang="less" scoped>
.portal-footer_page-hint-list {
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
