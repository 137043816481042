<script lang="ts">
import { reactive, defineComponent, PropType, inject } from "vue";
import { appResources } from "@/app-resources";
import { MagicButtonListVm } from "../../../view-models/modelling/magic-button-list-vm";
import { MagicButton } from "../../../backend-wrapper/dto-wrappers";
import { INotificationService } from "@/features/notifications/notification-service.interface";
import WizardButton from "../../../helper-components/wizard-button.vue";
import { ButtonVm } from "@/features/application-wizard/view-models/modelling/button-vm";

export default defineComponent({
  components: {
    WizardButton,
  },

  props: {
    magicButtonListVm: { type: Object as PropType<MagicButtonListVm>, required: true },
  },

  setup(props) {
    const notificationService = inject("notificationService") as INotificationService;

    const state = reactive({
      appWizardTexts: appResources.applicationWizardTexts,
      magicButtonTexts: appResources.magicButtonTexts,
    });

    async function onMagicButtonClicked(selectedMagicButton: MagicButton) {
      if (!props.magicButtonListVm.isValidMagicButtonCombination(selectedMagicButton)) {
        showNotification();
        return;
      }

      const result =
        await props.magicButtonListVm.updateMagicButtonsAfterCommandExecutionAsync(
          selectedMagicButton
        );

      if (!result.succeeded) {
        showNotification();
      }
    }

    function showNotification() {
      notificationService.info({
        title: state.appWizardTexts.modelling_magButNotificationHeader,
        message: state.appWizardTexts.modelling_magButNotificationBody,
      });
    }

    function generateReferenceButtonName(mb: MagicButton) {
      if (mb.magicButtonType !== "Reference") {
        return state.magicButtonTexts[mb.magicButtonType];
      } else {
        return props.magicButtonListVm.generateReferenceButtonName();
      }
    }

    function getButtonVm(mb: MagicButton): ButtonVm {
      return new ButtonVm(mb.magicButtonType, generateReferenceButtonName(mb));
    }

    return {
      state,
      onMagicButtonClicked,
      generateReferenceButtonName,
      getButtonVm,
    };
  },
});
</script>

<template>
  <div class="application-wizard_magic-button-list button-wrapper distance-top">
    <WizardButton
      v-for="mb in $props.magicButtonListVm.currentMagicButtons"
      v-bind:key="mb.magicButtonType"
      v-bind:buttonVm="getButtonVm(mb)"
      v-on:click="onMagicButtonClicked(mb)"
      v-bind:isSelected="mb.isExecuted"
      v-bind:isOpaque="$props.magicButtonListVm.areMagicButtonsOpaque"
      v-bind:isHoverable="true"
      v-bind:areButtonsDeletable="false"
    />
  </div>
</template>

<style lang="less" scoped>
@import "./report-modelling.less";

.button-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
</style>
