import { DisplayDeviceHint } from "@/common/styles/media-queries";

export class DashboardSettings {
  /**
   * If enabled, only show KPI that are listed in the kpiFilter array
   */
  applyKpiIdFilter: boolean = false;

  /**
   * Ids of KPIs that will be shown
   */
  shownKpiIds: number[] = [];

  isExtended: boolean = false;

  isCompact: boolean = false;

  /**
   * Stretches to the full width of container
   */
  useFullWidth: boolean = false;

  /**
   * Selected Kpid. -1 if no kpi is selected.
   */
  selectedKpiId: number = -1;

  /**
   * "true": embeds tiles so that parent element sets the size
   *         and a scrollbar is used to see all tiles
   *
   * "false": parent is sized according to tile content
   */
  scrollTiles: boolean = true;

  /**
   * Force to apply styles for the given
   * display device
   */
  forcedDisplayDevice: DisplayDeviceHint = null;

  static applyDefaultDesktop(settings: DashboardSettings): void {
    settings.useFullWidth = false;
  }

  static applyDefaultPhone(settings: DashboardSettings): void {
    settings.useFullWidth = true;
  }

  static applyDefaultPortal(settings: DashboardSettings): void {
    settings.forcedDisplayDevice = "mediaHint_isMobile";
    settings.useFullWidth = true;
    settings.scrollTiles = false;
    settings.applyKpiIdFilter = true;
  }

  clone(): DashboardSettings {
    const clone = new DashboardSettings();
    clone.applyKpiIdFilter = this.applyKpiIdFilter;
    clone.shownKpiIds = [...this.shownKpiIds];
    clone.useFullWidth = this.useFullWidth;
    clone.scrollTiles = this.scrollTiles;
    clone.forcedDisplayDevice = this.forcedDisplayDevice;
    clone.isExtended = this.isExtended;
    clone.isCompact = this.isCompact;
    return clone;
  }
}
