// Rounding in JS using only Math.round is highly problematic. Various ways
// around this problem exist.
// (https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary)

// number.toFixed() also has issues (still in jan-2024):
// https://stackoverflow.com/questions/20701029/rounding-issue-in-math-round-tofixed
//
// This file is used to isolate our solution to the problem and have a single
// place to change it.

import roundToFixed from "round-tofixed";
// be careful with negative numbers:
//https://github.com/terrymorse58/round-tofixed/issues/1

export function roundToDecimal(value: number, decimalPlaces: number = 0): number {
  let roundedResult: number = null;
  if (value < 0) {
    roundedResult = -1 * roundToFixed(Math.abs(value), decimalPlaces);
  } else {
    roundedResult = roundToFixed(value, decimalPlaces);
  }

  return roundedResult;
}
