import { FailedReason } from "@/common/results/failed-reason";
import { ITreeNodeLoader } from "../trees/tree-node-loader.interface";
import { TreeNodeVm } from "../trees/tree-node-vm";
import { ValueResult } from "@/common/results/value-result";
import { IReportFacade } from "@/features/application-wizard/backend-wrapper/report-facade-interface";

export class MeasureNodeLoader implements ITreeNodeLoader {
  private readonly _reportFacade: IReportFacade;
  private readonly _semanticModelId: string;

  constructor(reportFacade: IReportFacade, semanticModelId: string) {
    this._reportFacade = reportFacade;
    this._semanticModelId = semanticModelId;
  }

  async loadAsync(_id: string): Promise<ValueResult<TreeNodeVm[], FailedReason>> {
    const kpis = await this._reportFacade.getKpisAsync(this._semanticModelId);

    if (kpis.succeeded === false) {
      return ValueResult.createFromError(kpis.failedReason);
    }

    const result = [];

    for (const kpi of kpis.value) {
      const treeItem = new TreeNodeVm(kpi.id, kpi.name);
      treeItem.value = kpi;

      result.push(treeItem);
    }

    return ValueResult.createFromValue(result);
  }
}
