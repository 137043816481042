import { UAParser } from "ua-parser-js";
const parser = new UAParser();
const parserResult = parser.getResult();
const browserName = parserResult.browser.name.toLowerCase();
const browserType = (() => {
  switch (browserName) {
    case "chrome":
      return "chrome";
    case "firefox":
      return "firefox";
    case "safari":
      return "safari";
    case "webkit":
      return "safari";
    default:
      return "other";
  }
})();

export type BrowserType = "chrome" | "firefox" | "safari" | "other";

export function getBrowserType(): BrowserType {
  return browserType;
}

export function isMobile(): boolean {
  // Device type is undefined on desktop browsers
  return parserResult.device.type !== undefined;
}
