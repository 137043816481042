import { IPortalSelection } from "../contract/portal-selection.interface";
import { PortalVm } from "./portal-vm";

export class ProtectedPortalSelectionVm implements IPortalSelection {
  portalId: string = null;
  pageId: string = null;
  tileId: string = null;
  tilePageId: string = null;

  /**
   * Reset all blow pageId
   */
  resetPageSelection(): void {
    this.pageId = null;
    this.resetTileSelection();
  }

  /**
   * Resets previously selected subelements of the portal
   * (all elements below page ID)
   */
  resetTileSelection(): void {
    this.tileId = null;
    this.tilePageId = null;
  }

  getSelectedWeatherColor(portalVm: PortalVm): string {
    const selectedPage = portalVm?.portalPages.find((page) => page.id === this.pageId);
    if (!selectedPage) {
      return null;
    }

    const selectedTile = selectedPage.tiles.find((tile) => tile.id === this.tileId);
    if (!selectedTile) {
      return null;
    }

    const selectedTilePage = selectedTile.tilePageVms.find(
      (tilePage) => tilePage.id === this.tilePageId
    );

    return selectedTilePage?.weatherColor ?? null;
  }
}
