<script lang="ts">
import { defineComponent, computed } from "vue";
import ArrowIcon from "@/common/components/icons/arrow-icon.vue";
import { useHelpText } from "@/services/help-text-service/help-text.cpsl";
import { DefaultStyles } from "@/common/styles/default-styles";

const colorConstants = DefaultStyles.colorConstants;

export class HeightToggleEvents {
  static shrinkClicked = "shrinkClicked";
  static expandClicked = "expandClicked";
}

export default defineComponent({
  components: { ArrowIcon },
  emits: [],
  props: {
    isShowAllKpiActive: { type: Boolean, default: false },
    isCompact: { type: Boolean, default: false },
    isExtended: { type: Boolean, default: false },
    isExpandingKpis: { type: Boolean, default: false },
    areSparklinesCompatible: { type: Boolean, default: false },
  },
  setup(props, context) {
    const helpTextCpsl = useHelpText();

    //
    // Life Cycle:
    // --------------------

    //
    // Computeds:
    // --------------------
    const strokeColor = computed<string>(() => {
      return props.isShowAllKpiActive
        ? colorConstants.darkTextHEX
        : colorConstants.headerTextHEX;
    });

    const canExpand = computed<boolean>(() => {
      return (
        !props.isShowAllKpiActive ||
        (props.isShowAllKpiActive &&
          props.isCompact &&
          props.isExtended &&
          props.isExpandingKpis)
      );
    });

    const helpTextMainArrow = computed<string>(() => {
      return helpTextCpsl.kpiShrinkExpandText(
        !canExpand.value,
        props.isShowAllKpiActive,
        props.isCompact,
        props.isExtended,
        props.areSparklinesCompatible
      );
    });

    const helpTextSecondaryArrow = computed<string>(() => {
      return helpTextCpsl.kpiShrinkExpandText(
        props.isExpandingKpis,
        props.isShowAllKpiActive,
        props.isCompact,
        props.isExtended,
        props.areSparklinesCompatible
      );
    });

    //
    // Functions:
    // --------------------
    function onShrinkClick(): void {
      context.emit(HeightToggleEvents.shrinkClicked);
    }

    function onExpandClick(): void {
      context.emit(HeightToggleEvents.expandClicked);
    }

    //
    // Watcher:
    // --------------------
    return {
      helpTextCpsl,
      colorConstants,
      strokeColor,
      helpTextMainArrow,
      helpTextSecondaryArrow,
      canExpand,
      onShrinkClick,
      onExpandClick,
    };
  },
});
</script>

<template>
  <div class="height-toggle">
    <ArrowIcon
      v-bind:initialColor="strokeColor"
      v-bind:hoverColor="colorConstants.darkTextHEX"
      v-bind:rotationDegree="canExpand ? 90 : -90"
      v-bind:data-helpText="helpTextMainArrow"
      v-on:click.native="canExpand ? onExpandClick() : onShrinkClick()"
    />
    <ArrowIcon
      v-if="$props.isShowAllKpiActive && $props.isCompact && $props.isExtended"
      v-bind:initialColor="strokeColor"
      v-bind:hoverColor="colorConstants.darkTextHEX"
      v-bind:rotationDegree="$props.isExpandingKpis ? -90 : 90"
      v-bind:data-helpText="helpTextSecondaryArrow"
      v-on:click.native="$props.isExpandingKpis ? onShrinkClick() : onExpandClick()"
    />
  </div>
</template>

<style scoped lang="less">
.height-toggle {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .arrowIcon {
    height: 80%;
  }
}
</style>
